import React from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { Colors } from "../common/colors";
import IMAGES from '../common/images';
import { navigateToPath, setHeadMetaDescription } from "../common/functions";
import { get } from 'lodash';
import { modalQueue } from '../redux/actions';
import { useTitle } from 'hookrouter';

function PigeitVideoComponent(props) {
  useTitle('ShipWinner.com | The Best Shipping Rates for FedEx & UPS | Save up to 62% on Shipping Costs!');
  setHeadMetaDescription("Want to know how to get a Discount on Shipping? Watch the video below to learn how to get the Lowest Shipping Rates with FedEx and UPS. Best of all, It's Free! | ShipWinner.com");

  const dispatch = useDispatch();
  return (
    <PigeitVideoStyle className="video-container">
      <div className="background-container">
        <div className="content-container">
          <Button className="modal-close-button" onClick={close}>
            <IMAGES.close />
          </Button>
          <iframe
            width="100%"
            height="505"
            src="https://www.youtube.com/embed/TsMfcHXyHas"
            frameborder="0"
            allowFullScreen
            allow=" autoplay; encrypted-media"
            >
          </iframe>
        </div>
      </div>
    </PigeitVideoStyle>
  );
  function close() {
    if (get(props, 'modalConfig.url', false)) {
      navigateToPath('', true);
    }
    else {
      dispatch(modalQueue({ mode: 'delete' }));
    }
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const PigeitVideoStyle = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  text-align: center;
  overflow: hidden;
  background-color: ${Colors.white};
  .background-container {
    height: 100%;
    width: 100%;
    background-color: #fff;
    margin: 0 auto;
    position: relative;
  }
  .content-container {
  }
  .modal-close-button {
    background-color:${Colors.white};
    position: absolute;
    top: 21px;
    right: 26px;
  }
  @media only screen and (max-width: 992px){
    overflow: auto;
  }
`;

const PigeitVideo = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeitVideoComponent);


export default PigeitVideo;
