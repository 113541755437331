import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ZIndices } from '../common/zIndices';
import CONSTANTS from '../common/constants';

function LoaderComponent(props) {

  useEffect(() => {
    if (!props.imageOnly) {
      document.body.classList.add('loader-active');
      return function() {
        document.body.classList.remove('loader-active');
      }
    }
  });

  if (props.imageOnly) {
    return (<img src={CONSTANTS.PUBLIC_URL + "/images/loader.gif"} className={ props.className ? props.className : "loader-16x16"} alt="" />);
  }

  if (props.isVisible) {

    return (
      <LoaderStyle className="loader-wrapper">
        <div className="loader-text">{props.text}</div>
      </LoaderStyle>
    );
  }
  return (null);
}

const mapStateToProps = (state, ownProps) => {
  return {
    isVisible: state.data.loader.isVisible,
    text: state.data.loader.text
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const LoaderStyle = styled.div`
  position: fixed;
  background-color: rgba(0,0,0,0.3);
  width: 100%;
  height: 100%;
  top: 0;
  z-index: ${ZIndices.loader};
  .loader-text {
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
  }
`;

const Loader = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoaderComponent);

export default Loader;
