/* eslint jsx-a11y/anchor-is-valid: off */
import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Navigation from './Navigation.component';
import MainPageFooter from './MainPageFooter.component';
import { Colors } from '../common/colors';
import CONSTANTS from '../common/constants';
import { setHeadMetaDescription } from "../common/functions";
import { useTitle } from 'hookrouter';



function TrackYourShipmentComponent(props) {
  const [tabIndexForAll, setTabIndexForAll] = useState(null);
  const [trackingNumber, setTrackingNumber] = useState('');
  const header = !props.company?'Track Your Shipment':`Track Your ${props.company==='fedex'?'FedEx':'UPS'} Shipment`;
  const title = !props.company?'Tracking Your Shipment | ShipWinner':`Track Your ${props.company==='fedex'?'FedEx':'UPS'} Shipment | ShipWinner`;
  const description = !props.company?"Want to know how to Track your Shipment with a FedEx or UPS Tracking Number? We offer Free and Easy Package-Tracking Solutions | ShipWinner.com":
  `Want to know how to Track your Shipment with a ${props.company==='fedex'?'FedEx':'UPS'} Tracking Number? We offer Free and Easy Package-Tracking Solutions | ShipWinner.com`
  
  useTitle(title);
  setHeadMetaDescription(description);

  return (
    <TrackYourShipmentStyle className="hidden-on-fullscreen-modal">
      <div className="contact-us-background-container">
        <Navigation passedTabIndex={tabIndexForAll} />
      </div>


      <div className='header-container'>
        <h1 className='main-header'>{header}</h1>
        <p className='second-header'>Enter your tracking information to see where your package is now. It's on its way!</p>
      </div>
    <div className='main-container display-flex'>
      <div className='side-menu desktop-component'>
      <a target='_blank' rel="noopener noreferrer" href='https://www.fedex.com/locate/index.html' className='link' >Find the nearer FedEx location</a>
      <a target='_blank' rel="noopener noreferrer" href='https://www.ups.com/dropoff/?loc=en_US' className='link' >Find the nearer UPS location</a>
      <a className='link'>Find the nearer USPS location</a>
      </div>
      <div className='main-content'>
        <div className='track-package display-flex'>
          <input type={props.company==='fedex'?'number':'text'} value={trackingNumber} onChange={(e)=>setTrackingNumber(e.target.value)} className='track-input' placeholder='Enter your tracking number'/>
          <a className={'track-button'+(trackingNumber===''?' disabled':'')} href={getTrackingNumber()}>Find my Package</a>
        </div>
      </div>
    </div>
      <MainPageFooter />
    </TrackYourShipmentStyle>
  );

  function getTrackingNumber() {
    if (trackingNumber === '') {
      return null;
    }
    if(props.company==='fedex'){
      return 'https://www.fedex.com/apps/fedextrack/?trackingnumber=' + trackingNumber;
    }
    if(props.company==='ups'){
      return 'https://wwwapps.ups.com/tracking/tracking.cgi?tracknum=' + trackingNumber;
    }
    if (isNaN(trackingNumber)) {
      return 'https://wwwapps.ups.com/tracking/tracking.cgi?tracknum=' + trackingNumber;
    }
    else {
      return 'https://www.fedex.com/apps/fedextrack/?trackingnumber=' + trackingNumber;
    }
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const TrackYourShipmentStyle = styled.div`
.contact-us-background-container {
  background-image: url("${CONSTANTS.PUBLIC_URL}/images/pages/home/header-blue.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: white;
    width: 100%;
  }
  .header-container{
    width: 100%;
    background-color: #EDEDED;
    text-align: center;
    font-family: museo_sans500;
    color: #323232;
    .main-header{
      margin:0;
      padding: 41px 0;
      font-size: 36px;
    }
    .second-header{
      margin:0;
      font-size: 27px;
      padding: 24px 0;
    }
    .secondary-header{
      text-align: center;
      font-size: 27px;
      margin-top: -9px;
      color: #70777D;
    }
  }
  .main-container{
    padding-bottom: 50px;
    .side-menu{
      flex: 0 0 20%;
      margin-left: 100px;
      .link{
    margin-top: 50px;
    display: block;
    text-decoration: none;
    color:#323232;
  }
    }
    .main-content{
      margin-left:150px;

    }
    .track-package{
      padding-top: 50px;
      .track-input{
        height: 50px;
        width: 250px;
        font-size: 18px;
        padding-left: 15px;
        border-radius: 3px;
        border: solid 2px #186DA7;
        outline: none;
      }
      .track-input::placeholder{
        color:#9DAAB1;
      }
      .track-button{
        &.disabled{
          background-color: #9DAAB1;
        }
        height: 56px;
        text-decoration: none;
        background-color: #FCB02E;
        border-radius: 3px;
        line-height: 56px;
        width: 200px;
        text-align: center;
        margin-left: 10px;
        color: white;
      }
      .track-button:hover{
        background-color: #9DAAB1;
      }
    }
  }
  background-color: ${Colors.white};
  @media only screen and (max-width: 992px){
    .header-container{
      .second-header{
        font-size: 16px;
        padding-top: 0;
      }
    }
    .main-container{ 
      &.display-flex{
      flex-direction: column;
    }
      .main-content{
        margin-left: 0;
      }
      .track-package{
        &.display-flex{
      flex-direction: column;
    }
        .track-input{
          width: 80%;
          margin: auto;
        }
        .track-button{
          width: 85%;
          margin: auto;
          margin-top: 20px;
        }
      } 
    }
  }
`;

const TrackYourShipment = connect(
  mapStateToProps,
  mapDispatchToProps
)(TrackYourShipmentComponent);

export default TrackYourShipment;
