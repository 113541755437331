/* eslint react/jsx-pascal-case: off, react-hooks/exhaustive-deps: off, eqeqeq: off */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { api_update_profile, api_update_password ,api_getInitialData,api_addressBook_add,api_addressBook_getList, setupAnimatedLoader} from '../redux/actions';
import { Button } from '@material-ui/core';
import { Colors } from "../common/colors";
import Loader from './Loader.component';
import PigeItCheckboxComponent from "./PigeItCheckbox.component";
import { get } from 'lodash';
import PigeItInputAddress from "./PigeItInputAddress.component";
import { showErrorMessage,setHeadMetaDescription} from "../common/functions";
import { useTitle } from 'hookrouter';
import PigeItInput from './PigeItInput.component';
import { ZIndices } from "../common/zIndices";


function PigeItMyAccount_PersonalInfoComponent(props) {
  useTitle('My Account | Personal Info | ShipWinner');
  setHeadMetaDescription(`Welcome! We provide an Effortless Shipping Experience. Whether you're using FedEx or UPS, we'll help you collect all the necessary information so you can ship your packages with just a few clicks. Open your Free Shipping Account on ShipWinner to start Saving on Shipping Costs Now. | ShipWinner.com`);
  const dispatch = useDispatch();
  const [ inProgress, setInProgress ] = useState(false);
  const [placeId,setPlaceId]=useState('');
  const [addToAddressBook, setAddToAdderssBook] = useState(false);
  const [leftColFields, setLeftColFields] = useState([
    { label: 'Full name', id: 'fullname', name: 'fullname', type: 'text', value: '' },
    { label: 'Company', id: 'company', name: 'company', type: 'text', value: '' },
    { label: 'Email', id: 'email', name: 'email', type: 'text', value: '', disabled: true },
    { label: 'Phone', id: 'phone', name: 'phone', type: 'text', value: '' },
    { label: 'Adress', id: 'address', name: 'address', type: 'address', value: '' },
    { label: 'City', id: 'city', name: 'city', type: 'text', value: '',disabled:true },
    { label: 'State', id: 'state', name: 'state', type: 'text', value: '' ,disabled:true},
    { label: 'Country', id: 'country', name: 'country', type: 'text', value: '',disabled:true },
    { label: 'Zip code', id: 'zip-code', name: 'zip-code', type: 'text', value: '',disabled:true }
  ]);
  const [ rightColFields, setRightColFields ] = useState([
    { label: 'Old password', id: 'password', name: 'password', type: 'password', value: '' },
    { label: 'New Password', id: 'new-password', name: 'new-password', type: 'password', value: '' },
    { label: 'Confirm New Password', id: 'confirm-new-password', name: 'confirm-new-password', type: 'password', value: '' }
  ]);

  useEffect(function() {
    if (get(props,'apiData_getInitialData.result.success')) {
      leftColFields[0].value = get(props,'apiData_getInitialData.result.fullname');
      leftColFields[1].value = get(props,'apiData_getInitialData.result.company');
      leftColFields[2].value = get(props,'apiData_getInitialData.result.user');
      leftColFields[3].value = get(props,'apiData_getInitialData.result.phone');
      leftColFields[4].value = get(props,'apiData_getInitialData.result.address');
      leftColFields[5].value = get(props,'apiData_getInitialData.result.city');
      leftColFields[6].value = get(props,'apiData_getInitialData.result.state');
      leftColFields[7].value = get(props,'apiData_getInitialData.result.country');
      leftColFields[8].value = get(props,'apiData_getInitialData.result.zipcode');
      setPlaceId(get(props, 'apiData_getInitialData.result.googlePlaceId'));
      setLeftColFields([].concat(leftColFields));
    }
  }, [ props.apiData_getInitialData ]);

  return (
    <PigeItMyAccount_PersonalInfoStyle className="my-account-personal-info-container">
      <div className="mobile-component bottom-banner">
        <div className="save-changes-button-container">
          <Button onClick={save}> {inProgress && <Loader imageOnly />} Save Changes</Button>
        </div>
      </div>
      <div className="background-container">
        <div className='place-holder'/>
        <div className="desktop-component title-and-add-to-address-book-control-container display-flex">
          <div className="title-text flex-divided-2-cols">
            Personal Info
          </div>
        </div>
        <hr className="desktop-component seperator" />
        <div className="display-flex fjc-space-between">
          <div className="flex-divided-2-cols first-col">
              {leftColFields.map((item) => {
                return (
                <div key={item.id} className="ta-left field-label-input-container">
                    {
                      item.type == 'address' ?
                        <PigeItInputAddress className='input-field' passedValue={item.value} onChange={(value) => { onAddressInputChange(value) }} />
                        :
                        <PigeItInput label={item.label} name={item.id} id={item.name} type={item.type} className="input-field" value={item.value} disabled={inProgress||item.disabled} readOnly={item.readonly ? true : false} onChange={(e) => { changeField(item, e); }} />}
                </div>);
              })}
          </div>
          <div className="flex-divided-2-cols second-col">
            {rightColFields.map((item) => {
              return (
                <div key={item.id} className="ta-left field-label-input-container">
                  <PigeItInput label={item.label} name={item.id} id={item.name} type={item.type} className="input-field" value={item.value} disabled={inProgress||item.disable} readOnly={item.readonly ? true : false} onChange={(e) => { changeField(item, e); }} />
                </div>);
            })}
          </div>
        </div>
        <div className="add-to-adress-book-control flex-divided-2-cols">
          <PigeItCheckboxComponent disabled={inProgress} checked={addToAddressBook} onClick={(checked) => setAddToAdderssBook(checked)} labelText="Add my info to Address book" />
        </div>
        <div className='place-holder'/>
      </div>
      <div className="desktop-component save-changes-button-container">
        <Button onClick={save}> {inProgress && <Loader imageOnly />} Save Changes</Button>
      </div>
    </PigeItMyAccount_PersonalInfoStyle>
  );

  function changeField(item, e) {
    item.value = e.target.value;
    setLeftColFields([].concat(leftColFields));
    setRightColFields([].concat(rightColFields));
  }

  function onAddressInputChange(value) {
    //112 Ocean Ave, Amityville, NY 11701, USA
    let addressValue = get(value, 'placeObject.formatted_address', '');
    let placeIdValue = get(value, 'placeId', '');
    setPlaceId(placeIdValue);
    addressValue = addressValue.split(',');
    let city = addressValue.length >= 1 ? addressValue[1] : '';
    let state = addressValue.length >= 2 ? addressValue[2].split(' ').length > 1 ? addressValue[2].split(' ')[1] : "" : "";
    let country = addressValue.length >= 3 ? addressValue[3] : "USA";
    let zipCode = addressValue.length >= 2 ? addressValue[2].split(' ').length > 2 ? addressValue[2].split(' ')[2] : "" : "";
    setLeftColFields(leftColFields.map(item => {
      if (item.label === 'Adress') {
        return { ...item, value: get(value, 'placeObject.formatted_address', '') };
      }
      else if (item.label === 'City') {
        return { ...item, value: city };
      }
      else if (item.label === 'State') {

        return { ...item, value: state };
      }
      else if (item.label === 'Country') {
        return  { ...item, value: country };
      }
      else  if (item.label === 'Zip code') {
        return   { ...item, value: zipCode };
      }else{
        return item;
      }
    }));
  }

  function save(e) {
    if (inProgress) {
      return;
    }
    let expected = 0;
    let completed = 0;
    if(addToAddressBook){
      let request = {
        company: leftColFields[1].value||' your Company',
        email: get(props, 'apiData_getInitialData.result.user'),
        fullname: leftColFields[0].value,
        googlePlaceId: placeId,
        phone: leftColFields[3].value,
        callback: function (result){
          setInProgress(false);
          dispatch(setupAnimatedLoader({ isVisible: false }));
          if(result.success){
            dispatch(api_addressBook_getList());
          }
          else{
            showErrorMessage(result, dispatch);
          }
        }
      };
      setInProgress(true);
      dispatch(setupAnimatedLoader({ isVisible: true, text: 'Adding to address book...' }));
      dispatch(api_addressBook_add(request));
    }
    if (areProfileDetailsChanged()) {
      let updateProfileInput = {
        fullName: leftColFields[0].value,
        company: leftColFields[1].value,
        phone: leftColFields[3].value,
        address: leftColFields[4].value,
        city: leftColFields[5].value,
        state: leftColFields[6].value,
        country: leftColFields[7].value,
        zipCode: leftColFields[8].value,
        googlePlaceId: placeId,
        callback: function(result) {
          completed++;
          if (expected === completed) {
            if(result&&result.success){
              dispatch(api_getInitialData({callback:function(result) {
                setInProgress(false);
                dispatch(setupAnimatedLoader({ isVisible: false }));
              }}));
            }
          }
          if(result&&!result.success){
            setInProgress(false);
            dispatch(setupAnimatedLoader({ isVisible: false }));
            showErrorMessage(result,dispatch);
          }
        }
      };
      expected++;
      dispatch(setupAnimatedLoader({ isVisible: true, text: 'Updating profile...' }));
      setInProgress(true);
      dispatch(api_update_profile(updateProfileInput));
    }
    if (isPasswordInformationChanged()) {
      let updatePasswordInput = {
        password: rightColFields[0].value,
        newPassword: rightColFields[1].value,
        callback: function(result) {
          completed++;
          if (expected === completed) {
            setInProgress(false);
            dispatch(setupAnimatedLoader({ isVisible: false }));
          }
          if(result&&!result.success){
            setInProgress(false);
            dispatch(setupAnimatedLoader({ isVisible: false }));
            showErrorMessage(result,dispatch);
          }
        }
      };
      expected++;
      dispatch(setupAnimatedLoader({ isVisible: true, text: 'Updating password...' }));
      setInProgress(true);
      dispatch(api_update_password(updatePasswordInput));
    }
  }

  function areProfileDetailsChanged() {
    if (get(props,'apiData_getInitialData.result.fullname') != leftColFields[0].value ||
      get(props,'apiData_getInitialData.result.company') != leftColFields[1].value ||
      get(props,'apiData_getInitialData.result.phone') != leftColFields[3].value ||
      get(props,'apiData_getInitialData.result.address') != leftColFields[4].value ||
      get(props,'apiData_getInitialData.result.city') != leftColFields[5].value ||
      get(props,'apiData_getInitialData.result.state') != leftColFields[6].value ||
      get(props,'apiData_getInitialData.result.country') != leftColFields[7].value ||
      get(props,'apiData_getInitialData.result.zipcode') != leftColFields[8].value
    ) {
      return true;
    }
    return false;
  }

  function isPasswordInformationChanged() {
    if (rightColFields[0].value.length && rightColFields[1].value.length && rightColFields[2].value.length) {
      return true;
    }
    return false;
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    apiData_getInitialData: state.data.api.getInitialData
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  };
};

const PigeItMyAccount_PersonalInfoStyle = styled.div`
  .background-container {
    padding: 1px 18px 0 18px;
    background-color: ${Colors.panelBackground};
  }
  .place-holder{
    height: 40px;
  }
  .address-input{
    margin-bottom: 8px;
  }
  .bottom-banner{
    width: 100%;
    position: fixed;
    background: white;
    z-index: ${ZIndices.myAccountPersonalInfo};
    bottom: 0;
    box-shadow: 0px -1px 6px #d0d0d0;
  }
  .title-text {
    text-align: left;
    font-family: museo_sans300,sans-serif;
    color: ${Colors.darkBlue1};
    font-size: 30px;
    margin: 19px 0px 0px 25px;
  }
  .add-to-adress-book-control {
    text-align: left;
    margin: 19px 25px 0px 0px;
  }
  .seperator {
    margin-bottom: 30px;
  }
  .field-label {
    font-size: 16px;
    color: ${Colors.lightGray2};
    margin-bottom: 11px;
  }
  .input-field {
    width: 100%;
    margin-bottom: 18px;
  }
  .field-label-input-container {
    width: calc(100% - 25px);
  }
  .second-col .field-label-input-container {
    width: calc(100% - 45px);
    margin-left: 20px;
  }
  @media only screen and (max-width: 992px) {
    .add-to-adress-book-control{
      margin-bottom: 65px;
    }
    .input-field {
      margin-bottom: 18px;
    }
    .add-to-adress-book-control{
      text-align: left;
    }
    .field-label-input-container{
      width: calc(100%);
    }
    .display-flex {
      flex-flow: column nowrap;
    }
    .flex-divided-2-cols {
      flex: 0 0 100%;
    }
    .second-col .field-label-input-container {
      width: calc(100%);
      margin-left: 00px;
    }
    .title-and-add-to-address-book-control-container {
      display: none;
    }
  }
  .save-changes-button-container {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 10px;
    margin-top: 10px;
    BUTTON {
      margin-top: 0px;
      font-family: museo_sans700,sans-serif;
      font-size: 16px;
      max-width: 300px;
      width: 100%;
      height: 56px;
      color: ${Colors.white};
      background-color: ${Colors.orange};
    }
  }
`;

const PigeItMyAccount_PersonalInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItMyAccount_PersonalInfoComponent);


export default PigeItMyAccount_PersonalInfo;
