import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { modalQueue, reset_account_change_password } from '../redux/actions';
import { Button } from '@material-ui/core';
import { Colors } from "../common/colors";
import CONSTANTS from '../common/constants';
import IMAGES from '../common/images';
import Loader from './Loader.component';
import { showErrorMessage, getQueryParam } from "../common/functions";
import { navigateToPath } from "../common/functions";
import PigeItInput from './PigeItInput.component';


function PigeItEnterNewPasswordComponent(props) {

  const dispatch = useDispatch();
  const [inProgress, setInProgress] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState('Reseting password...');
  const [password,setPassword] = useState({pass1:'',pass2:''});
  const email = getQueryParam('username');
  const key = getQueryParam('resetKey');

  useEffect(() => {
    if (!key.length || !email.length) {
      close();
    }
  }, []);

  return (
    <PigeItEnterNewPasswordStyle className="enter-new-password-container">
      <div className="background-container">
        <div className="content-container">
          <Button className="modal-close-button" onClick={close}>
            <IMAGES.close />
          </Button>
          <div className="header-text">{email}</div>
          <PigeItInput className={'input'} value={password.pass1} onChange={(e)=>setPassword({...password,pass1:e.target.value})} disabled={inProgress}  label='Enter password' type='password'/>
          <PigeItInput className={'input'} value={password.pass2} onChange={(e)=>setPassword({...password,pass2:e.target.value})} disabled={inProgress}  label='Confirm password'type='password'/>
          <button className={'ok button'+(password.pass1===password.pass2&&password.pass2!==''?'':' disabled')} onClick={confirm}> {inProgress && <Loader imageOnly />}RESET PASSWORD</button>
        </div>
      </div>

    </PigeItEnterNewPasswordStyle >
  );

  function confirm() {
    if(inProgress){
      return;
    }
   if(password.pass1===password.pass2&&password.pass2!==''){
    let request = {
      newPassword: password.pass2,
      resetKey: key,
      username: email,
      callback:function(result){
        setInProgress(false);
        close();
        if(result.success){
          let modalConfig = {
            ...CONSTANTS.MODALS_CONFIG.MESSAGE,
            buttons: { ok: { visible: true, text: 'OK' }, cancel: { visible: false, text: 'CANCEL' } },
            message: 'Reset password success'
          };
          dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
        }
        else{
          close();
          showErrorMessage(result,dispatch);
        }
      }
    };
    setInProgress(true);
    dispatch(reset_account_change_password(request));
   }
  }

  function returnHome() {
    navigateToPath('', true);
  }

  function close() {
    if(inProgress){
      return;
    }
    dispatch(modalQueue({ mode: 'delete' }));
    returnHome();
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  };
};

const PigeItEnterNewPasswordStyle = styled.div`
  width: 100%;
  height: 100%;
  .input{
    margin: 0 94px;
    margin-bottom: 20px;
  }
  .button{
    background: unset;
    border: none;
    cursor: pointer;
    display: block;
    margin: auto;
    outline: none;
    &.ok{
      width: 350px;
      height: 56px;
      text-align: center;
      color: ${Colors.white};
      background-color: ${Colors.orange};
      font-size: 18px;
      font-family: museo_sans700;
      border-radius: 3px;
    }
    &.disabled{
      background-color:${Colors.lightGray4};
      cursor: unset;
    }
    .icon{
      svg{
        width: 16px;
        height: 13px;
        margin-right: 15px;
        path{
          fill: ${Colors.white};
        }
      }
    }
    
    .text{
      display: inline-block;
    }
  }
  
  .background-container {
    height: 100%;
    width: 100%;
    background-color: #fff;
    margin: 0 auto;
    
  }
  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
    padding-top: 5%;
  }
  .modal-close-button {
    position: absolute;
    top: 21px;
    right: 26px;
  }
  .header-text {
    max-width: 350px;
    font-size: 21px;
    text-align: center;
    margin: auto;
    word-wrap: break-word;
  }
  @media only screen and (max-width: 992px) { 
    .header-text {
      font-size: 25px;
    }
    .input{
      margin: 0 22px;
      margin-bottom: 20px;
    }
    .zip-input{
      min-width: 280px;
    }
    .button{
      &.find-location{
        width: 302px;
      }
    }
  }
`;

const PigeItEnterNewPassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItEnterNewPasswordComponent);




export default PigeItEnterNewPassword;
