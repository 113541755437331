/* eslint jsx-a11y/anchor-is-valid: off */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

function PigeitCaptchaComponent(props) {

  useEffect(function () {
    var createTime = new Date().getTime() / 1000;
    var localIntervalId = setInterval(function () {
      var runTime = new Date().getTime() / 1000;
      var isCleared = false;
      try {
        if (window.grecaptcha && window.grecaptcha.render) {
          isCleared = true;
          window.grecaptcha.render('recaptcha', {
            'sitekey': process.env.REACT_APP_RECAPTCHA_KEY,
            'callback': verifyCallback
          });
          if(props.onLoad){
            props.onLoad();
          }
          clearInterval(localIntervalId);
        }
      }
      catch (err) {
        // nothing to do
      }
      if (!isCleared && runTime - createTime > 10) {
        // 10 seconds passed - too much
        clearInterval(localIntervalId);
      }
    }, 100);
    // try to hook google maps
    return function () {
      // destruct
      if (localIntervalId != null) {
        clearInterval(localIntervalId);
      }
    }
  },[]);

  return (
    <div id='recaptcha' className={props.className} />
  );

  function verifyCallback(token) {
    if(props.onVerify) {
      props.onVerify(token);
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const PigeitCaptcha = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeitCaptchaComponent);

export default PigeitCaptcha;
