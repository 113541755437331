import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import IMAGES from '../common/images';
import { identifyCreditCardType } from "../common/functions";
import creditCardType from 'credit-card-type';
import { Colors } from '../common/colors';

function PigeItCreditCardInputComponent(props) {

  const [cardNumber, setCardNumber] = useState(props.value ? props.value : '');
  var cardType = creditCardType(cardNumber);
  const cardTypeIconType = identifyCreditCardType(cardNumber);
  let Icon = cardTypeIconType ? IMAGES[cardTypeIconType] : null;
  const [focused, setfocused] = useState(false);

  return (
    <PigeItCreditCardInputStyle className={"credit-card-input " + (props.className ? props.className : '')+ (focused ? ' focused' : '')+(props.error?' error':'')} >
      <label className={'address-label' +(props.error?' label-error':'')+ (focused ? ' focused' : '')}>Credit Card</label>
      <input className='input' onFocus={()=>setfocused(true)} onBlur={()=>setfocused(false)} value={formattedNum(cardNumber)} onChange={handleChange} placeholder={props.placeholder} />
      {cardNumber && Icon && <Icon />}
    </PigeItCreditCardInputStyle>
  );

  function handleChange(e) {
    let value = e.target.value;
    if (!isNaN(value.split(' ').join(''))) {
      setCardNumber(value.split(' ').join(''));
      if (props.onChange) {
        props.onChange({ number: value, cardType: cardType[0] ? cardType[0] : null });
      }
    }
  }

  function formattedNum(num) {
    if (cardType[0]) {
      //  if(num.length>cardType[0].lengths){
      //    num = num.substring(0,cardType[0].lengths);
      //  }
      let retNum = '';
      let prev = 0;
      for (let index = 0; index < cardType[0].gaps.length; index++) {
        let gap = cardType[0].gaps[index];
        if (num.length > gap) {
          retNum = retNum + num.substring(prev, gap) + ' ';
          prev = gap;
        }
      }
      retNum = retNum + num.substring(prev);
      return retNum;
    }
    else {
      return num;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: ownProps.onChange
  }
};

const PigeItCreditCardInputStyle = styled.div`
  display: flex !important;
  border: solid 1px #c1c4c5;
    border-radius: 4px;
    height: 36px;
    &.focused{
      border-color:${Colors.blue1}
    }
    &.error{
      border-color:${Colors.error};
    }
  svg{
    margin-right: 5px;
    margin-top: -2px;
    max-height: 40px;
    width: 60px;
  }
  .address-label{
    &.focused{
      color:${Colors.blue1}
    }
    &.label-error{
      color:${Colors.error};
    }
    position: absolute;
    font-family: 'museo_sans300';
    color: #9daab1;
    transform: translate(9px, -8px);
    display: block;
    background: white;
    width: 58px;
    font-size: 11px;
    padding: 0 3px;
  }
  .input:focus{
    border-color:${Colors.blue1}
  }
  .input::placeholder{
    color: #9daab1;
  }
  .input{
    margin-left: 13px;
    outline: none;
    border: none;
    width: 100%;
    color: black;
    font-size: 15px;
  }
`;

const PigeItCreditCardInput = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItCreditCardInputComponent);

export default PigeItCreditCardInput;
