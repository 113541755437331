/* eslint react/jsx-pascal-case: off */
import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { modalQueue, save_orders, genericSetValue, setupAnimatedLoader } from '../redux/actions';
import { padding, getGoogleAddressComponents, isValidSizeValue } from "../common/functions";
import { Button } from '@material-ui/core';
import { Colors } from "../common/colors";
import CONSTANTS from '../common/constants';
import PigeItSelect from "./PigeItSelect.component";
import IMAGES from '../common/images';
import moment from 'moment';
import PigeItCreditCardInput from './PigeItCreditCardInput.component';
import { get, range } from 'lodash';
import PigeItInput from './PigeItInput.component';
import PigeItCheckbox from './PigeItCheckbox.component';
import PigeItDatePicker from './PigeItDatePicker.component';


import { ZIndices } from "../common/zIndices";
import PigeItInputAddress from './PigeItInputAddress.component';

function PigeItBusinessesAddEditPackageComponent(props) {
  const VALUES = CONSTANTS.MULTI_PACKAGES_VALUES;
  const [pack, setpack] = useState(Object.keys(VALUES).reduce((a, b) => { a[b] = ''; return a; }, {}));
  const [error, seterror] = useState({});
  const dispatch = useDispatch();
  const packRef = useRef();
  const edit = get(props, 'modalConfig.package', false) ? true : false;
  const pad2 = (n)=> (n < 10 ? '0' : '') + n;
  let hours = range(9, 20).map(h => ({ value: pad2(h) + ':00', text: pad2(h) + ':00' }));

  useEffect(() => {
    if (edit) {
      let editPack = get(props, 'modalConfig.package', {});
      let temp = {};
      Object.keys(pack).forEach(key => {
        temp[key] = editPack[VALUES[key]];
      });
      if(temp.time===""){
        temp.time = hours[0].value;
      }
      if (!moment([parseInt(temp.year), parseInt(temp.month) - 1, parseInt(temp.day)]).isValid()) {
        temp.year = moment().year();
        temp.month = moment().month() + 1;
        temp.day = moment().date();
      }
      temp.pickup = temp.pickup === 'pickup';
      temp.residential = get(temp,'residential','').toLowerCase() === 'y';
      setpack(temp);
    } else {
      let year = pack.year;
      let month = pack.month;
      let day = pack.day;
      if (!moment([parseInt(pack.year), parseInt(pack.month) - 1, parseInt(pack.day)]).isValid()) {
        year = moment().year();
        month = moment().month() + 1;
        day = moment().date();
      }
      setpack({ ...pack, time: hours[0].value, year, month, day });
    }
  }, []);
  useEffect(() => {
    packRef.current = pack;
  }, [pack]);
  let address = (pack.street && pack.city && pack.state && pack.postalCode) ? (pack.street + ', ' + pack.city + ', ' + pack.state + ', ' + pack.postalCode + ', USA') : '';
  let date = moment([parseInt(pack.year), parseInt(pack.month) - 1, parseInt(pack.day)]);
  return (
    <PigeItBusinessesAddEditPackageStyle className="login-container" >
      <div className="background-container">
        <div className="content-container">
          <Button className="modal-close-button" onClick={close}>
            <IMAGES.close />
          </Button>
          <div className="header-text">{edit ? "Edit Package" : "Add New Package"}</div>
          <form>
            <div className="display-flex fjc-space-between column-container">
              <div className="flex-divided-2-cols first-col">
                <PigeItInput label="Recipient name" name='' value={pack.name} onChange={setFieldHandler('name')} id='cardHolderName' type='text' className="input-field" error={error.name} />
                <PigeItInput label="Recipient email" name='' value={pack.email} onChange={setFieldHandler('email')} id='cardHolderEmail' type='text' className="input-field" error={error.email} />
                <PigeItInput label="Recipient phone" type='number' name='' value={pack.phone} onChange={setFieldHandler('phone')} id='cardHolderEmail' type='number' className="input-field" error={error.phone} />
                <PigeItInput label="Recipient company" name='' value={pack.company} onChange={setFieldHandler('company')} id='cardHolderEmail' type='text' className="input-field" />
                <PigeItInputAddress label="Recipient Address" name='' passedValue={address} onChange={setAddressHandler()} id='cardHolderEmail' type='text' className="input-field-address" error={error.address} />
                <PigeItInput label="Recipient Address Line 2" name='' value={pack.addressLine2} onChange={setFieldHandler('addressLine2')} id='cardHolderEmail' type='text' className="input-field" />
                <PigeItCheckbox labelText='Address is residential' checked={pack.residential} onClick={(val) => setpack({ ...pack, residential: val })} className="input-field-checkbox" />



              </div>
              <div className="flex-divided-2-cols second-col">
                <div className='dimensions-container display-flex fjc-space-between'>
                  <PigeItInput label="Weight" type='number' value={pack.weight} onChange={setFieldHandler('weight')} id='cardHolderName' className="input-field" error={error.weight} />
                  <PigeItInput label="Height" type='number' value={pack.height} onChange={setFieldHandler('height')} id='cardHolderName' className="input-field" error={error.height} />
                  <PigeItInput label="Width" type='number' value={pack.width} onChange={setFieldHandler('width')} id='cardHolderName' className="input-field" error={error.width} />
                  <PigeItInput label="Length" type='number' value={pack.length} onChange={setFieldHandler('length')} id='cardHolderName' className="input-field" error={error.length} />
                </div>
              {/*  <PigeItCheckbox labelText='Pickup' checked={pack.pickup} onClick={(val) => setpack({ ...pack, pickup: val })} className="input-field-checkbox" />
                <PigeItDatePicker disabled={!get(pack, 'pickup', false)} label='Pickup Date' date={date} onChange={setDateHandler()} className="input-field-date-picker" />
                <PigeItSelect disabled={!get(pack, 'pickup', false)} label='Pickup Time' valueList={hours} className="input-field-select" value={{ text: pack.time, value: pack.time }} onChange={(val) => setpack({ ...pack, time: val.value })} />
                <PigeItInput label="Insurance content" name='company' value={pack.description} onChange={setFieldHandler('description')} id='company' type='text' className="input-field" />
                <PigeItInput label="Insurance value" type='number' name='company' value={pack.insuranceValue} onChange={setFieldHandler('insuranceValue')} id='company' className="input-field" error={error.insuranceValue} />
  */} </div>
            </div>
          </form>
          <Button className="button-save" onClick={savePackage}>{edit ? "Save Package" : "Add Package"}</Button>
        </div>
      </div>
    </PigeItBusinessesAddEditPackageStyle>
  );

  function setFieldHandler(field) {
    return (e) => {
      setpack({ ...packRef.current, [field]: e.target.value });
    }
  }

  function setDateHandler() {
    return (newValue) => {
      setpack({
        ...packRef.current,
        year: moment(newValue).year(),
        month: moment(newValue).month() + 1,
        day: moment(newValue).date(),
      });
    }
  }

  function setAddressHandler() {
    return (val) => {
      let addressParts = getGoogleAddressComponents(val);
      if (addressParts.city && addressParts.state && addressParts.postalCode && addressParts.streetNum && addressParts.street) {
        setpack({
          ...packRef.current,
          city: addressParts.city,
          state: addressParts.state,
          postalCode: addressParts.postalCode,
          street: addressParts.streetNum + ' ' + addressParts.street,
        });
      }
    }
  }

  function savePackage() {
    let err = {};
    Object.entries(packRef.current).forEach(ent => {
      if (['city', 'state', 'street', 'postalCode'].indexOf(ent[0]) !== -1 && !ent[1]) {
        err.address = true;
      }
      if (['name', 'time'].indexOf(ent[0]) !== -1 && !ent[1]) {
        err[ent[0]] = true;
      }
      if (['weight', 'width', 'height', 'length'].indexOf(ent[0]) !== -1 && !isValidSizeValue(ent[1])) {
        err[ent[0]] = true;
      }
      if (ent[0] === 'email' && !CONSTANTS.VALID_EMAIL_REGEX.test(String(ent[1]).toLowerCase())) {
        err[ent[0]] = true;
      }
      if (ent[0] === 'phone' && !CONSTANTS.VALID_PHONE_REGEX.test(String(ent[1]).toLowerCase())) {
        err[ent[0]] = true;
      }
    });
    if (get(packRef, 'current.insuranceValue', '') !== '' && isNaN(get(packRef, 'current.insuranceValue', ''))) {
      err.insuranceValue = true;
    }
    seterror(err);
    if (Object.keys(err).length !== 0) {
      return;
    }

    if (!pack.pickup) {
      pack.time = '';
      pack.date = '';
    }
    if (pack.insuranceValue) {
      pack.insuranceCurrency = 'DOLLAR';
    }
    pack.pickup = pack.pickup ? 'pickup' : 'dropoff';
    pack.residential = pack.residential ? 'y' : 'n';

    let order = {};
    Object.entries(pack).forEach(ent => {
      order[VALUES[ent[0]]] = ent[1];
    });
    if (edit) {
      order.id = get(props, 'modalConfig.package.id', '');
      dispatch(save_orders(get(props, 'orders', []).map(o => o.id === order.id ? order : o)));
    } else {
      let maxId = get(props, 'orders', []).map(o => parseInt(get(o, 'id', 0))).reduce((a, b) => Math.max(a, b), 0);
      order.id = maxId + 1;
      dispatch(save_orders([...get(props, 'orders', []), order]));
    }
    dispatch(genericSetValue({ path: 'ui.business.changed', value: true }));
    close();
  }

  function close() {
    dispatch(modalQueue({ mode: 'delete' }));
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    apiData_getInitialData: state.data.api.getInitialData,
    orders: state.data.ui.business.orders,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  };
};

const PigeItBusinessesAddEditPackageStyle = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  text-align: center;
  overflow: auto;
  .background-container {
    height: 100%;
    width: 100%;
    background-color: #fff;
    margin: 0 auto;
    position: relative;
    padding: 0px;
  }
  .content-container {
    background: white;
    padding-top: 45px;
    padding-right: 65px;
    padding-left: 65px;
  }
  .modal-close-button {
    position: absolute;
    top: 21px;
    right: 26px;
  }
  .header-text {
    font-size: 36px;
    margin-bottom:40px
  }
  .flex-divided-2-cols {
    flex: 0 0 48%;
  }
  .input-field {
    margin: 14px 0;
    flex: 0 0 22%;
  }
  .input-field-checkbox {
    text-align: start;
  }
  .input-field-date-picker {
    margin: 14px 0;
    margin-top: -6px;
  }
  .button-save{
    display: block;
    margin: auto;
    background: ${Colors.orange};
    padding: 10px 2px;
    font-size: 20px;
    border-radius: 5px;
    width: 221px;
    text-align: center;
    cursor: pointer;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: white;
    &:hover{
      background: ${Colors.orangeHover};
    }
  }
  @media only screen and (max-width: 992px) {
    .content-container{
      padding: 43px 21px;
    }
    .column-container{
      flex-direction:column;
    }
  }
`;

const PigeItBusinessesAddEditPackage = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItBusinessesAddEditPackageComponent);


export default PigeItBusinessesAddEditPackage;
