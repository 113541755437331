/* eslint react/jsx-pascal-case: off, react-hooks/exhaustive-deps: off */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect, useDispatch } from "react-redux";
import { Colors } from "../common/colors";
import {
  api_getShippingHistory,
  api_getShippingHistoryMsr,
  modalQueue,
} from "../redux/actions";
import InputSearch from "./InputSearch.component";
import Loader from "./Loader.component";
import { Button } from "@material-ui/core";
import { useTitle } from "hookrouter";
import { get } from "lodash";
import CONSTANTS from "../common/constants";
import moment from "moment";
import {
  showErrorMessage,
  identifyCreditCardType,
  setHeadMetaDescription,
} from "../common/functions";

function PigeItMyAccount_ShippingHistoryComponent(props) {
  const dispatch = useDispatch();
  const [inProgress, setInProgress] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  useTitle("My Account | Shipping History | ShipWinner");
  setHeadMetaDescription(
    `Welcome to Shipping History! Use our Free, Personally Customized Shipping History tool to see all your sent packages' history, and compare your FedEx and UPS Shipping History. Open your Free Shipping Account on ShipWinner to access Shipping History and start Saving on Shipping Costs Now. | ShipWinner.com`
  );

  //mock shipping history
  let packagesList = [];

  if (
    props.apiData_getShippingHistory.length ||
    props.apiData_getShippingHistoryMsr.length
  )
    packagesList = [
      ...props.apiData_getShippingHistory.map((item, i) => {
        return {
          type: "normal",
          packageTrackingNumber: get(
            item,
            "shippingDetails[0].packageTrackingNumber",
            ""
          ),
          amount: parseFloat(item.paymentDetails.amount),
          transactionId: item.transactionId,
          shipperAddress: getFormattedAddress(item.shipperAddress),
          shipperName: item.shipperName,
          recipientName: item.recipientName,
          recipientAddress: getFormattedAddress(item.recipientAddress),
          createTime: moment(item.createTime).format("YYYY/DD/MM"),
          timeAsMillis: moment(item.createTime).valueOf(),
          shippingCompanyId:
            parseInt(item.shippingCompanyId) === 1 ? "FedEx" : "Ups",
          status:
            parseInt(item.cancelled) !== 0 ? "Cancelled" : "Label Created",
        };
      }),
      ...props.apiData_getShippingHistoryMsr.map((item, i) => {
        return {
          type: "businesses",
          packageTrackingNumber: "",
          amount: parseFloat(item.paymentDetails.amount),
          shipperAddress: "",
          shipperName: get(item, "clientDetails.name", ""),
          recipientName: "",
          recipientAddress: "",
          createTime: moment(item.createTime).format("YYYY/DD/MM"),
          timeAsMillis: moment(item.createTime).valueOf(),
          transactionId: item.transactionId,
          shippingCompanyId:
            parseInt(item.shippingCompanyId) === 1 ? "FedEx" : "Ups",
          status:
            parseInt(item.cancelled) !== 0 ? "Cancelled" : "Label Created",
        };
      }),
    ].sort((a, b) => b.timeAsMillis - a.timeAsMillis);

  useEffect(function () {
    setInProgress(true);

    // read the list from the server
    dispatch(
      api_getShippingHistory({
        callback: function () {
          setInProgress(false);
        },
      })
    );
    // read the list from the server
    dispatch(
      api_getShippingHistoryMsr({
        callback: function () {
          setInProgress(false);
        },
      })
    );
  }, []);

  return (
    <PigeItMyAccount_ShippingHistoryStyle className="my-account-personal-info-container">
      <div className="background-container">
        <div className="desktop-component margin-top" />
        <div className="title-and-input-control-container display-flex fjc-space-between">
          <div className="desktop-component title-text">
            Shipping History {inProgress && <Loader imageOnly={true} />}
          </div>
          <div className="search-input-container flex-divided-3-cols">
            <InputSearch
              inputName={"search-shipping-history"}
              inputId={"search-shipping-history"}
              inputPlaceholder={"Search by name"}
              onSearch={onSearch}
            />
          </div>
          <div className="desktop-component place-holder" />
        </div>
        <div className="desktop-component header-control display-flex">
          <div className="from-column header">From</div>
          <div className="to-column header">To</div>
          <div className="date-column">Date</div>
          <div className="price-column">Price</div>
          <div className="shipping-with-column">Shipping With</div>
          <div className="number-column">Number</div>
          <div className="status-column">Status</div>
        </div>
        {packagesList
          .filter(isMatchingFilter)
          .sort((a, b) => new Date(b.createTime) - new Date(a.createTime))
          .map((item, i) => {
            return (
              <Button
                key={i}
                className={
                  "result-item-container " +
                  (item.status === "Label Created"
                    ? "processing-container "
                    : item.status === "Delivered"
                    ? "display-flex fd-row fjc-space-between"
                    : "cancelled-container ")
                }
                onClick={() => showInfo(item, i)}
              >
                <div>
                  {item.type === "businesses" && (
                    <div className="mobile-component businesses-column">
                      <div className="shipwinner">SHIPWINNER</div>
                      <div className="businesses">Businesses</div>
                    </div>
                  )}
                  <div className="mobile-component mobile-background-container display-flex fjc-space-between">
                    <div className="title-column">
                      {item.type !== "businesses" && (
                        <>
                          <div className="title-div">From</div>
                          <div className="title-div">To</div>
                        </>
                      )}
                      <div className="title-div">Date</div>
                      <div className="title-div">Price</div>
                      <div className="title-div">Shipping With</div>
                      <div className="title-div">Number</div>
                      <div className="title-div">Status</div>
                    </div>
                    <div className="value-column">
                      {item.type !== "businesses" && (
                        <>
                          <div className="value-div">
                            {item.shipperName + item.shipperAddress}
                          </div>
                          <div className="value-div">
                            {item.recipientName + item.recipientAddress}
                          </div>
                        </>
                      )}
                      <div className="value-div">{item.createTime} </div>
                      <div className="value-div">
                        {item.amount ? "$" + item.amount : " "}
                      </div>
                      <div className="value-div">{item.shippingCompanyId}</div>
                      <div className="value-div">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={getTrackingNumber(item.packageTrackingNumber)}
                        >
                          {item.packageTrackingNumber}
                        </a>
                      </div>
                      <div
                        className={
                          "value-div status-" +
                          (item.status === "Label Created"
                            ? "green"
                            : item.status === "Delivered"
                            ? "gray"
                            : "red") +
                          "-color"
                        }
                      >
                        {item.status}
                      </div>
                    </div>
                  </div>

                  <div className="desktop-component display-flex row-container">
                    {item.type === "businesses" ? (
                      <div className="businesses-column">
                        <div className="shipwinner">SHIPWINNER</div>
                        <div className="businesses">Businesses</div>
                      </div>
                    ) : (
                      <>
                        <div className="from-column">
                          <div className="name">{item.shipperName} </div>
                          <div className="info state">
                            {item.shipperAddress}
                          </div>
                        </div>
                        <div className="to-column">
                          <div className="name">{item.recipientName} </div>
                          <div className="info state">
                            {item.recipientAddress}
                          </div>
                        </div>
                      </>
                    )}
                    <div className="date-column info">{item.createTime} </div>
                    <div className="price-column info">
                      {item.amount ? "$" + item.amount : " "}
                    </div>
                    <div className="shipping-with-column info">
                      {item.shippingCompanyId}
                    </div>
                    <div className="number-column info number">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={getTrackingNumber(item.packageTrackingNumber)}
                      >
                        {item.packageTrackingNumber}
                      </a>
                    </div>
                    <div
                      className={
                        "status-column status-" +
                        (item.status === "Label Created"
                          ? "green"
                          : item.status === "Delivered"
                          ? "gray"
                          : "red") +
                        "-color"
                      }
                    >
                      {item.status}
                    </div>
                  </div>
                </div>
              </Button>
            );
          })}
      </div>
    </PigeItMyAccount_ShippingHistoryStyle>
  );

  function onSearch(value) {
    setFilterValue(value);
  }

  function showInfo(item, i) {
    let modalConfig = {
      ...(item.type === "businesses"
        ? CONSTANTS.MODALS_CONFIG.SHIPPING_INFO_MSR
        : CONSTANTS.MODALS_CONFIG.SHIPPING_INFO),
      shipment: [
        ...props.apiData_getShippingHistory,
        ...props.apiData_getShippingHistoryMsr,
      ].find((it) => it.transactionId === item.transactionId),
    };
    dispatch(modalQueue({ mode: "insert", modalConfig: modalConfig }));
  }

  function getTrackingNumber(packageTrackingNumber) {
    if (packageTrackingNumber === "") {
      return null;
    }
    if (isNaN(packageTrackingNumber)) {
      return (
        "https://wwwapps.ups.com/tracking/tracking.cgi?tracknum=" +
        packageTrackingNumber
      );
    } else {
      return (
        "https://www.fedex.com/apps/fedextrack/?trackingnumber=" +
        packageTrackingNumber
      );
    }
  }

  function getFormattedAddress(address) {
    const splitedAddress = address.split(",");
    let formattedAddress = "";
    if (splitedAddress && splitedAddress.length && splitedAddress.length > 1) {
      formattedAddress =
        splitedAddress[splitedAddress.length - 2] +
        ", " +
        splitedAddress[splitedAddress.length - 1];
    }
    return formattedAddress;
  }

  function isMatchingFilter(item) {
    if (filterValue === "") {
      return true;
    }
    for (let field of [
      "shipperName",
      "shipperAddress",
      "recipientName",
      "recipientAddress",
      "createTime",
      "amount",
      "shippingCompanyId",
      "packageTrackingNumber",
    ]) {
      if (String(get(item, field, "")).indexOf(filterValue) > -1) {
        return true;
      }
    }
    return false;
  }

  function onInformationClick(item, i) {
    /*  if (itemsClickable) {
        const callback = get(props, 'parentProps.modalConfig.callback', false);
        if (typeof (callback) === 'function') {
          callback(item);
          dispatch(modalQueue({ mode: 'delete' }));
        }
      }*/
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    apiData_getShippingHistory: get(
      state,
      "data.api.getShippingHistory.result.results",
      []
    ),
    apiData_getShippingHistoryMsr: get(
      state,
      "data.api.getShippingHistoryMsr.result.results",
      []
    ),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

const PigeItMyAccount_ShippingHistoryStyle = styled.div`
  .background-container {
    padding: 1px 18px 10px 18px;
    background-color: ${Colors.panelBackground};
  }
  .businesses-column {
    width: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .shipwinner {
      line-height: 20px;
      font-size: 20px;
      font-weight: 700;
      color: #247cbc;
    }
    .businesses {
      line-height: 15px;
      font-size: 13px;
      width: 121px;
      text-align: right;
      transform: translate(3px, -2px);
      color: #fed31d;
    }
  }
  .margin-top {
    height: 40px;
  }
  .place-holder {
    width: 255.5px;
  }
  .row-container {
    width: 800px;
  }
  .header-control {
    width: 800px;
    height: 70px;
    margin-bottom: 10px;
    background-color: #186da7;
    font-family: museo_sans300;
    color: #ffffff;
    font-size: 14px;
    line-height: 70px;
    border-radius: 3px 3px 0 0;
    cursor: pointer;
    text-align: left;
    margin-left: 7px;
  }
  .info {
    font-size: 14px;
    font-family: Museo_Sans500;
    text-align: left;
    color: #323232;
    padding-left: 10px;
  }
  .from-column {
    width: 120px;
    line-height: 17px;
    padding-top: 17px;
    padding-left: 10px;
    &.header {
      padding-top: 26px;
      font-family: Museo_Sans500;
    }
  }
  .to-column {
    width: 120px;
    line-height: 17px;
    padding-top: 17px;
    padding-left: 10px;
    &.header {
      padding-top: 26px;
      font-family: Museo_Sans500;
    }
  }
  .date-column {
    width: 120px;
    font-size: 14px;
    font-family: Museo_Sans500;
    padding-left: 10px;
    text-align: left;
  }
  .price-column {
    width: 60px;
    font-size: 14px;
    padding-left: 10px;
    font-family: Museo_Sans500;
  }
  .shipping-with-column {
    width: 100px;
    font-size: 14px;
    font-family: Museo_Sans500;
    padding-left: 10px;
  }
  .number-column {
    width: 150px;
    font-size: 14px;
    padding-left: 10px;
    font-family: Museo_Sans500;
  }
  .number {
    color: #2699fb;
    text-decoration: underline;
    text-align: left;
  }
  .status-column {
    width: 115px;
    padding-left: 10px;
    text-align: left;
    white-space: nowrap;
  }
  .status-green-color {
    color: #87c604;
    font-size: 14px;
    font-family: Museo_Sans500;
    text-align: left;
  }
  .status-gray-color {
    color: #99a0a5;
    font-size: 14px;
    font-family: Museo_Sans300;
    text-align: left;
  }
  .status-red-color {
    font-size: 14px;
    font-family: Museo_Sans500;
    text-align: left;
    color: #ff7285;
  }
  .name {
    color: #62686d;
    font-size: 14px;
    font-family: Museo_Sans500;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .state {
    padding-left: 0px;
  }
  .title-and-input-control-container {
    margin-bottom: 15px;
  }
  .title-text {
    text-align: left;
    font-family: museo_sans300, sans-serif;
    color: #323232;
    font-size: 30px;
    margin: 15px 30px 0px 2px;
  }
  .search-input-container {
    margin: 16px 0 0 0;
  }
  .result-item-container {
    &.processing-container {
      background-color: #eef7ff;
      border: solid 1px #b5ddff;
    }
    &.cancelled-container {
      border: solid 1px #ff7285;
    }
    cursor: pointer;
    margin-bottom: 9px;
    background-color: ${Colors.white};
    border-radius: 3px 3px 3px 3px;
    width: 800px;
    height: 70px;
    line-height: 70px;
    border: solid 1px rgba(0, 0, 0, 0);
  }

  @media only screen and (max-width: 992px) {
    .result-item-container {
      width: 100%;
      height: unset;
      line-height: unset;
      background: none;
    }

    .search-input-container {
      margin: 16px 0 0 0;
      margin-left: auto;
      margin-right: auto;
      flex: none;
      width: 100%;
    }
    .mobile-background-container {
      margin: 10px 10px 0 0;
      width: 100%;
      background-color: #eef7ff;
    }
    .title-column {
      flex: 30%;
      text-align: left;
      font-size: 14px;
      font-family: Museo_Sans500;
      color: #323232;
    }
    .title-div {
      margin: 21px;
      white-space: nowrap;
    }
    .value-column {
      flex: 60%;
      text-align: left;
      color: #62686d;
      font-size: 14px;
      font-family: Museo_Sans300;
    }
    .value-div {
      margin: 21px;
      white-space: nowrap;
      text-align: left;
    }
  }
`;

const PigeItMyAccount_ShippingHistory = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItMyAccount_ShippingHistoryComponent);

export default PigeItMyAccount_ShippingHistory;
