/* eslint default-case: off, eqeqeq: off, react-hooks/exhaustive-deps: off */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTitle } from 'hookrouter';
import { connect } from 'react-redux';
import Navigation from './Navigation.component';
import { Colors } from '../common/colors';
import MainPageMoreSections from './MainPageMoreSections.component';
import MainPageFooter from "./MainPageFooter.component";
import MainPagePurchaseProgress from './MainPagePurchaseProgress.component';
import IMAGES from '../common/images';
import CONSTANTS from '../common/constants';
import { setHeadMetaDescription, navigateToPath } from '../common/functions';
import { get } from 'lodash';
import MainPageBusinessReviews from './MainPageBusinessReviews.component';

function MainPageComponent(props) {
  let title = '';
  let description = '';
  let header = '';
  let header2 = '';
  let showUpsIcon = false;
  let showFedExIcon = false;
  let moreInfoHeader = '';
  let moreInfoText = '';
  let moreInfoImg = false;
  let moreInfoLinkHref = '';
  let moreInfoLinkText = '';
  const [tabIndexForAll, setTabIndexForAll] = useState(null);

  initializeSEO();
  useTitle(title);
  setHeadMetaDescription(description);

  useEffect(() => {
    if (props.activeModalQueue.length && tabIndexForAll == null) {
      setTabIndexForAll(-1);
    }
    else if (!props.activeModalQueue.length && tabIndexForAll == -1) {
      setTabIndexForAll(null);
    }
  }, [props.activeModalQueue]);

  return (
    <MainPageStyle className="main-page-wrapper hidden-on-fullscreen-modal">
      <div className="main-page-background-container">

        <Navigation passedTabIndex={tabIndexForAll} />

        {props.stage === 1 && (
          <>
            <div className="compare-prices-title text-align-center">{header}</div>
            <div className="compare-prices-title text-align-center meta">{header2}</div>
            <div className="display-flex fjc-space-around companies-conainer">
              {showFedExIcon && <div className="fff-row-nowrap companies-fedex-container">
                <IMAGES.fedex />
              </div>}
              {showUpsIcon && <div className="fff-row-nowrap companies-ups-container">
                <IMAGES.ups />
              </div>}
            </div>
          </>
        )}

        <MainPagePurchaseProgress passedTabIndex={tabIndexForAll} stage={props.stage} from={props.from} to={props.to} packId={get(props, 'pack.id', null)} company={props.company} />
      </div>

      {(!props.mobile || (props.stage !== 3 && props.stage !== 2)) && (

        <>{(moreInfoHeader || moreInfoText || moreInfoImg || moreInfoLinkHref) &&
          <div className='info-container'>
            {moreInfoHeader && <div className='header-container'>
              <h3 className='header'>{moreInfoHeader}</h3>
            </div>}
            {(moreInfoText || moreInfoImg) && <div className='display-flex'>
              <div className={'info' + (moreInfoImg ? ' half' : '')}>{moreInfoText}</div>
              {moreInfoImg && <div className='image-container'>{moreInfoImg}</div>}
            </div>}
            {moreInfoLinkHref && <a className='link' href={moreInfoLinkHref} onClick={(e) => { e.preventDefault(); navigateToPath(moreInfoLinkHref, true) }}>{moreInfoLinkText}</a>}
          </div>}
          <MainPageBusinessReviews />
          <MainPageMoreSections passedTabIndex={tabIndexForAll} />
        </>)}
      <MainPageFooter />


    </MainPageStyle>
  );

  function initializeSEO() {
    if (props.from) {
      //SEO Address
      title = 'ShipWinner.com | The Best Shipping Rates for FedEx & UPS from ' + props.from + ' to ' + props.to + '| Save up to 62% on Shipping Costs!';
      description = 'The Cheapest way to Ship a Package from ' + props.from + ' to ' + props.to + '. Start Saving on Local Shipping and Express Shipping! We offer the Lowest Shipping Rates from FedEx and UPS for a Total Savings of up to 62% | ShipWinner.com';
      header = "Compare Shipping Rates & Ship from " + props.from + " to " + props.to;
      header2 = 'Save Up to 62% on Shipping Costs';
      showUpsIcon = true;
      showFedExIcon = true;
      moreInfoText = `ShipWinner connects you to anyone else in the USA. If you're shipping a package from ${props.from} to ${props.to}, ShipWinner will find you the best rates available from FedEx and UPS. Our user-friendly tools will get you through the process quickly and efficiently. Print your own label today to save up to 62% on shipping costs! Now that package will be in Sunshine State before you know it.`;
      moreInfoHeader = `Ship from ${props.from} to ${props.to}`;
    } else if (props.pack) {
      //SEO packages
      const IMAGEPACK = IMAGES[get(props, 'pack.img.svg', '')];
      title = 'Ship ' + props.company + ' ' + props.pack.title + ' | ShipWinner';
      description = 'Ship ' + props.company + ' ' + props.pack.title + '. ' + props.pack.pageDescription;
      header = 'Ship ' + props.company + ' ' + props.pack.title;
      moreInfoImg = get(props, 'pack.img.svg', false) ? <IMAGEPACK /> : getImgTag(props.pack.img, 'img-view');
      moreInfoHeader = 'Ship ' + props.company + ' ' + props.pack.title;
      moreInfoText = get(get(props, 'pack.content', []).find(content => content.header.indexOf('Description') !== -1), 'para', []).reduce((a, b) => a + '. ' + b);
      moreInfoLinkHref = `package-details/${props.company.toLowerCase()}/${get(props, 'pack.path')}`;
      moreInfoLinkText = 'More Information';
      if (props.company.toLowerCase() === 'ups') {
        showUpsIcon = true;
      } else {
        showFedExIcon = true;
      }
    } else if (props.company) {
      //SEO companies
      header = props.company + " Shipping Cost Calculator.";
      header2 = 'Save Up to 62% on Shipping Costs with ' + props.company;
      if (props.company.toLowerCase() === 'ups') {
        title = 'UPS Shipping Cost Calculator | ShipWinner';
        description = 'ShipWinner.com Save up to 50% on UPS Express Shipping Services with ShipWinner\'s Free UPS Shipping Cost Calculator! Get the Best UPS Shipping Rates | ShipWinner.com';
        showUpsIcon = true;
        moreInfoText = 'The UPS Shipping Cost Calculator instantly gives you the best available price for any package you\'d like to ship in the USA. Due to our high shipping volume, choosing to ship with ShipWinner can save you up to 50% on shipping costs from UPS! Fill in your own box dimensions for a Custom Package, or select \"more\" to choose from UPS\'s available options for extra savings. ';
        moreInfoHeader = 'UPS Shipping Cost Calculator.'
      } else {
        title = 'FedEx Shipping Calculator | ShipWinner';
        description = 'ShipWinner\'s FedEx Shipping Cost Calculator gives you a Quick Quote for any package you\'d like to ship in the USA. Find the Best Rates and Save up to 62% Shipping with ShipWinner!  | ShipWinner.com.';
        showFedExIcon = true;
        moreInfoText = 'The FedEx Shipping Cost Calculator instantly gives you the best available price for any package you\'d like to ship in the USA. Due to our high volume of shipping, choosing to ship with ShipWinner can save you up to 62% on shipping costs from FedEx! Fill in your own box dimensions for a Custom Package, or select "more" to choose from FedEx\'s available options for extra savings.';
        moreInfoHeader = 'FedEx Shipping Cost Calculator.';
      }
    } else {
      //SEO nothing
      title = 'ShipWinner.com | The Best Shipping Rates for FedEx & UPS | Save up to 62% on Shipping Costs!';
      description = 'Save Up to 62% on Shipping Costs | Find the Cheapest Shipping Rates, and Print your Shipping Label in 2 minutes! ShipWinner is your answer to Easy, Efficient Shipping. Our Free Cost Calculator finds you the Best Price, while our Innovative Tools Streamline your Shipping. Open your Free Account Today! ShipWinnner.com.';
      header = "Compare Shipping Rates & Ship Now";
      header2 = 'Save Up to 62% on Shipping Costs';
      showUpsIcon = true;
      showFedExIcon = true;
    }
  }

  function getImgTag(img, className) {
    try {
      let imgName = img.baseUrl.split('/')[img.baseUrl.split('/').length - 1];
      return (
        <img className={className}
          srcSet={img.sizes.map((s, i) => {
            return img.baseUrl + '/' + imgName + '-' + s + '.' + img.format + (i !== img.sizes.length - 1 ? ', ' : '');
          }).reduce((a, b) => a + b, '')}
          src={img.baseUrl + '/' + imgName + '-' + img.sizes[img.sizes.length - 1] + '.' + img.format}
          alt="" />
      );
    } catch (err) {
      return null;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    activeModalQueue: state.data.activeModalQueue,
    mainPageUi: state.data.ui.mainPage,
    purchaseProgressStage: state.data.ui.mainPage.purchaseProgress.stage,
    mobile: state.data.displayMode === 'MOBILE',
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const MainPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainPageComponent);

const MainPageStyle = styled.div`
  .info-container{
    margin: 64px 0;
    .link{
      display: block;
      text-align: center;
      margin: 15px 0;
      font-size: 20px;
    }
    .info{
      text-align: center;
      font-size: 22px;
      color: #848484;
      align-self: center;
      &.half{
        text-align: justify;
      }
    }
    .image-container{
      flex: 0 0 29%;
      text-align: center;
      svg{
        width: 72%;
      }
    }
    .display-flex{
      margin: 0 10%;
      justify-content: center;
    }
    .header-container{ 
      .header{
        font-size: 40px;
        margin: 30px 15%;
        font-weight: 100;
        text-align: center;
      }
    }
  }
  .main-page-background-container {
    background-image: url("${CONSTANTS.PUBLIC_URL}/images/pages/home/header-blue.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: white;
    width: 100%;
    padding-bottom: 50px;
  }
  
  .table-wrapper {
    width: 100%;
  }
  .compare-prices-section {
    width: 100%;
    text-align: center;
  }
  
  .compare-prices-title {
    &.meta{
      padding-top: 13px;
      font-size: 26px;
      font-family: museo_sans300;
    }
    padding-top: 46px;
    font-size: 45px;
  }
  .companies-conainer {
    padding-top: 22px;
    margin: 0 auto;
    max-width: 276px;
    .companies-fedex-container {
      padding-top: 9px;    
      svg {
        width: 127px;
        height: 38px;
      }
    }
    .companies-ups-container {
      svg {
        width: 42px;
        height: 53px;
      }
    }
    .companies-dhl-container {
      padding-top: 20px;
      svg {
        width: 154px;
        height: 23px;
      }
    }
  }
  // mobile
  @media only screen and (max-width: 992px) {
    .info-container{
      .info{
        font-size: 18px;
        margin-bottom: 20px;
        &.half{
          text-align: center;
        }
      }
      .display-flex{
        margin: 30px 10px;
        flex-direction: column;
      }
      .header-container{
        .header{
          font-size: 28px;
          margin: 30px 10px;
        }
      }
    }
    .compare-prices-title.meta{
      padding-top: 3px;
      font-size: 18px;
    }
    .compare-prices-title{
      padding-top: 5px;
      font-size: 170%;
      width: 300px;
      margin: auto;
      margin-bottom: 3%;
    }
    .companies-conainer{
      justify-content: space-around;
      max-width: 300px;
      padding-top:10px;
      .companies-fedex-container{
        padding-top: 4px;
        padding-left: 10px;
        padding-right: 10px;
        svg{
          width: 80px;
          height: 40px;
          margin-left: 15px;
        }
      }
      .companies-ups-container{
        svg{
          width: 53px;
          height: 48px;
        }
      }
      .companies-dhl-container{
        padding-top: 9px;
        svg{
          width: 120px;
          height: 30px;
          margin-top: 0px;
        }
      }
    }
    .main-page-background-container {
      height: 100%;
      padding-bottom: 40px;
      background-image: unset;
      background-color: #03548f;
    }
    .panel-container {
      .flex-divided-4-cols {
        .panel-with-background {
          &.with-right-margin {
            margin-right: 0;
          }
        }
      }
      > .display-flex {
        flex-direction: column;
      }
      .get-your-price-button {
        width: auto;
      }
    }
  }
  .get-your-price-button {
    font-size: 18px;
    border: none;
    color: ${Colors.white};
    width: 350px;
    height: 56px;
    background-color: ${Colors.orange};
  }
  .add-package-button {
    font-size: 16px;
    height: 56px;
    background-color: transparent;
    border: none;
    color: ${Colors.blue1};
    svg {
      padding-right: 10px;
      path{
          fill:${Colors.blue1};
        }
    }
  }
`;

export default MainPage;
