/* eslint no-useless-escape: off */

import apiMocks from "./apiMocks";

const PACKAGE_TYPE_CUSTOM = {
  id: 1,
  customDimensions: true,
  dimensionsWidth: "", // value will be saved in redux
  dimensionsHeight: "", // value will be saved in redux
  dimensionsLength: "", // value will be saved in redux
  baseDescription: "Custom Package",
  dimensionsDescription: "Custom",
  selectionDisplayTitle: "Custom",
  dimensionsUnits: "in",
  checkboxState: false,
  weightRange: ["0", "150"],
};
let path =
  process.env.PUBLIC_URL +
  (process.env.PUBLIC_URL.substring(process.env.PUBLIC_URL.length - 1) !== "/"
    ? "/"
    : "");
const MULTI_PACKAGES_VALUES = {
  name: "recipient name",
  email: "recipient email",
  phone: "recipient phone",
  company: "recipient company",
  city: "recipient city",
  state: "recipient state or province code",
  postalCode: "recipient postal code",
  street: "recipient street address line 1",
  addressLine2: "recipient street address line 2",
  residential: "recipient address is residential (Y/N)",
  pickup: "(dropoff/pickup)",
  year: "courier time - year (format YYYY)",
  month: "courier time - month (format M)",
  day: "courier time - day of month (format D)",
  time: "courier time - time of day (format HH:MM in 24-hours)",
  weight: "package weight",
  height: "package size - height",
  width: "package size - width",
  length: "package size - length",
  description: "package content description",
  insuranceValue: "package insurance value",
  insuranceCurrency: "package insurance currency (DOLLAR or EURO)",
};

const CONSTANTS = {
  STACK_IMAGES: {
    "image-1": {
      baseUrl: path + "images/pages/stock/image-1",
      sizes: ["176w", "320w", "640w", "1024w", "2400w"],
      format: "jpg",
    },
    "image-2": {
      baseUrl: path + "images/pages/stock/image-2",
      sizes: ["176w", "320w", "640w", "1024w", "2400w"],
      format: "jpg",
    },
    "image-3": {
      baseUrl: path + "images/pages/stock/image-3",
      sizes: ["176w", "320w", "640w", "1024w", "2400w"],
      format: "jpg",
    },
    "image-4": {
      baseUrl: path + "images/pages/stock/image-4",
      sizes: ["176w", "320w", "640w", "1024w", "2400w"],
      format: "jpg",
    },
    "image-5": {
      baseUrl: path + "images/pages/stock/image-5",
      sizes: ["176w", "298w", "596w", "954w", "2236w"],
      format: "jpg",
    },
    "image-6": {
      baseUrl: path + "images/pages/stock/image-6",
      sizes: ["176w", "320w", "640w", "1024w", "2400w"],
      format: "jpg",
    },
    "image-7": {
      baseUrl: path + "images/pages/stock/image-7",
      sizes: ["176w", "320w", "640w", "1024w", "2400w"],
      format: "jpg",
    },
    "image-8": {
      baseUrl: path + "images/pages/stock/image-8",
      sizes: ["176w", "320w", "640w", "1024w", "2400w"],
      format: "jpg",
    },
    "image-9": {
      baseUrl: path + "images/pages/stock/image-9",
      sizes: ["176w", "320w", "640w", "925w"],
      format: "jpg",
    },
    "image-10": {
      baseUrl: path + "images/pages/stock/image-10",
      sizes: ["176w", "320w", "640w", "1024w", "2400w"],
      format: "jpg",
    },
  },
  CSV_ROW_LENGTH: 25,
  MULTI_PACKAGES_VALUES,
  CSV_HEADERS: Object.values(MULTI_PACKAGES_VALUES),
  DESKTOP_MIN_WIDTH: 992,
  DISPLAY_MODES: {
    DESKTOP: "DESKTOP",
    MOBILE: "MOBILE",
  },
  MONEY_EXCHANGE_TYPES: [
    { id: "1", text: "$", value: "DOLLAR" },
    { id: "2", text: "€", value: "EURO" },
  ],
  API_ROOT: process.env.REACT_APP_API_ROOT,
  PUBLIC_URL: process.env.PUBLIC_URL,
  SHIPPING_COMPANY_ID_FEDEX: 1,
  SHIPPING_COMPANY_ID_UPS: 2,
  SHIPPING_DROPOFF_TYPE_ID_DROPOFF: 1,
  SHIPPING_DROPOFF_TYPE_ID_COURIER: 2,
  // string values sent to server
  PACKAGE_WEIGHT_UNIT_TYPES: [
    { id: 1, text: "LBS" },
    { id: 2, text: "KILO" },
  ],
  PACKAGE_SIZE_UNIT_TYPES: [
    { id: 1, text: "IN" },
    { id: 2, text: "CM" },
  ],
  ADDRESS_TYPE_ID_BUSINESS: 1,
  ADDRESS_TYPE_ID_RESIDENTIAL: 2,
  ADDRESS_TYPES: [
    { id: 1, text: "Business" },
    { id: 2, text: "Residential" },
    { id: 3, text: "Mixed" },
    { id: 4, text: "Unknown" },
  ],
  COMPANY_TO_ID: {
    fedex: 1,
    ups: 2,
    usps: 3,
  },
  ID_TO_COMPANY: {
    1: "fedex",
    2: "ups",
    3: "usps",
  },
  PAYMENT_TYPE_ID_NEW: 1,
  PAYMENT_TYPE_ID_SAVED: 2,
  MAX_BUSINESS_DAYS: 3,
  // mapping key is day (sunday is 1), value is object with keys min/max (min is earliest pickup hour, max is latest hour, both 24H value)
  PICKUP_HOURS_RANGES: {
    1: { min: 9, max: 13 },
    2: { min: 9, max: 14 },
    3: { min: 9, max: 15 },
    4: { min: 9, max: 16 },
    5: { min: 9, max: 17 },
    6: { min: 9, max: 18 },
    7: { min: 9, max: 19 },
  },
  ENVELOPE_TYPES: [],
  PACKAGE_TYPE_CUSTOM: JSON.parse(JSON.stringify(PACKAGE_TYPE_CUSTOM)),
  PACKAGES_TYPES: [
    /*{
      category: 'Envelope/Letter',
      strips: [
        {
          id: '1',
          title: 'FEDEX Envelope',
          baseDescription: 'Shipments weighing 8 oz. or less rated at the FedEx Envelope rate; shipments exceeding 8 oz. rated at next whole pound. To maintain the structural integrity of FedEx Envelopes, ',
          items: [
            {
              id: 5,
              weightRange: ['0','1'],
              baseDescription: 'Fedex Envelope',
              dimensionsDescription: 'Length (9-1/2"),Width (12-1/2")',
              selectionDisplayTitle: 'Fedex Envelope',
              selectionDisplayDimensions: '9-1/2" x 12-1/2"',
              dimensionsWidth: '1',
              dimensionsHeight: '1',
              dimensionsLength: '1',
              dimensionsUnits: 'in',
              checkboxState: false
            },
          ],
          checkboxState: false
        },
        {
          id: '2',
          title: 'UPS Letter',
          baseDescription: 'Strong envelope for documents up to legal size',
          items: [
            {
              id: 13,
              weightRange: ['0','70'],
              baseDescription: 'UPS Letter',
              dimensionsDescription: 'Length (12.5-15"), Width (9.5")',
              selectionDisplayTitle: 'UPS Letter',
              selectionDisplayDimensions: ' 12.5-15" x 9.5"',
              dimensionsWidth: '1',
              dimensionsHeight: '1',
              dimensionsLength: '1',
              dimensionsUnits: 'in',
              checkboxState: false
            },
          ],
          checkboxState: false
        }
      ]
    },*/
    {
      category: "Pak",
      strips: [
        /*{
          id: '1',
          title: 'FEDEX Pak',
          baseDescription: 'For larger documents or other compact items.',

          items: [
            {
              id: 9,
              weightRange:['0','20'],
              baseDescription: 'Fedex Pak',
              dimensionsDescription: 'Length (10-1/4"), Width (12-3/4")',
              selectionDisplayTitle: 'Fedex Pak',
              selectionDisplayDimensions: '10-1/4” x 12-3/4”',
              dimensionsWidth: '12-3/4',
              dimensionsHeight: '0',
              dimensionsLength: '10-1/4',
              dimensionsUnits: 'in',
              checkboxState: false
            }
          ],
          checkboxState: false
        },*/
        {
          id: "1",
          title: "UPS Pak",
          baseDescription:
            "A UPS Pak is a cardboard box designed to wrap around your documents and electronic media for secure and safe fit.",
          items: [
            {
              id: 15,
              weightRange: ["0", "150"],
              baseDescription: "Ups Pak",
              dimensionsDescription: 'Length (17"), Width (13"),Height(1")',
              selectionDisplayTitle: "Ups Pak",
              selectionDisplayDimensions: '17" x 13" x 1"',
              dimensionsWidth: "13",
              dimensionsHeight: "1",
              dimensionsLength: "17",
              dimensionsUnits: "in",
              checkboxState: false,
            },
          ],
          checkboxState: false,
        },
      ],
    },
    {
      category: "Box",
      strips: [
        /*{
          id: '1',
          title: 'FEDEX Box Small-Medium-Large',
          baseDescription: 'The Fedex  Box is suited for a wide range of items, including  documents computer printouts and electronic parts.',
          items: [
            {
              id: 10,
              weightRange:['0','20'],
              baseDescription: 'Box - Small',
              dimensionsDescription: 'Length (10-7/8"), Width ( 1-1/2"),Height(12-3/8")',
              selectionDisplayTitle: 'Fedex Box - Small',
              selectionDisplayDimensions: ' 10-7/8" x 1-1/2" x 12-3/8"',
              dimensionsWidth: '1-1/2',
              dimensionsHeight: '12-3/8',
              dimensionsLength: '10-7/8',
              dimensionsUnits: 'in'
            },
            {
              id: 8,
              weightRange:['0','20'],
              baseDescription: 'Box - Medium',
              dimensionsDescription: 'Length (11-1/2"), Width 2-3/8"),Height(13-1/4")',
              selectionDisplayTitle: 'Fedex Box - Medium',
              selectionDisplayDimensions: '11-1/2" x 2-3/8" x 13-1/4"',
              dimensionsWidth: ' 2-3/8',
              dimensionsHeight: '13-1/4',
              dimensionsLength: '11-1/2',
              dimensionsUnits: 'in'
            },{
              id: 7,
              weightRange:['0','20'],
              baseDescription: 'Box - Large',
              dimensionsDescription: 'Length (12-3/8"), Width (3"),  Height(17-1/2")',
              selectionDisplayTitle: 'Fedex Box - Large',
              selectionDisplayDimensions: '12-3/8" x 3" x 17-1/2"',
              dimensionsWidth: '3',
              dimensionsHeight: '17-1/2',
              dimensionsLength: '12-3/8',
              dimensionsUnits: 'in'
            },{
              id: 6,
              weightRange:['0','20'],
              baseDescription: 'Box -Extra Large',
              dimensionsDescription: 'Length (11-7/8"), Width (10-13/16"),Height(11-1/16")',
              selectionDisplayTitle: 'Fedex Box -Extra Large',
              selectionDisplayDimensions: ' 11-7/8" x 10-13/16" x 11-1/16"',
              dimensionsWidth: '10-13/16',
              dimensionsHeight: '11-1/16',
              dimensionsLength: '11-7/8',
              dimensionsUnits: 'in'
            }
          ],

          checkboxState: false
        },*/
        {
          id: "2",
          title: "UPS Express Box Small-Medium-Large",
          baseDescription:
            "The UPS Express Box is suited for a wide range of items, including  documents computer printouts and electronic parts.",
          items: [
            {
              id: 20,
              weightRange: ["0", "150"],
              baseDescription: "Express Box - Small",
              dimensionsDescription: 'Length (13"), Width (11"),Height(2")',
              selectionDisplayTitle: "Ups Express - Small",
              selectionDisplayDimensions: '13" x 11" x 2"',
              dimensionsWidth: "11",
              dimensionsHeight: "2",
              dimensionsLength: "13",
              dimensionsUnits: "in",
            },
            {
              id: 21,
              weightRange: ["0", "150"],
              baseDescription: "Express Box - Medium",
              dimensionsDescription: 'Length (16"), Width (11"),Height(3")',
              selectionDisplayTitle: "Ups Express Box - Medium",
              selectionDisplayDimensions: '16" x 11" x 3"',
              dimensionsWidth: "11",
              dimensionsHeight: " 3",
              dimensionsLength: "16",
              dimensionsUnits: "in",
            },
            {
              id: 22,
              weightRange: ["0", "150"],
              baseDescription: "Express Box- Large",
              dimensionsDescription: 'Length (18" ), Width (13"),Height( 3")',
              selectionDisplayTitle: "Ups Express Box- Large",
              selectionDisplayDimensions: '18" x 13" x 3"',
              dimensionsWidth: "13",
              dimensionsHeight: "3",
              dimensionsLength: "18",
              dimensionsUnits: "in",
            },
          ],

          checkboxState: false,
        } /*,
        {
          id: '3',
          title: 'FEDEX  10kg Box and FEDEX  25kg Box',
          baseDescription:'Strong boxes to help your shipments arrive in good shape.',
          items: [
            {
              id: 2,
              weightRange:['0','22'],
              baseDescription: 'Box -10kg',
              dimensionsDescription: 'Length (15-13/16"), Width (12-15/16"),Height(10-3/16")',
              selectionDisplayTitle: 'FedEx 10kg Box',
              selectionDisplayDimensions: '15-13/16" x 12-15/16" x 10-3/16"',
              dimensionsWidth: '12-15/16',
              dimensionsHeight: '10-3/16',
              dimensionsLength: '15-13/16',
              dimensionsUnits: 'in'
            },{
              id: 3,
              weightRange:['0','56'],
              baseDescription: 'Box -25kg',
              dimensionsDescription: 'Length (21-9/16"), Width (16-9/16"),Height(13-3/16")',
              selectionDisplayTitle: 'FedEx 25kg Box',
              selectionDisplayDimensions: '21-9/16" x 16-9/16" x 13-3/16"',
              dimensionsWidth: '16-9/16',
              dimensionsHeight: '13-3/16"',
              dimensionsLength: '21-9/16',
              dimensionsUnits: 'in'
            }
          ],
          checkboxState: false
        },
        {
          id: '4',
          title: 'UPS  10kg Box and UPS  25kg Box',
          baseDescription:'Strong boxes to help your shipments arrive in good shape.',
          items: [
            {
              id: 18,
              weightRange:['0','22'],
              baseDescription: 'Box -10kg',
              dimensionsDescription: 'Length (16.5"), Width (13.25"),Height(10.75")',
              selectionDisplayTitle: 'Ups 10kg Box',
              selectionDisplayDimensions: '16.5" x 13.25"x 10.75"',
              dimensionsWidth: '13.25',
              dimensionsHeight: '10.75',
              dimensionsLength: '16.5',
              dimensionsUnits: 'in'
            },{
              id: 17,
              weightRange:['0','55'],
              baseDescription: 'Box -25kg',
              dimensionsDescription: 'Length (19.75"), Width (17.75"),Height(13.25")',
              selectionDisplayTitle: 'Ups 25kg Box',
              selectionDisplayDimensions: '19.75" x 17.75"x 13.25"',
              dimensionsWidth: '17.75',
              dimensionsHeight: ' 13.25',
              dimensionsLength: '19.75',
              dimensionsUnits: 'in'
            }
          ],
          checkboxState: false
        }
      */,
      ],
    },
    {
      category: "Tube",
      strips: [
        {
          id: "1",
          title: "UPS Tube",
          baseDescription:
            "Ideal for documents which must be rolled rather than folded, such as blueprints, charts, maps, drawings, and posters. ",
          items: [
            {
              id: 14,
              weightRange: ["0", "150"],
              baseDescription: "Ups tube",
              dimensionsDescription: 'Length (38"), Width (6"),Height(6")',
              selectionDisplayTitle: "Ups Tube",
              selectionDisplayDimensions: '38" x 6" x 6"',
              dimensionsWidth: "6",
              dimensionsHeight: "6",
              dimensionsLength: "38",
              dimensionsUnits: "in",
            },
          ],
          checkboxState: false,
        },
        {
          id: "1",
          title: "FEDEX Tube",
          baseDescription:
            "Self-sealing tube thats ideal for drawings, blueprints, charts, photographs, fabric samples and any other items which are better rolled than folded.",
          items: [
            {
              id: 11,
              weightRange: ["0", "20"],
              baseDescription: "Fedex tube",
              dimensionsDescription: 'Length (6"), Width (6"),Height(38")',
              selectionDisplayTitle: "Fedex Tube",
              selectionDisplayDimensions: ' 6" x 6" x 38"',
              dimensionsWidth: "6",
              dimensionsHeight: " 38",
              dimensionsLength: "6",
              dimensionsUnits: "in",
            },
          ],
          checkboxState: false,
        },
      ],
    },
    {
      category: "Custom",
      title: "Custom Package",
      customDimensions: true,
      item: {
        ...PACKAGE_TYPE_CUSTOM,
      },
    },
  ],

  SELECT_PACKAGE_ENVELOPE_TAB_INDEX: 0,
  SELECT_PACKAGE_BOX_TAB_INDEX: 3,
  MY_ACCOUNT_PERSONAL_INFO_TAB: "personal-info",
  MY_ACCOUNT_ADDRESS_BOOK_TAB: "address-book",
  MY_ACCOUNT_PACKAGES_LIST_TAB: "packages-list",
  MY_ACCOUNT_CREDIT_CARDS_TAB: "credit-cards",
  MY_ACCOUNT_SHIPPING_HISTORY_TAB: "shipping-history",
  MY_ACCOUNT_SHIPPING_STATISTICS_TAB: "shipping-statistics",
  MODALS_CONFIG: {
    LOGIN: {
      componentName: "Login",
      isVerticallyCentered: true,
      isFullScreen: false,
      height: "540px",
      width: "540px",
      closeOnClickOutside: true,
      closeOnPressingEsc: true,
    },
    VIDEO: {
      componentName: "Video",
      isVerticallyCentered: true,
      isFullScreen: false,
      height: "505px",
      width: "898px",
      closeOnClickOutside: true,
      closeOnPressingEsc: true,
    },
    CREDIT_CARDS_ADD_EDIT: {
      componentName: "CreditCardsAddEdit",
      isVerticallyCentered: true,
      isFullScreen: false,
      height: "490px",
      width: "800px",
      closeOnClickOutside: true,
      closeOnPressingEsc: true,
    },
    REGISTER: {
      componentName: "Register",
      isVerticallyCentered: true,
      isFullScreen: false,
      height: "540px",
      width: "540px",
      closeOnClickOutside: true,
      closeOnPressingEsc: true,
    },
    MY_ACCOUNT: {
      componentName: "MyAccount",
      isFullScreen: true,
      height: "100%",
      width: "100%",
      initialTab: "personal-info", // setup by one of the constants
      closeOnPressingEsc: true,
    },
    ADDRESS_BOOK_ADD_EDIT: {
      componentName: "AddressBookAddEdit",
      isFullScreen: false,
      height: "612px",
      width: "850px",
      closeOnClickOutside: true,
      closeOnPressingEsc: true,
    },
    PACKAGE_TYPE_SELECT_PACKAGE: {
      componentName: "PackageTypeSelectPackage",
      isFullScreen: true,
      height: "100%",
      width: "100%",
      closeOnPressingEsc: true,
    },
    PACKAGES_ADD_EDIT: {
      componentName: "PackageAddAdit",
      isFullScreen: true,
      height: "100%",
      width: "100%",
      closeOnPressingEsc: true,
    },
    ADD_EDIT_PACKAGE: {
      componentName: "AddAditPackage",
      isFullScreen: true,
      height: "100%",
      width: "100%",
      closeOnPressingEsc: true,
    },
    THANK_YOU: {
      componentName: "ThankYou",
      isFullScreen: true,
      height: "100%",
      width: "100%",
      closeOnPressingEsc: true,
    },
    THANK_YOU_SHOW_PACKAGES: {
      componentName: "ThankYouShowPackages",
      isFullScreen: false,
      height: "540px",
      width: "440px",
      closeOnClickOutside: true,
      closeOnPressingEsc: true,
    },
    CHOOSE_PICK_UP_TIME: {
      componentName: "ChoosePickUpTime",
      isFullScreen: false,
      height: "420px",
      width: "540px",
      closeOnClickOutside: true,
      closeOnPressingEsc: true,
    },
    MESSAGE: {
      componentName: "Message",
      isFullScreen: false,
      height: "280px",
      width: "540px",
      closeOnClickOutside: true,
      closeOnPressingEsc: true,
      notFullScreenMobile: true,
    },
    SAVE_MAPPING: {
      componentName: "SaveMapping",
      isFullScreen: false,
      height: "280px",
      width: "540px",
      closeOnClickOutside: true,
      closeOnPressingEsc: true,
      notFullScreenMobile: true,
    },
    UPLOAD_CSV: {
      componentName: "UploadCSV",
      isFullScreen: false,
      height: "434px",
      width: "640px",
      closeOnClickOutside: true,
      closeOnPressingEsc: true,
      notFullScreenMobile: true,
    },
    BUSINESSES_ADD_EDIT_PACKAGE: {
      componentName: "BusinessesAddEditPackage",
      isVerticallyCentered: true,
      isFullScreen: true,
      height: "100%",
      width: "100%",
      closeOnClickOutside: true,
      closeOnPressingEsc: true,
    },
    ACTIVATE: {
      componentName: "Activate",
      isFullScreen: false,
      height: "280px",
      width: "540px",
      closeOnClickOutside: true,
      closeOnPressingEsc: true,
    },
    FORGOT_PASSWORD_ENTER_EMAIL: {
      componentName: "ForgotPasswordEnterEmail",
      isFullScreen: false,
      height: "350px",
      width: "540px",
      closeOnClickOutside: true,
      closeOnPressingEsc: true,
    },
    ENTER_NEW_PASSWORD: {
      componentName: "EnterNewPassword",
      isFullScreen: false,
      height: "350px",
      width: "540px",
      closeOnClickOutside: true,
      closeOnPressingEsc: true,
    },
    CARDHOLDER_DETAILS: {
      componentName: "CardHoldersDetails",
      isFullScreen: false,
      height: "560px",
      width: "540px",
      closeOnClickOutside: true,
      closeOnPressingEsc: true,
    },
    PACKAGES_MORE_OPTIONS: {
      componentName: "PackageMoreOptions",
      isFullScreen: true,
      height: "100%",
      width: "100%",
      closeOnPressingEsc: true,
    },
    SHIPPING_INFO: {
      componentName: "ShippingInfo",
      isVerticallyCentered: true,
      isFullScreen: false,
      height: "100%",
      width: "100%",
      closeOnClickOutside: true,
      closeOnPressingEsc: true,
    },
    SHIPPING_INFO_MSR: {
      componentName: "ShippingInfoMsr",
      isVerticallyCentered: true,
      isFullScreen: false,
      height: "100%",
      width: "100%",
      closeOnClickOutside: true,
      closeOnPressingEsc: true,
    },
  },
  VALID_EMAIL_REGEX:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  VALID_PHONE_REGEX: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  API_MOCKS: apiMocks,
  DEFAULT_ERROR_MESSAGE: "Something went wrong please try again later.",
  ADDRESS_ERROR_CODES_RANGE: [], // setup next via errorStringToCode method
  USER_LOGGED_IN_ALREADY_CODE: "", // setup next via errorStringToCode method
  ERROR_MESSAGES: {
    0: "DATABASE_ERRORS_OK",
    1: "DATABASE_ERRORS_USER_EXISTS_AND_ACTIVATED",
    2: "DATABASE_ERRORS_USER_EXISTS_AND_WAITING_CONFIRMATION",
    3: "DATABASE_ERRORS_USER_NOT_FOUND",
    4: "DATABASE_ERRORS_PASSWORD_UPDATE_REQUIRED",
    5: "DATABASE_ERRORS_USER_INACTIVE",
    6: "DATABASE_ERRORS_INVALID_RESET_KEY",
    7: "DATABASE_ERRORS_RESET_KEY_EXPIRED",
    8: "DATABASE_ERRORS_PASSWORD_MISMATCH",
    9: "DATABASE_ERRORS_PASSWORD_MISMATCH_TOO_MANY_TIMES",
    10: "DATABASE_ERRORS_NEW_PASSWORD_SAME_AS_THE_OLD",
    11: "DATABASE_ERRORS_ACTIVATION_MISMATCH",
    12: "DATABASE_ERRORS_INSERT_FAILURE",
    13: "DATABASE_ERRORS_UPDATE_FAILURE",
    14: "DATABASE_ERRORS_DELETE_FAILURE",
    15: "DATABASE_ERRORS_PAYMENT_EXISTS",
    16: "DATABASE_ERRORS_PAYMENT_NOT_FOUND",
    17: "DATABASE_ERRORS_TRANSACTION_NOT_FOUND",
    18: "DATABASE_ERRORS_TRANSACTION_WITHOUT_COMPLETED_SHIPPING_DETAILS",
    19: "DATABASE_ERRORS_TRANSACTION_WITHOUT_VERIFIED_SHIPPING_DETAILS",
    20: "DATABASE_ERRORS_ADDRESS_BOOK_RECORD_NOT_FOUND",
    21: "DATABASE_ERRORS_PACKAGE_RECORD_NOT_FOUND",
    22: "DATABASE_ERRORS_COMPLETED_ALREADY",
    23: "DATABASE_ERRORS_CANCELLED_ALREADY",
    24: "USER_LOGGED_IN_ALREADY",
    25: "INVALID_USER",
    26: "API_INPUT_INVALID",
    27: "API_INVALID_CAPTCHA",
    28: "API_INPUT_INVALID_SHIPPING_COMPANY_ID",
    29: "API_INPUT_INVALID_SHIPPING_TYPE_ID",
    30: "API_INPUT_INVALID_SHIPPING_DROPOFF_TYPE_ID",
    31: "API_NOT_FOR_IDENTIFIED_USER",
    32: "API_ERROR_FAILED_TO_FIND_TRACKING_NUMBER",
    33: "API_ERROR_SHIPMENT_CANCELLED_ALREADY",
    34: "API_ERROR_PICKUP_CANCELLED_ALREADY",
    35: "GOOGLE_MAPS_ERRORS_FAILURE",
    36: "GOOGLE_MAPS_ERRORS_INVALID_RECIPIENT_ADDRESS",
    37: "GOOGLE_MAPS_ERRORS_INVALID_SENDER_ADDRESS",
    38: "GOOGLE_MAPS_ERRORS_INVALID_SENDER_AND_RECIPIENT_ADDRESS",
    39: "GOOGLE_MAPS_ERRORS_MISSING_MANDATORY_FIELDS",
    40: "GOOGLE_RECAPTCHA_FAILURE",
    41: "FEDEX_VALIDATE_ADDRESS_RESULT_RESIDENTIAL",
    42: "FEDEX_VALIDATE_ADDRESS_RESULT_BUSINESS",
    43: "FEDEX_VALIDATE_ADDRESS_RESULT_MIXED",
    44: "FEDEX_VALIDATE_ADDRESS_RESULT_MULTIPLE",
    45: "FEDEX_VALIDATE_ADDRESS_RESULT_OTHER",
    46: "FEDEX_VALIDATE_ADDRESS_ERRORS_CLIENT_SETUP_ERROR",
    47: "FEDEX_VALIDATE_ADDRESS_ERRORS_REQUEST_SETUP_ERROR",
    48: "FEDEX_VALIDATE_ADDRESS_ERRORS_RESPONSE_ERROR",
    49: "FEDEX_RATES_ERRORS_CLIENT_SETUP_ERROR",
    50: "FEDEX_RATES_ERRORS_REQUEST_SETUP_ERROR",
    51: "FEDEX_RATES_ERRORS_RESPONSE_ERROR",
    52: "FEDEX_SHIP_ERRORS_CLIENT_SETUP_ERROR",
    53: "FEDEX_SHIP_ERRORS_REQUEST_SETUP_ERROR",
    54: "FEDEX_SHIP_ERRORS_RESPONSE_ERROR",
    55: "FEDEX_PICKUP_ERRORS_CLIENT_SETUP_ERROR",
    56: "FEDEX_PICKUP_ERRORS_REQUEST_SETUP_ERROR",
    57: "FEDEX_PICKUP_ERRORS_RESPONSE_ERROR",
    58: "FEDEX_CANCEL_SHIP_ERRORS_CLIENT_SETUP_ERROR",
    59: "FEDEX_CANCEL_SHIP_ERRORS_REQUEST_SETUP_ERROR",
    60: "FEDEX_CANCEL_SHIP_ERRORS_RESPONSE_ERROR",
    61: "FEDEX_CANCEL_SHIP_ERRORS_DID_NOT_CANCEL_ERROR",
    62: "FEDEX_CANCEL_PICKUP_ERRORS_CLIENT_SETUP_ERROR",
    63: "FEDEX_CANCEL_PICKUP_ERRORS_REQUEST_SETUP_ERROR",
    64: "FEDEX_CANCEL_PICKUP_ERRORS_RESPONSE_ERROR",
    65: "FEDEX_CANCEL_PICKUP_ERRORS_DID_NOT_CANCEL_ERROR",
    66: "FEDEX_TRACK_ERRORS_CLIENT_SETUP_ERROR",
    67: "FEDEX_TRACK_ERRORS_REQUEST_SETUP_ERROR",
    68: "FEDEX_TRACK_ERRORS_RESPONSE_ERROR",
    69: "UPS_RATES_ERRORS_CLIENT_SETUP_ERROR",
    70: "UPS_RATES_ERRORS_REQUEST_SETUP_ERROR",
    71: "UPS_RATES_ERRORS_RESPONSE_ERROR",
    72: "UPS_PICKUP_RATES_ERRORS_CLIENT_SETUP_ERROR",
    73: "UPS_PICKUP_RATES_ERRORS_REQUEST_SETUP_ERROR",
    74: "UPS_PICKUP_RATES_ERRORS_RESPONSE_ERROR",
    75: "UPS_SHIP_ERRORS_CLIENT_SETUP_ERROR",
    76: "UPS_SHIP_ERRORS_REQUEST_SETUP_ERROR",
    77: "UPS_SHIP_ERRORS_RESPONSE_ERROR",
    78: "UPS_PICKUP_ERRORS_CLIENT_SETUP_ERROR",
    79: "UPS_PICKUP_ERRORS_REQUEST_SETUP_ERROR",
    80: "UPS_PICKUP_ERRORS_RESPONSE_ERROR",
    81: "UPS_CANCEL_SHIP_ERRORS_CLIENT_SETUP_ERROR",
    82: "UPS_CANCEL_SHIP_ERRORS_REQUEST_SETUP_ERROR",
    83: "UPS_CANCEL_SHIP_ERRORS_RESPONSE_ERROR",
    84: "UPS_CANCEL_PICKUP_ERRORS_CLIENT_SETUP_ERROR",
    85: "UPS_CANCEL_PICKUP_ERRORS_REQUEST_SETUP_ERROR",
    86: "UPS_CANCEL_PICKUP_ERRORS_RESPONSE_ERROR",
    87: "UPS_TRACK_ERRORS_CLIENT_SETUP_ERROR",
    88: "UPS_TRACK_ERRORS_REQUEST_SETUP_ERROR",
    89: "UPS_TRACK_ERRORS_RESPONSE_ERROR",
    90: "SEND_MAIL_OK",
    91: "SEND_MAIL_ERROR",
    92: "BLUESNAP_ERROR",
    93: "BLUESNAP_MARSHAL_DATA_ERROR",
    94: "PAYMENT_VERIFICATION_OK",
    95: "PAYMENT_VERIFICATION_REQUEST_ERROR",
    96: "PAYMENT_VERIFICATION_GET_EXISTING_CARDS_ERROR",
    97: "PAYMENT_VERIFICATION_ERROR",
    98: "PAYMENT_VERIFICATION_FAILED_INVALID_AMOUNT",
    99: "PAYMENT_VERIFICATION_FAILED_INVALID_CREDIT_CARD_NUMBER",
    100: "PAYMENT_VERIFICATION_FAILED_EXPIRED_CREDIT_CARD",
    101: "PAYMENT_VERIFICATION_FAILED_INVALID_EXPIRY_OR_CVV",
    102: "PAYMENT_TRANSACTION_ERROR",
    103: "PAYMENT_TRANSACTION_COMPLETED_ALREADY",
    104: "REFUND_TRANSACTION_OK",
    105: "REFUND_TRANSACTION_ERR0R",
    106: "REFUND_TRANSACTION_REFUNDED_ALREADY",
    107: "REFUND_TRANSACTION_INVALID_AMOUNT",
    108: "SAVE_CREDIT_CARD_ERROR",
    109: "DELETE_CREDIT_CARD_ERROR_NO_SAVED_CARD",
    110: "DELETE_CREDIT_CARD_ERROR",
    111: "VERIFY_PAYMENT_NO_PRICE_FOR_SHIPPING_TYPE",
    112: "VERIFY_PAYMENT_ERROR_SAVING_VERIFIED_SHIPPING_DETAILS",
    113: "VERIFY_PAYMENT_ERROR_SAVING_VERIFIED_PAYMENT_DETAILS",
    114: "VERIFY_PAYMENT_SAVE_SHIPPING_DETAILS_ERROR",
    115: "PAY_INVALID_TRANSACTION",
    116: "PAY_TRANSACTION_DETAILS_MISMATCH",
    117: "PAY_TRANSACTION_NOT_FULLY_VERIFIED",
    118: "PAY_TRANSACTION_HALF_COMPLETED",
    119: "PAY_TRANSACTION_ALREADY_COMPLETED",
    120: "UTILS_CREATE_FEDEX_REQUEST_ERROR",
    121: "UTILS_CREATE_UPS_REQUEST_ERROR",
    122: "FEDEX_TEMPORARILY_DISABLED",
  },
  DEBUGGING: false,
};

// setup specific codes for custom usage
CONSTANTS.USER_LOGGED_IN_ALREADY_CODE = errorStringToCode(
  "USER_LOGGED_IN_ALREADY"
);
CONSTANTS.ADDRESS_ERROR_CODES_RANGE = [
  parseInt(errorStringToCode("GOOGLE_MAPS_ERRORS_FAILURE")),
  parseInt(errorStringToCode("FEDEX_VALIDATE_ADDRESS_ERRORS_RESPONSE_ERROR")),
];

function errorStringToCode(str) {
  for (const numericKey in CONSTANTS.ERROR_MESSAGES) {
    const stringValue = CONSTANTS.ERROR_MESSAGES[numericKey];
    if (stringValue == str) {
      return numericKey;
    }
  }
  return "-1";
}

// custom messages
const languageTranslations = {
  DATABASE_ERRORS_USER_EXISTS_AND_ACTIVATED:
    "Account already activated, please sign in",
  DATABASE_ERRORS_USER_EXISTS_AND_WAITING_CONFIRMATION:
    "User exists waiting for confirmation please check your email",
  DATABASE_ERRORS_USER_NOT_FOUND: "Sorry user not found",
  DATABASE_ERRORS_PASSWORD_UPDATE_REQUIRED: "Password update required",
  DATABASE_ERRORS_USER_INACTIVE: "This user is inactive",
  DATABASE_ERRORS_INVALID_RESET_KEY: "Invalid link, please try again",
  DATABASE_ERRORS_RESET_KEY_EXPIRED: "Link expired",
  DATABASE_ERRORS_PASSWORD_MISMATCH: "Username and password do not match",
  DATABASE_ERRORS_PASSWORD_MISMATCH_TOO_MANY_TIMES:
    "Password mismatch too manyy times",
  DATABASE_ERRORS_NEW_PASSWORD_SAME_AS_THE_OLD:
    "Your new password is same as the current password, please try another password",
  DATABASE_ERRORS_ACTIVATION_MISMATCH: "Activation mismatch",
  DATABASE_ERRORS_PAYMENT_EXISTS: "Credit card already exists",
  DATABASE_ERRORS_PAYMENT_NOT_FOUND:
    "Credit card not found, please enter Credit card",
  DATABASE_ERRORS_ADDRESS_BOOK_RECORD_NOT_FOUND: "Address book is not found",
  DATABASE_ERRORS_PACKAGE_RECORD_NOT_FOUND: "Package list is not found",
  DATABASE_ERRORS_COMPLETED_ALREADY: "Order already completed",
  USER_LOGGED_IN_ALREADY: "User is logged in already",
  INVALID_USER: "Invalid username, please enter a valid username",
  API_INVALID_CAPTCHA: "Please try again",
  GOOGLE_MAPS_ERRORS_FAILURE: "Please enter a valid address",
  GOOGLE_MAPS_ERRORS_MISSING_MANDATORY_FIELDS: "Please enter a valid address",
  FEDEX_VALIDATE_ADDRESS_RESULT_RESIDENTIAL: "Please enter a valid address",
  FEDEX_VALIDATE_ADDRESS_RESULT_MULTIPLE: "Please enter a valid address",
  FEDEX_VALIDATE_ADDRESS_RESULT_OTHER: "Please enter a valid address",
  FEDEX_VALIDATE_ADDRESS_ERRORS_CLIENT_SETUP_ERROR:
    "Please enter a valid address",
  FEDEX_VALIDATE_ADDRESS_ERRORS_REQUEST_SETUP_ERROR:
    "Please enter a valid address",
  FEDEX_VALIDATE_ADDRESS_ERRORS_RESPONSE_ERROR: "Please enter a valid address",
  SEND_MAIL_ERROR: "There was an error sending the email",
  PAYMENT_VERIFICATION_FAILED_INVALID_AMOUNT: "please enter a correct amount",
  PAYMENT_VERIFICATION_FAILED_INVALID_CREDIT_CARD_NUMBER:
    "Please enter a valid credit card number ",
  PAYMENT_VERIFICATION_FAILED_EXPIRED_CREDIT_CARD:
    "This credit card has expired,please pay by other card",
  PAYMENT_VERIFICATION_FAILED_INVALID_EXPIRY_OR_CVV:
    "Invalid expiry or cvv, please check your card information ",
  SAVE_CREDIT_CARD_ERROR: " We couldn't save credit card, please try again",
  DELETE_CREDIT_CARD_ERROR_NO_SAVED_CARD: "No saved cards found ",
  DELETE_CREDIT_CARD_ERROR: "We couldn't delete credit card, please try again",
  VERIFY_PAYMENT_ERROR_SAVING_VERIFIED_SHIPPING_DETAILS:
    "Sorry there was a problem saving details, please try again ",
  VERIFY_PAYMENT_ERROR_SAVING_VERIFIED_PAYMENT_DETAILS:
    "Sorry there was a problem saving details, please try again ",
  VERIFY_PAYMENT_SAVE_SHIPPING_DETAILS_ERROR:
    "Sorry there was a problem saving details, please try again ",
  FEDEX_TEMPORARILY_DISABLED:
    "Dear Client,\nUnfortunately, FedEx service is currently unavailable.\nPlease consider shipping via UPS at a better price.\nWe are negotiating with FedEx to obtain reasonable prices for our clients.\nThank you,\nThe ShipWinner Team",
};

// translate
if (CONSTANTS.ERROR_MESSAGES) {
  for (const key in CONSTANTS.ERROR_MESSAGES) {
    const value = CONSTANTS.ERROR_MESSAGES[key];
    if (languageTranslations[value]) {
      CONSTANTS.ERROR_MESSAGES[key] = languageTranslations[value];
    }
  }
}

export default CONSTANTS;
