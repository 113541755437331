import React, { useRef, useState, useMemo } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { modalQueue, api_login, api_getInitialData, genericSetValue } from '../redux/actions';
import { Button, TextField, Link } from '@material-ui/core';
import { Colors } from "../common/colors";
import CONSTANTS from '../common/constants';
import { get } from 'lodash';
import PigeItCheckbox from "./PigeItCheckbox.component";
import Loader from './Loader.component';
import IMAGES from '../common/images';
import PigeItDatePicker from '../components/PigeItDatePicker.component';
import PigeItInput from './PigeItInput.component';

function PigeItCardHoldersDetailsComponent(props) {
  const dispatch = useDispatch();
  const fullName = get(props.purchaseProgress, 'payment.billingDetails.fullName', '');
  const [fields, setFields] = useState({
    fName: fullName.split(' ').length>0? fullName.split(' ')[0]:'',
    lName: fullName.split(' ').length>1? fullName.split(' ')[1]:'',
    zipCode: get(props.purchaseProgress, 'payment.billingDetails.zipCode', ''),
    email: get(props.purchaseProgress, 'payment.billingDetails.email', '')
  });
  const [errors, setErrors] = useState({ fName: false, lName: false, zipCode: false, email: false });

  return (
    <PigeItCardHoldersDetailsStyle className="oginl-container">
      <div className="background-container">
        <div className="content-container">
          <Button className="modal-close-button" onClick={close}>
            <IMAGES.close />
          </Button>
          <div className="header-text">Cardholder's <br /> Details</div>
          <PigeItInput className='input' error={errors.fName} value={fields.fName} onChange={(e) => setFields({ ...fields, fName: e.target.value })} label='First Name'/>
          <PigeItInput className='input' error={errors.lName} value={fields.lName} onChange={(e) => setFields({ ...fields, lName: e.target.value })} label='Last Name'/>
          <PigeItInput className='input' error={errors.zipCode} value={fields.zipCode} onChange={(e) => setFields({ ...fields, zipCode: e.target.value })} label='Zip Code'/>
          <PigeItInput className='input' error={errors.email} value={fields.email} onChange={(e) => setFields({ ...fields, email: e.target.value })} label='Email'/>

          <button className='button' disabled={fields.fName === '' && fields.lName === '' && fields.zipCode === '' && fields.email === ''} onClick={confirm}>CONFIRM</button>
        </div>
      </div>
    </PigeItCardHoldersDetailsStyle >
  );

  function confirm() {
    let err = errors;
    for (var f in fields) {
      if (fields[f] === '' || (f.toLowerCase().indexOf('email') !== -1 && !CONSTANTS.VALID_EMAIL_REGEX.test(String(fields[f]).toLowerCase()))) {
        err = { ...err, [f]: true };
      } else {
        err = { ...err, [f]: false };
      }
    }
    if (Object.entries(err).every(entry => !entry[1])) {
      dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress', value: { ...props.purchaseProgress, payment: { ...props.purchaseProgress.payment, differentBillingDetails: true, billingDetails: { email: fields.email, fullName: fields.fName + ' ' + fields.lName, zipCode: fields.zipCode } } } }));
      dispatch(modalQueue({ mode: 'delete' }));
    } else {
      setErrors(err);
    }
  }


  function close() {
    dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress', value: { ...props.purchaseProgress, payment: { ...props.purchaseProgress.payment, differentBillingDetails: false } } }));
    dispatch(modalQueue({ mode: 'delete' }));
  }


}
const mapStateToProps = (state, ownProps) => {
  return {
    purchaseProgress: state.data.ui.mainPage.purchaseProgress
  }
};




const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const PigeItCardHoldersDetailsStyle = styled.div`
  width: 100%;
  height: 100%;
  .input{
    display: block;
    margin-top: 20px;
  }
  .button:disabled{
    cursor:default;
    background-color: ${Colors.lightGray2};
  }
  .button{
    background: unset;
    border: none;
    cursor: pointer;
    display: block;
    margin: auto;
    outline: none;
    width: 350px;
    height: 56px;
    text-align: center;
    color: ${Colors.white};
    background-color: ${Colors.orange};
    font-size: 14px;
    font-family: museo_sans700;
    margin-top: 40px;
    border-radius: 3px;
    .icon{
      svg{
        width: 16px;
        height: 13px;
        margin-right: 15px;
        path{
          fill: ${Colors.white};
        }
      }
    }
    
    .text{
      display: inline-block;
    }
  }
  
  .background-container {
    height: 100%;
    width: 100%;
    background-color: #fff;
    margin: 0 auto;
    
  }
  .content-container {
    padding: 0 70px;
    padding-top: 61px;
  }
  .modal-close-button {
    position: absolute;
    top: 21px;
    right: 26px;
  }
  .header-text {
    font-size: 36px;
    text-align: center;
    margin-bottom: 32px;
  }
  @media only screen and (max-width: 992px) { 
    .content-container {
    padding: 0 21px;
  }
    .header-text {
      font-size: 25px;
    }
    .input{
      min-width: 232px;
    }
    .button{
      &.confirm{
        width: 254px;
      }
    }
  }
`;

const PigeItCardHoldersDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItCardHoldersDetailsComponent);




export default PigeItCardHoldersDetails;
