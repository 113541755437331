import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Colors } from '../common/colors';
import IMAGES from '../common/images';

function InputSearchComponent(props) {
  const [ value, setValue ] = useState('');

  return (
    <InputSearchStyle className={props.className}>
      <input type="text" id={props.inputId} className="search-input" name={props.inputName} value={value} placeholder={props.inputPlaceholder} onChange={onChange} />
      <button className="input-search-button" onClick={onSearchClick}>
        <span className="input-search-button-icon-container">
          <IMAGES.search />
        </span>
      </button>
    </InputSearchStyle>
  );

  function onChange(e) {
    setValue(e.target.value);
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  }
  function onSearchClick() {
    if (props.onSearch) {
      props.onSearch(value);
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const InputSearchStyle = styled.div`
  position: relative;
  .search-input {
    background-color: transparent;
    border: 1px solid ${Colors.darkBlue1};
    border-radius: 3px;
    display: block;
    box-sizing: border-box;
    width: 100%;
    font-size: 14px;
    line-height: 38px;
    height: 40px;
    padding: 0 36px 0 10px;
    color: ${Colors.black};
    &::placeholder {
      color: #9daab1;
    }
  }
  .input-search-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    top: 0;
    right: 0;
    height: 40px;
    width: 36px;
    padding: 2px 0 0;
  }
`;

const InputSearch = connect(
  mapStateToProps,
  mapDispatchToProps
)(InputSearchComponent);

export default InputSearch;
