import { logError, logEventForAnalytics } from "../common/functions";
import CONSTANTS from '../common/constants';
import { set, get } from 'lodash';

const ACTIONS = {
  VALIDATE_COUPON_FAILED:'VALIDATE_COUPON_FAILED',
  VALIDATE_COUPON_SUCCESS:'VALIDATE_COUPON_SUCCESS',
  GOOGLE_MAPS_READY:'GOOGLE_MAPS_READY',
  TOGGLE_LOADER: 'TOGGLE_LOADER',
  SET_LOADER_TEXT: 'SET_LOADER_TEXT',
  SETUP_ANIMATED_LOADER: 'SETUP_ANIMATED_LOADER',
  TOGGLE_V_SCROLL_TOP: 'TOGGLE_V_SCROLL_TOP',
  GENERIC_SET_VALUE: 'GENERIC_SET_VALUE',
  MODAL_HANDLER: 'MODAL_HANDLER',
  GET_INITIAL_DATA_SUCCESS: 'GET_INITIAL_DATA_SUCCESS',
  GET_INITIAL_DATA_FAILED: 'GET_INITIAL_DATA_FAILED',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILED: 'LOGIN_FAILED',
  RESET_ACCOUNT_CREATE_SUCCESS: 'RESET_ACCOUNT_CREATE_SUCCESS',
  RESET_ACCOUNT_CREATE_FAILED: 'RESET_ACCOUNT_CREATE_FAILED',
  RESET_ACCOUNT_CHANGE_PASSWORD_SUCCESS: 'RESET_ACCOUNT_CHANGE_PASSWORD_SUCCESS',
  RESET_ACCOUNT_CHANGE_PASSWORD_FAILED: 'RESET_ACCOUNT_CHANGE_PASSWORD_FAILED',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILED: 'REGISTER_FAILED',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILED: 'LOGOUT_FAILED',
  GET_SHIPPING_HISTORY_SUCCESS: 'GET_SHIPPING_HISTORY_SUCCESS',
  GET_SHIPPING_HISTORY_FAILED: 'GET_SHIPPING_HISTORY_FAILED',
  GET_SHIPPING_HISTORY_MSR_SUCCESS: 'GET_SHIPPING_HISTORY_MSR_SUCCESS',
  GET_SHIPPING_HISTORY_MSR_FAILED: 'GET_SHIPPING_HISTORY_MSR_FAILED',
  GET_PRICES_SUCCESS: 'GET_PRICES_SUCCESS',
  GET_PRICES_FAILED: 'GET_PRICES_FAILED',
  GET_PRICES_NO_INSURANCE_SUCCESS: 'GET_PRICES_NO_INSURANCE_SUCCESS',
  GET_PRICES_NO_INSURANCE_FAILED: 'GET_PRICES_NO_INSURANCE_FAILED',
  GET_PRICES_INSURANCE_SUCCESS: 'GET_PRICES_INSURANCE_SUCCESS',
  GET_PRICES_INSURANCE_FAILED: 'GET_PRICES_INSURANCE_FAILED',
  DELETE_INSURANCE: 'DELETE_INSURANCE',
  VERIFY_PAYMENT_SUCCESS: 'VERIFY_PAYMENT_SUCCESS',
  VERIFY_PAYMENT_FAILED: 'VERIFY_PAYMENT_FAILED',
  PAY_SUCCESS: 'PAY_SUCCESS',
  PAY_FAILED: 'PAY_FAILED',
  SEND_TO_CUSTOMER_SERVICE_SUCCESS: 'SEND_TO_CUSTOMER_SERVICE_SUCCESS',
  SEND_TO_CUSTOMER_SERVICE_FAILED: 'SEND_TO_CUSTOMER_SERVICE_FAILED',
  BUSINESS_REVIEWS_ADD_SUCCESS: 'BUSINESS_REVIEWS_ADD_SUCCESS',
  BUSINESS_REVIEWS_ADD_FAILED: 'BUSINESS_REVIEWS_ADD_FAILED',
  BUSINESS_REVIEWS_GET_VERIFIED_SUCCESS: 'BUSINESS_REVIEWS_GET_VERIFIED_SUCCESS',
  BUSINESS_REVIEWS_GET_VERIFIED_FAILED: 'BUSINESS_REVIEWS_GET_VERIFIED_FAILED',
  BUSINESS_REVIEWS_GET_VERIFIED_IMAGE_SUCCESS: 'BUSINESS_REVIEWS_GET_VERIFIED_IMAGE_SUCCESS',
  BUSINESS_REVIEWS_GET_VERIFIED_IMAGE_FAILED: 'BUSINESS_REVIEWS_GET_VERIFIED_IMAGE_FAILED',
  UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILED: 'UPDATE_PROFILE_FAILED',
  UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_FAILED: 'UPDATE_PASSWORD_FAILED',
  ACTIVATE_ACCOUNT_SUCCESS: 'ACTIVATE_ACCOUNT_SUCCESS',
  ACTIVATE_ACCOUNT_FAILED: 'ACTIVATE_ACCOUNT_FAILED',
  ADRESSBOOK_GET_LIST_SUCCESS: 'ADRESSBOOK_GET_LIST_SUCCESS',
  ADRESSBOOK_GET_LIST_FAILED: 'ADRESSBOOK_GET_LIST_FAILED',
  ADRESSBOOK_ADD_SUCCESS: 'ADRESSBOOK_ADD_SUCCESS',
  ADRESSBOOK_ADD_FAILED: 'ADRESSBOOK_ADD_FAILED',
  ADRESSBOOK_UPDATE_SUCCESS: 'ADRESSBOOK_UPDATE_SUCCESS',
  ADRESSBOOK_UPDATE_FAILED: 'ADRESSBOOK_UPDATE_FAILED',
  ADRESSBOOK_REMOVE_SUCCESS: 'ADRESSBOOK_REMOVE_SUCCESS',
  ADRESSBOOK_REMOVE_FAILED: 'ADRESSBOOK_REMOVE_FAILED',
  PACKAGES_GET_LIST_SUCCESS: 'PACKAGES_GET_LIST_SUCCESS',
  PACKAGES_GET_LIST_FAILED: 'PACKAGES_GET_LIST_FAILED',
  PACKAGES_ADD_SUCCESS: 'PACKAGES_ADD_SUCCESS',
  PACKAGES_ADD_FAILED: 'PACKAGES_ADD_FAILED',
  PACKAGES_UPDATE_SUCCESS: 'PACKAGES_UPDATE_SUCCESS',
  PACKAGES_UPDATE_FAILED: 'PACKAGES_UPDATE_FAILED',
  PACKAGES_REMOVE_SUCCESS: 'PACKAGES_REMOVE_SUCCESS',
  PACKAGES_REMOVE_FAILED: 'PACKAGES_REMOVE_SUCCESS',
  CREDITCARD_GET_LIST_SUCCESS: 'CREDITCARD_GET_LIST_SUCCESS',
  CREDITCARD_GET_LIST_FAILED: 'CREDITCARD_GET_LIST_FAILED',
  CREDITCARD_ADD_SUCCESS: 'CREDITCARD_ADD_SUCCESS',
  CREDITCARD_ADD_FAILED: 'CREDITCARD_ADD_FAILED',
  CREDITCARD_SET_SELECTED_CARD_SUCCESS: 'CREDITCARD_SET_SELECTED_CARD_SUCCESS',
  CREDITCARD_SET_SELECTED_CARD_FAILED: 'CREDITCARD_SET_SELECTED_CARD_FAILED',
  CREDITCARD_REMOVE_SUCCESS: 'CREDITCARD_REMOVE_SUCCESS',
  CREDITCARD_REMOVE_FAILED: 'CREDITCARD_REMOVE_FAILED',
  SAVE_STATE_TO_LOCALSTORAGE: 'SAVE_STATE_TO_LOCALSTORAGE',
  SET_DISPLAY_MODE: 'SET_DISPLAY_MODE',
  GET_PRICES_CSV_FAIL: 'GET_PRICES_CSV_FAIL',
  GET_PRICES_CSV_SUCCESS: 'GET_PRICES_CSV_SUCCESS',
  SAVE_CSV: 'SAVE_CSV',
  SAVE_CSV_HEADERS: 'SAVE_CSV_HEADERS',
  SAVE_ORDERS: 'SAVE_ORDERS',
  GET_PRICES_MULTI_FAILED: 'GET_PRICES_MULTI_FAILED',
  GET_PRICES_MULTI_SUCCESS: 'GET_PRICES_MULTI_SUCCESS',
  COLUMN_MAPPING_GET_LIST_FAILED:'COLUMN_MAPPING_GET_LIST_FAILED',
  COLUMN_MAPPING_GET_LIST_SUCCESS:'COLUMN_MAPPING_GET_LIST_SUCCESS',
  COLUMN_MAPPING_ADD_FAILED:'COLUMN_MAPPING_ADD_FAILED',
  COLUMN_MAPPING_ADD_SUCCESS:'COLUMN_MAPPING_ADD_SUCCESS',
  COLUMN_MAPPING_UPDATE_FAILED:'COLUMN_MAPPING_UPDATE_FAILED',
  COLUMN_MAPPING_UPDATE_SUCCESS:'COLUMN_MAPPING_UPDATE_SUCCESS',
  COLUMN_MAPPING_REMOVE_FAILED:'COLUMN_MAPPING_REMOVE_FAILED',
  COLUMN_MAPPING_REMOVE_SUCCESS:'COLUMN_MAPPING_REMOVE_SUCCESS',
  VERIFY_PAYMENT_MSR_FAIL:'VERIFY_PAYMENT_MSR_FAIL',
  VERIFY_PAYMENT_MSR_SUCCESS:'VERIFY_PAYMENT_MSR_SUCCESS',
  PAY_MSR_FAIL:'PAY_MSR_FAIL',
  PAY_MSR_SUCCESS:'PAY_MSR_SUCCESS',
};

// api's

/**
 *
 * @param input - structure: { callback: 'method' }
 * @returns {function(*)}
 */
const api_getInitialData = (input) => {
  return async (dispatch) => {

    await genericExecuteGet(dispatch,
      'api_getInitialData',
      'api_getInitialData',
      'api/get-initial-data/',
      ACTIONS.GET_INITIAL_DATA_SUCCESS,
      ACTIONS.GET_INITIAL_DATA_FAILED,
      input);
  };
};

/**
 *
 * @param input - structure: { username: 'string', password: 'string', callback: 'method' }
 * @returns {function(*)}
 */
const api_login = (input) => {
  return async (dispatch) => {

    await genericExecutePost(dispatch,
      'api_login',
      'api_login',
      'api/login/',
      ACTIONS.LOGIN_SUCCESS,
      ACTIONS.LOGIN_FAILED,
      input);
  };
};

/**
 *
 * @param input - structure:
 * @returns {function(*)}
 */
const reset_account_create = (input) => {
  return async (dispatch) => {

    await genericExecutePost(dispatch,
      'reset_account_create',
      'reset_account_create',
      'api/reset-account-password/create/',
      ACTIONS.RESET_ACCOUNT_CREATE_SUCCESS,
      ACTIONS.RESET_ACCOUNT_CREATE_FAILED,
      input);
  };
};

/**
 *
 * @param input
 * @returns {function(*)}
 */
const reset_account_change_password = (input) => {
  return async (dispatch) => {

    await genericExecutePost(dispatch,
      'reset_account_change_password',
      'reset_account_change_password',
      'api/reset-account-password/change-password/',
      ACTIONS.RESET_ACCOUNT_CHANGE_PASSWORD_SUCCESS,
      ACTIONS.RESET_ACCOUNT_CHANGE_PASSWORD_FAILED,
      input);
  };
};

/**
 *
 * @param input - structure: { username: 'string', password: 'string', callback: 'method' }
 * @returns {function(*)}
 */
const api_register = (input) => {
  return async (dispatch) => {

    await genericExecutePost(dispatch,
      'api_register',
      'api_register',
      'api/register/',
      ACTIONS.REGISTER_SUCCESS,
      ACTIONS.REGISTER_FAILED,
      input);
  };
};

/**
 *
 * @param input - structure: { callback: 'method' }
 * @returns {function(*)}
 */
const api_logout = (input) => {
  return async (dispatch) => {

    await genericExecutePost(dispatch,
      'api_logout',
      'api_logout',
      'api/logout/',
      ACTIONS.LOGOUT_SUCCESS,
      ACTIONS.LOGOUT_FAILED,
      input);
  };
};

/**
 *
 * @param input - structure: { fullName: 'string', company: 'string', phone: 'string', address: 'string', city: 'string', state: 'string', country: 'string', zipCode: 'string', callback: 'method' }
 * @returns {function(*)}
 */
const api_update_profile = (input) => {
  return async (dispatch) => {

    await genericExecutePost(dispatch,
      'api_update_profile',
      'api_update_profile',
      'api/update-profile/',
      ACTIONS.UPDATE_PROFILE_SUCCESS,
      ACTIONS.UPDATE_PROFILE_FAILED,
      input);
  };
};

/**
 *
 * @param input - structure: { password: 'string', newPassword: 'string', callback: 'method' }
 * @returns {function(*)}
 */
const api_update_password = (input) => {
  return async (dispatch) => {

    await genericExecutePost(dispatch,
      'api_update_password',
      'api_update_password',
      'api/update-password/',
      ACTIONS.UPDATE_PASSWORD_SUCCESS,
      ACTIONS.UPDATE_PASSWORD_FAILED,
      input);
  };
};

/**
 *
 * @param input - structure: { email: 'string', activation: 'string', callback: 'method' }
 * @returns {function(*)}
 */
const api_activate_account = (input) => {
  return async (dispatch) => {

    await genericExecuteGet(dispatch,
      'api_activate_account',
      'api_activate_account',
      'api/activate-account/?email=' + encodeURIComponent(input.email) + '&activation=' + input.activation,
      ACTIONS.ACTIVATE_ACCOUNT_SUCCESS,
      ACTIONS.ACTIVATE_ACCOUNT_FAILED,
      input);
  };
};

/**
 *
 * @param input - structure: { callback: 'method' }
 * @returns {function(*)}
 */
const api_addressBook_getList = (input) => {
  return async (dispatch) => {

    await genericExecuteGet(dispatch,
      'api_addressBook_getList',
      'api_addressBook_getList',
      'api/address-book/get-list/',
      ACTIONS.ADRESSBOOK_GET_LIST_SUCCESS,
      ACTIONS.ADRESSBOOK_GET_LIST_FAILED,
      input);
  };
};

/**
 *
 * @param input - structure: { placeId: 'string', fullname: 'string', company: 'string', email: 'string', phone: 'string', callback: 'method' }
 * @returns {function(*)}
 */
const api_addressBook_add = (input) => {
  return async (dispatch) => {

    await genericExecutePost(dispatch,
      'api_addressBook_add',
      'api_addressBook_add',
      'api/address-book/add/',
      ACTIONS.ADRESSBOOK_ADD_SUCCESS,
      ACTIONS.ADRESSBOOK_ADD_FAILED,
      input);
  };
};

/**
 *
 * @param input - structure: { id: 'string', placeId: 'string', fullname: 'string', company: 'string', email: 'string', phone: 'string',  callback: 'method' }
 * @returns {function(*)}
 */
const api_addressBook_update = (input) => {
  return async (dispatch) => {

    await genericExecutePost(dispatch,
      'api_addressBook_update',
      'api_addressBook_update',
      'api/address-book/update/',
      ACTIONS.ADRESSBOOK_UPDATE_SUCCESS,
      ACTIONS.ADRESSBOOK_UPDATE_FAILED,
      input);
  };
};

/**
 *
 * @param input - structure: { id: 'string', callback: 'method' }
 * @returns {function(*)}
 */
const api_addressBook_remove = (input) => {
  return async (dispatch) => {

    await genericExecutePost(dispatch,
      'api_addressBook_remove',
      'api_addressBook_remove',
      'api/address-book/remove/',
      ACTIONS.ADRESSBOOK_REMOVE_SUCCESS,
      ACTIONS.ADRESSBOOK_REMOVE_FAILED,
      input);
  };
};

/**
 *
 * @param input - structure: { callback: 'function' }
 * @returns {function(*=)}
 */
const api_packages_getList = (input) => {
  return async (dispatch) => {
    await genericExecuteGet(dispatch,
      'api_packages_getList',
      'api_packages_getList',
      'api/packages/get-list/',
      ACTIONS.PACKAGES_GET_LIST_SUCCESS,
      ACTIONS.PACKAGES_GET_LIST_FAILED,
      input);
  };
};

/**
 *
 * @param input - structure: { name: 'string', content: 'string', packageTypeId: 'integer', weight: 'float', weightUnitId: 'integer', sizeHeight: 'float', sizeWidth: 'float', sizeLength: 'float', sizeUnits: 'integer', callback: 'function' }
 * @returns {function(*=)}
 */
const api_packages_add = (input) => {
  return async (dispatch) => {
    await genericExecutePost(dispatch,
      'api_packages_add',
      'api_packages_add',
      'api/packages/add/',
      ACTIONS.PACKAGES_ADD_SUCCESS,
      ACTIONS.PACKAGES_ADD_FAILED,
      input);
  };
};

/**
 *
 * @param input - structure: { name: 'string', content: 'string', packageTypeId: 'integer', weight: 'float', weightUnitId: 'integer', sizeHeight: 'float', sizeWidth: 'float', sizeLength: 'float', sizeUnits: 'integer', callback: 'function' }
 * @returns {function(*=)}
 */
 const api_validate_coupon = (input) => {
  return async (dispatch) => {
    await genericExecutePost(dispatch,
      'api_validate_coupon',
      'api_validate_coupon',
      'api/validate-coupon/',
      ACTIONS.VALIDATE_COUPON_SUCCESS,
      ACTIONS.VALIDATE_COUPON_FAILED,
      input);
  };
};

/**
 *
 * @param input - structure: { id: 'string', name: 'string', content: 'string', packageTypeId: 'integer', weight: 'float', weightUnitId: 'integer', sizeHeight: 'float', sizeWidth: 'float', sizeLength: 'float', sizeUnits: 'integer', callback: 'function' }
 * @returns {function(*=)}
 */
const api_packages_update = (input) => {
  return async (dispatch) => {
    await genericExecutePost(dispatch,
      'api_packages_update',
      'api_packages_update',
      'api/packages/update/',
      ACTIONS.PACKAGES_UPDATE_SUCCESS,
      ACTIONS.PACKAGES_UPDATE_FAILED,
      input);
  };
};

/**
 *
 * @param input - structure: { id: 'string', callback: 'function' }
 * @returns {function(*=)}
 */
const api_packages_remove = (input) => {
  return async (dispatch) => {

    await genericExecutePost(dispatch,
      'api_packages_remove',
      'api_packages_remove',
      'api/packages/remove/',
      ACTIONS.PACKAGES_REMOVE_SUCCESS,
      ACTIONS.PACKAGES_REMOVE_FAILED,
      input);
  };
};

/**
 *
 * @param input - structure: { firstName: 'string', lastName: 'string', email: 'string', zip: 'string', creditCardNumber: 'string encrypted', creditCardSecurityCode: 'string encrypted', creditCardExpiryYear: 'string in format YYYY', creditCardExpiryMonth: 'string in format MM', callback: 'method' }
 * @returns {function(*)}
 */
const api_creditcard_setSelectedCard = (input) => {
  return async (dispatch) => {

    await genericExecutePost(dispatch,
      'api_creditcard_setSelectedCard',
      'api_creditcard_setSelectedCard',
      'api/payment-sources/set-selected-card/',
      ACTIONS.CREDITCARD_SET_SELECTED_CARD_SUCCESS,
      ACTIONS.CREDITCARD_SET_SELECTED_CARD_FAILED,
      input);
  };
};

/**
 *
 * @param input - structure: { callback: 'method' }
 * @returns {function(*)}
 */
const api_creditcard_getList = (input) => {
  return async (dispatch) => {

    await genericExecuteGet(dispatch,
      'api_creditcard_getList',
      'api_creditCard_getList',
      'api/payment-sources/get-list/',
      ACTIONS.CREDITCARD_GET_LIST_SUCCESS,
      ACTIONS.CREDITCARD_GET_LIST_FAILED,
      input);
  };
};

/**
 *
 * @param input - structure: { firstName: 'string', lastName: 'string', email: 'string', zip: 'string', creditCardNumber: 'string encrypted', creditCardSecurityCode: 'string encrypted', creditCardExpiryYear: 'string in format YYYY', creditCardExpiryMonth: 'string in format MM', callback: 'method' }
 * @returns {function(*)}
 */
const api_creditcard_addCreditCard = (input) => {
  return async (dispatch) => {

    await genericExecutePost(dispatch,
      'api_creditcard_addCreditCard',
      'api_creditCard_addCreditCard',
      'api/payment-sources/add-credit-card/',
      ACTIONS.CREDITCARD_ADD_SUCCESS,
      ACTIONS.CREDITCARD_ADD_FAILED,
      input);
  };
};

/**
 *
 * @param input - structure: { id: 'string', callback: 'method' }
 * @returns {function(*)}
 */
const api_creditcard_removeCreditCard = (input) => {
  return async (dispatch) => {

    await genericExecutePost(dispatch,
      'api_creditcard_removeCreditCard',
      'api_creditcard_removeCreditCard',
      'api/payment-sources/remove-credit-card/',
      ACTIONS.CREDITCARD_REMOVE_SUCCESS,
      ACTIONS.CREDITCARD_REMOVE_FAILED,
      input);
  };
};

/**
 *
 * @param input - structure: { callback: 'function' }
 * @returns {function(*=)}
 */
const api_getShippingHistory = (input) => {
  return async (dispatch) => {
    await genericExecuteGet(
      dispatch,
      'api_getShippingHistory',
      'api_getShippingHistory',
      'api/get-shipping-history/',
      ACTIONS.GET_SHIPPING_HISTORY_SUCCESS,
      ACTIONS.GET_SHIPPING_HISTORY_FAILED,
      input);
  };
};

const api_getShippingHistoryMsr = (input) => {
  return async (dispatch) => {
    await genericExecuteGet(
      dispatch,
      'api_getShippingHistoryMsr',
      'api_getShippingHistoryMsr',
      'api/get-shipping-history-msr/',
      ACTIONS.GET_SHIPPING_HISTORY_MSR_SUCCESS,
      ACTIONS.GET_SHIPPING_HISTORY_MSR_FAILED,
      input);
  };
};

const save_csv = (data) => {
  return async (dispatch) => {
    localStorage.setItem('csv-rows', JSON.stringify(data));
    dispatch({
      type: ACTIONS.SAVE_CSV,
      payload: data
    });
  }
}

const googleAutoCompleteInit = () => {
  return async (dispatch) => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&callback=initGoogleMap&libraries=places&v=weekly`;
    script.async=true;
    script.defer = true;
    window.initGoogleMap = ()=>{
      dispatch({
        type: ACTIONS.GOOGLE_MAPS_READY,
        payload: null
      });
      console.log('google-maps-ready')
    }
    document.getElementsByTagName('head')[0].appendChild(script);
  }
}

const save_orders = (data) => {
  return async (dispatch) => {
    localStorage.removeItem('csv-rows');
    localStorage.removeItem('csv-headers');
    localStorage.setItem('csv-orders', JSON.stringify(data));
    dispatch({
      type: ACTIONS.SAVE_ORDERS,
      payload: data
    });
  }
}

const save_csv_headers = (data) => {
  return async (dispatch) => {
    localStorage.setItem('csv-headers', JSON.stringify(data));
    dispatch({
      type: ACTIONS.SAVE_CSV_HEADERS,
      payload: data
    });
  }
}

const delete_insurance = () => {
  return async (dispatch, getState) => {
    let getPrices = get(getState(), 'data.api.getPrices');
    if (getPrices) {
      let newGetPrices = {
        ...getPrices, result: {
          ...get(getPrices, 'result', {}), results: get(getPrices, 'result.results', []).map((opt, optI) => {
            return {
              ...opt, options: get(getPrices, 'result.results[' + optI + '].options', []).map((res, resI) => {
                let temp = { ...get(getPrices, 'result.results[' + optI + '].options[' + resI + ']', {}) };
                temp.price = temp.withoutInsurance
                delete temp.withoutInsurance;
                return temp;
              })
            }
          })
        }
      }
      dispatch({
        type: ACTIONS.DELETE_INSURANCE,
        payload: newGetPrices
      });
    }
  }
}
/**
 *
 * @param input - structure: { date: 'string in format YYYY-MM-DD', time: 'string in format HH:MM', recipientPlaceId: 'string', senderPlaceId: 'string', shippingDropoffTypeId: 'integer', packages: [ { packagingType: 'integer', weight: 'float', weightUnits: 'integer', sizeHeight: 'float', sizeWidth: 'float', sizeLength: 'float', contentDescription: 'string', insuredValueAmount: 'float', insuredValueCurrency: 'integer' } ], callback: 'function' }
 * @returns {function(*=)}
 */
const api_getPrices = (input) => {
  return async (dispatch) => {
    if (input.packages.some(pack => pack.insuredValueAmount || pack.contentDescription)) {
      let callback = input.callback;
      delete input.callback;
      let count = 0;
      let result = null;
      let error = false;
      let withoutInsurance = { ...input, packages: get(input, 'packages', []).map(pack => ({ ...pack, contentDescription: "", insuredValueAmount: 0 })) };
      withoutInsurance.callback = (res) => {
        count = count + 1;
        if (res.success) {
          if (result) {
            result = {
              ...result, results: get(result, 'results', []).map((r, resI) => {
                return {
                  ...r, options: get(r, 'options', []).map((opt, optI) => {
                    if (opt.shippingTypeId === get(res, 'results[' + resI + '].options[' + optI + '].shippingTypeId', 0)) {
                      return { ...opt, withoutInsurance: get(res, 'results[' + resI + '].options[' + optI + '].price', 0) }
                    } else {
                      return opt;
                    }
                  })
                }
              })
            };
          } else {
            result = {
              ...res, results: get(res, 'results', []).map((r, resI) => {
                return {
                  ...r, options: get(r, 'options', []).map((opt, optI) => {
                    if (opt.shippingTypeId === get(res, 'results[' + resI + '].options[' + optI + '].shippingTypeId', 0)) {
                      return { ...opt, withoutInsurance: get(res, 'results[' + resI + '].options[' + optI + '].price', 0) }
                    } else {
                      return opt;
                    }
                  })
                }
              })
            };
          }
        } else {
          error = res;
        }
        if (count === 2) {
          callback(error ? error : result);
        }
      };
      input.callback = (res) => {
        count = count + 1;
        if (res.success) {
          if (!result) {
            result = res;
          } else {
            result = {
              ...result, results: get(result, 'results', []).map((r, resI) => {
                return {
                  ...r, options: get(r, 'options', []).map((opt, optI) => {
                    if (opt.shippingTypeId === get(res, 'results[' + resI + '].options[' + optI + '].shippingTypeId', 0)) {
                      return { ...opt, price: get(res, 'results[' + resI + '].options[' + optI + '].price', 0) }
                    } else {
                      return opt;
                    }
                  })
                }
              })
            };
          }
        } else {
          error = res;
        }
        if (count === 2) {
          callback(error ? error : result);
        }
      };
      genericExecutePost(
        dispatch,
        'api_getPrices',
        'api_getPrices',
        'api/get-prices/',
        ACTIONS.GET_PRICES_INSURANCE_SUCCESS,
        ACTIONS.GET_PRICES_INSURANCE_FAILED,
        input);
      genericExecutePost(
        dispatch,
        'api_getPrices',
        'api_getPrices',
        'api/get-prices/',
        ACTIONS.GET_PRICES_NO_INSURANCE_SUCCESS,
        ACTIONS.GET_PRICES_NO_INSURANCE_FAILED,
        withoutInsurance);
    } else {
      genericExecutePost(
        dispatch,
        'api_getPrices',
        'api_getPrices',
        'api/get-prices/',
        ACTIONS.GET_PRICES_SUCCESS,
        ACTIONS.GET_PRICES_FAILED,
        input);
    }
  };
};

const api_get_prices_multi = (input) => {
  return async (dispatch) => {
    await genericExecutePost(
      dispatch,
      'api_get_prices_multi',
      'api_get_prices_multi',
      'api/get-prices-multi/',
      ACTIONS.GET_PRICES_MULTI_SUCCESS,
      ACTIONS.GET_PRICES_MULTI_FAILED,
      input);
  };
};

const verify_payment_msr = (input) => {
  return async (dispatch) => {
    await genericExecutePost(
      dispatch,
      'verify_payment_msr',
      'verify_payment_msr',
      'api/verify-payment-msr/',
      ACTIONS.VERIFY_PAYMENT_MSR_SUCCESS,
      ACTIONS.VERIFY_PAYMENT_MSR_FAIL,
      input);
  };
};

const api_pay_msr = (input) => {
  return async (dispatch) => {
    await genericExecutePost(
      dispatch,
      'pay_msr',
      'pay_msr',
      'api/pay-msr/',
      ACTIONS.PAY_MSR_SUCCESS,
      ACTIONS.PAY_MSR_FAIL,
      input);
  };
};

const api_verifyPayment = (input) => {
  return async (dispatch) => {
    await genericExecutePost(
      dispatch,
      'api_verifyPayment',
      'api_verifyPayment',
      'api/verify-payment/',
      ACTIONS.VERIFY_PAYMENT_SUCCESS,
      ACTIONS.VERIFY_PAYMENT_FAILED,
      input);
  };
};

const api_pay = (input) => {
  return async (dispatch) => {
    await genericExecutePost(
      dispatch,
      'api_pay',
      'api_pay',
      'api/pay/',
      ACTIONS.PAY_SUCCESS,
      ACTIONS.PAY_FAILED,
      input);
  };
};

const api_get_prices_csv = (input) => {
  return async (dispatch) => {
    await genericExecutePost(
      dispatch,
      'api_get_prices_csv',
      'api_get_prices_csv',
      'api/get-prices-csv/',
      ACTIONS.GET_PRICES_CSV_SUCCESS,
      ACTIONS.GET_PRICES_CSV_FAIL,
      input);
  };
};

const api_send_to_customer_service = (input) => {
  return async (dispatch) => {
    await genericExecutePost(
      dispatch,
      'api_send_to_customer_service',
      'api_send_to_customer_service',
      'api/send-to-customer-service/',
      ACTIONS.SEND_TO_CUSTOMER_SERVICE_SUCCESS,
      ACTIONS.SEND_TO_CUSTOMER_SERVICE_FAILED,
      input);
  };
};

const api_businessReviews_add = (input) => {
  return async (dispatch) => {
    await genericExecutePost(
      dispatch,
      'api_business_reviews_add',
      'api_business_reviews_add',
      'api/business-reviews/add/',
      ACTIONS.BUSINESS_REVIEWS_ADD_SUCCESS,
      ACTIONS.BUSINESS_REVIEWS_ADD_FAILED,
      input);
  };
};

const api_businessReviews_getVerifiedImage = (input) => {
  return async (dispatch) => {
    await genericExecutePost(
      dispatch,
      'api_business_reviews_get_verified_image',
      'api_business_reviews_get_verified_image',
      'api/business-reviews/get-verified-image/',
      ACTIONS.BUSINESS_REVIEWS_GET_VERIFIED_IMAGE_SUCCESS,
      ACTIONS.BUSINESS_REVIEWS_GET_VERIFIED_IMAGE_FAILED,
      input);
  };
};

const api_businessReviews_getVerified = (input) => {
  return async (dispatch) => {
    await genericExecuteGet(
      dispatch,
      'api_business_reviews_get_verified',
      'api_business_reviews_get_verified',
      'api/business-reviews/get-verified/',
      ACTIONS.BUSINESS_REVIEWS_GET_VERIFIED_SUCCESS,
      ACTIONS.BUSINESS_REVIEWS_GET_VERIFIED_FAILED,
      input);
  };
};

const api_column_mapping_get_list = (input) => {
  return async (dispatch) => {
    await genericExecuteGet(
      dispatch,
      'api_column_mapping_get_list',
      'api_column_mapping_get_list',
      'api/column-mapping/get-list/',
      ACTIONS.COLUMN_MAPPING_GET_LIST_SUCCESS,
      ACTIONS.COLUMN_MAPPING_GET_LIST_FAILED,
      input);
  };
};

const api_column_mapping_add = (input) => {
  return async (dispatch) => {
    await genericExecutePost(
      dispatch,
      'api_column_mapping_add',
      'api_column_mapping_add',
      'api/column-mapping/add/',
      ACTIONS.COLUMN_MAPPING_ADD_SUCCESS,
      ACTIONS.COLUMN_MAPPING_ADD_FAILED,
      input);
  };
};

const api_column_mapping_update = (input) => {
  return async (dispatch) => {
    await genericExecutePost(
      dispatch,
      'api_column_mapping_update',
      'api_column_mapping_update',
      'api/column-mapping/update/',
      ACTIONS.COLUMN_MAPPING_UPDATE_SUCCESS,
      ACTIONS.COLUMN_MAPPING_UPDATE_FAILED,
      input);
  };
};

const api_column_mapping_remove = (input) => {
  return async (dispatch) => {
    await genericExecutePost(
      dispatch,
      'api_column_mapping_remove',
      'api_column_mapping_remove',
      'api/column-mapping/remove/',
      ACTIONS.COLUMN_MAPPING_REMOVE_SUCCESS,
      ACTIONS.COLUMN_MAPPING_REMOVE_FAILED,
      input);
  };
};

// loader actions
const toggleLoader = (data) => {
  return {
    type: ACTIONS.TOGGLE_LOADER,
    payload: { isVisible: data }
  };
};
const setLoaderText = (data) => {
  return {
    type: ACTIONS.SET_LOADER_TEXT,
    payload: data
  };
};
// animated loader actions
const setupAnimatedLoader = (data) => {
  return {
    type: ACTIONS.SETUP_ANIMATED_LOADER,
    payload: { isVisible: data.isVisible, text: data.text }
  };
};

// scrollbar actions
const toggleVScrollTop = (data) => {
  return {
    type: ACTIONS.TOGGLE_V_SCROLL_TOP,
    payload: data
  };
};

// generic updates (expected data structure - { path: 'string', value: 'any' })
const genericSetValue = (data) => {
  return {
    type: ACTIONS.GENERIC_SET_VALUE,
    payload: data
  };
};

// modal queue action (expected data structure:
// when adding - { mode: 'insert', modalConfig: 'string' })
// when deleting - { mode: 'delete' })
const modalQueue = (data) => {
  return async function (dispatch) {
    return dispatch({
      type: ACTIONS.MODAL_HANDLER,
      payload: data
    });
  };
};

const setDisplayMode = (data) => {
  return {
    type: ACTIONS.SET_DISPLAY_MODE,
    payload: data
  };
};

async function genericExecutePost(dispatch, apiName, mockName, uri, dispatchSuccess, dispatchFailed, input) {
  try {
    let dataInput = { ...input };
    delete dataInput.callback;
    let result;
    if (CONSTANTS.API_MOCKS.ENABLED) {
      result = CONSTANTS.API_MOCKS[mockName];
    }
    else {
      result = await (await fetch(CONSTANTS.API_ROOT + uri, { method: 'post', headers: { 'Content-Type': 'Application/json;' }, body: JSON.stringify(dataInput) })).json();
    }
    dispatch({ type: (result.success ? dispatchSuccess : dispatchFailed), payload: { result, request: dataInput } });
    if (input && input.callback) {
      input.callback(result);
    }
    // log errors
    if (!result.success) {
      logEventForAnalytics({
        'event': 'api-error',
        'code': result.code,
        'text': CONSTANTS.ERROR_MESSAGES[result.code] || CONSTANTS.DEFAULT_ERROR_MESSAGE
      });
    }
  }
  catch (err) {
    if (CONSTANTS.DEBUGGING) console.log('[' + apiName + '] exception', err);
    logEventForAnalytics({
      'event': 'api-error',
      'code': 0,
      'text': '[' + apiName + '] exception' + err.message
    });
    if (input && input.callback) {
      input.callback({ success: false, code: -1 });
    }
    logError(err);
  }
}
async function genericExecuteGet(dispatch, apiName, mockName, uri, dispatchSuccess, dispatchFailed, input) {
  try {
    let i = 9;
    let result;
    if (CONSTANTS.API_MOCKS.ENABLED) {
      result = CONSTANTS.API_MOCKS[mockName];
    }
    else {
      result = await (await fetch(CONSTANTS.API_ROOT + uri)).json();
    }

    // separate getInitial data api handling
    if (apiName !== 'api_getInitialData' || result.success != null) {
      dispatch({ type: (result.success ? dispatchSuccess : dispatchFailed), payload: { result } });
    }
    else if (apiName === 'api_getInitialData') {
      dispatch({ type: dispatchSuccess, payload: { result } });
    }

    if (input && input.callback) {
      input.callback(result);
    }
    // separate getInitial data api handling
    if (apiName !== 'api_getInitialData' || result.success != null) {
      // log errors
      if (!result.success) {
        logEventForAnalytics({
          'event': 'api-error',
          'code': result.code,
          'text': CONSTANTS.ERROR_MESSAGES[result.code] || CONSTANTS.DEFAULT_ERROR_MESSAGE
        });
      }
    }
  }
  catch (err) {
    if (CONSTANTS.DEBUGGING) console.log('[' + apiName + '] exception', err);
    logEventForAnalytics({
      'event': 'api-error',
      'code': 0,
      'text': '[' + apiName + '] exception' + err.message
    });
    if (input && input.callback) {
      input.callback({ success: false, code: -1 });
    }
    logError(err);
  }
}

export {
  ACTIONS,
  api_getInitialData,
  api_login,
  reset_account_create,
  reset_account_change_password,
  api_register,
  api_logout,
  api_update_profile,
  api_update_password,
  api_activate_account,
  api_addressBook_getList,
  api_addressBook_add,
  api_addressBook_update,
  api_addressBook_remove,
  api_packages_getList,
  api_packages_add,
  api_packages_update,
  api_packages_remove,
  api_creditcard_setSelectedCard,
  api_creditcard_getList,
  api_creditcard_addCreditCard,
  api_creditcard_removeCreditCard,
  api_getShippingHistory,
  api_getPrices,
  api_get_prices_multi,
  api_get_prices_csv,
  delete_insurance,
  api_verifyPayment,
  verify_payment_msr,
  api_pay,
  api_pay_msr,
  api_send_to_customer_service,
  api_businessReviews_add,
  api_businessReviews_getVerified,
  api_businessReviews_getVerifiedImage,
  api_column_mapping_remove,
  api_column_mapping_update,
  api_column_mapping_add,
  api_column_mapping_get_list,
  toggleLoader,
  setLoaderText,
  setupAnimatedLoader,
  toggleVScrollTop,
  genericSetValue,
  modalQueue,
  setDisplayMode,
  api_getShippingHistoryMsr,
  save_csv,
  save_csv_headers,
  save_orders,
  googleAutoCompleteInit,
  api_validate_coupon
};