/* eslint react/jsx-pascal-case: off */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { modalQueue } from '../redux/actions';
import { Button, Link } from '@material-ui/core';
import { Colors } from "../common/colors";
import { ZIndices } from '../common/zIndices';
import PigeItMyAccount_PersonalInfo from "./PigeItMyAccount_PersonalInfo.component";
import PigeItMyAccount_AddressBook from "./PigeItMyAccount_AddressBook.component";
import PigeItMyAccount_CreditCard from "./PigeItMyAccount_CreditCard.component";
import PigeItMyAccount_Packages from './PigeItMyAccount_Packages.component';
import PigeItMyAccount_ShippingHistory from './PigeItMyAccount_ShippingHistory.component';
import CONSTANTS from '../common/constants';
import { get } from 'lodash';
import IMAGES from '../common/images';
import STYLE from '../common/style';
import { navigateToPath, logEventForAnalytics } from "../common/functions";
import { A } from 'hookrouter';


function PigeItMyAccountComponent(props) {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const activeMenu = get(props, 'modalConfig.initialTab', CONSTANTS.MY_ACCOUNT_PERSONAL_INFO_TAB);
  const clickable = get(props, 'modalConfig.addressBookClickable', false) || get(props, 'modalConfig.packagesClickable', false) || get(props, 'modalConfig.cardClickable', false);
  let path = CONSTANTS.PUBLIC_URL;
  path = path + (path.substring(path.length - 1) !== '/' ? '/' : '');
  const activeMenuList = [
    CONSTANTS.MY_ACCOUNT_SHIPPING_HISTORY_TAB,
    CONSTANTS.MY_ACCOUNT_CREDIT_CARDS_TAB,
    CONSTANTS.MY_ACCOUNT_PACKAGES_LIST_TAB,
    CONSTANTS.MY_ACCOUNT_ADDRESS_BOOK_TAB,
    CONSTANTS.MY_ACCOUNT_PERSONAL_INFO_TAB
  ];
  if(activeMenuList.every(menu=>menu!==activeMenu)){
    navigateToPath('');
  }
  const reverseActiveMenuList = [...activeMenuList].reverse();
  useEffect(() => {
    logEventForAnalytics({
      'event': 'my-account'
    });
  }, []);

  return (
    <PigeItMyAccountStyle className="my-account-container" ref={containerRef}>
      <div className="background-container">
        <div className="header-container">
          <Button className="modal-close-button" onClick={close}>
            <IMAGES.close />
          </Button>
          <A href={path} className="active-logo" onClick={()=>{if(clickable)close()}}>
            <IMAGES.logo  />
          </A>
          {!clickable && <div className="my-account-text desktop-component">My Account</div>}
          {get(props, 'modalConfig.addressBookClickable', false) && <div className="select-text">Please Select an Address</div>}
          {get(props, 'modalConfig.packagesClickable', false) && <div className="select-text">Please Select a Package</div>}
          {get(props, 'modalConfig.cardClickable', false) && <div className="select-text">Please Select a Card</div>}

          <div className="menu-listing">
            {!clickable && <>  <A className='mobile-component arrow-left' href={getLeftArrowLink()}><IMAGES.arrowLeft /></A>
              <Button className={"menu-item" + (activeMenu === CONSTANTS.MY_ACCOUNT_PERSONAL_INFO_TAB ? ' active' : '')} component="button" onClick={()=>navigateToPath('my-account/' + CONSTANTS.MY_ACCOUNT_PERSONAL_INFO_TAB)}>
                Personal Info
            </Button>
              <Button className={"menu-item" + (activeMenu === CONSTANTS.MY_ACCOUNT_ADDRESS_BOOK_TAB ? ' active' : '')} component="button" onClick={()=>navigateToPath('my-account/' + CONSTANTS.MY_ACCOUNT_ADDRESS_BOOK_TAB)}>
                Address Book
            </Button>
              <Button className={"menu-item" + (activeMenu === CONSTANTS.MY_ACCOUNT_PACKAGES_LIST_TAB ? ' active' : '')} component="button" onClick={()=>navigateToPath('my-account/' + CONSTANTS.MY_ACCOUNT_PACKAGES_LIST_TAB)}>
                Package List
            </Button>
              <Button className={"menu-item" + (activeMenu === CONSTANTS.MY_ACCOUNT_CREDIT_CARDS_TAB ? ' active' : '')} component="button" onClick={()=>navigateToPath('my-account/' + CONSTANTS.MY_ACCOUNT_CREDIT_CARDS_TAB)}>
                Credit Card
            </Button>
              <Button className={"menu-item" + (activeMenu === CONSTANTS.MY_ACCOUNT_SHIPPING_HISTORY_TAB ? ' active' : '')} component="button" onClick={()=>navigateToPath('my-account/' + CONSTANTS.MY_ACCOUNT_SHIPPING_HISTORY_TAB)}>
                Shipping History
            </Button>
              <A className='mobile-component arrow-right' href={getRightArrowLink()}><IMAGES.arrowRight /></A>
            </>}
          </div>
         {/*<div className="border-component" />*/}
        </div>
        <div className={"content-container"+(clickable?' clickable-container':'')}>
          <div className="active-item-container">
            {activeMenu === CONSTANTS.MY_ACCOUNT_PERSONAL_INFO_TAB && (<PigeItMyAccount_PersonalInfo parentProps={props} />)}
            {activeMenu === CONSTANTS.MY_ACCOUNT_ADDRESS_BOOK_TAB && (<PigeItMyAccount_AddressBook parentProps={props} />)}
            {activeMenu === CONSTANTS.MY_ACCOUNT_PACKAGES_LIST_TAB && (<PigeItMyAccount_Packages parentProps={props} />)}
            {activeMenu === CONSTANTS.MY_ACCOUNT_CREDIT_CARDS_TAB && (<PigeItMyAccount_CreditCard parentProps={props} />)}
            {activeMenu === CONSTANTS.MY_ACCOUNT_SHIPPING_HISTORY_TAB && (<PigeItMyAccount_ShippingHistory parentProps={props} />)}
          </div>
        </div>
      </div>
    </PigeItMyAccountStyle>
  );

  function getLeftArrowLink() {
    return path + 'my-account/' + activeMenuList[(activeMenuList.indexOf(activeMenu) + 1) % activeMenuList.length];
  }
  function getRightArrowLink() {

    return path + 'my-account/' + reverseActiveMenuList[(reverseActiveMenuList.indexOf(activeMenu) + 1) % reverseActiveMenuList.length];
  }

  function close() {
    if (get(props, 'modalConfig.url', false)) {
      navigateToPath('', true);
    } else {
      dispatch(modalQueue({ mode: 'delete' }));
    }
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    state: state
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const PigeItMyAccountStyle = styled.div`
  .background-container{
    overflow: hidden;
    height: 100%;
  }
  svg.active-logo {
    ${STYLE.activeLogo}
  }
  background-color: ${Colors.white};
  height: 100%;
  text-align: center;
  overflow: auto;
  .modal-close-button {
    position: absolute;
    top: 21px;
    right: 26px;
  }
  .header-container {
    z-index: ${ZIndices.myProfileHeader};
    position: fixed;
    width: 100%;
    padding-top: 25px;
    background: ${Colors.white};
    box-shadow: 0px 0px 17px #8c8a8a;
  }
  .my-account-text {
    font-size: 36px;
    margin-bottom: 20px;
  }
  .select-text{
    font-size: 36px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .menu-listing {
  }
  .menu-item:hover{
    background-color: #f3f3f3;
    border-bottom: solid 2px #f3f3f3;
    &.active {
      border-bottom: solid 2px;
    }
  }
  .menu-item {
    border-radius:0;
    text-decoration: none;
    padding: 10px 15px;
    font-size: 16px;
    display: inline-block;
    color: ${Colors.black};
    &.active {
      color: ${Colors.blue1};
      border-bottom: solid 2px;
    }
  }
  .border-component {
    background: white;
    height: 30px;
    background-image: linear-gradient(#F2F2F2, #fff);
  }
  .content-container {
    margin-top: 184px;
    height: calc(100% - 186px);
    overflow-y: scroll;
  }
  .active-item-container {
    margin: 0 auto;
    width: 850px;
  }
  @media only screen and (max-width: 992px) {
    .active-logo{
      .logo-img{
        width: 37px !important;
        margin-left: 0 !important;
        transform: translate(0px, -5px);       
      }
      svg{
        width: 150px;
      } 
    }
    .select-text{
      font-size: 22px;
    }
    .result-item-container{
      .information-container{
        max-width: 200px;
      }
    } 
    .active-item-container {
      width: auto;
    }
    .content-container{
      margin-top: 131px;
      height: calc(100% - 143px);
      .clickable-container{
        padding-top: 173px;
      }
    }
    .menu-listing{
      display: flex;
      justify-content: space-around;
    }
    .arrow-right, .arrow-left{
      background: none;
      outline: none;
      border: none;
      padding-top: 16px;
      svg{
        width: 25px;
        height: 25px;
        path{
          fill: ${Colors.blue1};
        }
      }
    }
    .menu-item {
      font-size: 20px;
      min-width: 140px;
      display: none;
      &.active {
        display: block;
        border: none;
      }
    }
  }
`;

const PigeItMyAccount = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItMyAccountComponent);


export default PigeItMyAccount;
