import React, { useRef, useState, useMemo } from 'react';
import styled from 'styled-components';
import { connect, useDispatch, useStore } from 'react-redux';
import { modalQueue, api_login, api_getInitialData,api_getShippingHistory,api_column_mapping_get_list,api_creditcard_getList,api_packages_getList,api_addressBook_getList, api_getShippingHistoryMsr } from '../redux/actions';
import { Button, TextField, Link } from '@material-ui/core';
import { Colors } from "../common/colors";
import CONSTANTS from '../common/constants';
import { get } from 'lodash';
import PigeItCheckbox from "./PigeItCheckbox.component";
import Loader from './Loader.component';
import IMAGES from '../common/images';
import { showErrorMessage, setShipperDetails, navigateToPath ,getQueryParam, logEventForAnalytics,setHeadMetaDescription} from "../common/functions";
import { useTitle } from 'hookrouter';


function PigeItLoginComponent(props) {
  useTitle('Sign In | ShipWinner');
  setHeadMetaDescription(`Welcome back! We offer the Cheapest Rates from the Top Shipping Carriers, including FedEx and UPS. | ShipWinner.com`);
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const [ rememberMe, setRememberMe ] = useState(props.rememberMe ? true : false);
  const [ email, setEmail ] = useState(props.username ? props.username : '');
  const [ password, setPassword ] = useState('');
  const [ isEmailError, setIsEmailError ] = useState(false);
  const [ isPasswordError, setIsPasswordError ] = useState(false);
  const [ inProgress, setInProgress ] = useState(false);
  const store = useStore();

  return (
    <PigeItLoginStyle className="login-container" ref={containerRef}>
      <div className="background-container">
        <div className="content-container">
          <Button className="modal-close-button" onClick={ ()=>close(false) }>
            <IMAGES.close />
          </Button>
          <div className="header-text">Sign In</div>
          <div className="welcome-message">Welcome! Sign in to enjoy all the free tools ShipWinner has to offer.</div>
          <form>
            <div>
              <TextField onKeyDown={(e) => { if (e.keyCode === 13) { e.preventDefault(); signIn(); } }} autoFocus error={isEmailError} type='email' InputLabelProps={{ className: "email-textfield-label"}} InputProps={{ className: 'email-textfield-input-wrapper' }} fullWidth={true} className="email-textfield" placeholder="Email" label="Email" variant="outlined" value={email} onChange={(e) => { setEmail(e.target.value); }} />
            </div>
            <div>
              <TextField onKeyDown={(e) => { if (e.keyCode === 13) { e.preventDefault(); signIn(); } }} error={isPasswordError} fullWidth={true} type="password" className="password-textfield" placeholder="Password" label="Password" variant="outlined" value={password} onChange={(e) => { setPassword(e.target.value); }} />
            </div>
            <div>
              <Button fullWidth={true} className="sign-in-button" onClick={ signIn }>
                {inProgress && <Loader imageOnly />} SIGN IN
              </Button>
            </div>
            <div className="remember-me-and-forgot-password-container display-flex">
              <PigeItCheckbox checked={rememberMe} labelText="Remember me" onClick={(value) => { setRememberMe(value); }} />
              <div className="forgot-password-container flex-divided-2-cols">
                <Link component="button" classes={{button: 'forgot-password-button'}} onClick={(e) => { forgotPassword(e); }}>Forgot password?</Link>
              </div>
            </div>
          </form>
          <hr className="hr-separator" />
          <Button className="continue-as-a-guest-button" onClick={()=>close(false) }>Continue as a Guest</Button>
          <div className="new-user-sign-up-container">
            <span className="new-user-text">New User?</span> <Link classes={{button: 'sign-up-button'}} component="button" onClick={(e) => { signUp(e); }}>Sign Up</Link>
          </div>
        </div>
      </div>
    </PigeItLoginStyle>
  );

  function signIn() {
    if (inProgress) {
      return;
    }
    let emailValidationResult = validateField('email', true, true);
    let passwordValidationResult = validateField('password', true, true);
    if (emailValidationResult && passwordValidationResult) {
      setInProgress(true);
      dispatch(api_login({ username: email, password: password, callback }));
    }

    function callback(result) {
      setInProgress(false);
      if (get(result, 'success', false) || get(result, 'code', -1) == CONSTANTS.USER_LOGGED_IN_ALREADY_CODE) {
        // login success or already done - close this modal
        if (get(result, 'success', false)) {
          logEventForAnalytics({
            'event':'sign-in'
          });
        }
        dispatch(api_getInitialData({
          callback: function (result) {
            if (result.success && result.user) {
              setShipperDetails(store, dispatch);
              dispatch(api_addressBook_getList());
              dispatch(api_packages_getList());
              dispatch(api_creditcard_getList());
              dispatch(api_getShippingHistory());
              dispatch(api_getShippingHistoryMsr());
              dispatch(api_column_mapping_get_list());
            }
          }
        }));
        close(true);
      }
      else{
        showErrorMessage(result,dispatch);
      }
    }
  }

  function forgotPassword(e) {
    if (inProgress) {
      return;
    }
    if (e) {
      e.preventDefault();
    }
    close(false);
    dispatch(modalQueue({ mode: 'insert', modalConfig: CONSTANTS.MODALS_CONFIG.FORGOT_PASSWORD_ENTER_EMAIL }));
  }

  function signUp(e) {
    if (inProgress) {
      return;
    }
    if (e) {
      e.preventDefault();
    }
    navigateToPath('register', true);
  }

  function close(redirect) {
    if (inProgress) {
      return;
    }
    if (get(props, 'modalConfig.url', false)) {
      if(getQueryParam('redirectTo')&&redirect){
        navigateToPath(getQueryParam('redirectTo'), true);
        dispatch(modalQueue({ mode: 'delete' }));
      }else{
        navigateToPath('', true);
      }
    } else {
      dispatch(modalQueue({ mode: 'delete' }));
    }
 }

  // check validity of fields
  function validateField(fieldName, resetError, updateUi) {
    let isValid = false;
    switch (fieldName) {
      case 'email':
        isValid = CONSTANTS.VALID_EMAIL_REGEX.test(email);
        if (resetError || isValid) {
          setIsEmailError(false);
        }
        if (!isValid && updateUi) {
          setIsEmailError(true);
        }
        break;
      case 'password':
        isValid = password.length > 0;
        if (resetError || isValid) {
          setIsPasswordError(false);
        }
        if (!isValid && updateUi) {
          setIsPasswordError(true);
        }
        break;
      default:
        break;
    }
    return isValid;
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    username: state.data.modalsSavedData.login.username,
    rememberMe: state.data.modalsSavedData.login.rememberMe
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const PigeItLoginStyle = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  text-align: center;
  overflow: auto;
  background-color: ${Colors.white};
  .background-container {
    height: 100%;
    width: 100%;
    background-color: #fff;
    margin: 0 auto;
    position: relative;
  }
  .content-container {
    padding-top: 45px;
    padding-right: 65px;
    padding-left: 65px;
  }
  .modal-close-button {
    position: absolute;
    top: 21px;
    right: 26px;
  }
  .header-text {
    font-size: 36px;
  }
  .welcome-message {
    padding: 10px 0 10px 0;
  }
  .email-textfield {
    padding-bottom: 15px;
/*
    height: 40px;
    .email-textfield-label {
      height: 40px;
      top: -8px;
    }
    .email-textfield-input-wrapper {
      height: 40px;
    }
*/
  }
  .password-textfield {
    padding-bottom: 15px;
  }
  .sign-in-button {
    height: 56px;
    background-color: ${Colors.orange};
    color: ${Colors.white};
  }
  .forgot-password-container {
    padding-top: 8px;
    text-align: right;
  }
  .forgot-password-button {
    color: ${Colors.blue1};
    font-size: 14px;
  }
  .hr-separator {
    margin-bottom: 15px;
  }
  .continue-as-a-guest-button {
    border: 2px solid ${Colors.blue1};
    color: ${Colors.blue1};
    width: 250px;
    height: 60px;
    margin-bottom: 15px;
  }
  .new-user-sign-up-container {
    text-align: left;
    font-size: 16px;
  }
  .new-user-text {
    position: relative;
    top: 3px;
  }
  .sign-up-button {
    color: ${Colors.blue1};
    font-size: 16px;
  }
`;

const PigeItLogin = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItLoginComponent);


export default PigeItLogin;
