/* eslint react/jsx-pascal-case: off */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import { connect, useDispatch } from 'react-redux';
import { modalQueue } from '../redux/actions';
import { Button, TextField } from '@material-ui/core';
import { Colors } from "../common/colors";
import { ZIndices } from '../common/zIndices';
import PigeItRadio from "./PigeItRadio.component";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PigeItCheckbox from "./PigeItCheckbox.component";
import cloneDeep from 'lodash/cloneDeep';
import CONSTANTS from '../common/constants';
import IMAGES from '../common/images';
import STYLE from '../common/style';
import { get } from 'lodash';
import { convertPackageIdtoPackage } from '../common/functions';

import { COMPONENT_CONSTANTS } from "./PigeItInputWithHorizontalSelectAndListSelect.component";
import PigeItInput from './PigeItInput.component';

function PigeItPackageTypeSelect_PackageComponent(props) {
  const PANELS = cloneDeep(CONSTANTS.PACKAGES_TYPES);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [selectedPackageObject, setSelectedPackageObject] = useState({});
  const [customDimensionsObject, setCustomDimensionsObject] = useState({
    id: 1,
    customDimensions: true,
    dimensionsWidth: '',
    dimensionsWidthChanged: false,
    dimensionsHeight: '',
    dimensionsHeightChanged: false,
    dimensionsLength: '',
    dimensionsLengthChanged: false,
    weightRange: ['0', '150']
  });
  const [addToPackageList, setAddToPackageList] = useState(false);
  const [packageName, setPackageName] = useState('');
  const [error, setError] = useState({});
  const selectedCompany = get(props, 'modalConfig.selectedCompany', '');
  useEffect(() => {
    try {
      if (get(props, 'modalConfig.selectedPackage.selectedPackage.id', null) === 1) {
        const pack = {
          id: 1,
          customDimensions: true,
          dimensionsWidth: get(props, 'modalConfig.selectedPackage.selectedPackage.dimensionsWidth', ''),
          dimensionsWidthChanged: false,
          dimensionsHeight: get(props, 'modalConfig.selectedPackage.selectedPackage.dimensionsHeight', ''),
          dimensionsHeightChanged: false,
          dimensionsLength: get(props, 'modalConfig.selectedPackage.selectedPackage.dimensionsLength', ''),
          dimensionsLengthChanged: false,
          weightRange: ['0', '150']
        };
        setSelectedPackageObject(pack);
        setCustomDimensionsObject(pack);
        setSelectedPackage({
          selectedPackageId: 1,
          weight: {
            unit: get(props, 'modalConfig.selectedPackage.weightUnits', "lbs"),
            value: get(props, 'modalConfig.selectedPackage.weight', '')
          }
        });
        setSelectedCategory(CONSTANTS.PACKAGES_TYPES.length - 1);
      } else {
        let id = get(props, 'modalConfig.selectedPackage.selectedPackage.id', 1);
        setSelectedPackage({
          selectedPackageId: id,
          weight: {
            unit: get(props, 'modalConfig.selectedPackage.weightUnits', "lbs"),
            value: get(props, 'modalConfig.selectedPackage.weight', '')
          }
        });
        setSelectedPackageObject(convertPackageIdtoPackage(id));
        const tab = CONSTANTS.PACKAGES_TYPES.findIndex(tab => tab.strips.some(strip => strip.items.some(pack => pack.id === id)));
        setSelectedCategory(tab);
        setExpanded(get(CONSTANTS, 'PACKAGES_TYPES[' + tab + '].strips', []).findIndex(strip => strip.items.some(pack => pack.id === id)));
      }
    } catch (e) { }
  }, [])

  return (
    <PigeItPackageTypeSelect_PackageStyle className="package-type-select-package-container">
      <div className="background-container">
        <div className="header-container">
          <Button className="modal-close-button" onClick={close}>
            {<IMAGES.close />}
          </Button>
        </div>
        <div className="content-container">
          <div className="package-select-header active-logo">
            {<IMAGES.logo className="active-logo" />}
          </div>
          <div className="desktop-component display-flex fjc-space-around subheader-container">
            {
              PANELS.map(function (item, index) {
                return (<Button key={index} className={"subheader-item-button" + (selectedCategory === index ? ' active' : '')} onClick={() => { setSelectedCategory(index); }}>{item.category}</Button>);
              })
            }
          </div>
          <div className="mobile-component display-flex fjc-space-between subheader-container">
            <Button className="arrow-button left" onClick={() => { setSelectedCategory((selectedCategory - 1 < 0 ? PANELS.length - 1 : (selectedCategory - 1))); }}>{<IMAGES.arrowLeft />}</Button>
            <div className="subheader-item-text">{PANELS[selectedCategory].category}</div>
            <Button className="arrow-button right" onClick={() => { setSelectedCategory((selectedCategory + 1 >= PANELS.length ? 0 : (selectedCategory + 1))); }}>{<IMAGES.arrowRight />}</Button>
          </div>
          <div className="options-container">
            {PANELS[selectedCategory].strips && PANELS[selectedCategory].strips.map(function (stripItem, stripItemIndex) {
              const disabled = selectedCompany !== '' && stripItem.title.toLowerCase().indexOf(selectedCompany) === -1;
              return (<ExpansionPanel key={stripItemIndex} disabled={disabled} className="expansion-panel" square expanded={!disabled && expanded === stripItemIndex} onChange={() => { onPanelChange(stripItemIndex); }}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className="expand-icon" />} aria-controls={'panel-' + stripItemIndex + '-content'} id={'panel-' + stripItemIndex + '-content'}>
                  <Typography className="expansion-panel-title-container">{stripItem.title}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="expansion-panel-content-container">


                  {stripItem.items.map(function (packgeTypeItem, packageTypeItemIndex) {

                    return (
                      <React.Fragment key={packageTypeItemIndex}> <div className="display-flex fjc-space-between radio-container ">
                        <div className="display-flex">
                          <PigeItRadio name="envelope-items-group" value={packgeTypeItem.id} selectedRadioValue={selectedPackage.selectedPackageId} setSelectedRadioValue={(value) => { setSelectedPackageObject(packgeTypeItem); setSelectedPackage({ selectedPackageId: value, weight: { unit: 'lbs', value: '' } }); }} labelText={""} />
                          <div key={'d' + packageTypeItemIndex} className="item-description-and-add-to-package-list-container">
                            <div className="item-description-container" onClick={() => { setSelectedPackage(packgeTypeItem.id); setSelectedPackageObject({ ...packgeTypeItem, checkboxState: stripItem.checkboxState }); }}>
                              <div className="item-base-description">{packgeTypeItem.baseDescription}</div>
                              <div className="item-dimensions-description">{packgeTypeItem.dimensionsDescription}</div>
                            </div>
                          </div>
                        </div>
                        <div className="weight-input" >
                          <div className="package-weight">{<span className={"range-text" + (selectedPackage.selectedPackageId === packgeTypeItem.id && error.weight ? " error-text-weight" : '')}>{'Up to  ' + get(packgeTypeItem, 'weightRange[1]', '150') + ' lb. or less. '}</span>}</div>
                          <div className='package-weight'>
                            <PigeItInput type='number' error={(selectedPackage.selectedPackageId === packgeTypeItem.id && error.weight)} className='weight-input-field' label='Weight' disabled={selectedPackage.selectedPackageId !== packgeTypeItem.id} min={get(packgeTypeItem, 'weightRange[0]', '')} max={get(packgeTypeItem, 'weightRange[1]', '')} onChange={(e) => { onWeightChange(COMPONENT_CONSTANTS.INPUT_FIELD, e.target.value); }} passedTabIndex={props.passedTabIndex} value={selectedPackage.selectedPackageId === packgeTypeItem.id ? get(selectedPackage, 'weight.value', '0') : ''} />
                          </div>
                        </div>
                      </div><hr className='seperate-line' /></React.Fragment>);
                  })}
                  <div className='more-text'>{"* " + stripItem.baseDescription}</div>
                </ExpansionPanelDetails>
              </ExpansionPanel>);
            })
            }


            {PANELS[selectedCategory].customDimensions && (
              <ExpansionPanel className="expansion-panel" square expanded={expanded === 0} onChange={() => { onPanelChange(0); }}>
                <ExpansionPanelSummary className='panel-header' expandIcon={<ExpandMoreIcon className="expand-icon" />} aria-controls={'panel-0-content'} id={'panel-0-content'}>
                  <Typography className="expansion-panel-title-container">{PANELS[selectedCategory].title}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="expansion-panel-content-container">
                  <form className="display-flex fjc-space-between radio-container">
                    <div className="display-flex">
                      <PigeItRadio name="envelope-items-group" value={PANELS[selectedCategory].item.id} selectedRadioValue={selectedPackage.selectedPackageId} setSelectedRadioValue={(value) => { setSelectedPackageObject(customDimensionsObject); setSelectedPackage({ selectedPackageId: value, weight: { unit: 'lbs', value: '' } }); }} labelText={""} />
                      <div className="item-description-and-add-to-package-list-container">
                        <div className="item-description-container" onClick={() => { setSelectedPackageObject({ ...PANELS[selectedCategory].item }); }}>
                          <div className="item-base-description">{PANELS[selectedCategory].item.baseDescription}</div>
                          <div className="item-dimensions-description custom">
                            <PigeItInput type='number' error={customDimensionsObject.dimensionsWidthChanged && !isNumeric(customDimensionsObject.dimensionsWidth)} disabled={selectedPackage.selectedPackageId !== customDimensionsObject.id} autoFocus className="textfield" label="Width" variant="outlined" value={customDimensionsObject.dimensionsWidth} onChange={(e) => { setCustomDimensionsObject({ ...customDimensionsObject, dimensionsWidth: e.target.value, dimensionsWidthChanged: true }); }} />
                            <PigeItInput type='number' error={customDimensionsObject.dimensionsHeightChanged && !isNumeric(customDimensionsObject.dimensionsHeight)} disabled={selectedPackage.selectedPackageId !== customDimensionsObject.id} className="textfield" label="Height" variant="outlined" value={customDimensionsObject.dimensionsHeight} onChange={(e) => { setCustomDimensionsObject({ ...customDimensionsObject, dimensionsHeight: e.target.value, dimensionsHeightChanged: true }); }} />
                            <PigeItInput type='number' error={customDimensionsObject.dimensionsLengthChanged && !isNumeric(customDimensionsObject.dimensionsLength)} disabled={selectedPackage.selectedPackageId !== customDimensionsObject.id} className="textfield" label="Length" variant="outlined" value={customDimensionsObject.dimensionsLength} onChange={(e) => { setCustomDimensionsObject({ ...customDimensionsObject, dimensionsLength: e.target.value, dimensionsLengthChanged: true }); }} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="weight-input custom" >
                      <div className="package-weight">{<span className={"range-text" + (selectedPackage.selectedPackageId === customDimensionsObject.id && error.weight ? " error-text-weight" : '')}>{'Up to  ' + get(customDimensionsObject, 'weightRange[1]', '150') + ' lb. or less. '}</span>}</div>
                      <div className='package-weight'>
                        <PigeItInput type='number' error={(selectedPackage.selectedPackageId === customDimensionsObject.id && error.weight)} className='weight-input-field' label='Weight' disabled={selectedPackage.selectedPackageId !== customDimensionsObject.id} min={get(customDimensionsObject, 'weightRange[0]', '')} max={get(customDimensionsObject, 'weightRange[1]', '')} onChange={(e) => { onWeightChange(COMPONENT_CONSTANTS.INPUT_FIELD, e.target.value); }} passedTabIndex={props.passedTabIndex} value={selectedPackage.selectedPackageId === customDimensionsObject.id ? get(selectedPackage, 'weight.value', '0') : ''} />
                      </div>

                    </div>
                  </form>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )}
            {!props.disableAddToPackageList && <div className='add-to-package-container'>
              <PigeItCheckbox className='ta-left' checked={addToPackageList} labelText="Add to Package List" onClick={(value) => setAddToPackageList(value)} />
              {addToPackageList &&
                <PigeItInput label='Package Name' value={packageName} onChange={(e) => setPackageName(e.target.value)} name='package-name' id='package-name' error={error.packageName} style={{ width: '100%' }} />
              }
            </div>}
          </div>
          <div className="buttons-container">
            <div className='placeholder desktop-component'></div>
            <Button disabled={selectedPackage == null} className="save-and-continue-button" onClick={saveAndContinue}>SAVE AND CONTINUE</Button>
            <Button className="cancel-button" onClick={close}>CANCEL</Button>
          </div>
        </div>
      </div>
    </PigeItPackageTypeSelect_PackageStyle>
  );

  function onWeightChange(fieldName, value) {
    switch (fieldName) {
      case COMPONENT_CONSTANTS.INPUT_FIELD:
        setSelectedPackage({ ...selectedPackage, weight: { ...selectedPackage.weight, value: value } })
        break;
      case COMPONENT_CONSTANTS.HORIZONTAL_SELECT:
        setSelectedPackage({ ...selectedPackage, weight: { ...selectedPackage.weight, unit: value } })
        break;
    }
  }

  function close() {
    dispatch(modalQueue({ mode: 'delete', index: props.modalIndex }));
  }

  function onPanelChange(value) {
    setExpanded(value !== expanded ? value : null);
  }

  function saveAndContinue() {
    if (selectedPackageObject.customDimensions) {
      customDimensionsObject.dimensionsWidthChanged = true;
      customDimensionsObject.dimensionsHeightChanged = true;
      customDimensionsObject.dimensionsLengthChanged = true;
      for (let key of ['dimensionsWidth', 'dimensionsHeight', 'dimensionsLength']) {
        selectedPackageObject[key] = customDimensionsObject[key];
      }
      selectedPackageObject.selectionDisplayDimensions = customDimensionsObject.dimensionsWidth + '" x ' + customDimensionsObject.dimensionsHeight + '" x ' + customDimensionsObject.dimensionsLength + '"';
      setCustomDimensionsObject({ ...customDimensionsObject });
    }
    if (isPackageValid()) {
      const callback = get(props, 'modalConfig.callback', false);
      if (typeof (callback) === 'function') {
        if (packageName) {
          callback(selectedPackageObject, selectedPackage.weight, packageName);
        }
        else {
          callback(selectedPackageObject, selectedPackage.weight);
        }
      }
      close();
    }
  }

  function isPackageValid() {
    if (addToPackageList && packageName === '') {
      setError({ ...error, packageName: true });
      return false;
    }
    else {
      setError({ ...error, packageName: false });
    }
    if (get(selectedPackage, 'weight.value', '0') === '0' || get(selectedPackage, 'weight.value', '0') === '' || parseFloat(get(selectedPackageObject, 'weightRange[0]', '')) > parseFloat(get(selectedPackage, 'weight.value', '0')) || parseFloat(get(selectedPackageObject, 'weightRange[1]', '')) < parseFloat(get(selectedPackage, 'weight.value', '0'))) {
      setError({ ...error, weight: true });
      return false;
    }
    else {
      setError({ ...error, weight: false });
    }
    if (selectedPackageObject && selectedPackageObject.customDimensions && isNumeric(selectedPackageObject.dimensionsWidth) && isNumeric(selectedPackageObject.dimensionsHeight) && isNumeric(selectedPackageObject.dimensionsLength)) {
      return true;
    }
    else if (!selectedPackageObject.customDimensions) {
      return true;
    }
    return false;
  }

  function isNumeric(fieldValue) {
    return (fieldValue && fieldValue.length && !isNaN(fieldValue));
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    selectedTab: get(ownProps, 'modalConfig.selectedTab', 3),
    disableAddToPackageList: get(ownProps, 'modalConfig.disableAddToPackageList', false),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  };
};

const PigeItPackageTypeSelect_PackageStyle = styled.div`
  background-color: ${Colors.white};
  height: 100%;
  text-align: center;
  overflow-y: scroll;
  .more-text{
    font-family: 'museo_sans300';
  }
  .weight-input-field{
    width: 120px;
  }
  .textfield{
    width: 80px;
  }
  .package-select-header{
    margin-top: 5%;
  }
  .seperate-line{
    border: none;
    border-bottom: solid 1px #f1f0f0;
    width: 100%;
  }
  .add-to-package-container{
    max-width: 348px;
    margin: auto;
    padding-right: 13px;
    margin-bottom: 20px;
    .field-label {
      font-size: 16px;
      color: ${Colors.lightGray2};
      margin-bottom: 11px;
    }
   
    .input-field {
      &.error{
        border: solid 1px ${Colors.error};
      }
      width: 100%;
      margin-bottom: 11px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      border: solid 1px lightgray;
      border-radius: 3px;
      font-family: museo_sans500;
    }
  }
   .error-weight{
     input{
        border: solid 1px ${Colors.error};
     }
    }
  .modal-close-button {
    position: absolute;
    top: -34px;
    right: 26px;
  }
  .header-container {
    z-index: ${ZIndices.myProfileHeader};
    position: fixed;
    width: 100%;
    background: ${Colors.white};
  }
  .content-container {
    width: 992px;
    margin: auto;
  }

  .package-select-header svg.active-logo {
    ${STYLE.activeLogo}
  }
  .subheader-container {
    overflow: hidden;
    background-color: ${Colors.panelBackground};
    color: ${Colors.blue1};
    position: relative;
    height: 65px;
    line-height: 65px;
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 21px;
    /* MOBILE */
    .arrow-button {
      &.left {
        display: inline-block;
        text-align: left;
        padding-left: 20px;
      }
      &.right {
        display: inline-block;
        text-align: right;
        padding-right: 20px;
      }
      width: 33%;
      svg {
        height: 26px;
        width: 12px;
        padding-top: 7px;
        path {
          fill: ${Colors.blue1};
        }
      }
    }
  }
  .subheader-item-button{
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    display: block;
    width: 100%;
    font-size: 18px;
    border-radius: 0;
    &.active{
      border-bottom: solid 2px ${Colors.blue1};
      color: ${Colors.blue1};
    }
  }
  .subheader-item-text {
    BUTTON:hover{
      background:none;
    }
    width: 33%;
    SPAN {
      text-transform: none;
      font-size: 21px;
    }
    &.active button {
      color: ${Colors.blue1};
    }
  }
  .expansion-panel {
    .MuiExpansionPanelSummary-root{
      height: 50px;
      min-height: unset;
      &.Mui-expanded{
        height: 50px;
        min-height: unset;
      }
    }
    background-color: ${Colors.panelBackground};
    box-shadow: none;
    margin-bottom: 10px;
    .expand-icon {
      color: ${Colors.blue1};
    }
    .expansion-panel-title-container {
      font-family: museo_sans500, sans-serif;
      font-size: 18px;
    }
    .expansion-panel-content-container {
      min-width: 95%;
      text-align: left;
      display: inline-block;
      background-color: ${Colors.white};
    }
    .item-description-and-add-to-package-list-container {
      text-align: left;
    }
    .weight-input{
      &.custom{
        margin-top:13px;
      }
      margin-top: 6px;
      width: 20%;
    }
    .range-text{
      text-align:center;
      color:#70777d;
      font-size:small;
      margin-top:1px;
    }
    .error-text-weight{
      color:${Colors.error};
    }
    .package-weight{
      margin-top:0;
      margin-bottom: 10px;
      color: rgba(0, 0, 0, 0.87);
      display: flex;
      justify-content: flex-end;
    }
    .input-container{
        width: 200px;
      }
    .p{
        margin-top:0px;
    }
    .item-description-container {
      font-family: museo_sans300, sans-serif;
      cursor: pointer;
      margin-top: 10px;
      .item-base-description{
        font-family: museo_sans500;
      }
    }
    .item-dimensions-description {
      display: flex;
      margin-top: 15px;
      justify-content: space-between;
      width: 267px;
    }
  }
  .buttons-container {
    margin-bottom: 50px;
  }
  .save-and-continue-button {
    &.MuiButtonBase-root.Mui-disabled{
      color: ${Colors.white};
      background-color: rgba(0, 0, 0, 0.12);
    }
    font-size: 18px;
    border: none;
    color: #fff;
    width: 350px;
    height: 56px;
    font-size: 18px;
    background-color: ${Colors.orange};
    color: ${Colors.white};
  }
  .cancel-button {
    height: 56px;
    color: ${Colors.blue1};
    margin-left: 10px;
  }
  .placeholder {
    width: 72px;
    display: inline-block;
  }
  .width-wrapper, .height-wrapper, .length-wrapper {
   /* width: 120px;*/
  }
  .width-wrapper, .height-wrapper {
   /* margin-right: 20px;*/
  }
  @media only screen and (max-width: 992px) {
    .active-logo{
      .logo-img{
        width: 37px !important;
        margin-left: 0 !important;
        transform: translate(0px, -5px);       
      }
      svg{
        width: 150px;
      } 
    }
    .textfield{
      width: 73px;
    }
    .add-to-package-container{
      padding-right:0;
    }
    .modal-close-button{
      top: 0;
    }
    .content-container {
      margin: 0 20px;
      width:unset;
      margin-top:5%;
    }
    .radio-container{
      flex-direction:column;

    }
    .expansion-panel{
      .item-dimensions-description{
        width: unset;
      }
      .package-weight{
        justify-content:flex-start;
      }
      .item-description-and-add-to-package-list-container{
        width: 79%;
      }
      .weight-input{
        margin-top: 15px;
        margin-left: 47px;
        width: 80%;
      }
      .input-container{
        width: 150px;
      }
    }
    .save-and-continue-button {
      width: 100%;
    }
    .expansion-panel{
      .expansion-panel-content-container{
        width: 90%;
      }
      .item-dimensions-description{
        &.custom{
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          width: 101%;
        }
      }
    }
  }
`;

const PigeItPackageTypeSelect_Package = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItPackageTypeSelect_PackageComponent);


export default PigeItPackageTypeSelect_Package;
