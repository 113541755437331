import { Colors } from './colors';

const STYLE = {
  activeLogo: `
    height: 48px;
    path.l1_01, path.l1_02 {
      fill: ${Colors.darkBlue2};
    }
    path.l1_03 {
      fill: ${Colors.orange};
    }
    path.l2_01, path.l2_02, path.l2_03, path.l2_04, path.l2_05, path.l2_06, path.l2_07, path.l2_08, path.l2_09, path.l2_10 {
      fill: ${Colors.darkGray};
    }
  `
};

export default STYLE;