/* eslint eqeqeq: off */
import React from 'react';
import styled from 'styled-components';
import { Colors } from "../common/colors";
import { FormControlLabel, Radio } from '@material-ui/core';

function PigeItRadioComponent(props) {
  return (
    <PigeItRadioStyle className={"container" + (props.className ? (' ' + props.className) : '')+(props.disabled?' disabled':'')}>
      <FormControlLabel
        classes={{label: 'label'}}
        control={<Radio
          classes={{ checked: 'radio-checked'}}
          className="radio"
          checked={isChecked()}
          onClick={() => { if (props.setSelectedRadioValue) { props.setSelectedRadioValue(props.value); } } }
          name={props.name}
          disabled={props.disabled ? true : false}
          value={props.value} />
        }
        label={props.labelText}
      />
    </PigeItRadioStyle>
  );

  function isChecked() {
    if (props.value === props.selectedRadioValue) {
      // full match always true
      return true;
    }
    if (typeof(props.value) === typeof(props.selectedRadioValue) && props.value !== props.selectedRadioValue) {
      return false;
    }
    if (typeof(props.value) === 'object' && typeof(props.selectedRadioValue) === 'object') {
      // have objects - compare object fields
      for (const key in props.value) {
        if (props.value[key] != props.selectedRadioValue[key]) {
          return false;
        }
      }
      return true;
    }
    else if ((props.value && !props.selectedRadioValue) || (!props.value && props.selectedRadioValue)) {
      return false;
    }
    // defaults to true
    return true;
  }
}

const PigeItRadioStyle = styled.div`
  &.disabled {
    svg{
      path{
        fill:${Colors.lightGray3};
      }
    }
  }
  .container {
    text-align: left;
  }
  .label {
    font-size: 14px;
    color: ${Colors.black};
  }
  span.radio {
    color: ${Colors.blue1};
  }
  .checkbox svg {
    height: 27px;
    width: 27px;
  }
  span.radio-checked svg {
    /*
    color: ${Colors.white};
    background-color: ${Colors.blue1};
    height: 27px;
    width: 27px;
    */
  }
`;

export default PigeItRadioComponent;
