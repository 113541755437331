import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Colors } from "../common/colors";
import { get } from "lodash";
import { googleCache } from "../common/cache";
import { useThrottleCallback } from "@react-hook/throttle";

const getPredictionsFromGoogle = async (input, sessionToken) => {
  console.log(sessionToken);
  try {
    const fromCache = googleCache.getValue(`autocomplete:${input}`);
    if (fromCache) return fromCache;
    const service = new window.google.maps.places.AutocompleteService();
    const predictions = await service.getPlacePredictions({
      input,
      sessionToken,
      componentRestrictions: { country: ["us", "pr", "vi", "gu", "mp"] },
      types: ["geocode"],
    });
    googleCache.setValue(
      `autocomplete:${input}`,
      predictions?.predictions || []
    );
    return predictions?.predictions || [];
  } catch (e) {
    console.error(e);
    return [];
  }
};
export const getPlaceDetailsFromGoogle = (placeId, sessionToken) =>
  new Promise((resolve) => {
    console.log(sessionToken);
    try {
      const fromCache = googleCache.getValue(`place_details:${placeId}`);
      if (fromCache) return resolve(fromCache);
      const placesService = new window.google.maps.places.PlacesService(
        document.getElementById("places_element")
      );
      placesService.getDetails(
        {
          placeId,
          fields: [
            "address_components",
            "geometry",
            "formatted_address",
            "place_id",
            "types",
          ],
          sessionToken,
        },
        (place, code) => {
          if (code !== window.google.maps.places.PlacesServiceStatus.OK)
            console.error(code);
          googleCache.setValue(`place_details:${placeId}`, place);
          resolve(place);
        }
      );
    } catch (e) {}
  });
const PigeItInputAddressGoogleLoader = (props) => {
  if (props.googleMaspReady) {
    return <PigeItInputAddressComponent {...props} />;
  }
  return (
    <div style={{ margin: "10px" }}>
      <img style={{ width: "14px" }} src="/images/loader.gif" />
      <span style={{ color: "#888" }}>loading addresses...</span>
    </div>
  );
};

const Prediction = ({ pred, onClick }) => {
  const description = pred.description;
  const m_subs = pred.matched_substrings || [];
  const parts = [];
  let lastChar = 0;
  let key = 0;
  for (const { length, offset } of m_subs) {
    if (offset > lastChar) {
      parts.push(
        <span key={key}>{description.substr(lastChar, offset - lastChar)}</span>
      );
      key++;
    }
    parts.push(<b key={key}>{description.substr(offset, length)}</b>);
    lastChar = offset + length;
    key++;
  }
  if (lastChar + 1 < description.length) {
    parts.push(<span key={key}>{description.substr(lastChar)}</span>);
  }
  return (
    <button type="button" onClick={onClick} className="row-container">
      <img
        src={`${`${process.env.REACT_APP_PUBLIC_URL}images/autocomplete-icons.png`}`}
      />
      <p>{parts}</p>
    </button>
  );
};
function PigeItInputAddressComponent(props) {
  const shouldGetPreds = useRef(false);
  const sessionToken = useRef("");
  const [predictions, setpredictions] = useState([]);
  const [inputValue, setinputValue] = useState(props.passedValue);
  const inputEl = useRef(null);
  useEffect(() => {
    setinputValue(props.passedValue);
    getPredictions(props.passedValue);
  }, [props.passedValue]);

  const [focused, setfocused] = useState(false);

  const getPredictions = useCallback(
    useThrottleCallback(async (input) => {
      if (props.googleMaspReady && input) {
        let localSessionToken = sessionToken.current;
        if (props.googleMaspReady && !localSessionToken) {
          localSessionToken =
            new window.google.maps.places.AutocompleteSessionToken();
          sessionToken.current = localSessionToken;
        }
        const predictions = await getPredictionsFromGoogle(
          input,
          localSessionToken
        );
        setpredictions(predictions);
      } else {
        setpredictions([]);
      }
    }, 5),
    [props.googleMaspReady, sessionToken]
  );
  const onInputChange = useCallback(
    async (e) => {
      setinputValue(e.target.value);
      getPredictions(e.target.value);
    },
    [props.googleMaspReady]
  );

  const getPlaceDetails = useCallback(
    async (place_Id) => {
      if (props.googleMaspReady) {
        const placeObject = await getPlaceDetailsFromGoogle(
          place_Id,
          sessionToken.current
        );
        const placeId = placeObject.place_id;
        if (typeof props.onChange === "function")
          props.onChange({ placeObject, placeId });
        shouldGetPreds.current = true;
        sessionToken.current =
          new window.google.maps.places.AutocompleteSessionToken();
        setinputValue(get(placeObject, "formatted_address", ""));
      }
    },
    [props.googleMaspReady, sessionToken]
  );

  useEffect(() => {
    getPredictions(inputValue);
  }, [props.googleMaspReady]);

  useEffect(() => {
    if (shouldGetPreds.current) {
      getPredictions(inputValue);
      shouldGetPreds.current = true;
    }
  }, [inputValue, shouldGetPreds]);

  return (
    <PigeItInputAddressStyle
      className={
        props.className +
        (props.disabled ? " disabled" : "") +
        (props.simple ? " simple" : "")
      }
    >
      {!props.simple && (
        <label
          className={
            "address-label" +
            (focused ? " focused" : "") +
            (props.error ? " error" : "")
          }
        >
          Address
        </label>
      )}
      <input
        value={inputValue}
        disabled={props.disabled}
        onFocus={() => setfocused(true)}
        onBlur={() => setTimeout(() => setfocused(false), 500)}
        type="text"
        autocomplete="off"
        tabIndex={props.passedTabIndex}
        ref={inputEl}
        id={props.inputId}
        onChange={onInputChange}
        className={
          "address-input" +
          (props.error ? " error" : "") +
          (props.simple ? " simple" : "") +
          (focused && predictions.length ? " open" : "")
        }
        name={props.inputName}
      />
      <div
        className={`dropdown-container ${
          focused && predictions.length ? "" : "n-focused"
        }`}
      >
        {predictions.map((pred, i) => (
          <Prediction
            key={i}
            pred={pred}
            onClick={() => getPlaceDetails(pred.place_id)}
          />
        ))}
        <div className="credit-container">
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}images/powered_by_google_on_white.png`}
          />
        </div>
      </div>
    </PigeItInputAddressStyle>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    googleMaspReady: state.data.googleMaspReady,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

const PigeItInputAddressStyle = styled.div`
  position: relative;
  margin-top: -12px;
  .credit-container {
    display: flex;
    justify-content: flex-end;
    img {
      width: 120px;
      margin: 3px 2px;
    }
  }
  .dropdown-container {
    box-shadow: unset;
    border: solid 1px #2699fb;
    border-radius: 3px;
    border-top-left-radius: 0;
    border-top: none;
    border-top-right-radius: 0;
    font-family: museo_sans300;
    position: absolute;
    width: calc(100% - 2px);
    background-color: #fff;
    color: black;
    z-index: 6;
    .row-container {
      background: none;
      border: none;
      width: 100%;
      cursor: pointer;
      padding: 10px 8px;
      border-top: solid 1px #dfdfdf;
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        display: block;
        margin-bottom: 3px;
        margin-right: 6px;
      }
      p {
        margin: 0;
      }
    }
    &.n-focused {
      display: none;
    }
  }
  &.simple {
    position: unset;
    margin-top: unset;
  }
  &.disabled {
    .address-input {
      background-color: #ececec;
    }
    .input-select-from-list-button {
      svg {
        path {
          fill: #b1afaf;
        }
      }
      cursor: unset;
    }
  }
  .address-label {
    &.focused {
      color: ${Colors.blue1};
    }
    &.error {
      color: ${Colors.error};
      border: none;
    }
    color: #9daab1;
    transform: translate(10px, 6px);
    display: block;
    background: white;
    width: 41px;
    font-size: 11px;
    padding: 0 3px;
  }
  .address-input:focus {
    border-color: ${Colors.blue1};
  }
  .address-input {
    background-color: #fff;
    border: 1px solid #c3c7ca;
    border-radius: 5px;
    display: block;
    box-sizing: border-box;
    width: 100%;
    font-size: 14px;
    line-height: 38px;
    height: 38px;
    padding: 0 10px 0 10px;
    color: ${Colors.black};
    outline: none;
    &:focus::placeholder {
      color: transparent;
    }
    &::placeholder {
      color: #9daab1;
    }
    &.error {
      border-color: ${Colors.error};
    }
    &.simple {
      border: none;
      border-radius: 0;
      height: 33px;
      font-size: 16px;
      padding-left: 5px;
    }
    &.open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: none;
    }
  }
  .input-select-from-list-button {
    outline: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    top: 0;
    right: 0;
    height: 40px;
    width: 36px;
    padding: 2px 0 0;
    .input-select-from-list-button-icon-container {
    }
  }
`;

const PigeItInputAddress = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItInputAddressGoogleLoader);

export default PigeItInputAddress;
