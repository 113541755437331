/* eslint react/jsx-pascal-case: off, react-hooks/exhaustive-deps: off */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { genericSetValue, api_packages_getList, api_packages_remove,modalQueue, setupAnimatedLoader } from '../redux/actions';
import { Colors } from "../common/colors";
import InputSearch from './InputSearch.component';
import IMAGES from '../common/images';
import Loader from "./Loader.component";
import CONSTANTS from '../common/constants';
import { get } from 'lodash';
import { convertPackageIdtoPackage,setHeadMetaDescription } from "../common/functions";
import { useTitle } from 'hookrouter';
import { ZIndices } from "../common/zIndices";


function PigeItMyAccount_PackagesComponent(props) {
  useTitle('My Account | Package List | ShipWinner');
  setHeadMetaDescription(`Welcome to the Package List! Our Free Package List tool compiles a list of your Preferred Packaging Options. Once your package is just right, it's saved here, making it easy to replicate later orders. Whether you're using FedEx or UPS, all your great choices will be readily available. Now you can ship that package with just a few clicks! Open your Free Shipping Account on ShipWinner to start Saving on Shipping Costs Now. | ShipWinner.com`);

  const dispatch = useDispatch();
  const [ inProgress, setInProgress ] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [ itemsClickable, setItemsClickable ] = useState(get(props,'parentProps.modalConfig.packagesClickable', false));

  useEffect(function () {
    // read the list from the server
    setInProgress(true);
   // dispatch(genericSetValue({ path: 'api.packages_getList', value: undefined }));
    dispatch(api_packages_getList({ callback: function() {
      setInProgress(false);
    }}));
  }, []);

  return (
    <PigeItMyAccount_PackagesStyle className="my-account-personal-info-container">
      <div className="mobile-component bottom-banner">
        <div className="add-to-packagesList-control flex-divided-3-cols">
          <button className="add-new-packages-button" onClick={addNewPackage}>{<IMAGES.plus />}<span>Add new package</span></button>
        </div>
      </div>
      <div className="background-container">
      <div className='desktop-component place-holder'/>
        <div className="display-flex fjc-space-around">
          <div className="desktop-component title-text flex-divided-3-cols">
            Package List { inProgress && (<Loader imageOnly={true} />)}
          </div>
          <div className="search-input-container flex-divided-3-cols">
            <InputSearch inputName={"search-packages"} inputId={"search-packages"} inputPlaceholder={"Search packages"} onSearch={onSearch}  />
          </div>
          <div className="desktop-component add-to-packagesList-control flex-divided-3-cols">
            <button className="add-new-packages-button" onClick={addNewPackage}>{<IMAGES.plus />}<span>Add new package</span></button>
          </div>
        </div>
        <hr className="desktop-component seperator" />
        <div className="display-flex fd-column">
          {get(props, 'apiData_packagesGetList.result.success', false) && get(props, 'apiData_packagesGetList.result.results', []).map((item, i) => {
            item.pack = convertPackageIdtoPackage(item.packageTypeId);
            item.title = item.pack ? item.pack.selectionDisplayTitle : 'My Custom';
            item.dimensions = item.pack ? item.pack.selectionDisplayDimensions : '';
            if (!isMatchingFilter(item)) {
              return (null);
            }
            return (
              <div key={i} className={"result-item-container display-flex fd-row fjc-space-between" + (itemsClickable ? ' clickable' : '')}>
                <div className="information-container" tabIndex={(itemsClickable ? 0 : null)} onClick={() => { onInformationClick(item, i); }}>
                  <div className="name">{item.name}</div>
                  <div className="display-flex fd-row">
                    <div className="label big">{item.title+':'} </div>&nbsp;&nbsp;
                    <div className="label">Weight</div>&nbsp;<div className="content">{item.weight + ' ' + (parseInt(item.weightUnitId) === 1 ? "LBS" : "KILO" )+ ';'}</div>
                    <div className="label">Size</div>&nbsp;<div className="content">{parseInt(item.packageTypeId) === 1 ? item.sizeWidth + '\" x ' + item.sizeHeight + '\" x ' + item.sizeLength + '\" ' + (parseInt(item.sizeUnitId) === 1 ? "IN" : "CM" ): item.dimensions}</div>
                  </div>
                  <div className="display-flex fd-row">
                    <div className="label">content</div>&nbsp;<div className="content">{item.content+';'}</div>
                    <div className="label">Contains</div>&nbsp;<div className="content">{item.content+';'}</div>
                    <div className="label">Value</div>&nbsp;<div className="content">{item.value}</div>
                  </div>
                </div>
                <div className="actions-container">
                  <button className="edit-button" onClick={() => { editPackage(item); }}>{<IMAGES.edit />} <span className='desktop-component'>&nbsp;&nbsp;Edit</span></button>
                  &nbsp;
                  <button className="remove-button" onClick={() => { removePackage(item); } }>{<IMAGES.remove />} <span className='desktop-component'>&nbsp;&nbsp;Remove</span></button>
                </div>
              </div>);
          })}
        </div>
      </div>
      <div className="save-changes-button-container">
      </div>
    </PigeItMyAccount_PackagesStyle>
  );

  function removePackage(item) {
    if (inProgress) {
      return;
    }
    setInProgress(true);
    dispatch(setupAnimatedLoader({ isVisible: true, text: 'Deleting package....' }));
    dispatch(api_packages_remove({
      id: item.id,
      callback:function(result){
        setInProgress(false);
        dispatch(genericSetValue({ path: 'api.packages_getList', value: { result: { success: true, results: get(props, 'apiData_packagesGetList.result.results').filter(i => i.id !== item.id) } } }));
        dispatch(setupAnimatedLoader({ isVisible: false }));
      }
    }));
  }

  function onSearch(value) {
    setFilterValue(value);
  }

  // TODO - Search function not implemented
  function isMatchingFilter(item) {
    if (filterValue === '') {
      return true;
    }
    for (let field of ['name', 'title', 'weight', 'value','content']) {
      if (get(item,field,'').indexOf(filterValue) > -1) {
        return true;
      }
    }
    return false;
  }

  function addNewPackage() {
    const modalConfig = JSON.parse(JSON.stringify(CONSTANTS.MODALS_CONFIG.PACKAGES_ADD_EDIT));
    dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
  }

  function editPackage(selectedPackage) {
    const modalConfig = JSON.parse(JSON.stringify(CONSTANTS.MODALS_CONFIG.PACKAGES_ADD_EDIT));
    modalConfig.packageToEdit = {...selectedPackage};
    dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
  }

  function onInformationClick(item, i) {
    if (itemsClickable) {
      const callback = get(props,'parentProps.modalConfig.callback', false);
      if (typeof(callback) === 'function') {
        callback(item);
        dispatch(modalQueue({ mode: 'delete' }));
      }
    }
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    apiData_packagesGetList: state.data.api.packages_getList
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  };
};

const PigeItMyAccount_PackagesStyle = styled.div`
  .bottom-banner {
    width: 100%;
    position: fixed;
    background: white;
    z-index: ${ZIndices.myAccountPackages};
    bottom: 0;
    box-shadow: 0px -1px 6px #d0d0d0;
  }
  .place-holder{
    height: 40px;
  }
  .edit-button, .remove-button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .background-container {
    padding: 1px 18px 0px 18px;
    background-color: ${Colors.panelBackground};
  }
  .title-text {
    text-align: left;
    font-family: museo_sans300,sans-serif;
    color: ${Colors.darkBlue1};
    font-size: 30px;
    margin: 19px 0px 0px 25px;
  }
  .search-input-container {
    margin: 16px 0 0 0;
  }
  .add-to-packagesList-control {
    text-align: right;
    margin: 12px 25px 0px 0px;
  }
  .add-new-packages-button {
    font-size: 16px;
    height: 53px;
    background-color: transparent;
    border: none;
    color: ${Colors.blue1};
    svg {
      position: relative;
      top: -1px;
    }
    SPAN {
      position: relative;
      top: -12px;
      padding-left: 5px;
    }
  }
  .seperator {
    margin-bottom: 30px;
  }
  .result-item-container {
    &.clickable{
      cursor: pointer;
    }
    padding: 15px 6px;
    margin-bottom: 9px;
    background-color: ${Colors.white};
    .information-container {
      width: 100%;
      color: ${Colors.lightGray2};
      text-align: left;

      .name {
        margin-bottom: 9px;
        font-size: 16px;
        color:#62686D;
        
      }
      .label {
        font-size: 12px;
        font-family:museo_sans500;
        color: #323232;
      }
      .big{
        font-size: 14px;
      }
      
      .content {
        font-family:museo_sans300;
        font-size: 12px;

      }
    }
    .actions-container {
      min-width: 173px;
      margin-top: 18px;
      .edit-button {
        color: ${Colors.blue1};
        font-size: 14px;
        SPAN {
          text-transform: none;
        }
      }
      .remove-button {
        color: ${Colors.red};
        font-size: 14px;
        SPAN {
          text-transform: none;
        }
      }
    }
  }
  @media only screen and (max-width: 992px) {
    .result-item-container {
      .actions-container {
        min-width: unset;
      }
    }
    .background-container{
      padding: 12px 7px 90px 7px;
      background-color: ${Colors.panelBackground};
    }
    .add-to-packagesList-control {
      margin: unset;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .search-input-container {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .flex-divided-3-cols {
      flex: 0 0 100%;
    }
    .big {
      white-space: nowrap;
      overflow: hidden;
      font-size: 14px;
    }
    .content {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-family: museo_sans300;
      font-size: 12px;
    }
  }
`;

const PigeItMyAccount_Packages = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItMyAccount_PackagesComponent);


export default PigeItMyAccount_Packages;
