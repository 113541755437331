/* eslint react/jsx-pascal-case: off */
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch, useStore } from 'react-redux';
import { modalQueue, genericSetValue } from '../redux/actions';
import { Button } from '@material-ui/core';
import { Colors } from "../common/colors";
import { ZIndices } from '../common/zIndices';
import { identifyCreditCardType, logEventForAnalytics } from "../common/functions";
import CONSTANTS from '../common/constants';
import { get } from 'lodash';
import PigeItCheckbox from './PigeItCheckbox.component';
import PigeItSelect from './PigeItSelect.component';
import IMAGES from '../common/images';
import STYLE from '../common/style';
import moment from 'moment';

function PigeItThankYouComponent(props) {
  const dispatch = useDispatch();
  dispatch(genericSetValue({
    path: 'api', value: {
      getInitialData: props.apiData_getInitialData,
      verifyPayment: props.apiData_verifyPayment,
      pay: props.apiData_pay
    }
  }));
  const store = useStore();
  const containerRef = useRef(null);
  const NOTIFICATION_OPTIONS = [{ text: 'Choose notification option', value: '' }, { text: 'TODO', value: '1' }];
  const [selectedNotificationOption, setSelectedNotificationOption] = useState(NOTIFICATION_OPTIONS[0]);
  const [activeMenu, setActiveMenu] = useState(get(props, 'modalConfig.initialTab', false) || CONSTANTS.MY_ACCOUNT_PERSONAL_INFO_TAB);
  const [notification, setNotification] = useState(false);
  let cardNumber = '';
  let Icon = null;
  if (parseInt(get(props, 'apiData_verifyPayment.request.paymentDetails.paymentType', 1)) === 1) {
    cardNumber = get(props, 'purchaseProgress.payment.creditCard.cardNumber', '');
    const cardType = identifyCreditCardType(cardNumber);
    Icon = cardType ? IMAGES[cardType] : null;
    if (cardNumber.length > 4) {
      cardNumber = cardNumber.substring(cardNumber.length - 4);
    }
  }
  else {
    cardNumber = get(props, 'apiData_verifyPayment.request.paymentDetails.existingPaymentLast4Digits', '');
    const cardType = identifyCreditCardType(null, get(props, 'apiData_verifyPayment.request.paymentDetails.existingPaymentCardType', ''));
    Icon = cardType ? IMAGES[cardType] : null;
  }

  const shippingOption = parseInt(get(props, 'apiData_verifyPayment.request.shippingDetails.shippingDropoffTypeId', '1'));
  const shippingOptionText = shippingOption === CONSTANTS.SHIPPING_DROPOFF_TYPE_ID_DROPOFF ? "Drop Off" : "Pick Up";
  const date = moment(get(props, 'apiData_verifyPayment.request.shippingDetails.date', '')).format('YYYY/DD/MM');
  const time = get(props, 'apiData_verifyPayment.request.shippingDetails.time', '');

  logEventForAnalytics({
    'event': 'purchase',
    "transactionId": get(props, 'apiData_pay.result.transactionId', ''),
    "transactionTotal": get(props, 'apiData_verifyPayment.result.price', '0'),
  });
  return (
    <PigeItThankYouStyle className="thank-you-container" ref={containerRef}>
      <div className="background-container">
        <div className="header-container">
          <Button className="modal-close-button" onClick={close}>
            <IMAGES.close />
          </Button>
          <div className='logo-container'>
            <IMAGES.logo className="active-logo" />
          </div>
          <div className='bottom-banner mobile-component'>
            <a href={getLabelLink()} target='_blank' className='print-button'>
              PRINT LABEL
            </a>
          </div>
          <div className='thank-you-text'>Thank you for shipping with ShipWinner!</div>
          <div className='tracking-number-text'>You tracking number is <span>{get(props, 'apiData_pay.result.masterTrackingNumber', '')}</span></div>
          {get(props, 'apiData_pay.result.shippingPickupId', '') && <div className='tracking-number-text'>You pickup number is <span>{get(props, 'apiData_pay.result.shippingPickupId', '')}</span></div>}
          <div className='send-email-text'>Label will be sent to <span>{get(props, 'apiData_verifyPayment.request.paymentDetails.email', '')}</span></div>
          <div className='info-container display-flex fjc-space-between'>
            <div className='first-col'>
              <div className='shipper-receiver-info display-flex fjc-space-between'>
                <div className='shipper-info'>
                  <div className='label'>Shipper Information</div>
                  <div className='text'>{get(props, 'purchaseProgress.senderAddress', '')}</div>
                  <div className='text'>{get(props, 'purchaseProgress.shipper.addressLine2', '')}</div>
                  <div className='text'>{get(props, 'purchaseProgress.shipper.name', '')}, {get(props, 'purchaseProgress.shipper.phone', '')}</div>
                </div>
                <div className='receiver-info'>
                  <div className='label'>Receiver Information</div>
                  <div className='text'>{get(props, 'purchaseProgress.recipientAddress', '')} </div>
                  <div className='text'>{get(props, 'purchaseProgress.recipient.addressLine2', '')} </div>
                  <div className='text'>{get(props, 'purchaseProgress.recipient.name', '')}, {get(props, 'purchaseProgress.recipient.phone', '')}</div>
                </div>
              </div>
              <div className='other-info display-flex fjc-space-between'>
                <div className='first-col'>
                  <div className='payments-info-value display-flex'>
                    <div className='payment-method'>
                      <div className='label'>Payment</div>
                      <div className='card-info display-flex fjs-start'>
                        <div className='icon'>{Icon && <Icon />}</div>
                        <div className='number'>{cardNumber}</div>
                      </div>
                    </div>
                    <div className={'value-info' + (!props.modalConfig.hasInsurance ? ' hidden' : '')}>
                      <div className='label'>Value</div>
                      <div className='text'>{'$ ' + sumPackagesValue()}</div>
                    </div>
                  </div>
                </div>
                <div className='second-col'>
                  <div className='sending-options'>
                    <div className='label'>Service</div>
                    <div className='text'>{shippingOptionText}</div>
                    {shippingOption === CONSTANTS.SHIPPING_DROPOFF_TYPE_ID_COURIER && <div className='text'>{date + '  ' + time}</div>}
                  </div>
                </div>
              </div>
            </div>
            <div className='second-col'>
              <div className='shipping-options'>
                <div className='label'>Shipping Options</div>
                <div className='summery'>You ship with <span className='icon'>{get(props, 'purchaseProgress.activeCompany', '') === 'UPS' ? <IMAGES.ups /> : <IMAGES.fedex />}</span><span>, {[...get(props, 'purchaseProgress.morePackages', []), get(props, 'purchaseProgress.firstPackage', {})].length} packages</span></div>
                <div className='packages-list'>
                  {[...get(props, 'purchaseProgress.morePackages', []), get(props, 'purchaseProgress.firstPackage', {})].map((pack, i) => {
                    return (
                      <div key={i} className='package-info display-flex fjc-space-between'>
                        <div className='number'>{'#' + (i + 1)}</div>
                        <div className='type'>{get(pack, 'selectedPackage.selectionDisplayTitle', '')}</div>
                        <div className='weigth'>{get(pack, 'weight', '0')} {get(pack, 'weightUnits', 'lbs')}</div>
                        <div className='size'>{get(pack, 'selectedPackage.selectionDisplayDimensions', '')}</div>

                      </div>)
                  })}
                </div>
                {[...get(props, 'purchaseProgress.morePackages', []), get(props, 'purchaseProgress.firstPackage', {})].length > 3 && <button className='more-button' onClick={allPackages}>All Packages</button>}
              </div>
            </div>
          </div>
          <div className='total-container'>
            <hr className='line' />
            <div className='total'>In Total: <span>${get(props, 'apiData_verifyPayment.result.price', '0')}</span></div>
            <a href={getLabelLink()} target='_blank' className='print-button desktop-component'>
              PRINT LABEL
            </a>
          </div>
          <hr className='line desktop-component' />
          <button className='new-shipping-button desktop-component' onClick={close}>
            NEW SHIPPING
          </button>
          <div className='place-holder mobile-component' />
        </div>

      </div>
    </PigeItThankYouStyle >
  );
  function sumPackagesValue() {
    const packages = get(props, 'purchaseProgress.morePackages', []).concat(get(props, 'purchaseProgress.firstPackage', []));
    return packages.map(p => parseFloat(get(p, 'value', 0))).reduce((a, b) => a + b);
  }
  function menuItemClick(e, value) {
    e.preventDefault();
    setActiveMenu(value);
  }

  function getLabelLink() {
    return CONSTANTS.API_ROOT + 'api/get-shipment-label-as-file/?transactionId=' + get(props, 'apiData_pay.result.transactionId', '') + '&secretKey=' + get(props, 'apiData_pay.result.secretKey', '');
  }

  function newShipping() {
    close();
  }

  function close() {
    let currentState = store.getState();
    dispatch(genericSetValue({
      path: 'ui.mainPage.purchaseProgress', value: {
        ...props.purchaseProgress,
        stage: 1,
        senderAddress: get(currentState, 'data.api.getInitialData.result.address', ''),
        senderPlaceId: get(currentState, 'data.api.getInitialData.result.googlePlaceId', ''),
        recipientAddress: '',
        recipientPlaceId: '',
        firstPackage: {
          weightUnits: 'lbs',
          weight: '',
          selectedPackage: JSON.parse(JSON.stringify(CONSTANTS.PACKAGE_TYPE_CUSTOM))
        },
        morePackages: [],
        activeCompany: 'FEDEX',
        selectedShippingRadioOption: null,
        selectedShippingOption: null,
        selectedDropoffType: { text: 'Drop off', value: String(CONSTANTS.SHIPPING_DROPOFF_TYPE_ID_DROPOFF) },
        pickupTime: '',
        pickupDate: '',
        shipper: {
          name: get(currentState, 'data.api.getInitialData.result.fullname', ''),
          phone: get(currentState, 'data.api.getInitialData.result.phone', ''),
          email: get(currentState, 'data.api.getInitialData.result.user', ''),
          company: get(currentState, 'data.api.getInitialData.result.company', ''),
        },
        recipient: {
          name: '',
          phone: '',
          email: '',
          company: ''
        },
        payment: {
          selectedMethod: null,
          differentBillingDetails: false,
          billingDetails: {
            email: '',
            fullName: '',
            zipCode: ''
          },
          creditCard: {
            cardNumber: '',
            cvv: '',
            expiry: ''
          },
          paypal: {}
        },
        selectedCompany: '',
        coupon: {},
      }
    }));
    dispatch(modalQueue({ mode: 'delete', index: props.modalIndex }));
  }

  function allPackages() {
    dispatch(modalQueue({ mode: 'insert', modalConfig: CONSTANTS.MODALS_CONFIG.THANK_YOU_SHOW_PACKAGES }));
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    purchaseProgress: state.data.ui.mainPage.purchaseProgress,
    apiData_pay: state.data.api.pay,
    apiData_verifyPayment: state.data.api.verifyPayment,
    apiData_getInitialData: state.data.api.getInitialData
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const PigeItThankYouStyle = styled.div`
  svg.active-logo {
    ${STYLE.activeLogo}
  }
  background-color: ${Colors.white};
  height: 100%;
  text-align: center;
  overflow: auto;
  .new-shipping{
    svg{
        path{
          fill: ${Colors.blue1}
        }
    }
    margin-bottom: 20px;
    .icon{
      
      padding-top: 9px;
      padding-left: 20px;
      font-family: museo_sans700;
      font-size: 16px;
      color: #2699FB;
      background-color:rgba(0,0,0,0);
      border: none;
      padding: 10px 20px;
      outline: none;
      cursor: pointer;
    }
    justify-content: flex-start;
    display: flex;
    .new-shipping-button{
      font-family: museo_sans700;
      font-size: 16px;
      color: #2699FB;
      background-color:rgba(0,0,0,0);
      border: none;
      padding: 10px 20px;
      outline: none;
      cursor: pointer;
    }
  }
  .bottom-banner{
    width: 100%;
    position: fixed;
    background: white;
    z-index: ${ZIndices.thankYouComponent};
    bottom: 0;
    box-shadow: 0px -1px 6px #d0d0d0;
  }
  .place-holder{
    height: 200px;
  }
  .line{
    width: 300px;
    border: solid 0 #e2dfdf;
    border-width: 1px 0 0 0;
  }
  .thank-you-text{
    color:#0C5E96;
    font-size:33px;
    margin-top:15px;
    margin-bottom:15px;
  }
  .send-email-text, .tracking-number-text{
    font-size:16px;
    color:#323232;
    font-family:museo_sans300;
    line-height:20px;
    SPAN{
      font-family:museo_sans500;
    }
  }
  .info-container{
    width:930px;
    margin:auto;
    background-color:#F4F8FB;
    margin-top:22px;
    padding: 17px 30px;
    padding-bottom: 0;
    text-align:left;
    .label{
      color:#62686D;
      font-size:16px;
      margin-bottom:8px;
    }
    .text, .number, .size, .weigth{
      max-width: 230px;
      font-size:14px;
      font-family:museo_sans300;
    }
    .first-col{
      flex: 0 0 58%;
    }
    .second-col{
      flex: 0 0 38%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .shipper-info{
      flex: 0 0 50%;
    }
    .receiver-info{
      flex: 0 0 50%;
    }
    .other-info{
      .icon{
        svg{
          width: 50px;
          height: 40px;
        }
      }
      .value-info{
        &.hidden{
          visibility: hidden;
        }
        margin-left: 40px;
        .label{
          margin-bottom: 13px;
        }
      }
      .payment-method{
        .card-info{
          flex-direction: row;
        }
        .number{
          margin-top: 13px;
        }
        .label{
          margin-bottom: 0px;
        }
      }
      .first-col{
        flex: 0 0 50%;
      }
      .second-col{
        flex: 0 0 50%;
      }
    }
    .shipper-info, .receiver-info{
      margin-bottom: 35px;
    }
    .shipping-options{
      .more-button{
        background-color: unset;
        border: none;
        font-family: 'museo_sans700';
        color: ${Colors.blue1};
        cursor: pointer;
        outline: none;
      }
      .packages-list{
        height: 110px;
        overflow-y: hidden;
      }
      .icon{
        svg{
          max-height: 23px;
          width: 50px;
          margin-top: 2px;
          path{
            fill:#62686D;
          }
        }
      }
      .summery{
        font-size: 14px;
        SPAN{
          font-size:13px;
        }
      }
      .package-info{
        display: flex;
        flex-direction: row;
        .type{
          font-size: 14px;
        }
        .size, .weigth, .number{
          margin-top: 2px;
        }
        margin: 15px 0px;
      }
    }
  }
  .print-label{
    font-size:27px;
    margin-top: 17px;
    margin-bottom: 20px;
  }
  .total-container{
    width:930px;
    margin:auto;
    background-color:#F4F8FB;
    padding: 17px 30px;
    .notifications{
      max-width: 400px;
      margin: auto;
      }
      .line{
        width: 100%;
        max-width: 600px;
      }
      .total{
        font-size:18px;
        margin-top: 15px;
        margin-bottom: 15px;
      }
      .notification-container{
        display: flex;
      }
      .notifications-text{
        font-size: 12px;
        font-family:museo_sans300;
        width: 73px;
        margin-top: 10px;
        margin-right: 20px;
      }
      .notifications-options{
        min-width:282px;
        width: 100%;
        margin: 2.5px 0;
      }
      .notifications-checkbox{
        width: 27px;
      }
    }
  .new-shipping-button{
    font-family: museo_sans700;
    font-size: 14px;
    color: #2699FB;
    background-color:rgba(0,0,0,0);
    border: none;
    padding: 10px 60px;
    outline: none;
    cursor: pointer;
  }
  .print-button{
    display: inline-block;
    text-decoration: none;
    font-family: museo_sans700;
    font-size: 18px;
    color: #FFFFFF;
    background-color:#FCB02E;
    border-radius: 3px;
    border: solid 0;
    padding: 17px 60px;
    outline: none;
    cursor: pointer;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .modal-close-button {
    position: absolute;
    top: 21px;
    right: 26px;
  }
  .header-container {
    z-index: ${ZIndices.myProfileHeader};
    width: 100%;
    padding-top: 25px;
    background: ${Colors.white};
  }
  @media only screen and (max-width: 992px) { 
    .logo-container{
      img{
        width: 100px;
      }
    }
    .header-container{
      padding-top: 3%;
    }
    .display-flex{
      flex-direction: column;
    }
    .thank-you-text{
      font-size: 170%;
      margin: auto;
      margin-top: 3%;
      margin-bottom: 15px;
      margin-left: 8%;
      margin-right: 8%;
    }
    .print-button{
      margin-bottom: 10px;
      margin-top: 10px;
    }
    .total-container{
      width:unset;
      margin: 3%;
      margin-top: 0;
      padding-top: 3%;
    }
    .info-container{
      width: unset;
      margin: 3%;
      margin-bottom: 0;
      padding: 5%;
      .shipping-options{
        margin-top: 20px;
        .packages-list{
          max-height:110px;
          height: unset;
        }
      }
      .label{
        color: black;
      }
      .shipper-info, .receiver-info{
        margin-bottom: 20px;
      }
      .payments-info-value{
        flex-direction: row;
      }
      .total-container{
        .notifications-text{
          width: unset;
          margin-top: 16.5px;
        }
      }
      .other-info{
        flex-direction: row;  
        .first-col{
          flex: 0 0 50%;
        }
        .second-col{
          flex: 0 0 35%;
          .sending-options{
            .label{
              margin-bottom: 13px;
            }
          }
        }
        .value-info{
          margin-bottom: 0px;
        }
      }
    }
  }
`;

const PigeItThankYou = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItThankYouComponent);


export default PigeItThankYou;
