/* eslint eqeqeq: off */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { attachEvent, detachEvent,navigateToPath } from "../common/functions";
import { ZIndices } from '../common/zIndices';
import CONSTANTS from '../common/constants';
import { modalQueue } from '../redux/actions';

function PigeItModalComponent(props) {
  const config = props.config;
  const containerRef = useRef(null);
  const [ containerTopValue, setContainerTopValue ] = useState('0');
  const dispatch = useDispatch();
  useEffect(function() {
    if (config) {
      attachEvent(window, 'resize', resizeEvent);
      resizeEvent();
    }
    return function() {
      if (config) {
        detachEvent(window, 'resize', resizeEvent);
      }
    };
  });
  let positioningContainerStyle = {
    top: containerTopValue,
    margin: '0 auto'
  };
  if (props.displayMode === CONSTANTS.DISPLAY_MODES.DESKTOP) {
    if (config.width) {
        positioningContainerStyle.width = config.width;
    }
    if (config.height) {
        positioningContainerStyle.height = config.height;
    }
  }else if(config.notFullScreenMobile){
    if (config.height) {
      positioningContainerStyle.height = config.height;
    }
    positioningContainerStyle.width = '90%';
  }
  return (
    <PigeItModalStyle className={"pigeit-modal-container" + (props.isLast ? ' last-modal' : ' not-last-modal')} ref={containerRef} onClick={onClickOutside}>
      <div className="pigeit-modal-positioning-container" style={positioningContainerStyle}>
        {props.children}
      </div>
    </PigeItModalStyle>
  );

  function onClickOutside(e) {
    if (config && config.closeOnClickOutside && e.target == containerRef.current) {
      dispatch(modalQueue({mode: 'delete'}));
      if (config.url) {
        navigateToPath('', true);
      }
    }
  }

  function resizeEvent(e) {
    if (config && config.height && config.height != '100%') {
      const preferredHeight = parseInt(parseInt(config.height) / 2);
      const height = document.documentElement.clientHeight / 2;
      if ((props.displayMode === CONSTANTS.DISPLAY_MODES.MOBILE&&!config.notFullScreenMobile) || (height < preferredHeight && containerTopValue != '0')) {
        setContainerTopValue('0');
      }
      else if (height > preferredHeight && containerTopValue != 'calc(50% - ' + preferredHeight + 'px)') {
        setContainerTopValue('calc(50% - ' + preferredHeight + 'px)');
      }
    }
    else {
      setContainerTopValue('0');
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    displayMode: state.data.displayMode
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const PigeItModalStyle = styled.div`
  position: fixed;
  body.full-screen-modal & {
    position: static;
  }
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.5);
  z-index: ${ZIndices.modal};
  .pigeit-modal-positioning-container {
    body.full-screen-modal & {
      height: 100%;
    }
    position: relative;
  }
`;

const PigeItModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItModalComponent);

export default PigeItModal;
