import React, { useState } from 'react';
import styled from 'styled-components';
import { Colors } from "../common/colors";
import { Checkbox, FormControlLabel } from '@material-ui/core';

function PigeItCheckboxComponent(props) {
  return (
    <PigeItCheckboxStyle className={"container" + (props.className ? (' ' + props.className) : '')}>
      <FormControlLabel
        classes={{label: 'label'}}
        control={<Checkbox
          classes={{ checked: 'checkbox-checked'}}
          className="checkbox"
          disabled={props.disabled ? true : false}
          checked={props.checked}
          name={props.name}
          onClick={(e) => { if (props.onClick) { props.onClick(e.target.checked); } } }
          value={false} />
        }
        label={props.labelText}
      />
    </PigeItCheckboxStyle>
  );
}

const PigeItCheckboxStyle = styled.div`
  .container {
    text-align: left;
  }
  .label {
    font-size: 14px;
    color: ${Colors.black};
  }
  .checkbox {
    color: ${Colors.blue1};
  }
  .checkbox svg {
    height: 27px;
    width: 27px;
  }
  span.checkbox-checked svg {
    color: ${Colors.white};
    background-color: ${Colors.blue1};
    height: 27px;
    width: 27px;
  }
`;

const PigeItCheckbox = PigeItCheckboxComponent;
export default PigeItCheckbox;
