/* eslint react/jsx-pascal-case: off */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { modalQueue, genericSetValue, api_packages_getList, api_packages_add, setupAnimatedLoader } from '../redux/actions';
import { Button } from '@material-ui/core';
import { Colors } from "../common/colors";
import { ZIndices } from '../common/zIndices';
import { get } from 'lodash';
import IMAGES from '../common/images';
import CONSTANTS from '../common/constants';
import STYLE from '../common/style';
import PigeItInputWithHorizontalSelectAndListSelect, { COMPONENT_CONSTANTS } from './PigeItInputWithHorizontalSelectAndListSelect.component';
import MainPageInputPackageTypeBasic from './MainPageInputPackageTypeBasic.component';
import { convertPackageIdtoPackage, showErrorMessage, isValidWeightValue, isValidSizeValue, isUserLoggedIn, promptUserToSignIn } from "../common/functions";
import PigeItInput from './PigeItInput.component';

function PigeItPackages_AddEditComponent(props) {
  const dispatch = useDispatch();
  const [packages, setPackages] = useState([props.firstPackage, ...props.morePackages]);
  const [error, setError] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState('');
  useEffect(() => {
    if (packages.some(pak => pak.selectedPackage && (pak.selectedPackage.baseDescription.toLowerCase().indexOf('ups') !== -1 || pak.selectedPackage.selectionDisplayTitle.toLowerCase().indexOf('ups') !== -1))) {
      setSelectedCompany('ups');
    } else if (packages.some(pak => pak.selectedPackage && (pak.selectedPackage.baseDescription.toLowerCase().indexOf('fedex') !== -1 || pak.selectedPackage.selectionDisplayTitle.toLowerCase().indexOf('fedex') !== -1))) {
      setSelectedCompany('fedex');
    } else {
      setSelectedCompany('');
    }
  }, [packages]);
  useEffect(() => {
    let temp = packages.map(p => { return { ...p, showInsurance: p.value || p.content } });
    setPackages(get(props, 'modalConfig.addNew') ?
      [...temp, {
        weightUnits: 'lbs',
        weight: '',
        selectedPackage: JSON.parse(JSON.stringify(CONSTANTS.PACKAGE_TYPE_CUSTOM)),
        content: '',
        value: '',
        showInsurance: false,
      }] : temp);
  }, []);

  return (
    <PigeItPackages_AddEditStyle className="my-account-container">
      <div>
        <div className="header-container">
          <Button className="modal-close-button" onClick={close}>
            <IMAGES.close />
          </Button>
          <div className="mobile-component bottom-banner">
            {error && <div className='error-notification '>Please fill in all packages details!</div>}
            <div className='display-flex'>
              <div className='button-container'>
                <Button className="add-package-button" onClick={addNewPackage}><IMAGES.plus /><span>Add package</span></Button>
              </div>
              <div className='save-button-container'>
                <div className="save-and-continue-button">
                  <button onClick={save}>SAVE AND CONTINUE</button>
                </div>
              </div>
            </div>
          </div>
          <div className='active-logo'>
            <IMAGES.logo className="active-logo" />
          </div>
          <div className="edit-package-text">Add a Package</div>
          <div className="background-container background-container-mobile">
            <form>
              {packages.map((item, i) => {
                return (
                  <React.Fragment key={i + 'desktop'}>
                    <div className={'desktop-component package-container display-flex'}>
                      <div className='index-container'>
                        <div>{(i + 1) + '.'}</div>
                      </div>
                      <div className="ta-left field-label-input-container">
                        <div className="display-flex fjc-space-between field-label">
                          <div className='display-flex'>
                            <label className='main-title'>Package Type</label>
                            <button type='button' className="more-container" onClick={selectPackageHandler(i)}><span className="text">{get(item, 'selectedPackage.selectionDisplayTitle', 'Custom')}</span></button>
                            <label className='sub-title'>(in,lbs)</label>
                          </div>
                          <button type='button' className="more-container" onClick={() => onSelectPackageFromList(i)}><span className="text"><IMAGES.packageList /></span></button>
                        </div>
                        <div className='display-flex fjc-space-between'>
                          <PigeItInput type='number' error={get(item, 'error.weight', false)} label='Weight' className='input-field' onChange={changeWeightHandeler(i)} onSelectFromList={(item) => { onSelectPackageFromList(item, i); }} value={item.weight} name="package-weight-desktop" />
                          <MainPageInputPackageTypeBasic errors={get(item, 'error', {})} onChange={(value) => changePackage(i, value)} onClick={selectPackageHandler(i)} onEditClick={editPackageHandler(i)} selectedPackageType={item.selectedPackage} className="input-package-type display-flex"> </MainPageInputPackageTypeBasic>
                        </div>
                        {item.showInsurance && <div className='display-flex fjc-space-between insurance-container'>
                          <PigeItInput error={get(item, 'error.content', false)} type='text' value={item.content} onChange={(e) => setPackages(packages.map((p, ind) => i === ind ? { ...p, content: e.target.value } : p))} className='content-input' label='Package Content (Up to 100)' />
                          <PigeItInput error={get(item, 'error.value', false)} type='number' value={item.value} onChange={(e) => setPackages(packages.map((p, ind) => i === ind ? { ...p, value: e.target.value } : p))} className='value-input' label='Value' />
                        </div>}
                      </div>
                      <div className='actions-container'>
                        <Button className={"remove-button big" + (packages.length === 1 ? ' hidden' : '')} onClick={removePackageHandler(i)}>{<IMAGES.remove />}</Button>
                        {!item.showInsurance ?
                          <Button className="add-insurance-button" onClick={() => setPackages(packages.map((p, ind) => i === ind ? { ...p, showInsurance: true } : p))}>{<IMAGES.plusSmall />} &nbsp;&nbsp;Add Insurance</Button> :
                          <Button className="remove-insurance-button" onClick={() => setPackages(packages.map((p, ind) => i === ind ? { ...p, showInsurance: false, value: '', content: '' } : p))}>{<IMAGES.remove />} &nbsp;&nbsp;Remove Insurance</Button>}
                      </div>
                    </div>
                    <div key={i + 'mobile'} className={'mobile-component'}>
                      <div className={'mobile-component package-container display-flex'}>
                        <div className='index-container'>
                          {(i + 1) + '.'}
                        </div>
                        <div className={"ta-left field-label-input-container first"}>
                          <div className="display-flex fjc-space-between field-label">
                            <div className='display-flex'>
                              <label className='main-title'>Package Type</label>
                              <button type='button' className="more-container" onClick={selectPackageHandler(i)}><span className="text">{get(item, 'selectedPackage.selectionDisplayTitle', 'Custom')}</span></button>
                              <label className='sub-title'>(in,lbs)</label>
                            </div>
                            <button type='button' className="more-container" onClick={() => onSelectPackageFromList(i)}><span className="text"><IMAGES.packageList /></span></button>
                          </div>
                          <div className='display-flex fjc-space-between'>
                            <PigeItInput type='number' error={get(item, 'error.weight', false)} label='Weight' className='input-field' onChange={changeWeightHandeler(i)} onSelectFromList={(item) => { onSelectPackageFromList(item, i); }} value={item.weight} name="package-weight-desktop" />
                            <MainPageInputPackageTypeBasic errors={get(item, 'error', {})} onChange={(value) => changePackage(i, value)} onClick={selectPackageHandler(i)} onEditClick={editPackageHandler(i)} selectedPackageType={item.selectedPackage} className="input-package-type display-flex"> </MainPageInputPackageTypeBasic>
                          </div>
                          {item.showInsurance && <div className='display-flex fjc-space-between insurance-container'>
                            <PigeItInput error={get(item, 'error.content', false)} type='text' value={item.content} onChange={(e) => setPackages(packages.map((p, ind) => i === ind ? { ...p, content: e.target.value } : p))} className='content-input' label='Package Content (Up to 100)' />
                            <PigeItInput error={get(item, 'error.value', false)} type='number' value={item.value} onChange={(e) => setPackages(packages.map((p, ind) => i === ind ? { ...p, value: e.target.value } : p))} className='value-input' label='Value' />
                          </div>}
                          <div className='display-flex fjc-space-between'>
                            {!item.showInsurance ?
                              <Button className="add-insurance-button" onClick={() => setPackages(packages.map((p, ind) => i === ind ? { ...p, showInsurance: true } : p))}>{<IMAGES.plusSmall />} &nbsp;&nbsp;Add Insurance</Button> :
                              <Button className="remove-insurance-button" onClick={() => setPackages(packages.map((p, ind) => i === ind ? { ...p, showInsurance: false, value: '', content: '' } : p))}>{<IMAGES.remove />} &nbsp;&nbsp;Remove Insurance</Button>}
                            <Button className={"remove-button" + (packages.length === 1 ? ' hidden' : '')} onClick={removePackageHandler(i)}>{<IMAGES.remove />} &nbsp;&nbsp;Delete</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>);
              })}
            </form>
            <div className='mobile-component place-holder' />
          </div>

          <div className='desktop-component button-container'>
            <Button className="add-package-button" onClick={addNewPackage}><IMAGES.plusSmall /><span>Add Another package</span></Button>
          </div>
          {error && <div className='error-notification desktop-component'>Please fill in all packages details!</div>}
          <div className='desktop-component display-flex save-button-container'>
            <div className="save-and-continue-button">
              <Button onClick={save}>SAVE AND CONTINUE</Button>
            </div>
            <div className="cancel-button">
              <Button onClick={cancel}>CANCEL</Button>
            </div>
          </div>
        </div>
      </div>
    </PigeItPackages_AddEditStyle>
  );

  function onSelectPackageFromList(index) {
    if (isUserLoggedIn(get(props, 'apiData_getInitialData', {}))) {
      // open modal on address list tab
      const modalConfig = JSON.parse(JSON.stringify(CONSTANTS.MODALS_CONFIG.MY_ACCOUNT));
      modalConfig.initialTab = CONSTANTS.MY_ACCOUNT_PACKAGES_LIST_TAB;
      modalConfig.packagesClickable = true;
      modalConfig.callback = function (item) {
        let packageType = convertPackageIdtoPackage(item.packageTypeId);
        if (item.packageTypeId === '1') {
          packageType = { ...packageType, dimensionsHeight: item.sizeHeight, dimensionsLength: item.sizeLength, dimensionsUnits: parseInt(item.sizeUnitId) === 1 ? "in" : "cm", dimensionsWidth: item.sizeWidth, selectionDisplayDimensions: item.sizeWidth + '" x ' + item.sizeHeight + '" x ' + item.sizeLength + '"' };
        }
        const selectedPackage = { selectedPackage: packageType, weightUnits: (parseInt(item.weightUnitId) === 1 ? "lbs" : "kg"), weight: item.weight };
        setPackages(packages.map((pack, i) => {
          if (i === index) {
            return selectedPackage;
          } else {
            return pack;
          }
        }));
      };
      // scroll to start of the page
      window.scrollTo(0, 0);
      dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
    }
    else {
      promptUserToSignIn(dispatch);
    }
    // open modal on address list tab
  }

  function save() {
    if (packages.every(item =>
      isValidWeightValue(item.weight, 0, get(item, 'selectedPackage.weightRange[1]', 150)) &&
      (!item.showInsurance || item.content && isValidSizeValue(item.value)) &&
      (item.selectedPackage.id != CONSTANTS.PACKAGE_TYPE_CUSTOM.id ||
        item.selectedPackage.id == CONSTANTS.PACKAGE_TYPE_CUSTOM.id &&
        (isValidSizeValue(item.selectedPackage.dimensionsWidth) &&
          isValidSizeValue(item.selectedPackage.dimensionsLength) &&
          isValidSizeValue(item.selectedPackage.dimensionsHeight)
        )))) {
      const packagesToSave = packages.map(item => {
        delete item.showInsurance;
        delete item.error;
        return item;
      });
      dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress', value: { ...props.purchaseProgress, stage: 1, firstPackage: packagesToSave[0], morePackages: packagesToSave.filter((item, i) => i !== 0), selectedCompany } }));
      const callback = get(props, 'modalConfig.callback', false);
      if (typeof (callback) === 'function') {
        callback();
      }

      close();
    }
    else {
      setError(true);
      setPackages(packages.map(item => {
        let weight = !isValidWeightValue(item.weight, 0, get(item, 'selectedPackage.weightRange[1]', 150));
        let width = !isValidSizeValue(item.selectedPackage.dimensionsWidth);
        let length = !isValidSizeValue(item.selectedPackage.dimensionsLength);
        let height = !isValidSizeValue(item.selectedPackage.dimensionsHeight);
        let value = item.showInsurance && !isValidSizeValue(item.value);
        let content = item.showInsurance && !item.content;
        if (weight || width || length || height || value || content) {
          return { ...item, error: { weight, width, length, height, value, content } };
        } else {
          return { ...item, error: false };
        }
      }));
    }
  }

  function close() {
    dispatch(modalQueue({ mode: 'delete' }));
  }
  function cancel() {
    close();
  }
  function removePackageHandler(index) {
    return () => {
      setPackages(packages.filter((item, i) => i !== index));
    }
  }
  function changeWeightHandeler(index) {
    return (e) => {
      setPackages(packages.map((item, i) => {
        if (i === index) {
          return { ...item, weight: e.target.value };
        }
        return item;
      }));
    }
  }
  function addNewPackage() {
    setPackages([...packages, {
      weightUnits: 'lbs',
      weight: '',
      selectedPackage: JSON.parse(JSON.stringify(CONSTANTS.PACKAGE_TYPE_CUSTOM)),
      content: '',
      value: '',
      showInsurance: false,
    }]);
  }

  function changePackage(index, value) {
    setPackages(packages.map((item, i) => {
      if (i === index) {
        return { ...item, selectedPackage: value };
      }
      return item;
    }));
  }

  function selectPackageHandler(index) {
    return (e) => {
      if (inProgress) {
        return;
      }
      if (e) {
        e.preventDefault();
      }
      let modalConfig = JSON.parse(JSON.stringify(CONSTANTS.MODALS_CONFIG.PACKAGE_TYPE_SELECT_PACKAGE));
      const weight = get(packages, '[' + index + '].weight', null);
      const weightUnit = get(packages, '[' + index + '].weightUnit', null);
      modalConfig.selectedPackage = {
        selectedPackage: get(packages, '[' + index + '].selectedPackage'),
        weight: weight,
        weightUnits: weightUnit,
      }
      if (!get(props, 'apiData_getInitialData.result.success', false)) {
        modalConfig.disableAddToPackageList = true;
      }
      if (selectedCompany) {
        modalConfig.selectedCompany = selectedCompany;
      }
      modalConfig.callback = function (selectedPackage, weight, packageName) {
        setPackages(packages.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              weight: weight.value,
              weightUnits: weight.unit,
              selectedPackage: selectedPackage
            };
          }
          return item;
        }));
        if (packageName && !isNaN(weight) && weight !== '0') {
          let request = {
            name: packageName,
            content: '',
            packageTypeId: parseInt(selectedPackage.id || 0),
            weight: parseInt(weight),
            weightUnitId: parseInt(weightUnit === 'lbs' ? 1 : 2),
            sizeHeight: parseFloat(selectedPackage.dimensionsHeight),
            sizeWidth: parseFloat(selectedPackage.dimensionsWidth),
            sizeLength: parseFloat(selectedPackage.dimensionsLength),
            sizeUnitId: parseInt(selectedPackage.dimensionsUnits === 'in' ? 1 : 2)
          };
          request.callback = function (result) {
            setInProgress(false);
            dispatch(setupAnimatedLoader({ isVisible: false }));
            if (result && !result.success) {
              showErrorMessage(result, dispatch);
            }
            dispatch(api_packages_getList());
          };
          setInProgress(true);
          dispatch(setupAnimatedLoader({ isVisible: true, text: 'Adding package...' }));
          dispatch(api_packages_add(request));
        }
      };
      dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
    }
  }

  function editPackageHandler(index) {
    return () => {
      setPackages(packages.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            weight: '',
            weightUnit: 'lbs',
            selectedPackage: {
              id: 1,
              customDimensions: true,
              dimensionsWidth: '', // value will be saved in redux
              dimensionsHeight: '', // value will be saved in redux
              dimensionsLength: '', // value will be saved in redux
              baseDescription: 'Custom Package',
              dimensionsDescription: 'Custom',
              selectionDisplayTitle: 'My Custom',
              dimensionsUnits: 'in',
              checkboxState: false
            }
          }
        }
        return item;
      }));
    }
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    morePackages: get(state, 'data.ui.mainPage.purchaseProgress.morePackages', null),
    firstPackage: get(state, 'data.ui.mainPage.purchaseProgress.firstPackage', null),
    purchaseProgress: state.data.ui.mainPage.purchaseProgress,
    apiData_getInitialData: state.data.api.getInitialData
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const PigeItPackages_AddEditStyle = styled.div`
  .insurance-container{
    margin-bottom: 10px;
    .content-input {
      flex: 0 0 74.5%;
    }
    .value-input{
      flex: 0 0 23%;
    }
  }
  svg.active-logo {
    ${STYLE.activeLogo}
  }

  background-color: ${Colors.white};
  height: 100%;
  text-align: center;
  overflow: auto;
  .more-container{
    svg{
      width: 25px;
      height: 25px;
      path{
        fill: ${Colors.blue1};
      }
    }
    display: inline-block;
    background: none;
    border: none;
    color: ${Colors.blue1};
    font-family: museo_sans500;
    cursor: pointer;
    outline: none;
    margin-top: -6px;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 104px;
  }
  .input-package-type {
    height: 38px;
    margin-top: 9.5px;
    flex: 0 0 74%;
    .box-radio-icon {
      top: -6px;
    }
    .box-icon {
      top: -2px;
    }
    .box-label, .envelope-label {
      white-space: nowrap;
    }
  }
  .hidden {
    visibility: hidden;
  }
  .place-holder {
    height: 200px;
  }
  .error-notification {
    color: ${Colors.error};
    margin-bottom: 20px;
  }
  .save-button-container{
    margin-bottom:40px;
    justify-content: center;
    .save-and-continue-button{
      padding-left: 30px;
      padding-right: 30px;
      margin-left: 65px;
        BUTTON{
          padding-left: 47px;
          padding-right: 47px;
          font-family: museo_sans700,sans-serif;
          font-size: 16px;
          width: 100%;
          height: 56px;
          color: ${Colors.white};
          background-color: ${Colors.orange};
        }
    }
    .cancel-button{
      height: 56px;
      padding-top: 11px;
      font-family: museo_sans700,sans-serif;
      background-color: rgba(0,0,0,0);
      border: none;
      cursor: pointer;
      outline: none;
      button {
        color:${Colors.blue1};
      }
    }
  }
  .modal-close-button {
    position: absolute;
    top: 21px;
    right: 26px;
  }
  .index-container{
    margin: 64px 13px 0px 21px;
    font-size: 24px;
    color: ${Colors.lightGray2};
  }
  .error{
    border: solid 1px ${Colors.error};
  }
  .package-container{
    &.error {
      border: solid 1px ${Colors.error};
    }
    width:858px;
    margin-bottom:20px;
    background-color: ${Colors.lightBlue};
  }
  .add-package-button {
    font-size: 16px;
    height: 56px;
    background-color: transparent;
    border: none;
    color: ${Colors.blue1};
    outline: none;
    svg{
      path{
        fill: ${Colors.blue1};
      }
    }
    SPAN {
      padding-left: 5px;
  }
  }
  .button-container{
    width: 858px;
    margin:auto;
    justify-content: flex-end;
    display: flex;
  }
  .select-weight{
    width:300px;
  }
  .select-type{
    width:300px;
  }
  .actions-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .remove-button{
    min-width: unset;
    align-self: flex-end;
    color: ${Colors.red};
    svg{
      width: 15px;
      height: 15px;
    }
    &.big{
      svg{
        width: 23px;
        height: 23px;
      }
    }
  }
  .add-insurance-button, .remove-insurance-button{
    white-space: nowrap;
    color: ${Colors.blue1};
    margin-bottom: 25px;
    svg{
      width: 20px;
      height: 20px;
      path{
        fill: ${Colors.blue1};
      }
    }
  }
  .remove-insurance-button{
    color:${Colors.red};
    svg{
      width: 15px;
      height: 15px;
      path{
        fill: ${Colors.red};
      }
    }
  }
  .background-container{
    margin: auto;
    width: 858px;
    margin-bottom: 10px;
  }
  .field-label-input-container {
    width: 100%;
    margin: 14px 26px 14px 0px;
  }
  .field-label {
    font-size: 16px;
    color: ${Colors.lightGray2};
    margin-bottom: 11px;
    white-space: nowrap;
  
  }
  .input-field {
    padding-top: 10px;
    padding-bottom: 10px;
    flex: 0 0 23%;
  }
  .header-container {
    z-index: ${ZIndices.myProfileHeader};
    width: 100%;
    padding-top: 25px;
    background: ${Colors.white};
  }
  .edit-package-text {
    font-size: 36px;
    font-family:museo_sans500;
    margin-bottom: 30px;
    margin-top: 19px;
  }
  @media only screen and (max-width: 992px) {
    .active-logo{
      .logo-img{
        width: 37px !important;
        margin-left: 0 !important;
        transform: translate(0px, -5px);       
      }
      svg{
        width: 150px;
      } 
    }
    .add-insurance-button,.remove-insurance-button{
      margin-bottom:0;
    }
    .error-notification {
      margin: 0;
      margin-top: 5px;
    }
    .background-container-mobile{
      margin:30.67px 10px;
    }
    .header-container{
      padding-top: 5px;
    }
    .edit-package-text{
      font-size: 26px;
      margin-top: 0px;
    }
    .logo-container{
      svg{
        width: 100px;
      }
    }
    .button-container{
      flex: 0 0 43%;
      margin: auto;
      margin-top: 12px;
      BUTTON {
        outline: none;
      }
    }
    .save-button-container{
      flex: 0 0 60%;
      margin-bottom: 10px;
      margin-top: 10px;
      .save-and-continue-button{
        margin: auto;
        width: 80%;
        padding: unset;
        BUTTON{
          border: none;
          border-radius: 3px;
          cursor: pointer;
        }
      }
    }
    .bottom-banner{
      width: 100%;
      position: fixed;
      background: white;
      z-index: 2;
      bottom: 0;
      box-shadow: 0px -1px 6px #d0d0d0;
    }
    .active-item-container {
      width: auto;
    }  
    .select-width{
      width: 100%;
    }
    .field-label-input-container{
      .select-type{
        width: 100%;
        display: flex;
      }
      margin: unset;
      background-color: ${Colors.lightBlue};
      padding-top: 10px;
      padding-left: 10px;
      padding-right: 10px;
      &.second{
        margin-left: 42px;
        margin-right: 34px;
        padding-bottom: 10px;
      }
      &.first{
        width: 90%;
        padding-bottom: 10px;
      }
    }
    .background-container {
      width:unset;
      height:unset;
      margin: 3%;
    }
    .index-container{
      margin-bottom: 0;
      margin-left: 0px;
      margin-right: 3px;
      margin-top: 7px;
    }
    .actions-container{
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 5px;
    }
    .package-container{
      width:unset;
      height:unset;
      background:none;
      margin: 0;
      margin-bottom: 3%;
    }
    .select-weight{
      width: 100%;
    }
    .remove-button {
      .text{
        color: ${Colors.error};
        display: inline-block;
        margin-right: 5px;
        transform: translate(0px, -4px);
      }
      background: none;
      outline: none;
      border: none;
      cursor: pointer;
      margin-left: 5px;
    }
  }
`;


const PigeItPackages_AddEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItPackages_AddEditComponent);


export default PigeItPackages_AddEdit;
