/* eslint react/jsx-pascal-case: off */
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { modalQueue,api_creditcard_addCreditCard,api_creditcard_getList, setupAnimatedLoader } from '../redux/actions';
import { padding ,showErrorMessage} from "../common/functions";
import { Button } from '@material-ui/core';
import { Colors } from "../common/colors";
import CONSTANTS from '../common/constants';
import PigeItSelect from "./PigeItSelect.component";
import IMAGES from '../common/images';
import moment from 'moment';
import PigeItCreditCardInput from './PigeItCreditCardInput.component';
import { getBluesnapEncryptedFields } from "../common/functions";
import { get } from 'lodash';
import PigeItInput from './PigeItInput.component';
import { ZIndices } from "../common/zIndices";

function PigeItMyAccount_CreditCard_AddEditComponent(props) {

  const expirationDateOptionsMonth = [];
  const expirationDateOptionsYear = [];
  const now = moment();
  for (let loop = 1; loop <= 12; ++loop) { expirationDateOptionsMonth.push({ text: padding(loop, '0', '', 2), value: padding(loop, '0', '', 2) }); };
  for (let loop = parseInt(now.format('YYYY')); loop <= parseInt(now.format('YYYY')) + 10; ++loop) { expirationDateOptionsYear.push({ text: loop.toString(), value: loop.toString() }); };
  const [selectedExpirationMonth, setSelectedExpirationMonth] = useState(props.selectedExpirationMonth ? props.selectedExpirationMonth : expirationDateOptionsMonth[0]);
  const [selectedExpirationYear, setSelectedExpirationYear] = useState(props.selectedExpirationYear ? props.selectedExpirationYear : expirationDateOptionsYear[0]);
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const [cardNumber, setCardNumber] = useState('');
  const [fieldDetails, setFieldDetails] = useState({ cardHolderName: get(props,'apiData_getInitialData.result.fullname',''), company: get(props,'apiData_getInitialData.result.company',''), cardHolderEmail: get(props,'apiData_getInitialData.result.user',''), verificationCode: '' });
  const [error, setError] = useState({});
  const [inProgress, setInProgress] = useState(false);

  return (
    <PigeItMyAccount_CreditCard_AddEditStyle className="login-container" ref={containerRef}>
      <div className="background-container">
        <div className="content-container">
          <Button className="modal-close-button" onClick={close}>
            <IMAGES.close />
          </Button>
          <div className="header-text">Add New Card</div>
          <form>
            <div className="display-flex fjc-space-between">
              <div className="flex-divided-2-cols first-col">
                <div className="ta-left field-label-input-container">
                    <PigeItInput label={"Cardholder's Name"} name='cardHolderName' value={fieldDetails.cardHolderName} onChange={(e) => { updateField(e, 'cardHolderName'); }} id='cardHolderName' type='text' className={"input-field"} error={error.cardHolderName} />
                </div>
                <div className="ta-left field-label-input-container">
                    <PigeItInput label={"Cardholder's Email"} name='cardHolderEmail' value={fieldDetails.cardHolderEmail} onChange={(e) => { updateField(e, 'cardHolderEmail'); }} id='cardHolderEmail' type='text' className={"input-field"} error={error.cardHolderEmail} />
                </div>
              </div>
              <div className="flex-divided-2-cols second-col">
                <div className="ta-left field-label-input-container">
                    <PigeItInput label={"Company"} name='company' value={fieldDetails.company} onChange={(e) => { updateField(e, 'company'); }} id='company' type='text' className={"input-field"} error={error.company} />
                </div>
              </div>
            </div>
            <div className="display-flex fjc-space-between">
              <div className="flex-divided-2-cols first-col">
                <div className="ta-left field-label-input-container">
                    <PigeItCreditCardInput value={cardNumber} onChange={(card) => { setCardNumber(card.number); }} name='cardNo' id='cardNo' type='text' className={"input-field card"} error={error.cardNumber} />
                </div>
                <div className="ta-left field-label-input-container">
                  <div className="date-container">
                    <PigeItSelect label="Month" value={selectedExpirationMonth} name='select1' valueList={expirationDateOptionsMonth} onChange={(value) => { setSelectedExpirationMonth(value); }} />
                    <div className='space-between' />
                    <PigeItSelect label="Year" value={selectedExpirationYear} name='select2' valueList={expirationDateOptionsYear} onChange={(value) => { setSelectedExpirationYear(value); }} />
                  </div>
                </div>
              </div>
              <div className="flex-divided-2-cols second-col">
                <div className="ta-left field-label-input-container">
                    <PigeItInput label={"Verification Code"} name='verificationCode' value={fieldDetails.verificationCode} onChange={(e) => { updateField(e, 'verificationCode'); }} id='verificationCode' type='text' className={"input-field"} error={error.verificationCode} />
                </div>
              </div>
            </div>
          </form>
          <Button className="add-new-card-button" onClick={addCard}>Add Card</Button>
        </div>
      </div>
    </PigeItMyAccount_CreditCard_AddEditStyle>
  );

  function close() {
    dispatch(modalQueue({ mode: 'delete' }));
  }

  function updateField(e, name) {
    if (name !== 'verificationCode' || (!isNaN(e.target.value)&&e.target.value.length<5)) {
      setFieldDetails({ ...fieldDetails, [name]: e.target.value });
    }
  }

  function addCard() {
    if (inProgress) {
      return;
    }
    let err = {};
    if (!fieldDetails.cardHolderName) {
      err.cardHolderName = true;
    }

    if (!fieldDetails.verificationCode ) {
      err.verificationCode = true;
    }

    if (!cardNumber) {
      err.cardNumber = true;
    }

    if (!fieldDetails.cardHolderEmail || !CONSTANTS.VALID_EMAIL_REGEX.test(String(fieldDetails.cardHolderEmail).toLowerCase())) {
      err.cardHolderEmail = true;
    }
    setError(err);
    if (Object.keys(err).length) {
      return;
    }
    let cardEncrypted = { encyprtedCreditCard: '', encyprtedCvv: '' };
    if (fieldDetails.verificationCode && cardNumber) {
      cardEncrypted = getBluesnapEncryptedFields(fieldDetails.verificationCode, cardNumber);
    }
    let request = {
      creditCardExpiryMonth: selectedExpirationMonth.text,
      creditCardExpiryYear: selectedExpirationYear.text.toString(),
      creditCardNumber: cardEncrypted.encyprtedCreditCard,
      creditCardSecurityCode: cardEncrypted.encyprtedCvv,
      email: fieldDetails.cardHolderEmail,
      firstName: fieldDetails.cardHolderName.split(' ')[0],
      lastName: fieldDetails.cardHolderName.split(' ').length > 1 ? fieldDetails.cardHolderName.split(' ')[1] : '',
      zip: '',
    };
    dispatch(setupAnimatedLoader({ isVisible: true, text: 'Saving credit card...' }));
    setInProgress(true);
    request.callback = function (result) {
      if (result.success) {
        dispatch(api_creditcard_getList({
          callback: function (result) {
            setInProgress(false);
            dispatch(setupAnimatedLoader({ isVisible: false }));
            if(result.success){
              dispatch(modalQueue({ mode: 'delete' }));
            }
          }
        }));
      }
      else {
        showErrorMessage(result, dispatch);
        dispatch(setupAnimatedLoader({ isVisible: false }));
        setInProgress(false);
      }
    };
    dispatch(api_creditcard_addCreditCard(request));
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    apiData_getInitialData: state.data.api.getInitialData
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  };
};

const PigeItMyAccount_CreditCard_AddEditStyle = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  text-align: center;
  overflow: auto;
  .background-container {
    height: 100%;
    width: 100%;
    background-color: #fff;
    margin: 0 auto;
    position: relative;
    padding: 0px;
  }
  .content-container {
    background: white;
    padding-top: 45px;
    padding-right: 65px;
    padding-left: 65px;
  }
  .modal-close-button {
    position: absolute;
    top: 21px;
    right: 26px;
  }
  .header-text {
    font-size: 36px;
    margin-bottom:40px
  }
  .add-new-card-button {
    background-color:${Colors.orange};
    color:${Colors.white};
    font-family:museo_sans700;
    font-size:18px;
    border-radius:3px;
    width: 250px;
    height: 56px;
    margin-top:40px;
    margin-bottom: 40px;
  }
  .bottom-banner {
    width: 100%;
    position: fixed;
    background: white;
    z-index: ${ZIndices.myAccountCreditCardAddEdit};
    bottom: 0;
  }
  .field-label {
    font-size: 16px;
    color: ${Colors.lightGray2};
    margin-bottom: 11px;
  }
  .input-field {
    &.card{
    }
    &.error{
    }
    width: 100%;
    margin-bottom: 20px;
  }
  .field-label-input-container {
    width: calc(100% - 24px);
    margin-top:10px;
  }
  .second-col .field-label-input-container {
    width: calc(100% - 45px);
    margin-left: 20px;
  }
  @media only screen and (max-width: 992px) {
    .display-flex {
      flex-flow: column nowrap;
    }
    .date-container{
      margin-bottom: 12px;
    }
    .content-container{
      padding-right: 20px;
      padding-left: 30px;
    }
    .flex-divided-2-cols {
      flex: 0 0 100%;
    }
    .field-label-input-container{
      width: calc(100% - 11px);
    }
    .second-col .field-label-input-container {
      width: calc(100% - 11px);
      margin-left: 00px;
    }
  }
  .space-between {
    width:60px;
  }
  .date-container{
    display:flex;
  }
`;

const PigeItMyAccount_CreditCard_AddEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItMyAccount_CreditCard_AddEditComponent);


export default PigeItMyAccount_CreditCard_AddEdit;
