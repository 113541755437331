// PROD

import CONSTANTS from '../common/constants';
import moment from 'moment';

const now = moment();
const initialState = {
  loader: { isVisible: false, text: 'Loading' },
  animatedLoader: { isVisible: false, text: 'Loading' },
  isVScrollTop: true,
  api: {
    // api returned data
  },
  ui: {
    mainPage: {
      purchaseProgress: {
        stage: 1,
        // stage 1 params
        senderAddress: '',
        senderPlaceId: '',
        recipientAddress: '',
        recipientPlaceId: '',
        firstPackage: {
          weightUnits: 'lbs',
          weight: '',
          selectedPackage: JSON.parse(JSON.stringify(CONSTANTS.PACKAGE_TYPE_CUSTOM))
        },
        morePackages: [],
        // stage 2 params
        activeCompany: 'FEDEX', // FEDEX or UPS
        selectedShippingRadioOption: null,
        selectedShippingOption: { text: 'Choose a shipping option', value: '' },
        selectedDropoffType: { text: 'Drop off', value: String(CONSTANTS.SHIPPING_DROPOFF_TYPE_ID_DROPOFF) },
        pickupTime: now.format('HH:mm'),
        pickupDate: now.format('MM/DD/YYYY'),
        shipper: { name: '', phone: '', email: "", company: '', addressLine2: '' },
        recipient: { name: '', phone: '', email: "", company: '', addressLine2: '' },
        payment: {
          selectedMethod: null, // creditCard or paypal
          differentBillingDetails: false,
          billingDetails: {
            email: '',
            fullName: '',
            zipCode: ''
          },
          creditCard: {
            cardNumber: '',
            cvv: '',
            expiry: ''
          },
          paypal: {}
        },
        // used for re-calculation when switching between dropoff and pickup
        dropoffSavedRates: null,
        pickupSavedRates: null
      }
    },
    business: {
      orders: [],
      csvRows: [],
      headers: [],
      senderInfo: {
        city: '',
        state: '',
        postalCode: '',
        street: '',
        addressLine2: '',
        name: '',
        company: '',
        phone: '',
        addressText: '',
        email: '',
        residential: false,
      },
      selectedMapping: { text: 'Default', value: null },
      selectedShippingOption:'',
      selectedCompany:'',
      paymentDetails: {
        fromSavedCards: false,
        creditCard: '',
        expiry: '',
        cvv: '',
        cardType: '',
        last4Digits: '',
        expiryMonth: '',
        expiryYear: '',
      },
      changed: false,
    }
  },
  activeModalQueue: [
    // CONSTANTS.MODALS_CONFIG.PACKAGE_TYPE_SELECT_ENVELOPE
  ],
  modalsSavedData: {
    login: {
      username: '',
      rememberMe: ''
    }
  },
  displayMode: 'DESKTOP'
};

export default initialState;
