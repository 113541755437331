/* eslint jsx-a11y/anchor-is-valid: off */
import React, { useState } from 'react';
import { useTitle } from 'hookrouter';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Navigation from './Navigation.component';
import MainPageFooter from './MainPageFooter.component';
import IMAGES from '../common/images';
import CONSTANTS from '../common/constants';
import { Colors } from '../common/colors';
import { useRoutes, A, useRedirect } from 'hookrouter';
import { setHeadMetaDescription } from '../common/functions';

const TERMS_CONTENT = {
  title: 'Terms',
  content: [
    {
      pageTitle: 'Introduction | ShipWinner',
      pageDescription: 'ShipWinner Terms and Conditions | ShipWinner.com',
      title: 'Introduction',
      path: 'introduction',
      content: [
        {
          header: '',
          para: [
            'These terms and conditions govern your use of this website; by using this website, you accept these terms and conditions in full. If you disagree with these terms and conditions or any part of these terms and conditions, you must not use this website.',
            'You must be at least 18 years of age to use this website. By using this website, and by agreeing to these terms and conditions, you warrant and represent that you are at least 18 years of age.']
        }
      ]
    },
    {
      pageTitle: 'License to Use Website | ShipWinner',
      pageDescription: 'ShipWinner License to Use Website | ShipWinner.com',
      title: 'License to Use Website',
      path: 'license-to-use-website',
      content: [
        {
          header: '',
          para: ['Unless otherwise stated, ShipWinner and/ or its licensors own the intellectual property rights in the website and material on the website. Subject to the license below, all these intellectual property rights are reserved.',
            'You may view, download for caching purposes only, and print pages from the website for your own personal use, subject to the restrictions set out below and elsewhere in these terms and conditions. ']
        },
        {
          header: 'You must not:',
          para: [
            `• Republish material from this website (including republication on another website).`,
            `• Sell, rent or sub-license material from the website. `,
            `• Show any material from the website in public. `,
            `• Reproduce, duplicate, copy or otherwise exploit material on this website for any commercial purpose.`
          ]
        }
      ]
    },
    {
      pageTitle: 'Warranties | ShipWinner',
      pageDescription: 'ShipWinner Warranties | ShipWinner.com',
      title: 'Warranties',
      path: 'warranties',
      content: [
        {
          header: '',
          para: ['This website is provided "as is", without any representations or warranties, express or implied. ShipWinner makes no representations or warranties in relation to this website or the information and materials provided on this website.']
        },
        {
          header: 'Without prejudice to the generality of the foregoing paragraph, ShipWinner does not warrant that:',
          para: ['• this website will be constantly available, or available at all.',
            '• that the information on this website is complete, true, accurate or non-misleading.',
            'Nothing on this website constitutes, or is meant to constitute, advice of any kind. If you require advice in relation to any legal, financial or medical matter you should consult an appropriate professional.']
        }
      ]
    },
  ]
};
const ABOUT_US = {
  pageTitle: 'About Us | ShipWinner',
  pageDescription: 'ShipWinner is an Innovative Shipping Platform that functions as a Personal Shipping Assistant. We provide our customers with great tools to ensure Shipping for the Best Price while incorporating an Easy, Reliable, Personalized and Optimized Shipping Experience! | ShipWinner.com',
  title: 'Meet ShipWinner',
  content: [
    {
      header: '',
      para: ['ShipWinner is an innovative shipping platform that functions as a personal shipping assistant. We provide our customers with practical, convenient tools that find the very best shipping rates, while at the same time incorporating an easy, reliable, personalized and optimized shipping experience. Due to our high shipping volume, we can offer even better shipping rates than you\'ll find on our carriers\' sites- up to 62% off! ']
    },
    {
      header: '',
      para: [`Our specially developed tools streamline the shipping process, giving you easy access to any information you need for quick and efficient shipping: Try our Calculator to compare carrier rates, which include our ShipWinner discount, enabling you to always make the most cost-effective choice.`]
    },
    {
      header: '',
      para: [`Use the Address Book to see all your contacts' information.`]
    },
    {
      header: '',
      para: [`Make lists of your preferred packaging in the Package List, choosing between Custom Packaging or ready-made options.`]
    },
    {
      header: '',
      para: [`Print your own Shipping Label for your package, and choose between drop-off or scheduled pickups.`]
    },
    {
      header: '',
      para: [`See your previous shipments in Shipping History, and then get an overview of all your info in Shipping Statistics.`]
    },
    {
      header: '',
      para: [`You can also create shipping alerts to get notifications about your package in real-time. `]
    },
    {
      header: '',
      para: [`ShipWinner saves you money by finding the lowest shipping rates available, but our goal is to go even further. We want to enable you to save time with every shipment, by storing and building on your own preferences.`]
    },
    {
      header: '',
      para: [`ShipWinner makes it possible to ship with just a few clicks!`]
    },
    {
      header: '',
      para: [`We love providing reliable shipping with great rates. But that’s not all. We’re constantly learning from our customers' feedback. For example, we’ve noticed that, apart from being tedious, most shipping errors are due to mistakes in the address information. As a result, we've upgraded our site; now, all our address bars will automatically populate validated addresses for you.`]
    },
    {
      header: '',
      para: [`ShipWinner is all about its customers. We're here to help with any questions or concerns, and look forward to hearing about your experiences and suggestions! For more information, please contact us at team@shipwinner.com.`]
    },
    {
      header: 'ShipWinner Premium Business.',
      para: ['We offer unique business solutions, special rates, and customer service tailored to your needs. For more information, please contact us at support@shipwinner.com.']
    }
  ]
};
const FAQ = {
  pageTitle: 'Frequently Asked Questions | ShipWinner',
  pageDescription: 'We are deeply analyzing the feedback from our clients concerning the usage of ShipWinner platform and shipping process in general. Here you can find helpful answers for frequently asked questions.',
  title: 'Frequently Asked Questions',
  content: [
    {
      header: '',
      para: ['ShipWinner is an innovative shipping platform that functions as a personal shipping assistant. We provide our customers with practical, convenient tools that find the very best shipping rates while incorporating an easy, reliable, personalized and optimized shipping experience. Due to our high shipping volume, we can offer even better rates than you\'ll find on our carriers\' sites- up to 62% off!',
        'Our customers are ShipWinner\'s highest priority. Please don\'t hesitate to contact us with any questions or whenever you need a hand. We\'re happy to help, and we\'d love to hear suggestions and feedback from you!']
    },
    {
      header: 'Can I ship from home using ShipWinner?',
      para: [`Absolutely! We've built our platform to make shipping easy. You can print labels directly from our website. If you don’t want to leave the comfort of your home, you can even opt for your package to be picked up from your address.`]
    },
    {
      header: `How long does it take until I get my shipping label?`,
      para: [`We've tested our platform extensively on many users. We've found that new users who aren’t familiar with our system average between 4-7 minutes to get their shipping labels. Registered users, who are signed in with an address book, package list, and payment method on file, have their shipping labels ready in less than 10 clicks. On average, that's just 1-3 minutes!`]
    },
    {
      header: `Does ShipWinner do the deliveries?`,
      para: [`No. We are a shipping platform dedicated to providing a streamlined, personalized shipping experience. Our innovative, free tools help organize and store your shipping information and speed up the shipping process. Depending on your choice, the actual pickup and delivery will be performed by either FedEx or UPS.`]
    },
    {
      header: `What’s the difference between ShipWinner and the major shipping companies?`,
      para: [`We make it easy to compare between FedEx and UPS with our Calculator, so that you can find the best shipping rate with minimum effort. In addition, due to our high shipping volume, ShipWinner can get you rates that are even better than what you'll find on our carriers' sites- saving you up to 62%! We also offer complimentary shipping tools that really accelerate the shipping process, like our Address Book, Package List, and Shipping History.`]
    },
    {
      header: `How does ShipWinner Work?`,
      para: [`We've simplified shipping so that you can have your package shipped in just a few easy steps, and save your preferences for future use. Our price comparison tool allows our customers to compare prices between carriers on one page. Here's the ShipWinner process, step by step:`,
        `☑ Fill out the sender and recipient addresses, as well as the weight and size of your package. Click “Get Your Price”. Click between the options and select the one that works best for you. Click “Ship Now”.`,
        `☑ Your shipping information has been saved. Fill out the sender's and recipient's name and phone number. Click “Confirm”.`,
        `☑ Your package info has been saved. Confirm by clicking “Next”.`,
        `☑ Your shipping preference and price have been saved. Confirm by clicking “Next”.`,
        `☑ Fill out your preferred payment method. Click “Next”.`,
        `☑ Confirm your personal and shipping information. That’s it! You’ve created your shipping label, ready to be printed right away.`,]
    },
    {
      header: `If I encounter a problem, what do I do?`,
      para: [`We stand by our customers! Please don't hesitate to contact us at support@shipwinner.com.`]
    },
    {
      header: `What are the benefits of Registering / Signing In?`,
      para: [`Instead of repeatedly filling in all your details, as well as your frequently-used destinations and package choices, registering allows you to save all that information on our secure site. This allows you to ship your package in under 10 clicks! In addition, registered users can utilize the Shipping History, Sent Packages and Shipping Statistics tools to organize and analyze their shipping.`]
    },
    {
      header: `What is My Account?`,
      para: [`Once you’ve registered and signed in, you’ll see a “My Account” tab on the top of the page. This will allow you to begin creating your Address Book and Package List. Registered users with a customized Address Book, Package List, and payment method can ship in under 10 clicks. Click on the My Account tab to access, review, and edit your Personal Info, Address Book, and Package List. Sent packages will appear in Shipping History and Shipping Statistics, with even more easy-access information.`]
    },
    {
      header: `Can I have multiple accounts?`,
      para: [`If you open another account, you won’t be able to access your previously saved addresses and packages. So, while it’s technically possible, we don’t advise that you open multiple accounts.`]
    },
    {
      header: `I lost my password. What do I do?`,
      para: [`No worries. Click the Sign In link on the home page. On the bottom of the popup menu, click “forgot password?”. You’ll be asked to fill in your email address, and we’ll send you a link to reset your password. If you don't see a confirmation email, check the Spam or Promotions file in your email.`]
    },
    {
      header: `What is my Personal Info?`,
      para: [`Once you’ve registered, you’ll be directed to your Personal Info page. Fill out your information, and ShipWinner will automatically save it to your Address Book for future use. You can always update or change your information by signing in and going to the “My Account” tab.`]
    },
    {
      header: `What is my Address Book?`,
      para: [`The Address Book is your own, personal list of addresses that you can refer to every time you ship a package. Apart from the convenience of having all your contacts' information in one place, it can also prevent shipping complications due to mistakes entering addresses. There is no limit to the number of addresses you can save, and the address book is searchable.`]
    },
    {
      header: `How do I use an address from my Address Book?`,
      para: [`Sign in to your ShipWinner account. In the home page, click on the blue icon in the "To" section. Your address book will pop up. Choose an address and click to select.`]
    },
    {
      header: `How do I add an address to my Address Book?`,
      para: [`Click the “My Account” tab located on the top of all the pages, click “Address Book”, and add new addresses. You can also add an address to your Address Book after you've gotten your shipping price and clicked Ship Now. You'll see the sender and recipients' addresses, along with an option below them "+Add to Address Book". A popup will appear for you to confirm adding the address. Click OK, and the address will be saved for you.`]
    },
    {
      header: `How do I edit or remove an address from my Address Book?`,
      para: [`This can only be done from the “My Account” tab located on the top of all the pa	ges. Click “Address Book”. On each address, there’s an option to Edit or Remove.`]
    },
    {
      header: `What is my Package List?`,
      para: [`To optimize your shipping process, you can save preferred packages to your Package List. This is great for easily replicating similar packages without repeating the package selection process. There is no limit to the number of packages you can save, and your list is searchable.`]
    },
    {
      header: `How do I select a package from my Package List?`,
      para: [`If you’re not signed in, you’ll be prompted to Sign In. Once you’ve signed in, select the blue icon in Weight. Your Package List will pop up. Click on the package of your choice.`]
    },
    {
      header: `How do I add a package to my Package List?`,
      para: [`You can add a package to your Package List right from the home page. Once you’ve filled out the shipping address, there’s an option on the bottom of the page “+ Add to Package List”. Click on that option and you’ll receive a popup confirmation that your package has been saved successfully. Alternatively, you can always click the “My Account” tab located on the top of all the pages, click “Package List”, then select "+ Add new package".`]
    },
    {
      header: `How do I edit or remove a package from my Package List?`,
      para: [`This can only be done from the “My Account” tab located at the top of each page. Click “Package List”. On each address, you'll see an option to Edit or Remove.`]
    },
  ]
};
const POLICIES_CONTENT = {
  title: '',
  content: []
};
//TODO POLICIES?
/*const POLICIES_CONTENT = {
  title: 'Policies',
  content: [
    {
      title: 'Pigeit Privacy Policy',
      content: [
        {
          header: 'vs;kvsbpsfb jo jpo jpo jpo jpo jpj pj ',
          para: ['fv ff f fgee br tn rnr  bwb wrbwrb wbw wb w', 'fqe  eg weg gwe gwg g ewg ']
        }
      ],
    },

    {
      title: 'Acceptable Use Policy',
      content: [
        {
          header: 'vs;kvsbpsfb jo jpo jpo jpo jpo jpj pj ',
          para: ['fv ff f fgee br tn rnr  bwb wrbwrb wbw wb w', 'fqe  eg weg gwe gwg g ewg ']
        }
      ]
    },
    {
      title: 'Cookie Policy',
      content: [
        {
          header: 'vs;kvsbpsfb jo jpo jpo jpo jpo jpj pj ',
          para: ['fv ff f fgee br tn rnr  bwb wrbwrb wbw wb w', 'fqe  eg weg gwe gwg g ewg ']
        }
      ]
    },
  ]
}*/

function InformationComponent(props) {
  const displayMode = props.displayMode;
  const MainContent = function (props) {
    useTitle(props.pageTitle || props.content.pageTitle);
    if (props.title === FAQ.title) {
      let mainEntity = FAQ.content.map(question => {
        let questionHeader = question.header;
        let answer = question.para.reduce((a, b) => a + ' ' + b, '');
        if (questionHeader && answer) {
          return ({
            "@type": "Question",
            "name": questionHeader,
            "acceptedAnswer": {
              "@type": "Answer",
              "text": answer,
            }
          });
        } else {
          return false;
        }
      });
      document.getElementById('google-rich-results').text = JSON.stringify({
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": mainEntity.filter(q => q)
      });
    } else {
      document.getElementById('google-rich-results').text = '';
    }
    const pathPrefix = (CONSTANTS.PUBLIC_URL.substring(CONSTANTS.PUBLIC_URL.length - 1) !== '/' ? CONSTANTS.PUBLIC_URL + '/' : CONSTANTS.PUBLIC_URL);
    const [showTerms, setShowTerms] = useState(displayMode === 'DESKTOP');
    const [showPolicies, setShowPolicies] = useState(displayMode === 'DESKTOP');
    const contentColumn = props.title === ABOUT_US.title || props.title === FAQ.title ? props.content : props.content.content;
    return (
      <>
        <div className='header-container'>
          <div className='first-header '>{props.title}</div>
          {props.content.title&&<div className='second-header  '>{props.content.title}</div>}
        </div>
        <div className=" contain-cotainer display-flex fjc-space-around">
          <div className="menu-column">
            <div className="link-container" ><A className={"links" + (props.title === ABOUT_US.title ? ' selected' : '')} href={pathPrefix + 'information/about-us'}>About Us</A></div>

            <div className="terms-policies desktop-component">Terms</div>
            <button className="terms-policies mobile-component" onClick={() => setShowTerms(!showTerms)} ><div className="display-flex"><div className="button-text">Terms</div> <div className={"icon" + (showTerms ? " rotate-180" : '')}> <IMAGES.arrowDown /></div></div></button>

            {showTerms && TERMS_CONTENT.content.map((item, i) =>
              <div className="link-container" key={i}>
                <A className={"links" + (item.title === props.content.title ? ' selected' : '')} href={pathPrefix + 'information/terms-' + item.title.toLowerCase().split(' ').join('-')}>{item.title}</A>
              </div>
            )}

            {/* <div className="terms-policies desktop-component">Policies</div>
            <button className="terms-policies mobile-component" onClick={() => setShowPolicies(!showPolicies)}><div className="display-flex"><div className="button-text">Policies</div> <div className={"icon" + (showPolicies ? " rotate-180" : '')}> <IMAGES.arrowDown /></div></div></button>
            */}
            {showPolicies && POLICIES_CONTENT.content.map((item, i) =>
              <div className="link-container" key={i}>
                <A className={"links" + (item.title === props.content.title ? ' selected' : '')} href={pathPrefix + 'information/policies-' + item.title.toLowerCase().split(' ').join('-')}>{item.title}</A>
              </div>
            )}
            <div className="link-container" ><A className={"links" + (props.title === FAQ.title ? ' selected' : '')} href={pathPrefix + 'information/faq'}>FAQ</A></div>

          </div>
          <div className="content-column">
            {contentColumn.map((sec, i) => {
              return (
                <React.Fragment key={i}>
                  <div className="paragraph-header">{sec.header}</div>
                  {sec.para.map((para, i2) => {
                    return (
                      <div className="paragraph" key={i + '.' + i2}>{para}</div>
                    )
                  })}
                </React.Fragment>
              )
            })}
          </div>
        </div>
      </>);
  };
  useRedirect('/', '/about-us');
  let routes = {
    '/': () => 'none',
    '/about-us': () => <MainContent title={ABOUT_US.title} pageTitle={ABOUT_US.pageTitle} pageDescription={setHeadMetaDescription(ABOUT_US.pageDescription)} content={ABOUT_US.content} />,
    '/faq': () => <MainContent title={FAQ.title} pageTitle={FAQ.pageTitle} pageDescription={setHeadMetaDescription(FAQ.pageDescription)} content={FAQ.content} />
  };
  POLICIES_CONTENT.content.forEach(item => { routes['/policies-' + item.path] = () => <MainContent title='Policies' content={item} /> });
  TERMS_CONTENT.content.forEach(item => { routes['/terms-' + item.path] = () => <MainContent title='Terms' content={item} pageDescription={setHeadMetaDescription(item.pageDescription)} /> });
  const routeResult = useRoutes(routes);

  const [tabIndexForAll, setTabIndexForAll] = useState(null);

  return (
    <InformationStyle className="hidden-on-fullscreen-modal">
      <div className="background-container">
        <Navigation passedTabIndex={tabIndexForAll} />
      </div>
      {routeResult}

      <MainPageFooter />
    </InformationStyle>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    displayMode: state.data.displayMode
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const InformationStyle = styled.div`

  .background-container {
    background-image: url("${CONSTANTS.PUBLIC_URL}/images/pages/home/header-blue.png");
    background-repeat: no-repeat;
    background-size:100%;
    color: white;
    width: 100%;
  }
  .contain-cotainer{
    margin-bottom:50px;
  }

  .menu-column{
    flex:0 0 20%;
    color:#929C9B;
    font-family:museo_sans500;
    font-size:16px;
    margin-left:80px;
  
  .terms-policies{
    font-family:museo_sans500;
    font-size:16px;
    margin-top:20px;
    margin-bottom: 20px;

  }

    .link-container{
      padding-bottom:20px;
    }
    .links{
    
      text-decoration:none;
      color:#323232;
      font-family:museo_sans300;
      &.selected{
        
        color:#929C9B;

      }
    }
    
  }
  .content-column{
    flex: 0 0 60%;
    margin-right:9%;
   
  }
  .paragraph-header{
    color:#3A454E;
    font-size:20px;
    font-family:Museo_Sans500;
    margin-bottom:35px;
    margin-top:19px;
  }
  .paragraph{
      font-family:Museo_Sans300;
      margin-top:16px;
      margin-bottom:35px;

  }
  .header-container{
    width: 100%;
    min-height:114px;
    background-color: #EDEDED;
    margin-bottom:70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .first-header{
      color: ${Colors.black};
      font-family: museo_sans500;
      text-align: center;
      font-size: 36px;
      padding-top: 16px;
      padding-bottom: 12px;
    }
   .second-header{
      font-family: museo_sans500;
      color:#70777D;
      text-align: center;
      font-size: 27px;
      padding-bottom: 16px;
    }
  background-color: ${Colors.white};
  @media only screen and (max-width: 992px){
    .background-container{
      background-image: unset;
      background-color: #03548f;
    }
    .header-container{
      margin-bottom:0px;
      min-height: 0px;
    }

    .contain-cotainer{
    margin-bottom:50px;
    display:contents;
    
  }
  .first-header{
    padding-top: 20px;
    font-size:30px;

  }
  .button-text{
    flex:0 0 95%;
  }
  .icon{
    
    color:${Colors.darkBlue1};
  }
  
  .rotate-180{
    transform: rotate(180deg);
  }
  .menu-column{
    
    color:#929C9B;
    font-family:museo_sans500;
    font-size:16px;
    margin-left:0px;
    text-align:center;
    .terms-policies{
      width:100%;
      text-align:left;
      border: solid 1px #C8D9E2;
      background-color:${Colors.white};
      color:#323232;
      margin-top:0px;
      margin-bottom:0px;
      height:50px;
     
     
    }
  }
  .link-container{
      padding: 10px 10px 0px 10px;
      border:solid 1px #C8D9E2;
      text-align:left;
      height:10px;
      .links{
      text-decoration:none;
      color:#323232;
      font-family:museo_sans300;
      font-size:16px;
      &.selected{
        font-size:18px;
        font-family:museo_sans500;
        color:#323232;
      }
      }
  }
    
    
  .content-column{
      margin-right:0%;
      padding:25px 10px;
      
   }
  
  
  
  }
`;

const Information = connect(
  mapStateToProps,
  mapDispatchToProps
)(InformationComponent);

export default Information;
