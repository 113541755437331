/* eslint default-case: off, eqeqeq: off, react-hooks/exhaustive-deps: off */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTitle } from "hookrouter";
import { connect, useDispatch } from "react-redux";
import Navigation from "./Navigation.component";
import { Colors } from "../common/colors";
import MainPageFooter from "./MainPageFooter.component";
import IMAGES from "../common/images";
import CONSTANTS from "../common/constants";
import {
  genericSetValue,
  modalQueue,
  save_csv,
  save_csv_headers,
  save_orders,
} from "../redux/actions";
import {
  setHeadMetaDescription,
  navigateToPath,
  showMessage,
  logEventForAnalytics,
} from "../common/functions";
import { get } from "lodash";
import Papa from "papaparse";
import { CSV_MAIN_PAGE } from "../common/content";
import { Button } from "@material-ui/core";
import { useWindowSize } from "@react-hook/window-size";

function MultiplePackagesStage0Component(props) {
  const [width] = useWindowSize();
  const [bgLoading, setbgLoading] = useState(true);
  const dispatch = useDispatch();
  let userLoggedIn = get(props, "apiData_getInitialData.result.success", false);
  const [dragOver, setdragOver] = useState(false);
  useEffect(() => {
    logEventForAnalytics({ event: "businesses-page-0" });
  }, []);
  return (
    <MultiplePackagesStage0Style width={width}>
      <div>
        <div className="background-image">
          <img
            className="fade-in"
            style={{ display: bgLoading ? "none" : "block" }}
            onLoad={() => setbgLoading(false)}
            srcSet={`
            ${CONSTANTS.PUBLIC_URL}/images/pages/csv/background/176w.png 176w,
            ${CONSTANTS.PUBLIC_URL}/images/pages/csv/background/320w.png 320w,
            ${CONSTANTS.PUBLIC_URL}/images/pages/csv/background/640w.png 640w,
            ${CONSTANTS.PUBLIC_URL}/images/pages/csv/background/1024w.png 1024w,
            ${CONSTANTS.PUBLIC_URL}/images/pages/csv/background/2546w.jpg 2546w`}
            sizes={`
            (max-width: 176px) 176px,
            (max-width: 320px) 320px,
            (max-width: 640px) 640px,
            (max-width: 1024px) 1024px,
            2546px`}
            src={`${CONSTANTS.PUBLIC_URL}/images/pages/csv/background/176w.jpg`}
            alt=""
          />
        </div>
        <h3 className="main-title">Welcome to ShipWinner Business.</h3>
        <div className="display-flex fjc-space-between">
          <div className="floating-description">
            <h3 className="sub-title">
              <span className="desktop-component">
                Enjoy shipping with FedEx, UPS, and USPS for the lowest rates on
                the market.
                <br />
                <br />
              </span>
              <span className="desktop-component">
                Whether your company is large or small, we'll create an account
                that is the perfect fit for you… without any monthly fees.
                <br />
                <br />
              </span>
              <span>
                Our free business features allow you to ship easily and
                efficiently. Less time spent on shipping means more time to
                invest in your company.
                <br />
                <br />
              </span>
            </h3>
            {userLoggedIn ? (
              <Button
                className="button-main"
                onClick={() => navigateToPath("businesses/dashboard", true)}
              >
                GO TO DASHBOARD
              </Button>
            ) : (
              <Button
                className="button-main"
                onClick={() =>
                  navigateToPath("sign-in", true, false, {
                    redirectTo: "businesses",
                  })
                }
              >
                SIGN IN
              </Button>
            )}
          </div>
          <div className="buttons"></div>
        </div>
      </div>
      <div className="second-section">
        <p className="text">
          <h3>How does it work?</h3>
          <iframe
            width={Math.min(560, width - 40)}
            height="315"
            src="https://www.youtube.com/embed/9Xwqv6BNBhA?controls=1&autoplay=1&cc_load_policy=1&iv_load_policy=3&modestbranding=1?rel=0"
            frameborder="0"
            allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <h3>
            ShipWinner Business was developed to provide an answer to business'
            specific shipping needs.
            <br />
            <br />
          </h3>
          <span className="mobile-component">
            Enjoy shipping with FedEx, UPS, and USPS for the lowest rates on the
            market.
            <br />
            <br />
          </span>
          <span className="mobile-component">
            Whether your company is large or small, we'll create an account that
            is the perfect fit for you… without any monthly fees.
            <br />
            <br />
          </span>

          {getImgTag(
            {
              baseUrl: CONSTANTS.PUBLIC_URL + "/images/pages/csv/section-2",
              sizes: ["480w", "512w"],
              format: "jpg",
            },
            "floating-img"
          )}
          <span className="desktop-component">
            Open a ShipWinner Business account today, and join our other
            customers who optimize their business operations with ShipWinner!
            <br />
            <br />
          </span>
          <h3>What does ShipWinner Business have to offer?</h3>
          <ul>
            <li>Full End-to-End Service for your shipments.</li>
            <li>
              Easy Label Printing: Print your shipping label in just a few
              clicks with our specially developed tools.
            </li>
            <li>
              Dashboard: Our Dashboard feature consolidates all your shipping
              info in one convenient place.
            </li>
            <li>
              Ship With CSV Uploads/Excel: Save time by importing and sending
              whole lists of packages instead of entering all the information
              one package at a time.
            </li>
            <li>
              Live Price Comparison: No more searching between sites for the
              best rate. We'll compare the different shipping companies' prices
              and find you the lowest rates on the market.
            </li>
            <li>
              One Account: No need to open multiple accounts with shipping
              companies; we'll deal with the shipping companies for you.
            </li>
            <li>
              Package History: We'll save all your package history for future
              reference and easy customer follow-up.
            </li>
            <li>
              Tracking: Real-time tracking information for all your packages
              right here through your ShipWinner account.
            </li>
            <li>
              Shipping Statistics: Analyze and improve your marketing with our
              Shipping Statistics tool, based on your shipping history.
            </li>
          </ul>
        </p>
        <a
          className="button-main"
          onClick={(e) => {
            e.preventDefault();
            navigateToPath("businesses/csv-structure");
            window.scrollTo(0, 0);
          }}
          href={CONSTANTS.API_ROOT + "businesses/csv-structure"}
        >
          Build CSV Tutorial
        </a>
        <p dangerouslySetInnerHTML={{ __html: CSV_MAIN_PAGE }} />
      </div>
    </MultiplePackagesStage0Style>
  );

  function getImgTag(img, className) {
    try {
      let imgName = img.baseUrl.split("/")[img.baseUrl.split("/").length - 1];
      return (
        <img
          className={className}
          srcSet={img.sizes
            .map((s, i) => {
              return (
                img.baseUrl +
                "/" +
                imgName +
                "-" +
                s +
                "." +
                img.format +
                (i !== img.sizes.length - 1 ? ", " : "")
              );
            })
            .reduce((a, b) => a + b, "")}
          src={
            img.baseUrl +
            "/" +
            imgName +
            "-" +
            img.sizes[img.sizes.length - 1] +
            "." +
            img.format
          }
          alt=""
        />
      );
    } catch (err) {
      return null;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    mobile: state.data.displayMode === "MOBILE",
    activeModalQueue: state.data.activeModalQueue,
    apiData_getInitialData: state.data.api.getInitialData,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

const MultiplePackagesStage0 = connect(
  mapStateToProps,
  mapDispatchToProps
)(MultiplePackagesStage0Component);

const MultiplePackagesStage0Style = styled.div`
  .background-image {
    position: absolute;
    width: ${({ width }) => width}px;
    height: 605px;
    background-color: #226d9e;
    img {
      width: ${({ width }) => width}px;
      height: 605px;
      object-fit: cover;
    }
  }
  .main-section {
    background-image: url("${CONSTANTS.PUBLIC_URL}/images/pages/csv/csv-backgroud.jpg");
    background-size: cover;
    height: 650px;
    background-color: lightgrey;
  }
  .floating-description {
    width: 500px;
    background-color: #ffffffe0;
    box-shadow: 2px 2px 6px #0a0a0a;
    border-radius: 3px;
    margin-left: 200px;
    padding-bottom: 20px;
    z-index: 1;
  }
  .drag-and-drop {
    &.drag-over {
      border: dotted 4px #428ca5;
      background-color: #b3aeae;
    }
    border: dotted 4px lightblue;
    margin: 10px;
    padding: 31px 0px;
    background-color: lightgray;
    .text {
      text-align: center;
      font-size: 20px;
      color: grey;
      margin-bottom: 10px;
    }
  }
  .buttons {
    width: 60%;
  }
  .example-file {
    text-decoration: none;
    color: white;
    background: ${Colors.blue1};
    padding: 5px;
    border-radius: 3px;
  }
  .input-file {
    display: none;
  }
  .main-title {
    color: #ffffff;
    text-align: left;
    padding: 21px 0px;
    font-size: 57px;
    margin: 0;
    text-shadow: 6px 6px 8px black;
    opacity: 0.9;
    width: 571px;
    margin-left: 200px;
  }
  .sub-title {
    color: black;
    margin: 30px;
    text-align: center;
    font-family: museo_sans300;
    margin-bottom: 0;
  }
  .button-main {
    display: block;
    margin: auto;
    background: ${Colors.orange};
    padding: 10px 2px;
    font-size: 20px;
    border-radius: 5px;
    width: 221px;
    text-align: center;
    cursor: pointer;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: white;
    &:hover {
      background: ${Colors.orangeHover};
    }
  }
  .links-container {
    padding: 40px;
  }
  .floating-img {
    float: right;
    width: 300px;
    margin-right: 20px;
  }
  .second-section {
    margin: 0 160px;
    margin-top: 120px;
    color: #585858;
    font-size: 20px;
  }
  ul {
    list-style-position: inside;
  }
  li {
    margin: 20px 4px;
  }
  @media only screen and (max-width: 992px) {
    .main-section {
      height: 546px;
      background-color: ${Colors.blue2};
      transform: translate(0px, -1px);
    }
    .floating-description {
      width: unset;
      margin: 0 11px;
      padding-bottom: 20px;
    }
    .buttons {
      width: 0;
    }
    .sub-title {
      font-size: 18px;
    }
    .main-title {
      padding: 30px 3px;
      padding-bottom: 161px;
      font-size: 30px;
      overflow: hidden;
      width: unset;
      text-align: center;
      margin: 0;
    }
    .drag-and-drop {
      .text {
        display: none;
      }
      border: none;
      background: none;
      padding: 0;
    }
    .second-section {
      margin: 150px 20px 20px;
      h3 {
        text-align: center;
      }
      .floating-img {
        float: unset;
        width: 100%;
      }
      p {
        font-size: 18px;
      }
      ul {
        padding-left: 10px;
      }
      margin-bottom: 20px;
    }
  }
`;

export default MultiplePackagesStage0;
