import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Colors } from '../common/colors';
import IMAGES from '../common/images';
import { ZIndices } from "../common/zIndices";
import DayPicker from 'react-day-picker';
import moment from 'moment';
import { attachEvent, detachEvent, isDescendant } from '../common/functions';
import 'react-day-picker/lib/style.css';
import { get } from 'lodash';

function PigeItDatePickerComponent(props) {
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [location, setlocation] = useState({ top: '0px', left: '0px' });
  const inputRef = useRef();
  const modifiers = {
    highlighted: props.date.toDate(),
    disabled: {
      before: new Date(),
    }
  };
  useEffect(() => {
    if (calendarOpen) {
      let input = inputRef.current;
      let loc = get(input.getClientRects(), '[0]', {});
      setlocation({ top: ((loc.top || 0) + (loc.height || 0)) + 'px', left: (loc.left || 0) + 'px' });
    }
  }, [calendarOpen]);

  useEffect(() => {
    // Close the dropdown menu if the user clicks outside of it
    attachEvent(window, 'click', setToCloseOnClickOutside);
    return function () {
      detachEvent(window, 'click', setToCloseOnClickOutside);
    }
  }, []);

  const birthdayStyle = `
    .DayPicker-Day--highlighted {
      background-color: ${Colors.blue1};
      color: black;
    }
    .DayPicker-Day--highlighted:hover {
      background-color: ${Colors.blue1};
      color: black;
    }
    .DayPicker-Day:hover{
      background-color: ${Colors.lightGray1} !important;
  }`;
  return (
    <PigeItDatePickerStyle className={props.className} ref={inputRef}>
      {!props.simple && props.label && <label className={'picker-label' + (props.error ? ' error' : '')}>{props.label}</label>}
      <button type='button' className={'date-container display-flex' + (props.simple ? ' simple' : '')} onClick={() => props.disabled ? null : setCalendarOpen(!calendarOpen)} >
        {!props.simple && <div className='calendar-icon' name='date-picker'><IMAGES.calendar /></div>}
        <div className={'text-button' + (props.disabled ? ' disabled' : '')} type='button' name='date-picker' >{props.simple ? moment(props.date).format('YYYY/MM/DD') : moment(props.date).format('ddd MM/DD')}</div>
      </button>
      <div className={'calendar-container' + (!calendarOpen ? ' hidden-component' : '')} style={{ ...location }} >
        <style>{birthdayStyle}</style>
        <DayPicker disabledDays={{ daysOfWeek: [0, 6] }} modifiers={modifiers} onDayClick={(date, { disabled }) => { if (!disabled) { setCalendarOpen(false); if (props.onChange) props.onChange(date); } }} />
      </div>
    </PigeItDatePickerStyle>
  );

  function setToCloseOnClickOutside(evt) {
    if (!isDescendant(inputRef.current, evt.target)) {
      setCalendarOpen(false);
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const PigeItDatePickerStyle = styled.div`
  position: relative;
  width:100%;
  .calendar-container{
    background-color: rgba(255, 255, 255, 0.95);
    position: fixed;
    z-index: ${ZIndices.datePicker};
    border: solid 1.2px #2699fb;
    border-radius: 3px;
    z-index: 5;
  }
  .date-container:hover{
    border: solid 1px black;
  }
  .date-container{
    &.simple{
      height: 31px;
      padding: 1px;
      background: white;
      border:none;
      border-radius:unset;
      width:unset;
      .text-button{
        font-family: museo_sans500;
        color: #121213;
        font-size: 16px;
      }
    }
    border: solid 1px #c5c5c5;
    border-radius: 3px;
    height: 38px;
    background-color: white;
    width: 100%;
    .text-button{
      width: 100%;
      align-self: center;
    }
    .icons{
      margin-right: 5px;
    }
    .arrow-up, .arrow-down, .text-button, .calensar-button{
        background:unset;
        display: block;
        border: none;
        outline: none;
        cursor: pointer;
      }
    .text-button{
      text-align: left;
      font-family: museo_sans700;
      color: #70777d;
      &.disabled{
        color: ${Colors.lightGray5};
      }
    }
    .arrow-up{
      transform: rotate(180deg);
      margin-top: 3px;
    }
    .calendar-icon{
      margin: 10px;
      svg{
        width:16px;
        height:16px;
        path{
          fill: #C8D9E2;
        }
      }
    }
  }
  .picker-label{
    &.focused{
      color:${Colors.blue1}
    }
    &.error{
      color: ${Colors.error};
      border:none;
    }
    color: #9daab1;
    transform: translate(10px, 6px);
    display: block;
    background: white;
    width: 72px;
    font-size: 11px;
    padding: 0 3px;
  }
`;

const PigeItDatePicker = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItDatePickerComponent);

export default PigeItDatePicker;
