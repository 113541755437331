import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import IMAGES from '../common/images';
import { get, range } from 'lodash';
import { Colors } from '../common/colors';

function PigeitTableComponent(props) {
    let defaultSort = (a, b) => String(getValue(get(b, 'row[0]'))).localeCompare(String(getValue(get(a, 'row[0]'))));
    if (props.defaultSort) {
        defaultSort = (a, b) => props.defaultSort.func(getValue(get(a, 'row[' + props.defaultSort.column + ']')), getValue(get(b, 'row[' + props.defaultSort.column + ']')));
    }
    const [sortFunc, setSortFunc] = useState(() => defaultSort);
    const [headers, setHeaders] = useState(props.headers);
    const [page, setPage] = useState(1);
    const [headersWidth, setheadersWidth] = useState(get(props, 'headers', []).map(item => parseInt(get(item, 'width', 0))));
    useEffect(() => {
        setHeaders(props.headers);
        setheadersWidth(get(props, 'headers', []).map(item => get(item, 'width', null)));
    }, [props.headers]);
    const headerRef = useRef();
    return (
        <PigeitTableStyle className={props.className || ''}>
            <table onMouseUp={onMouseUp}>
                <thead>
                    <tr ref={headerRef} className='head'>
                        {get(props, 'headers', []).map((item, ind) => {
                            return (
                                <th className={'header' + ((get(headers, '[' + ind + '].selected', 0) !== 0) ? ' selected' : '')} onClick={updateShip(item, ind)} style={{ width: get(headersWidth, '[' + ind + ']', null) }} key={ind} title={item.name}>
                                    {item.name}<div className={'icon' + (get(headers, '[' + ind + '].selected', 0) === 1 ? ' down' : get(headers, '[' + ind + '].selected', 0) === 2 ? ' up' : '')}>{<IMAGES.arrowDown />}</div>
                                    <div className='col-seperator' onMouseDown={(e) => onMouseDown(e, ind)} onMouseMove={(e) => onMouseMove(e, ind)} onMouseUp={(e) => onMouseUp(e, ind)} style={{ top: '0', right: '0', width: '15px', position: 'absolute', cursor: 'col-resize', userSelect: 'none', height: '100%' }} />
                                    <div className='col-seperator hidden' onMouseDown={(e) => onMouseDown(e, ind, true)} onMouseMove={(e) => onMouseMove(e, ind, true)} onMouseUp={(e) => onMouseUp(e, ind)} style={{ top: '0', left: '0', width: '15px', position: 'absolute', cursor: 'col-resize', userSelect: 'none', height: '100%' }} />
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {get(props, 'data', []).sort(sortFunc).slice((page - 1) * get(props, 'rowsPerPage', 20), page * get(props, 'rowsPerPage', 20)).map((row, i) => {
                        return (
                            <tr ket={i} onClick={onRowClick(row.row)} className={'table-row ' + (row.error ? 'not-completed' : '') + (get(props, 'onRowClick', null) ? ' clickable' : '')} >
                                {row.row.map((item, ind) => {
                                    if (item.type === 'icon') {
                                        switch (item.value) {
                                            case 1:
                                                return (<td key={i + '-' + ind} className='icon-td'><IMAGES.check /></td>);
                                            case 2:
                                                return (<td key={i + '-' + ind} className='icon-td'><IMAGES.cross /></td>);
                                            case 3:
                                                return (<td key={i + '-' + ind} className='icon-td'><IMAGES.minus /></td>);
                                            default:
                                                return (<td key={i + '-' + ind} title={item}>{item}</td>);
                                        }
                                    } else if (item.type === 'link') {
                                        return (<td key={i + '-' + ind} title={item.value}><a target='_blank' href={item.href}>{item.value}</a></td>);
                                    } else if (item.type === 'button') {
                                        return (<td key={i + '-' + ind} title={item.value}><button onClick={item.onClick} disabled={item.disabled}>{item.value}</button></td>);
                                    } else if (item.type === 'text') {
                                        return (<td key={i + '-' + ind} style={{ backgroundColor: item.celColor, ...(item.style || {}) }} title={item.title || item.value}>{item.value}</td>);
                                    }
                                    return (<td key={i + '-' + ind} title={item}>{item}</td>);
                                })}
                            </tr>)
                    }
                    )}</tbody>
            </table>
            {get(props, 'data', []).length !== 0 && get(props, 'data', []).length >= get(props, 'rowsPerPage', 20) &&
                <>
                    <div className='navigation-container'>
                        <button className='button' onClick={() => setPage(Math.max(1, page - 1))}>{'<'}</button>
                        {range(Math.max(1, parseInt(get(props, 'data', []).length / get(props, 'rowsPerPage', 20) + 1) - 6), parseInt(get(props, 'data', []).length / get(props, 'rowsPerPage', 20) + 1) + 1).map(i =>
                            <button key={i} className={'button'+(page===i?' selected':'')} onClick={() => setPage(i)}>{i}</button>)}
                        <button className='button' onClick={() => setPage(Math.min(parseInt(get(props, 'data', []).length / get(props, 'rowsPerPage', 20) + 1), page + 1))}>{'>'}</button></div>
                    <p className='navigation-text'>Page {page} out of {parseInt(get(props, 'data', []).length / get(props, 'rowsPerPage', 20) + 1)}</p>
                </>}
        </PigeitTableStyle>
    );
    var pageX, curCol, nxtCol, curColWidth, nxtColWidth;

    function onMouseDown(e, i, left) {
        if (!left) {
            curCol = e.target.parentElement;
            nxtCol = curCol.nextElementSibling;
        } else {
            nxtCol = e.target.parentElement;
            curCol = nxtCol.previousElementSibling;
        }
        pageX = e.pageX;

        var padding = paddingDiff(curCol);

        curColWidth = curCol.offsetWidth - padding;
        if (nxtCol)
            nxtColWidth = nxtCol.offsetWidth - padding;
    }
    function onMouseMove(e, ind) {
        if (curCol) {
            var diffX = e.pageX - pageX;
            if (nxtCol && (nxtColWidth - (diffX)) > 50 && (curColWidth + diffX) > 50) {
                nxtCol.style.width = (nxtColWidth - (diffX)) + 'px';
            }
            if ((curColWidth + diffX) > 50) {
                curCol.style.width = (curColWidth + diffX) + 'px';
            }
        }
    }
    function onMouseUp(e, i) {
        curCol = undefined;
        nxtCol = undefined;
        pageX = undefined;
        nxtColWidth = undefined;
        curColWidth = undefined
    }

    function paddingDiff(col) {

        if (getStyleVal(col, 'box-sizing') == 'border-box') {
            return 0;
        }

        var padLeft = getStyleVal(col, 'padding-left');
        var padRight = getStyleVal(col, 'padding-right');
        return (parseInt(padLeft) + parseInt(padRight));

    }
    function getStyleVal(elm, css) {
        return (window.getComputedStyle(elm, null).getPropertyValue(css))
    }

    function onRowClick(row) {
        return (e) => {
            if (props.onRowClick) {
                props.onRowClick(row);
            }
        }
    }

    function updateShip(item, ind) {
        return (e) => {
            if (e.target.className === 'col-seperator') {
                return;
            }
            if (!props.blockSort) {
                setPage(1);
                setHeaders(headers.map((h, i) => {
                    if (i !== ind) {
                        return { ...h, selected: 0 }
                    } else {
                        return { ...h, selected: (get(h, 'selected', 0) + 1) % 3 }
                    }
                }));
                if (item.sortFunc) {
                    switch (headers[ind].selected) {
                        case 2:
                            setSortFunc(() => defaultSort);
                            break;
                        case 0:
                            setSortFunc(() => (a, b) => item.sortFunc(getValue(a.row[ind]), getValue(b.row[ind])));
                            break;
                        case 1:
                            setSortFunc(() => (a, b) => item.sortFunc(getValue(b.row[ind]), getValue(a.row[ind])));
                            break;
                        default:
                            setSortFunc(() => (a, b) => item.sortFunc(getValue(a.row[ind]), getValue(b.row[ind])));
                            break;
                    }
                } else {
                    switch (headers[ind].selected) {
                        case 2:
                            setSortFunc(() => defaultSort);
                            break;
                        case 0:
                            setSortFunc(() => (a, b) => String(getValue(get(a, 'row[' + ind + ']'))).localeCompare(String(getValue(get(b, 'row[' + ind + ']')))));
                            break;
                        case 1:
                            setSortFunc(() => (a, b) => String(getValue(get(b, 'row[' + ind + ']'))).localeCompare(String(getValue(get(a, 'row[' + ind + ']')))));
                            break;
                        default:
                            setSortFunc(() => (a, b) => String(getValue(get(a, 'row[' + ind + ']'))).localeCompare(String(getValue(get(b, 'row[' + ind + ']')))));
                            break;
                    }
                }
            }
        }
    }

    function getValue(item) {
        return item.value ? item.value : item;
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    }
};

const PigeitTableStyle = styled.div`
color: black;
.navigation-container{
    margin-top: 20px;
}
.col-seperator:hover{
    border-right:2px solid #0000ff;
}
.col-seperator.hidden:hover{
    border-right: none;
}
.icon-td{
  text-align:center;
}
table {
  border-collapse: collapse;
  border: 1px solid #ddd;
  border-radius: 3px;
}
th, td {
  padding: 4px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  border-left:1px solid #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
}
td{
  white-space: nowrap;
}
th{
    height:40px;
}
.head{
  background-color: #f5f5f5;
  cursor: pointer;
}
.not-completed{
  background-color: #efb5b5;
}
.not-completed:hover{
  background-color: #f78282;
}
tr:hover {background-color: #f5f5f5;}
.table-row{
    cursor: unset;
    &.clickable{
        cursor: pointer;
    }
}
.header{
    position: relative;
}
.selected{
  background-color: lightgrey;
}
table{
  margin: 20px;
  table-layout: fixed;
}
.icon{
  display: none;
  &.up{
    display: inline-block;
    transform: rotate(180deg);
  }
  &.down{
    display: inline-block;
  }
    margin-left: 10px;
}
.button{
    background-color: ${Colors.blue1};
    border: none;
    border-radius: 3px;
    padding: 5px 11px;
    margin: 0px 8px;
    font-size: 16px;
    font-family: 'museo_sans700';
    color: white;
    &.selected{
        background-color:${Colors.blue2};
    }
    &:hover{
        background-color:${Colors.blue2};
    }
}
`;

const PigeitTable = connect(
    mapStateToProps,
    mapDispatchToProps
)(PigeitTableComponent);

export default PigeitTable;
