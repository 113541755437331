import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { modalQueue } from '../redux/actions';
import { connect, useDispatch } from 'react-redux';
import { Colors } from '../common/colors';
import { Button } from '@material-ui/core';
import CONSTANTS from '../common/constants';
import IMAGES from '../common/images';
import { get } from 'lodash';
import { navigateToPath } from "../common/functions";

function MainPageMoreSectionsComponent(props) {
  const dispatch = useDispatch();

  const reviews = [
    {
      img: {
        baseUrl: CONSTANTS.PUBLIC_URL + '/images/pages/home/why-users-love-pigeit_01/',
        _176w: 'why-users-love-pigeit_01-176w.jpg',
        _320w: 'why-users-love-pigeit_01-320w.jpg',
        _640w: 'why-users-love-pigeit_01-640w.jpg',
        _873w: 'why-users-love-pigeit_01-873w.jpg',
      },
      text: '"ShipWinner is a key ingredient to our growing company, shipping orders has never been so easy."',
      name: 'Jason Berg, business owner'
    },
    {
      text: '"When you’re running an e-commerce business, there’s a perception that shipping is all about postage. It’s truly about seamless physical integrations—from label printing to packaging to scanning and tracking. ShipWinner helps us learn a lot about our operations, In the end, this translated to time and efforts saved for us."',
      name: 'Bob Jackson, business owner'
    },
    {
      img: {
        baseUrl: CONSTANTS.PUBLIC_URL + '/images/pages/home/why-users-love-pigeit_02/',
        _176w: 'why-users-love-pigeit_02-176w.jpg',
        _320w: 'why-users-love-pigeit_02-320w.jpg',
        _640w: 'why-users-love-pigeit_02-640w.jpg',
        _768w: 'why-users-love-pigeit_02-768w.jpg',
        _992w: 'why-users-love-pigeit_02-992w.jpg'
      },
    },
    {
      img: {
        baseUrl: CONSTANTS.PUBLIC_URL + '/images/pages/home/why-users-love-pigeit_03/',
        _176w: 'why-users-love-pigeit_03-176w.jpg',
        _320w: 'why-users-love-pigeit_03-320w.jpg',
        _640w: 'why-users-love-pigeit_03-640w.jpg',
        _873w: 'why-users-love-pigeit_03-873w.jpg',
      },
      text: '"When we began looking into how we could facilitate a subscription service, we wanted to find a partner who could solve all of the nuances of shipping at scale, without us having to build this product ourselves."',
      name: 'Jimmy Center CEO'
    }
  ];

  return (
    <MainPageMoreSectionsStyle>
      <div className="what-can-you-do-with-pigeit">
        <div className="title-text">What Can You Do With ShipWinner?</div>
        <div className="subtitle-text">Shipping can be unnecessarily complicated and inefficient. Wherever you are in the USA, ShipWinner makes it easy for you to get the best price for your shipments. Whether you're sending a small package to a friend or dozens of boxes to your clients, ShipWinner will get you the best price with the right carrier, period.</div>
        <div className="three-options-container display-flex fjc-space-between">
          <div className="flex-divided-3-cols">
            <div className="icon-position">
              <div className="icon-background">
                <IMAGES.doWithPigeItSearch className="icon" />
              </div>
            </div>
            <div className="icon-title-text">The Best Services & Delivery Rates</div>
            <div className="icon-subtitle-text">ShipWinner will compare the top couriers to get you the best services and delivery rates.</div>
          </div>
          <div className="flex-divided-3-cols">
            <div className="icon-position">
              <div className="icon-background">
                <IMAGES.doWithPigeItLabels className="icon" />
              </div>
            </div>
            <div className="icon-title-text">Create Shipping &nbsp; Labels</div>

            <div className="icon-subtitle-text">With ShipWinner you create shipping labels on your own and you are ready to drop off or get your packaged picked up.</div>
          </div>
          <div className="flex-divided-3-cols">
            <div className="icon-position">
              <div className="icon-background">
                <IMAGES.doWithPigeItTrack className="icon" />
              </div>
            </div>
            <div className="icon-title-text">Track All Your Packages</div>
            <div className="icon-subtitle-text">Delight customers with detailed tracking information, scale your operations with powerful, automated ShipWinner tools.</div>
          </div>
        </div>
        <div className="button-container">
          {!get(props, 'apiData_getInitialData.result.success', false) && <Button className="sign-up-button" tabIndex={props.passedTabIndex} onClick={signUp}>GET YOUR FREE SHIPWINNER ACCOUNT</Button>}
        </div>
      </div>
      <div className="deliver-with">
        <div className="background-image">
          <div className="background-color">
            <div className="title-text">We Deliver Anywhere in the USA with the top carriers </div>
            <div className="display-flex fjc-space-between companies-conainer">
              <div className="fff-row-nowrap companies-fedex-container">
                <IMAGES.fedex className="companies-fedex" />
              </div>
              <div className="fff-row-nowrap companies-ups-container">
                <IMAGES.ups className="companies-ups" />
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="pigeit-for-business">
        <div className="title-text">ShipWinner For Business</div>
        <div className="subtitle-text">Ship more in less time with ShipWinner. E-commerce shipping is easy - you'll be up and running in minutes — no installation or special tools involved.</div>
        <div className="three-options-container-first display-flex fjc-space-between">
          <div className="flex-divided-3-cols">
            <div className="col-title-text">Sell and ship anywhere in the US with ShipWinner </div>
            <div className="col-subtitle-text">Create shipping labels, add custom templates, choose the best carrier, handle customs documents.</div>
          </div>
          <div className="flex-divided-3-cols">
            <div className="col-title-text">Manage orders and view shipping reports</div>
            <div className="col-subtitle-text">Place holds, add insurance, create alerts, and adjust weight or dimensions.</div>
          </div>
          <div className="flex-divided-3-cols">
            <div className="col-title-text">Keep all your carrier records in one place</div>
            <div className="col-subtitle-text">ShipWinner makes sure all your orders play nice, whether you ship with UPS and FedEx</div>
          </div>
        </div>
        <div className="contact-us-text">Contact Us Today For Additional Offers</div>
        <div>
          <Button className="contact-us-button" tabIndex={props.passedTabIndex} onClick={contactUs}>CONTACT US</Button>
        </div>
        <div className="three-options-container-second display-flex fjc-space-between">
          <div className="flex-divided-3-cols">
            <div className="col-title-text">Use automation</div>
            <div className="col-subtitle-text">Choose the fastest shipping option, adjust ship-by date, assign to specific person, and more</div>
          </div>
          <div className="flex-divided-3-cols">
            <div className="col-title-text">Control the process</div>
            <div className="col-subtitle-text">User-level permissions and restrictions that give you full control</div>
          </div>
          <div className="flex-divided-3-cols">
            <div className="col-title-text">Receive great discounts</div>
            <div className="col-subtitle-text">Take advantage of incredible UPS and FedEx discounts</div>
          </div>
        </div>
      </div>
      <div className="seperator">
        <hr />
      </div>
      <div className="why-users-love-pigeit">
        <div className="title-text">Why Users Love ShipWinner</div>
        <div className="subtitle-text">Join the numerous customers who have mastered their shipping with ShipWinner. Learn what they say.</div>
        <table className="desktop-component">
          <tbody>
            <tr>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <td className="review-item review-item-first">
                        <div className="background">
                          <div className="review-image">
                            <img
                              srcSet={reviews[0].img.baseUrl + reviews[0].img._176w + ' 167w,\n' +
                                reviews[0].img.baseUrl + reviews[0].img._320w + ' 320w,\n' +
                                reviews[0].img.baseUrl + reviews[0].img._640w + ' 640w, \n'+
                                reviews[0].img.baseUrl + reviews[0].img._873w + ' 873w'}
                              src={reviews[0].img.baseUrl + reviews[0].img._873w}
                              alt="" />
                          </div>
                          <div className="review-text">{reviews[0].text}</div>
                          <div className="review-name">{reviews[0].name}</div>
                        </div>
                      </td>
                      <td className="review-item review-item-second">
                        <div className="background">
                          <div className="review-text">{reviews[1].text}</div>
                          <div className="review-name">{reviews[1].name}</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="review-item review-item-fourth" colSpan="2">
                        <div className="background">
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="review-text">{reviews[3].text}</div>
                                  <div className="review-name">{reviews[3].name}</div>
                                </td>
                                <td>
                                  <div className="review-image">
                                  <img
                                      srcSet={reviews[3].img.baseUrl + reviews[3].img._176w + ' 167w,\n' +
                                        reviews[3].img.baseUrl + reviews[3].img._320w + ' 320w,\n' +
                                        reviews[3].img.baseUrl + reviews[3].img._640w + ' 640w, \n'+
                                        reviews[3].img.baseUrl + reviews[3].img._873w + ' 873w'}
                                      src={reviews[3].img.baseUrl + reviews[3].img._873w}
                                      alt="" />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="review-item review-item-third">
                <div className="review-image">
                  <img
                    srcSet={reviews[2].img.baseUrl + reviews[2].img._176w + ' 167w,\n' +
                      reviews[2].img.baseUrl + reviews[2].img._320w + ' 320w,\n' +
                      reviews[2].img.baseUrl + reviews[2].img._640w + ' 640w,\n' +
                      reviews[2].img.baseUrl + reviews[2].img._768w + ' 768w,\n' +
                      reviews[2].img.baseUrl + reviews[2].img._992w + ' 992w'}
                    src={reviews[2].img.baseUrl + reviews[2].img._992w}
                    alt="" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="mobile-component">
          <tbody>
            <tr>
              <td className="review-item review-item-first">
                <div className="background">
                  <div className="review-image">
                  <img
                              srcSet={reviews[0].img.baseUrl + reviews[0].img._176w + ' 167w,\n' +
                                reviews[0].img.baseUrl + reviews[0].img._320w + ' 320w,\n' +
                                reviews[0].img.baseUrl + reviews[0].img._640w + ' 640w, \n'+
                                reviews[0].img.baseUrl + reviews[0].img._873w + ' 873w'}
                              src={reviews[0].img.baseUrl + reviews[0].img._873w}
                              alt="" />
                  </div>
                  <div className="review-text">{reviews[0].text}</div>
                  <div className="review-name">{reviews[0].name}</div>
                </div>
              </td>
            </tr>
            <tr>
              <td className="review-item review-item-second">
                <div className="background">
                  <div className="review-text">{reviews[1].text}</div>
                  <div className="review-name">{reviews[1].name}</div>
                </div>
              </td>
            </tr>
            <tr>
              <td className="review-item review-item-third">
                <div className="review-image">
                  <img
                    srcSet={reviews[2].img.baseUrl + reviews[2].img._176w + ' 167w,\n' +
                      reviews[2].img.baseUrl + reviews[2].img._320w + ' 320w,\n' +
                      reviews[2].img.baseUrl + reviews[2].img._640w + ' 640w,\n' +
                      reviews[2].img.baseUrl + reviews[2].img._768w + ' 768w,\n' +
                      reviews[2].img.baseUrl + reviews[2].img._992w + ' 992w'}
                    src={reviews[2].img.baseUrl + reviews[2].img._992w}
                    alt="" />
                </div>
              </td>
            </tr>
            <tr>
              <td className="review-item review-item-fourth">
                <div className="background">
                  <div className="review-image">
                  <img
                                      srcSet={reviews[3].img.baseUrl + reviews[3].img._176w + ' 167w,\n' +
                                        reviews[3].img.baseUrl + reviews[3].img._320w + ' 320w,\n' +
                                        reviews[3].img.baseUrl + reviews[3].img._640w + ' 640w, \n'+
                                        reviews[3].img.baseUrl + reviews[3].img._873w + ' 873w'}
                                      src={reviews[3].img.baseUrl + reviews[3].img._873w}
                                      alt="" />
                  </div>
                  <div className="review-text">{reviews[3].text}</div>
                  <div className="review-name">{reviews[3].name}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </MainPageMoreSectionsStyle>
  );

  function signUp() {
    if (get(props, 'apiData_getInitialData.result.success', false)) {
      return;
    }
    navigateToPath('sign-in', true);
  }

  function contactUs() {
    navigateToPath('contact-us', true);
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    apiData_getInitialData: state.data.api.getInitialData
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const MainPageMoreSectionsStyle = styled.div`
  text-align: center;
  .what-can-you-do-with-pigeit {
    padding-top: 80px;
    .title-text {
      color: ${Colors.black};
      font-size: 36px;
      margin-bottom: 10px;
    }
    .subtitle-text {
      margin: 0 230px 20px 230px;
      font-size: 18px;
      color: ${Colors.lightGray2};
      line-height: 30px;
    }
    .three-options-container {
      margin: 0 200px 0 200px;
    }
    .icon-position {
      display: inline-block;
      text-align: center;
      .icon-background {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        display: table-cell;
        vertical-align: middle;
        background-color: ${Colors.panelBackground};
        .icon {
          width: 50px;
        }
      }
    }
    .icon-title-text {
      font-family: museo_sans300, sans-serif;
      font-size: 24px;
      color: ${Colors.darkBlue1};
      padding: 30px;
      min-height: 54px;
    }
    .icon-subtitle-text {
      font-family: museo_sans300, sans-serif;
      font-size: 16px;
      color: ${Colors.mediumBlack};
      padding: 0 10px;
    }
    .button-container {
      margin-top: 50px;
      margin-bottom: 50px;
    }
    .sign-up-button {
      font-family: museo_sans700, sans-serif;
      font-size: 14px;
      color: ${Colors.white};
      background-color: ${Colors.orange};
      width: 350px;
      height: 56px;
    }
  }
  // mobile for what-can-you-do-with-pigeit
  @media only screen and (max-width: 992px) {
    .what-can-you-do-with-pigeit {
      padding-top: 20px;
      .sign-up-button{
        width: 300px;
      }
      .icon-subtitle-text{
        margin: 0 5%;
      }
      .title-text{
        font-size: 26px;
        margin: 3%;
      }
      .three-options-container {
        margin: 0 10px 0 10px;
        flex-flow: column;
      }
      .flex-divided-3-cols {
        margin-top: 20px;
      }
      .subtitle-text {
        margin: 0 4%;
      }
    }
  }
  .deliver-with {
    .background-image {
      background-image: url('${CONSTANTS.PUBLIC_URL}/images/pages/home/we-deliver-anywhere/we-deliver-anywhere.jpg');
    }
    .background-color {
      background-color: rgba(112,119,125,.75);
      height: 520px;
      vertical-align: middle;
      display: table-cell;
      width: 1%;
    }
    color: ${Colors.white};
    .title-text {
      margin: 5%;
      margin-top: 20px;
      font-size: 36px;
    }
  }
  .pigeit-for-business {
    padding: 80px 5% 0;
    .title-text {
      font-size: 36px;
      color: ${Colors.black};
      margin-bottom: 30px;
    }
    .subtitle-text {
      font-size: 18px;
      max-width: 800px;
      color: ${Colors.lightGray2};
      margin: 0 auto;
      margin-bottom: 30px;
      line-height: 30px;
    }
    .three-options-container-first,
    .three-options-container-second {
      max-width: 850px;
      margin: 0 auto;
    }
    .three-options-container-second {
      margin-bottom: 40px;
    }
    .col-title-text {
      font-size: 18px;
      font-family: museo_sans500, sans-serif;
      margin-bottom: 30px;
      min-height: 40px;
    }
    .col-subtitle-text {
      font-size: 16px;
      font-family: museo_sans300,sans-serif;
      color: ${Colors.mediumBlack};
    }
    .contact-us-text {
      font-size: 21px;
      color: ${Colors.orange};
      margin-top: 68px;
      margin-bottom: 17px;
    }
    .contact-us-button {
      font-family: museo_sans700, sans-serif;
      font-size: 14px;
      color: ${Colors.white};
      background-color: ${Colors.orange};
      width: 250px;
      height: 56px;
      margin-bottom: 71px;
    }
    .three-options-container-third,
    .three-options-container-fourth {
      max-width: 850px;
      margin: 0 auto;
    }
  }
  // mobile for pigeit-for-business
  @media only screen and (max-width: 992px) {
    .pigeit-for-business {
      .three-options-container-first,
      .three-options-container-second {
        margin: 0 10px 0 10px;
        flex-flow: column;
      }
      .flex-divided-3-cols {
        margin-top: 20px;
      }
      .col-title-text {
        min-height: 0;
      }
      .contact-us-button {
        max-width: 350px;
      }
    }
  }
  .seperator hr {
    max-width: 850px;
    border: 1px solid rgba(175,179,181,.36);
  }
  .why-users-love-pigeit {
    .title-text{
      margin: 0 3%;
    }
    .desktop-component {
      display: inline-block;

      .review-item {
        text-align: left;
        position: relative;
        height: 358px;
        width: 300px;
        font-size: 15px;
        line-height: 24px;
      }
      .review-item-first {
        padding-bottom: 20px;
        padding-right: 10px;
        width: 300px;
        max-width: 300px;
        margin-right: 20px;
        .review-image img {
          width: 300px;
        }
        .review-text {
          padding: 0 20px 0 20px;
          height: calc(100% - 260px);
        }
      }
      .review-item-second {
        padding-bottom: 20px;
        max-width: 300px;
        width: 300px;
        margin-right: 20px;
        .review-text {
          height: calc(100% - 50px);
          padding: 20px 20px 0 20px;
        }
      }
      .review-item-third {
        padding-left: 10px;
        .review-image img {
          width: 300px;
          height: 640px;
        }
      }
      .review-item-fourth {
        width: 300px;
        max-width: 300px;
        height: 260px;
        margin-top: 20px;
        .review-image {
          line-height: 0;
          img {
            width: 300px;
          }
        }
        .review-text {
          padding: 20px;
          height: 190px;
        }
      }
    }
    .mobile-component {
      display: inline-block;
      text-align: center;
      .title-text{
        margin: 0 3%;
      }
      .review-image{
        margin: 3%;
      }
      .review-item {
        margin: 3%;
        .review-text {
          margin-left: 20px;
          margin-right: 20px;
          text-align: left;
        }
        .review-name {
          padding-top: 25px;
          padding-bottom: 25px;
        }
      }
      .review-item-first {
        display: inline-block;
        text-align: center;
        .background {
          .review-image {
            margin: 0;
          }
          max-width: 100%;
        }
        .review-image {
          text-align: center;
          img {
            width: 100%;
          }
        }
      }
      .review-item-second {
        display: inline-block;
        text-align: center;
        .background {
          .review-image {
            margin: 0;
          }
          max-width: 100%;
        }
        .review-text {
          padding-top: 20px;
        }
      }
      .review-item-third {
        .review-image img {
          width: 100%;
        }
      }
      .review-item-fourth {
        display: inline-block;
        text-align: center;
        .background {
          .review-image {
            margin: 0;
          }
          max-width: 100%;
        }
        .review-image img {
          margin: 0;
          width: 100%;
        }
      }
    }
    .title-text {
      margin-top: 65px;
      margin-bottom: 30px;
      font-size: 36px;
      color: ${Colors.black};
    }
    .subtitle-text {
      margin: 5%;
      margin-bottom: 63px;
      font-size: 18px;
      color: ${Colors.lightGray2};
    }
    table {
      border-spacing: 0;
    }
    td {
      vertical-align: top;
    }
    .review-item {
      text-align: left;
      position: relative;
      color: ${Colors.black};
      font-size: 15px;
      line-height: 24px;
      .background {
        height: 100%;
        background-color: ${Colors.lightGray3};
      }
    }
    .review-name {
      text-align: center;
      color: ${Colors.lightGray2};
      &::before, &::after {
        content: "\\A0\\A0\\2014\\A0\\A0";
      }
    }
    .review-item-third {
      background-color: transparent;
      .review-image img {
        object-fit: cover;
      }
    }
  }
`;

const MainPageMoreSections = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainPageMoreSectionsComponent);

export default MainPageMoreSections;
