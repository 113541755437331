export const ZIndices = {
  navigation: '2',
  horizontalSelectOptionsSeperator: '1',
  modal: '3',
  loader: '4',
  selectComponent: '1',
  thankYouComponent: '2',
  myProfileHeader: '2',
  choosePickup: '1',
  datePicker: '1',
  myAccountAddressBook: '2',
  myAccountAddressBookAddEdit: '2',
  myAccountCreditCard: '2',
  myAccountCreditCardAddEdit: '1',
  myAccountPackages: '2',
  myAccountPackagesAddEdit: '2',
  myAccountPersonalInfo: '2',
  packagesAddEdit: '2',
  packagesMoreOptions: '1'
};
