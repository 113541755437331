import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Colors } from "../common/colors";
import IMAGES from "../common/images";
import { get } from "lodash";
import {
  attachEvent,
  detachEvent,
  isDescendant,
  navigateToPath,
} from "../common/functions";
import { ZIndices } from "../common/zIndices";

function PigeItDropDownComponent(props) {
  const container = useRef();
  const menu = useRef();
  const [offset, setoffset] = useState(0);
  const [minWidth, setminWidth] = useState(0);
  useEffect(() => {
    try {
      if (
        window.screen.width -
          (container.current.offsetLeft + menu.current.offsetWidth) <
        0
      ) {
        setoffset(container.current.offsetWidth - menu.current.offsetWidth);
      }
      if (container.current.offsetWidth > menu.current.offsetWidth) {
        setminWidth(container.current.offsetWidth);
      }
    } catch (err) {}
  });
  return (
    <PigeItDropDownStyle className={props.className}>
      <div className="dropdown" ref={container}>
        {props.new && <div className='new-tag'>NEW</div>}
        <a
          className="dropbtn"
          href={"/" + get(props, "main.href")}
          onClick={navigateLink(
            get(props, "main.href"),
            get(props, "main.onClick"),
            get(props, "main.queryParams")
          )}
        >
          {get(props, "main.text")}
        </a>
        <div
          className="dropdown-content"
          ref={menu}
          style={{
            transform: `translate(${offset}px, 0px)`,
            minWidth: `${minWidth}px`,
          }}
        >
          {get(props, "links", []).map((link, i) => {
            return (
              <a
                key={i}
                href={"/" + get(link, "href")}
                onClick={navigateLink(
                  get(link, "href"),
                  get(link, "onClick"),
                  get(link, "queryParams")
                )}
              >
                {get(link, "text")}
              </a>
            );
          })}
        </div>
      </div>
    </PigeItDropDownStyle>
  );

  function navigateLink(href, onClick, queryParams) {
    return (e) => {
      e.preventDefault();
      if (typeof onClick === "function") {
        onClick();
      }
      navigateToPath(href, true, false, queryParams);
    };
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: ownProps.onChange,
  };
};

const PigeItDropDownStyle = styled.div`
  .new-tag {
    position: absolute;
    transform: translate(94px, 4px);
    font-size: 10px;
    background-color: #fcb02e;
    padding: 1px 3px;
    border-radius: 2px;
    box-shadow: -3px 3px 3px 0px #005ea3;
  }
  .dropbtn {
    color: white;
    padding: 17px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    display: block;
    font-family: museo_sans500;
    text-decoration: none;
    white-space: nowrap;
    width: calc(100% - 32px);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .dropdown-content {
    text-align: left;
    visibility: hidden;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 5;
    border-radius: 3px;
    opacity: 0.95;
  }

  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    white-space: nowrap;
  }

  .dropdown-content a:hover {
    background-color: #f1f1f1;
    border-radius: 3px;
  }

  .dropdown:hover .dropdown-content {
    visibility: visible;
  }

  .dropdown:hover .dropbtn {
    background-color: ${Colors.blue2};
    border-radius: 3px;
  }
`;

const PigeItDropDown = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItDropDownComponent);

export default PigeItDropDown;
