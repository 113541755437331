import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { modalQueue, api_addressBook_add, api_addressBook_update, api_addressBook_getList, setupAnimatedLoader } from '../redux/actions';
import { Button, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { Colors } from "../common/colors";
import { showErrorMessage } from '../common/functions';
import IMAGES from '../common/images';
import Loader from './Loader.component';
import PigeItInputAddress from "./PigeItInputAddress.component";
import { get } from 'lodash';
import CONSTANTS from '../common/constants';
import { ZIndices } from "../common/zIndices";
import PigeItInput from './PigeItInput.component';


function PigeItMyAccount_AddressBook_AddEditComponent(props) {

  const dispatch = useDispatch();
  const [inProgress, setInProgress] = useState(false);
  const [palceId, setPlaceId] = useState('');
  const [error, setError] = useState({});
  const packageToUpdate = get(props, 'modalConfig.packageToUpdate', false);
  const [edit, setEdit] = useState(packageToUpdate !== false);
  const [leftColFields, setLeftColFields] = useState([
    { label: 'Street', id: 'Street', name: 'Street', type: 'address', value: edit ? packageToUpdate.address : '' },
    { label: 'City', id: 'City', name: 'City', type: 'text', value: '', disabled: true },
    { label: 'State', id: 'State', name: 'State', type: 'text', value: '', disabled: true },
    {
      first: { label: 'Country', id: 'Country', name: 'Country', type: 'text', value: '', disabled: true },
      second: { label: 'Zip Code', id: 'Zip Code', name: 'Zip Code', type: 'text', value: '' },
    }
  ]);
  const [rightColFields, setRightColFields] = useState([
    { label: 'Full Name', id: 'FullName', name: 'Full Name', type: 'text', value: edit ? packageToUpdate.fullname : '' },
    { label: 'Company', id: 'Company', name: 'Company', type: 'text', value: edit ? packageToUpdate.company : '' },
    { label: 'Email', id: 'Email', name: 'Email', type: 'text', value: edit ? packageToUpdate.email : '' },
    { label: 'Phone', id: 'Phone', name: 'Phonel', type: 'text', value: edit ? packageToUpdate.phone : '' }
  ]);
  useEffect(function () {
    // read the list from the server
    onAddressInputChange({ placeId: packageToUpdate.googlePlaceId, placeObject: { formatted_address: packageToUpdate.address } });
  }, []);

  return (
    <PigeItMyAccount_AddressBook_AddEditStyle>
      <div className="mobile-component bottom-banner">
        <div className="add-address-button-container">
          <Button onClick={save}> {inProgress && <Loader imageOnly />} {edit ? 'EDIT ADDRESS' : 'ADD ADDRESS'}</Button>
        </div>
      </div>
      <div className="background-container">
        <div className="content-container">
          <Button className="modal-close-button" onClick={close}>
            <IMAGES.close />
          </Button>
        </div>
        <div className="background-container">
          <div className="title-and-add-to-address-book-control-container display-flex fjc-space-around">
            <div className="title-text flex-divided-2-cols">
              {edit ? 'Edit Address' : 'Add New Address'}
            </div>
          </div>
          <div className="display-flex fjc-space-between">
            <div className="flex-divided-2-cols first-col">
              {leftColFields.map((item, i) => {
                return (item.first ? (
                  <div key={i} className="fjc-space-between two-fielt-container">
                    <div className=" first ta-left field-label-input-container">
                      {item.first.type === "address" ?
                        <PigeItInputAddress className='input-field' passedValue={item.first.value} onChange={(value) => { onAddressInputChange(value) }} />
                        : <PigeItInput label={item.first.label} name={item.first.id} id={item.first.name} type={item.first.type} className="input-field" value={item.first.value} disabled={inProgress || item.first.disabled} readOnly={item.first.readonly ? true : false} onChange={(e) => { changeField(item.first, e); }} />} </div>
                    <div key={item.second.id} className="second ta-left field-label-input-container">
                      <PigeItInput label={item.second.label} name={item.second.id} id={item.second.name} type={item.second.type} className="input-field" value={item.second.value} disabled={inProgress || item.first.disabled} readOnly={item.second.readonly ? true : false} onChange={(e) => { changeField(item.second, e); }} />
                    </div>
                  </div>
                ) : (
                    <div key={i} className="ta-left field-label-input-container">
                      {item.type === "address" ?
                        <PigeItInputAddress error={error[item.id]} className='input-field' passedValue={item.value} onChange={(value) => { onAddressInputChange(value) }} />
                        :
                        <PigeItInput label={item.label} name={item.id} id={item.name} type={item.type} className="input-field" value={item.value} disabled={inProgress || item.disabled} readOnly={item.readonly ? true : false} onChange={(e) => { changeField(item, e); }} />
                      }
                    </div>));
              })}
            </div>
            <div className="flex-divided-2-cols second-col">
              {rightColFields.map((item) => {
                return (
                  <div key={item.id} className="ta-left field-label-input-container">
                    <PigeItInput label={item.label} name={item.id} id={item.name} type={item.type} className={"input-field"} error={error[item.id]} value={item.value} disabled={inProgress || item.disabled} readOnly={item.readonly ? true : false} onChange={(e) => { changeField(item, e); }} />
                  </div>);
              })}
            </div>
          </div>
        </div>
        <div className="display-flex  fjs-center">
          <div className="place-holde" />
          <div className="desktop-component add-address-button-container">
            <Button onClick={save}> {inProgress && <Loader imageOnly />} {edit ? 'EDIT ADDRESS' : 'ADD ADDRESS'}</Button>
          </div>
          <button className="desktop-component cancel-button" onClick={close}>CANCEL</button>
        </div>

      </div>

    </PigeItMyAccount_AddressBook_AddEditStyle>
  );

  function save() {
    if (inProgress) {
      return;
    }
    let err = {};
    if (palceId === '') {
      err.Street = true;
    }
    rightColFields.forEach(item => {
      if (item.value === '' && item.id != 'Company' || item.id === 'Email' && !CONSTANTS.VALID_EMAIL_REGEX.test(String(item.value).toLowerCase()) || item.id === 'Phone' && !CONSTANTS.VALID_PHONE_REGEX.test(String(item.value).toLowerCase())) {
        err[item.id] = true;
      }
    });
    setError(err);
    if (!Object.keys(err).length) {
      let result = {
        company: get(rightColFields, '[1].value', ' your Company') || ' your Company',
        email: get(rightColFields, '[2].value', ''),
        fullname: get(rightColFields, '[0].value', ''),
        googlePlaceId: palceId,
        phone: get(rightColFields, '[3].value', ''),
      };
      if (edit) {
        result.id = get(props, 'modalConfig.packageToUpdate.id', '');
      }
      let dispatchMethod;
      let loaderText;
      if (edit) {
        dispatchMethod = api_addressBook_update;
        loaderText = 'Saving changes...';

      } else {
        dispatchMethod = api_addressBook_add;
        loaderText = 'Saving address...';
      }
      setInProgress(true);
      dispatch(setupAnimatedLoader({ isVisible: true, text: loaderText }));
      result.callback = function (result) {
        if (result.success) {
          dispatch(api_addressBook_getList({
            callback: function () {
              setInProgress(false);
              dispatch(setupAnimatedLoader({ isVisible: false }));
              dispatch(modalQueue({ mode: 'delete' }));
            }
          }));
        } else {
          showErrorMessage(result, dispatch);
          setInProgress(false);
          dispatch(setupAnimatedLoader({ isVisible: false }));
        }
      };
      dispatch(dispatchMethod(result));
    }
  }

  function changeField(item, e) {

    setRightColFields(rightColFields.map(it => {
      if (it.id === item.id) {
        return { ...it, value: e.target.value };
      }
      else {
        return it;
      }
    }));
  }

  function close() {
    dispatch(modalQueue({ mode: 'delete' }));
  }

  function onAddressInputChange(value) {
    //112 Ocean Ave, Amityville, NY 11701, USA
    let addressValue = get(value, 'placeObject.formatted_address', '');
    let placeIdValue = get(value, 'placeId', '');
    setPlaceId(placeIdValue);
    if (!addressValue) {
      return;
    }
    addressValue = addressValue.split(',');
    let city = addressValue.length >= 1 ? addressValue[1] : '';
    let state = addressValue.length >= 2 ? addressValue[2].split(' ').length > 1 ? addressValue[2].split(' ')[1] : "" : "";
    let country = addressValue.length >= 3 ? addressValue[3] : "USA";
    let zipCode = addressValue.length >= 2 ? addressValue[2].split(' ').length > 2 ? addressValue[2].split(' ')[2] : "" : "";
    setLeftColFields(leftColFields.map(item => {
      if (item.label === 'City') {
        return { ...item, value: city };
      }
      else if (item.label === 'State') {

        return { ...item, value: state };
      }
      else if (item.first && item.first.label === 'Country') {
        return { first: { ...item.first, value: country }, second: { ...item.second, value: zipCode } };
      }
      else if (item.label === 'Street') {
        // update google address displayed value
        return { ...item, value: addressValue };
      }
      else {
        return item;
      }
    }));

  }

}

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  };
};

const PigeItMyAccount_AddressBook_AddEditStyle = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  text-align: center;
  overflow: auto;
  .error{
    input {
      border: solid 1px ${Colors.error};
    }
  }
  .address-input{
    width: 100%;
  }
  .content-container {
   
    padding-right: 65px;
    padding-left: 65px;
  }
  .modal-close-button {
    position: absolute;
    top: 21px;
    right: 26px;
  }
  .background-container {
    padding: 36px 49px 32px 49px;
    background-color: #FFFFFF;
  }
  .two-fielt-container{
    width: calc(100% - 25px);
    display: flex;
  }
  .first{
    flex: 0 0 65%;   
  }
  .second{
   flex:0 0 30% 
  
  }
  .place-holde{
    width:255.5px;
  }
.add-address-button-container {
    padding-left: 30px;
    flex: 0 0 33%;
    BUTTON {
      padding-left: 47px;
    padding-right: 47px;
      font-family: museo_sans700,sans-serif;
      font-size: 14px;
      max-width: 250px;
      width: 100%;
      height: 56px;
      color: ${Colors.white};
      background-color: ${Colors.orange};
    }
  }
  .cancel-button {
    font-size: 14px;
    flex:0 0 33%;
    background-color: transparent;
    border: none;
    color: #2699FB;
    font-family: museo_sans500;
    outline:none;
    padding-left: 40px;
    cursor: pointer;
    text-align: left;
    
  }
  .bottom-banner {
    width: 100%;
    position: fixed;
    background: white;
    z-index: ${ZIndices.myAccountAddressBookAddEdit};
    bottom: 0;
    box-shadow: 0px -1px 6px #d0d0d0;
  }
  .title-text {
    text-align: center;
    font-family: museo_sans500,sans-serif;
    color: #323232;
    margin-bottom:40px;
    font-size: 36px;
    
  }
  .add-to-adress-book-control {
    text-align: right;
    margin: 19px 25px 0px 0px;
  }
  .field-label {
    font-size: 16px;
    color: ${Colors.lightGray2};
    margin-bottom: 11px;
  }
  .input-field {
    width:100%;
    margin-bottom: 20px;
  }
  .field-label-input-container {
    width: calc(100% - 25px);
  }
  .second-col .field-label-input-container {
    width: calc(100% - 45px);
    margin-left: 20px;
  }
  @media only screen and (max-width: 992px) {
    .display-flex {
      flex-flow: column nowrap;
    }
    .input-field {
    }
    .first-input{
      flex: 0 0 60%;
    }
    .background-container{     
      padding: 36px 19px 32px 19px;
    }
    .second-col{ 
      .field-label-input-container{
         width: unset;
         margin-left:unset;
      }
    }
    .first-col{ 
      .field-label-input-container{
         width: unset;
         margin-left:unset;
      }
    }
    .add-address-button-container{
      padding-left:unset;
      margin: 10px 0;
    }
    .two-fielt-container{
      width: 100%;
      display: flex;
    }
    .address-input{
    }
  }
`;

const PigeItMyAccount_AddressBook_AddEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItMyAccount_AddressBook_AddEditComponent);


export default PigeItMyAccount_AddressBook_AddEdit;
