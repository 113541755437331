/* eslint react-hooks/exhaustive-deps: off */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect, useDispatch, useStore } from 'react-redux';
import { modalQueue, api_getPrices, genericSetValue } from '../redux/actions';
import { Button } from '@material-ui/core';
import { Colors } from "../common/colors";
import { get } from 'lodash';
import IMAGES from '../common/images';
import { buildPackages, getBestPrice ,showErrorMessage} from "../common/functions";
import PigeItSelect from './PigeItSelect.component';
import PigeItDatePicker from './PigeItDatePicker.component';
import CONSTANTS from '../common/constants';
import Loader from './Loader.component';
import { ZIndices } from "../common/zIndices";
import moment from 'moment';

let GLOBAL_REQUEST_ID = 0;

function PigeItChoosePickUpTimeComponent(props) {

  const dispatch = useDispatch();
  const store = useStore();
  const [ date, setDate ] = useState(getNearestValidTime());
  const [ time, setTime ] = useState({ value: getNearestValidTime().format('HH:mm'), text: getNearestValidTime().format('HH:mm') });
  const [ inProgress, setInProgress] = useState(true);
  const [ price, setPrice] = useState('$0');
  const [ error, setError] = useState(false);
  const TIME_LIST = [];

  // find first available day
  const nearestValidTime = (date ? date.clone() : getNearestValidTime());
  const nearestValidTimeDayNumber = nearestValidTime.day() + 1;
  const nearestValidTimePickupRanges = CONSTANTS.PICKUP_HOURS_RANGES[nearestValidTimeDayNumber];


  if (!nearestValidTimePickupRanges) {
    // no valid day for display
    TIME_LIST.push({ value: -1, text: 'NO PICKUP AVAILABLE TODAY' } );
    const errorMessage = 'Pickup is not available.';
    if (error !== errorMessage) {
      setError(errorMessage);
      setInProgress(false);
      setTime(TIME_LIST[0]);
    }
  }
  else {
    if (error !== false) {
      setError(false);
    }
    // build time list for matched day
    const nearestValidTimeListObject = nearestValidTime.clone();
    nearestValidTimeListObject.set('hours', nearestValidTimePickupRanges.min).set('minutes', 0).set('seconds', 0);
    let isExistingTime = false;
    while (parseInt(nearestValidTimeListObject.format('H')) <= nearestValidTimePickupRanges.max) {
      const newItem = { value: nearestValidTimeListObject.format('HH:mm'), text: nearestValidTimeListObject.format('hh:mm A') };
      TIME_LIST.push(newItem);
      if (time.value === newItem.value) {
        isExistingTime = true;
      }
      nearestValidTimeListObject.add('hours', 1);
      if ((nearestValidTimeListObject.day() + 1) !== nearestValidTimeDayNumber) {
        // should not happen, but anyway...
        break;
      }
    }
    // set selected time
    if (!isExistingTime) {
      // override selected time since it's missing
      setTime(TIME_LIST[0]);
    }
  }

  // re-calculate price with pick-up
  useEffect(function() {
    if (error) {
      return;
    }
    recalculatePrice();
  }, [date, time]);

  return (
    <PigeItChoosePickUpTimeStyle className="choose-pickup-time-container">
      <div className="background-container">
        <div className="bottom-banner mobile-component">
          <button tabIndex={props.passedTabIndex} className="best-price-ship-now-button" onClick={shipNow}>
            <div className='display-flex button-content'>
              <div className='best-price'>
                <div className='best-price-text'>BEST PRICE</div>
                <div className='best-price-price'>{ inProgress && (<Loader imageOnly={true} />)} {price}</div>
                <div className="error-container">{error !== false ? error : ''}</div>
              </div>
              <div className='ship-now'>SHIP NOW</div>
            </div>
          </button>
        </div>
        <div className="content-container">
          <Button className="modal-close-button" onClick={close}>
            <IMAGES.close />
          </Button>
          <div className="header-text">Choose<br />Pick Up Time</div>
          <div className='input-container'>
            <PigeItDatePicker date={date} onChange={(newValue) => { onDatePickerChange(newValue); }} />
            <div className='palce-holder' />
            <PigeItSelect icon={IMAGES.clock} value={time} valueList={TIME_LIST} onChange={(value) => { setTime(value); } } />
          </div>
          <button tabIndex={props.passedTabIndex} className="best-price-ship-now-button desktop-component" onClick={shipNow}>
            <div className='display-flex button-content'>
              <div className='best-price'>
                <div className='best-price-text'>BEST PRICE</div>
                <div className='best-price-price'>{ inProgress && (<Loader imageOnly={true} />)} {price}</div>
              </div>
              <div className='ship-now'>SHIP NOW</div>
            </div>
          </button>
          <div className="error-container">{error !== false ? error : ''}</div>
        </div>
      </div>

    </PigeItChoosePickUpTimeStyle >
  );

  function onDatePickerChange(newValue) {
    setDate(moment(newValue));
  }

  function shipNow() {
    if (inProgress || error) {
      return;
    }
    const now = moment(moment().format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm'); // ignore seconds and stuff
    const selectedTime = moment(date.format('YYYY-MM-DD') + ' ' + time.value,'YYYY-MM-DD HH:mm');
    if (now.isAfter(selectedTime)) {
      // selected date is after now (should not happen since it's already blocked)
      return;
    }

    const callback = get(props,'modalConfig.callback', false);
    if (typeof(callback) === 'function') {
      callback({ date: date, time: time });
    }
    dispatch(modalQueue({ mode: 'delete' }));
  }

  function close() {
    if (inProgress) {
      return;
    }
    dispatch(modalQueue({ mode: 'delete' }));
  }

  function recalculatePrice() {
    let currentState = store.getState();
    setPrice('');
    const packages = get(currentState, 'data.ui.mainPage.purchaseProgress.morePackages', []).concat(get(currentState, 'data.ui.mainPage.purchaseProgress.firstPackage', []));
    const senderPlaceId = get(currentState, 'data.ui.mainPage.purchaseProgress.senderPlaceId', false);
    const recipientPlaceId = get(currentState, 'data.ui.mainPage.purchaseProgress.recipientPlaceId', false);

    const now = moment(moment().format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm'); // ignore seconds and stuff
    const selectedTime = moment(date.format('YYYY-MM-DD') + ' ' + time.value,'YYYY-MM-DD HH:mm');
    if (now.isAfter(selectedTime)) {
      // selected date is after now
      setError('Invalid time selected');
      setInProgress(false);
      return;
    }

    setInProgress(true);
    setError(false);

    (function(localRequestId) {
      let request = {
        recipientPlaceId: recipientPlaceId,
        senderPlaceId: senderPlaceId,
        recipientAddressExtraLines: '',
        senderAddressExtraLines: '',
        shippingDropoffTypeId: CONSTANTS.SHIPPING_DROPOFF_TYPE_ID_COURIER,
        time: time.value,
        date: date.format('YYYY-MM-DD'),
        packages: buildPackages({ packages }), // create new objects
        callback: function (result) {
          if (localRequestId != GLOBAL_REQUEST_ID || error) {
            // user tries too quickly / generated error last time
            return;
          }
          setInProgress(false);
          if (result.success) {
            dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress.pickupSavedRates', value: result }));
            const bestPriceResult = getBestPrice({ result: {...result, results: get(result,'results', []) } }, { shippingTypeId: get(props, 'selectedShippingOption.value', null) });
            if (get(bestPriceResult, 'option.price', false)) {
              setPrice('$' + get(bestPriceResult, 'option.price', '0'));
            }
            else {
              setError('No pickup available. please select a different time');
            }
          }
          else {
            showErrorMessage(result, dispatch);
            setError('Failed to calculate');
          }
        }
      };
      dispatch(api_getPrices(request));
    })(++GLOBAL_REQUEST_ID);
  }

  function getNearestValidTime() {
    const now = moment();
    const todayDayNumber = now.day() + 1;
    const todayPickupTimeRange = CONSTANTS.PICKUP_HOURS_RANGES[todayDayNumber];
    // will "jump" forward until we'll find nearest valid time
    let dynamicDayPickup = todayDayNumber;
    let dynamicPickupTimeRange = todayPickupTimeRange;
    let time = moment();

    try {
        time.set('hours', todayPickupTimeRange.min).set('minutes', 0).set('seconds', 0);
        while (now.isAfter(time)) {
            time.add('hours', 1);
            if (parseInt(time.format('H')) > parseInt(dynamicPickupTimeRange.max)) {
                // go to earliest pickup on next day
                dynamicDayPickup++;
                if (dynamicDayPickup === 8) {
                    dynamicDayPickup = 1;
                }
                dynamicPickupTimeRange = CONSTANTS.PICKUP_HOURS_RANGES[dynamicDayPickup];
                time.add('days', 1);
                time.set('hours', dynamicPickupTimeRange.min);
            }
        }
    }
    catch (err) {
        // nothing to do
    }

    return time;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    apiData_getPrices: state.data.api.getPrices,
    selectedShippingOption: state.data.ui.mainPage.purchaseProgress.selectedShippingOption
  }
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const PigeItChoosePickUpTimeStyle = styled.div`
  width: 100%;
  height: 100%;
  .bottom-banner{
    width: 100%;
    position: fixed;
    background: white;
    z-index: ${ZIndices.choosePickup};
    bottom: 0;
  }
  .input-container{
    max-width: 350px;
    margin: auto;
    margin-top: 36px;
    margin-bottom: 25px;
    .palce-holder{
      height: 40px;
    }
  }
  .error-container {
    color: ${Colors.red};
    text-align: center;
  }
  .best-price-ship-now-button{
      border:1.5px solid ${Colors.orange};
      outline:none;
      background-color:${Colors.white};
      border-radius:3px;
      cursor: pointer;
      padding:0;
      font-size:18px;
      width:300px;
      height: 58px;
      margin: auto;
      display: block;
      margin-top: 10px;
      margin-bottom: 10px;
      .ship-now{
        height:56px;
        width:220px;
        line-height:56px;
        color:${Colors.white};
        background-color:${Colors.orange};

      }
      .best-price{
        height:56px;
        width:130px;
        color:${Colors.orange};
        .best-price-price{
          font-size:27px;
          color: ${Colors.blue1};
        }
        .best-price-text{
          font-size:11px;
          margin-top:8px;
          color: ${Colors.blue1};
        }
      }
    }
  .background-container {
    height: 100%;
    width: 100%;
    background-color: #fff;
    margin: 0 auto;
    
  }
  .content-container {
    padding-top: 45px;
    padding-right: 65px;
    padding-left: 65px;
  }
  .modal-close-button {
    position: absolute;
    top: 21px;
    right: 26px;
  }
  .header-text {
    font-size: 36px;
    text-align: center;
  }
  @media only screen and (max-width: 992px) { 
    .input-container{
      margin-top: 136px;
    }
  }
`;

const PigeItChoosePickUpTime = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItChoosePickUpTimeComponent);

export default PigeItChoosePickUpTime;
