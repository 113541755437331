/* eslint default-case: off, eqeqeq: off, react-hooks/exhaustive-deps: off */
import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useTitle } from "hookrouter";
import { connect, useDispatch } from "react-redux";
import { Colors } from "../common/colors";
import IMAGES from "../common/images";
import CONSTANTS from "../common/constants";
import {
  navigateToPath,
  getBluesnapEncryptedFields,
  showErrorMessage,
  identifyCreditCardType,
  logEventForAnalytics,
} from "../common/functions";
import {
  genericSetValue,
  modalQueue,
  api_creditcard_getList,
  setupAnimatedLoader,
  api_creditcard_addCreditCard,
  verify_payment_msr,
  api_pay_msr,
  save_orders,
} from "../redux/actions";
import { get } from "lodash";
import { Button } from "@material-ui/core";
import PigeItSelect from "./PigeItSelect.component";
import PigeItInput from "./PigeItInput.component";
import PigeItCreditCardInput from "./PigeItCreditCardInput.component";
import PigeItCheckbox from "./PigeItCheckbox.component";
import moment from "moment";
import PigeItCouponInput from "./PigeItCouponInput.component";

const VALUES = CONSTANTS.MULTI_PACKAGES_VALUES;

function MultiplePackagesStage3Component(props) {
  const dispatch = useDispatch();
  const COMPANIES = [
    { text: "All", value: "all" },
    { text: "FedEx", value: "fedex" },
    { text: "UPS", value: "ups" },
  ];
  const SORT = {
    BEST_PRICE: "BEST_PRICE",
    FASTEST: "FASTEST",
    POPULAR: "POPULAR",
  };
  const options = get(props, "getPricesMulti.result.results", [])
    .map((comp) =>
      get(comp, "options", []).map((opt) => ({
        ...opt,
        company: get(comp, "company", ""),
      }))
    )
    .reduce((a, b) => [...a, ...b], []);
  const optionsRef = useRef();
  const paymentRef = useRef();
  const [stickyCss, setStickyCss] = useState("");
  const [sortMode, setSortMode] = useState(SORT.POPULAR);
  const [filterMode, setFilterMode] = useState({ text: "All", value: "all" });
  const [selectedOnTop, setSelectedOnTop] = useState(0);
  const returnedCompanies = COMPANIES.filter(
    (comp) =>
      comp.value === "all" ||
      options.some(
        (opt) => get(opt, "company", "").toLowerCase() === comp.value
      )
  );
  const [agreed, setAgreed] = useState(false);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    logEventForAnalytics({ event: "businesses-page-3" });
  }, []);

  useEffect(() => {
    if (
      !get(props, "orders", []).length ||
      !get(props, "getPricesMulti.result.results", []).length
    ) {
      navigateToPath("businesses/dashboard", true);
    }

    //scroll listener
    const linkedMethod = handleScrollEvent.bind(this);
    window.addEventListener("scroll", linkedMethod);
    return function () {
      window.removeEventListener("scroll", linkedMethod);
    };
  }, []);

  useEffect(() => {
    if (parseInt(get(props, "selectedShippingOption", 0)) !== 0) {
      setTimeout(() => {
        setSelectedOnTop(parseInt(get(props, "selectedShippingOption", 0)));
        window.scrollTo(0, 0);
        setStickyCss("");
      }, 500);
    }
  }, [props.selectedShippingOption]);

  const optionsList = options
    .filter(filterFunc)
    .sort(sortFunc(sortMode, selectedOnTop));
  const senderInfo = get(props, "senderInfo", {});
  const selectedPrice = get(
    options.find(
      (opt) =>
        parseInt(get(props, "selectedShippingOption", 0)) ===
        parseInt(get(opt, "shippingTypeId", ""))
    ),
    "price",
    "--.--"
  );
  let CardIcon = get(props, "paymentDetails.cardType", null)
    ? IMAGES[
    identifyCreditCardType(
      null,
      get(props, "paymentDetails.cardType", "").toLowerCase()
    )
    ]
    : null;
  const coupon_discount = get(props, 'coupon.amount', 0);

  return (
    <MultiplePackagesStage3Style>
      <div className="display-flex fjc-space-between stage-3-container">
        <div className="prices-container" ref={optionsRef}>
          {returnedCompanies.length > 2 && (
            <div className="company-container display-flex">
              <div className="title">Company</div>
              <PigeItSelect
                className="select"
                valueList={returnedCompanies}
                value={filterMode}
                onChange={(value) => {
                  setFilterMode(value);
                  resetSelection();
                }}
              />
            </div>
          )}
          <div className="filter-options display-flex fjc-space-between">
            <Button
              className={
                "options" +
                (sortMode === SORT.POPULAR ? " selected" : "") +
                " most-popular"
              }
              onClick={() => {
                setSortMode(SORT.POPULAR);
                resetSelection();
              }}
            >
              <div>
                <div className="title">
                  {props.mobile ? "Popular" : "Most Popular"}
                </div>
                <div className="price">
                  $
                  {get(
                    options.filter(filterFunc).sort(sortFunc(SORT.POPULAR)),
                    "[0].price",
                    0
                  )}
                </div>
              </div>
            </Button>
            <Button
              className={
                "options" +
                (sortMode === SORT.FASTEST ? " selected" : "") +
                " fastest"
              }
              onClick={() => {
                setSortMode(SORT.FASTEST);
                resetSelection();
              }}
            >
              <div>
                <div className="title">Fastest</div>
                <div className="price">
                  $
                  {get(
                    options.filter(filterFunc).sort(sortFunc(SORT.FASTEST)),
                    "[0].price",
                    0
                  )}
                </div>
              </div>
            </Button>
            <Button
              className={
                "options" +
                (sortMode === SORT.BEST_PRICE ? " selected" : "") +
                " best-price"
              }
              onClick={() => {
                setSortMode(SORT.BEST_PRICE);
                resetSelection();
              }}
            >
              <div>
                <div className="title">
                  {props.mobile ? "Cheapest" : "Best Price"}
                </div>
                <div className="price">
                  $
                  {get(
                    options.filter(filterFunc).sort(sortFunc(SORT.BEST_PRICE)),
                    "[0].price",
                    0
                  )}
                </div>
              </div>
            </Button>
          </div>
          <div className="prices-contianer">
            {optionsList.map((opt, i) => {
              return (
                <React.Fragment key={i}>
                  <hr className="line" />
                  <div
                    className={
                      "price-option display-flex fjc-space-between" +
                      (parseInt(get(props, "selectedShippingOption", 0)) ===
                        parseInt(get(opt, "shippingTypeId", ""))
                        ? " selected"
                        : "")
                    }
                  >
                    <div className="title flex32">{get(opt, "text", "")}</div>
                    <div className="company flex32 fjs-center">
                      {get(opt, "company", "") === "FEDEX" ? (
                        <IMAGES.fedex />
                      ) : (
                        <IMAGES.ups />
                      )}
                    </div>
                    <div className="display-flex fjc-flex-end flex32">
                      <div className="price">{`$${get(opt, "price", "")}`}</div>
                      <Button
                        className="select-price"
                        disabled={
                          parseInt(get(props, "selectedShippingOption", 0)) ===
                          parseInt(get(opt, "shippingTypeId", ""))
                        }
                        onClick={() =>
                          selectOption(
                            get(opt, "shippingTypeId", ""),
                            get(opt, "company", "")
                          )
                        }
                      >
                        {parseInt(get(props, "selectedShippingOption", 0)) !==
                          parseInt(get(opt, "shippingTypeId", ""))
                          ? "Select"
                          : "Selected"}
                      </Button>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <div className={"payment-container" + stickyCss} ref={paymentRef}>
          <div className="display-flex fjc-space-between">
            <label>Order Details</label>
            <Button
              className="edit"
              onClick={() => navigateToPath("businesses/dashboard", true)}
            >
              <span>Edit Packages</span>
              <IMAGES.edit />
            </Button>
          </div>
          <div className="details-info">
            <div className="text">
              {get(props, "orders", []).length +
                ", packages, " +
                (senderInfo.company
                  ? senderInfo.company + ", " + senderInfo.name
                  : senderInfo.name) +
                ", " +
                senderInfo.email +
                ", " +
                senderInfo.phone}
            </div>
            <div className="text">
              {senderInfo.addressText +
                (senderInfo.addressLine2
                  ? ", " + senderInfo.addressLine2
                  : senderInfo.addressLine2)}
            </div>
            {senderInfo.addressLine2 && (
              <div className="text">{senderInfo.addressLine2}</div>
            )}
          </div>
          <hr className="line" />
          <div className="display-flex fjc-space-between">
            {" "}
            <label>Payment Details</label>
            <Button
              onClick={() => onSelectCardFromList()}
              className="select-card"
            >
              Select Card
            </Button>
          </div>
          {!get(props, "paymentDetails.fromSavedCards", false) ? (
            <>
              <PigeItCreditCardInput
                error={errors.creditCard}
                className="cc-input"
                value={get(props, "paymentDetails.creditCard", "")}
                onChange={(card) => setPaymentField("creditCard", card.number)}
              />
              <div className="display-flex fjc-space-between date-ccv-container">
                <PigeItInput
                  error={errors.expiry}
                  label="Expiry"
                  className="input date"
                  value={formatCardDate(
                    get(props, "paymentDetails.expiry", "")
                  )}
                  onChange={(e) => setPaymentField("expiry", e.target.value)}
                />
                <div className="space" />
                <PigeItInput
                  error={errors.cvv}
                  label="CVV"
                  className="input ccv"
                  value={get(props, "paymentDetails.cvv", "")}
                  onChange={(e) =>
                    get(e, "target.value", "").length < 5
                      ? setPaymentField("cvv", e.target.value)
                      : null
                  }
                />
              </div>
              <Button
                style={{ visibility: canSaveCard() ? "visible" : "hidden" }}
                onClick={saveCard}
                className="add-to-cc"
              >
                <IMAGES.plusSmall />
                <span>Add to CC</span>
              </Button>
            </>
          ) : (
            <div className="saved-card-container">
              <div className="display-flex fjs-start">
                <div className="card-company">{CardIcon && <CardIcon />}</div>
                <div className="card-number">
                  {"  **** " + get(props, "paymentDetails.last4Digits", "")}
                </div>
              </div>
              <div className="card-month-year">
                {get(props, "paymentDetails.expiryMonth", "") +
                  "/" +
                  get(props, "paymentDetails.expiryYear", "")}
              </div>
              <div className="display-flex fjs-center">
                <Button
                  className="new-card"
                  onClick={() => setPaymentField("fromSavedCards", false)}
                >
                  Enter Different Card
                </Button>
              </div>
            </div>
          )}
          <div className="agree-container display-flex">
            <PigeItCheckbox
              value={agreed}
              labelText="I agree to ShipWinner"
              onClick={(val) => setAgreed(val)}
            />
            <a
              className="link"
              target="_blank"
              href="/information/terms-introduction"
            >
              Term & Conditions
            </a>
          </div>
          <div className="display-flex fjs-center coupon-container">
            <PigeItCouponInput msr />
          </div>
          <button
            onClick={confirm}
            disabled={selectedPrice === "--.--" || !agreed}
            className="confirm-button display-flex"
          >
            <div className="total">
              <div className="title">TOTAL</div>
              <div className='display-flex fjs-center fia-center' style={{ padding: '2px 17px' }}>
                {selectedPrice !== "--.--" && coupon_discount ? <div className="price-old">{"$" + selectedPrice}</div> : null}
                <div className="price">{"$" + (selectedPrice !== "--.--" && coupon_discount ? Math.max(1, selectedPrice - coupon_discount).toFixed(2) : selectedPrice)}</div>
              </div>
            </div>
            <div className="confirm">CONFIRM</div>
          </button>
        </div>
      </div>
    </MultiplePackagesStage3Style>
  );

  function resetSelection() {
    setSelectedOnTop(0);
    dispatch(
      genericSetValue({ path: "ui.business.selectedShippingOption", value: 0 })
    );
  }

  function confirm() {
    let senderInfo = get(props, "senderInfo", {});
    let paymentDetails = get(props, "paymentDetails", {});
    let err = {};
    if (!paymentDetails.fromSavedCards) {
      if (get(paymentDetails, "creditCard", "").length < 4) {
        err.creditCard = true;
      }
      if (get(paymentDetails, "cvv", "").length < 3) {
        err.cvv = true;
      }
      if (get(paymentDetails, "expiry", "").length !== 5) {
        err.expiry = true;
      }
    }
    setErrors(err);
    if (Object.keys(err).length) {
      return;
    }
    let expiryYear = paymentDetails.expiryYear || "";
    let expiryMonth = paymentDetails.expiryMonth || "";
    if (!paymentDetails.fromSavedCards) {
      let splittedExp = get(paymentDetails, "expiry", "").split("/");
      expiryYear = "20" + get(splittedExp, "[1]", "");
      expiryMonth = get(splittedExp, "[0]", "");
    }
    let cardEncrypted = getBluesnapEncryptedFields(
      get(paymentDetails, "cvv", ""),
      get(paymentDetails, "creditCard", "")
    );

    dispatch(
      setupAnimatedLoader({ isVisible: true, text: "Verifying payment..." })
    );
    dispatch(
      verify_payment_msr({
        couponCode: get(props, 'coupon.code'),
        paymentDetails: {
          paymentType: paymentDetails.fromSavedCards ? 2 : 1,
          existingPaymentLast4Digits: paymentDetails.last4Digits || "",
          existingPaymentCardType: paymentDetails.cardType || "",
          firstName: senderInfo.name || "",
          lastName: senderInfo.name || "",
          email: senderInfo.email || "",
          zip: senderInfo.postalCode || "",
          creditCardNumber: cardEncrypted.encyprtedCreditCard,
          creditCardSecurityCode: cardEncrypted.encyprtedCvv,
          creditCardExpiryYear: expiryYear,
          creditCardExpiryMonth: expiryMonth,
        },
        clientDetails: {
          name: senderInfo.name || "",
          company: senderInfo.company || "",
          phone: senderInfo.phone || "",
          email: senderInfo.email || "",
        },
        shippingDetails: {
          shippingCompanyId:
            CONSTANTS.COMPANY_TO_ID[
            get(props, "selectedCompany", "").toLowerCase()
            ],
          shippingTypeId: parseInt(get(props, "selectedShippingOption", "")),
        },
        list: get(props, "orders", []).map((pack, i) => ({
          shippingDropoffTypeId: 1,
          date: "",
          time: "",
          addressDataVersion: "plain",
          senderDetails: {
            name: senderInfo.name || "",
            company: senderInfo.company || "",
            phone: senderInfo.phone || "",
            email: senderInfo.email || "",
            notification: false,
          },
          senderAddressDetails: {
            googlePlaceId: "",
            addressExtraLines: senderInfo.addressLine2
              ? [senderInfo.addressLine2]
              : [],
            addressTypeId: senderInfo.residential ? 2 : 1,
            city: senderInfo.city || "",
            stateOrProvinceCode: senderInfo.state || "",
            postalCode: senderInfo.postalCode || "",
            addressLines: senderInfo.street ? [senderInfo.street] : [],
          },
          recipientDetails: {
            name: pack[VALUES.name] || "",
            company: pack[VALUES.company] || "",
            phone: pack[VALUES.phone] || "",
            email: pack[VALUES.email] || "",
            notification: false,
          },
          recipientAddressDetails: {
            googlePlaceId: "",
            addressExtraLines: pack[VALUES.addressLine2]
              ? [pack[VALUES.addressLine2]]
              : [],
            addressTypeId:
              String(pack[VALUES.residential]).toLowerCase() === "y" ? 2 : 1,
            city: pack[VALUES.city] || "",
            stateOrProvinceCode: pack[VALUES.state] || "",
            postalCode: pack[VALUES.postalCode] || "",
            addressLines: [pack[VALUES.street] || ""],
          },
          packages: [
            {
              packagingType: 1,
              weight: parseFloat(pack[VALUES.weight]) || 0,
              weightUnits: "LBS",
              sizeUnits: "IN",
              sizeHeight: parseFloat(pack[VALUES.height]) || 0,
              sizeWidth: parseFloat(pack[VALUES.width]) || 0,
              sizeLength: parseFloat(pack[VALUES.length]) || 0,
              insuredValueCurrency:
                pack[VALUES.insuranceCurrency] === "DOLLAR" ? 2 : 1,
              insuredValueAmount: parseFloat(pack[VALUES.insuranceValue]) || 0,
              contentDescription: pack[VALUES.description] || "",
            },
          ],
        })),
        callback: (result) => {
          if (result.success) {
            dispatch(
              setupAnimatedLoader({ isVisible: true, text: "Paying..." })
            );
            dispatch(
              api_pay_msr({
                transactionKey: get(result, "transactionKey", ""),
                callback: (result) => {
                  dispatch(setupAnimatedLoader({ isVisible: false }));
                  if (result.success) {
                    localStorage.removeItem("csv-orders");
                    navigateToPath("businesses/stage-4", true);
                  } else {
                    showErrorMessage(result, dispatch);
                  }
                },
              })
            );
          } else {
            dispatch(setupAnimatedLoader({ isVisible: false }));
            showErrorMessage(result, dispatch);
          }
        },
      })
    );
  }

  function handleScrollEvent() {
    if (props.mobile) {
      return;
    }
    let optionsTop = get(
      get(optionsRef, "current", () => { }).getClientRects(),
      "[0].top",
      0
    );
    let optionsBottom = get(
      get(optionsRef, "current", () => { }).getClientRects(),
      "[0].bottom",
      0
    );
    let paymentTop = get(
      get(paymentRef, "current", () => { }).getClientRects(),
      "[0].top",
      0
    );
    let paymentBottom = get(
      get(paymentRef, "current", () => { }).getClientRects(),
      "[0].bottom",
      0
    );
    if (
      (optionsTop <= 69 && parseInt(optionsBottom) > parseInt(paymentBottom)) ||
      (paymentTop >= 69 && parseInt(optionsTop) < parseInt(paymentTop))
    ) {
      setStickyCss(" stick-top");
    } else if (parseInt(optionsBottom) <= parseInt(paymentBottom)) {
      setStickyCss(" stick-bottom");
    } else {
      setStickyCss("");
    }
  }

  function onSelectCardFromList() {
    const modalConfig = JSON.parse(
      JSON.stringify(CONSTANTS.MODALS_CONFIG.MY_ACCOUNT)
    );
    modalConfig.initialTab = CONSTANTS.MY_ACCOUNT_CREDIT_CARDS_TAB;
    modalConfig.cardClickable = true;
    modalConfig.callback = function (item) {
      dispatch(
        genericSetValue({
          path: "ui.business.paymentDetails",
          value: {
            ...get(props, "paymentDetails", {}),
            fromSavedCards: true,
            cardType: item.cardType,
            last4Digits: item.last4Digits,
            expiryMonth: item.expiryMonth,
            expiryYear: item.expiryYear,
          },
        })
      );
    };
    window.scrollTo(0, 0);
    dispatch(modalQueue({ mode: "insert", modalConfig: modalConfig }));
  }

  function canSaveCard() {
    return (
      get(props, "paymentDetails.creditCard", "") !== "" &&
      get(props, "paymentDetails.cvv", "") !== "" &&
      get(props, "paymentDetails.expiry", "") !== ""
    );
  }

  function saveCard() {
    let cardNumber = get(props, "paymentDetails.creditCard", "");
    let cvv = get(props, "paymentDetails.cvv", "");
    let expiry = get(props, "paymentDetails.expiry", "");
    let name = get(props, "senderInfo.name", "");

    name = name.split(" ");
    let cardEncrypted = { encyprtedCreditCard: "", encyprtedCvv: "" };
    if (cvv && cardNumber) {
      cardEncrypted = getBluesnapEncryptedFields(cvv, cardNumber);
    }

    let request = {
      creditCardExpiryMonth: get(expiry.split("/"), "[0]", ""),
      creditCardExpiryYear: "20" + get(expiry.split("/"), "[1]", ""),
      creditCardNumber: cardEncrypted.encyprtedCreditCard,
      creditCardSecurityCode: cardEncrypted.encyprtedCvv,
      email: get(props, "senderInfo.email", ""),
      firstName: get(name, "[0]", ""),
      lastName: get(name, "[1]", ""),
      zip: get(props, "senderInfo.postalCode", ""),
      callback: function (result) {
        dispatch(setupAnimatedLoader({ isVisible: false }));
        if (result.success) {
          dispatch(api_creditcard_getList());
        } else {
          showErrorMessage(result, dispatch);
        }
      },
    };
    let modalConfig = {
      ...CONSTANTS.MODALS_CONFIG.MESSAGE,
      buttons: {
        ok: { visible: true, text: "OK" },
        cancel: { visible: true, text: "CANCEL" },
      },
      message: "Credit card will be saved",
    };
    modalConfig.callback = function (res) {
      if (res.confirm) {
        dispatch(
          setupAnimatedLoader({
            isVisible: true,
            text: "Saving Credit Card...",
          })
        );
        dispatch(api_creditcard_addCreditCard(request));
      }
    };
    dispatch(modalQueue({ mode: "insert", modalConfig: modalConfig }));
  }

  function formatCardDate(input) {
    if (input.length < 3 && isNaN(input)) {
      return "";
    } else if (input.length == 2 && !isNaN(input)) {
      return input + "/";
    } else if (input.length > 3 && isNaN(input.substring(3))) {
      return input.substring(0, 3);
    } else if (input.length > 5) {
      return input.substring(0, 5);
    } else {
      return input;
    }
  }

  function selectOption(option, company) {
    dispatch(
      genericSetValue({
        path: "ui.business.selectedShippingOption",
        value: option,
      })
    );
    dispatch(
      genericSetValue({ path: "ui.business.selectedCompany", value: company })
    );
  }

  function setPaymentField(field, val) {
    dispatch(
      genericSetValue({
        path: "ui.business.paymentDetails",
        value: { ...get(props, "paymentDetails", {}), [field]: val },
      })
    );
  }

  function filterFunc(op) {
    return (
      get(filterMode, "value", "") === "all" ||
      get(op, "company", "").toLowerCase() === get(filterMode, "value", "")
    );
  }

  function sortFunc(mode, selectedFirst) {
    const isUnknown = (str) =>
      String(str).toLowerCase().indexOf("unknown") !== -1;
    const isGround = (option) =>
      get(option, "text").toLowerCase().indexOf("ground") !== -1;
    const getValue = (field, option) =>
      parseFloat(isUnknown(field) || isGround(option) ? Infinity : field);
    return (a, b) => {
      if (
        parseInt(get(a, "shippingTypeId", 0)) === selectedFirst &&
        selectedFirst !== 0
      ) {
        return -1;
      }
      if (
        parseInt(get(b, "shippingTypeId", 0)) === selectedFirst &&
        selectedFirst !== 0
      ) {
        return 1;
      }
      if (mode === SORT.POPULAR) {
        return parseFloat(get(a, "price", 0)) - parseFloat(get(b, "price", 0));
      } else if (mode === SORT.BEST_PRICE) {
        return parseFloat(get(a, "price", 0)) - parseFloat(get(b, "price", 0));
      } else if (mode === SORT.FASTEST) {
        let daysA = getValue(get(a, "businessDays", 0), a);
        let hoursA = getValue(get(get(a, "time", "").split(":"), "[0]", 0), a);
        let minutesA = getValue(
          get(get(a, "time", "").split(":"), "[1]", 0),
          a
        );
        let priceA = parseFloat(get(a, "price", 0));

        let daysB = getValue(get(b, "businessDays", 0), b);
        let hoursB = getValue(get(get(b, "time", "").split(":"), "[0]", 0), b);
        let minutesB = getValue(
          get(get(b, "time", "").split(":"), "[1]", 0),
          b
        );
        let priceB = parseFloat(get(b, "price", 0));

        if (daysA !== daysB) {
          return parseFloat(daysA) - parseFloat(daysB);
        }
        if (hoursA !== hoursB) {
          return parseFloat(hoursA) - parseFloat(hoursB);
        }
        if (minutesA !== minutesB) {
          return parseFloat(minutesA) - parseFloat(minutesB);
        }
        return parseFloat(priceA) - parseFloat(priceB);
      }
    };
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    mobile: state.data.displayMode === "MOBILE",
    orders: state.data.ui.business.orders,
    senderInfo: state.data.ui.business.senderInfo,
    paymentDetails: state.data.ui.business.paymentDetails,
    selectedShippingOption: state.data.ui.business.selectedShippingOption,
    coupon: state.data.ui.business.coupon,
    selectedCompany: state.data.ui.business.selectedCompany,
    getPricesMulti: state.data.api.getPricesMulti,
    apiData_getInitialData: state.data.api.getInitialData,
    paymentSources_getList: state.data.api.paymentSources_getList,
    mobile: state.data.displayMode === "MOBILE",
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

const MultiplePackagesStage3 = connect(
  mapStateToProps,
  mapDispatchToProps
)(MultiplePackagesStage3Component);

const MultiplePackagesStage3Style = styled.div`
  padding: 40px;
  color: #585858;
  .coupon-container{
    margin:20px 0;
  }
  .new-card {
    color: ${Colors.blue1};
    font-size: 18px;
    margin: 11px;
  }
  .card-month-year {
    margin-left: 10px;
  }
  .card-company {
    svg {
      height: 38px;
    }
  }
  .card-number {
    line-height: 42px;
  }
  .select-card {
    margin-top: -7px;
    margin-bottom: 3px;
    color: ${Colors.blue1};
  }
  .details-info {
    min-height: 134px;
  }
  .add-to-cc {
    color: ${Colors.blue1};
    span {
      margin-left: 10px;
    }
    svg {
      path {
        fill: ${Colors.blue1};
      }
    }
  }
  .check {
    width: 33px;
    height: 33px;
    margin: 0 24px;
    path {
      fill: #00dc00;
    }
  }
  .edit {
    transform: translate(10px, -10px);
    span {
      display: inline-block;
      margin-right: 8px;
      color: ${Colors.blue1};
    }
  }
  .flex32 {
    flex: 0 0 32%;
  }
  .title {
    font-size: 18px;
    font-family: museo_sans500;
  }
  .text {
    font-family: museo_sans300;
    line-height: 23px;
    font-family: museo_sans300;
    line-height: 23px;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .stage-1-completed {
    border-radius: 3px;
    padding: 10px;
    line-height: 30px;
    background-color: #eff8fa;
    box-shadow: 1px 1px 5px #c9d2d4;
    .edit {
      span {
        display: inline-block;
        margin-right: 7px;
        font-size: 16px;
        line-height: 17px;
        color: ${Colors.blue1};
      }
    }
  }
  .prices-container {
    padding: 11px;
    margin-top: 20px;
    border-radius: 3px;
    background-color: #eff8fa;
    box-shadow: 1px 1px 5px #c9d2d4;
    width: calc(100% - 382px);
  }
  .payment-container {
    height: 568px;
    padding: 11px;
    margin-top: 20px;
    border-radius: 3px;
    background-color: #eff8fa;
    box-shadow: 1px 1px 5px #c9d2d4;
    width: 320px;
    .line {
      margin: 13px 0;
    }
    &.stick-top {
      right: 40px;
      top: 47px;
      position: fixed;
    }
    &.stick-bottom {
      position: unset;
      align-self: flex-end;
    }
  }
  .agree-container {
    width: 371px;
    margin: auto;
    .text {
      display: block;
      margin: 11px 0;
      margin-right: 8px;
      font-size: 16px;
      font-family: "museo_sans500";
    }
    .link {
      display: block;
      margin: 13px 0;
      text-decoration: none;
      color: ${Colors.blue1};
      font-size: 15px;
      transform: translate(-9px, 0px);
    }
  }
  .confirm-button {
    margin: auto;
    border: solid 2px ${Colors.orange};
    border-radius: 3px;
    background: none;
    padding: 0;
    margin-bottom: 40px;
    outline: none;
    cursor: pointer;
    &:hover {
      border: solid 2px #e6a330;
      .confirm {
        background-color: #e6a330;
      }
    }
    &:disabled {
      border: solid 2px ${Colors.lightGray5};
      .confirm {
        background-color: ${Colors.lightGray5};
      }
    }
    .title {
      font-size: 12px;
      padding: 0px 8px;
      padding-top: 10px;
      color: ${Colors.blue1};
    }
    .price {
      font-size: 29px;
      color: ${Colors.blue1};
    }
    .price-old{
      color: #b0bcc2;
      text-decoration: line-through;
    }
    .confirm {
      font-size: 26px;
      color: ${Colors.white};
      background-color: ${Colors.orange};
      padding: 19px 13px;
    }
  }
  .credit-card-input {
    background-color: white;
  }
  .date-ccv-container {
    margin: 13px 0px;
    .space {
      width: 10px;
    }
  }
  .company-container {
    width: 302px;
    padding: 10px;
    .title {
      line-height: 33px;
    }
    .select {
      margin: 0;
      margin-left: 15px;
      background-color: ${Colors.white};
      border-radius: 4px;
    }
  }
  .line {
    border: none;
    border-bottom: solid 1px #d8d8d8;
    margin: 3px 0;
  }
  .filter-options {
    margin-bottom: 25px;
    margin-top: 10px;
  }
  .options {
    .title {
      height: 23px;
    }
    &.selected {
      background-color: ${Colors.blue2};
      color: white;
    }
    flex: 0 0 32%;
    height: 70px;
    font-size: 18px;
    font-family: museo_sans500;
    font-size: 22px;
    background-color: #ffffff;
    box-shadow: 1px 1px 6px #cacaca;
  }
  .price-option {
    padding: 10px;
    border-radius: 3px;
    .title {
      line-height: 33px;
      min-width: 300px;
    }
    .price {
      font-size: 25px;
      color: #585858;
      font-family: museo_sans500;
      line-height: 37px;
      margin-right: 20px;
      min-width: 80px;
    }
    &.selected {
      box-shadow: 1px 1px 6px #cacaca;
      margin: 14px 0px;
      background-color: white;
      .title,
      .price {
        font-family: museo_sans700;
      }
    }
    .select-price {
      &:hover {
        background-color: #e6a330;
      }
      &:disabled {
        background-color: ${Colors.blue2};
        box-shadow: none;
      }
      font-size: 21px;
      font-family: museo_sans500;
      background-color: ${Colors.orange};
      color: ${Colors.white};
      box-shadow: 2px 2px 2px #d0bc97;
      span {
        line-height: 24px;
      }
    }
    .company {
      text-align: center;
      padding-top: 5px;
      svg {
        width: 80px;
        height: 25px;
        path {
          fill: ${Colors.blue2};
        }
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    .flex32 {
      flex: unset;
    }
    .price-option {
      .title {
        min-width: 241px;
        font-size: 16px;
      }
      .price {
        font-size: 19px;
        min-width: 61px;
      }
      .select-price {
        font-size: 18px;
      }
      .company {
        svg {
          width: 55px;
          height: 25px;
        }
      }
    }
  }
  @media only screen and (max-width: 992px) {
    padding: 10px;
    .stage-3-container {
      flex-direction: column;
    }
    .prices-container {
      width: auto;
    }
    .price-option {
      flex-direction: column;
    }
    .payment-container {
      width: auto;
    }
  }
`;

export default MultiplePackagesStage3;
