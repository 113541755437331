import React, { useRef, useState, useMemo } from "react";
import styled from "styled-components";
import { connect, useDispatch } from "react-redux";
import { modalQueue, api_login, api_getInitialData } from "../redux/actions";
import { Button, TextField, Link } from "@material-ui/core";
import { Colors } from "../common/colors";
import CONSTANTS from "../common/constants";
import { get } from "lodash";
import PigeItCheckbox from "./PigeItCheckbox.component";
import Loader from "./Loader.component";
import IMAGES from "../common/images";
import {
  identifyCreditCardType,
  convertPackageIdtoPackage,
} from "../common/functions";
import moment from "moment";
import PigeitTable from "./PigeitTable.component";

function PigeItShipmentInfoMsrComponent(props) {
  const dispatch = useDispatch();
  const packages = get(props, "modalConfig.shipment", null);
  var cardType = get(packages, "paymentDetails.cardType", null);
  let CardIcon = cardType
    ? IMAGES[identifyCreditCardType(null, cardType.toLowerCase())]
    : null;
  let headers = [
    { name: "Label", width: "80px" },
    { name: "Tracking", width: "180px" },
    { name: "Recipient name", width: "140px" },
    { name: "Recipient email", width: "130px" },
    { name: "Recipient phone", width: "130px" },
    { name: "Recipient company", width: "130px" },
    { name: "Recipient Address", width: "210px" },
    { name: "Recipient Line 2", width: "90px" },
    { name: "Dropoff/Pickup", width: "80px" },
    { name: "Weight", width: "60px" },
    { name: "Height", width: "60px" },
    { name: "Width", width: "60px" },
    { name: "Length", width: "60px" },
    { name: "Content", width: "90px" },
    { name: "Value", width: "60px" },
    { name: "Currency", width: "80px" },
  ];

  let data = get(packages, "list", []).map((pack, i) => {
    const trackingNumber = get(
      packages,
      "shippingDetails[" + i + "].packageTrackingNumber",
      ""
    );
    const labelLink =
      CONSTANTS.API_ROOT +
      `api/get-shipment-label-as-file-msr/?transactionId=${get(
        packages,
        "transactionId",
        ""
      )}&packageTrackingNumber=${trackingNumber}`;

    return {
      error: false,
      row: [
        {
          type: "button",
          value: "Download",
          onClick: () => window.open(labelLink, "_blank"),
        },
        {
          type: "link",
          value: trackingNumber,
          href: getTrackingNumber(trackingNumber),
        },
        get(pack, "recipientName", ""),
        get(pack, "recipientEmail", ""),
        get(pack, "recipientPhone", ""),
        get(pack, "recipientCompany", ""),
        get(pack, "recipientAddress", ""),
        get(pack, "shipperAddressExtraLines", ""),
        get(pack, "shippingDropoffTypeId", "") === 2 ? "pickup" : "dropoff",
        get(pack, "packagesDetails[0].weight", ""),
        get(pack, "packagesDetails[0].sizeHeight", ""),
        get(pack, "packagesDetails[0].sizeWidth", ""),
        get(pack, "packagesDetails[0].sizeLength", ""),
        get(pack, "packagesDetails[0].contentDescription", ""),
        get(pack, "packagesDetails[0].insuredValueAmount", ""),
        get(pack, "packagesDetails[0].insuredValueCurrency", ""),
      ],
    };
  });

  console.log(packages);
  return (
    <PigeItShipmentInfoMsrStyle className="oginl-container">
      <div className="background-container">
        <div className="content-container">
          <Button className="modal-close-button" onClick={close}>
            <IMAGES.close />
          </Button>

          <div className="header-text">Shipment Info</div>
          <div className="title-text">
            Total Price &nbsp;
            {"$" + get(packages, "paymentDetails.amount", "0")}
          </div>
          <div className="title-text sub">
            Create Time: &nbsp;
            {moment(get(packages, "createTime", "")).format("MM/DD/YYYY HH:mm")}
          </div>

          <hr className="seperator" />
          <div className="main-container display-flex fjc-space-between">
            <div className="info-container">
              <div className="info-title">Sender Details</div>
              <div className="info-text">
                {get(packages, "clientDetails.name", "") +
                  ", " +
                  get(packages, "clientDetails.company", "")}
              </div>
              <div className="info-text">
                {get(packages, "clientDetails.phone", "")}
              </div>
              <div className="info-text">
                {get(packages, "clientDetails.email", "")}
              </div>
              <div className="info-text">
                {get(packages, "list[0].shipperAddress", "")}
              </div>
              <div className="info-text">
                {get(packages, "list[0].shipperAddressExtraLines", "")}
              </div>
            </div>
            <div className="info-container">
              <div className="info-title card">Billing Info</div>
              <div className="display-flex row">
                <div className="info-icon card">{CardIcon && <CardIcon />}</div>
                <div className="info-text card">
                  {get(packages, "paymentDetails.last4Digits", "")}
                </div>
              </div>
              <div className="info-text">
                {get(packages, "paymentDetails.email", "")}
              </div>
            </div>
            <div className="info-container">
              <div className="info-title">Service</div>
              <div className="display-flex row">
                <div className="info-text">Shipped with</div>
                <div className="info-icon company">
                  {get(packages, "shippingCompanyId", "1") === "1" ? (
                    <IMAGES.fedex />
                  ) : (
                    <IMAGES.ups />
                  )}
                </div>
              </div>
              <div className="info-text">
                {get(packages, "shippingDropoffTypeId", "1") === "1"
                  ? "Drop off"
                  : "Pick up"}
              </div>
            </div>
          </div>
          <hr className="seperator" />
          <div className="title-text">Packages Information</div>
          <div className="packages-scroll">
            <PigeitTable
              className="packages-table"
              headers={headers}
              data={data}
              defaultSort={{
                column: 0,
                func: (a, b) => parseInt(a) - parseInt(b),
              }}
            />
          </div>
          <button
            className="get-all-labels"
            onClick={() =>
              window.open(
                CONSTANTS.API_ROOT +
                  `api/get-shipment-label-as-file-msr/?transactionId=${get(
                    packages,
                    "transactionId"
                  )}`,
                "_blank"
              )
            }
          >
            Print Labels
          </button>
        </div>
      </div>
    </PigeItShipmentInfoMsrStyle>
  );

  function getTrackingNumber(packageTrackingNumber) {
    if (packageTrackingNumber === "") {
      return null;
    }
    if (isNaN(packageTrackingNumber)) {
      return (
        "https://wwwapps.ups.com/tracking/tracking.cgi?tracknum=" +
        packageTrackingNumber
      );
    } else {
      return (
        "https://www.fedex.com/apps/fedextrack/?trackingnumber=" +
        packageTrackingNumber
      );
    }
  }

  function close() {
    dispatch(modalQueue({ mode: "delete" }));
  }
}
const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

const PigeItShipmentInfoMsrStyle = styled.div`
  width: 100%;
  height: 100%;
  .packages-table {
    button {
      padding: 4px 5px;
      font-size: 14px;
      background: #186da7;
      border: none;
      border-radius: 3px;
      color: white;
      cursor: pointer;
      outline: none;
    }
    margin: auto;
    text-align: -webkit-center;
    overflow-x: scroll;
    border: solid 1px lightgrey;
    padding-bottom: 10px;
    border-radius: 8px;
    background-color: white;
    table {
      width: max-content;
      margin: 0;
    }
    &::-webkit-scrollbar {
      width: 10px;
      background-color: lightgrey;
      margin-right: 1px;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      width: 10px;
      background-color: ${Colors.blue2};
      border-radius: 20px;
    }
  }
  .get-all-labels {
    display: block;
    margin: auto;
    margin-bottom: 10px;
    margin-top: 36px;
    padding: 14px 20px;
    font-size: 20px;
    background: ${Colors.orange};
    border: none;
    border-radius: 3px;
    color: white;
    cursor: pointer;
    outline: none;
    &:hover {
      background-color: ${Colors.orangeHover};
    }
  }
  .background-container {
    height: 100%;
    width: 100%;
    background-color: #fff;
    margin: 0 auto;
    overflow: auto;
  }
  .content-container {
    padding-top: 45px;
    padding-right: 65px;
    padding-left: 65px;
  }
  .modal-close-button {
    position: absolute;
    top: 21px;
    right: 26px;
  }
  .header-text {
    font-size: 36px;
    text-align: center;
  }
  .title-text {
    margin: 15px;
    font-size: 18px;
    text-align: center;
    color: ${Colors.lightGray6};
    &.sub {
      font-size: 14px;
    }
  }
  .seperator {
    border: none;
    border-bottom: solid 1px lightgray;
  }
  .packages-scroll {
    max-height: 330px;
  }
  .package-container {
    border: solid 1px #ece9e9;
    font-family: museo_sans300;
    padding: 10px;
    border-radius: 3px;
    margin: 5px;
    .package-text {
      &.id-tag {
        font-family: museo_sans500;
      }
      margin-left: 20px;
    }
  }
  .info-container {
    padding: 10px;
    font-size: 14px;
    font-family: museo_sans300;
    .info-title {
      font-family: museo_sans500;
      font-size: 16px;
      color: ${Colors.lightGray6};
      padding-bottom: 5px;
      &.card {
        padding-bottom: 0;
      }
    }
    .info-text {
      &.card {
        margin-top: 12px;
      }
    }
    .info-icon {
      &.card {
        margin-left: -6px;
        svg {
          height: 37px;
        }
      }
      &.company {
        margin-left: 10px;
        margin-top: -6px;
        svg {
          height: 20px;
          path {
            fill: ${Colors.lightGray6};
          }
        }
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .display-flex {
      flex-flow: column nowrap;
      &.row {
        flex-direction: row;
      }
    }
    .content-container {
      padding: 51px 32px;
    }
    .package-container {
      padding: 12px 0px;
      .package-text {
        margin-left: 10px;
      }
    }
  }
`;

const PigeItShipmentInfoMsr = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItShipmentInfoMsrComponent);

export default PigeItShipmentInfoMsr;
