export const Colors = {
  white: '#fff',
  lightGray1: '#9daab1',
  lightGray2: '#70777d',
  lightGray3: '#ededed',
  lightGray4: '#B0BCC2',
  lightGray5: '#c8d9e2',
  lightGray6: '#62686D',
  darkGray: '#575757',
  lightBlue: '#eaf5f8',
  darkBlue1: '#0c5e96',
  darkBlue2: '#186da7',
  blue1: '#2699fb',
  blue2: '#226d9e',
  blue3: '#048bc4',
  orange: '#fcb02e',
  orangeHover: '#e6a330',
  black: '#323232',
  mediumBlack: '#3a454e',
  panelBackground: '#F4F8FB',
  red: '#ff7285',
  error: '#ff5900',
};
