/* eslint default-case: off, eqeqeq: off, react-hooks/exhaustive-deps: off */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useInterceptor, useTitle } from "hookrouter";
import { connect, useDispatch } from "react-redux";
import Navigation from "./Navigation.component";
import { Colors } from "../common/colors";
import MainPageFooter from "./MainPageFooter.component";
import IMAGES from "../common/images";
import CONSTANTS from "../common/constants";
import { setHeadMetaDescription, navigateToPath, logEventForAnalytics } from "../common/functions";
import {
  genericSetValue,
  modalQueue,
  api_get_prices_csv,
  setupAnimatedLoader,
  save_orders,
} from "../redux/actions";
import { get } from "lodash";
import PigeitTable from "./PigeitTable.component";
import Papa from "papaparse";
import { Button } from "@material-ui/core";
import PigeItSelect from "./PigeItSelect.component";
import PigeItInput from "./PigeItInput.component";
import PigeItCreditCardInput from "./PigeItCreditCardInput.component";
import PigeItInputAddress from "./PigeItInputAddress.component";
import PigeItCheckbox from "./PigeItCheckbox.component";
import moment from "moment";

const VALUES = CONSTANTS.MULTI_PACKAGES_VALUES;

function MultiplePackagesStage4Component({
  orders,
  mobile,
  trackingNumbers,
  transactionId,
  paymentDetails,
  senderInfo,
  shippingTypeId,
  price,
  secretKey,
  shippingCompanyId,
  success,
}) {
  const dispatch = useDispatch();
  useInterceptor((currentPath, nextPath) => {
    dispatch(save_orders([]));
    dispatch(genericSetValue({ path: 'ui.business.coupon', value: { } }));
    return nextPath;
  });

  useEffect(() => {
    logEventForAnalytics({ event: "businesses-page-4" });
  }, []);

  useEffect(() => {
    if (!success) {
      navigateToPath("businesses", true);
    }
  });

  let headers = [
    { name: "Label", width: "80px" },
    { name: "Tracking", width: "180px" },
    { name: "Recipient name", width: "140px" },
    { name: "Recipient email", width: "130px" },
    { name: "Recipient phone", width: "130px" },
    { name: "Recipient company", width: "130px" },
    { name: "Recipient Address", width: "210px" },
    { name: "Recipient Line 2", width: "90px" },
    { name: "Recipient Residental", width: "65px" },
    { name: "Dropoff/Pickup", width: "80px" },
    {
      name: "Pickup Date",
      width: "110px",
      sortFunc: (a, b) => new Date(b).getTime() - new Date(a).getTime(),
    },
    { name: "Pickup Time", width: "80px" },
    { name: "Weight", width: "60px" },
    { name: "Height", width: "60px" },
    { name: "Width", width: "60px" },
    { name: "Length", width: "60px" },
    { name: "Content", width: "90px" },
    { name: "Value", width: "60px" },
    { name: "Currency", width: "80px" },
  ];

  let data = (orders || []).map((pack, i) => {
    const isPickup = String(pack[VALUES.pickup]).toLowerCase() === "pickup";
    const trackingNumber = get(
      trackingNumbers,
      "[" + i + "].packagesTrackingNumbers[0]",
      ""
    );
    const labelLink =
      CONSTANTS.API_ROOT +
      `api/get-shipment-label-as-file-msr/?transactionId=${transactionId}&packageTrackingNumber=${trackingNumber}&secretKey=` +
      secretKey;

    return {
      error: false,
      row: [
        {
          type: "button",
          value: "Download",
          onClick: () => window.open(labelLink, "_blank"),
        },
        {
          type: "link",
          value: trackingNumber,
          href: getTrackingNumber(trackingNumber),
        },
        pack[VALUES.name],
        pack[VALUES.email],
        pack[VALUES.phone],
        pack[VALUES.company],
        pack[VALUES.street] +
          ", " +
          pack[VALUES.city] +
          ", " +
          pack[VALUES.state] +
          ", " +
          pack[VALUES.postalCode],
        pack[VALUES.addressLine2],
        {
          type: "icon",
          value: String(pack[VALUES.residential]).toLowerCase() === "y" ? 1 : 3,
        },
        isPickup ? "pickup" : "dropoff",
        isPickup
          ? moment([
              parseInt(pack[VALUES.year]),
              parseInt(pack[VALUES.month]) - 1,
              parseInt(pack[VALUES.day]),
            ]).format("YYYY/MM/DD")
          : "",
        isPickup ? pack[VALUES.time] : "",
        pack[VALUES.weight],
        pack[VALUES.height],
        pack[VALUES.width],
        pack[VALUES.length],
        pack[VALUES.description],
        pack[VALUES.insuranceValue],
        pack[VALUES.insuranceCurrency],
      ],
    };
  });

  let Icon = null;
  Icon = IMAGES[get(paymentDetails, "cardType").toLowerCase()];

  return (
    <MultiplePackagesStage4Style>
      <div className="thank-you-text">
        Thank you for shipping with ShipWinner!
      </div>
      <div className="send-email-text">
        Label will be sent to <span>{get(senderInfo, "email", "")}</span>
      </div>
      <div className="main-container">
        <div className="upper-data-container">
          <div className="data-container">
            <p className="label">Payment</p>
            <div className="payment-container">
              <span>{get(paymentDetails, "last4Digits", "")}</span>
              {Icon ? <Icon /> : null}
            </div>
          </div>
          <div className="data-container">
            <p className="label">Shipping Options</p>
            <div className="sub-label company">
              <span>You ship with</span>
              {parseInt(shippingCompanyId) === 1 ? (
                <IMAGES.fedex />
              ) : (
                <IMAGES.ups />
              )}
              <span>{(orders || []).length} packages</span>
            </div>
          </div>
          <div className="data-container">
            <p className="label">Price</p>
            <div className="sub-label">
              <span>{price + "$"}</span>
            </div>
          </div>
        </div>
        <PigeitTable
          className="packages-table"
          headers={headers}
          data={data}
          defaultSort={{ column: 0, func: (a, b) => parseInt(a) - parseInt(b) }}
        />
        <button
          className="get-all-labels"
          onClick={() =>
            window.open(
              CONSTANTS.API_ROOT +
                `api/get-shipment-label-as-file-msr/?transactionId=${transactionId}&secretKey=` +
                secretKey,
              "_blank"
            )
          }
        >
          Download All Labels
        </button>
        <button
          className="new-shipping-button"
          onClick={() => {
            navigateToPath("businesses/dashboard");
          }}
        >
          NEW SHIPPING
        </button>
      </div>
    </MultiplePackagesStage4Style>
  );
  function getTrackingNumber(trackingNumber) {
    if (trackingNumber === "") {
      return null;
    }
    if (isNaN(trackingNumber)) {
      return (
        "https://wwwapps.ups.com/tracking/tracking.cgi?tracknum=" +
        trackingNumber
      );
    } else {
      return (
        "https://www.fedex.com/apps/fedextrack/?trackingnumber=" +
        trackingNumber
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    mobile: state.data.displayMode === "MOBILE",
    orders: get(state, "data.ui.business.orders", []),
    price: get(state, "data.api.verifyPaymentMsr.result.price", 0),
    shippingTypeId: get(
      state,
      "data.api.verifyPaymentMsr.result.shippingTypeId",
      -1
    ),
    shippingCompanyId: get(
      state,
      "data.api.verifyPaymentMsr.request.shippingDetails.shippingCompanyId",
      -1
    ),
    senderInfo: get(state, "data.ui.business.senderInfo", {}),
    paymentDetails: get(state, "data.ui.business.paymentDetails", {}),
    transactionId: get(state, "data.api.payMsr.result.transactionId", ""),
    secretKey: get(state, "data.api.payMsr.result.secretKey", ""),
    trackingNumbers: get(state, "data.api.payMsr.result.list", []),
    success: get(state, "data.api.payMsr.result.success", false),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

const MultiplePackagesStage4 = connect(
  mapStateToProps,
  mapDispatchToProps
)(MultiplePackagesStage4Component);

const MultiplePackagesStage4Style = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(98, 104, 109);
  .payment-container {
    margin-top: -5px;
  }
  .new-shipping-button {
    font-family: museo_sans700;
    font-size: 16px;
    color: #2699fb;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    padding: 10px 20px;
    outline: none;
    cursor: pointer;
    margin: auto;
    display: block;
  }
  .get-all-labels {
    display: block;
    margin: auto;
    margin-bottom: 10px;
    margin-top: 36px;
    padding: 14px 20px;
    font-size: 20px;
    background: ${Colors.orange};
    border: none;
    border-radius: 3px;
    color: white;
    cursor: pointer;
    outline: none;
    &:hover {
      background-color: ${Colors.orangeHover};
    }
  }
  .packages-table {
    button {
      padding: 4px 5px;
      font-size: 14px;
      background: #186da7;
      border: none;
      border-radius: 3px;
      color: white;
      cursor: pointer;
      outline: none;
    }
    margin: auto;
    text-align: -webkit-center;
    overflow-x: scroll;
    border: solid 1px lightgrey;
    padding-bottom: 10px;
    border-radius: 8px;
    background-color: white;
    table {
      width: max-content;
      margin: 0;
    }
    &::-webkit-scrollbar {
      width: 10px;
      background-color: lightgrey;
      margin-right: 1px;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      width: 10px;
      background-color: ${Colors.blue2};
      border-radius: 20px;
    }
  }
  .company {
    display: flex;
    align-items: baseline;
    svg {
      height: 15px;
      margin: 0 5px;
      path {
        fill: grey;
      }
    }
  }
  .payment-container {
    display: flex;
    align-items: center;
    svg {
      height: 34px;
    }
  }
  .payment-container,
  .sub-label {
    max-width: 230px;
    font-size: 14px;
    font-family: museo_sans300;
  }
  .upper-data-container {
    display: flex;
    justify-content: space-around;
  }
  .label {
    font-size: 16px;
    margin-bottom: 8px;
  }
  .main-container {
    padding: 37px 20px;
    display: block;
    margin: 0px 50px;
    background-color: #eff8fa;
    box-shadow: 1px 1px 5px #c9d2d4;
    border-radius: 3px;
    margin-bottom: 50px;
    padding-top: 10px;
    margin-top: 20px;
    width: calc(100% - 70px);
  }
  .send-email-text {
    font-size: 16px;
    color: #323232;
    font-family: museo_sans300;
    line-height: 20px;
    SPAN {
      font-family: museo_sans500;
    }
  }
  .thank-you-text {
    color: #0c5e96;
    font-size: 33px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  @media only screen and (max-width: 992px) {
    .upper-data-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }
  }
`;

export default MultiplePackagesStage4;
