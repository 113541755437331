
import React, { useState, useEffect } from 'react';
import { useTitle } from 'hookrouter';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Navigation from './Navigation.component';
import MainPageFooter from './MainPageFooter.component';
import IMAGES from '../common/images';
import { Colors } from '../common/colors';
import { useRoutes, A } from 'hookrouter';
import { get } from 'lodash';
import { PACKAGE_DETAILS_CONTENT } from '../common/content';
import CONSTANTS from '../common/constants';
import { setHeadMetaDescription, getImgTag } from '../common/functions';

const TYPES = {
  OVERVIEW: 'OVERVIEW',
  COMPANY: 'COMPANY',
  PACKAGE: 'PACKAGE'
};

function PackageDetailsComponent(props) {

  let path = CONSTANTS.PUBLIC_URL;
  path = path + (path.substring(path.length - 1) !== '/' ? '/' : '');

  const MainContent = function (props) {
    useTitle(get(props, 'pack.pageTitle', null) || get(props, 'company.pageTitle', null) || 'FedEx and UPS Package Information | ShipWinner');
    setHeadMetaDescription(get(props, 'pack.pageDescription', null) || get(props, 'company.pageDescription', null) || 'Learn about the different kinds of FedEx and UPS boxes that we ship. You can always decide to ship with a Custom Package of your own, but you might find exactly what you need on our list! Ship with ShipWinner to Save up to 62% on Shipping. | ShipWinner.com')
    const pagePathPrefix = path + 'package-details/';
    const IMAGEPACK = IMAGES[get(props, 'pack.img.svg', '')];
    const [menuState, setMenuState] = useState('');
    useEffect(() => {
      if (props.displayMode === 'DESKTOP') {
        setMenuState('');
      } else {
        setMenuState('close');
      }
    }, [props.displayMode])
    return (
      <>
        <div className='header-container'>
          {props.type === TYPES.PACKAGE && get(props, 'pack.blogHeader', '')}
          {props.type === TYPES.COMPANY && get(props, 'company.title', '')}
          {props.type === TYPES.OVERVIEW && 'Package Details'}
        </div>
        <div className="display-flex fjc-space-around contain-cotainer">
          <div className="menu-column">
            {PACKAGE_DETAILS_CONTENT.companies.map((company, ind) => {
              const selected = menuState === company.path;
              const packagePath = get(props, 'pack.path', '');
              const companyPath = get(props, 'company.path', '');
              return (<>
                <div className={'display-flex company-menu-link-container' + (!selected && (ind + 1) !== PACKAGE_DETAILS_CONTENT.companies.length ? ' no-bottom' : '')}>
                  <A className={'company-menu-link' + (companyPath === company.path ? ' selected' : '')} href={path + 'package-details/' + company.path}>{company.title}</A>
                  <button className={('arrow-container mobile-component' + (selected ? ' open' : ''))} onClick={() => { if (selected) { setMenuState('close') } else { setMenuState(company.path) } }}>
                    <IMAGES.arrowDown />
                  </button>
                </div>
                {company.packages.map((pack, i) => {
                  return <A key={i} className={'package-menu-link' + (!selected ? ' hidden' : '') + ((i + 1) === company.packages.length && (ind + 1) !== PACKAGE_DETAILS_CONTENT.companies.length ? ' no-bottom' : '') + (packagePath === pack.path ? ' selected' : '')} href={path + 'package-details/' + company.path + '/' + pack.path}>{pack.title}</A>
                })}
              </>);
            })}
          </div>

          {props.type === TYPES.PACKAGE && <div className="content-column">
            <div className="display-flex fjc-space-between detailsb-header">
              <A className={"left-details-header desktop-component" + (!props.pack.canShip ? ' hide' : '')} href={path + props.shipPath}>{'Ship Now Your' + ' ' + props.pack.blogHeader} </A>
              <div className="right-detailsb-header">
                <div className="create-date mobile-component">
                  {props.pack.createDate}
                </div>
                {props.pack.img.baseUrl && getImgTag(props.pack.img, 'details-header-img')}
                {get(props, 'pack.img.svg', null) && <div className='svg-icon-package'><IMAGEPACK /></div>}
                <div className="create-date desktop-component">
                  {props.pack.createDate}
                </div>
              </div>
            </div>
            <hr />
            {props.pack.content.map((sec, i) => {
              return (
                <React.Fragment key={i}>
                  <div className="paragraph-header">{sec.header}</div>
                  {sec.para.map((para, i2) => {
                    return (<div className="paragraph" key={i + '.' + i2}>{para}</div>)
                  })}
                </React.Fragment>);
            })}
            <A className={"left-details-header mobile-component" + (!props.pack.canShip ? ' hide' : '')} href={path + props.shipPath}>{'Ship Now Your' + ' ' + props.pack.blogHeader} </A>
          </div>}

          {props.type === TYPES.OVERVIEW && <div className="content-column">
            <div className='info-text'>Choosing just the right package can be confusing. Here, we explain about all the FedEx and UPS boxes that we ship. You can always decide on a Custom Package of your own, but you might find exactly what you need on our list! When you've made your decision, you can save it in your Package List, so it's ready for next time. Once it's shipped, it will also be saved in your Shipping History and Shipping Statistics.</div>
            <div className='display-flex  fjc-space-around   details-company-container'> {PACKAGE_DETAILS_CONTENT.companies.map((item, i) => {
              const ICON = IMAGES[item.img.svg];
              return (
                <A href={pagePathPrefix + item.path} key={i}>
                  <div className="details-img-view-container">
                    <div className="company-header">
                      Click For {item.title} Package Information
                  </div>
                    {ICON && <ICON />}
                  </div>
                </A>);
            })}
            </div>
          </div>}

          {props.type === TYPES.COMPANY &&
            <div className="content-column">
              <div className='display-flex details-view-container'> {props.company.packages.map((item, i) => {
                const IMAGESVG = IMAGES[item.img.svg] || null;
                return (
                  <A key={i} href={pagePathPrefix + props.company.path + '/' + item.path} className="details-view" key={i}>
                    <div className="details-header-view">
                      {item.title}
                    </div>
                    <div className="details-img-view-container company">
                      {item.img.baseUrl && getImgTag(item.img, 'details-img-view')}
                      {item.img.svg && <IMAGESVG />}
                    </div>
                  </A>);
              })}
              </div>
            </div>}
        </div>
      </>);
  };

  let routes = {
    '/': () => <MainContent title='overview-details' type={TYPES.OVERVIEW} displayMode={props.displayMode} />,
  };
  PACKAGE_DETAILS_CONTENT.companies.forEach(company => {
    routes['/' + company.path] = () => <MainContent title='company-details' type={TYPES.COMPANY} company={company} displayMode={props.displayMode} />;
    company.packages.forEach(pack => {
      routes['/' + company.path + '/' + pack.path] = () => <MainContent title='package-details' type={TYPES.PACKAGE} company={company} pack={pack} shipPath={'ship/' + company.path + '/' + pack.path} displayMode={props.displayMode} />;
    });
  });

  const routeResult = useRoutes(routes);
  const [tabIndexForAll, setTabIndexForAll] = useState(null);

  return (
    <PackageDetailsStyle className="hidden-on-fullscreen-modal">
      <div className="background-container">
        <Navigation passedTabIndex={tabIndexForAll} />
      </div>
      {routeResult}
      <MainPageFooter />
    </PackageDetailsStyle>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    displayMode: state.data.displayMode
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const PackageDetailsStyle = styled.div`
.info-text{
  margin: 26px 0;
  font-size: 19px;
  color: #747474;
  text-align: center;
}
.company-menu-link{
  display: block;
  color: black;
  margin-left: 20px;
  font-size: 24px;
  margin-top: 20px;
  &.selected{
    color:${Colors.blue3};
  }
}
.package-menu-link{
  display: block;
  color: black;
  padding: 5px;
  margin-left: 40px;
  &.selected{
    color:${Colors.blue3};
  }
}
.svg-icon-package{
  svg{
    width:100%;
    height: 100%;
    path{

    }
  }
}
.right-detailsb-header{
  display: flex;
    flex-direction: column;
    justify-content: space-between;
}
  .background-container {
    background-image: url("${process.env.PUBLIC_URL}/images/pages/home/header-blue.png");
    background-repeat: no-repeat;
    background-size:100%;
    color: white;
    width: 100%;
   }
  .contain-cotainer{
    margin-bottom:5%;
  }
  hr{
    border: none;
    border-bottom: solid 1px #d2cccc;
  }
  .left-details-header{
    &.hide{
      visibility: hidden;
    }
    padding-right: 20px;
    font-size: 20px;
    text-align: left;
    border: solid 1px white;
    background-color: #fcb02e;
    padding: 2%;
    text-decoration: none;
    border-radius: 4px;
    height: 24px;
    align-self: center;
    white-space: nowrap;
    display: block;
    min-width: 400px;
    text-align: center;
    :hover{
     background-color: #EDEDED;
  }
  }
  .company-header{
    margin-top:10%;
    margin-bottom:5%;
    color:#186da7;
  }
.details-view-container{
  flex-wrap:wrap;
}
.details-company-container{
  a{
    width: 290px;
    border: solid 2px lightgray;
    height: 190px;
  }
  margin-top:10%;
}
.details-view{
  width: 20%;
  margin: 20px;
  display: block;
  height: 100%;
  border: solid 2px lightgray;
  padding: 20px;
  min-height: 180px;
}
.create-date-details-view{
  height:5%;
  color:#929C9B;
  font-size:12px;
  font-family:museo_sans300;
  padding:10px;
}
A{
  text-decoration:none;
  color:white;
}
.details-header-view{
  height: 44%;
  font-size:16px;
  color:#62686D;
  font-family:museo_sans500;
  line-height: 24px;
  text-align: center;
}
.details-img-view-container{
  &.company{
    svg{
      height: 100%;
      width: 84%;
    path{
      fill:#000000;        
    } 
  }
  }
  height: 100%;
  width: 100%;
  text-align: center;
  svg{
    height: 55%;
    width: 80%;
    path{
      fill:#186da7;        
    } 
  }
}
.details-img-view{
  width:100%;
}
.create-date{
  color:#929C9B;
  font-size:12px;
  font-family:museo_sans300;
}
  .details-header-img{
    width:333px;
    margin-bottom:10px;
  }
  .menu-column{
    flex:0 0 20%;
    color:#929C9B;
    font-family:museo_sans500;
    font-size:16px; 
  .details{
    font-family:museo_sans500;
    font-size:16px;
    margin-top:20px;
    margin-bottom: 20px;
   }
    .link-container{
      padding-bottom:20px;
     }
    .links{ 
      text-decoration:none;
      color:#323232;
      font-family:museo_sans300;
      &.selected{    
        color:#929C9B;
      }
    } 
 }
  .content-column{
    flex: 0 0 70%;
    margin-right: 5%;
   }
  .paragraph-header{
    color:#3A454E;
    font-size:20px;
    font-family:Museo_Sans500;
    margin-bottom:30px;
    margin-top:19px;
   }
  .paragraph{
      font-family:Museo_Sans300;
      margin-top:16px;
      margin-bottom:20px;
   }
 .header-container{
    width: 100%;
    text-align:center;
    height:50px;
    background-color: #EDEDED;
    color:#323232;
    font-size:36px;
    margin-bottom:15px;
    padding-top: 10px;
    margin-bottom: 30px;
  } 
 .first-header{
      color: ${Colors.black};
      font-family: museo_sans500;
      text-align: center;
      font-size: 36px;
      padding-top: 16px;
      padding-bottom: 12px;
    }
    background-color:${Colors.white};
  
  @media only screen and (max-width: 992px){
    .company-menu-link{
      display: block;
      color: black;
      font-size: 24px;
      padding: 10px;
      margin:0;
      flex: 0 0 71%;
      padding-left: 12%;
    }
    .package-menu-link{
      display: block;
      color: black;
      padding: 7px;
      border-bottom: solid 1px lightgray;
      margin:0;
      &.hidden{
        display: none;
      }
      &.no-bottom{
        border-bottom:none;
      }
    }
    .company-menu-link-container{
      border-bottom: solid 1px ${Colors.blue1};
      border-top: solid 1px ${Colors.blue1};
      &.no-bottom{
        border-bottom:none;
      }
    }
    .arrow-container{
      display: block;
      flex: 0 0 14%;
      text-align: center;
      padding: 0;
      background: none;
      border: none;
      outline: none;
      &.open{
        transform: rotate(180deg);
      }
    }
    .background-container{
      background-image: unset;
      background-color: #03548f;
    }
    .header-container{ 
      min-height: 0px;
      padding-bottom: 10%;
      padding-top: 5%;
      margin-bottom: 0;
    }
    .details-company-container{
      display:block;
    }
    .company-header{
      margin-top: 22%;
     margin-bottom: 9%;
     text-align: -webkit-center;
     color:#186da7;
     font-size: 118%;
    }
    .left-details-header{
      min-width: unset;
      white-space: unset;
      height: unset;
    }
    .details-img-view-container{ 
     svg {
      width: 30%;
     }  
  }
  .contain-cotainer{
    margin-bottom:50px;
    flex-direction: column;
     }
  .content-column{
      margin-right:0%;
      padding:5%; 
   }
  .detailsb-header{
    flex-direction:column;
  }
  .details-view{
    width: 100%;
    height: 100%;
    display: block;
    margin: 5%;
    padding: 2%;
   }
  .first-header{
    padding-top: 20px;
    font-size:30px;
  }
  .details-header-img{  
    margin-bottom:10px;
  }
  .create-date{
    margin-bottom: 15px;
    margin-top: 15px;
    color: #929C9B;
    font-size: 12px;
    font-family: museo_sans300;
  }
  .button-text{
    flex:0 0 95%;
  }
  .icon{ 
    color:${Colors.darkBlue1};
  }
  .rotate-180{
    transform: rotate(180deg);
  }
  .menu-column{
    color:#929C9B;
    font-family:museo_sans500;
    font-size:16px;
    margin-left:0px;
    text-align:center;
    .details{
      width:100%;
      text-align:left;
      border: solid 1px #C8D9E2;
      background-color:${Colors.white};
      color:#323232;
      margin-top:0px;
      margin-bottom:0px;
      height:50px;     
    }
  }
  .link-container{
      padding: 10px 10px 0px 10px;
      border:solid 1px #C8D9E2;
      text-align:left;
      height:10px;
    .links{
      text-decoration:none;
      color:#323232;
      font-family:museo_sans300;
      font-size:16px;
      &.selected{
        font-size:18px;
        font-family:museo_sans500;
        color:#323232;
      }  
    }
  }
  }
`;


const PackageDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(PackageDetailsComponent);

export default PackageDetails;
