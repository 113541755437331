import React, { useRef, useState, useMemo } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { modalQueue, api_login, api_getInitialData } from '../redux/actions';
import { Button, TextField, Link } from '@material-ui/core';
import { Colors } from "../common/colors";
import CONSTANTS from '../common/constants';
import { get } from 'lodash';
import IMAGES from '../common/images';
import PigeItInput from './PigeItInput.component';

function PigeItSaveMappingComponent(props) {

  const dispatch = useDispatch();
  const [name, setname] = useState('')
  return (
    <PigeItSaveMappingStyle className="oginl-container">
      <div className="background-container">
        <div className="content-container">
          <Button className="modal-close-button" onClick={close}>
            <IMAGES.close />
          </Button>
          <div className="header-text">Do you want to save your custom mapping?</div>
          <PigeItInput label='Mapping name' className='mapping-name' value={name} onChange={(e) => setname(e.target.value)} />
          <button className='ok button' onClick={confirm}>SAVE AND CONTINUE</button>
          <button className='cancel button' onClick={close}>CONTINUE WITHOUT SAVING</button>
        </div>
      </div>

    </PigeItSaveMappingStyle >
  );

  function confirm() {
    let callback = get(props, 'modalConfig.callback', null);
    if (typeof callback === 'function') {
      callback({ confirm: true, name });
    }
    dispatch(modalQueue({ mode: 'delete' }));
  }


  function close() {
    let callback = get(props, 'modalConfig.callback', null);
    if (typeof callback === 'function') {
      callback({ confirm: false, name });
    }
    dispatch(modalQueue({ mode: 'delete' }));
  }


}

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  };
};

const PigeItSaveMappingStyle = styled.div`
  width: 100%;
  height: 100%;
  .mapping-name{
    margin: 18px 95px;
  }
  .button{
    background: unset;
    border: none;
    cursor: pointer;
    display: block;
    margin: auto;
    outline: none;
    &.ok{
      width: 350px;
      height: 56px;
      text-align: center;
      color: ${Colors.white};
      background-color: ${Colors.orange};
      font-size: 18px;
      font-family: museo_sans700;
      border-radius: 3px;
    }
    &.cancel{
      text-align: center;
      color: #2699FB;
      font-size: 14px;
      font-family: museo_sans500;
    }
    .icon{
      svg{
        width: 16px;
        height: 13px;
        margin-right: 15px;
        path{
          fill: ${Colors.white};
        }
      }
    }
    
    .text{
      display: inline-block;
    }
  }
  
  .background-container {
    height: 100%;
    width: 100%;
    background-color: #fff;
    margin: 0 auto;
    
  }
  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
    padding-top: 5%;
  }
  .modal-close-button {
    position: absolute;
    top: 21px;
    right: 26px;
  }
  .header-text {
    max-width: 350px;
    font-size: 21px;
    text-align: center;
    margin: auto;
    word-wrap: break-word;
  }
  @media only screen and (max-width: 992px) { 
    .content-container{
      height: 88%;
    }
    .mapping-name{
      margin: 13px;
    }
    .header-text {
      width: unset;
      font-size: 20px;
      margin-top: 46px;
      padding: 0 16px;
    }
    .zip-input{
      min-width: 280px;
    }
    .button{
      &.find-location{
        width: 302px;
      }
      &.ok{
        margin-top: 1px;
        margin-bottom: 10px;
        width: 200px;
      }
    }
  }
`;

const PigeItSaveMapping = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItSaveMappingComponent);




export default PigeItSaveMapping;
