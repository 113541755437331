import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, compose, applyMiddleware } from 'redux';
import combinedReducer from './redux/reducers';
import './common/style.css';
// import registerServiceWorker from './registerServiceWorker';

let store = createStore(
  combinedReducer,
  compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  )
);

// IE
(function() {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf('MSIE ');
  var version = null;

  if (msie > 0) {
    // IE 10 or older => return version number
    version = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:');
    version = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    version = parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  if (version) {
    document.body.classList.add('ie');
    document.body.classList.add('ie' + version);
    window.ie = true;
    window['ie' + version] = true;
  }
})();

if (window.ie) { console.log = function() { }; }

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);


// registerServiceWorker();
