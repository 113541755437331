import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Colors } from '../common/colors';
import IMAGES from '../common/images';
import { get } from 'lodash';
import { attachEvent, detachEvent, isDescendant } from '../common/functions';
import { ZIndices } from "../common/zIndices";

function PigeItSelectComponent(props) {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    // Close the dropdown menu if the user clicks outside of it
    attachEvent(window, 'click', setToCloseOnClickOutside);
    return function () {
      detachEvent(window, 'click', setToCloseOnClickOutside);
    }
  }, []);
  let mobile = props.mobile;
  let allText = get(props, 'valueList', []).every(opt => typeof opt.text === 'string');

  const selectedOption = props.valueList.filter((item) => String(item.value) === String(get(props, 'value.value')))[0];
  return (
    <PigeItSelectStyle className={props.className} ref={containerRef}>
      <div>
        <label className={'selecet-label' + (isOpen ? ' focused' : '')}>{props.label}</label>
        <div className={'dropdown-container' + (isOpen ? ' open-dropdwon-container' : ' dropdown-container-close')}>
          {props.customIcon && (<div className="custom-icon"><props.customIcon /></div>)}
          {(mobile) ? (<select name={props.name + '-mobile'} className={"mobile-component dropdown-button" + (props.disabled ? ' disabled' : '')} disabled={props.disabled} value={(selectedOption ? selectedOption.value : '')} onChange={(e) => { handleSelect(props.valueList.find(item => item.value === e.target.value)); }}>
            {props.valueList.map((item, i) => (<option key={i} value={item.value}>{(typeof item.text === 'function' ? item.text(() => { }, i, true) : item.text)}</option>))}
          </select>) : (<>
            <input name={props.name + '-desktop'} value={(selectedOption ? (typeof selectedOption.text === 'function' ? selectedOption.text(() => { }, 0, true) : selectedOption.text) : '')} onClick={() => { if (!props.disabled) setIsOpen(!isOpen); }} type='button' className={'dropdown-button desktop-component' + (props.disabled ? ' disabled' : '')}>
            </input>
            <div className="icon-container">
              <div className={(isOpen ? 'rotate-180' : '')}>
                <IMAGES.arrowDown className='icon' />
              </div>
            </div></>)}
        </div>
        <div className={'dropdown-menu-container desktop-component' + (isOpen ? ' show' : '')}>
          {props.valueList.map((item, i) => (typeof item.text === "function" ? item.text(() => { handleSelect(item); }, i) : <button key={i} onClick={() => { handleSelect(item); }} type='button' className='dropdown-button menu'><span className='dropdown-main-text'>{item.text}</span></button>))}
        </div>
      </div>
    </PigeItSelectStyle>
  );

  function handleSelect(val) {
    setIsOpen(false);
    if (props.onChange) props.onChange(val);
  }

  function setToCloseOnClickOutside(evt) {
    if (!isDescendant(containerRef.current, evt.target)) {
      setIsOpen(false);
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    mobile: state.data.displayMode === 'MOBILE',
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: ownProps.onChange,
  }
};

const PigeItSelectStyle = styled.div`
  position: relative;
  width:100%;
  margin-top: -11px;
  .selecet-label{
    &.focused{
      color:${Colors.blue1};
    }
    color: #9daab1;
    transform: translate(10px, 6px);
    display: block;
    background: white;
    width: 72px;
    font-size: 11px;
    padding: 0 3px;
  }
  .custom-icon {
    margin: 10px;
    margin-left: 15px;
    margin-top: 11px;
    svg {
      width: 16px;
      height: 16px;
      path {
        fill: ${Colors.lightGray5};
      }
    }
  }
  .icon{
      color:${Colors.darkBlue1};
  }
  .rotate-180{
    transform: rotate(180deg);
  }
  .icon-container{

  }
  .dropdown-button{
    outline:none;
    text-align:left;
    width:100%;
    padding: 0px 0px 0px 16px;
    border:none;
    background-color: rgba(255, 255, 255, 0.3);
    color:${Colors.lightGray2};
    font-family:museo_sans500;
    font-size:16px;
    &.menu{
      padding:9px 0px 11px 16px;
    }
    &.disabled{
      color: ${Colors.lightGray5};
    }
  }

  .menu:hover{
    background-color: ${Colors.lightGray5};
  }
  .dropdown-container-close:hover{
    border: solid 1px  #000000;
  }
  .dropdown-container{
    display:flex;
    height: 35px;
    margin-top: -1px;
    border: solid 1px  #c5c5c5;
    border-radius:4px;
  }
  .dropdown-container:focus{
    border: solid 1.2px  ${Colors.blue1};
  }
  .open-dropdwon-container{
    border: solid 1px  ${Colors.blue1};
    border-bottom: solid 0px white;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .dropdown-menu-container{
    width: calc(100% - 2px);
    display:none;
    border: solid 1px  ${Colors.lightGray5};
    border-radius:3px;
    position: absolute;
    z-index: ${ZIndices.selectComponent};
    max-height:100px;
    text-align:justify; 
    overflow-x: auto; 
    background-color:hsla(0,0%,100%,.9);
    animation: fadein 0.5s;
    z-index: 5;
  }
  .dropdown-menu-container::-webkit-scrollbar {
    width: 10px;
    background-color: hsla(0,0%,100%,0);
    margin-right:1px;
} 
.dropdown-menu-container::-webkit-scrollbar-thumb {
  width: 10px;
  background-color: ${Colors.blue1};
  border-radius:3px;
} 
  .dropdown-text{
    color:${Colors.lightGray1};
    font-family:museo_sans500;
    font-size:16px;
  }
  .icon-container{
    justify-content: center;
    margin-right: 12px;
    margin-top: 8px;
    fill:${Colors.blue1}
  }
  .show {
    display:block;
    border: solid 1px  ${Colors.blue1};
    border-top: solid 0px white;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}
`;

const PigeItSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItSelectComponent);

export default PigeItSelect;
