import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { toggleVScrollTop, api_logout } from '../redux/actions';
import { ZIndices } from '../common/zIndices';
import { Colors } from "../common/colors";
import IMAGES from '../common/images';
import { get } from 'lodash';
import { navigateToPath } from "../common/functions";
import PigeItDropDown from './PigeItDropDowncomponent';
import PigeItTrackOrderInput from './PigeItTrackOrderInput.component';

function NavigationComponent(props) {
  const dispatch = useDispatch();
  const [isVScrollTopState, setIsVScrollTopState] = useState(true);
  const [mobileMeunOpen, setMobileMeunOpen] = useState(false);

  // handle scrolling
  useEffect(() => {
    const linkedMethod = handleScrollEvent.bind(this);
    window.addEventListener('scroll', linkedMethod);
    return function () {
      window.removeEventListener('scroll', linkedMethod);
    }
  });

  function handleScrollEvent(evt) {
    if (props.mobile) {
      return;
    }
    const currentIsVScrollTop = isVScrollTopState;
    const newScrollPosition = (window.pageYOffset != null ? window.pageYOffset : window.scrollY);
    const newIsVScrollTop = (newScrollPosition === 0 ? true : false);
    if (currentIsVScrollTop !== newIsVScrollTop) {
      // local state
      setIsVScrollTopState(newIsVScrollTop);
      // global state setup
      dispatch(toggleVScrollTop({ isVScrollTop: newIsVScrollTop }));
    }
  }

  let userLoggedIn = get(props, 'apiData_getInitialData.result.success', false);
  const currentLocation = window.location.pathname.length > 1 ? window.location.pathname.substr(1) : '';
  //menu links
  let aboutUsLinks = [
    { text: 'Faq', href: 'information/faq' },
    { text: 'Contact Us', href: 'contact-us' },
    { text: 'BlogWinner', href: 'blogs/blog-winner' },
    { text: 'Terms', href: 'information/terms-introduction' }
  ];
  let packageInfoLinks = [
    { text: 'FedEx', href: 'package-details/fedex' },
    { text: 'UPS', href: 'package-details/ups' }
  ];
  let trackLinks = [
    { text: 'FedEx', href: 'track-your-shipment-fedex' },
    { text: 'UPS', href: 'track-your-shipment-ups' }
  ];
  let myAccountLoggedInLinks = [
    { text: 'Personal Info', href: 'my-account/personal-info' },
    { text: 'Address Book', href: 'my-account/address-book' },
    { text: 'Package List', href: 'my-account/packages-list' },
    { text: 'Credit Card', href: 'my-account/credit-cards' },
    { text: 'Shipping History', href: 'my-account/shipping-history' }
  ];
  let myAccountNotLoggedInLinks = [
    { text: 'Personal Info', href: 'sign-in', queryParams: { redirectTo: 'my-account/personal-info' } },
    { text: 'Address Book', href: 'sign-in', queryParams: { redirectTo: 'my-account/address-book' } },
    { text: 'Package List', href: 'sign-in', queryParams: { redirectTo: 'my-account/packages-list' } },
    { text: 'Credit Card', href: 'sign-in', queryParams: { redirectTo: 'my-account/credit-cards' } },
    { text: 'Shipping History', href: 'sign-in', queryParams: { redirectTo: 'my-account/shipping-history' } }
  ];
  let businessesLoggedInLinks = [
    { text: 'About Businesses', href: 'businesses'},
    { text: 'Dashboard', href: 'businesses/dashboard' },
    { text: 'CSV Structure', href: 'businesses/csv-structure' },
    { text: 'Shipping History', href: 'my-account/shipping-history' }
  ];

  let businessesNotLoggedInLinks = [
    { text: 'About Businesses', href: 'businesses'},
    { text: 'Dashboard', href: 'sign-in', queryParams: { redirectTo: 'businesses/dashboard' } },
    { text: 'CSV Structure', href: 'businesses/csv-structure' },
    { text: 'Shipping History', href: 'sign-in', queryParams: { redirectTo: 'my-account/shipping-history' } }
  ];

  if (!props.mobile) {
    return (<>
      <div style={{ height: '93px' }}></div>
      <NavigationStyle className={'navigation-container' + (!isVScrollTopState ? ' v-scroll-nontop' : '')}>
        <div className="desktop-component">
          <div className="fixed-background-container"></div>
          <div className="navigation-container display-flex fjc-space-between">
            <a onClick={(e) => onNavOptionClick(e, '')} className="header-logo-container">
              <IMAGES.logo />
            </a>
            <div className="navigation-options-left-aligned-container display-flex fjc-flex-end">
              <div className="display-flex fjc-space-between">
                <PigeItDropDown new main={{ text: 'BUSINESSES', href: 'businesses'}} links={userLoggedIn ?businessesLoggedInLinks:businessesNotLoggedInLinks} />
                <PigeItDropDown main={{ text: 'ABOUT US', href: 'information/about-us' }} links={aboutUsLinks} />
                <PigeItDropDown main={{ text: 'PACKAGE INFO', href: 'package-details' }} links={packageInfoLinks} />
                <PigeItDropDown main={{ text: 'TRACK A PACKAGE', href: 'track-your-shipment' }} links={trackLinks} />
                <PigeItDropDown main={{ text: 'MY ACCOUNT', href: userLoggedIn ? 'my-account' : 'sign-in', queryParams: userLoggedIn ? null : { redirectTo: 'my-account' } }} links={userLoggedIn ? myAccountLoggedInLinks : myAccountNotLoggedInLinks} />
              </div>
              <PigeItTrackOrderInput className='track-shipment-input' />
              {userLoggedIn ?
                <PigeItDropDown className='loggedin-user' main={{ text: getUserName(), href: 'my-account' }} links={[{ text: 'Logout', href: '#', onClick: () => dispatch(api_logout()) }]} /> :
                <PigeItDropDown main={{ text: 'SIGN IN', href: 'sign-in', queryParams: { redirectTo: currentLocation } }} links={[{ text: 'Sign in', href: 'sign-in', queryParams: { redirectTo: currentLocation } }, { text: 'Register', href: 'register' }]} />}
            </div>
          </div>
        </div>
      </NavigationStyle></>);
  } else {
    return (<MobileNavigation>
      <div className="mobile-component">
        <div className="navigation-container fjs-center">
          <div className='display-flex fjc-space-between'>
            <button className='menu-icon-container' onClick={() => mobileMenu(true)}>
              <IMAGES.mobileMenu />
            </button>
            <a onClick={(e) => onNavOptionClick(e, '')} className="header-logo-container mobile-icon-container">
              {<IMAGES.logo />}
            </a>
            <div className='place-holder' />
          </div>
        </div>
        <div className={'sidebar-backgroud-dimmer' + (mobileMeunOpen ? '' : ' hidden-component')} onClick={(e) => { if (e.target.className === 'sidebar-backgroud-dimmer') mobileMenu(false); }}>
          <div className={'sidebar-main-container' + (mobileMeunOpen ? '' : ' hidden-component')}>
            <div>
              <div className="sign-in-register-logout-container display-flex">
                {!userLoggedIn ? <>
                  <button className="sign-up-button" onClick={(e) => onMobileNavOptionClick(e, 'sign-in')}>Sign in</button>
                  <button className="register-button" onClick={(e) => onMobileNavOptionClick(e, 'register')}>Join us</button>
                </> : <>
                    <div className="logged-in-user">{getUserName()}</div>
                    <div className='logged-in-seperator'>|</div>
                    <button className="logout-button" onClick={() => { dispatch(api_logout()); mobileMenu(false); }}>Logout</button>
                  </>}
              </div>
              <hr className='seperator-line' />
              <div className="navigation-options-container">
                <a href={userLoggedIn ? 'my-account' : 'sign-in'} onClick={(e) => userLoggedIn ? onMobileNavOptionClick(e, 'my-account') : onMobileNavOptionClick(e, 'sign-in', { redirectTo: 'my-account' })} className="navigation-button ws-nowrap" >MY ACCOUNT</a>
                <a href='/package-details' onClick={(e) => onMobileNavOptionClick(e, 'package-details')} className="navigation-button ws-nowrap" >PACKAGE INFO</a>
                <a href='/track-your-shipment' onClick={(e) => onMobileNavOptionClick(e, 'track-your-shipment')} className="navigation-button ws-nowrap" >TRACK A PACKAGE</a>
                <a href='/blogs/blog-winner' onClick={(e) => onMobileNavOptionClick(e, 'blogs/blog-winner')} className="navigation-button ws-nowrap" >BLOG WINNER</a>
                <a href='/contact-us' onClick={(e) => onMobileNavOptionClick(e, 'contact-us')} className="navigation-button ws-nowrap" >CONTACT US</a>
              </div>
            </div>
            <div>
              <div className='display-flex fjc-space-between media-links-container'>
                <a className='icon-link' target='_blank' rel="noopener noreferrer" href='https://www.youtube.com/channel/UCUFlSc1rfFNlzzgG-w9EgkQ'><IMAGES.youtube /></a>
                <a className='icon-link' target='_blank' rel="noopener noreferrer" href='https://www.linkedin.com/company/pigeit-com/'><IMAGES.linkedin /></a>
                <a className='icon-link' target='_blank' rel="noopener noreferrer" href='https://www.facebook.com/ShipWinnercom-101654078069092/'><IMAGES.facebook /></a>
              </div>
              <a className='link' href='information/about-us' onClick={(e) => onMobileNavOptionClick(e, 'information/about-us')}>About Us</a>
              <a className='link' href='information/faq' onClick={(e) => onMobileNavOptionClick(e, 'information/faq')}>FAQ</a>
              <PigeItTrackOrderInput className='track-shipment-input' />
            </div>
          </div>
        </div>
      </div>
    </MobileNavigation>);
  }

  function mobileMenu(open) {
    if (open) {
      document.body.classList.add('mobile-navigation');
      setMobileMeunOpen(true);
    } else {
      document.body.classList.remove('mobile-navigation');
      setMobileMeunOpen(false);
    }
  }

  function onMobileNavOptionClick(e, navigationPath, queryParams) {
    e.preventDefault();
    mobileMenu(false);
    navigateToPath(navigationPath, true, false, queryParams);
  }

  function onNavOptionClick(e, navigationPath, queryParams) {
    e.preventDefault();
    navigateToPath(navigationPath, true, false, queryParams);
  }

  function getUserName() {
    let name = get(props, 'apiData_getInitialData.result.fullname', 'UNKNOWN');
    if (name === 'UNKNOWN' || name === '') {
      name = get(props, 'apiData_getInitialData.result.user', 'UNKNOWN');
    }
    if (name.indexOf('@') !== -1) {
      name = name.split('@')[0];
    }
    return name;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isVScrollTop: state.data.isVScrollTop,
    apiData_getInitialData: state.data.api.getInitialData,
    mobile: state.data.displayMode === 'MOBILE',
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const NavigationStyle = styled.div`
  color: white;
  width: 100%;
  font-size: 14px;
  height: 100px;
  z-index: ${ZIndices.navigation};
  position: fixed;
  top: 0;
  height: 0;
  .loggedin-user{
    max-width: 200px;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &.v-scroll-nontop {
    .header-logo-container{
      .logo-img{
        width: 37px !important;
        transform: translate(0px, -2px);
      }
      .img-container{
        svg{
          width: 170px;
        }
      }
    }
    .fixed-background-container {
      height: 60px;
    }
    .navigation-container {
      padding: 5px 20px;
    }
  }
  .fixed-background-container {
    position: fixed;
    top: 0;
    background-color: ${Colors.blue2};
    width: 100%;
    height: 0px;
    transition: height 0.4s linear;
    opacity:0.95;
  }
  .navigation-container {
    position: fixed;
    top: 0;
    padding: 20px 20px;
    width: calc(100% - 40px);
    transition: padding 0.4s linear;
  }
  .header-logo-container {
    cursor: pointer;
    .img-container{
      svg {
        width: 191px;
        height: 48px;
        transition: width 0.4s linear;
        path{
          fill:${Colors.white};
        }
      }
    }
    .logo-img{
      transition: width 0.4s linear;
    }
  }
  @media only screen and (max-width: 1200px) {
    .track-shipment-input{
      display: none;
    }
  }
`;

const MobileNavigation = styled.div`
  .media-links-container{
    width: 115px;
    margin-left: 20px;
    svg{
      width: 24px;
      height: 24px;
    }
  }
  .header-logo-container{
    .img-container{
      min-width: unset !important;
      .logo-img{
        width: 35px !important;
        transform: translate(0px, -5px);
      }
    }
    svg{
      width: 150px;
      margin-top: 6px;
      path{
        fill:${Colors.white};
      }
    }
  }
  .place-holder{
    width: 59px;
  }
  .menu-icon-container{
    svg{
      path{
        fill:${Colors.white};
      }
    }
    padding: 15px;
    border: none;
    background: none;
    outline: none;
    padding-left: 20px;
  }
  .sidebar-backgroud-dimmer{
    position:fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    overflow:auto;
    background-color:rgba(0,0,0,0.5);
    z-index: ${ZIndices.navigation};
  }
  .sidebar-main-container{
    animation: animateleft 0.4s;
    top:0;
    width:300px;
    height:100%;
    background-color:#226D9E;
    overflow: overlay;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .sign-in-register-logout-container {
      justify-content: space-between;
      margin: 0px 23px;
      padding-top: 15px;
      .sign-up-button {
        padding:0px;
        height: 40px;
        font-size: 14px;
        color: #fff;
        background: none;
        border: none;
        cursor: pointer;
      }
      .register-button {
        height: 40px;
        width: 51%;
        max-width: 120px;
        font-size: 14px;
        color: #fff;
        background-color: transparent;
        border-width: 2px;
        border-style: solid;
        border-color: #fff;
      }
      .logged-in-user {
        line-height: 38px;
        margin: 0 5px 0 5px;
      }
      .logged-in-seperator{
        font-family: museo_sans500;
        font-size: 14px;
        margin: 10px 10px 0 3px;
      }
      .logout-button {
        color: #fff;
        background: none;
        border: none;
        height: 38px;
        font: unset;
        font-family: museo_sans500;
        font-size: 14px;
      }
    }
    .seperator-line{
      border: none;
      border-top: 1px solid #048bc4;
      margin: 28px 23px;
    }
    .seperator-space{
      margin:100px;
    }
    .link{
      display:block;
      text-decoration:none;
      color:${Colors.white};
      font-family:museo_sans300;
      font-size:13px;
      margin:20px 23px;
    }
    .navigation-options-container{
      .navigation-button {
        margin: 25px;
        margin-left:17px;
        display:block;
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        font-size: 14px;
      }
    }
    .track-shipment-input{
      margin: 10px 15px;
    }
  }
`;

const Navigation = connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationComponent);

export default Navigation;
