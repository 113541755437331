/* eslint default-case: off, eqeqeq: off, react-hooks/exhaustive-deps: off */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useRoutes, useRedirect } from 'hookrouter';
import { connect, useDispatch } from 'react-redux';
import Navigation from './Navigation.component';
import { Colors } from '../common/colors';
import MainPageFooter from "./MainPageFooter.component";
import IMAGES from '../common/images';
import CONSTANTS from '../common/constants';
import { setHeadMetaDescription, navigateToPath } from '../common/functions';
import {save_orders} from '../redux/actions';
import { get } from 'lodash';
import MultiplePackagesStage0 from './MultiplePackagesStage0.component';
import MultiplePackagesStage1 from './MultiplePackagesStage1.component';
import MultiplePackagesStage2 from './MultiplePackagesStage2.component';
import MultiplePackagesStage3 from './MultiplePackagesStage3.component';
import MultiplePackagesStage4 from './MultiplePackagesStage4.component';
import MultiplePackagesCSVStructure from './MultiplePackagesCSVStructure.component';

function MultiplePackagesComponent(props) {
  const [stage, setstage] = useState(1);
  const dispatch = useDispatch();

  let routes = {
    '/': () => <MultiplePackagesStage0 />,
    '/map-csv-values': () => <MultiplePackagesStage1 />,
    '/dashboard': () => <MultiplePackagesStage2 />,
    '/stage-3': () => <MultiplePackagesStage3 />,
    '/stage-4': () => <MultiplePackagesStage4 />,
    '/csv-structure': () => <MultiplePackagesCSVStructure />,
  }
  const routeResult = useRoutes(routes);

  useEffect(() => {
    try {
      var orders = JSON.parse(localStorage.getItem('csv-orders'));
      if (Array.isArray(orders) && orders.length > 0 && orders.every(row => CONSTANTS.CSV_HEADERS.every(h => Object.keys(row).some(key => key === h)))) {
        dispatch(save_orders(orders));
      } else {
        localStorage.removeItem('csv-orders');
      }
    } catch (err) { }
  }, []);

  return (
    <MultiplePackagesStyle className="main-page-wrapper hidden-on-fullscreen-modal">
      <div className="main-page-background-container">
        <Navigation />
        <div className='stage-container'>
          {routeResult}
        </div>
      </div>
      <MainPageFooter />
    </MultiplePackagesStyle>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    mobile: state.data.displayMode === 'MOBILE',
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const MultiplePackages = connect(
  mapStateToProps,
  mapDispatchToProps
)(MultiplePackagesComponent);

const MultiplePackagesStyle = styled.div`
  .main-page-background-container {
    background-image: url("${CONSTANTS.PUBLIC_URL}/images/pages/home/header-blue.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: white;
    width: 100%;
  }
  .stage-container{
    width: 100%;
    background: white;
  }
  @media only screen and (max-width: 992px) {
    .main-page-background-container {
      background-image: unset;
      background-color: ${Colors.blue2};
    }
  }
`;

export default MultiplePackages;
