import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ZIndices } from '../common/zIndices';
import { Colors } from '../common/colors';
import IMAGES from '../common/images';

function PigeItHorizontalSelectComponent(props) {

  const defaultOptionsState = (props.options ? props.options : [ 'lbs', 'kg' ]);
  const [ options, setOptions ] = useState(defaultOptionsState); // default is lbs and kg
  const [ selectedValue, setSelectedValue ] = useState((props.initialValue ? props.initialValue : defaultOptionsState[0])); // default is first option
  const [ isOpen, setIsOpen ] = useState(false);
  return (
    <PigeItHorizontalSelectStyle className={'input-select-unit-container ' + props.className + (isOpen ? ' select-opened' : ' select-closed')}>
      {!isOpen && <button tabIndex={props.passedTabIndex} className={"input-select-button-selected" + (props.defaultColor === 'white' ? ' bg-blue' : ' bg-white')} onClick={onOpen}>
        <span>{selectedValue}</span>
        {<IMAGES.arrowRight/>}
      </button>}
      {isOpen && (<button tabIndex={props.passedTabIndex} className="input-select-button-option select-option-1" onClick={(e) => { onSelect(e, options[0]); } }>
        <span>{options[0]}</span>
        {<IMAGES.arrowLeft />}
      </button>)
      }
      {isOpen && (<div className="options-separator"></div>) }
      {isOpen && (<button tabIndex={props.passedTabIndex} className="input-select-button-option select-option-2" onClick={(e) => { onSelect(e, options[1]); } }>
        <span>{options[1]}</span>
      </button>)}
    </PigeItHorizontalSelectStyle>
  );

  function onOpen() {
    setIsOpen(true);
    if (props.onToggleView) {
      props.onToggleView(true);
    }
  }
  function onSelect(e, value) {
    setSelectedValue(value);
    setIsOpen(false);
    if (props.onToggleView) {
      props.onToggleView(true);
    }
    if (props.onChange) {
      props.onChange(value);
    }
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    state: state
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const PigeItHorizontalSelectStyle = styled.div`
  .input-select-button-selected {
    background-color: transparent;
    border-width: 0px 0px 0px 1px;
    cursor: pointer;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    position: absolute;
    top: 0;
    right: 0;
    width: 59px;
    height: 100%;
    line-height: 40px;
    font-size: 16px;
    text-align: left;
    color: ${Colors.blue1};
    padding-left: 10px;
    svg {
      top: calc(50% - 5px);
      right: 10px;
      position: absolute;
    }
    &.bg-blue {
      color: ${Colors.white};
      background-color: ${Colors.blue1};
      svg path {
        fill: ${Colors.white};
      }
    }
    &.bg-white svg path {
      fill: ${Colors.blue1};
    }
  }
  .input-select-button-option {
    background-color: ${Colors.blue1};
    border: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    height: 100%;
    font-family: museo_sans300,sans-serif;
    font-size: 16px;
    color: #fff;
  }
  .select-option-2 {
    right: 0;
    width: 56px;
  }
  .options-separator {
    background-color: ${Colors.white};
    top: 10px;
    right: 55px;
    height: calc(100% - 20px);
    position: absolute;
    width: 1px;
    z-index: ${ZIndices.horizontalSelectOptionsSeperator};
  }
  .select-option-1 {
    right: 56px;
    width: 63px;
    svg {
      position: absolute;
      top: calc(50% - 5px);
      left: 5px;
      path {
        fill: ${Colors.white};
      }
    }
  }
`;

const PigeItHorizontalSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItHorizontalSelectComponent);

export default PigeItHorizontalSelect;
