/* eslint react/jsx-pascal-case: off, react-hooks/exhaustive-deps: off */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { api_creditcard_getList, modalQueue, api_creditcard_addCreditCard } from '../redux/actions';
import { Colors } from "../common/colors";
import Loader from "./Loader.component";
import IMAGES from '../common/images';
import { genericSetValue, api_creditcard_removeCreditCard,api_creditcard_setSelectedCard, setupAnimatedLoader } from '../redux/actions';
import CONSTANTS from '../common/constants';
import { get } from 'lodash';
import { showErrorMessage, identifyCreditCardType ,setHeadMetaDescription} from "../common/functions";
import { useTitle } from 'hookrouter';
import { ZIndices } from "../common/zIndices";
import PigeItRadio from "./PigeItRadio.component";


// TODO - fix remove button to behave correctly. there are basically 2 options (depends on what you need):
// 1- you need to pass parameters => use inline function, example: onClick={ (e) => { myFunc(e,param1,param2); }}
// 2- you don't need to pass parameters => use reference, example: onClick={ myFunc }

function PigeItMyAccount_CreditCardComponent(props) {
  useTitle('My Account | Credit Card | ShipWinner');
  setHeadMetaDescription(``);

  const dispatch = useDispatch();
  const [inProgress, setInProgress] = useState(false);
  const [ itemsClickable, setItemsClickable ] = useState(get(props,'parentProps.modalConfig.cardClickable', false));
  const defaultCard = get(props, 'apiData_paymentSources_getList.result.results', []).find(c=>c.selected);
  useEffect(function () {
    setInProgress(true);
    // read the list from the server
    dispatch(api_creditcard_getList({
      callback: function (result) {
        setInProgress(false);
      }
    }));
  }, []);

  return (
    <PigeItMyAccount_CreditCardStyle className="my-account-personal-info-container">
      <div className="mobile-component bottom-banner">
        <div className="add-to-card-list-control flex-divided-2-cols">
          <button className="add-new-card-button" onClick={addNewCard}>{<IMAGES.plus />}<span>Add new card</span></button>
        </div>
      </div>
      <div className="background-container">
      <div className='place-holder'/>
        <div className="desktop-component title-and-add-to-card-list-control-container display-flex fjc-space-around">
          <div className="title-text flex-divided-2-cols">
            Credit Card {inProgress && (<Loader imageOnly={true} />)}
          </div>
          <div className="add-to-card-list-control flex-divided-2-cols">
            <button className="add-new-card-button" onClick={addNewCard}>{<IMAGES.plus />}<span>Add new card</span></button>
          </div>
        </div>
        <hr className="desktop-component seperator" />
        <div className="display-flex fd-column">
          {get(props, 'apiData_paymentSources_getList.result.results', []).length > 0 && get(props, 'apiData_paymentSources_getList.result.results', []).map((item, i) => {
            const cardType = identifyCreditCardType(null, item.cardType);
            const Icon = IMAGES[cardType] ? IMAGES[cardType] : null;
            return (
              <div key={i} className={"result-item-container display-flex fd-row fjc-space-between" + (itemsClickable ? ' clickable' : '')} tabIndex={(itemsClickable ? 0 : null)} onClick={() => { onInformationClick(item, i); }}>
                <div className='display-flex'>
                  <div className='default-card'>
                    <PigeItRadio name={"default-card-"+item.last4Digits} value={item} selectedRadioValue={defaultCard} setSelectedRadioValue={(value) => { setSelectedCard(value); }} />
                  </div>
                  <div className="information-container">
                    <div className="display-flex fd-row"><div className='company'><Icon style={{ height: '34px' }} /></div>&nbsp;&nbsp;&nbsp;<div className='numbers'>{hiddenCardNumber(item.last4Digits)}</div></div>
                    <div className="display-flex fd-row"><div className='label'>Expiration Date:</div>&nbsp;<div className='numbers date'>{item.expiryMonth + "/" + item.expiryYear.substring(2, 4)}</div></div>
                  </div>
                </div>
                <div className="actions-container">
                  {<button className="remove-button" onClick={() => removeCard(item.last4Digits, item.cardType)}>{<IMAGES.remove />} <span className='desktop-component'>&nbsp;&nbsp;Remove</span></button>}
                </div>
              </div>);
          })}
        </div>
      </div>
      <div className="save-changes-button-container">
      </div>
    </PigeItMyAccount_CreditCardStyle>
  );

  function removeCard(last4Digits, cardType) {
    setInProgress(true);
    dispatch(setupAnimatedLoader({ isVisible: true, text: 'Deleting card...' }));
    dispatch(api_creditcard_removeCreditCard({
      cardType: cardType,
      last4Digits: last4Digits,
      callback: function (result) {
        setInProgress(false);
        dispatch(setupAnimatedLoader({ isVisible: false }));
        if (result.success) {
          dispatch(genericSetValue({ path: 'api.paymentSources_getList', value: { result: { success: true, results: get(props, 'apiData_paymentSources_getList.result.results', []).filter(item => item.last4Digits !== last4Digits) } } }));
        }
        else {
          showErrorMessage(result, dispatch);
        }
      }
    }));
  }

  function hiddenCardNumber(number) {
    return " **** " + number;
  }

  function addNewCard() {
    const modalConfig = JSON.parse(JSON.stringify(CONSTANTS.MODALS_CONFIG.CREDIT_CARDS_ADD_EDIT));
    dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
  }

  function setSelectedCard(card) {
    // offline update (just for ui)
    const results = get(props, 'apiData_paymentSources_getList.result.results', []);
    let match = true;
    for (const key in card) {
      if (key !== 'selected') {
        if (defaultCard[key] != card[key]) {
          match = false;
        }
      }
    }
    if (match) {
      // no need to update
      return;
    }
    if (results.length) {
      results.forEach(function(item) {
        delete item.selected;
        match = true;
        for (const key in card) {
          if (item[key] != card[key]) {
            match = false;
          }
        }
        if (match) {
          item.selected = true;
        }
      });
      dispatch(genericSetValue({ path: 'api.paymentSources_getList', value: { result: { success: true, results: results } } }));
    }

    // real update
    dispatch(api_creditcard_setSelectedCard({
      cardType:card.cardType,
      expiryMonth:card.expiryMonth,
      expiryYear:card.expiryYear,
      last4Digits:card.last4Digits,
      callback:function (result) {
        if(result.success){
          setInProgress(true);
          dispatch(api_creditcard_getList({
            callback: function () {
              setInProgress(false);
            }
          }));
        }
      }
    }));
  }

  function onInformationClick(item, i) {
    if (itemsClickable) {
      const callback = get(props,'parentProps.modalConfig.callback', false);
      if (typeof(callback) === 'function') {
        callback(item);
        const card = item;
        if(card){
          dispatch(api_creditcard_setSelectedCard({
            cardType:card.cardType,
            expiryMonth:card.expiryMonth,
            expiryYear:card.expiryYear,
            last4Digits:card.last4Digits,
            callback:function (result) {
              if(result.success){
                setInProgress(true);
                dispatch(api_creditcard_getList({
                  callback: function () {
                    setInProgress(false);
                  }
                }));
              }
            }
          }));
        }
        dispatch(modalQueue({ mode: 'delete' }));
      }
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    apiData_paymentSources_getList: state.data.api.paymentSources_getList
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  };
};

const PigeItMyAccount_CreditCardStyle = styled.div`
  .bottom-banner {
    width: 100%;
    position: fixed;
    background: white;
    z-index: ${ZIndices.myAccountCreditCard};
    bottom: 0;
    box-shadow: 0px -1px 6px #d0d0d0;
  }
  .edit-button, .remove-button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .background-container {
    padding: 1px 8px;
    background-color: ${Colors.panelBackground};
  }
  .place-holder{
    height: 40px;
  }
  .title-text {
    text-align: left;
    font-family: museo_sans300,sans-serif;
    color: ${Colors.darkBlue1};
    font-size: 30px;
    margin: 19px 0px 0px 25px;
  }
  .add-to-card-list-control {
    text-align: right;
    margin: 12px 25px 0px 0px;
  }
  .add-new-card-button {
    font-size: 16px;
    height: 56px;
    background-color: transparent;
    border: none;
    color: ${Colors.blue1};
    svg {
      position: relative;
      top: -1px;
    }
    SPAN {
      position: relative;
      top: -12px;
      padding-left: 5px;
    }
  }
  .seperator {
    margin-bottom: 30px;
  }
  .result-item-container {
    &.clickable{
      cursor: pointer;
    }
    padding: 18px;
    margin-bottom: 9px;
    background-color: ${Colors.white};
    .defualt-card{
      padding-top: 9px;
    }
    .information-container {
      color: #323232;
      text-align: left;
      font-size:14px;
      line-height:24px;
      .label {
      }
      .company {
        line-height: unset;
        height: 24px;
        svg{
          height: 34px;
        }
        font-size:16px;
        color: #62686D;
        margin-bottom:6px;
      }
      .numbers {
        &.date{
          line-height: unset;
        }
        line-height: 36px;
        font-family:museo_sans300;
      }
    }
    .actions-container {
      margin-top: 18px;
      .edit-button {
        color: ${Colors.blue1};
        font-size: 14px;
        SPAN {
          text-transform: none;
        }
      }
      .remove-button {
        color: ${Colors.red};
        font-size: 14px;
        SPAN {
          text-transform: none;
        }
      }
    }
  }
  @media only screen and (max-width: 992px) {
    .background-container{
      margin-bottom: 60px;
    }
    .place-holder{
      height: 15px;
    }
    .add-to-card-list-control {
      margin: unset;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;

const PigeItMyAccount_CreditCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItMyAccount_CreditCardComponent);


export default PigeItMyAccount_CreditCard;
