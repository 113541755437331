/* eslint react/jsx-pascal-case: off */
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { modalQueue } from '../redux/actions';
import { Button } from '@material-ui/core';
import { Colors } from "../common/colors";
import { ZIndices } from '../common/zIndices';
import PigeItInputWithHorizontalSelectAndListSelect from './PigeItInputWithHorizontalSelectAndListSelect.component';
import PigeItSelect from "./PigeItSelect.component";
import IMAGES from '../common/images';
import STYLE from '../common/style';

function PigeItPackages_MoreOptionsComponent(props) {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const SIGNATURE_OPTIONS = [{ text: 'Choose', value: '' }, { text: '01', value: '01' }, { text: '02', value: '02' } ];
  const [ selectedSignature, setSelectedSignature] = useState(SIGNATURE_OPTIONS[0]);

  return (
    <PigeIt_Packages_MoreOptionsStyle className="my-account-container" ref={containerRef}>
      <div >
        <div className="mobile-component bottom-banner">

          <div className="contniue-button-container">
            <Button >  CONTINUE </Button>
          </div>
        </div>
        <div className="header-container">
          <Button className="modal-close-button" onClick={close}>
            <IMAGES.close />
          </Button>
          <div>
            <IMAGES.logo className="active-logo" />
          </div>
          <form>
            <div className="background-container background-container-mobile">
              <div className="text-top">Declare Content for Insurance Purposes</div>
              <div className="top">
                {[1, 2, 3].map((item, i) => {
                  return (
                    <div key={i} className="input-container display-flex">
                      <label className="label">{"Package " + (i+1)}</label>
                      <input className="input" placeholder={"List the content of a package here"} />
                      <div >
                        <PigeItInputWithHorizontalSelectAndListSelect className="value-input" showHorizontalSelect={true} options={['$', '€']} />
                      </div>
                    </div>)
                })}
              </div>
            </div>
            <div className="bottom display-flex fjc-space-between">
              <div className="left-background-container">
                <div className="text-bottom">Signature Opitions</div>
                <PigeItSelect className="select-signature-opitions" name="signature-opitions" valueList={SIGNATURE_OPTIONS} value={selectedSignature} onChange={(value) => { setSelectedSignature(value); }} />
              </div>
              <div className="desktop-component right-background-container">
                <div className="text-bottom">Coupon Code</div>
                <input className="input-CouponCode"/>
              </div>
            </div>
            <div className="display-flex  fjs-center buttons-container ">
            <div className="place-holder" />
              <div className="desktop-component contniue-button-container">
                <Button >  CONTINUE </Button>
              </div>
              <button className="desktop-component cancel-button">CANCEL</button>
              
            </div>

          </form>

        </div>
      </div>
    </PigeIt_Packages_MoreOptionsStyle>
  );
  function close() {
    dispatch(modalQueue({ mode: 'delete' }));
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const PigeIt_Packages_MoreOptionsStyle = styled.div`
  svg.active-logo {
    ${STYLE.activeLogo}
  }
  background-color: ${Colors.white};
  height: 100%;
  text-align: center;
  overflow: auto;
  .modal-close-button {
    position: absolute;
    top: 21px;
    right: 26px;
  }
  .background-container{
    background-color: ${Colors.lightBlue};
    margin:auto;
    border-radius: 3px 3px 3px 3px;
    width:870px;
    padding-bottom: 40px;
    margin-top: 40px;
  }
  .text-top{
    text-align: left;
    margin-left: 25px;
    padding-top: 24px;
    margin-bottom: 40px;
   
  }
  .input-container{
    margin:6px 0 6px 0;
    
  }
  .input{
    height: 36px;
    width: 545px;
    font-family: museo_sans300;
    border-radius: 3px 3px 3px 3px;
    border: solid 1px #C8D9E2;
    font-size: 14px;
    color:#323232;
    padding-left:5px;
   
  }
  .input::placeholder{
    color:#9DAAB1;
    font-size: 14px;
    font-family: museo_sans300;
  }
  .value-input{
    width: 150px;
    height: 40px;
    margin: 0 27px 0 10px;
  }
 
  .label{
    margin: 0px 27px 0 25px ;
    font-size: 16px;
    font-family: museo_sans300;
    color:#70777D;
    line-height:40px;
    width: 80px;
  }
  .bottom{
    margin:auto;
    margin-top:19px;
    width:870px;
  }
  .text-bottom{
      text-align: left;
      margin-left: 25px;
      padding-top: 24px;
      
  }
  .left-background-container{
    flex: 0 0 49%;
    background-color: ${Colors.lightBlue};
      border-radius: 3px 3px 3px 3px;
  }
  .right-background-container{
    flex: 0 0 49%;
    background-color: ${Colors.lightBlue};
      border-radius: 3px 3px 3px 3px;
  }
  .input-CouponCode{
    height: 36px;
    width: 370px;
    margin: 19px 25px 27px 25px;
    font-family: museo_sans300;
    border-radius: 3px 3px 3px 3px;
    border: solid 1px #C8D9E2;
    font-size: 14px;
    color:#323232;
    padding-left:5px;
  }
  .select-signature-opitions{
    background-color: ${Colors.white};
    width: 370px;
    margin: 19px 25px 27px 25px; 
    border-radius: 3px 3px 3px 3px;
  }
  .buttons-container{
    margin-top:19px;
    margin-bottom: 40px;
  
  }
  .contniue-button-container {
    BUTTON {
      padding-left: 47px;
      padding-right: 47px;
      font-family: museo_sans700,sans-serif;
      font-size: 14px;
      max-width: 230px;
     
      height: 56px;
      color: ${Colors.white};
      background-color: ${Colors.orange};
    }
  }
  .bottom-banner{
      width: 100%;
      position: fixed;
      background: white;
      z-index: ${ZIndices.packagesMoreOptions};
      bottom: 0;
  }
  .cancel-button {
    font-size: 14px;
    background-color: transparent;
    border: none;
    color: #2699FB;
    font-family: museo_sans500;
    outline:none;
    cursor: pointer;
    margin-left: 20px;
    
  }
  .place-holder{
    width: 87px;
  }

  .header-container {
    z-index: ${ZIndices.myProfileHeader};
    width: 100%;
    padding-top: 25px;
    background: ${Colors.white};
    
  }
  .edit-package-text {
    font-size: 36px;
    font-family:museo_sans500;
    margin-bottom: 20px;
  }
  .menu-listing {
    margin-bottom: 10px;
  }
  .menu-item {
    margin: 0 15px 0 5px;
    font-size: 16px;
    color: ${Colors.black};
    &.active {
      color: ${Colors.blue1};
    }
  }
  .border-component {
    background: white;
    height: 30px;
    background-image: linear-gradient(#F2F2F2, #fff);
  }
  .content-container {
    padding-top: 198px;
  }
  .active-item-container {
    margin: 0 auto;
    width: 850px;
  }
  
  @media only screen and (max-width: 992px) {
    .background-container-mobile{
      margin:30.67px 10px;
    }
    .background-container{
      width:unset;
    }
    .active-item-container {
      width: auto;
    }  
    .display-flex {
      flex-flow: column nowrap;
    }
    .input {
      width: 310px;
      margin-left: 10px;
      margin-bottom: 10px;
    }
    .select-signature-opitions{
      margin: 5px 2px 17px 0;
      width: 310px;
      margin-left: 10px;
    }
    .contniue-button-container {
      BUTTON {
        min-width: 80%;
      }
    }
  }
`;


const PigeItPackages_MoreOptions = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItPackages_MoreOptionsComponent);


export default PigeItPackages_MoreOptions;
