import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { Colors } from '../common/colors';
import PigeItHorizontalSelect from './PigeItHorizontalSelect.component';
import IMAGES from '../common/images';
import { get } from 'lodash';
import {  navigateToPath, isUserLoggedIn } from "../common/functions";
import CONSTANTS from '../common/constants';
import { modalQueue } from "../redux/actions";
import PigeItInput from './PigeItInput.component';

function PigeItInputWithHorizontalSelectAndListSelectComponent(props) {
  const dispatch = useDispatch();
  const [ isOpen, setIsOpen ] = useState(false);
  const [ fieldValue, setFieldValue ] = useState(props.initialInputValue);
  return (
    <PigeItInputWithHorizontalSelectAndListSelectStyle className={'input-container ' + props.className + (isOpen ? ' select-opened' : '') + (props.showListSelect ? ' with-select-list' : '') + (props.showHorizontalSelect ? ' with-horizontal-select' : '')+(props.disabled ? ' disabled' : '' )}>
      <PigeItInput tabIndex={props.passedTabIndex}  label='Weight' type="number" min={props.min} max={props.max} value={props.initialInputValue} onChange={(e) => { setFieldValue(e.target.value); if (props.onChange) { props.onChange(COMPONENT_CONSTANTS.INPUT_FIELD, e.target.value); } }} id={props.inputId} className={"input-field" + (props.showListSelect ? ' with-select-list' : '') + (props.showHorizontalSelect ? ' with-horizontal-select' : '')} name={props.inputName} placeholder={props.inputPlaceholder} disabled={props.disabled} />
      {props.showListSelect && (<button type='button' tabIndex={props.passedTabIndex}  className={"input-select-from-list-button" + (!props.showHorizontalSelect ? ' no-horizontal-select' : '')} onClick={selectFromList}>
        <span className="input-select-from-list-button-icon-container">
          <IMAGES.selectFromList />
        </span>
      </button>)}
      {props.showHorizontalSelect && (<PigeItHorizontalSelect className={props.className} options={props.options ? props.options : null} initialValue={props.initialHorizontalSelectValue} tabIndex={props.passedTabIndex} onToggleView={onToggleView} onChange={(value) => { onHorizontalSelect(value); } }></PigeItHorizontalSelect>)}
    </PigeItInputWithHorizontalSelectAndListSelectStyle>
  );

  function onHorizontalSelect(value) {
    setIsOpen(false);
    if (props.onChange) {
      props.onChange(COMPONENT_CONSTANTS.HORIZONTAL_SELECT, value);
    }
  }

  function onToggleView(isOpen) {
    setIsOpen(isOpen);
  }

  function selectFromList() {
    if (isUserLoggedIn(get(props, 'apiData_getInitialData', {}))) {
      if (props.onSelectFromList) {
        props.onSelectFromList();
      }
    }
    else {
      let modalConfig = {
        ...CONSTANTS.MODALS_CONFIG.MESSAGE,
        buttons: {ok: {visible: true, text: 'SIGN IN'}, cancel: {visible: false, text: 'CANCEL'}},
        message: 'This feature is available for registered users, if you want to enjoy this feature and more please register for free'
      };
      modalConfig.callback = function (res) {
        dispatch(modalQueue({mode: 'delete'}));
        if (res.confirm) {
          navigateToPath('sign-in', true);
        }
      };
      dispatch(modalQueue({mode: 'insert', modalConfig: modalConfig}));
    }
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    apiData_getInitialData: state.data.api.getInitialData
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const PigeItInputWithHorizontalSelectAndListSelectStyle = styled.div`
  position: relative;
  &.select-opened {
    .input-field {
      &.with-horizontal-select {
        padding: 0 118px 0 10px;
      }
      &.with-horizontal-select.with-select-list {
        padding: 0 148px 0 10px;
      }
    }
    .input-select-from-list-button {
      right: 118px;
    }
  }
  .input-field {
    &.with-horizontal-select {
      padding: 0 68px 0 10px;
    }
    &.with-select-list {
      padding: 0 40px 0 10px;
    }
    &.with-horizontal-select.with-select-list {
      padding: 0 98px 0 10px;
    }
    text-align: center;
    background-color: #fff;
    border: 1px solid #c8d9e2;
    border-radius: 3px;
    display: block;
    box-sizing: border-box;
    width: 100%;
    font-size: 18px;
    line-height: 38px;
    height: 40px;
    
    color: ${Colors.black};
  }
  .input-select-from-list-button {
    &.no-horizontal-select {
      right: 0px;
    }
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    top: 0;
    height: 40px;
    width: 36px;
    padding: 2px 0 0;
    right: 60px;
  }
  &.disabled{
    input{
      background-color: #F4F8FB;
    }
    svg{
      path{
        fill: #F4F8FB !important;
      }
    }
    span{
      color: #F4F8FB;
    }
  }
`;

const PigeItInputWithHorizontalSelectAndListSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItInputWithHorizontalSelectAndListSelectComponent);

export const COMPONENT_CONSTANTS = {
  INPUT_FIELD: 'input',
  HORIZONTAL_SELECT: 'horizontalSelect'
};
export default PigeItInputWithHorizontalSelectAndListSelect;
