import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Colors } from '../common/colors';
import IMAGES from '../common/images';
import { get } from 'lodash';
import { attachEvent, detachEvent, isDescendant } from '../common/functions';
import { Button, TextField } from '@material-ui/core';

function PigeItInputComponent(props) {
  
  return (
    <PigeItInputStyle style={{ ...props.style }} className={props.className+(props.multiline?' multiline':'')+(props.disabled?' disabled':'')}>
      <TextField
        inputRef={props.inputRef}
        error={props.error}
        type={props.type}
        placeholder={props.placeholder}
        id={props.id}
        name={props.name}
        multiline={props.multiline}
        disabled={props.disabled}
        fullWidth
        autoFocus={props.autoFocus}
        label={props.label}
        variant="outlined"
        value={props.value}
        onChange={(e) => { if (typeof props.onChange === 'function') props.onChange(e) }} />
    </PigeItInputStyle>
  );


}

const mapStateToProps = (state, ownProps) => {
  return {
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: ownProps.onChange
  }
};

const PigeItInputStyle = styled.div`
&.multiline{
  .MuiOutlinedInput-notchedOutline{
   height:unset;
 }
}
&.disabled{
  .MuiInputLabel-outlined {
    background-color: #f3f3f3;
  }
}
 .MuiOutlinedInput-notchedOutline{
   height:40px;
 }
 .MuiInputBase-input:disabled{
   background-color:#f3f3f3;
 }
 .MuiInputBase-input{
  padding: 9.5px 14px;
  font-family: 'museo_sans500';
  color: #5a5a5a;
  background-color:#fff;
   }
   .MuiFormControl-root{
     width:100%;
   }
 .MuiFormLabel-root{
  &.Mui-error{
    color:${Colors.error};
  }
  &.Mui-focused{
    color:${Colors.blue1};
  }
 }
 .MuiInputLabel-outlined {
   &.MuiInputLabel-shrink{
    transform: translate(11px, -6px) scale(0.75);
    background-color: rgba(0, 0, 0, 0);;
   }
  transform: translate(8px, 10px) scale(1);
    overflow: hidden;
    white-space: nowrap;
    line-height: 1.2;
    font-size: 14px;
    font-family: 'museo_sans500';
    color: #9daab1;
    background-color: rgba(0, 0, 0, 0);;
 }
 .MuiOutlinedInput-root{
  &.Mui-error{
    .MuiOutlinedInput-notchedOutline{
      border-color: ${Colors.error};
    }
  }
}
 .MuiOutlinedInput-root{
  &.Mui-focused{
    .MuiOutlinedInput-notchedOutline{
      border-color:${Colors.blue1};
      border-width:1px;
    }
  }
 }
`;

const PigeItInput = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItInputComponent);

export default PigeItInput;
