import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Colors } from '../common/colors';
import { Button } from '@material-ui/core';
import IMAGES from '../common/images';
import PigeItHorizontalSelect from './PigeItHorizontalSelect.component';
import PigeItInput from './PigeItInput.component';
import {get} from 'lodash';

function MainPageInputPackageTypeBasicComponent(props) {
  let [focusedRadio, setFocusedRadio] = useState('');
  let [selectedValue, setSelectedValue] = useState('');
  const selectedPackageType = props.selectedPackageType || {
    id: 1,
    customDimensions: true,
    dimensionsWidth: '0', // value will be saved in redux
    dimensionsHeight: '0', // value will be saved in redux
    dimensionsLength: '0', // value will be saved in redux
    baseDescription: 'Custom Package',
    dimensionsDescription: 'Custom',
    selectionDisplayTitle: 'My Custom',
    dimensionsUnits: 'in',
    checkboxState: false
  };

  return (<>
    {selectedPackageType.id === 1 && !props.canNotEdit && <>
      <PigeItInput type='number' error={get(props,'errors.length',false)} className='input-field' value={selectedPackageType.dimensionsLength} onChange={(e) => onChangeValue('length', e.target.value)} label='Length' height={'40px'} />
      <PigeItInput type='number' error={get(props,'errors.width',false)} className='input-field' value={selectedPackageType.dimensionsWidth} onChange={(e) => onChangeValue('width', e.target.value)} label='Width' height={'40px'} />
      <PigeItInput type='number' error={get(props,'errors.height',false)} className='input-field' value={selectedPackageType.dimensionsHeight} onChange={(e) => onChangeValue('height', e.target.value)} label='Height' height={'40px'} />
    </>}
    {(selectedPackageType.id !== 1 || props.canNotEdit) &&
      <MainPageInputPackageTypeBasicStyle className={props.className}>
        <div className="display-flex fjc-space-between selected-package-and-edit-container">
          <div className="selected-package-container">
            <div>{selectedPackageType.selectionDisplayTitle}</div>
            <div className="dimensions-text">{selectedPackageType.selectionDisplayDimensions}</div>
          </div>
          <div className="edit-button-container">
            <Button className="edit-button" onClick={() => { if (props.onEditClick) { props.onEditClick(); } }}><IMAGES.edit /></Button>
          </div>
        </div>
      </MainPageInputPackageTypeBasicStyle>
    }</>
  );

  function onClick(value) {
    if (props.onClick) {
      props.onClick(value);
    }
  }
  function onChangeValue(field, value) {
    let newPackage = null;
    switch (field) {
      case 'width':
        if (!isNaN(value)) {
          newPackage = { ...selectedPackageType, dimensionsWidth: value };
        }
        break;
      case 'height':
        if (!isNaN(value)) {
          newPackage = { ...selectedPackageType, dimensionsHeight: value };
        }
        break;
      case 'length':
        if (!isNaN(value)) {
          newPackage = { ...selectedPackageType, dimensionsLength: value };
        }
        break;
      case 'units':
        newPackage = { ...selectedPackageType, dimensionsUnits: value };
        break;
    }
    if (props.onChange && newPackage) {
      let text = newPackage.dimensionsWidth + '" x ' + newPackage.dimensionsHeight + '" x ' + newPackage.dimensionsLength + '"';
      props.onChange({ ...newPackage, selectionDisplayDimensions: text });
    }
  }
  function onInputFocusOrBlur(eventType, evt) {
    if (eventType === 'focus' && evt.target.className.indexOf('envelope-input') !== -1) {
      setFocusedRadio('envelope');
    }
    else if (eventType === 'focus' && evt.target.className.indexOf('box-input') !== -1) {
      setFocusedRadio('box');
    }
    else {
      setFocusedRadio('');
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    state: state
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const MainPageInputPackageTypeBasicStyle = styled.div`
  position: relative;
  .custom-package-container{
    .space-between-div{
      width: 10%;
    }
    display: flex;
    width: 100%;
    height: 38.5px;
    justify-content: space-between;
    .input-field{
      width: 100%;
      min-width:0; /*firefox flex bug fix*/
    }
    .input-field::placeholder{
      font-size: 14px;
      color: #9daab1;
    }
    .package-type-select-weight-unit{
      width: 58px;
      display: none;
    }
  }
  .focused {
    outline: 2px solid ${Colors.blue1};
  }
  .envelope-label {
    margin-left: 16px;
  }
  .box-label {
    margin-right: 16px;
  }
  .envelope-label, .box-label {
    display: inline-block;
    width: 80px;
  }
  .envelope-input, .box-input {
    /*visibility: hidden;*/
    width: 0;
    height: 0;
    top: 0;
    position: absolute;
  }
  .envelope-radio-icon {
    position: relative;
    top: 2px;
  }
  .box-radio-icon {
    position: relative;
    top: 2px;
  }
  .envelope-icon {
    position: relative;
    top: -3px;
    left: 12px;
  }
  .box-icon {
    position: relative;
    top: 3px;
    left: 8px;
  }
  .inputs-seperator {
    display: inline-block;
    width: 25px;
  }
  .selected-package-and-edit-container {
    border-radius: 3px;
    width: 100%;
    padding: 4.5px;
    border: 1px solid #c5c5c5;
    font-size: 13px;
    background-color: white;
  }
  .selected-package-container {
    color: ${Colors.black};
  }
  .dimensions-text {
    font-family: museo_sans300,sans-serif;
    font-size: 10px;
  }
  .edit-button {
    min-width: auto;
    width: 30px;
  }
`;

const MainPageInputPackageTypeBasic = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainPageInputPackageTypeBasicComponent);

export default MainPageInputPackageTypeBasic;
