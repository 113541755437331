import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { Colors } from '../common/colors';
import IMAGES from '../common/images';
import { get } from 'lodash';
import { attachEvent, detachEvent, isDescendant, showMessage } from '../common/functions';
import { Button, TextField } from '@material-ui/core';
import CONSTANTS from '../common/constants';
import { api_validate_coupon, genericSetValue } from '../redux/actions';

function PigeItCouponInputComponent(props) {
  console.log(props);
  const dispatch = useDispatch();
  const [code, setcode] = useState('');
  const [loading, setloading] = useState(false);
  const onAddCoupon = useCallback(
    () => {
      setloading(true);
      dispatch(api_validate_coupon({
        code, callback: (result) => {
          setloading(false);
          if (result.success) {
            if (props.msr)
              dispatch(genericSetValue({ path: 'ui.business.coupon', value: { code, amount: result.amount } }));
            else
              dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress.coupon', value: { code, amount: result.amount } }));

            showMessage(`You got a discount of ${result.amount}$`, dispatch);
            setcode('')
          } else {
            showMessage(`Invalid Coupon. Make sure you are logged in.`, dispatch);
          }
        }
      }))
    },
    [code, setloading, dispatch],
  )

  return (
    <PigeItCouponInputStyle style={{ ...props.style }} className={props.className + (props.multiline ? ' multiline' : '') + (props.disabled ? ' disabled' : '')}>
      <TextField
        error={props.error}
        type={props.type}
        placeholder="Coupon Code"
        id={props.id}
        name={props.name}
        multiline={props.multiline}
        disabled={props.disabled}
        fullWidth
        autoFocus={props.autoFocus}
        label={props.label}
        variant="outlined"
        value={code}
        onChange={(e) => setcode(e.target.value)} />
      <Button onClick={onAddCoupon} disabled={!code.match(/^SW-\d{6}$/)}>{loading ? <img src={CONSTANTS.PUBLIC_URL + "/images/loader.gif"} className={props.className ? props.className : "loader-16x16"} alt="" /> : 'Add Coupon'}</Button>
    </PigeItCouponInputStyle>
  );


}

const mapStateToProps = (state, ownProps) => {
  return {
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: ownProps.onChange
  }
};

const PigeItCouponInputStyle = styled.div`
display: flex;
align-items: center;
margin: 0 20px;
.MuiButtonBase-root{
  white-space: nowrap;
  background-color: #fcb02e;
  color: #fff;
  padding: 6.3px 18px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 37px;
  width: 118px;
  &.Mui-disabled{
    background-color:#B0BCC2;
    color: #fff;
  }
}
&.multiline{
  .MuiOutlinedInput-notchedOutline{
   height:unset;
 }
}
&.disabled{
  .MuiInputLabel-outlined {
    background-color: #f3f3f3;
  }
}
 .MuiOutlinedInput-notchedOutline{
   height:40px;
 }
 .MuiInputBase-input:disabled{
   background-color:#f3f3f3;
 }
 .MuiInputBase-input{
  padding: 9.5px 14px;
  font-family: 'museo_sans500';
  color: #5a5a5a;
  background-color:#fff;
   }
   .MuiFormControl-root{
     width:140px;
   }
 .MuiFormLabel-root{
  &.Mui-error{
    color:${Colors.error};
  }
  &.Mui-focused{
    color:${Colors.blue1};
  }
 }
 .MuiInputLabel-outlined {
   &.MuiInputLabel-shrink{
    transform: translate(11px, -6px) scale(0.75);
    background-color: rgba(0, 0, 0, 0);;
   }
  transform: translate(8px, 10px) scale(1);
    overflow: hidden;
    white-space: nowrap;
    line-height: 1.2;
    font-size: 14px;
    font-family: 'museo_sans500';
    color: #9daab1;
    background-color: rgba(0, 0, 0, 0);;
 }
 .MuiOutlinedInput-root{
  &.Mui-error{
    .MuiOutlinedInput-notchedOutline{
      border-color: ${Colors.error};
    }
  }
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 .MuiOutlinedInput-root{
  &.Mui-focused{
    .MuiOutlinedInput-notchedOutline{
      border-color:${Colors.blue1};
      border-width:1px;
    }
  }
 }
`;

const PigeItCouponInput = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItCouponInputComponent);

export default PigeItCouponInput;
