import React, { useRef, useState, useMemo } from "react";
import styled from "styled-components";
import { connect, useDispatch } from "react-redux";
import { modalQueue, api_login, api_getInitialData } from "../redux/actions";
import { Button, TextField, Link } from "@material-ui/core";
import { Colors } from "../common/colors";
import CONSTANTS from "../common/constants";
import { get } from "lodash";
import PigeItCheckbox from "./PigeItCheckbox.component";
import Loader from "./Loader.component";
import IMAGES from "../common/images";
import {
  identifyCreditCardType,
  convertPackageIdtoPackage,
} from "../common/functions";
import moment from "moment";

function PigeItShipmentInfoComponent(props) {
  const dispatch = useDispatch();
  const packages = get(props, "modalConfig.shipment", null);
  var cardType = get(packages, "paymentDetails.cardType", null);
  let CardIcon = cardType
    ? IMAGES[identifyCreditCardType(null, cardType.toLowerCase())]
    : null;

  return (
    <PigeItShipmentInfoStyle className="oginl-container">
      <div className="background-container">
        <div className="content-container">
          <Button className="modal-close-button" onClick={close}>
            <IMAGES.close />
          </Button>

          <div className="header-text">Shipment Info</div>
          <div className="title-text">
            Total Price &nbsp;
            {"$" + get(packages, "paymentDetails.amount", "0")}
          </div>
          <div className="title-text sub">
            Create Time: &nbsp;
            {moment(get(packages, "createTime", "")).format("MM/DD/YYYY HH:mm")}
          </div>

          <hr className="seperator" />
          <div className="main-container display-flex fjc-space-between">
            <div className="info-container">
              <div className="info-title">Sender</div>
              <div className="info-text">
                {" "}
                {get(packages, "shipperAddress", "")}
              </div>
              <div className="info-text">
                {" "}
                {get(packages, "shipperName", "")}
              </div>
              <div className="info-text">
                {" "}
                {get(packages, "shipperPhone", "")}
              </div>
            </div>
            <div className="info-container">
              <div className="info-title">Receiver</div>
              <div className="info-text">
                {" "}
                {get(packages, "recipientAddress", "")}
              </div>
              <div className="info-text">
                {" "}
                {get(packages, "recipientName", "")}
              </div>
              <div className="info-text">
                {" "}
                {get(packages, "recipientPhone", "")}
              </div>
            </div>
            <div className="info-container">
              <div className="info-title card">Billing Info</div>
              <div className="display-flex row">
                <div className="info-icon card">
                  {" "}
                  {CardIcon && <CardIcon />}
                </div>
                <div className="info-text card">
                  {" "}
                  {get(packages, "paymentDetails.last4Digits", "")}
                </div>
              </div>
              <div className="info-text">
                {" "}
                {get(packages, "paymentDetails.email", "")}
              </div>
            </div>
            <div className="info-container">
              <div className="info-title">Service</div>
              <div className="display-flex row">
                <div className="info-text">Shipped with</div>
                <div className="info-icon company">
                  {" "}
                  {get(packages, "shippingCompanyId", "1") === "1" ? (
                    <IMAGES.fedex />
                  ) : (
                    <IMAGES.ups />
                  )}
                </div>
              </div>
              <div className="info-text">
                {" "}
                {get(packages, "shippingDropoffTypeId", "1") === "1"
                  ? "Drop off"
                  : "Pick up"}
              </div>
            </div>
          </div>
          <hr className="seperator" />
          <div className="title-text">Packages Information</div>
          <div className="packages-scroll">
            {get(packages, "packagesDetails", []).map((item, i) => {
              const pack = convertPackageIdtoPackage(item.packagingType);
              return (
                <div key={i} className="package-container display-flex">
                  <div className="package-text id-tag">{"#" + (i + 1)}</div>
                  <div className="package-text">
                    {get(pack, "selectionDisplayTitle", "Custom Package")}
                  </div>
                  <div className="package-text">
                    {parseInt(item.packagingType) === 1
                      ? item.sizeWidth +
                        '" x ' +
                        item.sizeHeight +
                        '" x ' +
                        item.sizeLength +
                        '" ' +
                        (parseInt(item.sizeUnitId) === 1 ? "IN" : "CM")
                      : get(pack, "selectionDisplayDimensions", "")}
                  </div>
                  <div className="package-text">
                    {get(item, "weight", "0") +
                      (parseInt(item.weightUnitId) === 1 ? " LBS" : " KILO")}
                  </div>
                  <div className="package-text">
                    {"Tracking Number: "}
                    <a
                      target="_blank"
                      href={getTrackingNumber(
                        get(
                          packages,
                          "shippingDetails[" + i + "].packageTrackingNumber",
                          ""
                        )
                      )}
                    >
                      {get(
                        packages,
                        "shippingDetails[" + i + "].packageTrackingNumber",
                        ""
                      )}
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
          <button
            className="get-all-labels"
            onClick={() =>
              window.open(
                CONSTANTS.API_ROOT +
                  `api/get-shipment-label-as-file/?transactionId=${get(
                    packages,
                    "transactionId"
                  )}`,
                "_blank"
              )
            }
          >
            Print Labels
          </button>
        </div>
      </div>
    </PigeItShipmentInfoStyle>
  );

  function getTrackingNumber(packageTrackingNumber) {
    if (packageTrackingNumber === "") {
      return null;
    }
    if (isNaN(packageTrackingNumber)) {
      return (
        "https://wwwapps.ups.com/tracking/tracking.cgi?tracknum=" +
        packageTrackingNumber
      );
    } else {
      return (
        "https://www.fedex.com/apps/fedextrack/?trackingnumber=" +
        packageTrackingNumber
      );
    }
  }

  function close() {
    dispatch(modalQueue({ mode: "delete" }));
  }
}
const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

const PigeItShipmentInfoStyle = styled.div`
  width: 100%;
  height: 100%;
  .get-all-labels {
    display: block;
    margin: auto;
    margin-bottom: 10px;
    margin-top: 36px;
    padding: 14px 20px;
    font-size: 20px;
    background: ${Colors.orange};
    border: none;
    border-radius: 3px;
    color: white;
    cursor: pointer;
    outline: none;
    &:hover {
      background-color: ${Colors.orangeHover};
    }
  }
  .background-container {
    height: 100%;
    width: 100%;
    background-color: #fff;
    margin: 0 auto;
    overflow: auto;
  }
  .content-container {
    padding-top: 45px;
    padding-right: 65px;
    padding-left: 65px;
  }
  .modal-close-button {
    position: absolute;
    top: 21px;
    right: 26px;
  }
  .header-text {
    font-size: 36px;
    text-align: center;
  }
  .title-text {
    margin: 15px;
    font-size: 18px;
    text-align: center;
    color: ${Colors.lightGray6};
    &.sub {
      font-size: 14px;
    }
  }
  .seperator {
    border: none;
    border-bottom: solid 1px lightgray;
  }
  .packages-scroll {
    max-height: 330px;
    overflow-y: scroll;
    border: solid 1px #ece9e9;
    border-radius: 3px;
  }
  .package-container {
    border: solid 1px #ece9e9;
    font-family: museo_sans300;
    padding: 10px;
    border-radius: 3px;
    margin: 5px;
    .package-text {
      &.id-tag {
        font-family: museo_sans500;
      }
      margin-left: 20px;
    }
  }
  .info-container {
    padding: 10px;
    font-size: 14px;
    font-family: museo_sans300;
    .info-title {
      font-family: museo_sans500;
      font-size: 16px;
      color: ${Colors.lightGray6};
      padding-bottom: 5px;
      &.card {
        padding-bottom: 0;
      }
    }
    .info-text {
      &.card {
        margin-top: 12px;
      }
    }
    .info-icon {
      &.card {
        margin-left: -6px;
        svg {
          height: 37px;
        }
      }
      &.company {
        margin-left: 10px;
        margin-top: -6px;
        svg {
          height: 20px;
          path {
            fill: ${Colors.lightGray6};
          }
        }
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .display-flex {
      flex-flow: column nowrap;
      &.row {
        flex-direction: row;
      }
    }
    .content-container {
      padding: 51px 32px;
    }
    .package-container {
      padding: 12px 0px;
      .package-text {
        margin-left: 10px;
      }
    }
  }
`;

const PigeItShipmentInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItShipmentInfoComponent);

export default PigeItShipmentInfo;
