/* eslint react/jsx-pascal-case: off, react-hooks/exhaustive-deps: off */
import React, { useEffect, useState } from 'react';
import { useRoutes,useRedirect } from 'hookrouter';
import styled from 'styled-components';
import MainPage from './components/MainPage.component';
import ContactUs from './components/ContactUs.component';
import Information from './components/Information.component';
import PigeItModal from './components/PigeItModal.component';
import Loader from "./components/Loader.component";
import { useDispatch, connect, useStore } from 'react-redux';
import { api_getInitialData,api_column_mapping_get_list, modalQueue, setDisplayMode,api_getShippingHistory,api_businessReviews_getVerified,api_businessReviews_getVerifiedImage,api_addressBook_getList,api_creditcard_getList,api_packages_getList, api_getShippingHistoryMsr, googleAutoCompleteInit } from './redux/actions';
import PigeItLogin from "./components/PigeItLogin.component";
import {isInArray, attachEvent, detachEvent, isDescendant, setShipperDetails, getQueryParam} from './common/functions';
import PigeItRegister from "./components/PigeItRegister.component";
import PigeItMyAccount from "./components/PigeItMyAccount.component";
import PigeItMyAccount_AddressBook_AddEdit from './components/PigeItMyAccount_AddressBook_AddEdit.component';
import PigeItPackageTypeSelect_Package from "./components/PigeItPackageTypeSelect_Package.component";
import PigeItMyAccount_CreditCard_AddEdit from './components/PigeItMyAccount_CreditCard_AddEdit.component';
import PigeItMyAccount_Packages_AddEdit from './components/PigeItMyAccount_Packages_AddEdit.component';
import PigeItPackages_AddEdit from './components/PigeItPackages_AddEdit.component';
import PigeItThankYou from './components/PigeItThankYou.component';
import PigeItThankYou_ShowPackages from './components/PigeItThankYou_ShowPackages.component';
import PigeItChoosePickUpTime from './components/PigeItChoosePickUpTime.component';
import PigeItCardHoldersDetails from './components/PigeItCardHoldersDetails.component';
import PigeItPackages_MoreOptions from './components/PigeItPackages_MoreOptions.component';
import PigeItMessage from './components/PigeItMessage.component';
import PigeItShipmentInfo from './components/PigeItShipmentInfo.component';
import PigeItShipmentInfoMsr from './components/PigeItShipmentInfoMsr.component';
import PigeItForgotPasswordEnterEmail from './components/PigeItForgotPasswordEnterEmail.component';
import PigeitVideo from './components/PigeItVideo.component';
import TrackYourShipment from './components/TrackYourShipment.component';
import PigeItEnterNewPassword from './components/PigeItEnterNewPassword.component';
import PigeitActivate from './components/PigeItActivate.component';
import Blog from './components/Blog.component';
import PackageDetails from './components/PackageDetails.component';
import IMAGES from './common/images';
import CONSTANTS from './common/constants';
import { get } from 'lodash';
import { navigateToPath } from "./common/functions";
import {PACKAGE_DETAILS_CONTENT} from './common/content';
import PigeitLoader from './components/PigeitLoader.component';
import MultiplePackages from './components/MultiplePackages.component';
import PigeItSaveMapping from './components/PigeItSaveMapping.component';
import PigeItUploadCSV from './components/PigeItUploadCSV.component';
import PigeItBusinessesAddEditPackage from './components/PigeItBusinessesAddEditPackage.component';


let path = CONSTANTS.PUBLIC_URL;
path = path + (path.substring(path.length - 1) !== '/' ? '/' : '');

const CITIES = [['Florida','New York']];

function AppComponent(props) {
  const dispatch = useDispatch();
  const store = useStore();
  useRedirect(path+'my-account',path+'my-account/personal-info')
  const [ isVisible, setIsVisible ] = useState(false);
  let routes = {
    [path]: () => {
      props.activeModalQueue.forEach((item) => {
        if (item.url) {
          dispatch(modalQueue({ mode: 'delete', modalConfig: item }));
        }
      });
      return <MainPage stage={1}/>
    },
    [path+'businesses*']: () => {
      return <MultiplePackages/>
    },
    [path+'ship-with-ups']: () => {
      props.activeModalQueue.forEach((item) => {
        if (item.url) {
          dispatch(modalQueue({ mode: 'delete', modalConfig: item }));
        }
      });
      return <MainPage stage={1} company={'UPS'}/>
    },
    [path+'ship-with-fedex']: () => {
      props.activeModalQueue.forEach((item) => {
        if (item.url) {
          dispatch(modalQueue({ mode: 'delete', modalConfig: item }));
        }
      });
      return <MainPage stage={1} company={'FedEx'}/>
    },
    [path+'stage-1']: () => <MainPage stage={1}/>,
    [path+'stage-2']: () => <MainPage stage={2}/>,
    [path+'stage-3']: () => <MainPage stage={3}/>,
    [path+'my-account/:tab']: ({tab}) => {
      const modalConfig = JSON.parse(JSON.stringify(CONSTANTS.MODALS_CONFIG.MY_ACCOUNT));
      modalConfig.url = 'my-account/'+tab;
      modalConfig.initialTab = tab;
      props.activeModalQueue.forEach((item) => {
        if (item.url&&item.url!==('my-account/'+tab)) {
          dispatch(modalQueue({ mode: 'delete', modalConfig: item, deleteOther: true }));
        }
      });
      if(!props.activeModalQueue.some(item=>item.url===modalConfig.url)){
        dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
      }
      return <MainPage stage={get(props.purchaseProgress,'stage',1)}/>
    },
    [path+'sign-in']: () => {
      const modalConfig = JSON.parse(JSON.stringify(CONSTANTS.MODALS_CONFIG.LOGIN));
      modalConfig.url = 'sign-in';
      if(!props.activeModalQueue.some(item=>item.url===modalConfig.url)){
        dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
      }
      return <MainPage stage={get(props.purchaseProgress,'stage',1)}/>
    },
    [path+'register']: () => {
      const modalConfig = JSON.parse(JSON.stringify(CONSTANTS.MODALS_CONFIG.REGISTER));
      modalConfig.url = 'register';
      if(!props.activeModalQueue.some(item=>item.url===modalConfig.url)){
        dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
      }
      return <MainPage stage={get(props.purchaseProgress,'stage',1)}/>
    },
    [path+'video']: () => {
      const modalConfig = JSON.parse(JSON.stringify(CONSTANTS.MODALS_CONFIG.VIDEO));
      modalConfig.url = 'video';
      if(!props.activeModalQueue.some(item=>item.url===modalConfig.url)){
        dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
      }
      return <MainPage stage={get(props.purchaseProgress,'stage',1)}/>
    },
    [path + 'contact-us']: () => <ContactUs />,
    [path + 'track-your-shipment-:company']: ({company}) => <TrackYourShipment company={company}/>,
    [path + 'track-your-shipment']: () => <TrackYourShipment/>,
    [path + 'information*']: () => <Information />,
    [path + 'blogs*']: () => <Blog />,
    [path + 'package-details*']: () => <PackageDetails />
  };
  PACKAGE_DETAILS_CONTENT.companies.forEach(company=>company.packages.forEach(pack=>{
    routes[path + 'ship/'+company.path+'/'+pack.path] = () => <MainPage stage={1} pack={pack} company={company.title}/>
  }));

  // routes for debugging only
  if (CONSTANTS.DEBUGGING) {
    routes[path + 'icons'] = () => {
      // TODO - remove this
      let StyleWrapper = styled.div`
        svg { height: 40px; }
        svg:not(.custom-bg) { path { fill: black; } }
        td { text-align: center; }
      `;
      let keys = Object.keys(IMAGES);
      return <StyleWrapper>
        <table border={1}><tbody>
        {keys.map((imageKey) => {
          let iconToRender = typeof(IMAGES[imageKey]) == 'function' ? IMAGES[imageKey] : function() { return 'none'; };
          return <tr><td>{imageKey}</td><td><div className={imageKey} key={imageKey}>{iconToRender({className: imageKey})}</div></td></tr>;
        })}
        </tbody></table>
      </StyleWrapper>;
    };
  }
  CITIES.forEach(item=>routes[path+'ship-from-'+item[0].toLowerCase().split(' ').join('-')+'-to-'+item[1].toLowerCase().split(' ').join('-')]=()=>{
    props.activeModalQueue.forEach((item) => {
      if (item.url) {
        dispatch(modalQueue({ mode: 'delete', modalConfig: item }));
      }
    });
    return <MainPage stage={1} from={item[0]} to={item[1]} price={item[2]}/>
  });
  useEffect(() => {
    dispatch(googleAutoCompleteInit());
    window.dataLayer = window.dataLayer || [];
    const actionType = getQueryParam('action');
    switch (actionType) {
      case 'reset':
        const resetKeyParam = getQueryParam('resetKey');
        const userNameParam = getQueryParam('username');
        if (resetKeyParam.length > 0 && userNameParam.length > 0){
          let modalConfig = {
            ...CONSTANTS.MODALS_CONFIG.ENTER_NEW_PASSWORD
          };
          dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
        }
        break;
      case 'activate':
        const email = getQueryParam('email');
        const activation = getQueryParam('activation');
        let modalConfig = {...CONSTANTS.MODALS_CONFIG.ACTIVATE};
        modalConfig.activation = activation;
        modalConfig.email = email;
        dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
        break;
      default:
        break;
    }
    dispatch(api_getInitialData({
      callback : function(result){
        if(result.success&&result.user){
          setShipperDetails(store, dispatch);
          dispatch(api_addressBook_getList());
          dispatch(api_packages_getList());
          dispatch(api_creditcard_getList());
          dispatch(api_getShippingHistory());
          dispatch(api_getShippingHistoryMsr());
          dispatch(api_column_mapping_get_list());
        }
      }
    }));
    // get business reviews & their images
    dispatch(api_businessReviews_getVerified({
      callback: function(result) {
        if(result.success){
          get(result,'results',[]).forEach((resultItem)=>{
            dispatch(api_businessReviews_getVerifiedImage({ id: parseInt(resultItem.id) }));
          });
        }
      }
    }));
    resizeListener();
  }, []);

  useEffect(() => {

    // handle modal stuff
    if (props.activeModalQueue.length) {
      document.body.classList.add('modal-active');
      let method = 'remove';
      if (props.activeModalQueue[props.activeModalQueue.length - 1].isFullScreen || (props.displayMode === CONSTANTS.DISPLAY_MODES.MOBILE&&!props.activeModalQueue[props.activeModalQueue.length - 1].notFullScreenMobile)) {
        // if modal is full-screen or we're in mobile mode - set to full screen
        method = 'add';
      }
      document.body.classList[method]('full-screen-modal');
    }
    else {
      document.body.classList.remove('modal-active');
      document.body.classList.remove('full-screen-modal');
    }
    attachEvent(document, 'keydown', keydownEventListener);
    attachEvent(window, 'resize', resizeListener);

    // handle fade-in visibility
    setTimeout(function() {
      setIsVisible(true);
    }, 50);
    return function() {
      detachEvent(document, 'keydown', keydownEventListener);
      detachEvent(window, 'resize', resizeListener);
    }
  });

  const routeResult = useRoutes(routes);
  const defaultRoute = <MainPage />;
  const modalsCofigHash = CONSTANTS.MODALS_CONFIG;
  if (isVisible) {
    return (
      <AppStyle className={"main-app-wrapper fade-in"} onDrop={(e)=>e.preventDefault()} onDragOver={(e)=>e.preventDefault()}>
        { routeResult || defaultRoute }
        { isLoaderVisible() && <Loader /> }
        { isAnimatedLoaderVisible() && <PigeitLoader /> }
        {
            props.activeModalQueue.map(function(item, index) {
              switch (item.componentName) {
                  case modalsCofigHash.LOGIN.componentName:
                    return <PigeItModal key={index} isLast={index + 1 === props.activeModalQueue.length} config={item}><PigeItLogin modalConfig={item} modalIndex={index} /></PigeItModal>;
                  case modalsCofigHash.REGISTER.componentName:
                    return <PigeItModal key={index} isLast={index + 1 === props.activeModalQueue.length} config={item}><PigeItRegister modalConfig={item} modalIndex={index} /></PigeItModal>;
                  case modalsCofigHash.MY_ACCOUNT.componentName:
                    return <PigeItModal key={index} isLast={index + 1 === props.activeModalQueue.length} config={item}><PigeItMyAccount modalConfig={item} modalIndex={index} /></PigeItModal>;
                  case modalsCofigHash.PACKAGE_TYPE_SELECT_PACKAGE.componentName:
                    return <PigeItModal key={index} isLast={index + 1 === props.activeModalQueue.length} config={item}><PigeItPackageTypeSelect_Package modalConfig={item} modalIndex={index} /></PigeItModal>;
                  case modalsCofigHash.ADDRESS_BOOK_ADD_EDIT.componentName:
                    return <PigeItModal key={index} isLast={index + 1 === props.activeModalQueue.length} config={item}><PigeItMyAccount_AddressBook_AddEdit modalConfig={item} modalIndex={index} /></PigeItModal>;
                  case modalsCofigHash.CREDIT_CARDS_ADD_EDIT.componentName:
                    return <PigeItModal key={index} isLast={index + 1 === props.activeModalQueue.length} config={item}><PigeItMyAccount_CreditCard_AddEdit modalConfig={item} modalIndex={index} /></PigeItModal>;
                  case modalsCofigHash.PACKAGES_ADD_EDIT.componentName:
                    return <PigeItModal key={index} isLast={index + 1 === props.activeModalQueue.length} config={item}><PigeItMyAccount_Packages_AddEdit modalConfig={item} modalIndex={index} /></PigeItModal>;
                  case modalsCofigHash.ADD_EDIT_PACKAGE.componentName:
                    return <PigeItModal key={index} isLast={index + 1 === props.activeModalQueue.length} config={item}><PigeItPackages_AddEdit modalConfig={item} modalIndex={index} /></PigeItModal>;
                  case modalsCofigHash.THANK_YOU.componentName:
                    return <PigeItModal key={index} isLast={index + 1 === props.activeModalQueue.length} config={item}><PigeItThankYou modalConfig={item} modalIndex={index} /></PigeItModal>;
                  case modalsCofigHash.THANK_YOU_SHOW_PACKAGES.componentName:
                    return <PigeItModal key={index} isLast={index + 1 === props.activeModalQueue.length} config={item}><PigeItThankYou_ShowPackages modalConfig={item} modalIndex={index} /></PigeItModal>;
                  case modalsCofigHash.CHOOSE_PICK_UP_TIME.componentName:
                    return <PigeItModal key={index} isLast={index + 1 === props.activeModalQueue.length} config={item}><PigeItChoosePickUpTime modalConfig={item} modalIndex={index} /></PigeItModal>;
                  case modalsCofigHash.CARDHOLDER_DETAILS.componentName:
                    return <PigeItModal key={index} isLast={index + 1 === props.activeModalQueue.length} config={item}><PigeItCardHoldersDetails modalConfig={item} modalIndex={index} /></PigeItModal>;
                  case modalsCofigHash.PACKAGES_MORE_OPTIONS.componentName:
                    return <PigeItModal key={index} isLast={index + 1 === props.activeModalQueue.length} config={item}><PigeItPackages_MoreOptions modalConfig={item} modalIndex={index} /></PigeItModal>;
                  case modalsCofigHash.SHIPPING_INFO.componentName:
                    return <PigeItModal key={index} isLast={index + 1 === props.activeModalQueue.length} config={item}><PigeItShipmentInfo modalConfig={item} modalIndex={index} /></PigeItModal>;
                  case modalsCofigHash.SHIPPING_INFO_MSR.componentName:
                    return <PigeItModal key={index} isLast={index + 1 === props.activeModalQueue.length} config={item}><PigeItShipmentInfoMsr modalConfig={item} modalIndex={index} /></PigeItModal>;
                  case modalsCofigHash.MESSAGE.componentName:
                    return <PigeItModal key={index} isLast={index + 1 === props.activeModalQueue.length} config={item}><PigeItMessage modalConfig={item} modalIndex={index} /></PigeItModal>;
                  case modalsCofigHash.VIDEO.componentName:
                    return <PigeItModal key={index} isLast={index + 1 === props.activeModalQueue.length} config={item}><PigeitVideo modalConfig={item} modalIndex={index} /></PigeItModal>;
                  case modalsCofigHash.FORGOT_PASSWORD_ENTER_EMAIL.componentName:
                    return <PigeItModal key={index} isLast={index + 1 === props.activeModalQueue.length} config={item}><PigeItForgotPasswordEnterEmail modalConfig={item} modalIndex={index} /></PigeItModal>;
                  case modalsCofigHash.ENTER_NEW_PASSWORD.componentName:
                    return <PigeItModal key={index} isLast={index + 1 === props.activeModalQueue.length} config={item}><PigeItEnterNewPassword modalConfig={item} modalIndex={index} /></PigeItModal>;
                  case modalsCofigHash.ACTIVATE.componentName:
                    return <PigeItModal key={index} isLast={index + 1 === props.activeModalQueue.length} config={item}><PigeitActivate modalConfig={item} modalIndex={index} /></PigeItModal>;  
                  case modalsCofigHash.SAVE_MAPPING.componentName:
                    return <PigeItModal key={index} isLast={index + 1 === props.activeModalQueue.length} config={item}><PigeItSaveMapping modalConfig={item} modalIndex={index} /></PigeItModal>;    
                  case modalsCofigHash.UPLOAD_CSV.componentName:
                    return <PigeItModal key={index} isLast={index + 1 === props.activeModalQueue.length} config={item}><PigeItUploadCSV modalConfig={item} modalIndex={index} /></PigeItModal>;    
                  case modalsCofigHash.BUSINESSES_ADD_EDIT_PACKAGE.componentName:
                    return <PigeItModal key={index} isLast={index + 1 === props.activeModalQueue.length} config={item}><PigeItBusinessesAddEditPackage modalConfig={item} modalIndex={index} /></PigeItModal>;    
                  default:
                    return <div>UNKNOWN COMPONENT</div>
              }
            })
        }
      </AppStyle>
    );
  }
  else {
    return (null);
  }

  function isLoaderVisible() {
    return props.isLoaderVisible;
  }

  function isAnimatedLoaderVisible() {
    return props.isAnimatedLoaderVisible;
  }

  function resizeListener(e) {
    const clientWidth = document.documentElement.clientWidth;
    if (clientWidth < CONSTANTS.DESKTOP_MIN_WIDTH && props.displayMode === CONSTANTS.DISPLAY_MODES.DESKTOP) {
      // set to mobile (if not already set)
      dispatch(setDisplayMode(CONSTANTS.DISPLAY_MODES.MOBILE));
    }
    else if (clientWidth >= CONSTANTS.DESKTOP_MIN_WIDTH && props.displayMode === CONSTANTS.DISPLAY_MODES.MOBILE) {
        // set to desktop (if not already set)
        dispatch(setDisplayMode(CONSTANTS.DISPLAY_MODES.DESKTOP));
    }
  }

  function keydownEventListener(e) {
    setTimeout(function() {
      var modalContainer = document.querySelector('.pigeit-modal-container.last-modal');
      var activeElement = document.activeElement;
      if (modalContainer && !isDescendant(modalContainer, activeElement) && modalContainer.querySelectorAll('*').length) {
        activeElement.blur();
        modalContainer.querySelectorAll('*')[0].focus();
        e.preventDefault();
      }
    }, 0);

    if (e.keyCode === 27 && isInArray(document.body.classList, 'modal-active') && props.activeModalQueue[props.activeModalQueue.length - 1].closeOnPressingEsc) {
      // close the active modal when pressing 'esc'
      if(props.activeModalQueue.length>0&&props.activeModalQueue[props.activeModalQueue.length-1].url){
        navigateToPath('',true);
      }
      dispatch(modalQueue({ mode: 'delete' }));
    }
  }
}

const AppStyle = styled.div`
`;

const mapStateToProps = (state, ownProps) => {
  return {
    isLoaderVisible: state.data.loader.isVisible,
    isAnimatedLoaderVisible: state.data.animatedLoader.isVisible,
    activeModalQueue: state.data.activeModalQueue,
    displayMode: state.data.displayMode,
    purchaseProgress: state.data.ui.mainPage.purchaseProgress
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const App = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppComponent);

export default App;
