/* eslint react/jsx-pascal-case: off, react-hooks/exhaustive-deps: off */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { genericSetValue, api_addressBook_getList, api_addressBook_remove, modalQueue, setupAnimatedLoader } from '../redux/actions';
import { Colors } from "../common/colors";
import InputSearch from './InputSearch.component';
import IMAGES from '../common/images';
import Loader from "./Loader.component";
import CONSTANTS from '../common/constants';
import { ZIndices } from "../common/zIndices";
import { get } from 'lodash';
import { showErrorMessage ,setHeadMetaDescription} from "../common/functions";
import { useTitle } from 'hookrouter';


function PigeItMyAccount_AddressBookComponent(props) {
  useTitle('My Account | Address Book | ShipWinner');
  setHeadMetaDescription(`Welcome to the Address Book! We're here to simplify the shipping process for you. Whether you're using FedEx or UPS, we know how important it is to have all your information in one place; that way, you can ship your packages with just a few clicks. To use our Free Address Book tool, open your Free Shipping Account on ShipWinner and start Saving on Shipping Costs Now. | ShipWinner.com`);
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [itemsClickable, setItemsClickable] = useState(get(props, 'parentProps.modalConfig.addressBookClickable', false));
  const [loaderText, setLoaderText] = useState('Loading...');

  useEffect(function () {
    // read the list from the server
    setInProgress(true);
    // dispatch(genericSetValue({ path: 'api.addressBook_getList', value: undefined }));
    dispatch(api_addressBook_getList({
      callback: function () {
        setInProgress(false);
      }
    }));
  }, []);

  return (
    <PigeItMyAccount_AddressBookStyle className="my-account-personal-info-container">
      <div className="mobile-component bottom-banner">
        <div className="add-to-adress-book-control">
          <button className="add-new-address-button" onClick={addNewAddress}>{<IMAGES.plus />}<span>Add new address</span></button>
        </div>
      </div>

      <div className="background-container">
        <div className='desktop-component place-holder' />
        <div className="title-and-add-to-address-book-control-container display-flex fjc-space-around">
          <div className="desktop-component title-text flex-divided-3-cols">
            Address Book {inProgress && (<Loader imageOnly={true} />)}
          </div>
          <div className="search-input-container flex-divided-3-cols">
            <InputSearch inputName={"search-address-book"} inputId={"search-address-book"} inputPlaceholder={"Search by name, address or email"} onSearch={onSearch} />
          </div>
          <div className="desktop-component add-to-adress-book-control flex-divided-3-cols">
            <button className="add-new-address-button" onClick={addNewAddress}>{<IMAGES.plus />}<span>Add new address</span></button>
          </div>
        </div>
        <hr className="desktop-component seperator" />
        <div className="display-flex fd-column">
          {get(props, 'apiData_addressBook_getList.result.success', false) && get(props, 'apiData_addressBook_getList.result.results').map((item, i) => {
            if (!isMatchingFilter(item)) {
              return (null);
            }

            return (<div key={i} className={"result-item-container display-flex fd-row fjc-space-between" + (itemsClickable ? ' clickable' : '')}>
              <div tabIndex={(itemsClickable ? 0 : null)} className="information-container" onClick={() => { onInformationClick(item, i); }}>
                <div className="fullname">{item.fullname}</div>
                <div className="address">{item.address}</div>
                <div className="email-and-phone display-flex fd-row">
                  <div>{item.email}</div> <div>&nbsp;</div> <div>{item.phone}</div>
                </div>
              </div>
              <div className="actions-container">
                <button className="edit-button" onClick={() => { editAddressdHandler(item); }}>{<IMAGES.edit />} <span className='desktop-component' >&nbsp;&nbsp;Edit</span></button>
                &nbsp;&nbsp;
                <button className="remove-button" onClick={() => { removeAddressdHandler(item.id); }}>{<IMAGES.remove />} <span className='desktop-component' >&nbsp;&nbsp;Remove</span></button>
              </div>
            </div>);
          })}
        </div>
      </div>
      <div className="save-changes-button-container">

      </div>

    </PigeItMyAccount_AddressBookStyle>
  );

  function editAddressdHandler(item) {
    const modalConfig = JSON.parse(JSON.stringify(CONSTANTS.MODALS_CONFIG.ADDRESS_BOOK_ADD_EDIT));
    modalConfig.passedTabIndex = 0;
    modalConfig.packageToUpdate = item;
    dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
  }

  function removeAddressdHandler(id) {
    setInProgress(true);
    dispatch(setupAnimatedLoader({ isVisible: true, text: 'Deleting address...' }));
    dispatch(api_addressBook_remove({
      id: id,
      callback: function (result) {
        setInProgress(false);
        dispatch(setupAnimatedLoader({ isVisible: false }));
        if (result.success) {
          dispatch(genericSetValue({ path: 'api.addressBook_getList', value: { result: { success: true, results: get(props, 'apiData_addressBook_getList.result.results').filter(item => item.id !== id) } } }));
        }
        else {
          showErrorMessage(result, dispatch);
        }
      }
    }));
  }

  function onSearch(value) {
    setFilterValue(value);
  }

  function isMatchingFilter(item) {
    if (filterValue === '') {
      return true;
    }
    for (let field of ['fullname', 'address', 'email', 'phone']) {
      if (item[field].indexOf(filterValue) > -1) {
        return true;
      }
    }
    return false;
  }

  function addNewAddress() {
    //ADDRESS_BOOK_ADD_EDIT
    const modalConfig = JSON.parse(JSON.stringify(CONSTANTS.MODALS_CONFIG.ADDRESS_BOOK_ADD_EDIT));
    modalConfig.passedTabIndex = 0;
    dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
  }


  function onInformationClick(item, i) {
    if (itemsClickable) {
      const callback = get(props, 'parentProps.modalConfig.callback', false);
      if (typeof (callback) === 'function') {
        const duplicate = { ...JSON.parse(JSON.stringify(item)), isFromAddressBook: true };
        callback(duplicate);
        dispatch(modalQueue({ mode: 'delete' }));
      }
    }
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    apiData_addressBook_getList: state.data.api.addressBook_getList
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  };
};

const PigeItMyAccount_AddressBookStyle = styled.div`
  .bottom-banner {
    width: 100%;
    position: fixed;
    background: white;
    z-index: ${ZIndices.myAccountAddressBook};
    bottom: 0;
    box-shadow: 0px -1px 6px #d0d0d0;
  }
  .place-holder{
    height: 40px;
  }
  .edit-button, .remove-button{
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .background-container {
    padding: 1px 18px 0px 18px;
    background-color: ${Colors.panelBackground};
  }
  .title-text {
    text-align: left;
    font-family: museo_sans300,sans-serif;
    color: ${Colors.darkBlue1};
    font-size: 30px;
    margin: 19px 0px 0px 25px;
  }
  .search-input-container {
    margin: 12px 0 0 0;
  }
  .add-to-adress-book-control {
    text-align: right;
    margin: 12px 25px 0px 0px;
  }
  .add-new-address-button {
    font-size: 16px;
    height: 56px;
    background-color: transparent;
    border: none;
    color: ${Colors.blue1};
    svg {
      position: relative;
      top: -1px;
    }
    SPAN {
      position: relative;
      top: -12px;
      padding-left: 5px;
    }
  }
  .seperator {
    margin-bottom: 30px;
  }
  .result-item-container {
    &.clickable .information-container {
      cursor: pointer;
    }
    padding: 15px 6px;
    margin-bottom: 9px;
    background-color: ${Colors.white};
    .information-container {
      color: ${Colors.lightGray2};
      text-align: left;
      width: 100%;
      .fullname {
        margin-bottom: 9px;
      }
      .address, .email-and-phone {
        font-size: 14px;
        margin-bottom: 2px;
      }
    }
    .actions-container {
      min-width: 173px;
      margin-top: 18px;
      .edit-button {
        color: ${Colors.blue1};
        font-size: 14px;
        SPAN {
          text-transform: none;
        }
      }
      .remove-button {
        color: ${Colors.red};
        font-size: 14px;
        SPAN {
          text-transform: none;
        }
      }
    }
  }
  @media only screen and (max-width: 992px) {
    .result-item-container {
      .actions-container {
        min-width: unset;
      }
    }
    .add-to-adress-book-control {
      margin: unset;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .background-container {
      padding: 12px 7px 90px 7px;
      background-color: ${Colors.panelBackground};
    }
    .search-input-container {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .address, .email-and-phone {
      font-size: 14px;
      margin-bottom: 2px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .flex-divided-3-cols {
      flex: 0 0 100%;
    }
  }
`;

const PigeItMyAccount_AddressBook = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItMyAccount_AddressBookComponent);


export default PigeItMyAccount_AddressBook;
