import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ZIndices } from '../common/zIndices';

function PigeitLoaderComponent(props) {

  useEffect(() => {
    document.body.classList.add('loader-active');
    return function () {
      document.body.classList.remove('loader-active');
    }
  });

  return (
    <PigeitLoaderStyle className="loader-wrapper">
      <div className='background-dimmer'>
        <div className='loader-icon'>
          <svg className="lds-message" width="200px" height="200px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style={{background: "none"}}>
            <g transform="translate(20 50)">
              <circle cx="0" cy="0" r="6" fill="#2699fb" transform="scale(0.944731 0.944731)">
                <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
              </circle>
            </g>
            <g transform="translate(40 50)">
              <circle cx="0" cy="0" r="6" fill="#fcb02e" transform="scale(0.957535 0.957535)">
                <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
              </circle>
            </g>
            <g transform="translate(60 50)">
              <circle cx="0" cy="0" r="6" fill="#2699fb" transform="scale(0.687759 0.687759)">
                <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
              </circle>
            </g>
            <g transform="translate(80 50)">
              <circle cx="0" cy="0" r="6" fill="#fcb02e" transform="scale(0.33654 0.33654)">
                <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
              </circle>
            </g>
          </svg>
        </div>
        <div className='loader-text'>
            {props.text}
          </div>
      </div>
    </PigeitLoaderStyle >
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    isVisible: state.data.loader.isVisible,
    text: state.data.animatedLoader.text
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const PigeitLoaderStyle = styled.div`
  position: fixed;
  background-color: rgba(255,255,255,0.7);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: ${ZIndices.loader};
  .loader-icon, .loader-text{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .loader-text {
    transform: translate(-50%,100%);
    color: #fcb02e;
    font-size: 22px;
    white-space: nowrap;
  }
`;

const PigeitLoader = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeitLoaderComponent);

export default PigeitLoader;
