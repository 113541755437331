/* eslint jsx-a11y/anchor-is-valid: off */
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Colors } from '../common/colors';
import IMAGES from '../common/images';
import { get } from 'lodash';
import { navigateToPath } from "../common/functions";


function MainPageFooterComponent(props) {
  return (
    <MainPageFooterStyle>
      <div>
        <h1>
          Get Started Today For Free
        </h1>
        <p>
        ShipWinner makes sure all your orders ship right the first time.
        </p>
        {  ! get(props, 'apiData_getInitialData.result.success', false)&&<button className='sign-up-button' onClick={signUp}><b>SIGN UP FOR FREE</b></button>}
      </div>
      <div className='links-container mobile-container'>
        <hr className='line desktop-component' />
        <div className='links-layout mobile-layout'>
        {/*  <div className='link-group'>
            <p>
              WHAT WE DO
            </p>
            <p><a  className='link' onClick={noAction}>Create Shipping Labels</a></p>
            <p><a  className='link' onClick={noAction}>Connect Your  Shipping Tools</a></p>
            <p><a  className='link' onClick={noAction}>Manage Your Shipping Experience</a></p>
           
  </div>*/}
          <div className='link-group'>
            <p><a href='/information/about-us' className='link' onClick={(e)=>navigateTo('information/about-us',e)}>About Us</a></p>
            <p><a href='/information/terms-introduction' className='link' onClick={(e)=>navigateTo('information/terms-introduction',e)}>Terms of Use</a></p>
            <p><a href='/information/faq' className='link' onClick={(e)=>navigateTo('information/faq',e)}>FAQ</a></p>
          </div>
          <div className='link-group'>
            <p><a href='/track-your-shipment' className='link' onClick={(e)=>navigateTo('track-your-shipment',e)}>Track your shipment</a></p>
            <p><a target='_blank' rel="noopener noreferrer" href='https://www.fedex.com/locate/index.html' className='link' >Find the Nearest FedEx Location</a></p>
            <p><a target='_blank' rel="noopener noreferrer" href='https://www.ups.com/dropoff/?loc=en_US' className='link' >Find the Nearest UPS Location</a></p>
          </div>
          <div className='link-group'>
            <p><a href='/contact-us' className='link' onClick={(e)=>navigateTo('contact-us',e)}>Contact us</a></p>
            <p>
              Follow us on:
            </p>
            <a className='icon-link' target='_blank' rel="noopener noreferrer" href='https://www.youtube.com/channel/UCUFlSc1rfFNlzzgG-w9EgkQ'><IMAGES.youtube/></a>
            <a className='icon-link' target='_blank' rel="noopener noreferrer" href='https://www.linkedin.com/company/pigeit-com/'><IMAGES.linkedin/></a>
            <a className='icon-link' target='_blank' rel="noopener noreferrer" href='https://www.facebook.com/ShipWinnercom-101654078069092/'><IMAGES.facebook/></a>
          </div>
        </div>
      </div>
      <div className='support'>
        <a traget='_blank' href='mailto:support@shipwinner.com'>
            support@shipwinner.com
        </a>
      </div>
      <div className='copyright'>
        Copyright © ShipWinner All Right Reserved  |  Legal
      </div>
    </MainPageFooterStyle>
  );

  function noAction(e) {
  }

  function navigateTo(navigationPath,e){
    if(e){
      e.preventDefault();
    }
    navigateToPath(navigationPath, true);
  }

  function signUp() {
    if (get(props,'apiData_getInitialData.result.success', false)) {
      return;
    }
    navigateToPath('register', true);
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    apiData_getInitialData: state.data.api.getInitialData
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const MainPageFooterStyle = styled.div`
    padding: 40px;
    text-align: center;
    font-family: museo_sans300;
    background-color: ${Colors.blue2};
    color: white;
    align-items: center;
  .sign-up-button{
    background-color: ${Colors.orange}; 
    font-family: museo_sans700;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    font-size: 11px;
    border-radius: 2px;
    padding: 15px 50px 15px 50px;
    margin: 40px;
  }
  .copyright{
    font-size:10px;
    font-family:museo_sans500;
    margin:20px;
  }
  .support{
    margin:20px;
    display: block;
    a {
      font-family:museo_sans500;
      text-decoration: none;
      font-size:14px;
      color: white;
    }
  }
  .links-container{
    text-align:center;
    display:inline-block;
    .links-layout{
      text-align: center;
      display: inline-flex;
    }
    .line{
      border-top:1px solid ${Colors.blue3};
      border-bottom:0px solid white;
      border-left:0px solid white;
      border-right:0px solid white;
      margin:0 auto;
    }
    .link-group{
      text-align: left;
      font-family: museo_sans500;
      font-size: 80%;
      margin-top: 40px;
      margin: 80px ;
    }
    .link{
      color:white;
      text-decoration: none;
      font-family: museo_sans300;
    }
    .icon-link{
      margin: 5px;
    }
    @media only screen and (max-width: 992px){
      .mobile-container{
        text-align:left;
        display:block;
      }
      .mobile-layout{
        display:block;
      }
    }
  }
  @media only screen and (max-width: 992px){
    .links-container{
        .link-group{
          margin-left: 0;
        }
      } 
    .mobile-container{
      text-align:left;
      display:block;
    }
  }
`;

const MainPageFooter = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainPageFooterComponent);

export default MainPageFooter;
