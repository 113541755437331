/* eslint default-case: off */
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { modalQueue, api_register } from '../redux/actions';
import { Button, TextField, Link } from '@material-ui/core';
import { Colors } from "../common/colors";
import CONSTANTS from '../common/constants';
import IMAGES from '../common/images';
import { showErrorMessage, navigateToPath, logEventForAnalytics,  setHeadMetaDescription} from "../common/functions";
import Loader from './Loader.component';
import { useTitle } from 'hookrouter';
import { get } from 'lodash';

function PigeItRegisterComponent(props) {
  useTitle('Register with ShipWinner to Save up to 62% on Shipping Costs!');
  setHeadMetaDescription(`Welcome! Register for a Free Shipping Account to help you Save up to 62% on Shipping Costs. We offer the Cheapest Rates from FedEx and UPS, along with great tools to Speed Up and Simplify the Shipping Process. | ShipWinner.com`);

  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const [ email, setEmail ] = useState(props.username ? props.username : '');
  const [ password, setPassword ] = useState('');
  const [ isEmailError, setIsEmailError ] = useState(false);
  const [ isPasswordError, setIsPasswordError ] = useState(false);
  const [ inProgress, setInProgress ] = useState(false);

  return (
    <PigeItRegisterStyle className="register-container" ref={containerRef}>
      <div className="background-container">
        <div className="content-container">
          <Button className="modal-close-button" onClick={ close }>
            <IMAGES.close />
          </Button>
          <div className="header-text">Register</div>
          <div className="welcome-message">Welcome! Create an account to enjoy all the free tools ShipWinner has to offer.</div>
          <form>
            <div>
              <TextField autoFocus error={isEmailError} InputLabelProps={{ className: "email-textfield-label"}} type='email' InputProps={{ className: 'email-textfield-input-wrapper' }} fullWidth={true} className="email-textfield" placeholder="Email" label="Email" variant="outlined" value={email} onChange={(e) => { setEmail(e.target.value); }} />
            </div>
            <div>
              <TextField error={isPasswordError} fullWidth={true} type="password" className="password-textfield" placeholder="Password" label="Password" variant="outlined" value={password} onChange={(e) => { setPassword(e.target.value); }} />
            </div>
            <div>
              <Button fullWidth={true} className="sign-in-button" onClick={ register }>
                {inProgress && <Loader imageOnly />} REGISTER
              </Button>
            </div>
          </form>
          <hr className="hr-separator" />
          <Button className="continue-as-a-guest-button" onClick={ close }>Continue As A Guest</Button>
          <div className="new-user-sign-up-container">
            <span className="new-user-text">Already registered?</span> <Link classes={{button: 'sign-up-button'}} component="button" onClick={(e) => { signIn(e); }}>Sign in</Link>
          </div>
        </div>
      </div>
    </PigeItRegisterStyle>
  );

  function register() {
    if (inProgress) {
      return;
    }
    let emailValidationResult = validateField('email', true, true);
    let passwordValidationResult = validateField('password', true, true);
    if (emailValidationResult && passwordValidationResult) {
      setInProgress(true);
      dispatch(api_register({ username: email, password: password, callback }));
    }

    function callback(result){
      setInProgress(false);
      if(result&&!result.success){
        showErrorMessage(result,dispatch);
      }
      else{
        logEventForAnalytics({
          'event':'register'
        });
        let modalConfig = {
          ...CONSTANTS.MODALS_CONFIG.MESSAGE,
          message: "Confirmation Email send. Please activate your accout.",
          buttons: {ok:{visible:true,text:'OK'},cancel:{visible:false,text:'CANCEL'}}
        };
        dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
      }
      close();
    }
  }

  function signIn(e) {
    if (inProgress) {
      return;
    }
    if (e) {
      e.preventDefault();
    }
    navigateToPath('sign-in', true);
  }

  function close() {
    if (inProgress) {
      return;
    }
    if (get(props, 'modalConfig.url', false)) {
      navigateToPath('', true);
    }
    else {
      dispatch(modalQueue({ mode: 'delete' }));
    }
  }

  // check validity of fields
  function validateField(fieldName, resetError, updateUi) {
    let isValid;
    switch (fieldName) {
      case 'email':
        isValid = CONSTANTS.VALID_EMAIL_REGEX.test(email);
        if (resetError || isValid) {
          setIsEmailError(false);
        }
        if (!isValid && updateUi) {
          setIsEmailError(true);
        }
        return isValid;
      case 'password':
        isValid = password.length > 0;
        if (resetError || isValid) {
          setIsPasswordError(false);
        }
        if (!isValid && updateUi) {
          setIsPasswordError(true);
        }
        return isValid;
    }
    return false;
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const PigeItRegisterStyle = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  text-align: center;
  overflow: auto;
  background-color: #fff;
  .background-container {
    height: 100%;
    width: 100%;
    background-color: #fff;
    margin: 0 auto;
    position: relative;
  }
  .content-container {
    padding-top: 45px;
    padding-right: 65px;
    padding-left: 65px;
  }
  .modal-close-button {
    position: absolute;
    top: 21px;
    right: 26px;
  }
  .header-text {
    font-size: 36px;
  }
  .welcome-message {
    padding: 10px 0 10px 0;
  }
  .email-textfield {
    padding-bottom: 15px;
/*
    height: 40px;
    .email-textfield-label {
      height: 40px;
      top: -8px;
    }
    .email-textfield-input-wrapper {
      height: 40px;
    }
*/
  }
  .password-textfield {
    padding-bottom: 15px;
  }
  .sign-in-button {
    height: 56px;
    background-color: ${Colors.orange};
    color: ${Colors.white};
  }
  .hr-separator {
    margin-bottom: 15px;
  }
  .continue-as-a-guest-button {
    border: 2px solid ${Colors.blue1};
    color: ${Colors.blue1};
    width: 250px;
    height: 60px;
    margin-bottom: 15px;
  }
  .new-user-sign-up-container {
    text-align: left;
    font-size: 16px;
  }
  .new-user-text {
    position: relative;
    top: 3px;
  }
  .sign-up-button {
    color: ${Colors.blue1};
    font-size: 16px;
  }
`;

const PigeItRegister = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItRegisterComponent);


export default PigeItRegister;
