/* eslint default-case: off, eqeqeq: off, react-hooks/exhaustive-deps: off */
import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useTitle } from "hookrouter";
import { connect, useDispatch } from "react-redux";
import { Colors } from "../common/colors";
import CONSTANTS from "../common/constants";
import {
  showErrorMessage,
  navigateToPath,
  isValidWeightValue,
  isValidSizeValue,
  getGoogleAddressComponents,
  logEventForAnalytics,
} from "../common/functions";
import {
  genericSetValue,
  modalQueue,
  setupAnimatedLoader,
  api_get_prices_multi,
  save_orders,
} from "../redux/actions";
import { get } from "lodash";
import PigeitTable from "./PigeitTable.component";
import moment from "moment";
import PigeItDatePicker from "./PigeItDatePicker.component";
import PigeItInputAddress from "./PigeItInputAddress.component";
import PigeItInput from "./PigeItInput.component";
import PigeItCheckbox from "./PigeItCheckbox.component";
import { Button } from "@material-ui/core";
import IMAGES from "../common/images";

const ERRORS = {
  DATE: "DATE",
  TIME: "TIME",
  WEIGHT: "WEIGHT",
  WIDTH: "WIDTH",
  HEIGHT: "HEIGHT",
  LENGTH: "LENGTH",
  SENDER_ADDRESS: "SENDER_ADDRESS",
  SENDER_NAME: "SENDER_NAME",
  SENDER_PHONE: "SENDER_PHONE",
  RECIPIENT_ADDRESS: "RECIPIENT_ADDRESS",
  RECIPIENT_PHONE: "RECIPIENT_PHONE",
  VALUE: "VALUE",
  CURRENCY: "CURRENCY",
  DROPOFF_PICKUP: "DROPOFF_PICKUP",
};

const VALUES = CONSTANTS.MULTI_PACKAGES_VALUES;

const SERVER_ERRORS = {
  SENDER_ADDRESS: "invalid sender address",
  RECIPIENT: "invalid recipient address",
  DROPOFF_PICKUP: "mention dropoff and courier mode with the same value",
  TIME: "invalid time",
  WEIGHT: "invalid weight",
  SIZE: "invalid size",
  VALUE: "invalid insurance value",
  CURRENCY: "invalid insurance currency",
};

function MultiplePackagesStage2Component(props) {
  const dispatch = useDispatch();
  const [errors, seterrors] = useState([]);
  const orders = useRef(get(props, "orders", []));
  const senderInfoRef = useRef();
  const changed = useRef(false);
  const [senderInfo, setsenderInfo] = useState({ ...props.senderInfo });
  useEffect(() => {
    logEventForAnalytics({ event: "businesses-page-2" });
  }, []);
  const inputStyle = {
    width: "calc(100% - 10px)",
    height: "33px",
    padding: "0 5px",
    fontSize: "16px",
    outline: "none",
    border: "none",
  };

  if (props.initialData.user === "") {
    navigateToPath("businesses", true);
  }

  useEffect(() => {
    senderInfoRef.current = { ...props.senderInfo };

    const defaultPayment = get(
      props,
      "paymentSources_getList.result.results",
      []
    ).find((c) => c.selected);
    if (defaultPayment) {
      dispatch(
        genericSetValue({
          path: "ui.business.paymentDetails",
          value: {
            ...get(props, "paymentDetails", {}),
            fromSavedCards: true,
            cardType: defaultPayment.cardType,
            last4Digits: defaultPayment.last4Digits,
            expiryMonth: defaultPayment.expiryMonth,
            expiryYear: defaultPayment.expiryYear,
          },
        })
      );
    }
  }, []);

  useEffect(() => {
    //set orders
    orders.current = get(props, "orders", []);

    //translate server errors
    const includes = (serverError, error) =>
      get(serverError, "message", "").indexOf(error) !== -1;
    if (get(props, "getPricesCsv.result.errors", []).length) {
      seterrors([
        ...errors,
        ...get(props, "getPricesCsv.result.errors", []).map((e) => {
          let errorCode;
          if (includes(e, SERVER_ERRORS.SENDER_ADDRESS)) {
            errorCode = ERRORS.SENDER_ADDRESS;
          } else if (includes(e, SERVER_ERRORS.RECIPIENT_ADDRESS)) {
            errorCode = ERRORS.RECIPIENT_ADDRESS;
          } else if (includes(e, SERVER_ERRORS.DROPOFF_PICKUP)) {
            errorCode = ERRORS.DROPOFF_PICKUP;
          } else if (includes(e, SERVER_ERRORS.TIME)) {
            errorCode = ERRORS.DATE;
            errorCode = ERRORS.TIME;
          } else if (includes(e, SERVER_ERRORS.WEIGHT)) {
            errorCode = ERRORS.WEIGHT;
          } else if (includes(e, SERVER_ERRORS.SIZE)) {
            errorCode = ERRORS.WIDTH;
            errorCode = ERRORS.HEIGHT;
            errorCode = ERRORS.LENGTH;
          } else if (includes(e, SERVER_ERRORS.VALUE)) {
            errorCode = ERRORS.VALUE;
          } else if (includes(e, SERVER_ERRORS.CURRENCY)) {
            errorCode = ERRORS.CURRENCY;
          }
          return [e.index - 1, errorCode];
        }),
      ]);
    }
  }, [props]);

  let headers = [
    { name: "ID", width: "50px" },
    { name: "", width: "50px" },
    { name: "", width: "50px" },
    { name: "Recipient name", width: "140px" },
    { name: "Recipient email", width: "130px" },
    { name: "Recipient phone", width: "130px" },
    { name: "Recipient company", width: "130px" },
    { name: "Recipient Address", width: "210px" },
    { name: "Recipient Line 2", width: "90px" },
    { name: "Recipient Residential", width: "65px" },
    { name: "Dropoff/Pickup", width: "80px" },
    {
      name: "Pickup Date",
      width: "110px",
      sortFunc: (a, b) => new Date(b).getTime() - new Date(a).getTime(),
    },
    { name: "Pickup Time", width: "80px" },
    { name: "Weight", width: "60px" },
    { name: "Height", width: "60px" },
    { name: "Width", width: "60px" },
    { name: "Length", width: "60px" },
    { name: "Content", width: "90px" },
    { name: "Value", width: "60px" },
    { name: "Currency", width: "80px" },
  ];

  let data = get(props, "orders", []).map((pack, i) => {
    const isPickup = String(pack[VALUES.pickup]).toLowerCase() === "pickup";
    return {
      error: errors.some((err) => err[0] === i),
      row: [
        pack.id,
        {
          type: "text",
          title: "Delete package",
          value: (
            <button
              className="delete-button"
              onClick={() => {
                deletePackage(i);
              }}
            >
              <IMAGES.bin />
            </button>
          ),
        },
        {
          type: "text",
          title: "Edit package",
          value: (
            <button
              className="edit-button"
              onClick={() => {
                editPackage(i);
              }}
            >
              <IMAGES.edit />
            </button>
          ),
        },
        pack[VALUES.name],
        pack[VALUES.email],
        getInput(
          i,
          pack[VALUES.phone],
          VALUES.phone,
          ERRORS.RECIPIENT_PHONE,
          "Invalid Phone number, Please Enter a valid Phone number or leave empty"
        ),
        pack[VALUES.company],
        getInputAddress(
          i,
          pack[VALUES.street] +
            ", " +
            pack[VALUES.city] +
            ", " +
            pack[VALUES.state] +
            ", " +
            pack[VALUES.postalCode],
          ERRORS.RECIPIENT_ADDRESS,
          "Invalid address, Please Enter a valid address"
        ),
        pack[VALUES.addressLine2],
        {
          type: "icon",
          value: String(pack[VALUES.residential]).toLowerCase() === "y" ? 1 : 3,
        },
        isPickup ? "pickup" : "dropoff",
        isPickup
          ? getDateInput(
              i,
              moment([
                parseInt(pack[VALUES.year]),
                parseInt(pack[VALUES.month]) - 1,
                parseInt(pack[VALUES.day]),
              ])
            )
          : "",
        isPickup
          ? getInput(
              i,
              pack[VALUES.time],
              VALUES.time,
              ERRORS.TIME,
              "Invalid Time, Please Enter a valid Time"
            )
          : "",
        getInput(
          i,
          pack[VALUES.weight],
          VALUES.weight,
          ERRORS.WEIGHT,
          "Invalid Weight, Please Enter a valid Weight"
        ),
        getInput(
          i,
          pack[VALUES.height],
          VALUES.height,
          ERRORS.HEIGHT,
          "Invalid Height, Please Enter a valid Height"
        ),
        getInput(
          i,
          pack[VALUES.width],
          VALUES.width,
          ERRORS.WIDTH,
          "Invalid Width, Please Enter a valid Width"
        ),
        getInput(
          i,
          pack[VALUES.length],
          VALUES.length,
          ERRORS.LENGTH,
          "Invalid Length, Please Enter a valid Length"
        ),
        pack[VALUES.description],
        getInput(
          i,
          pack[VALUES.insuranceValue],
          VALUES.insuranceValue,
          ERRORS.VALUE,
          "Invalid Value, Please Enter a valid Value"
        ),
        getInput(
          i,
          pack[VALUES.insuranceCurrency],
          VALUES.insuranceCurrency,
          ERRORS.CURRENCY,
          "Invalid Currency, Please Enter a valid Currency"
        ),
      ],
    };
  });

  return (
    <MultiplePackagesStage2Style>
      <h3 className="main-title">Dashboard</h3>
      <div className="main-container sender-info">
        <label className="sender-label">Sender Info</label>
        <div className="display-flex fjc-space-between">
          <PigeItInputAddress
            error={errors.some((e) => e[1] === ERRORS.SENDER_ADDRESS)}
            className="input address"
            onChange={updateAddress}
            passedValue={senderInfo.addressText}
          />
          <PigeItInput
            label="Address Line 2"
            value={senderInfo.addressLine2}
            onChange={(e) => updateField("addressLine2", e.target.value)}
            className="input"
          />
          <PigeItInput
            error={errors.some((e) => e[1] === ERRORS.SENDER_NAME)}
            label="Full Name"
            value={senderInfo.name}
            onChange={(e) => updateField("name", e.target.value)}
            className="input"
          />
          <PigeItInput
            label="Company"
            value={senderInfo.company}
            onChange={(e) => updateField("company", e.target.value)}
            className="input"
          />
          <PigeItInput
            error={errors.some((e) => e[1] === ERRORS.SENDER_PHONE)}
            label="Phone"
            value={senderInfo.phone}
            onChange={(e) => updateField("phone", e.target.value)}
            className="input"
          />
        </div>
        <PigeItCheckbox
          labelText="Address is residential"
          changed={senderInfo.residential}
          onClick={(val) => updateField("residential", val)}
        />
      </div>
      <div className="main-container">
        <div className="display-flex buttons-container">
          <div className="display-flex flex-wrap">
            <Button className="import-button" onClick={onAddPackageClick}>
              Add Package
            </Button>
            <Button className="import-button" onClick={onImportCSVClick}>
              Import CSV
            </Button>
            <Button
              className="import-button"
              onClick={() => importEcommerce("businesses-import-wix")}
            >
              Import WIX
            </Button>
            <Button
              className="import-button"
              onClick={() => importEcommerce("businesses-import-woocommerce")}
            >
              Import WooCommerce
            </Button>
            <Button
              className="import-button"
              onClick={() => importEcommerce("businesses-other-imports")}
            >
              Other Imports
            </Button>
          </div>
          <button
            className="delete-button no-width"
            onClick={deleteAllPackages}
          >
            <IMAGES.bin />
          </button>
        </div>
        <PigeitTable
          className="packages-table"
          headers={headers}
          data={data}
          defaultSort={{ column: 0, func: (a, b) => parseInt(a) - parseInt(b) }}
        />
        <button
          disabled={get(props, "orders", []).length === 0}
          className="get-prices"
          onClick={getPrices}
        >
          GET PRICES
        </button>
      </div>
    </MultiplePackagesStage2Style>
  );

  async function importEcommerce(event) {
    logEventForAnalytics({ event });
    let conf = await showMessage(
      "We’re working hard to add more features for you to enjoy. If you need to import data from your e-commerce platform, please contact us.",
      "CONTACT US",
      "CLOSE"
    );
    if (conf) {
      navigateToPath("contact-us");
    }
  }

  function onImportCSVClick() {
    logEventForAnalytics({ event: "businesses-import-csv" });
    dispatch(
      modalQueue({
        mode: "insert",
        modalConfig: CONSTANTS.MODALS_CONFIG.UPLOAD_CSV,
      })
    );
  }

  function onAddPackageClick() {
    logEventForAnalytics({ event: "businesses-add-package" });
    dispatch(
      modalQueue({
        mode: "insert",
        modalConfig: CONSTANTS.MODALS_CONFIG.BUSINESSES_ADD_EDIT_PACKAGE,
      })
    );
  }

  function editPackage(index) {
    dispatch(
      modalQueue({
        mode: "insert",
        modalConfig: {
          ...CONSTANTS.MODALS_CONFIG.BUSINESSES_ADD_EDIT_PACKAGE,
          package: get(props, "orders", []).find((o, i) => index === i),
        },
      })
    );
  }

  function getInput(rowI, value, cellI, errorType, errorMessage) {
    if (errors.some((err) => err[0] === rowI && err[1] === errorType)) {
      return {
        type: "text",
        celColor: "red",
        style: { backgroundColor: "red", padding: "1px" },
        title: errorMessage,
        value: (
          <input
            value={value}
            onChange={(e) => {
              dispatch(
                genericSetValue({ path: "ui.business.changed", value: true })
              );
              dispatch(
                save_orders(
                  get(orders, "current", []).map((row, i) => {
                    if (i === rowI) {
                      row[cellI] = e.target.value;
                    }
                    return row;
                  })
                )
              );
            }}
            style={inputStyle}
          />
        ),
      };
    } else {
      return value;
    }
  }

  function getInputAddress(rowI, value, errorType, errorMessage) {
    if (errors.some((err) => err[0] === rowI && err[1] === errorType)) {
      return {
        type: "text",
        celColor: "red",
        style: { backgroundColor: "red", padding: "1px" },
        title: errorMessage,
        value: (
          <PigeItInputAddress
            simple
            passedValue={value}
            onChange={(value) => {
              let addressParts = getGoogleAddressComponents(value);
              dispatch(
                genericSetValue({ path: "ui.business.changed", value: true })
              );
              dispatch(
                save_orders(
                  get(orders, "current", []).map((row, i) => {
                    if (i === rowI) {
                      row[VALUES.city] = addressParts.city;
                      row[VALUES.state] = addressParts.state;
                      row[VALUES.postalCode] = addressParts.postalCode;
                      row[VALUES.street] =
                        addressParts.streetNum + " " + addressParts.street;
                    }
                    return row;
                  })
                )
              );
            }}
            style={inputStyle}
          />
        ),
      };
    } else {
      return value;
    }
  }

  function getDateInput(rowI, value) {
    if (errors.some((err) => err[0] === rowI && err[1] === ERRORS.DATE)) {
      return {
        type: "text",
        celColor: "red",
        style: { backgroundColor: "red", padding: "1px" },
        title: "Invalid date. Please enter a valid date.",
        value: (
          <PigeItDatePicker
            simple
            date={value}
            onChange={(newValue) => {
              dispatch(
                genericSetValue({ path: "ui.business.changed", value: true })
              );
              dispatch(
                save_orders(
                  get(orders, "current", []).map((row, i) => {
                    if (i === rowI) {
                      row[VALUES.year] = moment(newValue).year();
                      row[VALUES.month] = moment(newValue).month() + 1;
                      row[VALUES.day] = moment(newValue).date();
                    }
                    return row;
                  })
                )
              );
            }}
            style={inputStyle}
          />
        ),
      };
    } else {
      return value.format("YYYY/MM/DD");
    }
  }

  function updateField(field, val) {
    dispatch(genericSetValue({ path: "ui.business.changed", value: true }));
    senderInfoRef.current = { ...senderInfoRef.current, [field]: val };
    setsenderInfo(senderInfoRef.current);
    dispatch(
      genericSetValue({
        path: "ui.business.senderInfo",
        value: senderInfoRef.current,
      })
    );
  }
  function updateAddress(value) {
    dispatch(genericSetValue({ path: "ui.business.changed", value: true }));
    let addressParts = getGoogleAddressComponents(value);
    senderInfoRef.current = {
      ...senderInfoRef.current,
      city: addressParts.city,
      state: addressParts.state,
      postalCode: addressParts.postalCode,
      street: addressParts.streetNum + ", " + addressParts.street,
      addressText: addressParts.addressText,
    };
    setsenderInfo(senderInfoRef.current);
    dispatch(
      genericSetValue({
        path: "ui.business.senderInfo",
        value: senderInfoRef.current,
      })
    );
  }

  function deletePackage(index) {
    dispatch(genericSetValue({ path: "ui.business.changed", value: true }));
    seterrors(
      errors
        .filter((e) => e[0] !== index)
        .map((e) => (e[0] > index ? [e[0] - 1, e[1]] : e))
    );
    dispatch(
      save_orders(get(props, "orders", []).filter((p, i) => i !== index))
    );
  }

  async function deleteAllPackages() {
    let conf = await showMessage(
      "Are you sure you want to delete all packages?"
    );
    if (!conf) return;
    dispatch(genericSetValue({ path: "ui.business.changed", value: true }));
    seterrors([]);
    dispatch(save_orders([]));
  }

  function checkErrors() {
    let err = [];
    get(props, "orders", []).forEach((pack, i) => {
      if (String(pack[VALUES.pickup]).toLowerCase() === "pickup") {
        if (
          !moment([
            parseInt(pack[VALUES.year]),
            parseInt(pack[VALUES.month]) - 1,
            parseInt(pack[VALUES.day]),
          ]).isValid()
        ) {
          err.push([i, ERRORS.DATE]);
        }
        if (
          pack[VALUES.time].length !== 5 ||
          pack[VALUES.time].indexOf(":") !== 2 ||
          isNaN(pack[VALUES.time].substr(0, 2)) ||
          isNaN(pack[VALUES.time].substr(3, 2))
        ) {
          err.push([i, ERRORS.TIME]);
        }
      }
      if (
        !pack[VALUES.postalCode] ||
        !pack[VALUES.state] ||
        !pack[VALUES.city] ||
        !pack[VALUES.street]
      ) {
        err.push([i, ERRORS.RECIPIENT_ADDRESS]);
      }
      if (
        !pack[VALUES.phone] ||
        !CONSTANTS.VALID_PHONE_REGEX.test(pack[VALUES.phone])
      ) {
        err.push([i, ERRORS.RECIPIENT_PHONE]);
      }
      if (!isValidWeightValue(pack[VALUES.weight])) {
        err.push([i, ERRORS.WEIGHT]);
      }
      if (!isValidSizeValue(pack[VALUES.height])) {
        err.push([i, ERRORS.HEIGHT]);
      }
      if (!isValidSizeValue(pack[VALUES.width])) {
        err.push([i, ERRORS.WIDTH]);
      }
      if (!isValidSizeValue(pack[VALUES.length])) {
        err.push([i, ERRORS.LENGTH]);
      }
      if (pack[VALUES.insuranceValue]) {
        if (!isValidSizeValue(pack[VALUES.insuranceValue])) {
          err.push([i, ERRORS.VALUE]);
        }
        if (
          pack[VALUES.insuranceCurrency].toLowerCase() !== "dollar" &&
          pack[VALUES.insuranceCurrency].toLowerCase() !== "euro"
        ) {
          err.push([i, ERRORS.CURRENCY]);
        }
      }
    });
    if (
      !get(props, "senderInfo.city", false) ||
      !get(props, "senderInfo.state", false) ||
      !get(props, "senderInfo.postalCode", false) ||
      !get(props, "senderInfo.street", false)
    ) {
      err.push([-1, ERRORS.SENDER_ADDRESS]);
    }
    if (!get(props, "senderInfo.name", false)) {
      err.push([-1, ERRORS.SENDER_NAME]);
    }
    if (
      !get(props, "senderInfo.phone", false) ||
      !CONSTANTS.VALID_PHONE_REGEX.test(
        String(get(props, "senderInfo.phone", false))
      )
    ) {
      err.push([-1, ERRORS.SENDER_PHONE]);
    }
    seterrors(err);
    return err.length !== 0;
  }

  function showMessage(message, confirmText = "YES", cancelText = "CANCEL") {
    return new Promise((resolve) => {
      let modalConfig = {
        ...CONSTANTS.MODALS_CONFIG.MESSAGE,
        buttons: {
          ok: { visible: true, text: confirmText },
          cancel: { visible: true, text: cancelText },
        },
        message,
        callback: function (res) {
          resolve(res.confirm);
        },
      };
      dispatch(modalQueue({ mode: "insert", modalConfig: modalConfig }));
    });
  }

  async function getPrices() {
    if (checkErrors()) {
      return;
    }
    if (get(props, "getPricesMulti.result.success", false) && !props.changed) {
      navigateToPath("businesses/stage-3", true);
      return;
    }
    if (
      get(props, "orders", []).some(
        (pack, i) => String(pack[VALUES.pickup]).toLowerCase() === "pickup"
      )
    ) {
      let result = await showMessage(
        "Pickup is not available. Do you want to continue without pickup?"
      );
      if (!result) return;
      else {
        dispatch(
          save_orders(
            get(props, "orders", []).map((pack, i) => {
              pack[VALUES.pickup] = "dropoff";
              pack[VALUES.time] = "";
              pack[VALUES.date] = "";
              return pack;
            })
          )
        );
      }
    }
    dispatch(
      setupAnimatedLoader({ isVisible: true, text: "Finding best price..." })
    );
    dispatch(
      api_get_prices_multi({
        shippingCompanies: [1, 2],
        list: get(props, "orders", []).map((pack, i) => ({
          shippingDropoffTypeId: 1,
          date: "",
          time: "",
          addressDataVersion: "plain",
          senderAddressDetails: {
            googlePlaceId: "",
            addressExtraLines: [""],
            addressTypeId: senderInfo.residential ? 2 : 1,
            city: senderInfo.city,
            stateOrProvinceCode: senderInfo.state,
            postalCode: senderInfo.postalCode,
            addressLines: [senderInfo.street, senderInfo.addressLine2],
          },
          recipientAddressDetails: {
            googlePlaceId: "",
            addressExtraLines: [],
            addressTypeId:
              String(pack[VALUES.residential]).toLowerCase() === "y" ? 2 : 1,
            city: pack[VALUES.city] || "",
            stateOrProvinceCode: pack[VALUES.state] || "",
            postalCode: pack[VALUES.postalCode] || "",
            addressLines: [
              pack[VALUES.street] || "",
              pack[VALUES.addressLine2] || "",
            ],
          },
          packages: [
            {
              packagingType: 1,
              weight: parseFloat(pack[VALUES.weight]) || 0,
              weightUnits: "LBS",
              sizeUnits: "IN",
              sizeHeight: parseFloat(pack[VALUES.height]) || 0,
              sizeWidth: parseFloat(pack[VALUES.width]) || 0,
              sizeLength: parseFloat(pack[VALUES.length]) || 0,
              insuredValueCurrency:
                pack[VALUES.insuranceCurrency] === "DOLLAR" ? 1 : 2,
              insuredValueAmount: parseFloat(pack[VALUES.insuranceValue]) || 0,
              contentDescription: pack[VALUES.description] || "",
            },
          ],
        })),
        callback: async (result) => {
          dispatch(setupAnimatedLoader({ isVisible: false }));
          if (result.success) {
            if (result.warnings && result.warnings.length) {
              logEventForAnalytics({ event: "businesses-csv-address-error" });
              let conf = await showMessage(
                "We could not validate the recipient address of orders number " +
                  result.warnings
                    .map((w) => parseInt(w.index) + 1)
                    .reduce((a, b) => a + ", " + b) +
                  ". If the address is not valid, you may be charged for submitting an invalid address. Do you want to continue?",
                "CONTINUE"
              );
              if (!conf) {
                genericSetValue({ path: "ui.business.changed", value: true });
                return;
              }
            }
            if (
              get(result, "results", [])
                .map((r) => get(r, "options", []))
                .reduce((a, b) => [...a, ...b], []).length
            ) {
              navigateToPath("businesses/stage-3", true);
              dispatch(
                genericSetValue({ path: "ui.business.changed", value: false })
              );
            } else {
              showErrorMessage({ success: false }, dispatch);
            }
          } else {
            showErrorMessage(result, dispatch);
          }
        },
      })
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    mobile: state.data.displayMode === "MOBILE",
    orders: state.data.ui.business.orders,
    senderInfo: state.data.ui.business.senderInfo,
    changed: state.data.ui.business.changed,
    getPricesMulti: state.data.api.getPricesMulti,
    initialData: get(state, "data.api.getInitialData.result", {}),
    paymentSources_getList: state.data.api.paymentSources_getList,
    mobile: state.data.displayMode === "MOBILE",
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

const MultiplePackagesStage2 = connect(
  mapStateToProps,
  mapDispatchToProps
)(MultiplePackagesStage2Component);

const MultiplePackagesStage2Style = styled.div`
  .buttons-container {
    justify-content: space-between;
    align-items: center;
  }
  .main-title {
    color: #5d5d5d;
    text-align: center;
    padding: 21px 0px;
    font-size: 24px;
    margin: 0;
  }
  .import-button {
    background-color: ${Colors.blue1};
    color: white;
    box-shadow: 1px 1px 4px grey;
    font-size: 16px;
    margin-right: 20px;
    margin-bottom: 10px;
    padding: 2px 7px;
    &:hover {
      background-color: ${Colors.blue2};
    }
    &:selected {
      box-shadow: none;
    }
    &:disabled {
      background-color: ${Colors.lightGray1};
      color: lightgray;
      box-shadow: none;
    }
  }
  .delete-button {
    background: none;
    outline: none;
    cursor: pointer;
    border: none;
    width: 100%;
    svg {
      fill: red;
    }
    &.no-width {
      width: unset;
    }
  }
  .edit-button {
    background: none;
    outline: none;
    cursor: pointer;
    border: none;
    width: 100%;
    svg {
      path {
        fill: green;
      }
    }
  }
  .main-container {
    padding: 37px 20px;
    display: block;
    margin: 0px 50px;
    background-color: #eff8fa;
    box-shadow: 1px 1px 5px #c9d2d4;
    border-radius: 3px;
    margin-bottom: 50px;
    padding-top: 10px;
    &.sender-info {
      padding: 14px 20px;
      margin-bottom: 20px;
      .sender-label {
        display: block;
        color: black;
        padding: 0;
        font-size: 19px;
        padding-bottom: 10px;
      }
      .input {
        flex: 0 0 17%;
        &.address {
          flex: 0 0 30%;
        }
      }
    }
  }
  .sub-title {
    color: black;
    margin: 0 70px;
    text-align: center;
    font-family: museo_sans300;
    margin-bottom: 40px;
  }
  .packages-table {
    margin: auto;
    text-align: -webkit-center;
    overflow-x: scroll;
    border: solid 1px lightgrey;
    padding-bottom: 10px;
    border-radius: 8px;
    background-color: white;
    table {
      width: max-content;
      margin: 0;
    }
    &::-webkit-scrollbar {
      width: 10px;
      background-color: lightgrey;
      margin-right: 1px;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      width: 10px;
      background-color: ${Colors.blue2};
      border-radius: 20px;
    }
  }
  .get-prices {
    display: block;
    margin: auto;
    margin-top: 36px;
    padding: 14px 20px;
    font-size: 20px;
    background: ${Colors.orange};
    border: none;
    border-radius: 3px;
    color: white;
    cursor: pointer;
    outline: none;
    &:hover {
      background-color: ${Colors.orangeHover};
    }
    &:disabled {
      background: ${Colors.lightGray4};
    }
  }
  @media only screen and (max-width: 992px) {
    .main-container {
      margin: 0 10px;
    }
    .sender-info {
      .display-flex {
        flex-direction: column;
      }
    }
    .input.address {
      margin: 7px 0px;
      margin-top: 0;
    }
    .input {
      margin: 5px 0;
    }
  }
`;

export default MultiplePackagesStage2;
