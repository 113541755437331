import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { modalQueue, api_activate_account } from '../redux/actions';
import { Button } from '@material-ui/core';
import { Colors } from "../common/colors";
import CONSTANTS from '../common/constants';
import { get } from 'lodash';
import IMAGES from '../common/images';
import { navigateToPath, logEventForAnalytics } from "../common/functions";

function PigeitActivateComponent(props) {

  const dispatch = useDispatch();
  const [inProgress, setInProgress] = useState(true);

  useEffect(() => {
    let request = {
      email : get(props,'modalConfig.email',''),
      activation : get(props,'modalConfig.activation',''),
      callback: function(result) {
        if(result&&result.success){
          setInProgress(false);
          logEventForAnalytics({
            'event':'activated'
          });
        }else{
          dispatch(modalQueue({ mode: 'delete' }));
          let modalConfig = {
            ...CONSTANTS.MODALS_CONFIG.MESSAGE,
            message: 'Sorry, we couldn\'t activate your account. Please contact us.',
            buttons: {ok:{visible:true,text:'OK'},cancel:{visible:false,text:'CANCEL'}},
            callback: function() {
              navigateToPath('contact-us', true);
            }
          };
          dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
        }
      }
    };
    dispatch(api_activate_account(request));
  }, []);

  return (
    <PigeitActivateStyle className="oginl-container">
      <div className="background-container">
        <div className="content-container">
          <Button className="modal-close-button" onClick={close}>
            <IMAGES.close />
          </Button>
          {inProgress ? <>
          <div className="header-text">Activating account</div>
          <svg className="lds-message" width="200px" height="200px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style={{ background: "none",margin:'auto' }}>
            <g transform="translate(20 50)">
              <circle cx="0" cy="0" r="6" fill="#2699fb" transform="scale(0.944731 0.944731)">
                <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
              </circle>
            </g>
            <g transform="translate(40 50)">
              <circle cx="0" cy="0" r="6" fill="#fcb02e" transform="scale(0.957535 0.957535)">
                <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
              </circle>
            </g>
            <g transform="translate(60 50)">
              <circle cx="0" cy="0" r="6" fill="#2699fb" transform="scale(0.687759 0.687759)">
                <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
              </circle>
            </g>
            <g transform="translate(80 50)">
              <circle cx="0" cy="0" r="6" fill="#fcb02e" transform="scale(0.33654 0.33654)">
                <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
              </circle>
            </g>
          </svg></> :
            <div className="header-text">You account is activated. Please sign in</div>}
          <button className={'ok button'+(inProgress?' disabled':'')} onClick={confirm}>SIGN IN</button>
        </div>
      </div>

    </PigeitActivateStyle >
  );

  function confirm() {
    if(inProgress){
      return;
    }
    dispatch(modalQueue({ mode: 'delete' }));
    navigateToPath('sign-in', true);
  }


  function close() {
    if(inProgress){
      return;
    }
    dispatch(modalQueue({ mode: 'delete' }));
  }


}

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  };
};

const PigeitActivateStyle = styled.div`
  width: 100%;
  height: 100%;
  .button{
    background: unset;
    border: none;
    cursor: pointer;
    display: block;
    margin: auto;
    outline: none;
    &.ok{
      width: 350px;
      height: 56px;
      text-align: center;
      color: ${Colors.white};
      background-color: ${Colors.orange};
      font-size: 18px;
      font-family: museo_sans700;
      border-radius: 3px;
    }
    &.cancel{
      text-align: center;
      color: #2699FB;
      font-size: 14px;
      font-family: museo_sans500;
    }
    &.disabled{
      background-color: ${Colors.lightGray4};
      cursor:unset;
    }
    .icon{
      svg{
        width: 16px;
        height: 13px;
        margin-right: 15px;
        path{
          fill: ${Colors.white};
        }
      }
    }
    
    .text{
      display: inline-block;
    }
  }
  
  .background-container {
    height: 100%;
    width: 100%;
    background-color: #fff;
    margin: 0 auto;
    
  }
  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
    padding-top: 5%;
  }
  .modal-close-button {
    position: absolute;
    top: 21px;
    right: 26px;
  }
  .header-text {
    max-width: 350px;
    font-size: 21px;
    text-align: center;
    margin: auto;
    word-wrap: break-word;
  }
  @media only screen and (max-width: 992px) { 
    .header-text {
      font-size: 25px;
    }
    .zip-input{
      min-width: 280px;
    }
    .button{
      &.find-location{
        width: 302px;
      }
    }
  }
`;

const PigeitActivate = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeitActivateComponent);




export default PigeitActivate;
