import ms from "ms";

function replacer(key, value) {
  if (value instanceof Map) {
    return {
      dataType: "Map",
      value: Array.from(value.entries()), // or with spread: value: [...value]
    };
  } else {
    return value;
  }
}

function reviver(key, value) {
  if (typeof value === "object" && value !== null) {
    if (value.dataType === "Map") {
      return new Map(value.value);
    }
  }
  return value;
}

class Cache {
  map = new Map();
  MAX_SIZE = 500;
  MAX_TIME = ms("30 days");
  name = "";
  constructor(name) {
    this.name = name;
    try {
      const stored = localStorage.getItem(name);
      if (stored) {
        this.map = JSON.parse(stored, reviver);
        this.cleanData();
      }
    } catch (e) {}
  }

  setValue(key, value) {
    this.cleanData();
    const old = this.map.get(key);
    if (old) {
      old.timestamp = Date.now();
      this.map.set(key, old);
    } else {
      this.map.set(key, { value, freq: 1, timestamp: Date.now() });
    }
    localStorage.setItem(this.name, JSON.stringify(this.map, replacer));
  }

  getValue(key) {
    this.cleanData();
    const old = this.map.get(key);
    if (old) {
      old.freq++;
      this.map.set(key, old);
      localStorage.setItem(this.name, JSON.stringify(this.map, replacer));
      return old.value;
    }
    return null;
  }

  cleanData() {
    try {
      if (this.map.size > this.MAX_SIZE) {
        const smallest = Array.from(this.map.entries()).reduce((a, b) =>
          a[1].freq < b[1].freq ? a : b
        );
        console.log(smallest);
        this.map.delete(smallest[0]);
        localStorage.setItem(this.name, JSON.stringify(this.map, replacer));
      }
      const allOld = Array.from(this.map.entries())
        .filter((ent) => Date.now() - ent[1].timestamp > this.MAX_TIME)
        .map((ent) => ent[0]);
      allOld.forEach((key) => this.map.delete(key));
    } catch (e) {
      console.log(e);
    }
  }
}

export const googleCache = new Cache("@save-data");
