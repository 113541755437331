/* eslint no-useless-escape: off */

import CONSTANTS from "./constants";

let path = CONSTANTS.PUBLIC_URL;
path = path + (path.substring(path.length - 1) !== "/" ? "/" : "");

const BLOG_CONTENT = {
  title: "BlogWinner | ShipWinner",
  subTitle: `At ShipWinner, we believe that giving our customers real value is the most important thing we can do.`,
  description: ``,
  content: [
    {
      pageTitle: "How to Price My Online Product | ShipWinner",
      pageDescription: `One of the first things you need to do when you open an online shop is establish prices for your products. If you need to know how to calculate sales prices for your online store, you've come to the right place! At BlogWinner, we'll give you useful, reliable information to help you grow your business.`,
      title: "How to Price My Online Products",
      path: "how-to-price-my-online-product",
      createDate: "Feb 1, 2020",
      blogHeader: "Started a new online business? Congratulations!",
      img: CONSTANTS.STACK_IMAGES["image-1"],
      content: [
        {
          header: ``,
          para: [
            `In 2020, just about anyone can open a virtual store. In this blog, we'll talk about a very frequently asked question: how to price your products.`,
          ],
        },
        {
          header: `Why is it Easier to be a Seller Today?`,
          para: [
            `Not too long ago, if you wanted to open a business, you needed to rent brick-and-mortar premises, while carefully considering your store's location and its accessibility to your clientele. You needed to wake up early to open your shop, and hope that people would actually walk in and purchase your merchandise. Then there were other expenses—property taxes, water, electricity, heating in the winter and AC in the summer, to name a few—which started you off each month with a loss that you'd only overcome mid-month. Each employee that you added to your staff incurred another expense. All these costs had to be calculated into your products' prices to ensure a profit. Today, you have the luxury of opening an online store right from your living room if you want to, dispensing with all those extra expenses.`,
          ],
        },
        {
          header: `Setting Prices in Today's Market`,
          para: [
            `There are lots of different pricing models out there, but let's start with the most basic questions: How much does your product cost, and what are your overhead costs?`,
            `Let's take a minute to discuss each of those variables.`,
          ],
        },
        {
          header: `What is Overhead?`,
          para: [
            `Overhead refers to any ongoing business expense that's not directly related to the product's cost—for example, rent, utilities, or employee salaries. Other examples include legal fees, inventory insurance, or warehouse insurance. Since these are all expenses that are beyond the actual cost of the product, they're overhead costs.`,
          ],
        },
        {
          header: `What is a Product's Direct Cost, and How do I Calculate it?`,
          para: [
            `A product's direct cost is any expense you pay that is directly related to purchasing your product. Let's say you sell tennis balls. You buy a four-pack of balls for $1, so $1 is your direct cost for that product. If you need to pay a fixed cost of 50¢ shipping per package to buy your four-pack, your direct cost is $1.50. If your shipping costs change with your purchase quantities, then your direct cost will change accordingly. So direct costs can include shipping fees, handling fees, or even storage fees if they're directly tied to each of your products.`,
            `Here's another example of a direct cost. If you're a dentist and you install dental crowns for people, your direct cost would include the cost of the crown itself and the disposable dental supplies necessary for the procedure. Conversely, your overhead costs would include the rest of the clinic costs that you pay, regardless of how many patients you see. Rent, secretary and hygienist salaries, utilities, and even transportation costs are all examples of overhead.`,
          ],
        },
        {
          header: `Online Prices vs Store Prices, and What it Means to You as a Seller`,
          para: [
            `The dentist example above highlights the difference in overhead costs between an online business and a traditional business.`,
            `You might be asking yourself at this point why it's so important for an online business to calculate overhead costs. After all, you don’t have to worry about a lot of the expenses that traditional businesses do.`,
            `So think about this next question. Is an online product cheaper or more expensive than the equivalent product in a traditional store or mall? The overhead cost of the product is lower, so the selling price must be lower too. But then why are products sometimes more expensive on <a target='_blank' href='https://www.amazon.com/'>Amazon</a>, <a target='_blank' href='https://www.ebay.com/'>eBay</a>, or other online marketplaces, compared to a traditional store?`,
            `The answer is actually pretty simple. Online stores can choose to have relatively low overhead costs (for example, by working from home instead of an office), but their shipping, storage, and fulfillment costs are much higher than in a brick-and-mortar store or mall. These have to be calculated into the direct cost of online products. It's important to note that these expenses aren't paid by the seller, but rather by the buyer, per product. Traditional stores have a harder time incorporating these expenses into their prices because on the one hand, they have high overhead costs, and on the other hand, they have to keep their prices competitive to online (low overhead) prices.`,
          ],
        },
        {
          header: `OK. So How Do I Price My Products?`,
          para: [
            `Now that you understand the difference between overhead and direct costs, we can talk about how to set your selling price.`,
            `That's the million-dollar question. Here's a good guideline to help you decide.`,
            `A big factor to consider is the demand for your product. If you're the only one selling a product that you developed yourself, or if you're selling a service or intellectual property, you can charge 100% more than your initial investment.`,
            `However, if you're trying to sell a product in a competitive market, you have to aggressively cut your prices. It's important to remember that as an online vendor, you have low overhead costs. So although you might have a small profit margin for each product you sell, you could make a high total revenue long-term.`,
          ],
        },
        {
          header: `How Do My Profit Margins Affect Sales?`,
          para: [
            `The smaller your profit margins are, the cheaper your product will be for your customers, which in turn makes for a faster sale of your products. When you raise your prices, you may end up losing customers who would have purchased your products faster had the price been more affordable.`,
          ],
        },
        {
          header: `Are There Risks to Price-Cutting?`,
          para: [
            `Definitely. Don’t price-cut exclusive or luxury items in your inventory. The low price can create a lower perceived value and damage your brand.`,
          ],
        },
        {
          header: `What's Considered a Good Profit Margin?`,
          para: [
            `In general, if your profit margin falls between 20–30%, that's considered a great profit. Any lower than that, you’re in danger of operating at a loss if you run into unexpected expenses, like returns of damaged goods. Any higher than that, you run the risk of your products being too expensive—causing your customers to take their business elsewhere.`,
          ],
        },
        {
          header: `One Last Thing…`,
          para: [
            `Here's a fundamental rule. Always remember that your customers are people just like you.`,
            `Treat them fairly, and the market will treat you fairly too.`,
            `At ShipWinner, we believe that giving our customers real value is the most important thing we can do. We're here to help send that message to your customers as well.`,
          ],
        },
      ],
    },
    {
      pageTitle: "How to Sell Your Handcrafted Products | ShipWinner",
      pageDescription: `What an incredible feeling it is to create. To wake up in the morning feeling inspired, and channel that inspiration into making something new. Many people dream of creating their own products, but only a select few make that dream a reality. So the fact that you belong to that exclusive group is amazing!`,
      title: "How to Sell Your Handcrafted Products",
      path: "how-to-sell-your-handcrafted-products",
      createDate: "Feb 5, 2020",
      blogHeader: "Promoting Your Handmade Business",
      img: CONSTANTS.STACK_IMAGES["image-5"],
      content: [
        {
          header: ``,
          para: [
            `One of the things that artists generally enjoy less is dealing with the technical aspects of their business. A key element is deciding where to sell your creations. If you do it right, it can go pretty smoothly and easily. We've compiled some info for you about where to market your products to help you grow your brand.`,
          ],
        },
        {
          header: `Where Should I Sell My Products?`,
          para: [
            `If you're a fashion or product designer, there are a several online options to help you get your art out into the world. You know your product is great, and you’re wondering how to best use your time to sell it as efficiently a possible.  There are lots of ecommerce platforms to choose from—<a target='_blank' href='https://www.amazon.com/'>Amazon</a>, <a target='_blank' href='https://www.ebay.com/'>eBay</a>, and <a href='https://www.etsy.com/' target='_blank'>Etsy</a>, to name a few, not to mention starting your own online store—and since each is different, the trick is to figure out which is best suited to you. Sometimes it's even worthwhile to sell your products on more than one platform.`,
            `So where should you sell your products? Let's break down the three main options to help you decide.`,
          ],
        },
        {
          header: `What am I Selling?`,
          para: [
            `If you're selling unique, handmade items, like trendy leather laptop sleeves, or chic silver jewelry, <a href='https://www.etsy.com/' target='_blank'>Etsy</a> is probably the best platform for you.`,
          ],
        },
        {
          header: `Why is Etsy Great for Artists?`,
          para: [
            `<a href='https://www.etsy.com/' target='_blank'>Etsy</a> is a marketplace created specifically for artists, crafters, and designers. Potential customers know that <a href='https://www.etsy.com/' target='_blank'>Etsy</a> is home to these creators, so they go there to find unique, high-quality items. In effect, <a href='https://www.etsy.com/' target='_blank'>Etsy</a> does a lot of the marketing for you. That's why it's worthwhile for you, as an artist, to become an <a href='https://www.etsy.com/' target='_blank'>Etsy</a> seller.`,
          ],
        },
        {
          header: `How Much Does Etsy Cost?`,
          para: [
            `Before selling on <a href='https://www.etsy.com/' target='_blank'>Etsy</a>, it’s important to know what it entails: How much does it cost? Is there a monthly fee? Do they take a commission?`,
            `<a href='https://www.etsy.com/' target='_blank'>Etsy</a> doesn't charge membership or subscription fees. Instead, merchants pay three main fees:
            <ul>
              <li>A Listing fee, which is $0.20 per item. Each item is listed for 4 months (or until sold).</li>
              <li>A Transaction fee, which is 5% of every purchase.</li>
              <li>A Payment Processing fee, which is 3% plus $0.25 per item (in the US).</li>
            </ul>
            <a target='_blank' href='https://www.etsy.com/'>GO TO etsy.com</a>`,
          ],
        },
        {
          header: `As an Artist, Should I Sell on Amazon or eBay?`,
          para: [
            `The simple answer is, why not? If you choose their cheapest selling plan and sell enough to cover your costs while reaching new customers, that’s fantastic. <a target='_blank' href='https://www.amazon.com/'>Amazon</a> offers two selling plans: a Professional plan and an Individual Plan.`,
            `<b>The Professional selling plan</b> is available for a monthly subscription fee of $39.99, plus referral fees and variable closing fees. If you plan on selling a lot of items, this is the right track for you. As long as your revenue exceeds your subscription fee, you'll be making a profit. `,
            `<b>The Individual selling plan</b> is best for people who are planning on selling less than 40 items a month. There's no monthly subscription fee; instead, you pay $0.99 per item that you sell, plus referral fees and variable closing fees.`,
            `<a target='_blank' href='https://www.amazon.com/'>GO TO amazon.com</a>`,
          ],
        },
        {
          header: `How Much Does it Cost to be an eBay Seller?`,
          para: [
            `<a target='_blank' href='https://www.ebay.com/'>eBay</a>'s system works a little differently. When you list an item on <a target='_blank' href='https://www.ebay.com/'>eBay</a>, you're charged an insertion fee—a fee for listing that item. The basic <a target='_blank' href='https://www.ebay.com/'>eBay</a> account allows for up to 50 listings each month with zero insertion fees. Once you exceed that allowance, each additional item is charged an insertion fee of $0.35. Aside from the insertion fee, <a target='_blank' href='https://www.ebay.com/'>eBay</a> charges a final value fee when you sell your item. The final value fee is a percentage of the selling price of your merchandise, and it varies by category.`,
            `<a target='_blank' href='https://www.ebay.com/'>GO TO ebay.com</a>`,
          ],
        },
        {
          header: `So Which is Better? Etsy, Amazon, or eBay?`,
          para: [
            `Every platform has its advantages, depending on the size and nature of your business. We'll start with smaller businesses, expanding from there.`,
            `If you're handcrafting your own products, <a href='https://www.etsy.com/' target='_blank'>Etsy</a> takes first place. It automatically attracts the clientele that will be drawn to your wares, and since it was developed with artists in mind, it will work well for your needs. Another advantage is that it's almost free (just a low listing fee) until you actually start selling.`,
            `<a target='_blank' href='https://www.ebay.com/'>eBay</a> takes second place. It's a convenient marketplace for designers and creators, but since that's not its primary purpose, potential customers have access to a wide variety of goods. Since they probably didn’t come to <a target='_blank' href='https://www.ebay.com/'>eBay</a> specifically to buy handmade, one-of-a-kind products, they might prefer the more common but cheaper option available on the site. At the same time, there's no monthly fee, and with the Individual plan, you only pay if you're selling your merchandise.`,
            `<a target='_blank' href='https://www.amazon.com/'>Amazon</a> is in the third place. It’s a huge ecommerce platform but, like <a target='_blank' href='https://www.ebay.com/'>eBay</a>, doesn't target artisans. <a target='_blank' href='https://www.amazon.com/'>Amazon</a> users perceive it as an extremely trustworthy platform, especially due to the seller review feature, but they don't necessarily care if what they're buying is a boutique product. It's also important to remember that <a target='_blank' href='https://www.amazon.com/'>Amazon</a> charges a monthly fee, unlike the other sites we covered. That being said, if your business is at a stage that on the one hand, it has the inventory to handle a large number of orders, and on the other hand, you're selling enough to cover your monthly fee and still generate revenue, then <a target='_blank' href='https://www.amazon.com/'>Amazon</a> has a lot to offer. The high traffic to the site means increased brand awareness and a larger customer base, which in turn means increased sales volume. In addition, <a target='_blank' href='https://www.amazon.com/'>Amazon's</a> fulfillment centers can be a huge boost to help you scale your business and reach even more customers.`,
          ],
        },
        {
          header: `Should I Also Start My Own Online Store?`,
          para: [
            `Absolutely! Even if you don’t generate any sales from your site, you should definitely open your own online store.`,
          ],
        },
        {
          header: `Wait. If I'm Already an Etsy, eBay, or Amazon Seller, Why Do I Need My Own Shop?`,
          para: [
            `Here's an analogy to explain why you should have your own online store, even if you're selling already on another platform. When you open a traditional store, you have to pay rent every month. If you don't bring in enough sales to cover that cost, you lose money. Now let's suppose you were given store space for free. If you don't put a big storefront sign outside, someone else will! The internet is the modern version of a mall, with one big difference: You get multiple selling platforms basically for free, with free opportunities to put up you sign and advertise it. `,
          ],
        },
        {
          header: `But What Are the Advantages of Having an Online Shop If I'm Not Selling From It?`,
          para: [
            `We're glad you asked.
            <ul>
              <li>Customers who have seen your products on other platforms can contact you through your site, cutting out the middleman and generating more revenue.</il>
              <li>Every time your brand or name is searched, your site and products will appear in the search results. This SEO (Search Engine Optimization) is worth its weight in gold, but we'll leave that for another blog.</il>
              <li>When you have your own site, it gives a better impression to clients who found you through big marketplaces like <a href='https://www.etsy.com/' target='_blank'>Etsy</a> or <a target='_blank' href='https://www.amazon.com/'>Amazon</a>. It makes you and your products seem more credible and trustworthy if your customers see that you're backed up by a brand.</il>
            </ul>`,
          ],
        },
        {
          header: `So How Do I Start Selling My Creations?`,
          para: [
            `<ol>
              <li>Read through the options, and decide which one makes the most sense for your brand.</li>
              <li>Build your own site! Even if it's just a single page through a site like WordPress.</li>
              <li>Most importantly… Get selling, so that you can create some more!</li>
            </ol>
            At ShipWinner, we'll always be here to cheer you on and help get your artwork to its new home, so that it can do what it’s meant to do… make more people happy.`,
            `Best of luck!!! `,
          ],
        },
      ],
    },
    {
      pageTitle:
        "5 Tips for Making Your Seasonal Business a Success | ShipWinner",
      pageDescription: `It's an amazing feeling when business is going well. You feel elated and empowered to see sales coming in and your shipments on their way to your customers. But what happens at the end of your busy period? Most businesses have to deal with fluctuations in demand at some point during the year. The trick is figuring out how to manage your business during the quieter times as well, keeping your brand strong.`,
      title: "5 Tips for Making Your Seasonal Business a Success",
      path: "5-tips-for-making-your-seasonal-business-a-success",
      createDate: "Feb 7, 2020",
      blogHeader:
        "5 Tips for Making Your Seasonal Business a Year-Round Success",
      img: CONSTANTS.STACK_IMAGES["image-6"],
      content: [
        {
          header: ``,
          para: [
            `There are two kinds of businesses: <b>Seasonal businesses</b> have a defined period of the year where their products are more relevant, so that's when the bulk of their sales takes place. Say, a company that sells ski poles, or a company that sells New Year's cards. <b>Non-seasonal businesses</b> sell products that are equally marketable the year-round. For example, a company that sells baby toys or cool keychains. Seasonal or not, sales are almost never at a constant, fixed state in any business, so it's important to plan for your company's slower times.`,
            `This blog is going to focus on seasonal businesses, but there are take-home messages that are applicable to non-seasonal businesses as well.`,
            `<b>How to Keep a Seasonal Business Profitable Year Round</b>`,
          ],
        },
        {
          header: `1. Increase Your Profit Margin`,
          para: [
            `Have you ever been walking in the city on a beautiful day? And then suddenly, the blue sky is filled with clouds and it starts to pour? Next thing you know there are stands everywhere selling umbrellas, popping up like mushrooms. Do you think that the umbrellas are being sold at their usual price? Probably not. Since people are willing to pay more for an umbrella right now, the umbrellas can be sold for more. That's increasing the profit margin due to increased demand.`,
            `Seasonal businesses can actually be more profitable than non-seasonal ones. The reason is rooted in supply and demand. Let's explain that.`,
            `The competition in your branch of business dictates the supply and demand, and therefore the selling price of your products. Here's the key: Seasonal businesses can have less competition than non-seasonal businesses. Year-round businesses constantly have to keep their prices competitive in a fixed market, whereas seasonal business' products are usually needed by people within a set time frame, allowing you to increase your profit margin. In addition, seasonal businesses often build their brand around the uniqueness of their products, like unusual holiday sweaters, heirloom seeds, or end-of-the-year gifts for teachers. It's often difficult to compare such specialized products, even within the same category. That means less competition, which in turn means you can increase your profit margin.`,
          ],
        },
        {
          header: `2. Summer Here Means it's Winter in Australia`,
          para: [
            `If your seasonal business primarily sells all-winter or all-summer products, zoom out and see if you can market globally instead of just locally. That way, you'll always have a target market available. This option might not be easily doable for smaller businesses, but it's worth thinking about. The world has gotten a lot smaller thanks to great shipping options, and you might just find your niche on the other side of the ocean.`,
          ],
        },
        {
          header: `3. Effective Financial Planning`,
          para: [
            `Sure, we all like to spend when we've got ready cash. The question is, are we really making a profit, and figuring out just how much of a profit. If we have a three-month busy selling period and the rest of the year we sell 50% less, that means we have to equally divide the sales from those three months over the remaining nine months of the year. It's important to identify and chart your yearly sales pattern, and then plan accordingly.`,
            `Let's say you're selling winter apparel or swimsuits. Probably, you're making a lot more sales during one half of the year compared to the other half. So for example, you make $10,000 during a good month and $5,000 during an off-season month. If you add it all up, on average, you make $7,500 a month. If you've figured out that your monthly profit is $7,500, you now know that you don’t have a spending budget of $10,000, even during a good month, right?`,
          ],
        },
        {
          header: `4. Selling Seasonal Products Year Round`,
          para: [
            `If you've built your brand around specialty winter hats or cozy winter blankets, expand your inventory to include summer hats or beach towels as well. That way, half your inventory is always in season, so you can take advantage of being a seasonal business (less competition and a higher profit margin) and still count on making a year-round profit.`,
          ],
        },
        {
          header: `5. Inventory Management`,
          para: [
            `Sometimes the most important contributors to successfully managing your business come down to the really simple things, like efficient inventory management.  Inventory management means having the right stock, in the right amount, in the right place, at the right time, and for the right cost. In other words, you want to have stock available when you need it, but having excess stock lying around is bad for your bottom line.`,
            `Good inventory management can double and triple your profits by reducing costs, improving cash flow, and raising your net revenue. Here's an anecdote to illustrate this. Steve Jobs held Tim Cook in high regard due to his incredible accomplishments handling Apple's inventory. At one point, Apple was selling its merchandise within hours of receiving it! That's an immediate return on investment. Imagine how efficient that makes your business in terms of profitability—especially since what's selling this year may not be relevant anymore next year.`,
            `Though you need enough inventory on hand to supply your products, it's also important not to have a surplus. If you sell 100 products and make a profit of $50 on each one, but you have 100 more of that product sitting in storage, then your net profit is…0! That's why good inventory management is essential, particularly concerning seasonal products.`,
          ],
        },
        {
          header: `Let's Sum it Up`,
          para: [
            `To summarize, you can earn quite a lot as a seasonal brand if you follow these rules:
            <ol>
              <li>Increase your profit margin. You can do this because your products are seasonal.</li>
              <li>Look to the global market to find an alternative target audience.</li>
              <li>Identify and chart your yearly sales pattern, and make a strong financial plan.</li>
              <li>Sell seasonal products for all the seasons of the year.</li>
              <li>Three words: Inventory, inventory, and inventory!</li>
            </ol>
            At ShipWinner, we believe in giving our clients the best information we can, to help you along on your business journey. You've got this.`,
            `Best of luck!`,
          ],
        },
      ],
    },
    {
      pageTitle:
        "Maximizing Profits: Streamlining through Shipping! | ShipWinner",
      pageDescription: `It's essential to streamline operations wherever possible. This allows you to make the best use of your resources, without wasting time and money on unnecessary methods or costs. Sometimes, when sales are going well, it's hard to take a step back and analyze how your business can increase its efficiency. But when sales are slower than you'd like, it's especially important to take the opportunity and see where you can streamline your business.`,
      title: "Maximizing Profits: Streamlining through Shipping!",
      path: "maximizing-profits-streamlining-through-shipping",
      createDate: "March 8, 2020",
      blogHeader:
        "Efficiency is the key to running a successful business in the competitive arena of e-commerce.",
      img: CONSTANTS.STACK_IMAGES["image-7"],
      content: [
        {
          header: ``,
          para: [
            `When you start thinking about streamlining, there are a lot of questions that come up:`,
            `How can you identify areas to streamline?`,
            `How can you sell more efficiently?`,
            `How can you offer your customers lower prices without losing money?`,
            `The answer to all these questions lies in shipping.`,
          ],
        },
        {
          header: `Fulfillment Centers`,
          para: [
            `A fulfillment center is a third-party logistics provider (also known as a 3PL). Its job is to store sellers' inventory in their warehouses, and take care of the whole process of fulfillment, i.e. getting your products to your customers. If you’re an Amazon seller, you're probably familiar with the Fulfillment by Amazon (FBA) service. While convenient, 3PLs have their disadvantages, starting with the fee. Each fulfillment service has its own pricing model; we'll talk about the three main components:`,
            `<b style='text-decoration:underline;' >Monthly Inventory Storage Fees:</b> This is a fee per cubic foot to store your inventory until it's sold and shipped.`,
            `<b style='text-decoration:underline;' >Package Dimension:</b> The size of your package has a big impact on the shipping price, and therefore on your profit margin.`,
            `<b style='text-decoration:underline;' >Package Weight:</b> This factor is mostly only significant if your package's weight is unusually substantial. For example, if you're shipping lead weights in a shoebox, the shipping rate will increase dramatically—despite the small package size.`,
            `The package dimensions have the biggest effect on your shipping costs, so here's an important guideline for fulfillment: <b>Do whatever you can to ship your products in the smallest possible packaging!</b>`,
            `Now that you've selected the smallest package to ship your product, let's talk about different shipping options.`,
            `Either you can use FBA or similar fulfillment services by other companies, or you can ship your products directly to your customers yourself, known as self-fulfillment.`,
          ],
        },
        {
          header: `When Should You Choose Self-Fulfillment?`,
          para: [
            `If your customers are buying from you because they want your specific brand, not a generic run-of-the-mill product, that's a good reason for you to choose self-fulfillment. Your customers will probably start out by searching specifically for you on Google, which will lead them to your site. Since it's your own site, you can save on fulfillment costs, meaning you can sell your products for a lower price without affecting your profit margin. An added benefit of selling from your own site is developing a customer base independent of a third-party platform. That means less competition than in a bigger marketplace, in addition to the opportunity to develop your brand. You get to control everything about the way you sell and showcase your brand presence—from your display to your content.`,
            `Sometimes it's still better to ship your packages yourself, even when you're selling through Amazon or any other e-commerce platform. Sometimes you can find a fantastic deal with a shipping company which costs less than the rates offered by a fulfillment service.`,
            `Another example for when self-fulfillment is preferable is when you're distributing your products locally. Let’s say you're selling catered meals in New Hampshire. You'll be able to deliver your goods for much less if you use a local courier instead of a national carrier.`,
            `If you're selling seasonal goods, that also might be a good reason to ship your products yourself. That way, you don’t have to pay storage fees to a fulfillment service for the off-season, when you're not actually selling your products.`,
            `This may also be the right choice for you if you prefer to deal directly with your clients, without a go-between. This allows you more control, as well as a chance to connect with your customers through advertising.`,
            `Something to keep in mind is that self-fulfillment provides an opportunity to brand your shipping in ways that aren't an option with fulfillment services. Develop a relationship with your customers by adding personalized touches to your packages to create a memorable unboxing experience. These can be anything from customized labels or personal notes, to branded boxes or color-coordinated tissue paper. You can also add promotional material to your packages to encourage future purchases from your brand.`,
          ],
        },
        {
          header: `Shipping Packages Yourself? Great. Here's the Best Way to Ship.`,
          para: [
            `If you've chosen self-fulfillment, the next step is to decide on the best shipping option.`,
            `Here are some tips to help you:
          <ol>
            <li><b>Minimal Packaging:</b> That rule about shipping with the smallest possible package? Still relevant for self-fulfillment. Choose the smallest box that will snugly fit your product to save on shipping.</li>
            <li><b>Compare rates:</b>Compare shipping rates between shipping comparison companies like Parcel Monkey, ShipGooder, or ShipWinner. Alternatively, go directly to FedEx, UPS, or USPS and search their sites for the best available rate for your package.</li>
            <li><b>Drop-off:</b>If you don’t need pickup services, you can save on shipping with the drop-off option. You can drop off several packages at once at each drop-off location. Find the one that's most convenient for you.</li>
            <li><b>Shipping Services:</b> Twenty-four-hour delivery is going to be much more expensive than using ground shipping with FedEx, UPS, or USPS. There's a range of prices for different services; make sure you're choosing the one you need.</li>
            <li><b>Insurance:</b>Did you know that FedEx and UPS offer the first $100 of shipping insurance for free? However, be aware that if your package's value exceeds $100, you'll have to pay the full amount. If this is the case, carefully consider whether the insurance is worth it to you.</li>
            <li><b>Local Clients:</b>If you're shipping your package to a nearby destination, you can save while still providing prompt shipping. FedEx offers ground shipping (7-day delivery) vs more expensive overnight shipping. If you’re shipping locally, you can use the cheaper ground option and your package will still get to your client in less than 7 days.</li>
            <li><b>Split or Combine Packages:</b>Sometimes, the weight and dimensions of one large package will be far more expensive than the same products split into two or three smaller packages. Sometimes the opposite is the case, and it's better to combine packages. See what makes the most sense for each package.</li>
            <li><b>Compare Prices Between Carriers!</b>Ground shipping with one carrier may be almost as expensive as two-day shipping with another. It's common to find significant price differences between FedEx and UPS, for example, depending on the shipping service you choose. Each carrier has its own price calculation; carefully check out the different options, or use <a target='_blank' href='https://www.shipwinner.com'>ShipWinner's rate comparison tool.</a></li>
            <li><b>Provide Real Value for Your Customers:</b>Your customers, like you, know real value when they see it. Self-fulfillment allows you to send anything you'd like in your package—a small freebie, a gift card, coupons, raffle tickets, sales information, or a personal letter. Take this opportunity to really connect with your client and make a good impression.</li>
            <li><b>Feedback:</b>Self-fulfillment is an opportunity to ask for real, honest feedback from your customers. Feedback is a valuable tool to improve your business's success; it can help you find the areas you need to improve or update to make your business the absolute best it can be.</li>
          </ol>`,
            `Remember… satisfied customers will keep coming back to you again and again, and word-of-mouth advertising is something that can significantly strengthen your brand as well. Make your customers happy by streamlining your business, giving them the best value for the best price`,
            `At ShipWinner, we're big believers in providing valuable information for our clients. Here's to streamlining and expanding your business, starting today!`,
          ],
        },
        {
          header: ``,
          para: [``, ``, ``, ``],
        },
        {
          header: ``,
          para: [``, ``, ``, ``],
        },
        {
          header: ``,
          para: [``, ``, ``, ``],
        },
        {
          header: ``,
          para: [``, ``, ``, ``],
        },
        {
          header: ``,
          para: [``, ``, ``, ``],
        },
        {
          header: ``,
          para: [``, ``, ``, ``],
        },
        {
          header: ``,
          para: [``, ``, ``, ``],
        },
        {
          header: ``,
          para: [``, ``, ``, ``],
        },
        {
          header: ``,
          para: [``, ``, ``, ``],
        },
        {
          header: ``,
          para: [``, ``, ``, ``],
        },
      ],
    },
  ],
};

const PACKAGE_DETAILS_CONTENT = {
  title: "Pacage-Details",
  companies: [
    {
      id: "1",
      pageTitle: "FedEx Package Information | ShipWinner",
      pageDescription:
        "All about FedEx Packages. Understand more about FedEx Packages, and Save up to 62% on Shipping with ShipWinner. | ShipWinner.com",
      title: "FedEx",
      path: "fedex",
      createDate: "July 1, 2019",
      blogHeader: "FedEx",
      img: {
        svg: "fedex",
      },
      packages: [
        {
          id: 5,
          title: "Envelope",
          pageTitle: "FedEx Envelope | ShipWinner",
          pageDescription:
            "About FedEx Envelope. FedEx Envelope is a standard size and weight. Ship with ShipWinner for Reduced Rates, and Save up to 62% on Shipping. | ShipWinner.com",
          path: "fedex-envelope",
          createDate: "July 26, 2019",
          blogHeader: "FedEx Envelope",
          canShip: false,
          img: {
            baseUrl: path + "images/pages/fedex-packages-img/fedex_envelope",
            sizes: ["320w"],
            format: "png",
          },
          content: [
            {
              header: "Description",
              para: [
                "The FedEx Envelope is perfect for sending documents. It can hold up to 60 A4 pages. Whether you're sending family records, work reports or anything in between, ShipWinner will find the best rate to ship your FedEx Envelope.",
              ],
            },
            {
              header: "Dimensions",
              para: ['Length 9-1/2", Width 12-1/2"'],
            },
            {
              header: "Max Weight",
              para: ["1 lbs"],
            },
          ],
        },
        {
          id: 9,
          title: "Pak",
          path: "fedex-pak",
          pageTitle: "FedEx Pak Box | ShipWinner",
          pageDescription:
            "About FedEx Pak. Total weight (including contents, documentation and packaging) must not exceed 20 lbs. A special FedEx Pak rate applies up to this weight. Ship with ShipWinner to Save up to 62% on Shipping. | ShipWinner.com",
          createDate: "July 26, 2019",
          blogHeader: " FedEx Pak",
          canShip: false,
          img: {
            baseUrl: path + "images/pages/fedex-packages-img/fedex_pak",
            sizes: ["320w"],
            format: "png",
          },
          content: [
            {
              header: "Description",
              para: [
                "The FedEx Pak is perfect for larger documents, but it can also ship any items that you can fit in it. What will you put in your FedEx Pak? Ship it with ShipWinner for reduced shipping rates!",
              ],
            },
            {
              header: "Small",
              para: ['Length 10-1/4", Width 12-3/4"'],
            },
            {
              header: "Max Weight:",
              para: ["20 lbs"],
            },
          ],
        },
        {
          id: 11,
          title: "Tube",
          path: "fedex-tube",
          pageTitle: "FedEx Tube | ShipWinner",
          pageDescription:
            'About FedEx Tube. Dimensions: Height 38" (96.5 cm), Width 6" (15.2 cm), Depth 6" (15.2 cm). Ship with ShipWinner to Save up to 62% on Shipping. | ShipWinner.com',
          createDate: "July 26, 2019",
          blogHeader: " FedEx Tube",
          canShip: true,
          img: {
            baseUrl: path + "images/pages/fedex-packages-img/fedex_tube",
            sizes: ["320w"],
            format: "png",
          },
          content: [
            {
              header: "Description",
              para: [
                "When the item that you're sending is better off being rolled rather than folded, the FedEx Tube is the perfect choice. Its triangular shape is well-suited to shipping rolled drawings, blueprints, charts, or large photographs. ShipWinner will find you the best rate to get your FedEx Tube where it's headed!",
              ],
            },
            {
              header: "Dimensions",
              para: ['Length 6", Width 6", Height 38"'],
            },
            {
              header: "Max Weight:",
              para: ["20 lbs"],
            },
          ],
        },
        {
          id: 10,
          title: "Small Box",
          path: "fedex-small-box",
          pageTitle: "FedEx Small Box | ShipWinner",
          pageDescription:
            'About FedEx Small Box. Dimensions: Length (10-7/8"), Width ( 1-1/2"),Height(12-3/8"). Ship with ShipWinner to Save up to 62% on Shipping. | ShipWinner.com',
          createDate: "July 26, 2019",
          blogHeader: "FedEx Small Box ",
          canShip: false,
          img: {
            baseUrl: path + "images/pages/fedex-packages-img/fedex_small_box",
            sizes: ["320w"],
            format: "png",
          },
          content: [
            {
              header: "Description: ",
              para: [
                "The FedEx Small Box can comfortably fit file folders, catalogues, videotapes, CDs, or even a single reel of magnetic tape. ShipWinner will find you the best rate to ship your FedEx Small Box. Just enter the shipping information above, and click Get Your Price!",
              ],
            },
            {
              header: "S1: Rectangular and shallow",
              para: ['Dimensions: Length 10-7/8", Width 1-1/2", Height12-8/8”'],
            },
            {
              header: "S2: Rectangular and deep",
              para: [
                'Dimensions: Length 8-3/4", Width 2-11/16", Height 11-5/16”',
              ],
            },
            {
              header: "Max Weight",
              para: ["20 lbs"],
            },
          ],
        },
        {
          id: 8,
          title: "Medium Box ",
          path: "fedex-medium-box",
          pageTitle: "FedEx Medium Box | ShipWinner",
          pageDescription:
            'About FedEx Medium Box. Inside dimensions: Length (11-1/2"), Width 2-3/8"),Height(13-1/4"). Ship with ShipWinner to Save up to 62% on Shipping. | ShipWinner.com',
          createDate: "July 26, 2019",
          blogHeader: "FedEx Medium Box",
          canShip: false,
          img: {
            baseUrl: path + "images/pages/fedex-packages-img/fedex_medium_box",
            sizes: ["320w"],
            format: "png",
          },
          content: [
            {
              header: "Description",
              para: [
                "The FedEx Medium Box is ideal for shipping DVDs, CDs or video games. It's also a good choice for books, documents and binders. Soft goods, like bedding or clothing, work well in here too! Shipping with ShipWinner gets your goods to their destination for the best shipping rate available. ShipWinner.com.",
              ],
            },
            {
              header: "M1: Rectangular and shallow",
              para: ["Length 11-1/2”, Width 2-3/8”, Height 13-1/4”"],
            },
            {
              header: "M2: Rectangular and deep",
              para: ['Length 9", Width 5", Height 12”'],
            },
            {
              header: "Max Weight:",
              para: ["20 lbs"],
            },
          ],
        },
        {
          id: 7,
          title: "Large Box ",
          path: "fedex-large-box",
          pageTitle: "FedEx Large Box | ShipWinner",
          pageDescription:
            'About FedEx Extra Large Box. Dimensions: Length (12-3/8"), Width (3"),  Height(17-1/2"). Ship with ShipWinner to Save up to 62% on Shipping. | ShipWinner.com',
          createDate: "July 26, 2019",
          blogHeader: "  FedEx Large Box",
          canShip: false,
          img: {
            baseUrl: path + "images/pages/fedex-packages-img/fedex_large_box",
            sizes: ["320w"],
            format: "png",
          },
          content: [
            {
              header: "Description",
              para: [
                "The FedEx Large Box is just the right size for side-by-side paper stacks or oversize binders. It can also fit soft goods, toys, houseware or sports accessories. Whatever you decide to put in it, ShipWinner will find you the best rate to ship it to its destination!",
              ],
            },
            {
              header: "L1: Rectangular and shallow",
              para: ["Length 12-3/8”, Width  3”, Height 17-1/2”"],
            },
            {
              header: "L2: Rectangular and deep:",
              para: ['Length 8-3/4", Width 7-3/4", Height 11-5/16”'],
            },
            {
              header: "Max Weight:",
              para: ["20 lbs"],
            },
          ],
        },
        {
          id: 6,
          title: "Extra Large Box",
          path: "fedex-extra-large-box",
          pageTitle: "FedEx Extra Large Box | ShipWinner",
          pageDescription:
            'About FedEx Extra Large Box. Dimensions: Length (11-7/8"), Width (10-13/16"),Height(11-1/16"). Ship with ShipWinner to save up to 62% on Shipping. | ShipWinner.com',
          createDate: "July 26, 2019",
          blogHeader: "FedEx Extra Large Box",
          canShip: false,
          img: {
            baseUrl:
              path + "images/pages/fedex-packages-img/fedex_extra_large_box",
            sizes: ["320w"],
            format: "png",
          },
          content: [
            {
              header: "Description",
              para: [
                "If you want to ship books, binders, or houseware, the FedEx Extra Large Box is the right choice for you. This box is also great for houseware or sporting gear, or other circular or cubed-shaped items. You can even fill it with soft goods, like bedding or apparel. Ship with ShipWinner for discounts on shipping your FedEx Extra Large Box today!",
              ],
            },
            {
              header: "X1: Cube-shaped",
              para: ["Length 11-7/8”, Width  10-13/16”, Height 11-1/16”"],
            },
            {
              header: "X2: Rectangular-shaped",
              para: ['Length 15-3/4", Width 14-3/16", Height 6”'],
            },
            {
              header: "Max Weight:",
              para: ["20 lbs"],
            },
          ],
        },
        {
          id: 1,
          title: " Custom Package",
          path: "fedex-custom-package",
          pageTitle: "FedEx Custom Package | ShipWinner",
          pageDescription:
            "About FedEx Custom Package. Choose your shipment's dimensions with the FedEx Custom Package Deal! Ship with ShipWinner for Reduced Rates. | ShipWinner.com",
          createDate: "July 26, 2019",
          blogHeader: "  FedEx Custom Package",
          canShip: true,
          img: {
            baseUrl:
              path + "images/pages/fedex-packages-img/fedex_extra_large_box",
            sizes: ["320w"],
            format: "png",
          },
          content: [
            {
              header: "Description: ",
              para: [
                "Sometimes you have a package that needs its own special box. Maybe it's unusually shaped or fragile. Maybe you have the perfect box for your contents on hand. Or maybe it's just more convenient. With the FedEx Custom Package, ShipWinner makes sure you get the best possible shipping rate while using your fully customized box. Fill in the dimensions, and print your shipping label!",
              ],
            },
            {
              header: "Max Weight:",
              para: ["150 lbs"],
            },
          ],
        },
      ],
    },
    {
      pageTitle: "UPS Package Information | ShipWinner",
      pageDescription:
        "Everything you need to know about UPS Package options. Ship with ShipWinner for up to 50% Savings on Shipping!  | ShipWinner.com",
      id: "1",
      title: "UPS",
      path: "ups",
      createDate: "July 26, 2019",
      blogHeader: "UPS",
      img: {
        svg: "ups",
      },
      packages: [
        {
          id: 13,
          title: "Letter",
          path: "ups-letter",
          pageTitle: "UPS Letter | ShipWinner",
          pageDescription:
            'About UPS Letter. Dimensions: Length (12.5-15"), Width (9.5"). Ship with ShipWinner to Save up to 50% on Shipping. | ShipWinner.com',
          createDate: "July 26, 2019",
          blogHeader: "UPS Letter",
          canShip: false,
          img: {
            svg: "letter",
          },
          content: [
            {
              header: "Description",
              para: [
                "The UPS Letter is a strong envelope designed for shipping documents that are legal-sized or smaller. Ship with ShipWinner to get all the advantages of UPS deliveries, at a reduced rate. Enter your package's details above to get your price now!",
              ],
            },
            {
              header: "Dimensions",
              para: ['Length 12.5-15", Width 9.5"'],
            },
            {
              header: "Max Weight:",
              para: ["70 lbs"],
            },
          ],
        },
        {
          id: 20,
          title: "Express Box - Small",
          path: "ups-express-box-small",
          pageTitle: "UPS Small Express Box | ShipWinner",
          pageDescription:
            'About UPS Small Express Box. Dimensions: 13" x 11" x 2". Ship with ShipWinner to Save up to 50% on Shipping. | ShipWinner.com',
          createDate: "July 26, 2019",
          blogHeader: " UPS Express Box - Small",
          canShip: true,
          img: {
            svg: "expressBox",
          },
          content: [
            {
              header: "Description",
              para: [
                "The UPS Small Express Box is the perfect size for documents, but there's a lot more you can fit in it. CDs, videotapes, and file folders are just a few of the things you can ship in this compact package. ShipWinner will find you the best rate to send your package on its way. Save it to your Package List with its contents to send the perfect package next time too!",
              ],
            },
            {
              header: "Dimensions:  ",
              para: ['Length 13", Width 11", Height 2"'],
            },
            {
              header: "Max Weight:",
              para: ["150 lbs"],
            },
          ],
        },
        {
          id: 21,
          title: "Express Box-Medium",
          path: "ups-express-box-medium",
          pageTitle: "UPS Medium Express Box | ShipWinner",
          pageDescription:
            'About UPS Medium Express Box. Dimensions: 16" x 11" x 3". Ship with ShipWinner to Save up to 50% on Shipping. | ShipWinner.com',
          createDate: "July 26, 2019",
          blogHeader: " UPS Express Box-Medium",
          canShip: true,
          img: {
            svg: "expressBox",
          },
          content: [
            {
              header: "Description",
              para: [
                "The UPS Medium Express Box is a great choice for computer printouts and electronic parts, but it can also fit so much more. Catalogues and books would work well, or even apparel or soft goods. Shipping with ShipWinner can save up to 50% on shipping costs, and you can save your perfected package in your Package List for next time!",
              ],
            },
            {
              header: "Dimensions",
              para: ['Length 16", Width 11", Height 3"'],
            },
            {
              header: "Max Weight:",
              para: ["150 lbs"],
            },
          ],
        },
        {
          id: 22,
          title: "Express Box-Large",
          path: "ups-express-box-large",
          pageTitle: "UPS Large Express Box | ShipWinner",
          pageDescription:
            'About UPS Large Express Box. Inside dimensions: 18" x 13" x 3"; weight limit: 150 lbs. Ship with ShipWinner for up to 50% Savings on Shipping Costs. | ShipWinner.com',
          createDate: "July 26, 2019",
          blogHeader: "UPS Express Box-Large ",
          canShip: true,
          img: {
            svg: "expressBox",
          },
          content: [
            {
              header: "Description",
              para: [
                "UPS Express Boxes' wide, flat shape make them a versatile shipping option for a range of items. Use them for computer printouts, electronic parts, books or catalogues. Once you've found the right fit for your box, you can add it to the Package List so it's ready for next time! With ShipWinner's reduced rates, you can save up to 50% of your shipping costs.",
              ],
            },
            {
              header: "Dimensions:  ",
              para: ['Length 18", Width 13",Height 3"'],
            },
            {
              header: "Max Weight:",
              para: ["150 lbs"],
            },
          ],
        },
        {
          id: 1,
          title: "Custom Package ",
          path: "ups-custom-package",
          pageTitle: "UPS Custom Package | ShipWinner",
          pageDescription:
            "About UPS Custom Package. Fully personalize your box by choosing its dimensions yourself with the UPS Custom Package deal. Ship with ShipWinner for up to 50% Savings on Shipping Costs. | ShipWinner.com",
          createDate: "July 26, 2019",
          blogHeader: "UPS Custom Package",
          canShip: true,
          img: {
            svg: "customBox",
          },
          content: [
            {
              header: "Description",
              para: [
                "Custom Packages are the most convenient way to ship your package, because you can use any box you have on hand. If your item is bulky, oversized, or fragile, you might decide that a Custom Package is the way to go. Enter your box's dimensions above to get ShipWinner's discounted shipping rate!",
              ],
            },
            {
              header: "Max Weight:",
              para: ["150 lbs"],
            },
          ],
        },
        {
          id: 14,
          title: " Tube",
          path: "ups-tube",
          pageTitle: "UPS Tube | ShipWinner",
          pageDescription:
            'About UPS Tube: Triangular tube for rolled papers. Dimensions: 38" x 6" x 6". Ship with ShipWinner for up to 50% Savings on Shipping. | ShipWinner.com',
          createDate: "July 26, 2019",
          blogHeader: "Ups Tube",
          canShip: true,
          img: {
            svg: "tube",
          },
          content: [
            {
              header: "Description: ",
              para: [
                "When you want to ship posters, drawings, or blueprints, it's always better to roll them instead of folding them. The UPS Tube's triangular shape allows you to safely ship anything that's better off being rolled. It's also perfect for large photographs, charts, or maps. Enter your package's details above to get ShipWinner's reduced shipping rates on your UPS Tube!",
              ],
            },
            {
              header: "Dimensions:",
              para: ['Length 38", Width 6", Height 6"'],
            },
            {
              header: "Max Weight: ",
              para: ["150 lbs"],
            },
          ],
        },
        {
          id: 15,
          title: " Pak",
          path: "ups-pak",
          pageTitle: "UPS Pak | ShipWinner",
          pageDescription:
            'About UPS Pak. Dimensions: 17" x 13" x 1". Ship with ShipWinner for up to 50% Savings on Shipping. | ShipWinner.com',
          createDate: "July 26, 2019",
          blogHeader: "Ups Pak",
          canShip: true,
          img: {
            svg: "pack",
          },
          content: [
            {
              header: "Description: ",
              para: [
                "The UPS Pak is a cardboard box that wraps around your documents or media to provide tight, secure packaging. It's especially suited to heavier documents. Ship your UPS Pak with ShipWinner for reduced shipping rates!",
              ],
            },
            {
              header: "Dimensions:",
              para: ['Length 17", Width 13", Height 1"'],
            },
            {
              header: "Max Weight: ",
              para: ["150 lbs"],
            },
          ],
        },
      ],
    },
  ],
};

const CSV_MAIN_PAGE = `
  <p><h3>How to Ship Multiple Orders Simultaneously with a CSV File</h3>
  Are you a seller on Amazon, eBay, or Etsy? Fantastic!
  <br /><br />
  You can upload files from your shipping platform or your own personal file, and we'll find you the shipping services you need—for the lowest rate on the market.
  <br /><br />
  First time using ShipWinner Business? Welcome! We're happy to show you the ropes.
  <br /><br />
  Download our <a download href='https://www.shipwinner.com/7ffcce92-3753/sample.csv'>sample file</a> to see what information is necessary to upload multiple shipments at once.
  <br /><br />
  Open the sample file once it's downloaded.
  <br /><br />
  <h3>Step 1: Filling In the Package Information</h3>
  The sample file is made up of columns and rows.
  <br /><br />
  The title above each column explains what information should be filled in:<br /><br />
  Recipient: Fill in your client's name.<br /><br />
  Sender's Address: Fill in your address.<br /><br />
  Height/Width/Length: Fill in the package's dimensions.<br /><br />
  Weight: Fill in the package's weight.<br /><br />
  Pickup or Drop Off: Fill in either option according to your preference.<br /><br />
  Insurance: Specify whether you'd like to insure the package, and for how much.<br /><br />
  Filled out all the fields on the form? Great! Time for the next step.
  <br /><br />
  <h3>Step 2: Uploading a File To ShipWinner</h3>
  Now that you've seen how to fill out the sample file, you can upload your own file with your customers' information. Before uploading, be sure to verify that all the details are correct. Then click Upload CSV.
  <br /><br />
  <h3>Step 3: Verification</h3>
  Our system will double-check the data in your file. Any errors will be marked with a red line, and missed fields will be marked with white squares.
  <br /><br />
  All set? Great. You're almost done.
  <br /><br />
  <h3>Step 4: Get the Best Price</h3>
  Click Get the Best Price, and wait a few seconds (depending on the size of the file) for us to find you the best price for your shipment. Don’t exit this page.
  <br /><br />
  <h3>Step 5: Here's the Best Price</h3>
  Congratulations! You've found the best rate to ship your package list.
  <br /><br />
  The system will display a list of great prices and services tailored to your needs. All that's left is for you to choose the price and service that work best for you.
  <br /><br />
  <h3> Step 6: Confirm Your Order</h3>
  Confirm your details and payment information. Now you're ready to print your shipping labels.
  <br /><br />
  <h3> Step 7: Print Your Label</h3>
  Click Print Label, and your printing labels will appear in a pop-up PDF file. You can save it to your computer, or print it right away from any printer. A copy will also be emailed to you, and you can always access it again in My Account. Just stick the labels onto your packages and you’re good to go.
  <br /><br />
  That's it! All your packages' information, including tracking, printing return labels, and claims follow-up are saved and accessible in My Account.
  <br /><br />
  What Do I Do With My Labeled Packages?
  <br /><br />
  <ul>
    <li>If you chose the Pickup option from FedEx, UPS, or USPS,  the company will come to your address to collect the packages. You schedule the pickup time when you select the Pickup option.</li>
    <li>If you chose the Drop Off option, take your packages to the nearest drop box. For example, if you chose FedEx, click here to search for the FedEx Drop Box nearest to your location. If you chose UPS, click here to search for the nearest UPS Drop Box. For the nearest USPS Collection Box, click here.</li>
    <li>After sending your packages, you can track them in My Account to make sure everything is going according to plan.</li>
    <li>If any problems arise with your package, you can contact the shipping companies directly with your package's tracking number to resolve the issue.</li>
    <li>If your package was lost or damaged, contact us and we'll be happy to help.</li>
  </ul>
  The ShipWinner Team is here to assist you! Please don't hesitate to reach out with any questions or concerns.
  </p>`;

export { PACKAGE_DETAILS_CONTENT, BLOG_CONTENT, CSV_MAIN_PAGE };
