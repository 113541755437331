/* eslint default-case: off, eqeqeq: off, react-hooks/exhaustive-deps: off */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTitle } from "hookrouter";
import { connect, useDispatch } from "react-redux";
import { Colors } from "../common/colors";
import IMAGES from "../common/images";
import CONSTANTS from "../common/constants";
import {
  setupAnimatedLoader,
  api_column_mapping_get_list,
  api_column_mapping_add,
  modalQueue,
  save_orders,
  genericSetValue,
  api_column_mapping_remove,
} from "../redux/actions";
import {
  setHeadMetaDescription,
  navigateToPath,
  showErrorMessage,
  logEventForAnalytics,
} from "../common/functions";
import { get, range } from "lodash";
import PigeItSelect from "./PigeItSelect.component";
import { Button } from "@material-ui/core";

function MultiplePackagesStage1Component(props) {
  const dispatch = useDispatch();
  const headers = get(props, "headers", []);
  const firstRow = get(props, "csvRows[0]", []);
  const [selectedMapping, setselectedMapping] = useState(
    get(props, "selectedMapping", { text: "Default", value: null })
  );
  const [changed, setchanged] = useState(false);
  const [valuesMap, setvaluesMap] = useState(
    CONSTANTS.CSV_HEADERS.reduce((a, b) => {
      a[b] = headers.indexOf(b) !== -1 ? b : null;
      return a;
    }, {})
  );
  useEffect(() => {
    logEventForAnalytics({ event: "businesses-page-1" });
  }, []);
  useEffect(() => {
    if (get(props, "csvRows", []).length === 0) {
      navigateToPath("businesses");
    }
  }, []);

  useEffect(() => {
    if (!get(selectedMapping, "value", false)) {
      setvaluesMap(
        CONSTANTS.CSV_HEADERS.reduce((a, b) => {
          a[b] = headers.indexOf(b) !== -1 ? b : null;
          return a;
        }, {})
      );
    } else {
      let mapping = get(
        get(props, "getColumnMapping.result.results", []).find(
          (map) => map.id === selectedMapping.value
        ),
        "mapping",
        []
      );
      if (Array.isArray(mapping)) {
        mapping = mapping.reduce((a, b) => {
          a[get(b, "source")] = get(b, "target");
          return a;
        }, {});
        setvaluesMap(mapping);
      }
    }
  }, [selectedMapping]);

  useEffect(() => {
    dispatch(
      genericSetValue({
        path: "ui.business.selectedMapping",
        value: selectedMapping,
      })
    );
  }, [selectedMapping]);

  return (
    <MultiplePackagesStage1Style>
      <div className="title">Map Values</div>
      <div className="sub-title">Map Values</div>
      <div className="mapping-container display-flex fjc-space-between">
        <div className="saved-mappings">
          <label className="select-label">Saved mappings</label>
          <PigeItSelect
            className="select-mapping"
            valueList={[{ text: "Default", value: null }, ...getMapOptions()]}
            value={selectedMapping}
            onChange={(val) => setselectedMapping(val)}
          />
        </div>
        <Button className="continue-button" onClick={applyMap}>
          CONTINUE
        </Button>
      </div>
      <form className="main-container">
        <div className="row-container desktop-component display-flex fjc-space-between">
          <div className="text">Header</div>
          <div className="text">Your Header</div>
          <div className="first-row">First Row</div>
        </div>
        {CONSTANTS.CSV_HEADERS.map((header, i) => {
          return (
            <React.Fragment key={i}>
              <hr className={i === 0 ? "desktop-component" : ""} />
              <div className={"row-container display-flex fjc-space-between"}>
                <div className="text">{header}</div>
                <PigeItSelect
                  className="select-header"
                  valueList={[
                    ...headers.map((h, i) => ({ text: h, value: h })),
                    { text: "Ignore", value: null },
                  ]}
                  value={{
                    text: valuesMap[header] || "Ignore",
                    value: valuesMap[header],
                  }}
                  onChange={(val) => {
                    setvaluesMap({ ...valuesMap, [header]: val.text });
                    setchanged(true);
                  }}
                />
                <div className="first-row desktop-component">
                  {get(
                    firstRow,
                    "[" + headers.indexOf(valuesMap[header]) + "]",
                    ""
                  )}
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </form>
    </MultiplePackagesStage1Style>
  );

  function getMapOptions() {
    return get(props, "getColumnMapping.result.results", []).map((map) => ({
      text: (handleClick, i, textOnly) =>
        textOnly ? (
          map.name
        ) : (
          <div
            key={i}
            className="display-flex fjc-space-between custom-button-container"
          >
            <button className="select-custom-button" onClick={handleClick}>
              {map.name}
            </button>
            <button
              className="select-delete-button"
              onClick={deleteMappingHandler(map.id, map.name)}
            >
              <IMAGES.bin />
            </button>
          </div>
        ),
      value: map.id,
    }));
  }

  function deleteMappingHandler(id, name) {
    return () => {
      let modalConfig = {
        ...CONSTANTS.MODALS_CONFIG.MESSAGE,
        message: `Do you want to delete '${name}' mapping?`,
        buttons: {
          ok: { visible: true, text: "YES" },
          cancel: { visible: true, text: "NO" },
        },
        callback: (res) => {
          if (res.confirm) {
            dispatch(
              setupAnimatedLoader({
                isVisible: true,
                text: "Deleting mapping...",
              })
            );
            dispatch(
              api_column_mapping_remove({
                id: String(id),
                callback: (result) => {
                  if (result.success) {
                    if (parseInt(selectedMapping.value) === parseInt(id)) {
                      setselectedMapping({ text: "Default", value: null });
                      dispatch(
                        genericSetValue({
                          path: "ui.business.selectedMapping",
                          value: { text: "Default", value: null },
                        })
                      );
                    }
                    dispatch(
                      api_column_mapping_get_list({
                        callback: (result) => {
                          dispatch(setupAnimatedLoader({ isVisible: false }));
                          if (!result.success) {
                            showErrorMessage(result, dispatch);
                          }
                        },
                      })
                    );
                  } else {
                    showErrorMessage(result, dispatch);
                    dispatch(setupAnimatedLoader({ isVisible: false }));
                  }
                },
              })
            );
          }
        },
      };
      dispatch(modalQueue({ mode: "insert", modalConfig: modalConfig }));
    };
  }

  function applyMap() {
    let maxId = get(props, "orders", [])
      .map((o) => parseInt(get(o, "id", 0)))
      .reduce((a, b) => Math.max(a, b), 0);
    let orders = get(props, "csvRows", []).map((row, id) => {
      let temp = { id: maxId + id + 1 };
      CONSTANTS.CSV_HEADERS.forEach((header, i) => {
        temp[header] = get(
          row,
          "[" + headers.indexOf(valuesMap[header]) + "]",
          ""
        );
      });
      return temp;
    });
    dispatch(save_orders([...get(props, "orders", []), ...orders]));
    if (!changed) {
      navigateToPath("businesses/dashboard", true, true);
      return;
    }
    let modalConfig = {
      ...CONSTANTS.MODALS_CONFIG.SAVE_MAPPING,
      callback: (res) => {
        dispatch(save_orders([...get(props, "orders", []), ...orders]));
        if (res.confirm) {
          dispatch(
            setupAnimatedLoader({ isVisible: true, text: "Saving mapping..." })
          );
          dispatch(
            api_column_mapping_add({
              name: res.name,
              mapping: Object.entries(valuesMap).map((p) => ({
                source: p[0] || "",
                target: p[1] || "",
              })),
              callback: (result) => {
                if (result.success) {
                  dispatch(
                    api_column_mapping_get_list({
                      callback: (res) => {
                        dispatch(setupAnimatedLoader({ isVisible: false }));
                        if (!res.success) {
                          showErrorMessage(res, dispatch);
                        } else {
                          navigateToPath("businesses/dashboard", true, true);
                        }
                      },
                    })
                  );
                } else {
                  dispatch(setupAnimatedLoader({ isVisible: false }));
                  showErrorMessage(result, dispatch);
                }
              },
            })
          );
        } else {
          navigateToPath("businesses/dashboard", true, true);
        }
      },
    };
    dispatch(modalQueue({ mode: "insert", modalConfig: modalConfig }));
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    mobile: state.data.displayMode === "MOBILE",
    csvRows: state.data.ui.business.csvRows,
    headers: state.data.ui.business.headers,
    orders: state.data.ui.business.orders,
    selectedMapping: state.data.ui.business.selectedMapping,
    getColumnMapping: state.data.api.getColumnMapping,
    mobile: state.data.displayMode === "MOBILE",
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

const MultiplePackagesStage1 = connect(
  mapStateToProps,
  mapDispatchToProps
)(MultiplePackagesStage1Component);

const MultiplePackagesStage1Style = styled.div`
  color: #585858;
  .saved-mappings {
    flex: 0 0 37%;
  }
  .select-mapping {
    margin-top: 11px;
    background-color: white;
  }
  .custom-button-container:hover {
    background-color: ${Colors.lightGray5};
  }
  .select-custom-button {
    background: none;
    border: none;
    width: 100%;
    text-align: start;
    color: #70777d;
    font-family: museo_sans500;
    font-size: 16px;
    padding: 9px 0px 11px 16px;
    outline: none;
    cursor: pointer;
  }
  .select-delete-button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    svg {
      fill: red;
    }
  }
  .title {
    font-size: 28px;
    text-align: center;
    padding: 24px 0px;
    padding-bottom: 5px;
  }
  .sub-title {
    font-size: 18px;
    text-align: center;
    padding: 11px 0px;
  }
  .main-container {
    padding: 7px 49px;
    margin: 0px 50px;
    background-color: #eff8fa;
    box-shadow: 1px 1px 5px #c9d2d4;
    border-radius: 3px;
    margin-bottom: 20px;
    display: block;
  }
  .mapping-container {
    padding: 7px 49px;
    margin: 0px 50px;
    background-color: #eff8fa;
    box-shadow: 1px 1px 5px #c9d2d4;
    border-radius: 3px;
    margin-bottom: 20px;
  }
  .row-container {
    padding: 5px 0px;
    padding-bottom: 1px;
    .text {
      width: 425px;
      line-height: 28px;
    }
    .select-header {
      width: 470px;
      background-color: white;
      margin-top: -3px;
    }
    .first-row {
      width: 300px;
      line-height: 28px;
    }
    &.error {
      background-color: #ffebeb;
    }
  }
  .continue-button {
    background-color: orange;
    color: white;
    font-size: 21px;
    padding: 11px 26px;
    display: block;
    margin: 4px 0;
  }
  @media only screen and (max-width: 992px) {
    .mapping-container {
      margin: 0 10px;
      margin-bottom: 13px;
      padding: 7px 9px;
      flex-direction: column-reverse;
    }
    .main-container {
      margin: 0 10px;
      margin-bottom: 13px;
      padding: 7px 9px;
    }
    .row-container {
      flex-direction: column;
      .text {
        padding-bottom: 8px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .select-header {
        width: unset;
      }
    }
  }
`;

export default MultiplePackagesStage1;
