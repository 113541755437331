/* eslint jsx-a11y/anchor-is-valid: off */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import Navigation from './Navigation.component';
import MainPageFooter from './MainPageFooter.component';
import { Colors } from '../common/colors';
import PigeItSelect from './PigeItSelect.component';
import { modalQueue, api_send_to_customer_service, setupAnimatedLoader } from '../redux/actions';
import CONSTANTS from '../common/constants';
import { setHeadMetaDescription, showErrorMessage, navigateToPath } from "../common/functions";
import { get } from 'lodash';
import { useTitle } from 'hookrouter';
import PigeitCaptcha from './PigeitCaptcha.component';
import PigeItInput from './PigeItInput.component';



function ContactUsComponent(props) {
  useTitle('Contact Us | ShipWinner');
  setHeadMetaDescription("ShipWinner is all about its customer service! Thank you for choosing ShipWinner. We're here to assist you, anytime during the shipping process. Please don't hesitate to contact us; we love hearing from our customers!");

  const SUBJECT_OPTIONS = ['General', 'Website or Technical Support', 'Payment Issues'];
  const SUBJECTS = SUBJECT_OPTIONS.map(sub => { return { text: sub, value: sub.toLowerCase().split(' ').join('-') } });
  const [tabIndexForAll, setTabIndexForAll] = useState(null);
  const [fields, setFields] = useState({ name: { val: '', error: false }, email: { val: get(props, 'apiData_getInitialData.result.user', ''), error: false }, phone: { val: get(props, 'apiData_getInitialData.result.phone', ''), error: false }, subject: { val: SUBJECTS[0], error: false }, message: { val: '', error: false } });
  const [inProgress, setInProgress] = useState(false);
  const dispatch = useDispatch();
  const [captcha, setCaptcha] = useState('');

  return (
    <ContactUsStyle className="hidden-on-fullscreen-modal">
      <div className="contact-us-background-container">
        <Navigation passedTabIndex={tabIndexForAll} />
      </div>
      <div className='header-container'>
        <div className='header'>Contact Us</div>
      </div>
      <div className='form-container'>
        <div className='description'>
        ShipWinner is all about its customer service! Thank you for choosing ShipWinner. We're here to assist you, anytime during the shipping process. Please don't hesitate to contact us; we love hearing from our customers!</div>
        <div className='support'>
          <a traget='_blank' href='mailto:support@shipwinner.com'>
            support@shipwinner.com
          </a>
        </div>
        <div className='form-fields-container'>
          <form className='form'>
            <div className="display-flex fjc-space-between two-input">
              <div className="flex-divided-2-cols first-col">
                <div className="ta-left field-label-input-container">
                  <PigeItInput label="Name" tabIndex={tabIndexForAll} value={fields.name.val} onChange={(e) => setFields({ ...fields, name: { val: e.target.value, error: false } })} name='Name' id='Name' error={fields.name.error} />
                </div>
              </div>
              <div className="flex-divided-2-cols second-col">
                <div className="ta-left field-label-input-container">
                  <PigeItInput label="Email" tabIndex={tabIndexForAll} value={fields.email.val} onChange={(e) => setFields({ ...fields, email: { val: e.target.value, error: false } })} name='Email' id='Email' type='email' error={fields.email.error} />
                </div>
              </div>
            </div>
            <div className="display-flex fjc-space-between two-input">
              <div className="flex-divided-2-cols first-col">
                <div className="ta-left field-label-input-container">
                  <PigeItInput label="Phone" tabIndex={tabIndexForAll} value={fields.phone.val} onChange={(e) => setFields({ ...fields, phone: { val: e.target.value, error: false } })} name='Phone' id='Phone' type='number' error={fields.phone.error} />
                </div>
              </div>
              <div className="flex-divided-2-cols second-col">
                <div className="ta-left field-label-select-container">
                  <PigeItSelect label="Subject" value={fields.subject.val} onChange={(value) => setFields({ ...fields, subject: { val: value, error: false } })} passedTabIndex={tabIndexForAll} valueList={SUBJECTS} />
                </div>
              </div>
            </div>
            <div className='message-container'>
              <PigeItInput label="Message" multiline id="message" name="message" value={fields.message.val} onChange={(e) => setFields({ ...fields, message: { val: e.target.value, error: false } })} tabIndex={tabIndexForAll} error={fields.message.error} />
            </div>
            <PigeitCaptcha className="recaptcha" onVerify={(token) => setCaptcha(token)} />
            <Button disabled={captcha === ''} tabIndex={tabIndexForAll} className='submit' onClick={onSubmit} type='button'>SUBMIT</Button>
          </form>
          <div className='description bottom'>
          At ShipWinner, getting you the best shipping price is only part of our job. We're here to make the shipping process as smooth as possible, in any way we can. No question is too small; we're happy to help with any account features or anything else you need a hand with. Let us know what we can do for you! Feel free to reach out to the ShipWinner team at any time, and we'll do our best to help with any questions or concerns.</div>
        </div>
      </div>
      <MainPageFooter />
    </ContactUsStyle>
  );
  function onSubmit() {
    let tempFields = {};
    Object.entries(fields).forEach(field => {
      if (field[0] === 'email' && !CONSTANTS.VALID_EMAIL_REGEX.test(String(field[1].val).toLowerCase())) {
        tempFields[field[0]] = { val: '', error: true };
      } else if (field[0] === 'phone' && !CONSTANTS.VALID_PHONE_REGEX.test(String(field[1].val).toLowerCase())) {
        tempFields[field[0]] = { val: '', error: true };
      } else {
        tempFields[field[0]] = { val: field[1].val, error: field[1].val === '' };
      }
    });
    setFields(tempFields);
    if (Object.entries(tempFields).some(field => field[1].error)) {
      return;
    }
    else {
      let request = {
        captcha: captcha,
        email: fields.email.val,
        message: fields.message.val,
        name: fields.name.val,
        phone: fields.phone.val,
        subject: fields.subject.val.value
      };
      request.callback = function (result) {
        dispatch(setupAnimatedLoader({ isVisible: false }));
        if (result.success) {
          let modalConfig = {
            ...CONSTANTS.MODALS_CONFIG.MESSAGE,
            buttons: { ok: { visible: true, text: 'OK' }, cancel: { visible: false, text: 'CANCEL' } },
            message: 'Send Successfully',
            callback: function (res) {
              returnHome();
            }
          };
          dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
        } else {
          showErrorMessage(result, dispatch);
        }
      };

      dispatch(setupAnimatedLoader({ isVisible: true, text: 'Loading...' }));
      dispatch(api_send_to_customer_service(request));
    }

  }

  function returnHome() {
    navigateToPath('', true);
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    apiData_getInitialData: state.data.api.getInitialData
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const ContactUsStyle = styled.div`
.two-input{
  margin: 25px 0;
}
.support{
  display: block;
  text-align: center;
}
.captcha-content{
  .refresh{
    background-color: lightgray;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 3px;
  }
  display: flex;
    height: 40px;
    justify-content: space-between;
}
  .contact-us-background-container {
    background-image: url("${CONSTANTS.PUBLIC_URL}/images/pages/home/header-blue.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: white;
    width: 100%;
  }
  .description{
    max-width: 620px;
    color:#70777D;
    font-size: 16px;
    margin: auto;
    margin-top: 52px;
    text-align: center;
    &.bottom{
      margin-bottom: 40px;
    }
  }
  .form-fields-container{
    margin-top: 40px;
  }
  .form{
    .recaptcha{
      margin-top: 20px;
    }
    .submit{
      display: block;
      margin: auto;
      outline: none;
      border: unset;
      border-radius: 3px;
      cursor: pointer;
      background-color: ${Colors.orange};
      color: ${Colors.white};
      width: 250px;
      height: 56px;
      font-size: 14px;
      font-family: museo_sans700;
      margin-top: 68px;
      margin-bottom: 92px;
      &:disabled{
        background-color: ${Colors.lightGray4};
      }
    }
    .message{
      &.error{
        border: solid 1px ${Colors.error};
      }
      font-family: museo_sans500;
      padding: 10px;
      resize: none;
      width: calc(100% - 22px);
      height: 115px;
      border-radius: 3px;
      border: solid 1px #c8d9e2;
    }
    max-width: 620px;
    margin: auto;
    .field-label {
    font-size: 16px;
    color: ${Colors.lightGray2};
    margin-bottom: 11px;
    margin-top: 27px;
    }
    .input-field {
    &.error{
      border: solid 1px ${Colors.error};
    }
    width: 100%;
    margin-bottom: 11px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 3px;
    padding-left: 5px;
    border: solid 1px #c8d9e2;
    height: 36px;
    font-family: museo_sans500;
  }
  .field-label-input-container {
  }
  .second-col .field-label-input-container {
    margin-left: 20px;
  }
  .field-label-select-container{
    width: calc(100% - 20px);
    margin-left: 20px;
  }
  }
  .header-container{
    width: 100%;
    background-color: #EDEDED;
  }
  .header{
      color: ${Colors.black};
      text-align: center;
      font-size: 36px;
      padding-top: 79px;
      padding-bottom: 79px;
    }
  background-color: ${Colors.white};
  @media only screen and (max-width: 992px){
    .contact-us-background-container{
      background-image: unset;
      background-color: #03548f;
    }
    .captcha-content{
      margin-right: 20px;
    }
    .description{
      margin: auto;
      margin-top: 20px;
      padding: 10px;
    }
    .mobile-container{
      text-align:left;
      display:block;
    }
    .form-fields-container .display-flex{
      flex-direction: column;
    }
    .field-label-input-container{
      margin: 13px 20px;
    }
    .two-input{
      margin: 0;
    }
    .form{
      .recaptcha{
        margin-left: 20px;
      }
      .input-field{
        width: calc(100% - 25px);
      }
      .message-container{
        margin-left: 20px;
        width: calc(100% - 42px);
      }
      .field-label-select-container{
        width: calc(100% - 41px);
        margin: 13px 20px 28px 20px;
      }
      
    }
    .form-fields-container{
      padding-bottom: 50px;
    }
  }
`;

const ContactUs = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactUsComponent);

export default ContactUs;
