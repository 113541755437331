/* eslint default-case: off, eqeqeq: off, react-hooks/exhaustive-deps: off */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect, useDispatch } from "react-redux";
import CONSTANTS from "../common/constants";
import { Colors } from "../common/colors";
import { logEventForAnalytics, navigateToPath } from "../common/functions";

function MultiplePackagesCSVStructureComponent(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    logEventForAnalytics({ event: "businesses-csv-structure" });
  }, []);
  return (
    <MultiplePackagesCSVStructureStyle>
      <h2>CSV File Structure</h2>
      <p>
        To import packages with a csv file you need to build your csv file
        according to the following structure:
      </p>
      <a
        className="download-csv-example"
        download
        href="https://www.shipwinner.com/7ffcce92-3753/sample.csv"
      >
        Download CSV Sample
      </a>
      <div className="scroll-x">
        <table className="structure-table">
          <thead>
            <tr>
              <th>Column Name</th>
              <th>Column Type</th>
              <th>Column Required</th>
              <th>Column Description</th>
              <th>Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>recipient name</td>
              <td>Full Name</td>
              <td>Yes (If company is not set)</td>
              <td>The name of the recipient (will be shown on the label)</td>
              <td>rname</td>
            </tr>
            <tr>
              <td>recipient email</td>
              <td>Email Address</td>
              <td>No</td>
              <td>The email of the recipient</td>
              <td>youremail@mail.com</td>
            </tr>
            <tr>
              <td>recipient phone</td>
              <td>Phone Number</td>
              <td>Yes</td>
              <td>
                The phone number of the recipient (will be shown on the label)
              </td>
              <td>9721111111</td>
            </tr>
            <tr>
              <td>recipient company</td>
              <td>Company Name</td>
              <td>Yes (If name is not set)</td>
              <td>The name of the recipient (will be shown on the label</td>
              <td>cname</td>
            </tr>
            <tr>
              <td>recipient city</td>
              <td>City Name</td>
              <td>Yes</td>
              <td>Valid recipient city</td>
              <td>New York</td>
            </tr>
            <tr>
              <td>recipient state or province code</td>
              <td>State Code</td>
              <td>Yes</td>
              <td>Valid recipient state or province code</td>
              <td>NY</td>
            </tr>
            <tr>
              <td>recipient postal code</td>
              <td>Postal Code</td>
              <td>Yes</td>
              <td>Valid recipient postal code</td>
              <td>10037</td>
            </tr>
            <tr>
              <td>recipient street address line 1</td>
              <td>Address Line</td>
              <td>Yes</td>
              <td>Valid recipient street name and number</td>
              <td>2251 5th Ave</td>
            </tr>
            <tr>
              <td>recipient street address line 2</td>
              <td>Address Line</td>
              <td>No</td>
              <td>Recipient address line 2 (will be shown on the label)</td>
              <td>Apartment 2</td>
            </tr>
            <tr>
              <td>recipient address is residential (Y/N)</td>
              <td>Y/N</td>
              <td>Yes</td>
              <td>If the recipient address is residential</td>
              <td>N</td>
            </tr>
            <tr>
              <td>dropoff (Y/N)</td>
              <td>Y</td>
              <td>Yes</td>
              <td>
                If you want to drop off the package (need to be 'Y' becouse
                pickup is not available)
              </td>
              <td>Y</td>
            </tr>
            <tr>
              <td>courier (Y/N)</td>
              <td>N</td>
              <td>Yes</td>
              <td>
                If you want pick up service for the package (need to be 'N'
                becouse pickup is not available)
              </td>
              <td>N</td>
            </tr>
            <tr>
              <td>courier time - year (format YYYY)</td>
              <td>String (YYYY)</td>
              <td>No</td>
              <td>
                If pickup is 'Y' then it is the pickup year (usually the current
                year)
              </td>
              <td>2021</td>
            </tr>
            <tr>
              <td>courier time - month (format M)</td>
              <td>String (M)</td>
              <td>No</td>
              <td>
                If pickup is 'Y' then it is the pickup month (usually the
                current month)
              </td>
              <td>4</td>
            </tr>
            <tr>
              <td>courier time - day of month (format D)</td>
              <td>String (D)</td>
              <td>No</td>
              <td>
                If pickup is 'Y' then it is the pickup date in month (usually in
                the next few days)
              </td>
              <td>21</td>
            </tr>
            <tr>
              <td>courier time - time of day (format HH:MM in 24-hours)</td>
              <td>String (HH:MM)</td>
              <td>No</td>
              <td>
                If pickup is 'Y' then it is the pickup time in 24 hours format
              </td>
              <td>17:20</td>
            </tr>
            <tr>
              <td>package weight</td>
              <td>Number in LBS</td>
              <td>Yes</td>
              <td>The package weight</td>
              <td>1</td>
            </tr>
            <tr>
              <td>package size - height</td>
              <td>Number in INCH</td>
              <td>Yes</td>
              <td>The package height</td>
              <td>1</td>
            </tr>
            <tr>
              <td>package size - width</td>
              <td>Number in INCH</td>
              <td>Yes</td>
              <td>The package width</td>
              <td>1</td>
            </tr>
            <tr>
              <td>package size - length</td>
              <td>Number in INCH</td>
              <td>Yes</td>
              <td>The package length</td>
              <td>1</td>
            </tr>
            <tr>
              <td>package content description</td>
              <td>String</td>
              <td>No</td>
              <td>The package content description (for the insurance)</td>
              <td>rname</td>
            </tr>
            <tr>
              <td>package insurance value</td>
              <td>Number in (DOLLAR/EURO)</td>
              <td>No</td>
              <td>The package value (for the insurance)</td>
              <td>12</td>
            </tr>
            <tr>
              <td>package insurance currency (DOLLAR or EURO)</td>
              <td>DOLLAR/EURO</td>
              <td>Yes (If package insurance value is set)</td>
              <td>The package insurance currency</td>
              <td>DOLLAR</td>
            </tr>
          </tbody>
        </table>
      </div>
      <a
        className="button-main"
        onClick={(e) => {
          e.preventDefault();
          navigateToPath("businesses");
          window.scrollTo(0, 0);
        }}
        href={CONSTANTS.API_ROOT + "businesses"}
      >
        Start Now
      </a>
    </MultiplePackagesCSVStructureStyle>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    mobile: state.data.displayMode === "MOBILE",
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

const MultiplePackagesCSVStructure = connect(
  mapStateToProps,
  mapDispatchToProps
)(MultiplePackagesCSVStructureComponent);

const MultiplePackagesCSVStructureStyle = styled.div`
  color: black;
  padding: 20px;
  .button-main {
    display: block;
    margin: auto;
    background: ${Colors.orange};
    padding: 10px 2px;
    font-size: 20px;
    border-radius: 5px;
    width: 221px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    color: white;
    margin-top:20px;
    &:hover {
      background: ${Colors.orangeHover};
    }
  }
  .download-csv-example {
    margin-bottom: 20px;
    display: block;
  }
  .structure-table {
    border-collapse: collapse;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 3px;
    table-layout: fixed;
    width: 100%;
    margin: auto;
    min-width: 800px;
    thead {
      background-color: rgb(245, 245, 245);
    }
    th {
      padding: 15px 10px;
      text-align: left;
      border-bottom: 1px solid rgb(221, 221, 221);
      border-left: 1px solid rgb(221, 221, 221);
      overflow: hidden;
      text-overflow: ellipsis;
    }
    td {
      padding: 10px 10px;
      text-align: left;
      border-bottom: 1px solid rgb(221, 221, 221);
      border-left: 1px solid rgb(221, 221, 221);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media only screen and (max-width: 992px) {
    .scroll-x {
      overflow-x: scroll;
    }
  }
`;

export default MultiplePackagesCSVStructure;
