import { get } from 'lodash';
import creditCardType from 'credit-card-type';
import CONSTANTS from '../common/constants';
import { modalQueue } from '../redux/actions';
import { genericSetValue } from '../redux/actions';
import { navigate } from 'hookrouter';
import React, { useState } from 'react';
import { getPlaceDetailsFromGoogle } from '../components/PigeItInputAddress.component';


/* eslint eqeqeq: off */
const logError = function (err) {
  try {
    fetch(
      CONSTANTS.API_ROOT + 'api/log-client-error/',
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ err: JSON.stringify(err) })
      }
    );
  }
  catch (err) {
    // nothing to do
  }
};

/**
 * check if item is within array
 * @param arr
 * @param item
 * @returns {boolean}
 */
const isInArray = function (arr, item) {
  try {
    for (let loop = 0; loop < arr.length; ++loop) {
      if (arr[loop] == item) {
        return true;
      }
    }
  }
  catch (err) {
    // nothing to do
  }
  return false;
};

/**
 * generic attach event to support multi-browser
 * @param obj
 * @param eventName
 * @param callback
 */
const attachEvent = function (obj, eventName, callback) {
  if (obj == null || typeof (obj) == 'undefined') {
    return;
  }
  if (obj.addEventListener) {
    obj.addEventListener(eventName, callback, false);
  }
  else if (obj.attachEvent) {
    obj.attachEvent("on" + eventName, callback);
  }
  else {
    obj["on" + eventName] = callback;
  }
};

/**
 * generic detach event to support multi-browser
 * @param obj
 * @param eventName
 * @param callback
 */
const detachEvent = function (obj, eventName, callback) {
  if (obj == null || typeof (obj) == 'undefined') {
    return;
  }
  if (obj.removeEventListener) {
    obj.removeEventListener(eventName, callback, false);
  }
  else if (obj.detachEvent) {
    obj.detachEvent("on" + eventName, callback);
  }
  else {
    obj["on" + eventName] = null;
  }
};

/**
 * this function converts redux input to package request object
 * @param input structure: { packages: [ { id: 'string', dimensionsWidth: 'string', dimensionsLength: 'string', dimensionsHeight: 'string', dimensionsUnits: 'string' } ], weight: 'string', weightUnits: 'string' }
 * @returns {Array}
 */
const buildPackages = function (input) {
  let output = [];
  for (let loop = 0; loop < input.packages.length; ++loop) {
    const packageType = input.packages[loop].selectedPackage;
    const weight = input.packages[loop].weight;
    const weightUnits = input.packages[loop].weightUnits;
    const contentDescription = (input.packages[loop].content || '');
    const insuredValueAmount = (input.packages[loop].value || 0);
    output.push({
      contentDescription: contentDescription,
      insuredValueAmount: parseInt(insuredValueAmount),
      insuredValueCurrency: parseInt(CONSTANTS.MONEY_EXCHANGE_TYPES[0].id), // TODO - dollar for now, should be dynamic
      packagingType: parseInt(packageType.id),
      weight: parseFloat(weight),
      weightUnits: weightUnits.toUpperCase(),
      sizeHeight: parseFloat(packageType.dimensionsHeight),
      sizeWidth: parseFloat(packageType.dimensionsWidth),
      sizeLength: parseFloat(packageType.dimensionsLength),
      sizeUnits: packageType.dimensionsUnits.toUpperCase()
    });
  }
  return output;
};

/**
 *
 * @param serviceResult - service response
 * @param shippingTypeId - shipping type id
 * @returns {result object | null}
 */
const getPriceByShippingTypeId = function (serviceResult, shippingTypeId) {
  const results = get(serviceResult, 'results', []);
  for (let companyResult of results) {
    for (let companyOptionResult of companyResult.options) {
      if (companyOptionResult.shippingTypeId == shippingTypeId) {
        return companyOptionResult;
      }
    }
  }
  return null;
};

/**
 *
 * @param serviceResult - the service result
 * @param input - structure: { company: 'string - FEDEX or UPS', shippingTypeId: 'integer', shippingOption: 'string - 1-business-day or 2-business-days or 3-business-days or ground' }
 * @returns {*} - matched result (best price or exact match)
 */
const getBestPrice = function (serviceResult, input = {}) {
  const businessDaysOptionsTranslation = {
    '1-business-days': '1',
    '2-business-days': '2',
    '3-business-days': '3'
  };
  const inputCompany = input.company;
  const inputShippingTypeId = input.shippingTypeId;
  const inputShippingOption = input.shippingOption; // can be: 1-business-day, 2-business-days, 3-business-days or ground
  const results = get(serviceResult, 'result.results', []);
  let minimumPrice = -1;
  let minimumPriceCompany = null;
  let minimumPriceResult = null;
  for (let companyResult of results) {
    if (companyResult.success && (!inputCompany || inputCompany == companyResult.company)) {
      for (let companyOptionResult of companyResult.options) {
        if (minimumPrice == -1 || parseFloat(companyOptionResult.price) < minimumPrice) {
          if (inputShippingTypeId && companyOptionResult.shippingTypeId == inputShippingTypeId) {
            // exact match - unique shipping type id
            minimumPrice = parseFloat(companyOptionResult.price);
            minimumPriceResult = companyOptionResult;
            minimumPriceCompany = companyResult;
          }
          else if (!inputShippingTypeId && inputShippingOption &&
            ((companyOptionResult.isGround && inputShippingOption == 'ground') ||
              (!companyOptionResult.isGround && (businessDaysOptionsTranslation[inputShippingOption] == companyOptionResult.businessDays || (businessDaysOptionsTranslation[inputShippingOption] == CONSTANTS.MAX_BUSINESS_DAYS && companyOptionResult.businessDays >= CONSTANTS.MAX_BUSINESS_DAYS))))) {
            // best for shipping option
            minimumPrice = parseFloat(companyOptionResult.price);
            minimumPriceResult = companyOptionResult;
            minimumPriceCompany = companyResult;
          }
          else if (!inputShippingTypeId && !inputShippingOption) {
            // not comparing any parameter, just seeking best price overall
            minimumPrice = parseFloat(companyOptionResult.price);
            minimumPriceResult = companyOptionResult;
            minimumPriceCompany = companyResult;
          }
        }
      }
    }
  }
  return { company: minimumPriceCompany, option: minimumPriceResult };
};

/**
 * this function gets top/left offsets relative to page
 * @param el - DOM element
 * @returns {{top: number, left: number}}
 */
const getElementOffset = function (el) {
  var _x = 0;
  var _y = 0;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft;
    _y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }
  return { top: _y, left: _x };
};

/**
 *
 * @param cvv - string of cvv
 * @param cardNumber - string of credit card number
 * @returns {{encyprtedCreditCard, encyprtedCvv}}
 */
const getBluesnapEncryptedFields = function (cvv, cardNumber) {
  if (!window.__BLUESNAP__) {
    const key = process.env.REACT_APP_BLUESNAP_KEY;
    try {
      if (String(process.env.REACT_APP_BLUESNAP_DEV) === 'true') {
        window.__BLUESNAP__ = new window.BlueSnap(key, true);
      }
      else {
        window.__BLUESNAP__ = new window.BlueSnap(key);
      }
    }
    catch (err) {
      // nothing to do
    }
  }
  removeCreatedFields();
  document.querySelector("[data-bluesnap='encryptedCreditCard']").value = cardNumber;
  document.querySelector("[data-bluesnap='encryptedCvv']").value = cvv;
  window.__BLUESNAP__.encrypt('bluesnap-form');
  const encyprtedCreditCard = document.querySelector("[name='encryptedCreditCard']").value;
  const encyprtedCvv = document.querySelector("[name='encryptedCvv']").value;
  setTimeout(removeCreatedFields, 10);
  return {
    encyprtedCreditCard: encyprtedCreditCard,
    encyprtedCvv: encyprtedCvv
  };
  function removeCreatedFields() {
    for (const fieldName of 'encryptedCreditCard,encryptedCvv,ccLast4Digits'.split(',')) {
      const fieldFromDom = document.querySelector("#bluesnap-form input[name='" + fieldName + "']");
      if (fieldFromDom) {
        fieldFromDom.parentNode.removeChild(fieldFromDom);
      }
    }
  }
};

const isDescendant = function (parent, child) {
  var node = child.parentNode;
  while (node != null) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};

const padding = function (value, padPrefixStr, padSuffixStr, wantedLength) {
  let loopProtection = 1000; // prevent usage more than 1,000 times (not very logical padding)
  value = String(value);
  while (loopProtection > 0 && value.length < wantedLength) {
    value = padPrefixStr + value + padSuffixStr;
    --loopProtection;
  }
  return value;
};

/**
 * this function translate all cards to name (name is like the function in images.js file)
 * @param cardNumber - string of the card number
 * @returns {string} - mastercard or visa or diners or maestro or discover or amex
 */
const identifyCreditCardType = function (cardNumber, cardNameFromServer) {
  if (cardNameFromServer) {
    // tranlsate from server
    return cardNameFromServer.toLowerCase();
  }
  try {
    // conversions are from creditCardType lib
    var cardNamesConversion = {
      'american-express': 'amex',
      'diners-club': 'diners'
    };
    let result = creditCardType(cardNumber)[0].type.toLowerCase();
    return (cardNamesConversion[result] ? cardNamesConversion[result] : result);
  }
  catch (err) {
    // failed to identify - nothing to do
  }
  return false;
};


const convertPackageIdtoPackage = function (packageId) {
  let packages = CONSTANTS.PACKAGES_TYPES.map(item => item.strips ? item.strips.map(item => item.items).reduce((x, y) => x.concat(y), []) : [item.item]).reduce((x, y) => x.concat(y), []).concat(CONSTANTS.ENVELOPE_TYPES);
  return packages.find(item => parseInt(item.id) === parseInt(packageId));
};

const convertPackageWeightUnitTextToId = function (weightUnitText) {
  const result = CONSTANTS.PACKAGE_WEIGHT_UNIT_TYPES.filter((item) => (item.text.toLowerCase() == weightUnitText.toLowerCase()));
  if (result.length === 1) {
    return result[0].id;
  }
  return -1;
};

const convertPackageSizeUnitTextToId = function (sizeUnitText) {
  const result = CONSTANTS.PACKAGE_SIZE_UNIT_TYPES.filter((item) => (item.text.toLowerCase() == sizeUnitText.toLowerCase()));
  if (result.length === 1) {
    return result[0].id;
  }
  return -1;
};
const showErrorMessage = function (result, dispatch) {
  let modalConfig = {
    ...CONSTANTS.MODALS_CONFIG.MESSAGE,
    message: CONSTANTS.ERROR_MESSAGES[result.code] || CONSTANTS.DEFAULT_ERROR_MESSAGE,
    buttons: { ok: { visible: true, text: 'OK' }, cancel: { visible: false, text: 'CANCEL' } }
  };
  dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
  logError({ errorMessage: CONSTANTS.ERROR_MESSAGES[result.code] || CONSTANTS.DEFAULT_ERROR_MESSAGE, code: result.code });
};

const showMessage = function (message, dispatch) {
  let modalConfig = {
    ...CONSTANTS.MODALS_CONFIG.MESSAGE,
    message: message,
    buttons: { ok: { visible: true, text: 'OK' }, cancel: { visible: false, text: 'CANCEL' } }
  };
  dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
};

const setShipperDetails =async function (store, dispatch) {
  let currentState = store.getState();
  const fullName = get(currentState, 'data.api.getInitialData.result.fullname', '');
  const company = get(currentState, 'data.api.getInitialData.result.company', '');
  const email = get(currentState, 'data.api.getInitialData.result.user', '');
  const phone = get(currentState, 'data.api.getInitialData.result.phone', '');
  const address = get(currentState, 'data.api.getInitialData.result.address', '');
  const googlePlaceId = get(currentState, 'data.api.getInitialData.result.googlePlaceId', '');
  if (address && googlePlaceId && get(currentState, 'data.ui.mainPage.purchaseProgress', '')) {
    let address_components = get(await getPlaceDetailsFromGoogle(googlePlaceId),'address_components',[]);
    const getField = name =>{
      return get(address_components.find(component=>get(component,'types',[]).some(type=>type===name)),'short_name','')
    }
    const addressObject = {
      googlePlaceId: googlePlaceId,
      addressDataVersion: "plain",
      countryCode: getField('country'),
      city: getField('locality'),
      stateOrProvinceCode: getField('administrative_area_level_1'),
      postalCode: getField('postal_code'),
      addressLines: [`${getField('street_number')} ${getField('route')}`]
    }
    dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress', value: { ...get(currentState, 'data.ui.mainPage.purchaseProgress', ''),senderAddressDetails:addressObject, senderAddress: address, senderPlaceId: googlePlaceId, shipper: { ...get(currentState, 'ui.mainPage.purchaseProgress.shipper', ''), name: fullName, phone: phone, email: email, company: company } } }));
  } else if (get(currentState, 'data.ui.mainPage.purchaseProgress', '')) {
    dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress', value: { ...get(currentState, 'data.ui.mainPage.purchaseProgress', ''), shipper: { ...get(currentState, 'ui.mainPage.purchaseProgress.shipper', ''), name: fullName, phone: phone, email: email, company: company } } }));
  }
};

const getQueryParam = function (variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return '';
};

const setHeadMetaDescription = function (value) {
  try {
    document.getElementById("head-description-meta").content = value;
  }
  catch (err) { }
};

const navigateToPath = function (path, shouldScrollToTop, replace, queryParams) {
  let pathPrefix = CONSTANTS.PUBLIC_URL;
  navigate(pathPrefix + (pathPrefix.substring(pathPrefix.length - 1) !== '/' ? '/' : '') + path, replace, queryParams);
  if (shouldScrollToTop) {
    window.scrollTo(0, 0);
  }
  logEventForAnalytics({ 'event': 'pageview' });
};

const isValidSizeValue = function (value) {
  if (value == 0) {
    return false;
  }
  if (!value) {
    return false;
  }
  if (isNaN(value)) {
    return false;
  }
  if (parseFloat(value) <= 0) {
    return false;
  }
  if (parseFloat(value)>100) return false;
  return true;
};

const isValidWeightValue = function (value, minValue, maxValue) {
  if (value == 0) {
    return false;
  }
  if (!value) {
    return false;
  }
  if (isNaN(value)) {
    return false;
  }
  if (parseFloat(value) <= 0 || parseFloat(value) > parseFloat(maxValue)) {
    return false;
  }
  // check for minimum separately
  if (parseFloat(minValue) > 0 && parseFloat(value) < parseFloat(minValue)) {
    return false;
  }
  return true;
};

const promptUserToSignIn = function (dispatch) {
  let modalConfig = {
    ...CONSTANTS.MODALS_CONFIG.MESSAGE,
    buttons: { ok: { visible: true, text: 'SIGN IN' }, cancel: { visible: false, text: 'CANCEL' } },
    message: 'This feature is available for registered users, if you want to enjoy this feature and more please register for free'
  };
  modalConfig.callback = function (res) {
    if (res.confirm) {
      dispatch(modalQueue({ mode: 'delete' }));
      navigateToPath('sign-in', true);
    }
  };
  dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
}

const getImgTag = function (img, className) {
  try {
    let imgName = img.baseUrl.split('/')[img.baseUrl.split('/').length - 1];
    return (
      <img className={className}
        srcSet={img.sizes.map((s, i) => {
          return img.baseUrl + '/' + imgName + '-' + s + '.' + img.format + ' ' + s + (i !== img.sizes.length - 1 ? ', ' : '');
        }).reduce((a, b) => a + b, '')}
        sizes={img.sizes.map((s, i) => `(max-width: ${s.substr(0, s.length - 1)}px) ${s.substr(0, s.length - 1)}px` + (i !== img.sizes.length - 1 ? ', ' : '')).reduce((a, b) => a + b, '')}
        src={img.baseUrl + '/' + imgName + '-' + img.sizes[img.sizes.length - 1] + '.' + img.format}
        alt="" />
    );
  } catch (err) {
    return null;
  }
}


/**
 *
 * @param apiData_getInitialData - the api data
 * @returns {boolean} - checks if user logged in or not
 */
const isUserLoggedIn = function (apiData_getInitialData) {
  if (get(apiData_getInitialData, 'result.success', false)) {
    return true;
  }
  return false;
};

const logEventForAnalytics = function (eventData) {
  window.dataLayer.push(eventData);
};

const getGoogleAddressComponents = (googleAddress) => {
  let addressText = get(googleAddress, 'placeObject.formatted_address', '');
  let addressComp = get(googleAddress, 'placeObject.address_components', []);
  let streetNum = addressComp.find(comp => get(comp, 'types', []).some(type => type === 'street_number'));
  let street = addressComp.find(comp => get(comp, 'types', []).some(type => type === 'route'));
  let state = addressComp.find(comp => get(comp, 'types', []).some(type => type === 'administrative_area_level_1'));
  let city = addressComp.find(comp => get(comp, 'types', []).some(type => type === 'locality' || type === 'sublocality'));
  let postalCode = addressComp.find(comp => get(comp, 'types', []).some(type => type === 'postal_code'));
  streetNum = get(streetNum, 'short_name', '');
  street = get(street, 'short_name', '');
  state = get(state, 'short_name', '');
  city = get(city, 'short_name', '');
  postalCode = get(postalCode, 'short_name', '');
  return { city, state, postalCode, street, streetNum, addressText };
}


export {
  logError,
  isInArray,
  attachEvent,
  detachEvent,
  buildPackages,
  getPriceByShippingTypeId,
  getBestPrice,
  getElementOffset,
  getBluesnapEncryptedFields,
  isDescendant,
  padding,
  identifyCreditCardType,
  convertPackageIdtoPackage,
  convertPackageWeightUnitTextToId,
  convertPackageSizeUnitTextToId,
  showErrorMessage,
  showMessage,
  setShipperDetails,
  getQueryParam,
  setHeadMetaDescription,
  navigateToPath,
  isValidSizeValue,
  isValidWeightValue,
  promptUserToSignIn,
  isUserLoggedIn,
  logEventForAnalytics,
  getImgTag,
  getGoogleAddressComponents,
};