import React, { useRef, useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { connect, useDispatch } from "react-redux";
import {
  modalQueue,
  save_csv,
  save_csv_headers,
  genericSetValue,
} from "../redux/actions";
import { Button, TextField, Link } from "@material-ui/core";
import { Colors } from "../common/colors";
import CONSTANTS from "../common/constants";
import {
  showMessage,
  navigateToPath,
  logEventForAnalytics,
} from "../common/functions";
import { get } from "lodash";
import IMAGES from "../common/images";
import Papa from "papaparse";

function PigeItUploadCSVComponent(props) {
  const dispatch = useDispatch();
  const [dragOver, setdragOver] = useState(false);
  useEffect(() => {
    logEventForAnalytics({ event: "businesses-open-csv-modal" });
  }, []);
  return (
    <PigeItUploadCSVStyle>
      <div className="background-container">
        <div className="content-container">
          <Button className="modal-close-button" onClick={props.close}>
            <IMAGES.close />
          </Button>
          <div className="main-title">Upload Package From a CSV File</div>
          <div className="explanation-text">
            Check{" "}
            <a
              href={CONSTANTS.API_ROOT + "businesses/csv-structure"}
              onClick={(e) => {
                e.preventDefault();
                navigateToPath("businesses/csv-structure");
                props.close();
              }}
            >
              here
            </a>{" "}
            for tutorial and examples
          </div>
          <div
            className={"drag-and-drop" + (dragOver ? " drag-over" : "")}
            onDrop={dropFile}
            onDragOver={(e) => {
              e.preventDefault();
              setdragOver(true);
            }}
            onDragLeave={() => setdragOver(false)}
          >
            <div className="text">Drop a CSV file</div>
            <label htmlFor="csv" className="upload-button">
              Upload a CSV Now
            </label>
            <input
              onChange={onSelectFile}
              accept=".csv"
              style={{ display: "none" }}
              type="file"
              id="csv"
            />
          </div>
        </div>
      </div>
    </PigeItUploadCSVStyle>
  );

  function dropFile(e) {
    e.preventDefault();
    if (get(e, "dataTransfer.items", false)) {
      for (var i = 0; i < get(e, "dataTransfer.items.length", 0); i++) {
        // If dropped items aren't files, reject them
        if (get(e, "dataTransfer.items[" + i + "].kind", "") === "file") {
          var file = get(
            e,
            "dataTransfer.items[" + i + "]",
            () => {}
          ).getAsFile();
          parseFile(file);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < get(e, "dataTransfer.files.length", 0); i++) {
        parseFile(e.dataTransfer.files[i]);
      }
    }
  }

  function onSelectFile(e) {
    try {
      if (e.target.files && e.target.files.length === 1 && e.target.files[0]) {
        parseFile(e.target.files[0]);
      }
    } catch (err) {
      showMessage("Error Importing CSV");
    }
  }

  function parseFile(file) {
    let fileReader = new FileReader();
    fileReader.readAsText(file);
    fileReader.onload = (event) => {
      let parsed = Papa.parse(event.target.result);
      if (parsed.errors.length > 0) {
        showMessage("Error Importing CSV", dispatch);
        return;
      }
      let rows = parsed.data.filter((r, i) => r.length > 1 && i !== 0);
      if (rows.length === 0) {
        showMessage("Incompatible or empty file", dispatch);
        return;
      }
      dispatch(save_csv(rows));
      dispatch(save_csv_headers(parsed.data[0]));
      navigateToPath("businesses/map-csv-values", true);
      dispatch(genericSetValue({ path: "ui.business.changed", value: true }));
      props.close();
    };
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    close: () => dispatch(modalQueue({ mode: "delete" })),
  };
};

const PigeItUploadCSVStyle = styled.div`
  width: 100%;
  height: 100%;
  .background-container {
    height: 100%;
    width: 100%;
    background-color: #fff;
    margin: 0 auto;
  }
  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
    padding-top: 5%;
  }
  .modal-close-button {
    position: absolute;
    top: 21px;
    right: 26px;
  }
  .drag-and-drop {
    &.drag-over {
      border: dotted 4px #428ca5;
      background-color: #b3aeae;
    }
    border: dotted 4px lightblue;
    margin: 10px;
    padding: 31px 0px;
    background-color: lightgray;
    .text {
      text-align: center;
      font-size: 20px;
      color: grey;
      margin-bottom: 10px;
    }
  }
  .upload-button {
    display: block;
    margin: auto;
    background: #2699fb;
    padding: 10px 2px;
    font-size: 20px;
    border-radius: 5px;
    width: 221px;
    text-align: center;
    cursor: pointer;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: white;
    &:hover {
      background: ${Colors.blue2};
    }
  }
  .main-title {
    font-size: 24px;
    text-align: center;
    padding: 31px 0;
  }
  .explanation-text {
    text-align: center;
  }
  @media only screen and (max-width: 992px) {
    .drag-and-drop {
      border: none;
      margin: unset;
      padding: unset;
      background-color: white;
      .text {
        display: none;
      }
    }
    .main-title {
      font-size: 24px;
      text-align: center;
      padding: 31px 29px;
      padding-top: 93px;
    }
  }
`;

const PigeItUploadCSV = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItUploadCSVComponent);

export default PigeItUploadCSV;
