import { combineReducers } from "redux";
import { ACTIONS } from "./actions";
import { set, get } from "lodash";
import initialState from "./initialState.prod";

// if (window.localStorage) {
//   if (localStorage.getItem('state')) {
//     try {
//       initialState.getShippingRates = JSON.parse(localStorage.getItem('getShippingRates'));
//     }
//     catch (err) { }
//   }
// }

const mainReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    // api's actions
    case ACTIONS.GET_INITIAL_DATA_SUCCESS:
    case ACTIONS.GET_INITIAL_DATA_FAILED:
      state.api.getInitialData = action.payload;
      if (get(action, "payload.result.success", false)) {
        let data = get(action, "payload.result", {});
        state.ui.business.senderInfo = {
          city: data.city,
          state: data.state,
          postalCode: data.zipcode,
          street: get(get(data, "address", "").split(","), "[0]", ""),
          addressLine2: "",
          name: data.fullname,
          company: data.company,
          phone: data.phone,
          addressText: data.address,
          email: data.user,
        };
      }
      return { ...state };
    case ACTIONS.LOGIN_SUCCESS:
    case ACTIONS.LOGIN_FAILED:
      state.api.login = action.payload;
      return { ...state };
    case ACTIONS.RESET_ACCOUNT_CREATE_SUCCESS:
    case ACTIONS.RESET_ACCOUNT_CREATE_FAILED:
      state.api.resetAccountCreate = action.payload;
      return { ...state };
    case ACTIONS.RESET_ACCOUNT_CHANGE_PASSWORD_SUCCESS:
    case ACTIONS.RESET_ACCOUNT_CHANGE_PASSWORD_FAILED:
      state.api.resetAccountChangePassword = action.payload;
      return { ...state };
    case ACTIONS.LOGOUT_SUCCESS:
    case ACTIONS.LOGOUT_FAILED:
      state.api.logout = action.payload;
      // in case of success - reset all api information and just keep logout information
      if (action.type === ACTIONS.LOGOUT_SUCCESS) {
        state.api = {
          getInitialData: { result: { user: "" } },
          logout: action.payload,
        };
      }
      return { ...state };
    case ACTIONS.REGISTER_SUCCESS:
    case ACTIONS.REGISTER_FAILED:
      state.api.register = action.payload;
      return { ...state };
    case ACTIONS.GET_PRICES_SUCCESS:
    case ACTIONS.GET_PRICES_FAILED:
      state.api.getPrices = action.payload;
      return { ...state };
    case ACTIONS.DELETE_INSURANCE:
      state.api.getPrices = action.payload;
      return { ...state };
    case ACTIONS.GET_PRICES_NO_INSURANCE_SUCCESS:
      if (get(state, "api.getPrices.result.success", false)) {
        state.api.getPrices = {
          ...get(state, "api.getPrices", {}),
          result: {
            ...get(state, "api.getPrices.result", {}),
            results: get(state, "api.getPrices.result.results", []).map(
              (res, resI) => {
                return {
                  ...res,
                  options: get(res, "options", []).map((opt, optI) => {
                    if (
                      opt.shippingTypeId ===
                      get(
                        action,
                        "payload.result.results[" +
                          resI +
                          "].options[" +
                          optI +
                          "].shippingTypeId",
                        0
                      )
                    ) {
                      return {
                        ...opt,
                        withoutInsurance: get(
                          action,
                          "payload.result.results[" +
                            resI +
                            "].options[" +
                            optI +
                            "].price",
                          0
                        ),
                      };
                    } else {
                      return opt;
                    }
                  }),
                };
              }
            ),
          },
        };
      } else {
        state.api.getPrices = {
          ...get(action, "payload", {}),
          result: {
            ...get(action, "payload.result", {}),
            results: get(action, "payload.result.results", []).map(
              (res, resI) => {
                return {
                  ...res,
                  options: get(res, "options", []).map((opt, optI) => {
                    if (
                      opt.shippingTypeId ===
                      get(
                        action,
                        "payload.result.results[" +
                          resI +
                          "].options[" +
                          optI +
                          "].shippingTypeId",
                        0
                      )
                    ) {
                      return {
                        ...opt,
                        withoutInsurance: get(
                          action,
                          "payload.result.results[" +
                            resI +
                            "].options[" +
                            optI +
                            "].price",
                          0
                        ),
                      };
                    } else {
                      return opt;
                    }
                  }),
                };
              }
            ),
          },
        };
      }
      return { ...state };
    case ACTIONS.GET_PRICES_INSURANCE_SUCCESS:
      if (get(state, "api.getPrices.result.success", false)) {
        state.api.getPrices = {
          ...get(state, "api.getPrices", {}),
          result: {
            ...get(state, "api.getPrices.result", {}),
            results: get(state, "api.getPrices.result.results", []).map(
              (res, resI) => {
                return {
                  ...res,
                  options: get(res, "options", []).map((opt, optI) => {
                    if (
                      opt.shippingTypeId ===
                      get(
                        action,
                        "payload.result.results[" +
                          resI +
                          "].options[" +
                          optI +
                          "].shippingTypeId",
                        0
                      )
                    ) {
                      return {
                        ...opt,
                        price: get(
                          action,
                          "payload.result.results[" +
                            resI +
                            "].options[" +
                            optI +
                            "].price",
                          0
                        ),
                      };
                    } else {
                      return opt;
                    }
                  }),
                };
              }
            ),
          },
        };
      } else {
        state.api.getPrices = action.payload;
      }
      return { ...state };
    case ACTIONS.VERIFY_PAYMENT_SUCCESS:
    case ACTIONS.VERIFY_PAYMENT_FAILED:
      state.api.verifyPayment = action.payload;
      return { ...state };
    case ACTIONS.VERIFY_PAYMENT_MSR_FAIL:
    case ACTIONS.VERIFY_PAYMENT_MSR_SUCCESS:
      state.api.verifyPaymentMsr = action.payload;
      return { ...state };
    case ACTIONS.PAY_SUCCESS:
    case ACTIONS.PAY_FAILED:
      state.api.pay = action.payload;
      return { ...state };
    case ACTIONS.PAY_MSR_SUCCESS:
    case ACTIONS.PAY_MSR_FAILED:
      state.api.payMsr = action.payload;
      return { ...state };
    case ACTIONS.SEND_TO_CUSTOMER_SERVICE_SUCCESS:
    case ACTIONS.SEND_TO_CUSTOMER_SERVICE_FAILED:
      state.api.sendToCustomerService = action.payload;
      return { ...state };
    case ACTIONS.UPDATE_PROFILE_SUCCESS:
    case ACTIONS.UPDATE_PROFILE_FAILED:
      state.api.updateProfile = action.payload;
      return { ...state };
    case ACTIONS.UPDATE_PASSWORD_SUCCESS:
    case ACTIONS.UPDATE_PASSWORD_FAILED:
      state.api.updatePassword = action.payload;
      return { ...state };
    case ACTIONS.ACTIVATE_ACCOUNT_SUCCESS:
    case ACTIONS.ACTIVATE_ACCOUNT_FAILED:
      state.api.activateAccount = action.payload;
      return { ...state };
    case ACTIONS.ADRESSBOOK_GET_LIST_SUCCESS:
    case ACTIONS.ADRESSBOOK_GET_LIST_FAILED:
      state.api.addressBook_getList = action.payload;
      return { ...state };
    case ACTIONS.ADRESSBOOK_ADD_SUCCESS:
    case ACTIONS.ADRESSBOOK_ADD_FAILED:
      state.api.addressBook_add = action.payload;
      return { ...state };
    case ACTIONS.ADRESSBOOK_UPDATE_SUCCESS:
    case ACTIONS.ADRESSBOOK_UPDATE_FAILED:
      state.api.addressBook_update = action.payload;
      return { ...state };
    case ACTIONS.ADRESSBOOK_REMOVE_SUCCESS:
    case ACTIONS.ADRESSBOOK_REMOVE_FAILED:
      state.api.addressBook_remove = action.payload;
      return { ...state };
    case ACTIONS.CREDITCARD_GET_LIST_SUCCESS:
    case ACTIONS.CREDITCARD_GET_LIST_FAILED:
      state.api.paymentSources_getList = action.payload;
      return { ...state };
    case ACTIONS.CREDITCARD_ADD_SUCCESS:
    case ACTIONS.CREDITCARD_ADD_FAILED:
      state.api.paymentSources_add = action.payload;
      return { ...state };
    case ACTIONS.CREDITCARD_SET_SELECTED_CARD_SUCCESS:
    case ACTIONS.CREDITCARD_SET_SELECTED_CARD_FAILED:
      state.api.paymentSources_setSelectedCard = action.payload;
      return { ...state };
    case ACTIONS.CREDITCARD_REMOVE_SUCCESS:
    case ACTIONS.CREDITCARD_REMOVE_FAILED:
      state.api.paymentSources_remove = action.payload;
      return { ...state };
    case ACTIONS.PACKAGES_GET_LIST_SUCCESS:
    case ACTIONS.PACKAGES_GET_LIST_FAILED:
      state.api.packages_getList = action.payload;
      return { ...state };
    case ACTIONS.PACKAGES_ADD_SUCCESS:
    case ACTIONS.PACKAGES_ADD_FAILED:
      state.api.packages_add = action.payload;
      return { ...state };
    case ACTIONS.PACKAGES_UPDATE_SUCCESS:
    case ACTIONS.PACKAGES_UPDATE_FAILED:
      state.api.packages_update = action.payload;
      return { ...state };
    case ACTIONS.PACKAGES_REMOVE_SUCCESS:
    case ACTIONS.PACKAGES_REMOVE_FAILED:
      state.api.packages_remove = action.payload;
      return { ...state };
    case ACTIONS.GET_SHIPPING_HISTORY_SUCCESS:
    case ACTIONS.GET_SHIPPING_HISTORY_FAILED:
      state.api.getShippingHistory = action.payload;
      return { ...state };
    case ACTIONS.GET_SHIPPING_HISTORY_MSR_SUCCESS:
    case ACTIONS.GET_SHIPPING_HISTORY_MSR_FAILED:
      state.api.getShippingHistoryMsr = action.payload;
      return { ...state };
    case ACTIONS.GET_PRICES_CSV_FAIL:
    case ACTIONS.GET_PRICES_CSV_SUCCESS:
      state.api.getPricesCsv = action.payload;
      return { ...state };
    case ACTIONS.GET_PRICES_MULTI_FAILED:
    case ACTIONS.GET_PRICES_MULTI_SUCCESS:
      state.api.getPricesMulti = action.payload;
      return { ...state };
    case ACTIONS.COLUMN_MAPPING_GET_LIST_FAILED:
    case ACTIONS.COLUMN_MAPPING_GET_LIST_SUCCESS:
      state.api.getColumnMapping = action.payload;
      return { ...state };
    case ACTIONS.BUSINESS_REVIEWS_GET_VERIFIED_SUCCESS:
      let reviews = get(action, "payload.result.results", []);
      //shuffle  randomly
      for (let i = reviews.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = reviews[i];
        reviews[i] = reviews[j];
        reviews[j] = temp;
      }
      state.api.businessReviews = reviews;
      return { ...state };
    case ACTIONS.BUSINESS_REVIEWS_GET_VERIFIED_FAILED:
      state.api.businessReviews = [];
      return { ...state };
    case ACTIONS.BUSINESS_REVIEWS_GET_VERIFIED_IMAGE_SUCCESS:
      state.api.businessReviews = get(state, "api.businessReviews", []).map(
        (review) => {
          if (
            parseInt(review.id) ===
            parseInt(get(action, "payload.request.id", 0))
          ) {
            return {
              ...review,
              profilePhoto: get(
                action,
                "payload.result.results.profilePhoto",
                ""
              ),
              imageFormat: get(
                action,
                "payload.result.results.imageFormat",
                ""
              ),
            };
          } else {
            return review;
          }
        }
      );
      return { ...state };
    // loader actions
    case ACTIONS.TOGGLE_LOADER:
      return {
        ...state,
        loader: { ...state.loader, isVisible: action.payload.isVisible },
      };
    case ACTIONS.SET_LOADER_TEXT:
      return {
        ...state,
        loader: { ...state.loader, text: action.payload.text },
      };
    case ACTIONS.SETUP_ANIMATED_LOADER:
      let newAnimatedLoaderConfig = { ...state.animatedLoader };
      if (action.payload.isVisible != null) {
        newAnimatedLoaderConfig.isVisible = action.payload.isVisible;
      }
      if (action.payload.text) {
        newAnimatedLoaderConfig.text = action.payload.text;
      }
      return { ...state, animatedLoader: newAnimatedLoaderConfig };
    case ACTIONS.TOGGLE_V_SCROLL_TOP:
      return { ...state, isVScrollTop: action.payload.isVScrollTop };
    case ACTIONS.GENERIC_SET_VALUE:
      const path = action.payload.path;
      const value = action.payload.value;
      newState = { ...state };
      set(newState, path, value);
      return newState;
    // modal reducer
    case ACTIONS.MODAL_HANDLER:
      switch (action.payload.mode) {
        case "insert":
          newState = { ...state };
          //if the modal has a url, check that the modal isn't already in the queue
          if (
            !action.payload.modalConfig.url ||
            newState.activeModalQueue.every(
              (item) => item.url !== action.payload.modalConfig.url
            )
          ) {
            newState.activeModalQueue = newState.activeModalQueue.concat(
              action.payload.modalConfig
            );
          }
          return newState;
        case "delete":
          newState = { ...state };
          if (action.payload.index != null) {
            newState.activeModalQueue = newState.activeModalQueue.filter(
              function (item, index) {
                return action.payload.index != index;
              }
            );
          } else if (action.payload.modalConfig) {
            newState.activeModalQueue = newState.activeModalQueue.filter(
              function (item, index) {
                return action.payload.modalConfig != item;
              }
            );
          } else {
            newState.activeModalQueue.pop();
            newState.activeModalQueue = [].concat(newState.activeModalQueue);
          }
          return newState;
        default:
          return state;
      }
    case ACTIONS.SAVE_STATE_TO_LOCALSTORAGE:
      if (window.localStorage) {
        localStorage.setItem(
          "getShippingRates",
          JSON.stringify(state.getShippingRates)
        );
        localStorage.setItem(
          "modalsSavedData",
          JSON.stringify(state.modalsSavedData)
        );
      }
      return state;
    case ACTIONS.SET_DISPLAY_MODE:
      newState = { ...state, displayMode: action.payload };
      return newState;
    case ACTIONS.LOGIN_ADMIN_SUCCESS: {
      newState = { ...state, admin: { ...state.admin, loggedIn: true } };
      return newState;
    }
    case ACTIONS.LOGIN_ADMIN_FAILED: {
      newState = { ...state, admin: { ...state.admin, loggedIn: false } };
      return newState;
    }
    case ACTIONS.LOGOUT_ADMIN_FAILED: {
      newState = { ...state, admin: { ...state.admin, loggedIn: true } };
      return newState;
    }
    case ACTIONS.LOGOUT_ADMIN_FAILED: {
      newState = { ...state, admin: { ...state.admin, loggedIn: false } };
      return newState;
    }
    case ACTIONS.USERS_TRANSACTIONS_ADMIN_FAILED: {
      newState = { ...state, admin: { ...state.admin, api: action.payload } };
      return newState;
    }
    case ACTIONS.USERS_TRANSACTIONS_ADMIN_SUCCESS: {
      newState = { ...state, admin: { ...state.admin, api: action.payload } };
      return newState;
    }
    case ACTIONS.SAVE_CSV: {
      newState = {
        ...state,
        ui: {
          ...state.ui,
          business: { ...state.ui.business, csvRows: action.payload },
        },
      };
      return newState;
    }
    case ACTIONS.SAVE_ORDERS: {
      newState = {
        ...state,
        ui: {
          ...state.ui,
          business: { ...state.ui.business, orders: action.payload },
        },
      };
      return newState;
    }
    case ACTIONS.SAVE_CSV_HEADERS: {
      newState = {
        ...state,
        ui: {
          ...state.ui,
          business: { ...state.ui.business, headers: action.payload },
        },
      };
      return newState;
    }
    case ACTIONS.GOOGLE_MAPS_READY:
      newState = {
        ...state,
        googleMaspReady: true,
      };
      return newState;
    default:
      return state;
  }
  return state;
};

const combinedReducer = combineReducers({
  data: mainReducer,
});

export default combinedReducer;
