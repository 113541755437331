
import React, { useState } from 'react';
import { useTitle } from 'hookrouter';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Navigation from './Navigation.component';
import MainPageFooter from './MainPageFooter.component';
import IMAGES from '../common/images';
import { Colors } from '../common/colors';
import { useRoutes, A, useRedirect } from 'hookrouter';
import { setHeadMetaDescription, getImgTag } from '../common/functions';
import CONSTANTS from '../common/constants';
import { get } from 'lodash';
import {BLOG_CONTENT} from '../common/content';

let path = CONSTANTS.PUBLIC_URL;
path = path + (path.substring(path.length - 1) !== '/' ? '/' : '');

function BlogComponent(props) {
  const displayMode = props.displayMode;
  const MainContent = function (props) {
    useTitle(props.title || get(props, 'content.pageTitle', ''));
    setHeadMetaDescription(props.description || get(props, 'content.pageDescription', ''));

    const [showBlog, setShowBlog] = useState(displayMode === 'DESKTOP');
    const contentColumn = get(props, 'content.content', '');
    const img = get(props, 'content.img', null);
    const createDate = get(props, 'content.createDate', '');
    const blogHeader = get(props, 'content.blogHeader', '');
    const pageDescription = get(props, 'content.pageDescription', '');
    return (
      <>
        <div className='header-container'>
          {get(props, 'content.title', 'BlogWinner')}
          {props.type === 'newsroom' && <div className='sub'>{get(BLOG_CONTENT, 'subTitle', '')}</div>}
        </div>
        <div className=" display-flex fjc-space-around contain-cotainer">
          <div className="menu-column">


            <div className="link-container">
              <A className={"links" + (!get(props, 'content.title', null) ? ' selected' : '')} href={path + 'blogs/blog-winner'}>BlogWinner</A>
            </div>
            <button className="blogs mobile-component" onClick={() => setShowBlog(!showBlog)} ><div className="display-flex"><div className="button-text">Blogs</div> <div className={"icon" + (showBlog ? " rotate-180" : '')}> <IMAGES.arrowDown /></div></div></button>

            {showBlog && BLOG_CONTENT.content.map((item, i) =>
              <div className="link-container" key={i}>
                <A className={"links" + (item.title === get(props, 'content.title', '') ? ' selected' : '')} href={path + 'blogs/' + item.path}>{item.title}</A>
              </div>
            )}

          </div>
          {props.type === 'blog' && <div className="content-column">
            <div className="left-blog-header">
              {blogHeader}

            </div>
            <div className="description">
              {pageDescription}

            </div>
            <div className="create-date">
              {createDate}
            </div>
            <hr />
            {img.baseUrl && getImgTag(img, 'blog-header-img')}
            {contentColumn.map((sec, i) => {
              return (
                <React.Fragment key={i}>
                  <div className="paragraph-header" dangerouslySetInnerHTML={{ __html: sec.header }}></div>
                  {sec.para.map((para, i2) => {
                    return (
                      <div className="paragraph" key={i + '.' + i2} dangerouslySetInnerHTML={{ __html: para }}></div>
                    )
                  })}
                </React.Fragment>
              )
            })}
          </div>}
          {props.type === 'newsroom' && <div className="content-column">
            <div className='display-flex  blog-view-container'> {BLOG_CONTENT.content.map((item, i) => {
              return (
                <A href={path + 'blogs/' + item.path} className="blog-view" key={i}>
                  <div className="create-date-blog-view">
                    {item.createDate}
                  </div>

                  <div className="blog-header-view">

                    {item.title}

                  </div>
                  <div className="blog-img-view-container">
                    {item.img.baseUrl && getImgTag({ ...item.img, sizes: get(item.img, 'sizes', []).slice(0, 3) }, 'blog-img-view')}
                  </div>
                </A>
              );
            })}</div>


          </div>}
        </div>
      </>);
  };
  useRedirect('/', '/blog-winner');
  let routes = {
    '/blog-winner': () => <MainContent title={BLOG_CONTENT.title} description={BLOG_CONTENT.description} type={'newsroom'} />,
  };
  BLOG_CONTENT.content.forEach(item => { routes['/' + item.path] = () => <MainContent type={'blog'} content={item} pageDescription={setHeadMetaDescription(item.pageDescription)} /> });
  const routeResult = useRoutes(routes);

  const [tabIndexForAll, setTabIndexForAll] = useState(null);

  return (
    <BlogStyle className="hidden-on-fullscreen-modal">
      <div className="background-container">
        <Navigation passedTabIndex={tabIndexForAll} />
      </div>
      {routeResult}

      <MainPageFooter />
    </BlogStyle>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    displayMode: state.data.displayMode
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const BlogStyle = styled.div`
  .paragraph-header, .paragraph{
    a{
      color: ${Colors.blue2};
    }
  }
  .description{
    font-family: Museo_Sans300;
    margin-top: 16px;
    margin-bottom: 35px;
    font-size: 16px;
  }
  .background-container {
    background-image: url("${path}images/pages/home/header-blue.png");
    background-repeat: no-repeat;
    background-size:100%;
    color: white;
    width: 100%;
   }
  .contain-cotainer{
    margin-bottom:50px;
  }
  hr{
    border: none;
    border-bottom: solid 1px #d2cccc;
  }
  
  .left-blog-header{
    color:#62686D;
    padding-right: 20px;
    font-size: 20px;
    text-align: left;
    height: fit-content;
    align-self: center;
  }
.blog-view-container{
  flex-wrap:wrap;
}
.blog-view{
  width: 251px;
  height: 320px;
  display: block;
  margin: 20px;
  border-radius: 5px;
  box-shadow: 1px 2px 4px 1px #afabab;
}
.create-date-blog-view{
  height:5%;
  color:#929C9B;
  font-size:12px;
  font-family:museo_sans300;
  padding:10px;
}

A{
  text-decoration:none;
}
.blog-header-view{
  height: 52px;
  padding: 10px;
  font-size: 20px;
  color: #62686D;
  font-family: museo_sans500;
  line-height: 24px;
}

.blog-img-view-container{
  height: 212px;
  overflow:hidden;
}
.blog-img-view{
  height: 192px;
  width: 100%;
  object-fit: cover;
  margin-top: 21px;
  overflow: hidden;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.create-date{
  color:#929C9B;
  font-size:12px;
  font-family:museo_sans300;
}
    

  
  .blog-header-img{
    width:100%;
    margin-bottom:10px;

  }

  .menu-column{
    flex:0 0 20%;
    color:#929C9B;
    font-family:museo_sans500;
    font-size:16px;
    margin-left:80px;
  
  
  .blogs{
    font-family:museo_sans500;
    font-size:16px;
    margin-top:20px;
    margin-bottom: 20px;

   }
    .link-container{
      padding-bottom:20px;
     }
    .links{
    
      text-decoration:none;
      color:#323232;
      font-family:museo_sans300;
      &.selected{
        
        color:#929C9B;
      }
    }
    
 }
  .content-column{
    flex: 0 0 60%;
    margin-right:10%;
   }
  .paragraph-header{
    color:#3A454E;
    font-size:20px;
    font-family:Museo_Sans500;
    margin-bottom:35px;
    margin-top:19px;
   }
  .paragraph{
      font-family:Museo_Sans300;
      margin-top:16px;
      margin-bottom:35px;
   }
 .header-container{
    width: 100%;
    text-align:center;
    background-color: #EDEDED;
    color:#323232;
    font-size:36px;
    padding: 30px 0;
    margin-bottom: 30px;
    .sub{
      font-size: 19px;
      margin-top: 10px;
      font-family: museo_sans300;
    }
  }
  
 .first-header{
      color: ${Colors.black};
      font-family: museo_sans500;
      text-align: center;
      font-size: 36px;
      padding-top: 16px;
      padding-bottom: 12px;
    }
    background-color:${Colors.white};
  
  @media only screen and (max-width: 992px){
    .background-container{
      background-image: unset;
      background-color: #03548f;
    }
    .header-container{
      margin-bottom:0px;
      min-height: 0px;
    }

    .contain-cotainer{
    margin-bottom:50px;
    flex-direction: column;
  
     }
  .content-column{
      margin-right:0%;
      padding:25px 10px; 
   }
  .blog-header{
    flex-direction:column;
  }
  .blog-view{
    border: solid 2px lightgray; 
    border: solid 2px lightgray;
    width: 100%;
    height: 100%;
    display: block;
    margin: 5%;
   }
  .first-header{
    padding-top: 20px;
    font-size:30px;
  }
  .blog-header-img{
    
    margin-bottom:10px;

  }
  .create-date{
    margin-bottom: 15px;
    margin-top: 15px;
    color: #929C9B;
    font-size: 12px;
    font-family: museo_sans300;
  }
  .button-text{
    flex:0 0 95%;
  }
  .icon{
    
    color:${Colors.darkBlue1};
  }
  
  .rotate-180{
    transform: rotate(180deg);
  }
  .menu-column{
    
    color:#929C9B;
    font-family:museo_sans500;
    font-size:16px;
    margin-left:0px;
    text-align:center;
    .blogs{
      width:100%;
      text-align:left;
      border: solid 1px #C8D9E2;
      background-color:${Colors.white};
      color:#323232;
      margin-top:0px;
      margin-bottom:0px;
      height:50px;
     
     
    }
  }
  .link-container{
      padding: 10px 10px 0px 10px;
      border:solid 1px #C8D9E2;
      text-align:left;
    .links{
      text-decoration:none;
      color:#323232;
      font-family:museo_sans300;
      font-size:16px;
      &.selected{
        font-size:18px;
        font-family:museo_sans500;
        color:#323232;
      }
    
    }
  }
  }
`;


const Blog = connect(
  mapStateToProps,
  mapDispatchToProps
)(BlogComponent);

export default Blog;
