import React, { useRef, useState, useMemo } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { modalQueue, api_login, api_getInitialData } from '../redux/actions';
import { Button, TextField, Link } from '@material-ui/core';
import { Colors } from "../common/colors";
import CONSTANTS from '../common/constants';
import { get } from 'lodash';
import PigeItCheckbox from "./PigeItCheckbox.component";
import Loader from './Loader.component';
import IMAGES from '../common/images';

function PigeItThankYou_ShowPackagesComponent(props) {

  const dispatch = useDispatch();
  const [inProgress, setInProgress] = useState(false);


  return (
    <PigeItThankYou_ShowPackagesStyle className="oginl-container">
      <div className="background-container">
        <div className="content-container">
          <Button className="modal-close-button" onClick={close}>
            <IMAGES.close />
          </Button>
          <div className="header-text">All Packages</div>
          <div className='content'>
            <div className='packages-list'>
              {[...get(props, 'purchaseProgress.morePackages', []),get(props, 'purchaseProgress.firstPackage', {})].map((pack, i) => {
                return (
                  <div key={i} className='package-info display-flex fjc-space-between'>
                    <div className='number'>{'#' + (i + 1)}</div>
                    <div className='type'>{get(pack, 'selectedPackage.baseDescription', '')}</div>
                    <div className='weigth'>{get(pack, 'weight', '0')} {get(pack, 'weightUnits', 'lbs')}</div>
                    <div className='size'>{get(pack, 'selectedPackage.selectionDisplayDimensions', '')}</div>

                  </div>)
              })}
            </div>
          </div>
        </div>
      </div>
    </PigeItThankYou_ShowPackagesStyle >
  );




  function close() {
    if (inProgress) {
      return;
    }
    dispatch(modalQueue({ mode: 'delete' }));
  }


}
const mapStateToProps = (state, ownProps) => {
  return {
    purchaseProgress: state.data.ui.mainPage.purchaseProgress
  }
};




const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const PigeItThankYou_ShowPackagesStyle = styled.div`
width: 100%;
height: 100%;

  .background-container {
    height: 100%;
    width: 100%;
    background-color: #fff;
    margin: 0 auto;
    
  }
  .content-container {
    padding-top: 45px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .modal-close-button {
    position: absolute;
    top: 21px;
    right: 26px;
  }
  .header-text {
    font-size: 36px;
    text-align: center;
    margin-bottom: 20px;
  }
  .content{
    background-color: #F4F8FB;
    padding: 20px;
  }
  .label{
      color:#62686D;
      font-size:16px;
      margin-bottom:8px;
    }
    .text, .number, .size, .weigth{
      max-width: 230px;
      font-size:14px;
      font-family:museo_sans300;
    }
  .packages-list{
        height: 360px;
        overflow-y: scroll;
        background-color: ${Colors.white};
        padding: 0 20px;
        .package-info{
        display: flex;
        flex-direction: row;
        .type{
          font-size: 14px;
        }
        .size, .weigth, .number{
          margin-top: 2px;
        }
        margin: 15px 0px;
      }
  }
  @media only screen and (max-width: 992px) { 
    .display-flex {
      flex-flow: column nowrap;
    }
    .content{
      padding: 0;
    }
    .packages-list{
      padding: 0;
    }
  }
  
`;

const PigeItThankYou_ShowPackages = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItThankYou_ShowPackagesComponent);




export default PigeItThankYou_ShowPackages;
