import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import IMAGES from '../common/images';

function PigeItTrackOrderInputComponent(props) {
  const [trackingNumber, setTrackingNumber] = useState('');

  return (
    <PigeItTrackOrderInputStyle style={{ ...props.style }} className={props.className}>
      <input tabIndex={props.passedTabIndex} className="track-order-input" value={trackingNumber} onChange={(e) => setTrackingNumber(e.target.value)} type="text" placeholder="Enter your tracking number" />
      <a tabIndex={props.passedTabIndex} target='_blank' rel="noopener noreferrer" href={getTrackingNumber()} className="track-order-button"><IMAGES.search /></a>
    </PigeItTrackOrderInputStyle>
  );
  
  function getTrackingNumber() {
    if (trackingNumber === '') {
      return null;
    }
    if (isNaN(trackingNumber)) {
      return 'https://wwwapps.ups.com/tracking/tracking.cgi?tracknum=' + trackingNumber;
    }
    else {
      return 'https://www.fedex.com/apps/fedextrack/?trackingnumber=' + trackingNumber;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const PigeItTrackOrderInputStyle = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 260px;
  background-color: #0b578b;
  border-color: #0b578b;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  height: 45px;
  margin: 2px;
  .track-order-input {
    width: 100%;
    color: #fff;
    padding: 0 16px;
    font-size: 16px;
    letter-spacing: .65px;
    background: none;
    border: none;
    outline: none;
    ::placeholder {
      color: rgba(255,255,255,0.43);
      font-size:13px;
    }
  }
  .track-order-button {
    svg{
      path{
        fill: white;
      }
    }
    font-size: 11px;
    color: #fff;
    background-color: transparent;
    position: relative;
    padding: 10px;
    border: none;
  }
`;

const PigeItTrackOrderInput = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItTrackOrderInputComponent);

export default PigeItTrackOrderInput;
