/* eslint react/jsx-pascal-case: off */
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { modalQueue, api_packages_add, api_packages_update, api_packages_getList, setupAnimatedLoader } from '../redux/actions';
import { Button } from '@material-ui/core';
import { Colors } from "../common/colors";
import { ZIndices } from '../common/zIndices';
import { COMPONENT_CONSTANTS } from './PigeItInputWithHorizontalSelectAndListSelect.component';
import MainPageInputPackageTypeBasic from './MainPageInputPackageTypeBasic.component';
import CONSTANTS from '../common/constants';
import IMAGES from '../common/images';
import STYLE from '../common/style';
import { get } from 'lodash';
import { convertPackageIdtoPackage, isValidSizeValue, isValidWeightValue } from "../common/functions";
import PigeItInput from './PigeItInput.component';

function PigeItMyAccount_Packages_AddEditComponent(props) {
  const CONTENT_TYPES = [{ text: 'Choose type', value: '' }];
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const packageToEdit = get(props, 'modalConfig.packageToEdit', {});
  const [name, setName] = useState(packageToEdit.name || '');
  const [content, setContent] = useState(packageToEdit.content || '');
  const [weight, setWeight] = useState({ type: (packageToEdit.weightUnitId === 1 || !packageToEdit.weightUnitId ? 'lbs' : 'kg') || 'lbs', weight: packageToEdit.weight || '' });
  const [inProgress, setInProgress] = useState(false);

  let packageType = JSON.parse(JSON.stringify(CONSTANTS.PACKAGE_TYPE_CUSTOM));
  if (packageToEdit) {
    if (packageToEdit.packageTypeId == CONSTANTS.PACKAGE_TYPE_CUSTOM.id) {
      // custom package
      packageType = { ...packageType, dimensionsHeight: packageToEdit.sizeHeight, dimensionsLength: packageToEdit.sizeLength, dimensionsUnits: parseInt(packageToEdit.sizeUnitId) === 1 ? "in" : "cm", dimensionsWidth: packageToEdit.sizeWidth, selectionDisplayDimensions: packageToEdit.sizeWidth + '" x ' + packageToEdit.sizeHeight + '" x ' + packageToEdit.sizeLength + '"' };
    } else if (packageToEdit.packageTypeId) {
      packageType = convertPackageIdtoPackage(packageToEdit.packageTypeId);
    }
  }
  const [selectedPackage, setSelectedPackage] = useState(packageType);
  const [error, setError] = useState({});
  return (
    <PigeItMyAccount_Packages_AddEditStyle className="my-account-container" ref={containerRef}>
      <div className="mobile-component bottom-banner">
        <div className="add-package-button-container">
          <Button onClick={save}>{packageToEdit.id ? 'SAVE PACKAGE' : 'ADD PACKAGE'}</Button>
        </div>
      </div>
      <div>
        <div className="header-container">
          <Button className="modal-close-button" onClick={close}>
            <IMAGES.close />
          </Button>
          <div>
            <IMAGES.logo className="active-logo" />
          </div>
          <div className="edit-package-text">{packageToEdit.id ? "Edit Pacakge" : "Add Package"}</div>
          <div className="background-container background-container-mobile">
            <form>
              <div className="ta-left field-label-input-container">
                <PigeItInput label="Package Name" name="package-name" id="package-name" type="text" value={name} onChange={(e) => setName(e.target.value)} className={"input-field"} error={error.name} />
                <div className="field-label display-flex fjc-space-between">
                  <label htmlFor="package-type">Package Info 	&nbsp;<span className='sub-title'>(in,lbs)</span></label>
                  <button className="desktop-component add-container more" type='button' onClick={selectPackage}><span className="text">More Package Types</span></button>
                </div>
                <div className='display-flex fjc-space-between'>
                  <PigeItInput label="Weight" className={"input-field"} error={error.weight} showHorizontalSelect={true} name="package-weight" id="package-weight" onChange={(e) => { onWeightChange(COMPONENT_CONSTANTS.INPUT_FIELD, e.target.value); }} value={weight.weight} />
                  <MainPageInputPackageTypeBasic errors={error.errors} onChange={(value) => setSelectedPackage(value)} className={"select-package" + (error.selectedPackage ? ' error' : '')} selectedPackageType={selectedPackage} onEditClick={clearSelection} />
                </div>
                <button className="mobile-component add-container more" type='button' onClick={selectPackage}><span className="text">More Package Types</span></button>
                <PigeItInput label="Package Content" name="package-content" id="package-content" type="text" className="input-field" value={content} onChange={(e) => setContent(e.target.value)} />
              </div>
            </form>
          </div>
          <div className="display-flex fjs-center">
            <div className="desktop-component add-package-button-container">
              <Button onClick={save}>{packageToEdit.id ? 'SAVE PACKAGE' : 'ADD PACKAGE'}</Button>
            </div>
            <Button className="desktop-component cancel-button" onClick={cancel}>CANCEL</Button>
          </div>
        </div>
      </div>
    </PigeItMyAccount_Packages_AddEditStyle>
  );

  function close() {
    dispatch(modalQueue({ mode: 'delete' }));
  }
  function cancel() {
    close();
  }

  function clearSelection() {
    setSelectedPackage(JSON.parse(JSON.stringify(CONSTANTS.PACKAGE_TYPE_CUSTOM)));
    setWeight({ type: 'lbs', weight: '' });
  }

  function selectPackage() {
    let modalConfig = JSON.parse(JSON.stringify(CONSTANTS.MODALS_CONFIG.PACKAGE_TYPE_SELECT_PACKAGE));
    modalConfig.selectedPackage = { selectedPackage, weightUnits: weight.type, weight: weight.weight };
    modalConfig.disableAddToPackageList = true;
    modalConfig.callback = function (selectedPackage, w) {
      setWeight({ type: w.unit, weight: w.value });
      setSelectedPackage(selectedPackage);
    };
    dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
  }

  function save() {
    if (inProgress) {
      return;
    }
    let err = {};
    if (CONSTANTS.PACKAGE_TYPE_CUSTOM.id) {
      let errors = {};
      if (!isValidSizeValue(selectedPackage.dimensionsHeight)) {
        errors.height = true;
      }
      if (!isValidSizeValue(selectedPackage.dimensionsWidth)) {
        errors.width = true;
      }
      if (!isValidSizeValue(selectedPackage.dimensionsLength)) {
        errors.length = true;
      }
      err.errors = errors;
    }
    if (!isValidWeightValue(weight.weight, 0, 150)) { err.weight = true; }
    if (!name) { err.name = true; }
    setError(err);
    if (Object.entries(err).every(entry => !entry[1] || (typeof entry[1] === 'object'))) {
      let request = {
        name: name,
        content: content,
        packageTypeId: parseInt(selectedPackage.id || 0),
        weight: parseFloat(weight.weight),
        weightUnitId: parseInt(weight.type === 'lbs' ? 1 : 2),
        sizeHeight: parseFloat(selectedPackage.dimensionsHeight),
        sizeWidth: parseFloat(selectedPackage.dimensionsWidth),
        sizeLength: parseFloat(selectedPackage.dimensionsLength),
        sizeUnitId: parseInt(selectedPackage.dimensionsUnits === 'in' ? 1 : 2)
      };
      if (packageToEdit) {
        request.id = packageToEdit.id;
      }
      let dispatchMethod;
      let loaderText;
      if (packageToEdit.id) {
        loaderText = 'Updating package...';
        dispatchMethod = api_packages_update;
      }
      else {
        loaderText = 'Saving package...';
        dispatchMethod = api_packages_add;
      }
      setInProgress(true);
      dispatch(setupAnimatedLoader({ isVisible: true, text: loaderText }));
      request.callback = function (result) {
        dispatch(api_packages_getList({
          callback: function () {
            setInProgress(false);
            dispatch(setupAnimatedLoader({ isVisible: false }));
            if (result.success) {
              dispatch(modalQueue({ mode: 'delete' }));
            }
          }
        }));
      };
      dispatch(dispatchMethod(request));
    }
  }

  function onWeightChange(fieldName, value) {
    switch (fieldName) {
      case COMPONENT_CONSTANTS.INPUT_FIELD:
        setWeight({ weight: value, type: weight.type });
        break;
      case COMPONENT_CONSTANTS.HORIZONTAL_SELECT:
        setWeight({ weight: weight.weight, type: value });
        break;
    }
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const PigeItMyAccount_Packages_AddEditStyle = styled.div`
  svg.active-logo {
    ${STYLE.activeLogo}
  }
  .package-type{
    &.sub-title{
      color: #b1b1b1;
      font-size: 14px;
    }
    display: block;
  }
  .add-container {
    &.more{
    }
    display: block;
    color: ${Colors.blue1};
    background-color: rgba(0,0,0,0);
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
  }
  background-color: ${Colors.white};
  height: 100%;
  text-align: center;
  overflow: auto;
  .select-package{
    width: 96.2%;
    flex: 0 0 72%;
    margin-right: 11px;
  }
  .modal-close-button {
    position: absolute;
    top: 21px;
    right: 26px;
  }
  .background-container{
    background-color: ${Colors.lightBlue};
    width: 800px;
    margin: auto;
  }
  .field-label-input-container {
    padding: 20px 94px 3px ;
    
   

  }
  .space-between{
    flex: 0 0 45%;
  }
  
  .field-label {
    font-size: 16px;
    color: ${Colors.lightGray2};
    margin-bottom: 11px;
  
  }
  .error{
      border: solid 1px ${Colors.error};
      border-radius: 3px;
      height:39px;
    }
    .error-package{
      input{
        border: solid 1px ${Colors.error};
        border-radius: 3px
      }
    }
  .input-field {
    width: 100%;
    flex: 0 0 23%;
    margin-bottom: 20px;
  }
  .select-field{
    width: 100%;
    height: 40px;
    margin-bottom: 11px;
    background-color: ${Colors.white};
  }
  .header-container {
    z-index: ${ZIndices.myProfileHeader};
    width: 100%;
    padding-top: 25px;
    background: ${Colors.white};
    
  }
  .edit-package-text {
    font-size: 36px;
    font-family:museo_sans500;
    margin-bottom: 20px;
  }
  .menu-listing {
    margin-bottom: 10px;
  }
  .menu-item {
    margin: 0 15px 0 5px;
    font-size: 16px;
    color: ${Colors.black};
    &.active {
      color: ${Colors.blue1};
    }
  }
  .border-component {
    background: white;
    height: 30px;
    background-image: linear-gradient(#F2F2F2, #fff);
  }
  .content-container {
    padding-top: 198px;
  }
  .active-item-container {
    margin: 0 auto;
    width: 850px;
  }
  .add-package-button-container {
    margin-top: 20px;
    padding-left: 30px;
    BUTTON {
      padding-left: 47px;
      padding-right: 47px;
      font-family: museo_sans700,sans-serif;
      font-size: 14px;
      max-width: 250px;
      width: 100%;
      height: 56px;
      color: ${Colors.white};
      background-color: ${Colors.orange};
    }
  }
  .bottom-banner {
      width: 100%;
      position: fixed;
      background: white;
      z-index: ${ZIndices.myAccountPackagesAddEdit};
      bottom: 0;
      box-shadow: 0px -1px 6px #d0d0d0;
  }
  .cancel-button {
    font-size: 14px;
    background-color: transparent;
    border: none;
    color: #2699FB;
    font-family: museo_sans500;
    outline:none;
    cursor: pointer;
    margin-left: 20px;
    margin-top: 20px;
  }
  @media only screen and (max-width: 992px) {
    .add-container {
      &.more{
        margin: auto;
        font-size: 18px;
        padding-bottom: 15px;
      }
    }
    .select-package{
      .selected-package-and-edit-container{
        display: flex;
        flex-flow: row;
        margin-bottom: 20px;
      }
    }
    .error{
      border: solid 1px ${Colors.error};
      border-radius: 3px;
      height:55px;
    }
    .background-container-mobile{
      width:unset;
      margin:30.67px 10px;
    }
    .active-item-container {
      width: auto;
    }  
    .display-flex {
      flex-flow: column nowrap;
    }
    .field-label-input-container {
      padding: 20px 13px 36px;
    }
    .input-field {
      margin-top: 7px;
    }
    .field-label {
      margin-top:10px;
    }
    .add-package-button-container {
      padding-left:unset;
      margin-bottom:10px;
      margin-top: 10px;
    }
  }
`;


const PigeItMyAccount_Packages_AddEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(PigeItMyAccount_Packages_AddEditComponent);


export default PigeItMyAccount_Packages_AddEdit;
