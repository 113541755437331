/* eslint default-case: off, eqeqeq: off, react-hooks/exhaustive-deps: off */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect, useDispatch, useStore } from 'react-redux';
import PigeItInputAddress, { getPlaceDetailsFromGoogle } from "./PigeItInputAddress.component";
import PigeItInputWithHorizontalSelectAndListSelect, { COMPONENT_CONSTANTS } from "./PigeItInputWithHorizontalSelectAndListSelect.component";
import { Colors } from '../common/colors';
import { promptUserToSignIn, isUserLoggedIn, showErrorMessage, buildPackages, getBestPrice, getBluesnapEncryptedFields, convertPackageIdtoPackage, identifyCreditCardType, convertPackageSizeUnitTextToId, convertPackageWeightUnitTextToId, navigateToPath, logEventForAnalytics, isValidSizeValue, isValidWeightValue } from "../common/functions";
import MainPageInputPackageTypeBasic from "./MainPageInputPackageTypeBasic.component";
import { delete_insurance, genericSetValue, modalQueue, api_packages_add, api_packages_getList, api_creditcard_getList, api_addressBook_getList, api_getPrices, api_verifyPayment, api_pay, api_addressBook_add, api_creditcard_addCreditCard, setupAnimatedLoader } from '../redux/actions';
import Loader from './Loader.component';
import { get } from 'lodash';
import CONSTANTS from '../common/constants';
import IMAGES from '../common/images';
import PigeItRadio from "./PigeItRadio.component";
import PigeItSelect from './PigeItSelect.component';
import { Button } from '@material-ui/core';
import PigeItCheckbox from './PigeItCheckbox.component';
import moment from 'moment';
import PigeItCreditCardInput from './PigeItCreditCardInput.component';
import PigeItInput from './PigeItInput.component';
import PigeItCouponInput from './PigeItCouponInput.component';

function MainPagePurchaseProgressComponent(props) {
  // if there's no prices and stage input is not 1
  if (!get(props, 'apiData_getPrices.result.success', false) && props.stage !== 1) {
    navigateToPath('', true);
  }
  const DEFAULT_SELECTED_SHIPPING_OPTION_LIST = 'Choose a shipping option';
  const DROP_OFF_TYPES_LIST = [{ text: 'Drop off', value: String(CONSTANTS.SHIPPING_DROPOFF_TYPE_ID_DROPOFF) }];//TODO fix pickup , { text: 'Pick up', value: String(CONSTANTS.SHIPPING_DROPOFF_TYPE_ID_COURIER) }];
  const getBestPriceForFullSelection = getBestPrice(props.apiData_getPrices, { company: props.purchaseProgress.activeCompany, shippingTypeId: get(props, 'purchaseProgress.selectedShippingOption.value', null), shippingOption: get(props, 'purchaseProgress.selectedShippingRadioOption', null) });
  const store = useStore();
  const dispatch = useDispatch();
  const [inProgress, setInProgress] = useState(false);
  const [paymentType, setPaymentType] = useState(false);
  const [agreedTerms, setAgreedTerms] = useState(false);
  const [useExistingPayment, setUseExistingPayment] = useState(false);
  const [sendEmailNotifications, setSendEmailNotifications] = useState(false);
  const [errors, setErrors] = useState({});
  const [residentialAddress, setResidentialAddress] = useState(false);
  const [cardType, setCardType] = useState(null);
  const [selectedCreditCard, setSelectedCreditCard] = useState({});
  const [senderAddressError, setSenderAddressError] = useState(null);
  const [recipientAddressError, setRecipientAddressError] = useState(null);
  const [senderServerError, setSenderServerError] = useState(false);
  const [recipientServerError, setRecipientServerError] = useState(false);
  const [showMoreOptions, setshowMoreOptions] = useState(false);
  const [showInsurance, setShowInsurance] = useState(false);

  useEffect(() => {
    if (get(props, 'purchaseProgress.firstPackage.value', false) || get(props, 'purchaseProgress.firstPackage.content', false)) {
      setShowInsurance(true);
    }
  }, [props.purchaseProgress])

  useEffect(() => {
    if (props.packId) {
      dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress', value: { ...props.purchaseProgress, selectedCompany: get(props, 'company', '').toLowerCase(), firstPackage: { weightUnits: 'lbs', weight: '', selectedPackage: convertPackageIdtoPackage(props.packId) } } }));
    }
  }, []);

  let CardIcon = get(selectedCreditCard, 'cardType', null) ? IMAGES[identifyCreditCardType(null, selectedCreditCard.cardType.toLowerCase())] : null;
  const completedStage1Strip = (stage) => (<button className={"completed-container display-flex fd-row fjs-center" + (stage === 3 ? ' stage3-completed-1' : '')} onClick={editStage1}>
    <div className="desktop-component from-label">From</div><div className="from-text">{get(props, 'purchaseProgress.senderAddress', '')}</div>
    <div className="desktop-component to-label">To</div><div className="to-text">{get(props, 'purchaseProgress.recipientAddress', '')}</div>
    <div className="desktop-component packages-label">Packages</div><div className="desktop-component packages-text">{(get(props, 'purchaseProgress.morePackages.length', 0) + 1)} packages, {sumPackagesWeight() + ' ' + props.weightUnits + (hasInsurance() ? (', value: $' + sumPackagesValue()) : "")}</div>
    <div className="action-icon-container"><IMAGES.edit /></div>
  </button>);

  return (
    <MainPagePurchaseProgressStyle className="purchase-progress-wrapper">
      {props.stage == 1 && (
        <div className="stage1-panel-container panel-container">
          <div className="display-flex fjc-space-between">
            <div className="flex-divided-3-cols">
              <div className={"panel-with-background with-right-margin"}>
                <div className='display-flex fjc-space-between'>
                  <div className="panel-item-title-container">{"From" + (props.from ? (" - " + props.from) : '')}</div>
                  <button className="add-container icon" type="button" onClick={() => selectAddressFromList('from')}><IMAGES.addressBook /></button>
                </div>
                <PigeItInputAddress error={(errors.senderPlaceId || senderServerError)} showListSelect={true} passedTabIndex={props.passedTabIndex} className="input-address-from-component" passedValue={props.purchaseProgress.senderAddress} onChange={(value) => { onAddressInputChange('from', value) }} inputName="from" inputId="from" inputPlaceholder="Address" />
                <div className={'error-message address' + ((errors.senderPlaceId || senderServerError) ? ' error-text' : '')}>*Please enter full address, including street name and house number</div>
              </div>
            </div>
            <div className="flex-divided-3-cols">
              <div className={"panel-with-background with-right-margin"}>
                <div className='display-flex fjc-space-between'>
                  <div className="panel-item-title-container">{"To" + (props.to ? (" - " + props.to) : '')}</div>
                  <button className="add-container icon" type="button" onClick={() => selectAddressFromList('to')}><IMAGES.addressBook /></button>
                </div>
                <PigeItInputAddress error={(errors.recipientPlaceId || recipientServerError)} showListSelect={true} passedTabIndex={props.passedTabIndex} className="input-address-to-component" passedValue={props.purchaseProgress.recipientAddress} onChange={(value) => { onAddressInputChange('to', value) }} inputName="to" inputId="to" inputPlaceholder="Address" />
                <div className={'error-message address' + ((errors.recipientPlaceId || recipientServerError) ? ' error-text' : '')}>*Please enter full address, including street name and house number</div>
              </div>
            </div>
            {(props.purchaseProgress.morePackages && props.purchaseProgress.morePackages.length > 0) ?
              <div className="flex-divided-3-cols">
                <div className={"panel-with-background" + (errors.packages ? ' error' : '')}>
                  <div className="display-flex fjc-space-between">
                    <div className="panel-item-title-container">{(props.purchaseProgress.morePackages.length + 1) + ' Packages'}</div>
                  </div>
                  <MainPageInputPackageTypeBasic canNotEdit={true} selectedPackageType={props.firstPackage} onEditClick={cleanSelectedPackage} passedTabIndex={props.passedTabIndex} className="input-package-type more-packages display-flex fjc-space-around" componentName="package-type" envelopeComponentId="package-type-envelope" boxComponentId="package-type-box" />
                </div>
              </div> : <>
                <div className="flex-divided-3-cols">
                  <div className={"panel-with-background with-right-margin"}>
                    <div className='display-flex fjc-space-between'>
                      <div className='display-flex container-80'>
                        <div className="panel-item-title-container">Package Type&nbsp;</div>
                        <button className="add-container more selected-type" type="button" onClick={selectPackage}>{get(props, "purchaseProgress.firstPackage.selectedPackage.selectionDisplayTitle", "Custom")}</button>
                        <div className='sub-title'>(in,lbs)</div>
                      </div>
                      <button className="add-container icon pack-list" type="button" onClick={onSelectPackageFromList}><IMAGES.packageList /></button>
                    </div>
                    <div className='display-flex package-info-container'>
                      <PigeItInput error={errors.weight} className="input-field" tabIndex={props.passedTabIndex} label='Weight' type="number" min={props.min} max={props.max} value={get(props, 'weight', '0')} onChange={(e) => onWeightChange(COMPONENT_CONSTANTS.INPUT_FIELD, e.target.value)} />
                      <MainPageInputPackageTypeBasic errors={errors.errors} onChange={changePackage} selectedPackageType={props.firstPackage} onEditClick={cleanSelectedPackage} onClick={selectPackage} passedTabIndex={props.passedTabIndex} className={"input-package-type display-flex"} componentName="package-type" envelopeComponentId="package-type-envelope" boxComponentId="package-type-box" />
                    </div>
                    <div className={'error-message weight' + (errors.weight ? ' error-text' : '')}>{"*Must be " + get(props, "purchaseProgress.firstPackage.selectedPackage.weightRange[1]", "150") + " or less"}</div>
                  </div>
                  <div className={"mobile-component panel-with-background package-container"}>
                    {showMoreOptions && <>
                      <Button tabIndex={props.passedTabIndex} className={"add-insurance" + (showInsurance ? ' hide' : '')} onClick={() => setShowInsurance(true)}><span>Add Insurance</span></Button>
                      <Button className="add-container more-options" onClick={() => addPackage(true)}><span className="text">Add Another package</span></Button></>}
                    <button className={"show-more" + (showMoreOptions ? " open" : '')} onClick={() => setshowMoreOptions(!showMoreOptions)}><IMAGES.arrowDown /></button>
                  </div>
                </div>
              </>}
          </div>
          {showInsurance && !get(props, 'purchaseProgress.morePackages', []).length && <div className='insurance-container'>
            <div className='display-flex fjc-space-between mani-container'>
              <PigeItInput error={errors.content} className='content-input' label='Package Content' onChange={(e) => onInsuranceChange('content', e.target.value)} value={get(props, 'purchaseProgress.firstPackage.content', '')} />
              <div className='display-flex fjc-space-between flex-width'>
                <PigeItInput error={errors.value} type="number" className='value-input' label='Declared Value (Up to 100 USD)' onChange={(e) => onInsuranceChange('value', e.target.value)} value={get(props, "purchaseProgress.firstPackage.value", "")} />
                <Button type="text" className='cancel-insurance' onClick={() => { setShowInsurance(false); onInsuranceChange('clear') }}><IMAGES.remove /><span>Cancel</span></Button>
              </div>
            </div>
          </div>}
          <div className="display-flex fjc-space-between second-row-controls-container">
            <Button tabIndex={props.passedTabIndex} className={"desktop-component add-insurance" + (showInsurance ? ' hide' : '')} onClick={() => setShowInsurance(true)}><IMAGES.plusSmall /><span>Add Insurance</span></Button>
            <Button tabIndex={props.passedTabIndex} className="get-your-price-button" name="get-your-price" onClick={getPrice}>{inProgress && <Loader imageOnly />}GET YOUR PRICE</Button>
            <Button tabIndex={props.passedTabIndex} className="desktop-component add-package-button" onClick={() => addPackage(true)}><IMAGES.plusSmall /><span>Add Another Package</span></Button>
          </div>
          <PigeItCheckbox name="billing-ditails-checkbox" className='address-checkbox' checked={residentialAddress} onClick={(checked) => setResidentialAddress(checked)} value={0} labelText={'Sending to a residential address.'} />
        </div>
      )}
      {props.stage == 2 && (
        <>
          <div className='header-text'>Choose Your Shipping Option</div>
          <div className="stage2-panel-container panel-container">
            <div className="">{completedStage1Strip(2)}</div>
            <div className='select-company-send-options'>
              <div className="display-flex fjc-space-between stage-2-tabs">
                <Button disabled={!isCompanyAvailable('FEDEX')} name="select-company-fedex" className={"flex-divided-2-cols company-button right-border" + (get(props, 'purchaseProgress.activeCompany', 'FEDEX') === 'FEDEX' ? ' active' : '')} onClick={() => { onCompanyClick('FEDEX'); }}>
                  <IMAGES.fedex className={"company-fedex" + (get(props, 'purchaseProgress.activeCompany', 'FEDEX') == 'FEDEX' ? ' active' : '')} />
                </Button>
                <Button disabled={!isCompanyAvailable('UPS')} name="select-company-ups" className={"flex-divided-2-cols company-button left-border" + (get(props, 'purchaseProgress.activeCompany', 'FEDEX') === 'UPS' ? ' active' : '') + " right-border"} onClick={() => { onCompanyClick('UPS'); }}>
                  <IMAGES.ups className={"company-ups" + (get(props, 'purchaseProgress.activeCompany', 'FEDEX') === 'UPS' ? ' active' : '')} />
                </Button>
              </div>
              <div className='display-flex fjc-space-around select-shipping-time'>
                <PigeItRadio name="select-shipping-option-radio-1-business-days" disabled={!isHavingOption(props.purchaseProgress.activeCompany, '1-business-days')} value={'1-business-days'} selectedRadioValue={props.purchaseProgress.selectedShippingRadioOption} setSelectedRadioValue={(value) => { onSelectShippingOptionRadio(value); }} labelText={(props.mobile ? (get(props, 'purchaseProgress.activeCompany', 'FEDEX') == 'FEDEX' ? "FedEx " : "UPS ") : '') + "Next Business Day"} />
                <PigeItRadio name="select-shipping-option-radio-2-business-days" disabled={!isHavingOption(props.purchaseProgress.activeCompany, '2-business-days')} value={'2-business-days'} selectedRadioValue={props.purchaseProgress.selectedShippingRadioOption} setSelectedRadioValue={(value) => { onSelectShippingOptionRadio(value); }} labelText={(props.mobile ? (get(props, 'purchaseProgress.activeCompany', 'FEDEX') == 'FEDEX' ? "FedEx " : "UPS ") : '') + "2 Business Days"} />
                <PigeItRadio name="select-shipping-option-radio-3-business-days" disabled={!isHavingOption(props.purchaseProgress.activeCompany, '3-business-days')} value={'3-business-days'} selectedRadioValue={props.purchaseProgress.selectedShippingRadioOption} setSelectedRadioValue={(value) => { onSelectShippingOptionRadio(value); }} labelText={(props.mobile ? (get(props, 'purchaseProgress.activeCompany', 'FEDEX') == 'FEDEX' ? "FedEx " : "UPS ") : '') + "3 Business Days" + (isHavingMoreThanMax() ? '+' : '')} />
                <PigeItRadio name="select-shipping-option-radio-ground" disabled={!isHavingOption(props.purchaseProgress.activeCompany, 'ground')} value={'ground'} selectedRadioValue={props.purchaseProgress.selectedShippingRadioOption} setSelectedRadioValue={(value) => { onSelectShippingOptionRadio(value); }} labelText={(get(props, 'purchaseProgress.activeCompany', 'FEDEX') == 'FEDEX' ? "FedEx" : "UPS") + " Ground"} />
              </div>
              <div className='display-flex select-pickup-type'>
                <PigeItSelect className='first-select' value={props.purchaseProgress.selectedShippingOption} name="select-shipping-option-list" onChange={(value) => { onSelectShippingOptionList(value); }} valueList={getShippingOptionsList(get(props, 'purchaseProgress.selectedShippingRadioOption', ''))} />
                <div className='seperator' />
                <PigeItSelect value={props.purchaseProgress.selectedDropoffType} name="select-drop-off-type" onChange={(value) => { onSelectDropoffType(value); }} valueList={DROP_OFF_TYPES_LIST} />
              </div>
            </div>
            {!props.mobile ? <div className="display-flex fjc-space-between second-row-controls-container">
              <Button tabIndex={props.passedTabIndex} className={"desktop-component add-insurance" + (!hasInsurance() ? ' hide' : '')} onClick={() => dispatch(delete_insurance())}><IMAGES.close /><span>Cancel Insurance</span></Button>
              <div className='display-flex'>
                {hasInsurance() &&
                  <><button tabIndex={props.passedTabIndex} className="stage2-best-price-ship-now-button small" >
                    <div className='button-content'>
                      <div className='best-price small'>
                        <div className='best-price-text'>INSURANCE</div>
                        <div className='best-price-price'>${parseFloat(parseFloat(get(getBestPriceForFullSelection, 'option.price', '0')) - parseFloat(get(getBestPriceForFullSelection, 'option.withoutInsurance', '0'))).toFixed(2)}</div>
                      </div>
                    </div>
                  </button>
                    <div className='plus'>+</div>
                  </>}
                <button tabIndex={props.passedTabIndex} className="stage2-best-price-ship-now-button" onClick={shipNow}>
                  <div className='display-flex button-content'>
                    <div className='best-price'>
                      <div className='best-price-text'>BEST PRICE</div>
                      <div className='best-price-price'>${get(getBestPriceForFullSelection, 'option.withoutInsurance', 0) || get(getBestPriceForFullSelection, 'option.price', 0)}</div>
                    </div>
                    <div className='ship-now'>SHIP NOW</div>
                  </div>
                </button>
                {hasInsurance() && <div className='space' />}
              </div>
              <div className='cancel-space' />
            </div> :
              <div className='second-row-controls-container'>
                <button tabIndex={props.passedTabIndex} className="stage2-best-price-ship-now-button">
                  <div className='display-flex fjs-center button-content'>
                    <div className='best-price'>
                      <div className='best-price-text'>BEST PRICE</div>
                      <div className='best-price-price'>${get(getBestPriceForFullSelection, 'option.withoutInsurance', 0) || get(getBestPriceForFullSelection, 'option.price', 0)}</div>
                    </div>
                    {hasInsurance() && <>
                      <div className='plus'>+</div>
                      <div className='best-price small'>
                        <div className='best-price-text'>INSURANCE</div>
                        <div className='best-price-price'>${parseFloat(parseFloat(get(getBestPriceForFullSelection, 'option.price', '0')) - parseFloat(get(getBestPriceForFullSelection, 'option.withoutInsurance', '0'))).toFixed(2)}</div>
                      </div>
                    </>}
                  </div>
                </button>
                <button tabIndex={props.passedTabIndex} className={"stage2-best-price-ship-now-button last"} onClick={shipNow}>
                  <div className='display-flex button-content'>
                    <div className='ship-now'>SHIP NOW</div>
                  </div>
                </button>
                <Button tabIndex={props.passedTabIndex} className={"add-insurance" + (!hasInsurance() ? ' hide' : '')} onClick={() => dispatch(delete_insurance())}><IMAGES.close /><span>Cancel Insurance</span></Button>
              </div>}
          </div>
        </>
      )}
      {props.stage == 3 && (
        <>
          <div className='header-text'>One more step...</div>
          <div className="stage3-panel-container panel-container">
            <div className='fjc-space-around display-flex form-container'>
              <div className='card-container'>
                <div className='card-header'>
                  Shipper Information
                </div>
                <PigeItInput className={'input-field'} label='Address' disabled type='text' value={get(props, 'purchaseProgress.senderAddress', '')} />
                <PigeItInput className={'input-field'} type='text' label='Address line 2 (optional)' value={get(props, 'purchaseProgress.shipper.addressLine2', '')} onChange={(e) => { onStage3DetailsUpdate('shipper', 'addressLine2', e.target.value); }} />
                <div className='display-flex two-inputs-container'>
                  <PigeItInput className={'input-field half-width'} error={errors.senderName} type='text' label='Name' value={get(props, 'purchaseProgress.shipper.name', '')} onChange={(e) => { onStage3DetailsUpdate('shipper', 'name', e.target.value); }} />
                  <div className='seperator' />
                  <PigeItInput className={'input-field half-width'} error={errors.senderCompany} type='text' label='Company' value={get(props, 'purchaseProgress.shipper.company', '')} onChange={(e) => { onStage3DetailsUpdate('shipper', 'company', e.target.value); }} />
                </div>
                <PigeItInput className={'input-field'} error={errors.senderPhone} type='text' label='Phone' value={get(props, 'purchaseProgress.shipper.phone', '')} onChange={(e) => { onStage3DetailsUpdate('shipper', 'phone', e.target.value); }} />
                <PigeItInput className={'input-field'} error={errors.senderEmail} type='text' label='Email' value={get(props, 'purchaseProgress.shipper.email', '')} onChange={(e) => { onStage3DetailsUpdate('shipper', 'email', e.target.value); }} />
                <button className={'add-container' + (canSaveAddress('shipper') ? '' : ' hidden')} type='button' onClick={() => { saveAddress('shipper'); }}><IMAGES.plusSmall /><span className='text'>Add to address book</span></button>
              </div>
              <div className='card-container'>
                <div className='card-header'>
                  Receiver Information
                </div>
                <PigeItInput className={'input-field'} label='Address' disabled type='text' value={get(props, 'purchaseProgress.recipientAddress', '')} />
                <PigeItInput className={'input-field'} type='text' label='Address line 2 (optional)' value={get(props, 'purchaseProgress.recipient.addressLine2', '')} onChange={(e) => { onStage3DetailsUpdate('recipient', 'addressLine2', e.target.value); }} />
                <div className='display-flex two-inputs-container'>
                  <PigeItInput className={'input-field half-width'} error={errors.recipientName} type='text' label='Name' value={get(props, 'purchaseProgress.recipient.name', '')} onChange={(e) => { onStage3DetailsUpdate('recipient', 'name', e.target.value); }} />
                  <div className='seperator' />
                  <PigeItInput className={'input-field half-width'} error={errors.recipientCompany} type='text' label='Company' value={get(props, 'purchaseProgress.recipient.company', '')} onChange={(e) => { onStage3DetailsUpdate('recipient', 'company', e.target.value); }} />
                </div>
                <PigeItInput className={'input-field'} error={errors.recipientPhone} type='text' label='Phone' value={get(props, 'purchaseProgress.recipient.phone', '')} onChange={(e) => { onStage3DetailsUpdate('recipient', 'phone', e.target.value); }} />
                <PigeItInput className={'input-field'} error={errors.recipientEmail} type='text' label='Email (Optional)' value={get(props, 'purchaseProgress.recipient.email', '')} onChange={(e) => { onStage3DetailsUpdate('recipient', 'email', e.target.value); }} />
                <button className={'add-container' + (canSaveAddress('recipient') ? '' : ' hidden')} type='button' onClick={() => { saveAddress('recipient'); }}><IMAGES.plusSmall /><span className='text'>Add to address book</span></button>
              </div>
              <div className='card-container two-childeren'>
                <div className='card-container first'>
                  <div className='display-flex fjc-space-between'>
                    <div className='card-header'>
                      Package Information
                    </div>
                    {getStage3ShippingCompany()}
                    <button className='edit-button' onClick={editStage2}>
                      <IMAGES.edit />
                    </button>
                  </div>
                  <div className='display-flex fjc-start information first'>
                    <div className="packages-text">{(get(props, 'purchaseProgress.morePackages.length', 0) + 1)} packages,  {sumPackagesWeight() + ' ' + props.weightUnits + (hasInsurance() ? (', value: $' + sumPackagesValue()) : "")}</div>
                  </div>
                  <div className='display-flex fjc-start information'>
                    <div className="shipping-option-text-container">{getStage3ShippingOption()}</div>
                  </div>
                  <div className='display-flex fjc-start information'>
                    <div className="sending-option-text">{getStage3SendingOption()}</div>
                  </div>
                </div>
                <div className='line-container'><hr /></div>
                <div className='card-container second'>
                  <div className='display-flex fjc-space-between'>
                    <div className='card-header payment'>
                      Payment Method
                    </div>
                    <button className='select-card-from-list' onClick={() => onSelectCardFromList()}>Select Card</button>
                  </div>
                  {!useExistingPayment ? <> <PigeItCreditCardInput className={'input-field cc'} error={errors.cardNumber} type='input' placeholder='CC Number' value={get(props, 'purchaseProgress.payment.creditCard.cardNumber', '')} onChange={(card) => { onStage3DetailsUpdate('payment.creditCard', 'cardNumber', card.number); setCardType(card.cardType); }} />
                    <div className='display-flex two-inputs-container'>
                      <PigeItInput className={'input-field half-width'} error={errors.expiry} type='text' placeholder='00/00' label='Date' value={formatCardDate(get(props, 'purchaseProgress.payment.creditCard.expiry', ''))} onChange={(e) => { onStage3DetailsUpdate('payment.creditCard', 'expiry', e.target.value); }} />
                      <div className='seperator' />
                      <PigeItInput className={'input-field half-width'} error={errors.cvv} type='text' label={get(cardType, 'code.name', 'CVV')} value={get(props, 'purchaseProgress.payment.creditCard.cvv', '')} onChange={(e) => { onStage3DetailsUpdate('payment.creditCard', 'cvv', e.target.value); }} />
                    </div>
                    <div className='display-flex fjc-space-between'>
                      <button className={'add-container' + (canSaveCard('recipient') ? '' : ' hidden')} type='button' onClick={saveCard}><IMAGES.plusSmall /><span className='text'>Add to CC</span></button>
                      <div className='display-flex billing-ditails'>
                        <PigeItCheckbox name="billing-details-checkbox" className="billing-details-checkbox" value={0} checked={!props.differentBillingDetails} onClick={differentBillingDetails} labelText={'Billing details'} />
                      </div>
                    </div></> : <>
                    <div className='display-flex fjs-start'>
                      <div className='card-name'>{CardIcon && <CardIcon />}</div>
                      <div className='card-number'>{'  **** ' + selectedCreditCard.last4Digits}</div>
                    </div>
                    <div className='display-flex fjs-start'>
                      <div className='card-month'>{selectedCreditCard.expiryMonth + '/'}</div>
                      <div className='card-year'>{selectedCreditCard.expiryYear}</div>
                    </div>
                    <div className='display-flex fjs-center'>
                      <button className='new-card' onClick={() => setUseExistingPayment(false)}>Enter Different Card</button>
                    </div></>}
                </div></div>
              <div className='mobile-component card-container'>
                <div className="display-flex fjs-center agree-container">
                  <div className='agree-checkbox'>
                    <PigeItCheckbox name="billing-ditails-checkbox" checked={agreedTerms} onClick={(checked) => setAgreedTerms(checked)} value={0} labelText={''} />
                  </div>
                  <div className='agree-text'>
                    I agree to ShipWinner
                  </div>
                  <a className='agree-link' href=''>
                    Term & Conditions
                  </a>
                </div>
                {agreedTerms && <div className="display-flex fjs-center agree-container">
                  <div className='agree-checkbox'>
                    <PigeItCheckbox name="billing-ditails-checkbox" checked={sendEmailNotifications} onClick={(checked) => setSendEmailNotifications(checked)} value={0} labelText={''} />
                  </div>
                  <div className='agree-text'>
                    Send email notifications
                  </div>
                </div>}
                <div className="display-flex fjs-center coupon-container">
                  <PigeItCouponInput />
                </div>
                <button tabIndex={props.passedTabIndex} className={"best-price-confirm-button" + (agreedTerms ? '' : ' disabled')} disabled={!agreedTerms} onClick={confirm}>
                  <div className='display-flex button-content'>
                    <div className='best-price'>
                      <div className='best-price-text'>TOTAL</div>
                      <div className='display-flex fjs-center fia-center'>
                        {get(props, 'purchaseProgress.coupon.amount', '') && <div className='best-origin-price-price mobile'>${get(getBestPriceForFullSelection, 'option.price', 0)}</div>}
                        <div className='best-price-price mobile'>${Math.max(1, get(getBestPriceForFullSelection, 'option.price', 0) - get(props, 'purchaseProgress.coupon.amount', 0)).toFixed(2)}</div>
                      </div>
                    </div>
                    <div className='ship-now'>{inProgress && <Loader imageOnly />}CONFIRM</div>
                  </div>
                </button>
              </div>
            </div>
            <div className="desktop-component display-flex fjs-center agree-container">
              <div className='agree-checkbox'>
                <PigeItCheckbox name="billing-ditails-checkbox" checked={agreedTerms} onClick={(checked) => setAgreedTerms(checked)} value={0} labelText={''} />
              </div>
              <div className='agree-text'>
                I agree to ShipWinner
              </div>
              <a className='agree-link' href='/information/terms-introduction' onClick={(e) => { e.preventDefault(); navigateToPath('information/terms-introduction') }}>
                Term & Conditions
              </a>
            </div>
            {agreedTerms && <div className="desktop-component display-flex fjs-center agree-container">
              <div className='agree-checkbox'>
                <PigeItCheckbox name="billing-ditails-checkbox" checked={sendEmailNotifications} onClick={(checked) => setSendEmailNotifications(checked)} value={0} labelText={''} />
              </div>
              <div className='agree-text'>
                Send email notifications
              </div>
            </div>}
            <div className="desktop-component display-flex fjs-center controls-container">
              <div className='display-flex fjs-center'>
                <div style={{ width: '258px' }} />
                <button tabIndex={props.passedTabIndex} className={"best-price-confirm-button" + (agreedTerms ? '' : ' disabled')} disabled={!agreedTerms} onClick={confirm}>
                  <div className='display-flex button-content'>
                    <div className='best-price'>
                      <div className='best-price-text'>TOTAL</div>
                      <div className='display-flex fjs-center fia-center'>
                        {get(props, 'purchaseProgress.coupon.amount', '') && <div className='best-origin-price-price'>${get(getBestPriceForFullSelection, 'option.price', 0)}</div>}
                        <div className='best-price-price'>${Math.max(1, get(getBestPriceForFullSelection, 'option.price', 0) - get(props, 'purchaseProgress.coupon.amount', 0)).toFixed(2)}</div>
                      </div>
                    </div>
                    <div className='ship-now'>{inProgress && <Loader imageOnly />}CONFIRM</div>
                  </div>
                </button>
                <PigeItCouponInput />
              </div>
            </div>
          </div>
        </>
      )
      }
    </MainPagePurchaseProgressStyle >
  );

  ///////////////////////////////////////////////////////////////////
  ///////////////////////////// STAGE 1 /////////////////////////////
  ///////////////////////////////////////////////////////////////////

  function onInsuranceChange(fieldName, value) {
    switch (fieldName) {
      case 'value':
        dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress', value: { ...props.purchaseProgress, firstPackage: { ...props.purchaseProgress.firstPackage, value: value } } }));
        break;
      case 'content':
        dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress', value: { ...props.purchaseProgress, firstPackage: { ...props.purchaseProgress.firstPackage, content: value } } }));
        break;
      case 'clear':
        dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress', value: { ...props.purchaseProgress, firstPackage: { ...props.purchaseProgress.firstPackage, content: '', value: '' } } }));
        break;
    }
  }

  function onWeightChange(fieldName, value) {
    switch (fieldName) {
      case COMPONENT_CONSTANTS.INPUT_FIELD:
        dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress.firstPackage.weight', value: value }));
        break;
      case COMPONENT_CONSTANTS.HORIZONTAL_SELECT:
        dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress.firstPackage.weightUnits', value: value }));
        break;
    }
  }

  async function onAddressInputChange(fieldName, value) {
    const currentState = store.getState();
    let addressValue = get(value, 'placeObject.formatted_address', '') || get(value, 'address', ''); // placeObject is for google callback, address is for address book selection
    let placeIdValue = get(value, 'placeId', '') || get(value, 'googlePlaceId', '');
    let placeObject = get(value, 'placeObject', null);
    let company = get(value, 'company', '');
    let email = get(value, 'email', '');
    let fullname = get(value, 'fullname', '');
    let phone = get(value, 'phone', '');
    let objectToSet;
    let isValidAddress = false;
    let address_components = get(value, 'placeObject.address_components');
    if (!Array.isArray(address_components)) {
      address_components = get(await getPlaceDetailsFromGoogle(placeIdValue), 'address_components', []);
    }
    isValidAddress = (address_components || []).some(item => get(item, 'types', []).some(i => i === "postal_code"));
    const getField = name => {
      return get(address_components.find(component => get(component, 'types', []).some(type => type === name)), 'short_name', '')
    }
    const addressObject = {
      googlePlaceId: placeIdValue,
      addressDataVersion: "plain",
      countryCode: getField('country'),
      city: getField('locality'),
      stateOrProvinceCode: getField('administrative_area_level_1'),
      postalCode: getField('postal_code'),
      addressLines: [`${getField('street_number')} ${getField('route')}`]
    }
    switch (fieldName) {
      case 'from':
        setSenderServerError(false);
        setSenderAddressError(!isValidAddress);
        objectToSet = { ...get(currentState, 'data.ui.mainPage.purchaseProgress', {}), senderPlaceId: placeIdValue, senderAddressDetails: addressObject, senderAddress: addressValue, shipper: { name: fullname, phone: phone, email: email, company: company } };
        if (!value.isFromAddressBook) {
          // delete extra fields setup
          delete objectToSet.shipper;
        }
        dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress', value: objectToSet }));
        break;
      case 'to':
        setRecipientServerError(false);
        setRecipientAddressError(!isValidAddress);
        objectToSet = { ...get(currentState, 'data.ui.mainPage.purchaseProgress', {}), recipientPlaceId: placeIdValue, recipientAddressDetails: addressObject, recipientAddress: addressValue, recipient: { name: fullname, phone: phone, email: email, company: company } };
        if (!value.isFromAddressBook) {
          // delete extra fields setup
          delete objectToSet.recipient;
        }
        dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress', value: objectToSet }));
        break;
    }
  }

  function selectAddressFromList(fieldName) {
    if (props.disabled) {
      return;
    }
    if (isUserLoggedIn(get(props, 'apiData_getInitialData', {}))) {
      // open modal on address list tab
      const modalConfig = JSON.parse(JSON.stringify(CONSTANTS.MODALS_CONFIG.MY_ACCOUNT));
      modalConfig.initialTab = CONSTANTS.MY_ACCOUNT_ADDRESS_BOOK_TAB;
      modalConfig.addressBookClickable = true;
      modalConfig.callback = function (item) {
        onAddressInputChange(fieldName, item);
      };
      // scroll to start of the page
      window.scrollTo(0, 0);
      dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
    }
    else {
      let modalConfig = {
        ...CONSTANTS.MODALS_CONFIG.MESSAGE,
        buttons: { ok: { visible: true, text: 'SIGN IN' }, cancel: { visible: false, text: 'CANCEL' } },
        message: 'This feature is available for registered users, if you want to enjoy this feature and more please register for free. '
      };
      modalConfig.callback = function (res) {
        dispatch(modalQueue({ mode: 'delete' }));
        if (res.confirm) {
          navigateToPath('sign-in', true);
        }
      };
      dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
    }
  }

  function onSelectPackageFromList() {
    if (isUserLoggedIn(get(props, 'apiData_getInitialData', {}))) {
      // open modal on address list tab
      const modalConfig = JSON.parse(JSON.stringify(CONSTANTS.MODALS_CONFIG.MY_ACCOUNT));
      modalConfig.initialTab = CONSTANTS.MY_ACCOUNT_PACKAGES_LIST_TAB;
      modalConfig.packagesClickable = true;
      modalConfig.callback = function (item) {
        let packageType = convertPackageIdtoPackage(item.packageTypeId);
        if (item.packageTypeId === '1') {
          packageType = { ...packageType, dimensionsHeight: item.sizeHeight, dimensionsLength: item.sizeLength, dimensionsUnits: parseInt(item.sizeUnitId) === 1 ? "in" : "cm", dimensionsWidth: item.sizeWidth, selectionDisplayDimensions: item.sizeWidth + '" x ' + item.sizeHeight + '" x ' + item.sizeLength + '"' };
        }
        dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress', value: { ...props.purchaseProgress, firstPackage: { selectedPackage: packageType, weightUnits: (parseInt(item.weightUnitId) === 1 ? "lbs" : "kg"), weight: item.weight } } }));
      };
      // scroll to start of the page
      window.scrollTo(0, 0);
      dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
    }
    else {
      promptUserToSignIn(dispatch);
    }
  }

  function selectPackage() {
    let modalConfig = JSON.parse(JSON.stringify(CONSTANTS.MODALS_CONFIG.PACKAGE_TYPE_SELECT_PACKAGE));
    modalConfig.selectedTab = 4;
    modalConfig.selectedPackage = get(props, 'purchaseProgress.firstPackage');
    if (!get(props, 'apiData_getInitialData.result.success', false)) {
      modalConfig.disableAddToPackageList = true;
    }
    // TODO - REMOVE THIS
    //if (get(props, 'purchaseProgress.selectedCompany', '')) {
    // modalConfig.selectedCompany = get(props, 'purchaseProgress.selectedCompany', '');
    //}
    modalConfig.callback = function (selectedPackage, weight, packageName) {
      if (packageName && get(props, 'purchaseProgress.firstPackage.weight', null)) {
        let request = {
          name: packageName,
          content: '',
          packageTypeId: parseInt(selectedPackage.id || 0),
          weight: parseFloat(get(props, 'purchaseProgress.firstPackage.weight', 0)),
          weightUnitId: convertPackageWeightUnitTextToId(get(props, 'purchaseProgress.firstPackage.weightUnits', 'lbs')),
          sizeHeight: parseFloat(selectedPackage.dimensionsHeight),
          sizeWidth: parseFloat(selectedPackage.dimensionsWidth),
          sizeLength: parseFloat(selectedPackage.dimensionsLength),
          sizeUnitId: convertPackageSizeUnitTextToId(selectedPackage.dimensionsUnits),
          callback: function (result) {
            dispatch(setupAnimatedLoader({ isVisible: false }));
            if (!result.success) {
              showErrorMessage(result, dispatch);
              return;
            }
            dispatch(api_packages_getList());
          }
        };
        dispatch(setupAnimatedLoader({ isVisible: true, text: 'Adding package...' }));
        dispatch(api_packages_add(request));
      }
      let selectedCompany = '';
      try {
        if (selectedPackage.baseDescription.toLowerCase().indexOf('ups') !== -1 || selectedPackage.selectionDisplayTitle.toLowerCase().indexOf('ups') !== -1) {
          selectedCompany = 'ups';
        }
        if (selectedPackage.baseDescription.toLowerCase().indexOf('fedex') !== -1 || selectedPackage.selectionDisplayTitle.toLowerCase().indexOf('fedex') !== -1) {
          selectedCompany = 'fedex';
        }
      } catch (e) { }
      dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress', value: { ...props.purchaseProgress, selectedCompany: selectedCompany, firstPackage: { weightUnits: weight.unit, weight: weight.value, selectedPackage: selectedPackage } } }));
    };
    dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
  }

  function editStage1() {
    navigateToPath('', true);
    dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress', value: { ...props.purchaseProgress, stage: 1 } }));
  }

  function editPackage() {
    // clear selected value
    dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress', value: { ...props.purchaseProgress, selectedCompany: '', firstPackage: { ...props.purchaseProgress.firstPackage, selectedPackage: null } } }));
  }

  function changePackage(value) {
    dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress', value: { ...props.purchaseProgress, selectedCompany: '', firstPackage: { ...props.purchaseProgress.firstPackage, selectedPackage: value } } }));
  }

  function more() {

    let modalConfig = JSON.parse(JSON.stringify(CONSTANTS.MODALS_CONFIG.PACKAGES_MORE_OPTIONS));
    modalConfig.callback = function (result) {
      // TODO - handle result
    };
    dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
  }

  function getPrice() {
    if (inProgress) {
      return;
    }
    let currentState = store.getState();
    // verify all required data exists
    let checkErrors = {};
    const packages = get(currentState, 'data.ui.mainPage.purchaseProgress.morePackages', []).concat(props.purchaseProgress.firstPackage ? props.purchaseProgress.firstPackage : []);
    const senderPlaceId = get(currentState, 'data.ui.mainPage.purchaseProgress.senderPlaceId', false);
    const recipientPlaceId = get(currentState, 'data.ui.mainPage.purchaseProgress.recipientPlaceId', false);
    let senderAddressDetails = get(currentState, 'data.ui.mainPage.purchaseProgress.senderAddressDetails', false);
    let recipientAddressDetails = get(currentState, 'data.ui.mainPage.purchaseProgress.recipientAddressDetails', false);
    if (!senderAddressDetails) {
      senderAddressDetails = {
        googlePlaceId: senderPlaceId,
        addressDataVersion: 'google',
        countryCode: '',
        city: '',
        stateOrProvinceCode: '',
        postalCode: '',
        addressLines: []
      }
    }
    if (!recipientAddressDetails) {
      recipientAddressDetails = {
        googlePlaceId: recipientPlaceId,
        addressDataVersion: 'google',
        countryCode: '',
        city: '',
        stateOrProvinceCode: '',
        postalCode: '',
        addressLines: []
      }
    }

    if (showInsurance && !isValidSizeValue(packages[0].value)) {
      checkErrors.value = true;
    }
    if (showInsurance && !packages[0].content) {
      checkErrors.content = true;
    }

    if (!senderPlaceId || senderAddressError) {
      checkErrors.senderPlaceId = true;
    }
    if (!recipientPlaceId || recipientAddressError) {
      checkErrors.recipientPlaceId = true;
    }
    if (packages[0].selectedPackage.id == CONSTANTS.PACKAGE_TYPE_CUSTOM.id) {
      let dimensionsErrors = {};
      if (!isValidSizeValue(packages[0].selectedPackage.dimensionsWidth)) {
        dimensionsErrors.width = true;
      }
      if (!isValidSizeValue(packages[0].selectedPackage.dimensionsHeight)) {
        dimensionsErrors.height = true;
      }
      if (!isValidSizeValue(packages[0].selectedPackage.dimensionsLength)) {
        dimensionsErrors.length = true;
      }
      if (Object.keys(dimensionsErrors).length) {
        checkErrors.errors = dimensionsErrors;
      }
    }
    if (!isValidWeightValue(packages[0].weight, 0, get(packages, '[0].selectedPackage.weightRange[1]', '150'))) {
      checkErrors.weight = true;
    }
    setErrors(checkErrors);
    if (Object.keys(checkErrors).length || recipientServerError || senderServerError) {
      return;
    }
    recipientAddressDetails.addressTypeId = (residentialAddress ? CONSTANTS.ADDRESS_TYPE_ID_RESIDENTIAL : CONSTANTS.ADDRESS_TYPE_ID_BUSINESS);
    senderAddressDetails.addressTypeId = CONSTANTS.ADDRESS_TYPE_ID_BUSINESS;

    // setup request
    let request = {
      recipientAddressDetails,
      senderAddressDetails,
      shippingDropoffTypeId: CONSTANTS.SHIPPING_DROPOFF_TYPE_ID_DROPOFF, // default at this stage
      time: '', // empty on this stage
      date: '', // empty on this stage
      packages: buildPackages({ packages }), // create new objects
      recipientAddressTypeId: (residentialAddress ? CONSTANTS.ADDRESS_TYPE_ID_RESIDENTIAL : CONSTANTS.ADDRESS_TYPE_ID_BUSINESS),
      callback: function (result) {
        setInProgress(false);
        dispatch(setupAnimatedLoader({ isVisible: false }));
        if (result.success) {
          if (get(result, 'results', []).some(res => res.success)) {
            const filtered = { success: result.success, results: result.results.map(item => { return { ...item, options: item.options.filter(f => f.businessDays !== 4) } }) };
            let bestPriceResult = [];
            bestPriceResult = getBestPrice({ result: filtered });
            const bestPriceCompanyResult = get(bestPriceResult, 'company.company', 'FEDEX');
            const isGround = get(bestPriceResult, 'option.isGround', false);
            let bestPriceShippingRadioOption = (isGround ? 'ground' : (get(bestPriceResult, 'option.businessDays', '1') + '-business-days'));
            const text = get(bestPriceResult, 'option.text', '');
            const value = get(bestPriceResult, 'option.shippingTypeId', '1');
            const now = moment();
            navigateToPath('stage-2', true);
            logEventForAnalytics({
              'event': 'get-your-price'
            });
            dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress', value: { ...props.purchaseProgress, stage: 2, activeCompany: bestPriceCompanyResult, selectedShippingRadioOption: bestPriceShippingRadioOption, selectedShippingOption: { text: text, value: value, object: bestPriceResult.option }, pickupTime: now.format('HH:MM'), pickupDate: now.format('MM/DD/YYYY'), dropoffSavedRates: result } }));
          }
          else {
            let modalConfig = {
              ...CONSTANTS.MODALS_CONFIG.MESSAGE,
              message: "Couldn't find prices...",
              buttons: { ok: { visible: true, text: 'OK' }, cancel: { visible: false, text: 'CANCEL' } }
            };
            dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
          }
        }
        else {
          const resultCodeAsInt = parseInt(result.code);
          const recipientAddressErrorCode = parseInt(result.recipientAddressErrorCode);
          const senderAddressErrorCode = parseInt(result.senderAddressErrorCode);
          let checkErrors = {};
          if (recipientAddressErrorCode) {
            setRecipientServerError(true);
          }
          if (senderAddressErrorCode) {
            setSenderServerError(true);
          }
          setErrors(checkErrors);
          if (resultCodeAsInt > CONSTANTS.ADDRESS_ERROR_CODES_RANGE[0] && resultCodeAsInt < CONSTANTS.ADDRESS_ERROR_CODES_RANGE[1]) {
            logEventForAnalytics({
              'event': 'api-error-address',
              'code': result.code,
              'text': CONSTANTS.ERROR_MESSAGES[result.code] || CONSTANTS.DEFAULT_ERROR_MESSAGE,
              'sender_address': get(currentState, 'data.ui.mainPage.purchaseProgress.senderAddress', ''),
              'recipient_address': get(currentState, 'data.ui.mainPage.purchaseProgress.recipientAddress', ''),
            });
          } else {
            showErrorMessage(result, dispatch);
          }
        }
      }
    };

    setInProgress(true);
    dispatch(setupAnimatedLoader({ isVisible: true, text: 'Finding best price...' }));
    dispatch(api_getPrices(request));
  }

  ///////////////////////////////////////////////////////////////////
  ///////////////////////////// STAGE 2 /////////////////////////////
  ///////////////////////////////////////////////////////////////////

  function isCompanyAvailable(company) {
    return (getBestPrice(props.apiData_getPrices, { company: company }).company != null ? true : false);
  }

  function editStage2() {
    navigateToPath('stage-2', true);
    dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress', value: { ...props.purchaseProgress, stage: 2 } }));
  }

  function getShippingOptionsList(selectedShippingRadioOption) {
    let result = [];
    const optionsForSelectedCompany = get(get(props, 'apiData_getPrices.result.results', []).filter((item) => item.company === props.purchaseProgress.activeCompany), '[0].options', []);
    if (selectedShippingRadioOption) {
      // show relevant options only
      let optionsForSelectedShippingOption;
      if (selectedShippingRadioOption == 'ground') {
        optionsForSelectedShippingOption = optionsForSelectedCompany.filter((item) => item.isGround === true);
      }
      else {
        const businessDaysOptionsTranslaction = {
          '1-business-days': '1',
          '2-business-days': '2',
          '3-business-days': '3'
        };
        optionsForSelectedShippingOption = optionsForSelectedCompany.filter((item) => item.isGround === false && (item.businessDays == businessDaysOptionsTranslaction[selectedShippingRadioOption] || (parseInt(businessDaysOptionsTranslaction[selectedShippingRadioOption]) == CONSTANTS.MAX_BUSINESS_DAYS && item.businessDays > CONSTANTS.MAX_BUSINESS_DAYS)));
      }
      optionsForSelectedShippingOption = optionsForSelectedShippingOption.map(function (item) {
        return { text: item.text + ' - $' + item.price, value: String(item.shippingTypeId), object: item };
      });
      result = result.concat(optionsForSelectedShippingOption);
    }
    else {
      // show all shipment options
      result = result.concat(optionsForSelectedCompany.map(function (item) {
        return { text: item.text + ' - $' + item.price, value: String(item.shippingTypeId), object: item };
      }));
    }
    if (result.length === 0) {
      result = [{ text: DEFAULT_SELECTED_SHIPPING_OPTION_LIST, value: '' }];
    }
    return result;
  }

  function isHavingOption(company, option) {
    if (getBestPrice(props.apiData_getPrices, { company: company, shippingOption: option }).company) {
      return true;
    }
    return false;
  }

  function isHavingMoreThanMax() {
    const optionsForSelectedCompany = get(get(props, 'apiData_getPrices.result.results', []).filter((item) => item.company === props.purchaseProgress.activeCompany), '[0].options', []);
    for (const optionItem of optionsForSelectedCompany) {
      if (optionItem.businessDays > CONSTANTS.MAX_BUSINESS_DAYS) {
        return true;
      }
    }
    return false;
  }

  function shipNow() {
    const defaultPayment = get(props, 'paymentSources_getList.result.results', []).find(c => c.selected);
    setUseExistingPayment(defaultPayment ? true : false);
    setSelectedCreditCard(defaultPayment);
    let currentState = store.getState();
    let shippingCompanyId = get(currentState, 'data.ui.mainPage.purchaseProgress.activeCompany', '');
    if (shippingCompanyId === "FEDEX" && !window.debugFedEx) {
      showErrorMessage({ success: false, code: 122 }, dispatch);
      return;
    }
    if (get(props, 'purchaseProgress.selectedDropoffType.value', '') == CONSTANTS.SHIPPING_DROPOFF_TYPE_ID_COURIER) {
      // pickup - open choose pickup date/time modal
      const modalConfig = JSON.parse(JSON.stringify(CONSTANTS.MODALS_CONFIG.CHOOSE_PICK_UP_TIME));
      modalConfig.passedTabIndex = 0;
      modalConfig.callback = function (result) {
        /* result structure: { data: 'moment object', time: 'string in format HH:mm' } */
        navigateToPath('stage-3', true);
        dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress', value: { ...props.purchaseProgress, pickupTime: result.time.value, pickupDate: result.date.format('MM/DD/YYYY'), stage: 3 } }));
      };
      dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
    }
    else {
      navigateToPath('stage-3', true);
      dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress', value: { ...props.purchaseProgress, stage: 3 } }));
    }
    logEventForAnalytics({
      'event': 'ship-now',
      'company': get(props, 'purchaseProgress.activeCompany', ''),
      'dropOffType': get(props, 'purchaseProgress.selectedDropoffType.value', '1') === '1' ? 'drop-off' : 'pickup',
      'shippingOption': get(props, 'purchaseProgress.selectedShippingOption.text', ''),
      'price': get(getBestPriceForFullSelection, 'option.price', '0')
    });
  }
  function getBillingDetails() {
    dispatch(modalQueue({ mode: 'insert', modalConfig: CONSTANTS.MODALS_CONFIG.CARDHOLDER_DETAILS }));
  }
  function confirm() {
    if (inProgress || !agreedTerms) {
      return;
    }
    let currentState = store.getState();
    // verify all required data exists
    let checkErrors = {};
    const packages = get(currentState, 'data.ui.mainPage.purchaseProgress.morePackages', []).concat(props.purchaseProgress.firstPackage ? props.purchaseProgress.firstPackage : []);
    const senderPlaceId = get(currentState, 'data.ui.mainPage.purchaseProgress.senderPlaceId', '');
    const recipientPlaceId = get(currentState, 'data.ui.mainPage.purchaseProgress.recipientPlaceId', '');
    const shippingDropoffTypeId = get(currentState, 'data.ui.mainPage.purchaseProgress.selectedDropoffType.value', '0');
    const shippingTypeId = get(currentState, 'data.ui.mainPage.purchaseProgress.selectedShippingOption.value', '0');
    const time = get(currentState, 'data.ui.mainPage.purchaseProgress.pickupTime', '');
    let date = get(currentState, 'data.ui.mainPage.purchaseProgress.pickupDate', '');
    const recipientPhone = get(currentState, 'data.ui.mainPage.purchaseProgress.recipient.phone', '');
    const recipientName = get(currentState, 'data.ui.mainPage.purchaseProgress.recipient.name', '');
    const recipientCompany = get(currentState, 'data.ui.mainPage.purchaseProgress.recipient.company', '');
    const senderPhone = get(currentState, 'data.ui.mainPage.purchaseProgress.shipper.phone', '');
    const senderName = get(currentState, 'data.ui.mainPage.purchaseProgress.shipper.name', '');
    const senderCompany = get(currentState, 'data.ui.mainPage.purchaseProgress.shipper.company', '');
    const senderEmail = get(currentState, 'data.ui.mainPage.purchaseProgress.shipper.email', '');
    const recipientEmail = get(currentState, 'data.ui.mainPage.purchaseProgress.recipient.email', '');
    const senderAddressLine2 = get(currentState, 'data.ui.mainPage.purchaseProgress.shipper.addressLine2', '');
    const recipientAddressLine2 = get(currentState, 'data.ui.mainPage.purchaseProgress.recipient.addressLine2', '');
    const couponCode = get(currentState, 'data.ui.mainPage.purchaseProgress.coupon.code');
    let shippingCompanyId = get(currentState, 'data.ui.mainPage.purchaseProgress.activeCompany', '');
    const differentBillingDetails = get(currentState, 'data.ui.mainPage.purchaseProgress.payment.differentBillingDetails', '');
    const cardNumber = get(currentState, 'data.ui.mainPage.purchaseProgress.payment.creditCard.cardNumber', '');
    const cvv = get(currentState, 'data.ui.mainPage.purchaseProgress.payment.creditCard.cvv', '');
    let expiry = get(currentState, 'data.ui.mainPage.purchaseProgress.payment.creditCard.expiry', '');
    let existingCardNumber = get(selectedCreditCard, 'last4Digits', '');
    let existingCardType = get(selectedCreditCard, 'cardType', '');
    let senderAddressDetails = get(currentState, 'data.ui.mainPage.purchaseProgress.senderAddressDetails', false);
    let recipientAddressDetails = get(currentState, 'data.ui.mainPage.purchaseProgress.recipientAddressDetails', false);
    if (!senderAddressDetails) {
      senderAddressDetails = {
        googlePlaceId: senderPlaceId,
        addressDataVersion: 'google',
        countryCode: '',
        city: '',
        stateOrProvinceCode: '',
        postalCode: '',
        addressLines: senderAddressLine2 ? [senderAddressLine2] : []
      }
    }
    if (!recipientAddressDetails) {
      recipientAddressDetails = {
        googlePlaceId: recipientPlaceId,
        addressDataVersion: 'google',
        countryCode: '',
        city: '',
        stateOrProvinceCode: '',
        postalCode: '',
        addressLines: recipientAddressLine2 ? [recipientAddressLine2] : []
      }
    }

    if (!senderName) {
      checkErrors.senderName = true;
    } else {
      checkErrors.senderName = false;
    }
    if (!senderPhone || !CONSTANTS.VALID_PHONE_REGEX.test(String(senderPhone).toLowerCase())) {
      checkErrors.senderPhone = true;
    } else {
      checkErrors.senderPhone = false;
    }
    if (!senderEmail || !CONSTANTS.VALID_EMAIL_REGEX.test(String(senderEmail).toLowerCase())) {
      checkErrors.senderEmail = true;
    } else {
      checkErrors.senderEmail = false;
    }

    if (!recipientName) {
      checkErrors.recipientName = true;
    } else {
      checkErrors.recipientName = false;
    }
    if (!recipientPhone || !CONSTANTS.VALID_PHONE_REGEX.test(String(recipientPhone).toLowerCase())) {
      checkErrors.recipientPhone = true;
    } else {
      checkErrors.recipientPhone = false;
    }
    if (recipientEmail && !CONSTANTS.VALID_EMAIL_REGEX.test(String(recipientEmail).toLowerCase())) {
      checkErrors.recipientEmail = true;
    } else {
      checkErrors.recipientEmail = false;
    }

    if (!cardNumber && !useExistingPayment) {
      checkErrors.cardNumber = true;
    } else {
      checkErrors.cardNumber = false;
    }
    if ((!cvv || isNaN(cvv)) && !useExistingPayment) {
      checkErrors.cvv = true;
    } else {
      checkErrors.cvv = false;
    }
    if ((!expiry || expiry.length !== 5) && !useExistingPayment) {
      checkErrors.expiry = true;
    } else {
      expiry = expiry.split('/');
      checkErrors.expiry = false;
    }
    let billingName = '';
    let billingEmail = '';
    let billingZip = '';
    let cardEncrypted = { encyprtedCreditCard: '', encyprtedCvv: '' };

    switch (shippingCompanyId) {
      case 'FEDEX':
        shippingCompanyId = 1;
        break;
      case 'UPS':
        shippingCompanyId = 2;
        break;
      default:
        shippingCompanyId = 0;
    }

    if (cvv && cardNumber) {
      cardEncrypted = getBluesnapEncryptedFields(cvv, cardNumber);
    }

    if (differentBillingDetails) {
      billingName = get(currentState, 'data.ui.mainPage.purchaseProgress.payment.billingDetails.fullName', '');
      billingEmail = get(currentState, 'data.ui.mainPage.purchaseProgress.payment.billingDetails.email', '');
      billingZip = get(currentState, 'data.ui.mainPage.purchaseProgress.payment.billingDetails.zipCode', '');
    } else {
      billingName = senderName;
      billingEmail = senderEmail;
    }
    billingName = billingName.split(' ');

    if (date.length) {
      let splitted = date.split('/');
      date = splitted[2] + '-' + splitted[0] + '-' + splitted[1];
    }
    if (!senderPlaceId) {
      checkErrors.recipientPlaceId = true;
    }
    if (!recipientPlaceId) {
      checkErrors.senderPlaceId = true;
    }
    if (!packages[0].selectedPackage) {
      checkErrors.packages = true;
    }
    if (packages[0].weight === '0' || isNaN(packages[0].weight)) {
      checkErrors.weight = true;
    }
    setErrors(checkErrors);
    if (Object.entries(checkErrors).some(entry => entry[1])) {
      return;
    }
    recipientAddressDetails.addressTypeId = get(currentState, 'data.api.getPrices.result.recipientAddressTypeId', CONSTANTS.ADDRESS_TYPE_ID_BUSINESS) === CONSTANTS.ADDRESS_TYPE_ID_BUSINESS ? CONSTANTS.ADDRESS_TYPE_ID_BUSINESS : CONSTANTS.ADDRESS_TYPE_ID_RESIDENTIAL;
    senderAddressDetails.addressTypeId = get(currentState, 'data.api.getPrices.result.senderAddressTypeId ', CONSTANTS.ADDRESS_TYPE_ID_BUSINESS) === CONSTANTS.ADDRESS_TYPE_ID_BUSINESS ? CONSTANTS.ADDRESS_TYPE_ID_BUSINESS : CONSTANTS.ADDRESS_TYPE_ID_RESIDENTIAL;
    if (recipientAddressLine2) {
      recipientAddressDetails.addressLines.push(recipientAddressLine2)
    }
    if (senderAddressLine2) {
      senderAddressDetails.addressLines.push(senderAddressLine2)
    }
    // setup request
    let request = {
      couponCode,
      shippingDetails: {
        recipientAddressDetails,
        senderAddressDetails,
        recipientCompany: recipientCompany,
        recipientName: recipientName,
        recipientPhone: recipientPhone,
        recipientEmail: recipientEmail,
        senderCompany: senderCompany,
        senderName: senderName,
        senderPhone: senderPhone,
        senderEmail: senderEmail,
        senderNotification: sendEmailNotifications,
        recipientNotification: sendEmailNotifications,
        shippingCompanyId: shippingCompanyId,
        shippingDropoffTypeId: parseInt(shippingDropoffTypeId),
        shippingTypeId: parseInt(shippingTypeId),
        time: time,
        date: date,
        packages: buildPackages({ packages })
      },
      paymentDetails: {
        creditCardExpiryMonth: expiry.length > 0 ? expiry[0] : '',
        creditCardExpiryYear: '20' + (expiry.length > 1 ? expiry[1] : ''),
        creditCardNumber: cardEncrypted.encyprtedCreditCard,
        creditCardSecurityCode: cardEncrypted.encyprtedCvv,
        email: billingEmail,
        existingPaymentCardType: existingCardType,
        existingPaymentLast4Digits: existingCardNumber,
        firstName: billingName.length > 0 ? billingName[0] : '',
        lastName: billingName.length > 1 ? billingName[1] : '',
        paymentType: useExistingPayment ? CONSTANTS.PAYMENT_TYPE_ID_SAVED : CONSTANTS.PAYMENT_TYPE_ID_NEW,
        zip: billingZip
      },
      callback: function (result) {
        if (result.success) {
          let request = {
            transactionKey: result.transactionKey,
            callback: function (result) {
              setInProgress(false);
              dispatch(setupAnimatedLoader({ isVisible: false }));
              if (result.success) {
                logEventForAnalytics({
                  'event': 'confirm',
                  'price': get(getBestPriceForFullSelection, 'option.price', '0')
                });
                dispatch(modalQueue({ mode: 'insert', modalConfig: { ...CONSTANTS.MODALS_CONFIG.THANK_YOU, hasInsurance: hasInsurance() } }));
              }
              else {
                showErrorMessage(result, dispatch);
              }
            }
          };
          dispatch(setupAnimatedLoader({ isVisible: true, text: 'Paying...' }));
          dispatch(api_pay(request));
        }
        else {
          setInProgress(false);
          dispatch(setupAnimatedLoader({ isVisible: false }));
          showErrorMessage(result, dispatch);
        }
      }
    };
    setInProgress(true);
    dispatch(setupAnimatedLoader({ isVisible: true, text: 'Verifying payment...' }));
    dispatch(api_verifyPayment(request));
  }
  function addPackage(addNew) {
    const modalConfig = JSON.parse(JSON.stringify(CONSTANTS.MODALS_CONFIG.ADD_EDIT_PACKAGE));
    modalConfig.passedTabIndex = 0;
    modalConfig.addNew = addNew;
    modalConfig.callback = function (result) {
      //  dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress', value: { ...props.purchaseProgress, stage: 1 } }));
    };
    dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
  }

  function cleanSelectedPackage() {
    if (props.purchaseProgress.morePackages && props.purchaseProgress.morePackages.length > 0) {
      addPackage(false);
    }
    else {
      dispatch(genericSetValue({
        path: 'ui.mainPage.purchaseProgress', value: {
          ...props.purchaseProgress,
          selectedCompany: '',
          firstPackage: {
            weightUnits: 'lbs',
            weight: '',
            selectedPackage: JSON.parse(JSON.stringify(CONSTANTS.PACKAGE_TYPE_CUSTOM))
          }
        }
      }));
    }
  }


  function onCompanyClick(value) {
    const bestPrice = getBestPrice(get(props, 'apiData_getPrices', []), { company: value });
    const isGround = get(bestPrice, 'option.isGround', false);
    let bestPriceShippingRadioOption = null;
    if (isGround) {
      bestPriceShippingRadioOption = 'ground';
    } else {
      const businessDays = get(bestPrice, 'option.businessDays', '1');
      bestPriceShippingRadioOption = businessDays + '-business-days';
    }
    const text = get(bestPrice, 'option.text', '');
    const shippingValue = get(bestPrice, 'option.shippingTypeId', '1');
    //setStagesData({ ...stagesData, [2]: { ...stagesData[2], activeCompany: value, selectedShippingOptionRadio: null, selectedShippingOptionList: DEFAULT_SELECTED_SHIPPING_OPTION_LIST } });
    dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress', value: { ...props.purchaseProgress, activeCompany: value, selectedShippingOption: { text: text, value: shippingValue }, selectedShippingRadioOption: bestPriceShippingRadioOption } }));
  }
  function differentBillingDetails(checked) {
    if (!checked) {
      getBillingDetails();
    } else {
      dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress', value: { ...props.purchaseProgress, payment: { ...props.purchaseProgress.payment, differentBillingDetails: false } } }));
    }
  }
  function hasInsurance() {
    return get(getBestPriceForFullSelection, 'option.withoutInsurance', false) && (get(getBestPriceForFullSelection, 'option.price', '0') !== get(getBestPriceForFullSelection, 'option.withoutInsurance', '0'));
  }
  function onSelectShippingOptionRadio(value) {
    dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress', value: { ...props.purchaseProgress, selectedShippingRadioOption: value, selectedShippingOption: getShippingOptionsList(value).reduce((x, y) => get(x, 'object.price', 0) < get(y, 'object.price', 0) ? x : y) } }));
    //setStagesData({ ...stagesData, [2]: { ...stagesData[2], selectedShippingOptionRadio: value, selectedShippingOptionList: DEFAULT_SELECTED_SHIPPING_OPTION_LIST } });
  }
  function onSelectShippingOptionList(value) {
    dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress', value: { ...props.purchaseProgress, selectedShippingOption: value } }));
  }
  function onSelectDropoffType(value) {
    dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress', value: { ...props.purchaseProgress, selectedDropoffType: value } }));
    if (String(value.value) === String(CONSTANTS.SHIPPING_DROPOFF_TYPE_ID_DROPOFF)) {
      // on change to dropoff - restore old prices
      dispatch(genericSetValue({ path: 'api.getPrices.result', value: get(props, 'purchaseProgress.dropoffSavedRates', null) }));
    }
  }
  function getStage3ShippingOption() {
    return (<><span className='shipping-option-label'>{props.purchaseProgress.activeCompany == 'FEDEX' ? 'FedEx,' : 'UPS,'}</span><span className="shipping-option-text">{get(props, 'purchaseProgress.selectedShippingOption.text')}</span></>);
  }
  function getStage3ShippingCompany() {
    return <div className='shipping-option-container'>{props.purchaseProgress.activeCompany == 'FEDEX' ? <IMAGES.fedex className="company-fedex" /> : <IMAGES.ups className="company-ups" />}</div>
  }
  function getStage3SendingOption() {
    return get(props, 'purchaseProgress.selectedDropoffType.text', '') + (get(props, 'purchaseProgress.selectedDropoffType.value', '') == CONSTANTS.SHIPPING_DROPOFF_TYPE_ID_COURIER ? ' on ' + get(props, 'purchaseProgress.pickupDate', '') + ' at ' + get(props, 'purchaseProgress.pickupTime', '') : '');
  }

  function onStage3DetailsUpdate(parentOfField, field, value) {
    if (field === 'cvv' && value.length > 4) {
      return;
    }
    dispatch(genericSetValue({ path: 'ui.mainPage.purchaseProgress.' + parentOfField, value: { ...get(props.purchaseProgress, parentOfField), [field]: value } }));
  }

  function canSaveAddress(person) {
    return get(props, 'purchaseProgress.' + person + '.name', '') !== '' &&
      get(props, 'purchaseProgress.' + person + '.email', '') !== '' &&
      get(props, 'purchaseProgress.' + person + '.phone', '') !== '' &&
      CONSTANTS.VALID_EMAIL_REGEX.test(String(get(props, 'purchaseProgress.' + person + '.email', '')).toLowerCase()) &&
      CONSTANTS.VALID_PHONE_REGEX.test(String(get(props, 'purchaseProgress.' + person + '.phone', '')).toLowerCase())
  }

  function canSaveCard(person) {
    return get(props, 'purchaseProgress.payment.creditCard.cardNumber', '') !== '' &&
      get(props, 'purchaseProgress.payment.creditCard.cvv', '') !== '' &&
      get(props, 'purchaseProgress.payment.creditCard.expiry', '') !== ''
  }

  function saveCard(e) {
    if (!isUserLoggedIn(get(props, 'apiData_getInitialData', {}))) {
      promptUserToSignIn(dispatch);
      return;
    }
    let currentState = store.getState();
    let cardNumber = get(props, 'purchaseProgress.payment.creditCard.cardNumber', '');
    let cvv = get(props, 'purchaseProgress.payment.creditCard.cvv', '');
    let expiry = get(props, 'purchaseProgress.payment.creditCard.expiry', '');
    const senderEmail = get(currentState, 'data.ui.mainPage.purchaseProgress.shipper.email', '');
    const senderName = get(currentState, 'data.ui.mainPage.purchaseProgress.shipper.name', '');
    const differentBillingDetails = get(currentState, 'data.ui.mainPage.purchaseProgress.payment.differentBillingDetails', '');
    let billingName = '';
    let billingEmail = '';
    let billingZip = '';
    if (differentBillingDetails) {
      billingName = get(currentState, 'data.ui.mainPage.purchaseProgress.payment.billingDetails.fullName', '');
      billingEmail = get(currentState, 'data.ui.mainPage.purchaseProgress.payment.billingDetails.email', '');
      billingZip = get(currentState, 'data.ui.mainPage.purchaseProgress.payment.billingDetails.zipCode', '');
    } else {
      billingName = senderName;
      billingEmail = senderEmail;
    }
    billingName = billingName.split(' ');
    let cardEncrypted = { encyprtedCreditCard: '', encyprtedCvv: '' };
    if (cvv && cardNumber) {
      cardEncrypted = getBluesnapEncryptedFields(cvv, cardNumber);
    }
    if ((!expiry || expiry.length !== 5) && !useExistingPayment) {
      setErrors({ ...errors, expiry: true });
      return;
    } else {
      expiry = expiry.split('/');
      delete errors.expiry;
      setErrors({ ...errors });
    }
    let request = {
      creditCardExpiryMonth: expiry.length > 0 ? expiry[0] : '',
      creditCardExpiryYear: '20' + (expiry.length > 1 ? expiry[1] : ''),
      creditCardNumber: cardEncrypted.encyprtedCreditCard,
      creditCardSecurityCode: cardEncrypted.encyprtedCvv,
      email: billingEmail,
      firstName: billingName.length > 0 ? billingName[0] : '',
      lastName: billingName.length > 1 ? billingName[1] : '',
      zip: billingZip,
      callback: function (result) {
        setInProgress(false);
        dispatch(setupAnimatedLoader({ isVisible: false }));
        if (result.success) {
          dispatch(api_creditcard_getList());
        }
        else {
          showErrorMessage(result, dispatch);
        }
      }
    }
    let modalConfig = {
      ...CONSTANTS.MODALS_CONFIG.MESSAGE,
      buttons: { ok: { visible: true, text: 'OK' }, cancel: { visible: true, text: 'CANCEL' } },
      message: 'Credit card will be saved'
    };
    modalConfig.callback = function (res) {
      if (res.confirm) {
        setInProgress(true);
        dispatch(setupAnimatedLoader({ isVisible: true, text: 'Saving Credit Card...' }));
        dispatch(api_creditcard_addCreditCard(request));
      }
    };
    dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
  }

  function saveAddress(person) {
    if (!isUserLoggedIn(get(props, 'apiData_getInitialData', {}))) {
      promptUserToSignIn(dispatch);
      return;
    }
    const name = get(props, 'purchaseProgress.' + person + '.name', '');
    const email = get(props, 'purchaseProgress.' + person + '.email', '');
    const phone = get(props, 'purchaseProgress.' + person + '.phone', '');
    const company = get(props, 'purchaseProgress.' + person + '.company', '');
    let place = '';
    if (person === 'shipper') {
      place = get(props, 'purchaseProgress.senderPlaceId', '');
    } else if (person === 'recipient') {
      place = get(props, 'purchaseProgress.recipientPlaceId', '');
    }
    let request = {
      company: company,
      email: email,
      fullname: name,
      googlePlaceId: place,
      phone: phone,
      callback: function (result) {
        setInProgress(false);
        dispatch(setupAnimatedLoader({ isVisible: false }));
        if (result.success) {
          dispatch(api_addressBook_getList());
        }
        else {
          showErrorMessage(result, dispatch);
        }
      }
    }
    let modalConfig = {
      ...CONSTANTS.MODALS_CONFIG.MESSAGE,
      buttons: { ok: { visible: true, text: 'OK' }, cancel: { visible: true, text: 'CANCEL' } },
      message: 'Details will be added to the address book'
    };
    modalConfig.callback = function (res) {
      if (res.confirm) {
        setInProgress(true);
        dispatch(setupAnimatedLoader({ isVisible: true, text: 'Saving Address...' }));
        dispatch(api_addressBook_add(request));
      }
    };
    dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
  }
  function formatCardDate(input) {
    if (input.length < 3 && isNaN(input)) {
      return '';
    } else if (input.length == 2 && !isNaN(input)) {
      return input + '/';
    } else if (input.length > 3 && isNaN(input.substring(3))) {
      return input.substring(0, 3);
    } else if (input.length > 5) {
      return input.substring(0, 5);
    } else {
      return input;
    }
  }

  function sumPackagesWeight() {
    const packages = get(props, 'purchaseProgress.morePackages', []).concat(get(props, 'purchaseProgress.firstPackage', []));
    return packages.map(p => parseFloat(get(p, 'weight', 0))).reduce((a, b) => a + b);
  }
  function sumPackagesValue() {
    const packages = get(props, 'purchaseProgress.morePackages', []).concat(get(props, 'purchaseProgress.firstPackage', []));
    return packages.map(p => parseFloat(get(p, 'value', 0))).reduce((a, b) => a + b);
  }

  function onSelectCardFromList(item) {
    if (isUserLoggedIn(get(props, 'apiData_getInitialData', {}))) {
      // open modal on address list tab
      const modalConfig = JSON.parse(JSON.stringify(CONSTANTS.MODALS_CONFIG.MY_ACCOUNT));
      modalConfig.initialTab = CONSTANTS.MY_ACCOUNT_CREDIT_CARDS_TAB;
      modalConfig.cardClickable = true;
      modalConfig.callback = function (item) {
        setSelectedCreditCard(item);
        setUseExistingPayment(true);
      };
      // scroll to start of the page
      window.scrollTo(0, 0);
      dispatch(modalQueue({ mode: 'insert', modalConfig: modalConfig }));
    } else {
      promptUserToSignIn(dispatch);
    }
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    activeModalQueue: state.data.activeModalQueue,
    purchaseProgress: state.data.ui.mainPage.purchaseProgress,
    weight: state.data.ui.mainPage.purchaseProgress.firstPackage.weight,
    weightUnits: state.data.ui.mainPage.purchaseProgress.firstPackage.weightUnits,
    firstPackage: state.data.ui.mainPage.purchaseProgress.firstPackage.selectedPackage,
    stage3Shipper: state.data.ui.mainPage.purchaseProgress.shipper, // connected for refresh
    stage3Recipient: state.data.ui.mainPage.purchaseProgress.recipient, // connected for refresh
    stage3Payment: state.data.ui.mainPage.purchaseProgress.payment, // connected for refresh
    stage3PaymentCreditCard: state.data.ui.mainPage.purchaseProgress.payment.creditCard, // connected for refresh
    stage3PaymentPaypal: state.data.ui.mainPage.purchaseProgress.payment.paypal, // connected for refresh
    apiData_getPrices: state.data.api.getPrices,
    apiData_verifyPayment: state.data.api.verifyPayment,
    paymentSources_getList: state.data.api.paymentSources_getList,
    differentBillingDetails: state.data.ui.mainPage.purchaseProgress.payment.differentBillingDetails,
    mobile: state.data.displayMode === 'MOBILE',
    apiData_getInitialData: state.data.api.getInitialData
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

const MainPagePurchaseProgress = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainPagePurchaseProgressComponent);

const MainPagePurchaseProgressStyle = styled.div`
  .coupon-container{
    margin:20px 0;
  }
  .best-origin-price-price{
    text-decoration: line-through;
    color:#B0BCC2;
    &.mobile{
      font-size:14px;
    }
  }
  .show-more{
    background: none;
    border: none;
    outline: none;
    margin: auto;
    display: block;
    width: 100px;
    &.open{
      transform: rotate(180deg);
    }
    svg{
      width: 13px;
      height: 13px;
    }
  }
  .add-package-button ,.add-insurance {
    &.hide{
      visibility: hidden;
    }
    font-size: 16px;
    height: 56px;
    background-color: transparent;
    border: none;
    color: ${Colors.blue1};
    svg {
      padding-right: 10px;
      path{
          fill:${Colors.blue1};
        }
    }
  }
  .add-insurance{
    margin-right: 56px;
  }
  .space-between-div{
    width: 7%;
  }
  .hidden {
    visibility: hidden;
  }
  .error {
    border: solid 1px ${Colors.error};
  }
  .header-text {
    text-align: center;
    font-size: 26px;
    margin: 20px;
  }
  .bottom-place-hloder {
    height: 240px;
  }
  .seperate-line{
    margin: auto;
    width: 997px;
    background-color: ${Colors.lightBlue};
    .line{
      margin: 0 3% 0 3%;
      border: solid 0px ${Colors.lightGray5};
      border-width: 1px 0px 0px 0px;
    }
  }
  .bottom-banner{
    width: 100%;
    position: fixed;
    background: white;
    z-index: 1;
    bottom: 0;
    text-align: center;
    .agree-container{
      font-size:12px;
      .agree-checkbox{
        width:27px;
      }
      .agree-link{
          text-decoration:none;
          color:${Colors.blue1};
          line-height: 42px;
          margin-left: 4px;
          margin-top: 1px;
      }
      .agree-text{
          margin-left:10px;
          color:${Colors.black};
          line-height: 42px;
          margin-top: 1px;
      }
    }
  }
  .completed-container {
    width: 100%;
    border: none;
    outline: none;
    cursor: pointer;
    padding-top: 17px;
    padding-left: 16px;
    padding-bottom: 17px;
    background-color: ${Colors.white};
    color: ${Colors.lightGray2};
    border-radius: 3px;
    position: relative;
    justify-content: flex-start;
    margin-bottom: 17px;
    &.stage3-completed-1{
      border-radius: 3px 3px 0 0;
      width: 997px;
    }
    &.stage3-completed-2{
      padding-bottom: 12px;
      margin-top: 0;
      border-radius: 0 0 3px 3px;
      width: 997px;
    }
    .from-label ,.to-label, .packages-label, .sending-option-label, .shipping-option-label{
      color: ${Colors.lightGray2};
      font-size: 14px;
      font-family: museo_sans500;
      margin-right: 6px;
      white-space: nowrap;
    }
    .from-text, .to-text, .sending-option-text, .shipping-option-text-container{
      color: ${Colors.lightGray2};
      font-family: museo_sans300;
      font-size: 13px;
      margin-top: 3px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-right:37px;
    }
    .shipping-option-text-container {
      svg {
        &.company-fedex {
          height: 16px;
        }
        &.company-ups {
          height: 20px;
        }
        path {
          fill: ${Colors.lightGray6};
        }
      }
      .shipping-option-text {
        vertical-align: top;
      }
    }

    .packages-text{
      color: ${Colors.lightGray2};
      font-family: museo_sans300;
      font-size: 13px;
      margin-top: 3px;
      white-space: nowrap;
      overflow: hidden;
      margin-right:50px;
    }
    .action-icon-container{
      right: 18px;
      top: 18px;
      position: absolute;
      BUTTON{
        background-color:rgba(0,0,0,0);
      }
    }
  }
  .panel-container {
    padding-bottom: 41px;
    margin: 20px 60px 0 60px;
    background-color: ${Colors.lightBlue};
    border-radius: 3px;
    /*width: 960px;
    margin: auto;
    margin-top: 20px;*/
  }
  .add-container:hover{
    background-color:#ededed;
  }
  .add-container {
    &.hidden{
      visibility: hidden;
    }
    &.more{
      height: 46px;
      font-size: 15px;
      &.selected-type{
        max-width: 100px;
      }
    }
    &.icon{
      padding: 10px 17px 8px 19px;
      margin-right: 0;
      height: 48px;
      svg{
        width: 21px;
        height: 21px;
      }
      &.pack-list{
        svg{
          width: 28px;
          height: 28px;
        } 
      }
    }
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${Colors.blue1};
    background-color: rgba(0,0,0,0);
    border: none;
    outline: none;
    cursor: pointer;
    height: 56px;
    padding: 0;
    svg {
      width: 17px;
      height: 17px;
      path {
        fill: ${Colors.blue1};
      }
    }
    SPAN {
      position: relative;
      top: -4px;
      padding-left: 5px;
      font-size: 14px;
    }
  }
  .stage1-panel-container {
    padding-top: 19px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom:10px;
    .address-message{
      color: #70777d;
      font-size: 14px;
      font-family: museo_sans300;
      margin-top: 10px;
    }
    .address-checkbox{
      width: 260px;
      margin:auto;
      .label  {
        color:#929292 ;
      }
    }
    
    .more-button {
      visibility: hidden;
      width: 218px;
      font-size: 16px;
      background-color: transparent;
      border: none;
      color: ${Colors.blue1};
      svg {
        path {
          fill: ${Colors.blue1};
        }
      }
    }
    .flex-divided-3-cols {
      height: 134px;
      flex: 0 1 33.333%;
      &.strech-two {
        flex: 0 1 48%;
      }
      .error-message{
        margin-top: 7px;
        margin-left: 11px;
        font-size: 14px;
        color: ${Colors.error};
        display: block;
        color: ${Colors.error};
        visibility: hidden;
        font-family: 'museo_sans300';
        &.error-text{
          visibility: visible;
        }
      }
      .panel-with-background {
        height: 134px;
        background-color: ${Colors.white};
        &.with-right-margin {
          margin-right: 6px;
        }
        &.package-container{
          /*min-width: 276px;*/
        }
        
        .input-package-type {
          &.more-packages{
            margin-left:12px;
            margin-right:12px;
          }
          justify-content: space-around;
          flex: 0 0 74%;
          .box-radio-icon {
            top: -6px;
          }
          .box-icon {
            top: -2px;
          }
          .box-label, .envelope-label {
            white-space: nowrap;
          }
        }
        
         
      }
      .container-80{
        flex: 0 0 80%;
      }
      .sub-title{
          color: ${Colors.lightGray1};
          font-size: 12px;
          margin-top: 17px;
          margin-left: 5px;
        }
      .panel-item-title-container {
        white-space: nowrap;
        color: ${Colors.lightGray2};
        padding: 12px 0 18px 12px;
      }
      .input-address-from-component {
        margin-left: 12px;
        margin-right: 12px;
      }
      .input-address-to-component {
        margin-left: 12px;
        margin-right: 12px;
      }
      .package-info-container{
        margin: 0 4%;
        justify-content: space-between;
      }
      .input-field {
        flex: 0 0 23%;
      }
    }
    .second-row-controls-container {
      padding-top: 33px;
    }
    .insurance-container {
      background-color: white;
      margin-top: 16px;
      padding: 12px;
      .value-input{
        flex: 0 0 73%;
      }
      .content-input{
        flex: 0 0 65.5%;
      }
      .cancel-insurance{
        color: ${Colors.blue1};
        span{
          margin-left: 5px;
        }
        svg{
          path{
            fill: ${Colors.blue1};
          }
        }
      }
      .flex-width {
        flex: 0 0 31.6%;
      }
    }
  }
  .stage2-panel-container {
    padding:17px;
    padding-bottom:40px;
    .company-button{
      padding: 6px 0;
      svg {
        path {
          fill: ${Colors.lightGray4};
        }
        &.active {
          path {
            fill: ${Colors.blue1};
          }
        }
      }
        &.active{
          border-bottom: solid 1.5px ${Colors.blue1};
        }
      border-radius:unset;
      flex:0 0 50%;
      border-bottom: solid 1.5px ${Colors.lightGray3};
      &.left-border{
        border-left: solid 0.75px ${Colors.lightGray3};
      }
      &.right-border{
        border-right: solid 0.75px ${Colors.lightGray3};
      }
    }
    .select-shipping-time{
      margin:20px;
      margin-bottom:0px;
    }
    .seperator{
      margin:10px;
      margin-bottom:0px;
    }
    .select-pickup-type{
      margin:20px;
      margin-bottom:0px;
    }
    .select-company-send-options{
      background-color:white;
      border-radius:3px;
      padding-bottom:19px;
      margin-bottom:24px;
    }
    .add-package-button{
      padding:0;
      cursor: pointer;
      outline:none;
      svg{
        path{
          fill:${Colors.blue1};
        }
      }
    }
    .more-button{
      visibility: hidden;
      width: 135.4px;
      font-size: 16px;
      background-color: transparent;
      border: none;
      color: ${Colors.blue1};
      cursor: pointer;
      outline:none;
      height: 20px;
      margin-top: 39px;
      svg{
        path{
          fill:${Colors.blue1};
        }
      }
    }
    svg.company-fedex {
      height: 21px;
    }
    svg.company-ups {
      height: 45px;
    }
  }
  .plus{
    font-size: 27px;
    color: #2699fb;
    line-height: 54px;
    width: 40px;
    text-align: center;
  }
  .space{
    width: 140px;
  }
  .cancel-space{
    width: 226px;
  }
  .stage2-best-price-ship-now-button{
    &.disabled {
      cursor: default;
      border:1.5px solid ${Colors.lightGray2};
      .ship-now {
        background-color:${Colors.lightGray2};
      }
      .best-price{
        color:${Colors.lightGray2};
      }
    }
    border:1.5px solid ${Colors.orange};
    outline:none;
    background-color:${Colors.white};
    border-radius:3px;
    cursor: pointer;
    padding:0;
    font-size:18px;
    width:349px;
    height: 58px;
    .ship-now {
      height:56px;
      width:220px;
      line-height:56px;
      color:${Colors.white};
      background-color:${Colors.orange};

    }
    .best-price {
      height:56px;
      width:130px;
      &.small{
        width: unset;
      }
      color:${Colors.orange};
      .best-price-price{
        font-size:27px;
        color: ${Colors.blue1};
      }
      .best-price-text{
        font-size:11px;
        margin-top:8px;
        color: ${Colors.blue1};
      }
    }
    &.small{
      width: 100px;
      cursor: unset;
    }
  }
  .best-price-confirm-button{
    &.disabled {
      cursor: default;
      border:1.5px solid ${Colors.lightGray4};
      .ship-now {
        background-color:${Colors.lightGray4};
      }
      .best-price{
        color:${Colors.lightGray4};
      }
    }
    border:1.5px solid ${Colors.orange};
    outline:none;
    background-color:${Colors.white};
    border-radius:3px;
    cursor: pointer;
    padding:0;
    font-size:18px;
    width:349px;
    height: 59px;
    .ship-now{
      height:57px;
      width:220px;
      line-height:56px;
      color:${Colors.white};
      background-color:${Colors.orange};
      margin-top: -1px;
    }
    .best-price{
      height:56px;
      width:160px;
      color:${Colors.orange};
      .best-price-price{
        font-size:27px;
        color: ${Colors.blue1};
        &.mobile{
          font-size:18px;
        }
      }
      .best-price-text{
        font-size:11px;
        margin-top:8px;
        color: ${Colors.blue1};
      }
    }
  }
  .stage3-panel-container{
    padding: 16px 19px;
    .form-container{
      margin-bottom:20px;
    }
    .agree-container{
      margin-top: 2px;
      font-size: 12px;
      width: 275px;
      justify-content: start;
      margin: auto;
      .agree-checkbox{
        width:27px;
      }
      .agree-link{
          text-decoration:none;
          color:${Colors.blue1};
          line-height: 42px;
          margin-left: 4px;
          margin-top: 1px;
      }
      .agree-text{
          margin-left:10px;
          color:${Colors.black};
          line-height: 42px;
          margin-top: 1px;
      }
    }
    .card-container{
      .line-container{
        background-color: white;
        hr{
          border: none;
          border-bottom: solid 1px #c1c1c1;
          margin: 16px 5%;
          margin-top: 15px;
        }
      }
      &.two-childeren{
        background:none;
        display:flex;
        flex-direction:column;
      }
      &.first{
        flex: 0 0 34%;
        padding-bottom: 5px;
      }
      &.second{
        flex: 0 0 52%;
      }
      .information{
        &.first{
          padding-top: 6px;
        }
        padding-left: 5%;
        height: 25px;
      }
      .edit-button{
        border: none;
        background: none;
        display: block;
        width: 50px;
        cursor: pointer;
        outline:none;
      }
      .from-label ,.to-label, .packages-label, .sending-option-label, .shipping-option-label{
      color: ${Colors.lightGray2};
      font-size: 16px;
      font-family: museo_sans500;
      margin-right: 10px;
      white-space: nowrap;
    }
    .from-text, .to-text, .sending-option-text, .shipping-option-text-container{
      color: ${Colors.lightGray2};
      font-family: museo_sans300;
      font-size: 13px;
      margin-top: 3px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-right:37px;
    }
    .shipping-option-container {
      margin-top:13px;
      svg {
        &.company-fedex {
          height: 16px;
        }
        &.company-ups {
          height: 28px;
        }
        path {
          fill: ${Colors.lightGray6};
        }
      }
      .shipping-option-text {
        vertical-align: top;
      }
    }

    .packages-text{
      color: ${Colors.lightGray2};
      font-family: museo_sans300;
      font-size: 13px;
      margin-top: 3px;
      white-space: nowrap;
      overflow: hidden;
      margin-right:50px;
    }
      background-color:${Colors.white};
      flex: 0 0 33%;
      border-radius: 3px;
      .new-card{
        outline:none;
        color:${Colors.blue1};
        border:none;
        background: none;
        cursor: pointer;
        font-family: museo_sans500;
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 41px;
          }
      .card-number, .card-month, .card-year{
        color:black;
      }
      .card-name{
        margin-left: 3%;
        svg{
          height: 30px;
        }
      }
      .card-number{
        margin-top: 6px;
      }
      .card-month{
        margin-left: 5%;
      }
      .card-month, .card-year{
        color: ${Colors.lightGray2};
      }
      .billing-ditails {
        .billing-details-checkbox{
          margin-left: 10px;
          SPAN {
            padding: 0;
            margin-right: 5px;
          }
        }
      }
      .agree-container {
        margin-top:0;
        font-size:12px;
        .agree-checkbox {
          width:27px;
        }
        .agree-link {
          text-decoration:none;
          color:${Colors.blue1};
          line-height: 42px;
          margin-left: 4px;
          margin-top: 1px;
        }
        .agree-text{
          margin-left:10px;
          color:${Colors.black};
          line-height: 42px;
          margin-top: 1px;
        }
      }
      .best-price-confirm-button{
        &.disabled {
          cursor: default;
          border:1.5px solid ${Colors.lightGray4};
          .ship-now {
            background-color:${Colors.lightGray4};
          }
          .best-price{
            color:${Colors.lightGray4};
          }
        }
        border:1.5px solid ${Colors.orange};
        outline:none;
        background-color:${Colors.white};
        border-radius:3px;
        cursor: pointer;
        padding:0;
        font-size:18px;
        width:300px;
        height: 58px;
        margin: auto;
        display: block;
        margin-top: 0px;
        margin-bottom: 10px;
        .ship-now{
          height:56px;
          width:220px;
          line-height:56px;
          color:${Colors.white};
          background-color:${Colors.orange};
  
        }
        .best-price{
          height:56px;
          width:130px;
          color:${Colors.orange};
          .best-price-price{
            font-size:27px;
            color: ${Colors.blue1};
            &.mobile{
              font-size:18px;
            }
          }
          .best-price-text{
            font-size:11px;
            margin-top:8px;
            color: ${Colors.blue1};
          }
        }
      }
      .card-header{
        color: ${Colors.lightGray2};
        font-size: 16px;
        padding: 13px;
        padding-bottom: 8px;
        padding-left: 5%;
        padding-top: 18px;
        white-space: nowrap;
        &.payment{
          padding-bottom: 0;
          height: 20px;
          padding-top: 2px;
        }
      }
      .select-card-from-list{
        background: none;
        cursor: pointer;
        border: none;
        font-size: 16px;
        height: 21px;
        margin: 12px;
        color: ${Colors.blue1};
        outline: none;
        margin-bottom: 13px;
        margin-top: 0px;
      }
      .input-field::placeholder{
        color:${Colors.lightGray4};
      }
      .input-field:disabled{
        background-color:#e8e8e8;
      }
      .input-field{
        &.error {
          border: solid 1px ${Colors.error};
        }
        &.cc{
            margin-top:0;
          }
        display: block;
        width: 89.9%;
        margin: auto;
        margin-top: 10px;
        &.half-width{
          width: 48.5%;
          margin: 0;
          min-width:0;
        }
      }
      .seperator{
        width:3%;
      }
      .two-inputs-container{
        margin: auto;
        margin-top: 10px;
        margin-bottom: 0;
        width:89.7%;
      }
      .billing-ditails{
        width: 118px;
        height: 30px;
        margin-top: 13px;
        margin-right: 10px;
        .billing-ditails-text{
          color:${Colors.black};
          font-size: 12px;
          width: 80px;
          margin-top: 8px;
        }
        .billing-details-checkbox {
          .label {
            width: 90px;
            font-size: 12px;
          }
        }
        .billing-ditails-checkbox{
          width: 27px;
          margin: auto;
          
        }
      }
      
      .add-container{
        svg{
          width:17px;
          height:17px;
          path{
            fill:${Colors.blue1};
          }
        }
        color: ${Colors.blue1};
        background-color: rgba(0,0,0,0);
        border: none;
        outline: none;
        cursor: pointer;
        height: 54.4px;
        padding: 0;
        margin-left: 4%;
        SPAN {
          position: relative;
          top: -4px;
          padding-left: 5px;
          font-size: 14px;
        }
      }
    }
  }
  // mobile
  @media only screen and (max-width: 992px) {
    .custom-package-container{
      .input-field{
        margin:0;
      }
    }
    .add-insurance{
      margin: auto;
      display: block;
      font-family: 'museo_sans500';
      &.hide{
        display: none;
      }
    }
    .input-field{
      font-size: 16px;
      margin-top: 2%;
    }
    .panel-container{
      width: unset;
      margin: 3% 3% 0 3%;
      padding: 2%;
      padding-top: 0;
    }
    .main-page-background-container {
      height: 1040px;
    }
    .stage1-panel-container {
      .insurance-container {
        .content-input{
          margin-bottom: 20px;
        }
        .mani-container{
          flex-direction: column;
        }
      }
      .second-row-controls-container{
        padding-top:2%;
      }
      .more-button{
        margin-top: 2%;
      }
      .add-container{
        height: 35px;
        &.more{
          padding-top: 1px;
        }
        &.more-options{
          text-align: center;
          margin: auto;
          display: block;
          padding-top: 5px;
          .text{
            font-size: 16px;
          }
        }
        &.pack-list{
          svg{
            width:28px;
            height: 26px;
          }
        }
        SPAN{
          font-family: museo_sans500;
          position: unset;
          top: unset;
          padding-left: 0;
          font-size: unset;
        }
      }
      .flex-divided-3-cols {
        .error-message{
          &.address{
            display: none;
            &.error-text{
              display: block;
            }
          }
          &.weight{
              display: none;
            &.error-text{
              display: block;
            }
          }
        }
        margin-top: 4%;
        border-radius: 3px;
        flex: none;
        height: unset;
        .sub-title{
          margin-top: 14px;
        }
        .panel-with-background{
          min-width: 106px;
          height: unset;
          padding-bottom: 3%;
          .input-package-type{
            height: 38px;
            margin-top: 6px;
            justify-content: center;
          }
        }
        .input-address-from-component, .input-address-to-component, .input-weight-component{
          margin-left: 4%;
        }
        .panel-item-title-container{
          padding: 3% 0 3% 4%;
          min-width: 107px;
        }
        .panel-with-background {
          &.with-right-margin {
            margin-right: 0;
            height: unset;
            padding-bottom: 3%;
          }
        }
      }
      > .display-flex {
        flex-direction: column;
        }
      .get-your-price-button {
        font-family: museo_sans700;
        margin-top: 5%;
        margin-bottom: 5%;
        width: auto;
      }
    }
    .seperate-line{
      background-color: ${Colors.white};
      margin: 0;
      width: unset;
    }
    .stage3-panel-container{
      margin-bottom:100px;
      .card-container{
        .shipping-option-container{
          margin-top: 23px;
        }
        .select-card-from-list{
          margin-bottom: 0;
          margin-top: 13px;
        }
        .card-header{
          margin-bottom: 4%;
          margin-top: 4%;
        }
        &.first{
          margin-top: 0;
          padding-top: 0;
        }
        &.second{
          margin-top: 0;
        }
        &.two-childeren{
          padding-top: 0;
          padding-bottom: 0;
        }
        padding-bottom: 3%;
        margin-top: 3%;
        .two-inputs-container{
          margin-top: 10px;
        }
        .input-field{
          margin-top: 3%;
          &.cc{
            margin-top:0;
          }
        }
        .billing-ditails{
          margin-top: 6.5%;
        }
      }        
      .billing-ditails{
          .billing-details-checkbox{
            SPAN{
              padding: 0;
              margin-right: 5px;
            }

          }
        }
        margin-bottom: 4%;
       
       
        .add-container{
          margin-top: 2%;
          height: 40px;
        }
      }
      .completed-container{
        &.stage3-completed-2{
          width: 100%;
          padding-top: 14px;
          padding-bottom: 12px;
          .action-icon-container{
            right: 10px;
            top: 18px;
            BUTTON{
              min-width: unset;
            }
          }
          margin: 0;
          background-color: ${Colors.white};
          margin-bottom:4%;
        }
        &.stage3-completed-1{
          width: 100%;
          .action-icon-container{
            right: 10px;
            top: 18px;
            BUTTON{
              min-width: unset;
            }
          }
          margin: 0;
          background-color: ${Colors.white};
        }
      }
    
    .stage2-panel-container {
      padding: 2%;
      padding-top: 4%;
      margin-top: 10%;
      .select-shipping-time{
        margin: 2%;
        margin-top: 8%;
      }
      .select-pickup-type{
        margin: 2%;
      }
      .first-select{
        margin-bottom: 20px;
      }
      .select-company-send-options{
        margin-bottom: 0;
        padding-bottom: 2%;
        .select-company-send-options{
          margin-top: 8%;
        }
        .select-pickup-type{
          margin-top: 8%;
        }
        .display-flex {
          flex-direction: column;
          align-items:flex-start;
          }
        .stage-2-tabs{
          flex-direction: unset;
          align-items:unset;
        }
      }
      .second-row-controls-container{
        flex-direction: column;
        align-items:center;
        .best-price-ship-now-button{
          .button-content{
            flex-direction: row;
          }
        }
      }
      .stage2-best-price-ship-now-button {
        .button-content{
          flex-direction: row;
        }
        &.disabled {
          cursor: default;
          border:1.5px solid ${Colors.lightGray2};
          .ship-now {
            background-color:${Colors.lightGray2};
          }
          .best-price{
            color:${Colors.lightGray2};
          }
        }
        border:1.5px solid ${Colors.orange};
        outline:none;
        background-color:${Colors.white};
        border-radius:3px;
        cursor: pointer;
        padding:0;
        font-size:18px;
        width: 100%;
        height: 58px;
        display: block;
        margin-top: 5%;
        margin-bottom: 5%;
        &.last{
          margin-bottom:0;
        }
        .ship-now{
          height:56px;
          width:100%;
          line-height:56px;
          color:${Colors.white};
          background-color:${Colors.orange};
  
        }
        .best-price{
          height:56px;
          width:100px;
          color:${Colors.orange};
          .best-price-price{
            font-size:27px;
            color: ${Colors.blue1};
            &.mobile{
              font-size:18px;
            }
          }
          .best-price-text{
            color: ${Colors.blue1};
            font-size:11px;
            margin-top:8px;
          }
        }
      }
      .display-flex {
        flex-direction: column;
        align-items:center;
      }
      .completed-container{
        width: 100%;
        flex-direction: row;
        align-items:left;
        margin: 0;
        margin-bottom: 4%;
        background-color: ${Colors.white};
        .action-icon-container{
          right: 10px;
          top: 18px;
          BUTTON{
            min-width: unset;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 1350px) {
    .panel-container{
      margin: 19px 7%;
      width: unset;
    }
    .seperate-line{
      width: 80%;
    }
  }
`;

export default MainPagePurchaseProgress;
